.header {
  background: white;
  // line-height: 1.125rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 0 3.5rem;
  margin-bottom: 100px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  .global-search {
    max-width: 500px;
    margin: auto;
    display: flex;
    line-height: 0;
    input {
      padding: 12px 16px;
      border: 2px solid #fd104d;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      outline: none !important;
      flex: 1;
      font-size: var(--small-font-size);
      max-height: 40px;
    }
    button {
      background: #fd104d;
      color: white;
      padding: 12px 30px;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border: 2px solid #fd104d;
      font-size: var(--small-font-size);
    }
  }
  .navbar-ham {
    display: none;
  }
  .menu-left {
    // line-height: 25px;
    line-height: 1.125rem;
    border: 0;
    display: flex !important;
    align-items: center !important;
    text-align: left !important;
    // margin-top: 7px;
    height: 60px;
    .ant-alert {
      font-size: var(--small-font-size);
    }
  }
  .menu-right {
    float: right;
    border-bottom: none !important;

    .download-route {
      a {
        display: flex;
        margin-bottom: 2px;
        margin-right: 8px;
        i {
          font-size: 18px;
        }
      }
    }

    .plan-info {
      border-radius: 4px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-right: 12px;

      border-radius: 2px;
      padding: 5px 10px;
      background: #f5fafe;
      border: 1px solid #b5daff;

      .red {
        color: red !important;
        border: none !important;
      }

      .green {
        color: green;
        border: none !important;
      }

      .blue {
        color: #1818b8;
        border: none !important;
      }

      .ng-margin {
        margin-left: 6px;
      }

      svg {
        height: 18px !important;
        width: 18px;
      }
      .plan-name {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #52b4bf;
        font-size: 14px;
        text-transform: capitalize;
      }

      p {
        line-height: 18px;
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;
        color: #212121;
        font-size: 12px;

        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .help-notification {
    display: flex !important;
    align-items: center;
    .help-icon {
      display: block;
    }
    .help-icon-hover {
      display: none;
    }
  }
  .help-notification:hover {
    .help-icon {
      display: none;
    }
    .help-icon-hover {
      display: block;
    }
  }
  .shopify-notification {
    display: flex !important;
    align-items: center;
    .icon-without-hover {
      display: block;
    }
    .icon-with-hover {
      display: none;
    }
  }
  .shopify-notification:hover {
    .icon-without-hover {
      display: none;
    }
    .icon-with-hover {
      display: block;
    }
  }
  .ant-menu {
    display: inline-block;
    font-size: var(--small-font-size);
    .ant-menu-item,
    .ant-menu-item-selected {
      border-bottom: 0;
      padding: 0 0px;
      font-size: var(--small-font-size);
      font-weight: 400;
    }
  }
  .navbar-user-detail {
    background: #f5faff;
    padding: 8px 5px !important;
  }
  .ant-avatar {
    background-color: rgb(252, 71, 71);
    border: 2px solid white;
    box-sizing: content-box;
  }
  .avatar-details {
    display: inline-block;
    font-weight: 500;
    margin: 0 10px;
    font-size: var(--small-font-size);
    line-height: 20px;
    color: #212121;
    text-transform: capitalize;
    vertical-align: middle;
    span {
      font-size: 11px;
      font-weight: 400;
    }
  }
  .ant-dropdown-menu-item {
    a {
      padding: 10px 25px;
    }
  }
  .div {
    a {
      display: flex;
      font-size: var(--small-font-size);
      font-weight: 400;
      flex-direction: column;
      justify-content: center;
      span:first-of-type {
        margin-bottom: 0px;
      }
    }
  }
  .wallet {
    background: #f5faff;
    // padding: 13px 8px;
    font-size: var(--small-font-size) !important;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: #212121;
  }
  .walletParent {
    margin-left: 1rem !important;
    div {
      border: 1px solid #b5daff;
    }
  }
  // .storeStatusIcon {
  //     font-size: 20px;
  //     svg {
  //         font-size: 20px !important;
  //     }
  // }
  // .ant-dropdown-open {
  //   color: black !important;
  // }
}

.shopify_img {
  width: 16px;
  height: 16px;
}
.woocommerce_img {
  margin-right: 4px;
}

.ogoWoocommerce_img {
  width: 32px;
  margin-right: 4px;
}

.wocommerce_shopify {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-left: 4px;
  // svg {
  //   width: 56px;
  // }
}

.storeStatusIcon {
  svg {
    font-size: 32px !important;
  }
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 10px;
  min-width: auto;
}

.navhead {
  position: absolute;
  width: auto;
  top: 0;
  // left: 200px;
  z-index: 999;
}

.upnav {
  transform: translateY(-100%);
  transition: all 0.3s linear;
}

.downnav {
  transform: translateY(0%);
  transition: all 0.3s linear;
}

.up2 {
  transform: translateY(-100%);
  transition: all 0.3s linear;
  box-shadow: none !important;
}

.down2 {
  transform: translateY(0%);
  transition: all 0.3s linear;
  box-shadow: none !important;
}

.header.full-width {
  width: 100%;
}

.userli {
  font-size: var(--small-font-size) !important;
  display: flex;
  align-items: center;
  height: 40px;
  padding-right: 8px !important;

  .left_Store {
    width: 48%;
    display: flex;
    align-items: center;
  }
  .right_store {
    width: 48%;
    display: flex;
    align-items: center;
  }

  p {
    margin-left: 0 !important;
  }
}

.header.full-width {
  padding: 0 72px 0 0;
  box-shadow: none;
  z-index: 1;
}

@media (max-width: 768px) {
  .header .ant-menu {
    display: none;
  }
  .header.ant-layout-header .navbar-ham {
    display: block;
    float: right;
  }
}

// .header.ant-layout-header {
//   padding: 0 20px;
//   background: #001E41;
//   .logo {
//     // display: inline-block;
//     float: left;
//   }
//   .ant-menu-dark {
//     background: transparent;
//     float: right
//   }
//   .ant-menu-item {
//     padding: 0 10px;
//   }
//   .ant-menu-item-selected, .ant-menu-item-active {
//     background: #00162F;
//   }
//   .ant-menu-item .anticon {
//     font-size: var(--extra-medium-small-font-size);
//   }
// }
// .ant-dropdown-open {
//   color: black !important;
// }

// .ant-dropdown-open {
//     a {
//          :hover {
//             color: red !important;
//         }
//     }
// }
.shop {
  margin-top: 22px;
  z-index: 999;
}

.helpnav {
  margin-top: 11px;
}

.usernav {
  margin-top: 11px;
}

.userStore {
  margin-top: 16px !important;
  text-transform: capitalize;
  position: relative;
  margin: 0;

  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;

  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px #00000015;
  -webkit-transform: translate3d(0, 0, 0);

  .userStore-nested {
    border-radius: 4px;
    // padding-bottom: 32px;
  }
}

.store-dropdown-list {
  position: relative;
}

.add-shopify-store {
  position: absolute;
  bottom: 0;
  height: 39px;
  cursor: pointer;
  background: #fff;
  width: 100% !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-left: 16px;
  border-top: 1px solid rgb(223, 223, 223);
  box-shadow: 0px -1px 10px #00000010;
  margin-bottom: 0px !important;
}

.storenav:hover {
  background: #f5faff;
}

.add-shopify-store:hover {
  background: #f5faff;
}

@media screen and (max-width: 1438px) {
  .navhead {
    position: absolute;
    width: calc(100% - 200px);
    top: 0;
    // left: 200px;
    padding: 0 3.5rem;
    z-index: 999;
  }
}

@media screen and (min-width: 1439px) and (max-width: 1445px) {
  .navhead {
    position: absolute;
    width: calc(100% - 200px);
    top: 0;
    // left: 200px;
    padding: 0 104px !important;
    z-index: 999;
  }

  .header.full-width {
    width: 90%;
    padding-left: 5% !important;
    padding-right: 5.5% !important;
  }
}

@media screen and (min-width: 1440px) and (max-width: 3200px) {
  .navhead {
    position: absolute;
    width: calc(100% - 240px);
    top: 0;
    // left: 200px;
    z-index: 999;
    padding: 0 3.5rem;
  }
  .header.full-width {
    width: 100%;
    padding-left: 5% !important;
    padding-right: 5.5% !important;
  }
}

.vis {
  top: 56px !important;
}

.ant-spin.ant-spin-spinning {
  border: none !important;
}

.plan-link {
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
  }
}
