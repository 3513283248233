@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:wght@600&family=Poppins:wght@400;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Martel:wght@400;600&display=swap);
.import-product-search {
  width: 400px !important; }
  .import-product-search .ant-input-group .ant-input {
    height: 40px !important;
    box-shadow: 0px 2px 8px #66656514;
    border-radius: 2px 0 0 2px;
    border: 1px solid #e3e3e3 !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .import-product-search .ant-input-group .ant-input:hover {
    border: 1px solid #b2b2b2 !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .import-product-search .ant-input-group .ant-input:focus {
    border: 2px solid #fd104d !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .import-product-search button {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: none; }

.ant-btn {
  border-radius: 2px !important; }

.pick {
  height: 40px !important; }
  .pick .ant-calendar-picker-input {
    height: 40px; }

.clickable {
  background-color: #0068ff !important;
  box-shadow: 0px 2px 8px #002f7225 !important;
  border: 2px solid #0068ff !important;
  border-radius: 2px !important;
  line-height: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #fff !important;
  text-shadow: none !important;
  cursor: pointer; }

.clickable:hover {
  background-color: #0063f2 !important;
  border: 2px solid #0058d6 !important;
  color: #fff !important; }

.clickable:active {
  background-color: #0058d6 !important;
  color: #fff !important;
  box-shadow: none !important; }

.clickable-dropdown {
  transition: 0.5s all;
  background-color: #2a9c27 !important;
  box-shadow: 0px 2px 8px #002f7225 !important;
  border: 2px solid #2a9c27 !important;
  border-radius: 2px !important;
  line-height: none !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #fff !important;
  text-shadow: none !important; }

.clickable-dropdown:hover {
  transition: 0.5s all;
  background-color: #2bc822 !important;
  border: 2px solid #229d1b !important;
  color: #fff !important;
  box-shadow: none !important; }

.clickable-dropdown:active {
  background-color: #229d1b !important;
  color: #fff !important; }

.capitalize-text {
  text-transform: capitalize; }

.cursor-pointer {
  cursor: pointer; }

.border-orange {
  position: relative;
  z-index: 9;
  border-radius: 5px; }
  .border-orange .ant-input:hover {
    border: none; }
  .border-orange .ant-calendar-picker:hover {
    border: none; }
  .border-orange .ant-calendar-picker:hover .ant-calendar-picker-input {
    border: none; }
  .border-orange .ant-calendar-picker-input.ant-input {
    border: 1px solid red !important;
    animation: border-pulse 2s infinite; }

.small-tooltip-text {
  font-size: 11px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.032em !important; }

@keyframes border-pulse {
  0% {
    box-shadow: 0 0 0 0 red; }
  70% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

.ant-tooltip-inner {
  background-color: #000000e6 !important;
  padding: 12px !important;
  font-size: 11px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.032em !important; }
  .ant-tooltip-inner .float-tooltip h1 {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #fff; }
  .ant-tooltip-inner .float-tooltip p {
    text-align: left; }

.underline-animation {
  display: inline-block;
  position: relative;
  color: #0068ff; }

.underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  color: #0068ff;
  background-color: #0068ff;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out; }

.underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left; }

.ant-modal-body .input-number-txt {
  width: 100% !important; }

.breadcurmb-txt {
  display: flex !important;
  align-items: center; }
  .breadcurmb-txt > span {
    margin: 0 4px !important; }

.flag-dropdown {
  background-color: transparent !important; }
  .flag-dropdown .selected-flag:hover {
    background-color: transparent !important; }
  .flag-dropdown .selected-flag:focus {
    background-color: transparent !important; }

.react-tel-input .form-control {
  border-radius: 0px !important; }

.ant-modal-mask {
  background-color: #000000e6 !important;
  z-index: 999 !important; }

.logoRightContainer {
  display: none; }

@media screen and (max-width: 967px) {
  .left-card-containerS {
    display: none; }
  .mainCard-signup {
    max-width: 98%; }
  .right-card-signincontainer .flex-from {
    padding: 0 8% !important; }
  .right-card-signincontainer .flex-from input {
    width: 100% !important; }
  .right-card-signincontainer .flex-from .submitbutton {
    width: 100% !important; }
  .right-card-signincontainer .flex-from .signup-line .login-form-signup-here {
    margin-left: unset !important; }
  .right-card-signincontainer .flex-from .signup-line {
    display: flex;
    justify-content: space-between; }
  .right-card-signincontainer .sub-heading {
    margin-bottom: 20px; }
  .logoRightContainer {
    display: block;
    text-align: center;
    margin-top: 20px; }
  .otpVerifyContaner .heading-bar,
  .otpVerifyContaner .otp-section {
    padding: 20px 10% !important; }
  .otpVerifyContaner .main-heading {
    font-size: 22px !important; }
  .otpVerifyContaner .email-section {
    padding: 0 10% !important; }
  .otpVerifyContaner .email-section .input-email {
    width: 100% !important; }
  .otpVerifyContaner .otp-section .submit-section {
    margin-top: 10px;
    padding-top: unset !important; }
  .otpVerifyContaner {
    min-height: unset !important; }
  .otpVerifyContaner .otp-section .otp-verify .otp-number {
    width: 40px !important; }
  .otpVerifyContaner .otp-section .submit-section button {
    width: 100% !important; } }

@media screen and (max-width: 530px) {
  .forgot-remember-parent {
    width: 100% !important; }
  .mainCard-signup {
    max-width: 98%; }
  .right-card-signupcontainer .flex-from .flex-form-inner .option-group {
    flex-direction: column;
    width: auto !important; }
  .right-card-signupcontainer .heading-bar {
    padding: 15px !important; }
  .right-card-signupcontainer .main-heading {
    font-size: 22px !important; }
  .right-card-signupcontainer .flex-from {
    padding: 15px !important; }
  .right-card-signupcontainer .flex-form2 {
    padding: 15px !important; }
  .right-card-signupcontainer .flex-form2 .submitbutton {
    width: 100% !important; }
  .right-card-signupcontainer .signup-line {
    display: flex;
    justify-content: space-between; }
  .right-card-signupcontainer .signup-line .login-form-signup-here {
    margin-left: 0px !important; }
  .right-card-signincontainer .heading-bar {
    padding: 15px !important; }
  .right-card-signincontainer .main-heading {
    font-size: 22px !important;
    text-align: center;
    padding-left: unset !important; }
  .right-card-signincontainer .sub-heading {
    text-align: center;
    padding-left: unset !important; }
  .right-card-signincontainer .flex-from {
    padding: 0 8% !important; }
  .right-card-signincontainer .flex-from input {
    width: 100% !important; }
  .right-card-signincontainer .flex-from .submitbutton {
    width: 100% !important; }
  .right-card-signincontainer .flex-from .signup-line .login-form-signup-here {
    margin-left: unset !important; }
  .right-card-signincontainer .flex-from {
    padding: 0 8% !important; } }

* {
  font-family: "Poppins", "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important; }

:root {
  --normal-letter-spacing: 0.032em;
  --small-font-size: 12px;
  --extra-small-font-size: 10px;
  --extra-medium-small-font-size: 14px;
  --medium-font-size: 16px;
  --extra-medium-font-size: 20px;
  --max-font-size: 24px;
  --small-line-height: 16px;
  --max-line-height: 24px;
  --medium-line-height: 20px; }

html,
body {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-size: unset !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; }

h1 {
  font-size: 2em; }

#root {
  height: 100%; }

.content-body {
  height: 100%;
  overflow-y: hidden; }

.bold {
  font-weight: bold; }

.ant-card {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid #d6d6d6 !important; }

a {
  text-decoration: none !important; }

.content-page-title {
  font-size: 1.3125rem;
  font-weight: 400;
  margin-bottom: 0px; }

.content-page-subtitle {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0px; }

.content-page-subtitle-right {
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0; }

.ant-dropdown-menu {
  padding: 0 !important; }
  .ant-dropdown-menu .ant-dropdown-menu-item-divider {
    margin: 0; }

.import-product-search .ant-input-group .ant-input {
  height: 400px;
  box-shadow: 0px 2px 8px #00000014;
  border-radius: 2px 0 0 2px;
  border: 0px; }
  .import-product-search .ant-input-group .ant-input input {
    height: 40px; }

.import-product-search input {
  height: 40px; }

.ant-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative; }
  .ant-pagination .ant-pagination-total-text {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    position: absolute;
    font-size: 12px;
    right: 120px;
    color: #898989; }
  .ant-pagination .ant-pagination-item {
    border-radius: 2px;
    font-size: 12px;
    border: 1px solid #ffffff;
    box-shadow: 0 3px 8px #00000016;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px; }
    .ant-pagination .ant-pagination-item a {
      color: #212121;
      font-size: 12px; }
  .ant-pagination .ant-pagination-jump-next-custom-icon {
    margin-right: 0px; }
  .ant-pagination .ant-pagination-item-before-jump-next {
    margin-right: 0px; }
  .ant-pagination .ant-pagination-prev {
    border-radius: 2px;
    border: 2px solid #ffffff; }
    .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
      border-radius: 0px;
      border: 0px; }
  .ant-pagination .ant-pagination-next .ant-pagination-item-link {
    border-radius: 2px;
    border: 0px; }
  .ant-pagination .ant-pagination-item:hover {
    background-color: #f5faff;
    border: 1px solid #1268fb; }
    .ant-pagination .ant-pagination-item:hover a {
      color: #212121;
      border: 0px solid #1268fb;
      border-radius: 0px; }
  .ant-pagination .ant-pagination-item-active {
    background-color: #1268fb;
    border-radius: 2px;
    border: 1px solid #1268fb; }
    .ant-pagination .ant-pagination-item-active a {
      color: #fff;
      border-radius: 0px; }
  .ant-pagination .ant-pagination-item-active:hover {
    background-color: #1268fb;
    border: 1px solid #1268fb;
    border-radius: 2px; }
    .ant-pagination .ant-pagination-item-active:hover a {
      color: #fff; }
  .ant-pagination .ant-pagination-item-active:focus {
    background-color: #1268fb;
    border: 1px solid #1268fb;
    border-radius: 2px; }
    .ant-pagination .ant-pagination-item-active:focus a {
      color: #fff; }
  .ant-pagination .ant-pagination-options {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    margin-left: 48px;
    justify-content: flex-end; }
    .ant-pagination .ant-pagination-options .ant-pagination-options-size-changer {
      order: 2;
      margin-right: 0px;
      min-width: 103px; }
      .ant-pagination .ant-pagination-options .ant-pagination-options-size-changer .ant-select-selection {
        border-radius: 2px;
        border: 0px solid #ffffff; }
        .ant-pagination .ant-pagination-options .ant-pagination-options-size-changer .ant-select-selection .ant-select-selection-selected-value {
          color: #212121;
          font-size: 12px;
          width: 85px; }
    .ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
      position: absolute;
      left: 0px;
      font-size: 12px;
      color: #898989; }
      .ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
        border-radius: 2px;
        border: 1px solid #ffffff;
        color: #212121;
        height: 32px;
        width: 36px;
        padding: 0;
        text-align: center; }

@media screen and (max-width: 1438px) {
  .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative; }
    .ant-pagination .ant-pagination-total-text {
      min-width: -webkit-fit-content;
      min-width: -moz-fit-content;
      min-width: fit-content;
      position: absolute;
      font-size: 12px;
      right: 120px;
      color: #898989; }
    .ant-pagination .ant-pagination-item {
      border: 0 !important;
      border-radius: 2px;
      font-size: 12px;
      display: flex;
      height: 24px;
      width: 24px !important;
      align-items: center;
      min-width: 24px !important; }
      .ant-pagination .ant-pagination-item a {
        color: #212121;
        font-size: 12px;
        line-height: 24px;
        height: 24px !important;
        width: 24px !important;
        min-width: 24px !important;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center; }
    .ant-pagination .ant-pagination-jump-next-custom-icon {
      margin-right: 0px; }
    .ant-pagination .ant-pagination-item-before-jump-next {
      margin-right: 0px; }
    .ant-pagination .ant-pagination-prev {
      border: 0 !important;
      border-radius: 2px;
      font-size: 12px;
      display: flex;
      height: 24px;
      width: 24px !important;
      align-items: center;
      min-width: 24px !important; }
      .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
        color: #212121;
        font-size: 12px;
        line-height: 24px;
        height: 24px !important;
        width: 24px !important;
        min-width: 24px !important;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center; }
    .ant-pagination .ant-pagination-next {
      border: 0 !important;
      border-radius: 2px;
      font-size: 12px;
      display: flex;
      height: 24px;
      width: 24px !important;
      align-items: center;
      min-width: 24px !important; }
      .ant-pagination .ant-pagination-next .ant-pagination-item-link {
        color: #212121;
        font-size: 12px;
        line-height: 24px;
        height: 24px !important;
        width: 24px !important;
        min-width: 24px !important;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center; }
    .ant-pagination .ant-pagination-item:hover {
      background-color: #f5faff;
      border-radius: 2px; }
      .ant-pagination .ant-pagination-item:hover a {
        color: #212121;
        border: 1px solid #4398ed; }
    .ant-pagination .ant-pagination-item-active {
      background-color: #1268fb;
      border-radius: 2px;
      border: 1px solid #1268fb; }
      .ant-pagination .ant-pagination-item-active a {
        color: #fff;
        border-radius: 0px; }
    .ant-pagination .ant-pagination-item-active:hover {
      background-color: #1268fb;
      border: 1px solid #1268fb;
      border-radius: 2px; }
      .ant-pagination .ant-pagination-item-active:hover a {
        color: #fff; }
    .ant-pagination .ant-pagination-item-active:focus {
      background-color: #1268fb;
      border-radius: 2px; }
      .ant-pagination .ant-pagination-item-active:focus a {
        color: #fff; }
    .ant-pagination .ant-pagination-options {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      margin-left: 48px;
      justify-content: flex-end;
      height: 24px; }
      .ant-pagination .ant-pagination-options .ant-pagination-options-size-changer {
        order: 2;
        margin-right: 0px;
        min-width: 103px;
        border: 0 !important;
        border-radius: 2px;
        font-size: 12px;
        display: flex;
        height: 24px;
        align-items: center; }
        .ant-pagination .ant-pagination-options .ant-pagination-options-size-changer .ant-select-selection {
          border-radius: 2px;
          border: 0px solid #ffffff;
          height: 24px; }
          .ant-pagination .ant-pagination-options .ant-pagination-options-size-changer .ant-select-selection .ant-select-selection-selected-value {
            color: #212121;
            font-size: 12px;
            height: 24px;
            line-height: 24px; }
      .ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
        position: absolute;
        left: 0px;
        font-size: 12px;
        color: #898989; }
        .ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
          border-radius: 2px;
          border: 1px solid #ffffff;
          color: #212121;
          height: 24px;
          width: 32px; } }

.recharts-surface {
  overflow: visible !important; }

#fade-in {
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1s; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

.alert-wrap-reporting {
  position: relative; }
  .alert-wrap-reporting .ex-ic {
    position: absolute;
    top: 17px;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;
    display: inline-block;
    color: #0068ff;
    left: 25px; }
  .alert-wrap-reporting .anticon-info-circle {
    visibility: hidden; }

.table-title-icon {
  display: flex;
  align-items: center;
  justify-content: center !important;
  position: relative;
  z-index: 9999; }
  .table-title-icon > span {
    min-width: 24px !important; }

.ant-message {
  z-index: 9999999999999 !important; }

.login-page-container {
  width: 100%;
  height: 100%;
  padding: 20px 0px 20px 20px; }
  .login-page-container .ant-form-item {
    margin-bottom: 15px; }
  .login-page-container .login-container {
    width: 390px;
    max-width: 100%;
    height: 100%;
    margin: auto;
    padding: 7vw 15px 0 15px; }
    .login-page-container .login-container .logo {
      margin-bottom: 16.9px;
      width: 119.31px; }
    .login-page-container .login-container .login-page-heading {
      margin-bottom: 0px;
      font-size: 32px;
      font-weight: 600;
      line-height: 38px; }
    .login-page-container .login-container .tagline {
      color: #1a1a1a;
      margin: 0 0 23.6px 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.2px; }
    .login-page-container .login-container .ant-tabs-bar {
      border: 0;
      text-align: center; }
    .login-page-container .login-container .login-form .ant-input-lg {
      height: 36px; }
    .login-page-container .login-container .login-form-forgot,
    .login-page-container .login-container .login-form-signup-here {
      float: right; }
    .login-page-container .login-container .login-form-forgot {
      font-weight: 500; }
    .login-page-container .login-container .ant-alert {
      margin-bottom: 15px; }
    .login-page-container .login-container .login-form-button {
      width: 100%;
      margin: 0 0;
      box-shadow: 0px 1px 2px rgba(0, 104, 255, 0.59); }
    .login-page-container .login-container .register-tag-line {
      margin: 15px 0 10px 0; }
    .login-page-container .login-container .login-page-footer {
      position: -webkit-sticky;
      position: sticky;
      top: calc(100% - 55px); }
      .login-page-container .login-container .login-page-footer p {
        margin-bottom: 0px;
        color: #1a1a1a; }
      .login-page-container .login-container .login-page-footer .footer-links {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0px; }
        .login-page-container .login-container .login-page-footer .footer-links li {
          display: inline-block;
          margin: 0 20px; }
          .login-page-container .login-container .login-page-footer .footer-links li a {
            color: #1a1a1a; }
          .login-page-container .login-container .login-page-footer .footer-links li a:hover {
            color: black; }
    .login-page-container .login-container .login_form {
      flex: 1 0 auto; }

.signup-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  background: url(/static/media/onboarding_bg.8b0debec.svg) no-repeat;
  background-size: cover; }
  .signup-container .signup {
    flex: 1 1;
    max-width: 100%;
    height: 100%;
    margin: auto;
    padding: 2.5vw 15px 0 15px; }
    .signup-container .signup .sms-switch {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: lighter;
      color: #808080;
      flex-wrap: wrap;
      grid-gap: 8px;
      gap: 8px; }
      .signup-container .signup .sms-switch .right-text {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        gap: 8px; }
        .signup-container .signup .sms-switch .right-text .ant-switch {
          background-color: #1890ff !important; }
        .signup-container .signup .sms-switch .right-text .ant-switch-checked {
          background-color: #36c626 !important; }
        .signup-container .signup .sms-switch .right-text span {
          font-weight: 500; }
    .signup-container .signup .logo {
      margin-bottom: 18px;
      max-width: 200px; }
    .signup-container .signup .signup-form-container {
      min-height: 100%;
      background-position: bottom right;
      background-repeat: no-repeat;
      padding: 10rem;
      position: relative; }
      .signup-container .signup .signup-form-container svg.logo {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        max-width: 100%; }
      .signup-container .signup .signup-form-container .signup-card {
        width: 700px;
        margin: auto;
        box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
        border-radius: 0; }
        .signup-container .signup .signup-form-container .signup-card .react-tel-input {
          border: 2px solid #cdcdcc !important;
          min-height: 40px;
          background-color: #fff !important;
          border-radius: 4px !important; }
          .signup-container .signup .signup-form-container .signup-card .react-tel-input input {
            background: #fff !important; }
        .signup-container .signup .signup-form-container .signup-card .ant-card-body {
          padding: 2.57rem; }
          .signup-container .signup .signup-form-container .signup-card .ant-card-body .title {
            text-align: center;
            font-size: 2rem;
            margin-bottom: 0;
            color: #3a3543;
            font-weight: 500; }
          .signup-container .signup .signup-form-container .signup-card .ant-card-body .sub-title {
            text-align: center;
            font-size: 1.143rem;
            color: #000;
            font-weight: lighter; }
          .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form {
            padding-top: 2.143rem; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .flex {
              display: flex;
              justify-content: space-between;
              align-items: flex-start; }
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .flex .ant-form-item,
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .flex .form-button,
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .flex .disclaimer {
                width: 48%;
                margin-bottom: 0.36rem; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item {
              display: block;
              margin-top: 0.71rem;
              margin-bottom: 1.07rem; }
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input {
                border: 2px solid #cdcdcc;
                border-radius: 4px;
                min-height: 40px; }
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:hover,
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:active,
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus,
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus-within {
                border-color: #4d4d4d;
                box-shadow: 0 0 0 2px rgba(77, 77, 77, 0); }
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper {
                display: block; }
                .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper span {
                  color: #4d4d4d;
                  font-size: 1rem;
                  font-weight: lighter; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .has-error input {
              border-color: #ff1c1c !important; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .label {
              font-size: 1rem;
              font-weight: lighter;
              color: #808080; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .checkbox-label {
              color: #868686;
              font-size: 1rem;
              font-weight: 400;
              font-style: italic;
              display: block; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer {
              font-weight: 500; }
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer a {
                color: #ff806f !important; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .form-button {
              font-size: 1.86rem;
              background: #00db6e;
              border-color: #00db6e;
              outline: none;
              padding: 16px 10%;
              text-transform: capitalize;
              height: 5.5rem;
              max-height: 100%;
              font-weight: 600;
              border-radius: 10px; }
          .signup-container .signup .signup-form-container .signup-card .ant-card-body .bottom-link {
            color: #868686;
            font-size: 0.86rem;
            font-weight: 500;
            text-align: center;
            margin: 1rem 0 0 0; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .bottom-link a {
              color: #ff806f !important; }
      .signup-container .signup .signup-form-container .onboarding-footer {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translate(-50%, 0); }

.signup-password-popover-content h5 {
  color: #535353;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.1rem; }

.signup-password-popover-content p {
  color: #868686;
  font-size: 1rem;
  margin-bottom: 0.1rem; }
  .signup-password-popover-content p svg {
    width: 1rem;
    height: 1rem;
    vertical-align: text-bottom; }

@media (max-width: 500px) {
  .signup-container .signup {
    height: 100%;
    margin: auto;
    padding: 1rem !important;
    width: 100% !important; }
    .signup-container .signup .sms-switch {
      margin-bottom: 12px;
      justify-content: center; }
    .signup-container .signup .ant-row-flex {
      transform: translateX(-50%) !important;
      left: 50% !important;
      position: absolute !important; }
    .signup-container .signup .logo {
      margin-bottom: 18px;
      max-width: 200px; }
    .signup-container .signup .signup-form-container {
      min-height: 100%;
      width: 100% !important;
      background-position: bottom right;
      background-repeat: no-repeat;
      padding: 3rem 1rem !important;
      position: relative; }
      .signup-container .signup .signup-form-container svg.logo {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        max-width: 100%; }
      .signup-container .signup .signup-form-container .signup-card {
        width: 100% !important;
        margin: auto;
        box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
        border-radius: 0; }
        .signup-container .signup .signup-form-container .signup-card .react-tel-input {
          border: 2px solid #cdcdcc !important;
          min-height: 40px;
          background-color: #fff !important;
          border-radius: 4px !important; }
          .signup-container .signup .signup-form-container .signup-card .react-tel-input input {
            background: #fff !important; }
        .signup-container .signup .signup-form-container .signup-card .ant-card-body {
          padding: 1rem;
          width: 100%; }
          .signup-container .signup .signup-form-container .signup-card .ant-card-body .title {
            text-align: center;
            font-size: 1.5rem;
            margin-bottom: 0;
            color: #3a3543;
            font-weight: 500; }
          .signup-container .signup .signup-form-container .signup-card .ant-card-body .sub-title {
            text-align: center;
            font-size: 1.143rem;
            color: #000;
            font-weight: lighter; }
          .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form {
            padding-top: 1rem;
            width: 100%; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .flex {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              flex-wrap: wrap; }
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .flex .ant-form-item,
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .flex .form-button,
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .flex .disclaimer {
                width: 100% !important;
                margin-bottom: 0.36rem; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item {
              display: block;
              margin-top: 0.71rem;
              margin-bottom: 1.07rem; }
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input {
                border: 2px solid #cdcdcc;
                border-radius: 4px;
                min-height: 40px; }
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:hover,
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:active,
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus,
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item input:focus-within {
                border-color: #4d4d4d;
                box-shadow: 0 0 0 2px rgba(77, 77, 77, 0); }
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper {
                display: block;
                line-height: 22px !important;
                text-align: center; }
                .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .ant-form-item .ant-checkbox-wrapper span {
                  color: #4d4d4d;
                  font-size: 14px !important;
                  text-align: center !important;
                  font-weight: lighter; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .has-error input {
              border-color: #ff1c1c !important; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .label {
              font-size: 1rem;
              font-weight: lighter;
              color: #808080; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .checkbox-label {
              color: #868686;
              font-size: 12px;
              text-align: center;
              font-weight: 400;
              line-height: 22px !important;
              font-style: italic;
              margin-top: 12px;
              display: block; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer {
              font-weight: 500;
              font-size: 12px;
              text-align: center; }
              .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .disclaimer a {
                color: #ff806f !important; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .signup-form .form-button {
              font-size: 1.86rem;
              background: #00db6e;
              border-color: #00db6e;
              outline: none;
              padding: 16px 10%;
              text-transform: capitalize;
              height: 5.5rem;
              max-height: 100%;
              font-weight: 600;
              border-radius: 10px; }
          .signup-container .signup .signup-form-container .signup-card .ant-card-body .bottom-link {
            color: #868686;
            font-size: 0.86rem;
            font-weight: 500;
            text-align: center;
            margin: 1rem 0 0 0; }
            .signup-container .signup .signup-form-container .signup-card .ant-card-body .bottom-link a {
              color: #ff806f !important; }
      .signup-container .signup .signup-form-container .onboarding-footer {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translate(-50%, 0); } }

.signin-container-new {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#536be8, #3d2fd8, #3d2fd8, #6c48e2);
  position: relative; }
  .signin-container-new .mainCard-signup {
    z-index: 999;
    width: 864px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 6px 8px 48px rgba(0, 0, 0, 0.39);
    display: flex; }
  .signin-container-new .shadow-container {
    position: absolute;
    top: -247px;
    left: -63px; }

.left-card-containerS {
  width: 45%;
  background-color: red;
  border-radius: 8px 0 0 8px; }
  .left-card-containerS .Tabs-container {
    height: 100%; }
  .left-card-containerS .ant-carousel {
    background-color: green;
    height: 100%;
    border-radius: 8px 0 0 8px; }
    .left-card-containerS .ant-carousel .slick-list {
      height: 100%; }
      .left-card-containerS .ant-carousel .slick-list .slick-track {
        height: 100%; }
        .left-card-containerS .ant-carousel .slick-list .slick-track .slick-slide {
          height: 100%; }
        .left-card-containerS .ant-carousel .slick-list .slick-track .slick-slide:first-child {
          height: 100%; }
    .left-card-containerS .ant-carousel ul {
      bottom: 40px; }
      .left-card-containerS .ant-carousel ul li button {
        border-radius: 100%;
        border: 0.5px solid black;
        height: 15px;
        background-color: #FFFFFF;
        width: 15px;
        opacity: 1; }
      .left-card-containerS .ant-carousel ul li button:hover {
        opacity: 1; }
      .left-card-containerS .ant-carousel ul li.slick-active button {
        border-radius: 100%;
        border: 0.5px solid black;
        height: 15px;
        background-color: black;
        width: 15px; }
  .left-card-containerS .ant-carousel .slick-slide {
    border-radius: 8px 0 0 8px;
    text-align: center;
    background-color: yellow;
    overflow: hidden; }
  .left-card-containerS .ant-carousel div.slick-slide > div:first-child {
    height: 100%; }
  .left-card-containerS .ant-carousel .slick-slide h3 {
    color: #fff; }

@media screen and (max-width: 967px) {
  .left-card-containerS {
    display: none; } }

.tabFirstContainer {
  height: 100%;
  background-color: #fcc583;
  border-radius: 8px 0 0 8px; }
  .tabFirstContainer .logocontainer {
    padding: 40px 0 25px 0; }
  .tabFirstContainer .bar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 70px;
    grid-gap: 12px;
    gap: 12px; }
    .tabFirstContainer .bar-container .bar {
      width: 300px;
      height: 40px;
      border-radius: 24px;
      margin-left: 55px;
      background-color: #ffffff;
      font-size: 14px;
      padding-top: 3px;
      text-align: left;
      padding-left: 12px; }
      .tabFirstContainer .bar-container .bar .right-logo {
        position: relative;
        top: 6px;
        right: 4px; }
      .tabFirstContainer .bar-container .bar .data {
        display: inline-block;
        text-align: left; }

.tabSecondContainer {
  height: 100%;
  background-color: #fc9f83;
  border-radius: 8px 0 0 8px; }
  .tabSecondContainer .logocontainer {
    padding-top: 57px;
    padding-bottom: 40px; }
  .tabSecondContainer .dis {
    display: flex;
    justify-content: center;
    font-size: 14px;
    text-align: left;
    color: #212121; }
    .tabSecondContainer .dis .dis-container {
      width: 320px;
      padding: 24px 18px 29px 18px;
      background-color: #ffffff;
      border-radius: 10px; }
      .tabSecondContainer .dis .dis-container .top-data {
        margin-bottom: 16px; }
        .tabSecondContainer .dis .dis-container .top-data .para {
          font-weight: 600; }
      .tabSecondContainer .dis .dis-container .name {
        color: #352FBA;
        font-weight: 600;
        margin-bottom: 4px; }
      .tabSecondContainer .dis .dis-container .infodata {
        margin-bottom: 4px; }
      .tabSecondContainer .dis .dis-container .stat {
        font-weight: 600; }
        .tabSecondContainer .dis .dis-container .stat .greenArrowContainer {
          margin-right: 8px; }

.right-container-signup {
  flex: 1 1;
  padding-bottom: 47px !important; }
  .right-container-signup .ant-tabs-nav-scroll {
    display: none; }
  .right-container-signup .ant-tabs-bar {
    display: none; }

.right-card-signincontainer .rightlogocontainer {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  top: 3px;
  left: 6px; }

.right-card-signincontainer .facebookcontainer {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  top: 2px;
  left: 0; }

.right-card-signincontainer .googlecontainer {
  position: relative;
  margin-left: 10px;
  margin-right: 5px;
  top: 3px;
  left: 0; }

.right-card-signincontainer .error-phone {
  color: #f5222d;
  line-height: 1;
  padding-bottom: 6px; }

.right-card-signincontainer .heading-bar {
  padding-top: 58px;
  padding-left: 56px; }

.right-card-signincontainer .main-heading {
  font-size: 28px;
  font-weight: 600;
  font-family: "Martel", serif !important;
  color: #212121;
  padding-left: 90px; }

.right-card-signincontainer .sub-heading {
  font-size: 14px;
  font-weight: 400;
  color: #323041;
  padding-left: 84px; }

.right-card-signincontainer .flex-from {
  padding-left: 56px;
  padding-top: 24px; }
  .right-card-signincontainer .flex-from .ant-form-item {
    margin-bottom: 16px; }
  .right-card-signincontainer .flex-from .otp-info {
    font-size: 12px;
    color: #898989;
    font-style: italic;
    line-height: 1;
    margin-top: 6px; }
  .right-card-signincontainer .flex-from .input-signup23 {
    height: 40px !important;
    border-radius: 4px !important;
    border: 1px solid #898989;
    background-color: #f5f5f5;
    font-size: 14px;
    padding: 10px 0 10px 16px;
    width: 390px; }
  .right-card-signincontainer .flex-from .input-signup22 input {
    height: 40px !important;
    border-radius: 4px !important;
    border: 1px solid #898989;
    background-color: #f5f5f5;
    font-size: 14px;
    padding: 10px 0 10px 16px;
    width: 390px; }
  .right-card-signincontainer .flex-from .input-signup22 .ant-input-suffix {
    right: 68px; }
  .right-card-signincontainer .flex-from .phoneinput input {
    height: 40px !important;
    border-radius: 4px !important;
    border: 1px solid #898989 !important;
    background-color: #f5f5f5;
    font-size: 14px;
    padding: 10px 0 10px 16px;
    width: 390px !important;
    padding-left: 45px !important; }
  .right-card-signincontainer .flex-from .error-phone-input input:focus {
    border: 1px solid red !important; }
  .right-card-signincontainer .flex-from .forgetBtn {
    border: 0px solid;
    font-weight: 500;
    color: #0068ff;
    box-shadow: none;
    padding: 0; }
  .right-card-signincontainer .flex-from .alert-error {
    width: 390px;
    margin-bottom: 16px;
    margin-top: 16px; }
  .right-card-signincontainer .flex-from .submitbutton {
    background-color: #38b957;
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px;
    font-size: 14px;
    display: block;
    color: #ffffff;
    font-weight: 500;
    border: 0px solid transparent; }
  .right-card-signincontainer .flex-from .signup-line {
    margin-bottom: 12px; }
    .right-card-signincontainer .flex-from .signup-line .login-form-signup-here {
      margin-left: 129px; }
  .right-card-signincontainer .flex-from .facebookbutton {
    background-color: #2275c8;
    text-align: center;
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px !important;
    font-size: 14px;
    display: block;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 12px;
    border: 0px solid transparent; }
  .right-card-signincontainer .flex-from .signupbutton {
    border-radius: 2px !important;
    text-align: center;
    height: 40px !important;
    width: 390px !important;
    font-size: 14px;
    display: block;
    border: 1px solid #212121;
    font-weight: 500;
    color: #212121; }

.right-card-signincontainer .login-page-footer {
  font-weight: 500;
  margin-top: 50px; }
  .right-card-signincontainer .login-page-footer p {
    margin-bottom: 0px;
    color: #1a1a1a;
    padding-left: 165px; }
  .right-card-signincontainer .login-page-footer .contactus-line {
    text-align: center;
    width: 100%; }
  .right-card-signincontainer .login-page-footer .footer-links {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0px;
    padding-left: 128px; }
    .right-card-signincontainer .login-page-footer .footer-links li {
      display: inline-block;
      margin: 0 20px; }
      .right-card-signincontainer .login-page-footer .footer-links li a {
        color: #1a1a1a; }
      .right-card-signincontainer .login-page-footer .footer-links li a:hover {
        color: black; }

.forgotSection {
  color: #212121;
  min-height: 550px; }
  .forgotSection .heading-bar {
    padding-top: 58px;
    padding-left: 56px; }
  .forgotSection .main-heading {
    font-size: 28px;
    font-weight: 600;
    font-family: "Martel", serif !important;
    color: #212121;
    padding-left: 90px; }
  .forgotSection .sub-heading {
    font-size: 14px;
    font-weight: 400;
    color: #323041;
    padding-left: 84px; }
  .forgotSection .heading-reset {
    color: #0068ff;
    font-weight: 500;
    padding-top: 60px;
    padding-left: 175px; }
  .forgotSection .line-blue {
    background-color: #0068ff;
    height: 2px;
    width: 178px;
    margin-left: 159px;
    margin-top: 10px; }
  .forgotSection .flex-from {
    padding-left: 56px;
    padding-top: 24px; }
    .forgotSection .flex-from .input-signup23 {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 390px; }
  .forgotSection .alert {
    width: 390px;
    margin-bottom: 12px; }
  .forgotSection .change-emailbtn {
    background-color: #38b957;
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px !important;
    font-size: 14px;
    display: block;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 12px;
    border: 0px solid transparent; }
  .forgotSection .login-form-signup-here {
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px !important;
    font-size: 14px;
    display: block;
    font-weight: 500;
    margin-bottom: 12px; }
  .forgotSection .login-page-footer {
    font-weight: 500;
    margin-top: 50px; }
    .forgotSection .login-page-footer .copy-line {
      padding-left: 175px; }
    .forgotSection .login-page-footer .contactus-line {
      text-align: center;
      width: 100%; }
    .forgotSection .login-page-footer p {
      margin-bottom: 0px;
      color: #1a1a1a; }
    .forgotSection .login-page-footer .footer-links {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0px;
      padding-left: 138px; }
      .forgotSection .login-page-footer .footer-links li {
        display: inline-block;
        margin: 0 20px; }
        .forgotSection .login-page-footer .footer-links li a {
          color: #1a1a1a; }
        .forgotSection .login-page-footer .footer-links li a:hover {
          color: black; }

.loading-container {
  font-family: Poppins;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  background-color: #f0f3f7; }
  .loading-container .ant-skeleton.ant-skeleton-active {
    width: 70% !important;
    padding-top: 20px; }

.update-email-modal {
  text-align: center; }
  .update-email-modal .btn-green {
    background: #36c626;
    border-color: #36c626;
    color: white; }

.otp-verification-form {
  position: absolute;
  left: 35%;
  margin-top: 48px; }
  .otp-verification-form .ant-card {
    border-radius: 2px !important;
    width: 456px;
    margin: 0 auto;
    height: auto;
    margin-top: 40px; }
  .otp-verification-form .support-email-phone-modal .ant-modal-content .ant-modal-close {
    display: none !important; }
  .otp-verification-form .support-email-phone-modal .ant-modal-content .support-phone-number-txt {
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 22px;
    margin-bottom: 0px;
    color: #fc125e; }
  .otp-verification-form .support-email-phone-modal .ant-modal-content .number-verify-txt {
    font-size: 12px;
    color: #4b4b4b;
    line-height: 22px;
    letter-spacing: 0.032em;
    text-align: center; }
  .otp-verification-form .support-email-phone-modal .ant-modal-content .ant-modal-header {
    border-bottom: none !important;
    padding: 32px 24px !important; }
  .otp-verification-form .support-email-phone-modal .ant-modal-content .ant-modal-footer {
    border-top: none !important; }
  .otp-verification-form .support-email-phone-modal .otp-verify {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 24px; }
    .otp-verification-form .support-email-phone-modal .otp-verify .otp-number {
      width: 15%;
      border: none;
      background: #f5f5f5;
      height: 32px;
      text-align: center; }
  .otp-verification-form .support-email-phone-modal .otp-txt {
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 5px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989; }
  .otp-verification-form .support-email-phone-modal .resend-code {
    float: right;
    margin-top: -16px;
    font-size: 12px;
    letter-spacing: 0.032em; }
    .otp-verification-form .support-email-phone-modal .resend-code .resend-code-txt {
      color: #3273cc;
      text-decoration: underline !important;
      margin-right: 4px;
      background: transparent;
      border: none;
      cursor: pointer; }
    .otp-verification-form .support-email-phone-modal .resend-code .resend-code-txt2 {
      color: #98b9e5;
      text-decoration: underline !important;
      margin-right: -3px;
      background: transparent;
      border: none;
      pointer-events: none; }
  .otp-verification-form .support-email-phone-modal .header-support-txt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    margin-top: 56px; }
    .otp-verification-form .support-email-phone-modal .header-support-txt .change-number-txt {
      color: #fc125e;
      border: none;
      background: none;
      text-decoration: underline !important;
      cursor: pointer; }
    .otp-verification-form .support-email-phone-modal .header-support-txt .save-number-txt {
      color: #28af21;
      border: none;
      background: none;
      text-decoration: underline !important;
      cursor: pointer; }
  .otp-verification-form .support-email-phone-modal .edit-ican {
    margin-right: 3px; }
  .otp-verification-form .support-email-phone-modal .save-ican {
    margin-right: 3px; }
    .otp-verification-form .support-email-phone-modal .save-ican svg path {
      fill: #28af21; }
  .otp-verification-form .support-email-phone-modal .input-number-data {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .otp-verification-form .support-email-phone-modal .input-number-data .ind-91 {
      width: 20%;
      background: #f5f5f5;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-size: 12px; }
      .otp-verification-form .support-email-phone-modal .input-number-data .ind-91 .ant-select-selection {
        background: none;
        border: none;
        font-size: 12px;
        display: flex;
        justify-content: center;
        border-radius: 2px;
        color: #212121; }
      .otp-verification-form .support-email-phone-modal .input-number-data .ind-91 .ant-select-selection__rendered {
        line-height: 23px !important; }
      .otp-verification-form .support-email-phone-modal .input-number-data .ind-91 .ant-select-arrow {
        margin-top: -7px !important; }
    .otp-verification-form .support-email-phone-modal .input-number-data .input-email-txt {
      width: 100%; }
      .otp-verification-form .support-email-phone-modal .input-number-data .input-email-txt input {
        background: #f5f5f5;
        border: none;
        border-radius: 0px;
        color: #212121;
        letter-spacing: 0.032em;
        line-height: 20px; }
    .otp-verification-form .support-email-phone-modal .input-number-data .input-number-txt {
      width: 100%; }
      .otp-verification-form .support-email-phone-modal .input-number-data .input-number-txt input {
        background: #f5f5f5;
        border: none;
        border-radius: 0px;
        color: #212121;
        letter-spacing: 0.032em;
        line-height: 20px; }
  .otp-verification-form .support-email-phone-modal .verify-continue-btn {
    width: 408px;
    margin-top: 8px;
    border: none;
    box-shadow: none;
    background: #2bc822;
    border-radius: 2px;
    color: #fff;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px; }
  .otp-verification-form .support-email-phone-modal .cancel-btn {
    width: 408px;
    margin-top: 8px;
    border: 1px solid #898989;
    box-shadow: none;
    border-radius: 2px;
    color: #898989;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px; }
  .otp-verification-form .support-email-phone-modal .otp-number-txt {
    font-size: 12px;
    font-style: italic;
    color: #898989;
    margin-top: 4px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .otp-verification-form .support-email-phone-modal .ant-input {
    font-size: 12px !important; }
  .otp-verification-form .support-email-phone-modal .disable-phonenumber {
    background: #f5f5f5;
    font-size: 12px;
    height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: 0.032em;
    color: #212121;
    padding-left: 16px; }
  .otp-verification-form .header-txt .account-txt {
    text-align: center;
    color: #fc125e;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 20px;
    margin-bottom: 4px; }
  .otp-verification-form .header-txt .verify-emai-mobile-txt {
    text-align: center;
    margin-bottom: -40px;
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #4b4b4b;
    font-weight: 400; }
  .otp-verification-form .verify-continue-btn {
    width: 408px;
    margin-top: 8px;
    border: none;
    box-shadow: none;
    background: #2bc822 !important;
    border-radius: 2px;
    color: #fff;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px;
    margin-top: 16px; }
  .otp-verification-form .verify-continue-btn2 {
    width: 408px;
    margin-top: 8px;
    border: none;
    box-shadow: none;
    background: #94e38f !important;
    border-radius: 2px;
    color: #fff;
    letter-spacing: 0.032em;
    height: 40px;
    line-height: 16px;
    pointer-events: none; }

.verify-continue-btn:hover {
  background: #2bc822 !important;
  color: #fff !important; }

.verify-continue-btn2:hover {
  background: #94e38f !important;
  color: #fff !important; }

.onboarding-header {
  text-align: center; }
  .onboarding-header .title {
    font-size: 24px;
    color: #ff005e;
    margin: 0; }
  .onboarding-header .subtitle {
    font-size: 16px;
    color: #4b4b4b; }

.form-container-card {
  box-shadow: 2px 2px 50px #cacaca !important; }

.green {
  border: 1px solid green; }

.red {
  border: 1px solid red; }

.onboarding-header .title {
  margin-bottom: 5px; }

.onboarding-header .subtitle {
  font-size: 16px !important;
  line-height: 22px; }

.questions-form-step1 {
  overflow-x: auto !important;
  min-height: 30vh !important;
  max-height: 59vh;
  padding-right: 25px;
  margin-bottom: 8px;
  text-align: left; }
  .questions-form-step1 .op {
    font-size: 12px !important; }
  .questions-form-step1 .opt {
    font-size: 12px !important; }
  .questions-form-step1 p {
    font-size: 12px;
    color: #000000;
    margin-bottom: 6px;
    font-weight: 400; }
  .questions-form-step1 input {
    height: 32px;
    background: #f5f5f5;
    border: none;
    width: 100%;
    font-size: 12px; }
  .questions-form-step1 input:focus {
    outline: none !important; }
  .questions-form-step1 .ant-radio-group {
    width: 100%; }
    .questions-form-step1 .ant-radio-group .radiobutton_data {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .questions-form-step1 .ant-radio-group .radiobutton_data div {
        width: 48%;
        background-color: #efefef;
        height: 32px;
        display: flex;
        align-items: center; }
        .questions-form-step1 .ant-radio-group .radiobutton_data div label {
          padding-left: 10px; }
  .questions-form-step1 .form-input .dd {
    margin-bottom: 20px; }
    .questions-form-step1 .form-input .dd input {
      padding-left: 10px; }
  .questions-form-step1 .form-input .nn {
    margin-bottom: 20px; }
  .questions-form-step1 .select-tab {
    background: #efefef;
    color: #4b4b4b;
    width: 100%;
    padding: 5px 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border: 1px solid transparent; }
  .questions-form-step1 .select-tab.active {
    background: #e6f4ff;
    border: 1px solid #2d95ef; }
  .questions-form-step1 .questions-form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px; }
    .questions-form-step1 .questions-form-footer .step-count {
      color: #9b9b9b; }
  .questions-form-step1 .form-input input {
    height: 32px;
    background: #f5f5f5;
    border: none;
    width: 100%; }
  .questions-form-step1 .ant-select-selection--single {
    position: relative;
    height: 32px;
    cursor: pointer;
    border-radius: 0;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px; }

.iti {
  position: relative;
  display: inline-block;
  width: 100%; }

.flna {
  display: flex;
  justify-content: space-between; }

.questions-form-step1::-webkit-scrollbar {
  width: 5px; }

.questions-form-step1::-webkit-scrollbar-thumb {
  background: #888; }

.questions-form-step1::-webkit-scrollbar-track {
  background: #f1f1f1; }

.ant-select-selection__placeholder {
  font-size: 12px !important; }

.react-tel-input .flag-dropdown {
  border: none !important; }

.react-tel-input .form-control {
  background: #f5f5f5 !important;
  border: none !important;
  width: 100% !important; }

.selected-flag:hover {
  background: #f5f5f5 !important; }

.custom-phn {
  border: 1px solid red !important; }

.next {
  margin-top: 0px !important;
  background: #3eca2a;
  border-color: #3eca2a;
  color: white;
  width: 200px;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.032em;
  font-weight: 500;
  height: 40px; }

.skip {
  margin-top: 0px !important;
  border-color: #898989;
  color: gray;
  width: 100px;
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.032em;
  font-weight: 500;
  height: 40px;
  margin-right: 16px; }

.progressbar_data {
  display: flex;
  justify-content: center;
  align-items: center; }
  .progressbar_data .progressbar_data2 .line-container {
    margin-top: 10px;
    display: flex;
    width: 256px;
    margin-bottom: 16px; }
    .progressbar_data .progressbar_data2 .line-container .progress-line {
      position: relative;
      border-radius: 10px;
      width: 100%;
      background: #cecece;
      height: 8px;
      z-index: 90;
      display: flex;
      justify-content: space-between; }
      .progressbar_data .progressbar_data2 .line-container .progress-line .progress {
        position: absolute;
        width: 75%;
        height: 100%;
        background: #2bc822;
        transition: width 1s ease-in-out; }
    .progressbar_data .progressbar_data2 .line-container .status {
      position: relative;
      top: -4px; }
      .progressbar_data .progressbar_data2 .line-container .status .dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #cecece;
        cursor: pointer;
        margin-left: -1px; }
        .progressbar_data .progressbar_data2 .line-container .status .dot .current {
          background: white;
          border: 5px solid orange; }
        .progressbar_data .progressbar_data2 .line-container .status .dot.completed {
          position: relative;
          background: #2bc822;
          border: 5px solid #2bc822; }
      .progressbar_data .progressbar_data2 .line-container .status .dot.dot-active {
        outline: 4px solid #b8f0b3;
        animation: pulse-black 2s infinite; }

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #2ac82260; }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

@keyframes load-10 {
  0% {
    width: 0; }
  100% {
    width: 10%; } }

@keyframes load-58 {
  0% {
    width: 0; }
  100% {
    width: 58%; } }

@keyframes load-100 {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.questionScreen2-wraper {
  padding-bottom: 60px; }

.questionScreen2 {
  width: 700px;
  margin: 100px auto 0;
  padding: 30px px; }
  .questionScreen2 .questionScreen2-header-txt .questionScreen2-account-txt {
    font-size: 22px;
    letter-spacing: .032em;
    line-height: 28px;
    align-items: center;
    font-weight: 500;
    color: #fc125e;
    text-align: center; }
  .questionScreen2 .questionScreen2-header-txt .step1 {
    font-size: 12px;
    letter-spacing: .032em;
    line-height: 22px;
    align-items: center;
    font-weight: 400;
    text-align: center;
    color: #4b4b4b;
    margin-top: 5px; }
  .questionScreen2 .question-following-txt {
    font-size: 16px;
    color: #000;
    letter-spacing: .032em;
    line-height: 16px;
    margin-top: 16px;
    margin-bottom: 20px; }
  .questionScreen2 .step-list1 ul {
    list-style-type: none;
    padding: 0; }
    .questionScreen2 .step-list1 ul li {
      background: #f5f5f5;
      margin-bottom: 8px;
      letter-spacing: 0.032em;
      color: #212121;
      font-size: 12px; }
      .questionScreen2 .step-list1 ul li label {
        display: block;
        padding: 8px; }
      .questionScreen2 .step-list1 ul li .step1-list-checkbox {
        margin-right: 8px;
        color: #212121;
        font-size: 13px;
        line-height: 19px; }
  .questionScreen2 .step-list2 ul {
    list-style-type: none;
    padding: 0; }
    .questionScreen2 .step-list2 ul .questionScreenStep2 {
      background: #f5f5f5;
      margin-bottom: 8px;
      letter-spacing: 0.032em;
      color: #212121;
      font-size: 12px;
      justify-content: center;
      align-items: center;
      width: 100%; }
      .questionScreen2 .step-list2 ul .questionScreenStep2 .ant-radio-wrapper {
        white-space: initial !important;
        color: #212121;
        font-size: 13px;
        padding: 8px;
        display: flex;
        align-items: center;
        line-height: 20px; }
      .questionScreen2 .step-list2 ul .questionScreenStep2 .step2-list-checkbox {
        margin-right: 8px; }
      .questionScreen2 .step-list2 ul .questionScreenStep2 .step2-text {
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.032em; }
      .questionScreen2 .step-list2 ul .questionScreenStep2 .ant-checkbox-wrapper + span,
      .questionScreen2 .step-list2 ul .questionScreenStep2 .ant-checkbox + span {
        padding-right: 0px !important;
        padding-left: 0px !important; }
  .questionScreen2 .step-list3 {
    margin-top: 10px; }
    .questionScreen2 .step-list3 ul {
      list-style-type: none;
      padding: 0; }
      .questionScreen2 .step-list3 ul .questionScreenStep3 {
        background: #f5f5f5;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        color: #212121;
        font-size: 12px; }
        .questionScreen2 .step-list3 ul .questionScreenStep3 label {
          color: #212121;
          font-size: 12px;
          padding: 8px;
          width: 100%; }
        .questionScreen2 .step-list3 ul .questionScreenStep3 .step3-list-checkbox .option-value {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          margin-left: 4px; }
        .questionScreen2 .step-list3 ul .questionScreenStep3 .step3-list-checkbox .option-value-radio-btn {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          margin-left: -4px; }
        .questionScreen2 .step-list3 ul .questionScreenStep3 .step3-text {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.032em; }
        .questionScreen2 .step-list3 ul .questionScreenStep3 .ant-checkbox-wrapper + span,
        .questionScreen2 .step-list3 ul .questionScreenStep3 .ant-checkbox + span {
          padding-right: 0px !important;
          padding-left: 0px !important; }
    .questionScreen2 .step-list3 .multiselect-doublecolumn-list {
      list-style-type: none;
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .questionScreen2 .step-list3 .multiselect-doublecolumn-list .questionScreenStep3 {
        background: #f5f5f5;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        color: #212121;
        font-size: 12px;
        width: 48%; }
        .questionScreen2 .step-list3 .multiselect-doublecolumn-list .questionScreenStep3 .step3-list-checkbox .option-value {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          margin-left: 8p; }
        .questionScreen2 .step-list3 .multiselect-doublecolumn-list .questionScreenStep3 .step3-text {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.032em; }
        .questionScreen2 .step-list3 .multiselect-doublecolumn-list .questionScreenStep3 .ant-checkbox-wrapper + span,
        .questionScreen2 .step-list3 .multiselect-doublecolumn-list .questionScreenStep3 .ant-checkbox + span {
          padding-right: 0px !important;
          padding-left: 0px !important; }
      .questionScreen2 .step-list3 .multiselect-doublecolumn-list .questionScreenStep3:last-child {
        width: 100%; }
    .questionScreen2 .step-list3 .singleselect-doublecolumn-list {
      list-style-type: none;
      padding: 0;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .questionScreen2 .step-list3 .singleselect-doublecolumn-list .questionScreenStep3 {
        background: #f5f5f5;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        color: #212121;
        font-size: 12px;
        width: 48%; }
        .questionScreen2 .step-list3 .singleselect-doublecolumn-list .questionScreenStep3 .step3-list-checkbox .option-value {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          margin-left: 8p; }
        .questionScreen2 .step-list3 .singleselect-doublecolumn-list .questionScreenStep3 .step3-text {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.032em; }
        .questionScreen2 .step-list3 .singleselect-doublecolumn-list .questionScreenStep3 .ant-checkbox-wrapper + span,
        .questionScreen2 .step-list3 .singleselect-doublecolumn-list .questionScreenStep3 .ant-checkbox + span {
          padding-right: 0px !important;
          padding-left: 0px !important; }
    .questionScreen2 .step-list3 .widthquestionScreenStep3 {
      width: 100% !important;
      margin: 0px !important; }
    .questionScreen2 .step-list3 .other-select-niche {
      background: #f5f5f5;
      padding: 10px;
      padding-top: 0;
      margin-bottom: 0;
      width: 100% !important; }
      .questionScreen2 .step-list3 .other-select-niche .selectNiche {
        background: #fff;
        border: none;
        border-radius: 0px;
        font-size: 12px;
        letter-spacing: 0.032em; }
  .questionScreen2 .continue-btn {
    width: 100%;
    height: 40px;
    color: #fff;
    background: #94e390;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500;
    pointer-events: none; }
  .questionScreen2 .continue-btn2 {
    width: 100%;
    height: 40px;
    color: #fff;
    background: #3eca2a;
    border: none;
    border-radius: 2px;
    font-size: 16px;
    font-weight: 500; }
  .questionScreen2 .header-question-txt {
    font-size: 15px;
    margin-bottom: 5px;
    letter-spacing: .032em;
    line-height: 18px;
    color: #898989;
    margin-bottom: 10px;
    margin-top: 20px;
    font-weight: 600;
    color: #463f3f; }
  .questionScreen2 .input-type-number {
    margin-bottom: 16px; }
    .questionScreen2 .input-type-number .question-number-input input {
      background: #f5f5f5;
      border: none;
      border-radius: 0px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .questionScreen2 .input-type-number .question-number-input .ant-input-group-addon {
      border: none !important;
      background: #f5f5f5f5 !important;
      border-radius: 0px !important; }
  .questionScreen2 .input-type-text {
    margin-bottom: 16px; }
    .questionScreen2 .input-type-text .question-text-input {
      background: #f5f5f5;
      border: none;
      border-radius: 0px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
  .questionScreen2 .step3-number {
    margin-right: 5px; }
  .questionScreen2 .mandatory-validation {
    color: #c62929;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .questionScreen2 .error-red {
    color: #c62929; }
  .questionScreen2 .ant-radio-checked .ant-radio-inner {
    border-color: #46ce3f; }
  .questionScreen2 .ant-radio-inner::after {
    background-color: #2bc823; }
  .questionScreen2 .ant-radio-wrapper:hover .ant-radio,
  .questionScreen2 .ant-radio:hover .ant-radio-inner,
  .questionScreen2 .ant-radio-input:focus + .ant-radio-inner {
    border-color: #46ce3f; }
  .questionScreen2 .communication-mode {
    width: 100%; }
    .questionScreen2 .communication-mode .ant-select-selection {
      background-color: #f5f5f5;
      border: none;
      border-top-width: 1.02px;
      border-radius: 0px; }
    .questionScreen2 .communication-mode .ant-select-selection-selected-value {
      font-size: 12px;
      letter-spacing: 0.032em;
      color: #212121;
      font-weight: 400; }
  .questionScreen2 .react-tel-input .form-control {
    position: relative;
    font-size: 12px;
    letter-spacing: 0.01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 5px;
    line-height: 25px;
    height: 32px;
    width: 300px;
    outline: none; }
  .questionScreen2 .react-tel-input .form-control {
    background: #f5f5f5 !important;
    border: none !important;
    width: 100% !important;
    border-radius: 0px; }
  .questionScreen2 .dd-communication-mode {
    margin-bottom: 16px; }
    .questionScreen2 .dd-communication-mode .input-communication-mode {
      width: 100%;
      height: 32px;
      border: none;
      background: #f5f5f5;
      font-size: 12px;
      padding-left: 8px;
      letter-spacing: 0.032em;
      line-height: 16px; }
  .questionScreen2 .react-tel-input .flag-dropdown.open .selected-flag {
    background: #f5f5f5f5;
    border-radius: 3px 0 0 0; }

.communication-mode-option {
  border-bottom: 1px solid #cecece !important;
  padding: 4px 8px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 32px; }
  .communication-mode-option .anticon svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    padding-top: 2px; }

.communication-mode-option:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 2px solid #b5dafe !important;
  border-radius: 0px !important; }

.onboarding-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: row;
  background: url(/static/media/onboarding_bg.8b0debec.svg) no-repeat;
  background-size: cover; }
  .onboarding-container .form-container-card {
    margin: auto;
    padding: 0px 26px 26px 26px;
    text-align: center;
    background-position: bottom right;
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 456px;
    margin-top: 7%;
    border-radius: 2px !important; }
    .onboarding-container .form-container-card .illustartion-img {
      margin: 16px 0px;
      width: 112px;
      height: 112px; }
    .onboarding-container .form-container-card .header-txt {
      font-size: 16px;
      letter-spacing: 0.032em;
      font-weight: 500;
      line-height: 22px;
      color: #fc125e;
      margin-bottom: 8px; }
    .onboarding-container .form-container-card .header-subtitle {
      font-size: 12px;
      line-height: 18px;
      color: #4b4b4b; }
    .onboarding-container .form-container-card .myaccount-btn {
      margin-top: 24px;
      width: 368px !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px; }
    .onboarding-container .form-container-card .anticon {
      margin-top: 8px;
      margin-left: 4px !important; }
  .onboarding-container .ant-form-item {
    margin-bottom: 15px; }
  .onboarding-container .btn-green {
    background: #3eca2a;
    border-color: #3eca2a;
    color: white;
    width: 200px;
    font-size: 12px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.032em;
    font-weight: 500;
    height: 40px;
    margin-top: 8px;
    color: #fff; }
  .onboarding-container .btn-green:active {
    background: #3eca2a; }
  .onboarding-container .btn-green:hover {
    background-color: #24a51d; }
  .onboarding-container .onboarding {
    flex: 1 1;
    max-width: 100%;
    height: 100%;
    margin: auto;
    padding: 2.5vw 15px 0 15px; }
    .onboarding-container .onboarding .logo {
      margin-bottom: 18px;
      max-width: 200px; }
    .onboarding-container .onboarding .tagline {
      color: rgba(0, 0, 0, 0.45);
      margin: 0 0 42px 0; }
    .onboarding-container .onboarding .ant-tabs-bar {
      border: 0;
      text-align: center; }
    .onboarding-container .onboarding .login-form-forgot,
    .onboarding-container .onboarding .login-form-signup-here {
      float: right; }
    .onboarding-container .onboarding .ant-alert {
      margin-bottom: 15px; }
    .onboarding-container .onboarding .login-form-button {
      width: 100%;
      margin: 0 0;
      box-shadow: 0px 1px 2px rgba(0, 104, 255, 0.59); }
    .onboarding-container .onboarding .register-tag-line {
      margin: 15px 0 10px 0; }
    .onboarding-container .onboarding .login-page-footer {
      position: -webkit-sticky;
      position: sticky;
      top: calc(100% - 55px); }
      .onboarding-container .onboarding .login-page-footer .footer-links {
        list-style-type: none;
        padding: 0; }
        .onboarding-container .onboarding .login-page-footer .footer-links li {
          display: inline-block;
          margin: 0 20px; }
          .onboarding-container .onboarding .login-page-footer .footer-links li a {
            color: rgba(0, 0, 0, 0.45); }
          .onboarding-container .onboarding .login-page-footer .footer-links li a:hover {
            color: black; }
  .onboarding-container .onboarding-sidebar {
    width: 327px;
    background: url(/static/media/onboarding-sidebar-bg.c146e064.svg) #16273b;
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 32px 40px;
    display: flex;
    flex-direction: column; }
    .onboarding-container .onboarding-sidebar a {
      color: rgba(255, 255, 255, 0.4);
      font-size: 15px; }
    .onboarding-container .onboarding-sidebar a:hover {
      color: white; }
    .onboarding-container .onboarding-sidebar .steps {
      display: flex;
      flex: 1 1;
      align-items: center; }
      .onboarding-container .onboarding-sidebar .steps ul {
        list-style-type: none;
        margin: 0;
        position: relative; }
        .onboarding-container .onboarding-sidebar .steps ul li {
          color: #737d89;
          font-size: 15px;
          position: relative;
          margin: 10px 0; }
        .onboarding-container .onboarding-sidebar .steps ul li:before {
          content: "";
          position: absolute;
          left: -34px;
          top: 4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #737d89; }
        .onboarding-container .onboarding-sidebar .steps ul li.active {
          color: white; }
        .onboarding-container .onboarding-sidebar .steps ul li.active:after {
          content: "";
          position: absolute;
          left: -31px;
          top: 7px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #16273b; }
        .onboarding-container .onboarding-sidebar .steps ul li.completed:after {
          content: "";
          position: absolute;
          left: -31px;
          top: 7px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #36c626; }
        .onboarding-container .onboarding-sidebar .steps ul li.active:before {
          background: white; }
      .onboarding-container .onboarding-sidebar .steps ul:before {
        content: "";
        position: absolute;
        top: 22px;
        left: 12px;
        width: 4px;
        height: calc(100% - 42px);
        background: #737d89; }
    .onboarding-container .onboarding-sidebar .testimonial {
      color: white;
      text-align: center;
      font-size: 14px; }
      .onboarding-container .onboarding-sidebar .testimonial .testimonial-user {
        display: flex;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
        align-items: center; }
        .onboarding-container .onboarding-sidebar .testimonial .testimonial-user img {
          width: 50px; }
        .onboarding-container .onboarding-sidebar .testimonial .testimonial-user p {
          flex: 1 1;
          margin: 0 0 0 10px;
          text-align: left; }

.signup-container-new1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#536be8, #3d2fd8, #3d2fd8, #6c48e2);
  position: relative; }
  .signup-container-new1 .mainCard-signup {
    z-index: 999;
    width: 864px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 6px 8px 48px rgba(0, 0, 0, 0.39);
    display: flex; }
  .signup-container-new1 .shadow-container {
    position: absolute;
    top: -247px;
    left: -63px; }

.left-card-container1 {
  width: 42%;
  background-color: red;
  border-radius: 8px 0 0 8px; }
  .left-card-container1 .Tabs-container {
    height: 100%; }
  .left-card-container1 .ant-carousel {
    background-color: green;
    height: 100%;
    border-radius: 8px 0 0 8px; }
    .left-card-container1 .ant-carousel .slick-list {
      height: 100%; }
      .left-card-container1 .ant-carousel .slick-list .slick-track {
        height: 100%; }
        .left-card-container1 .ant-carousel .slick-list .slick-track .slick-slide {
          height: 100%; }
        .left-card-container1 .ant-carousel .slick-list .slick-track .slick-slide:first-child {
          height: 100%; }
    .left-card-container1 .ant-carousel ul {
      bottom: 40px; }
      .left-card-container1 .ant-carousel ul li button {
        border-radius: 100%;
        border: 0.5px solid black;
        height: 15px;
        background-color: #FFFFFF;
        width: 15px;
        opacity: 1; }
      .left-card-container1 .ant-carousel ul li button:hover {
        opacity: 1; }
      .left-card-container1 .ant-carousel ul li.slick-active button {
        border-radius: 100%;
        border: 0.5px solid black;
        height: 15px;
        background-color: black;
        width: 15px; }
  .left-card-container1 .ant-carousel .slick-slide {
    border-radius: 8px 0 0 8px;
    text-align: center;
    background-color: yellow;
    overflow: hidden; }
  .left-card-container1 .ant-carousel div.slick-slide > div:first-child {
    height: 100%; }
  .left-card-container1 .ant-carousel .slick-slide h3 {
    color: #fff; }


.tabSecondContainer {
  height: 100%;
  background-color: #fc9f83;
  border-radius: 8px 0 0 8px; }
  .tabSecondContainer .logocontainer {
    padding-top: 57px;
    padding-bottom: 40px; }
  .tabSecondContainer .dis {
    display: flex;
    justify-content: center;
    font-size: 14px;
    text-align: left;
    color: #212121; }
    .tabSecondContainer .dis .dis-container {
      width: 320px;
      padding: 24px 18px 29px 18px;
      background-color: #ffffff;
      border-radius: 10px; }
      .tabSecondContainer .dis .dis-container .top-data {
        margin-bottom: 16px; }
        .tabSecondContainer .dis .dis-container .top-data .para {
          font-weight: 600; }
      .tabSecondContainer .dis .dis-container .name {
        color: #352FBA;
        font-weight: 600;
        margin-bottom: 4px; }
      .tabSecondContainer .dis .dis-container .infodata {
        margin-bottom: 4px; }
      .tabSecondContainer .dis .dis-container .stat {
        font-weight: 600; }
        .tabSecondContainer .dis .dis-container .stat .greenArrowContainer {
          margin-right: 8px; }

.right-container-signup1 {
  flex: 1 1;
  padding-bottom: 47px !important; }
  .right-container-signup1 .ant-tabs-nav-scroll {
    display: none; }
  .right-container-signup1 .ant-tabs-bar {
    display: none; }

.otpVerifyContaner1 {
  color: #212121;
  min-height: 550px; }
  .otpVerifyContaner1 .error-text {
    color: red;
    font-size: 12px; }
  .otpVerifyContaner1 .heading-bar {
    padding-top: 58px;
    padding-left: 42.4px; }
  .otpVerifyContaner1 .main-heading {
    font-size: 28px;
    font-weight: 600;
    font-family: "Martel", serif !important;
    color: #212121; }
  .otpVerifyContaner1 .sub-heading {
    font-size: 14px;
    font-weight: 400;
    color: #323041; }
  .otpVerifyContaner1 .email-section {
    padding-top: 24px;
    padding-left: 42.4px;
    padding-bottom: 16px; }
    .otpVerifyContaner1 .email-section .input-email {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 390px; }
    .otpVerifyContaner1 .email-section .edit {
      margin-left: 4px; }
      .otpVerifyContaner1 .email-section .edit .editiconContainer {
        position: relative;
        top: 2px; }
      .otpVerifyContaner1 .email-section .edit .edit-s {
        text-decoration: underline;
        color: #fc125e; }
    .otpVerifyContaner1 .email-section .otp-info {
      font-size: 12px;
      font-style: italic;
      color: #898989; }
  .otpVerifyContaner1 .otp-section {
    padding-left: 42.4px; }
    .otpVerifyContaner1 .otp-section .otp-verify .otp-number {
      width: 56px;
      height: 40px;
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      margin-right: 6px;
      padding-left: 25px;
      border: 1px solid #898989; }
    .otpVerifyContaner1 .otp-section .otp-verify .otp-number:focus {
      border: 1px solid #3a2ccd; }
    .otpVerifyContaner1 .otp-section .msg-otp {
      font-size: 12px;
      font-style: italic;
      color: #898989;
      margin-top: 6px;
      margin-bottom: 4px; }
    .otpVerifyContaner1 .otp-section .resend {
      border: 0;
      background-color: #ffffff;
      text-decoration: underline;
      color: #3b2dd1;
      cursor: pointer; }
    .otpVerifyContaner1 .otp-section .disableresend {
      text-decoration: underline;
      opacity: 0.6;
      pointer-events: none; }
    .otpVerifyContaner1 .otp-section .time-container {
      color: #898989; }
    .otpVerifyContaner1 .otp-section .submit-section {
      padding-top: 199px; }
      .otpVerifyContaner1 .otp-section .submit-section .verify-container {
        background-color: #38b957;
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px !important;
        font-size: 14px;
        display: block;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 12px;
        border: 0px solid transparent; }
      .otpVerifyContaner1 .otp-section .submit-section .disable-verify-container {
        background-color: #38b957;
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px !important;
        font-size: 14px;
        display: block;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 12px;
        border: 0px solid transparent;
        opacity: 0.6; }
      .otpVerifyContaner1 .otp-section .submit-section .go-back-btn {
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px !important;
        font-size: 14px;
        display: block;
        color: #898989;
        border: 1px solid #898989; }
      .otpVerifyContaner1 .otp-section .submit-section .rightlogocontainer {
        position: relative;
        margin-left: 0;
        margin-right: 0;
        top: 3px;
        left: 6px; }
  .otpVerifyContaner1 .change-btn-container .change-emailbtn {
    background-color: #38b957;
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px !important;
    font-size: 14px;
    display: block;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 12px;
    border: 0px solid transparent; }
  .otpVerifyContaner1 .ant-btn[disabled] {
    opacity: 0.6; }

.onboarding-header {
  text-align: center; }
  .onboarding-header .title {
    font-size: 24px;
    color: #ff005e;
    margin: 0; }
  .onboarding-header .subtitle {
    font-size: 18px;
    color: #4b4b4b; }

.woCommerce_detail_input .common_label {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #898989;
  margin-bottom: 4px; }

.woCommerce_detail_input .upeer_label_margin {
  margin-right: 6px;
  margin-bottom: 16px; }

.woCommerce_detail_input .shop_name {
  width: 100%;
  border-radius: 0px;
  background: #f5f5f5;
  border: none;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #212121; }

.woCommerce_detail_input .shop_url {
  width: 100%;
  border-radius: 0px;
  background: #f5f5f5;
  border: none;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #212121; }

.woCommerce_detail_input .shop_consumer_key {
  width: 100%;
  border-radius: 0px;
  background: #f5f5f5;
  border: none;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #212121; }

.woCommerce_detail_input .shop_consumer_secret {
  width: 100%;
  border-radius: 0px;
  background: #f5f5f5;
  border: none;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #212121; }

.woCommerce_detail_input .clickable_btn {
  background: #36c626;
  border-color: #36c626;
  color: white;
  width: 512px;
  border-radius: 2px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 8px;
  margin-bottom: 24px;
  height: 40px; }

.woCommerce_detail_input .skip-step {
  border: 1px solid #898989;
  margin-top: -16px;
  margin-bottom: 16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }
  .woCommerce_detail_input .skip-step .onboarding-skip {
    width: 512px;
    border: none;
    background: transparent;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }

.woCommerce_detail_input .shopify-connect-title {
  font-size: 16px;
  color: #ff005e;
  margin: 0;
  letter-spacing: 0.032em;
  line-height: 22px; }

.woCommerce_detail_input .shopify-connect-subtitle {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 20px;
  margin-bottom: 16px; }

.woCommerce_detail_input .star {
  color: #c62929; }

.onboarding-field {
  text-align: left;
  display: flex;
  flex-direction: column-reverse;
  position: relative; }
  .onboarding-field label {
    display: inline-block;
    transform: translateX(35px) scale(1.1);
    transition-duration: 0.3s;
    margin-bottom: 10px; }
  .onboarding-field .input-addon {
    position: absolute;
    right: 1px;
    bottom: 1px;
    background: #efefef;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 12px; }
  .onboarding-field .input-field {
    border-radius: 2px; }
  .onboarding-field .shopify-field:after {
    content: ".myshopify.com"; }
  .onboarding-field .input-field:focus + label {
    transform: translate(0, 3px); }
  .onboarding-field .input-field:focus,
  .onboarding-field .input-field:hover {
    box-shadow: none;
    border: 1px solid black; }

.shopify-connect .content-container {
  width: 100%;
  max-width: 450px;
  margin: auto;
  text-align: center; }

.shopify-connect .skip {
  cursor: pointer;
  color: #4b4b4b;
  width: 510px;
  border: 1px solid black;
  border-radius: 2px;
  background: none;
  padding: 3px;
  margin-bottom: 30px;
  font-weight: 400; }

.shopify-connect .shopify-connect-title {
  font-size: 16px;
  color: #ff005e;
  margin: 0;
  letter-spacing: 0.032em;
  line-height: 22px; }

.shopify-connect .shopify-connect-subtitle {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 20px; }

.shopify-connect .shopify-connect-img {
  margin: 48px 0; }

.shopify-connect .shopify-connect-label {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 20px; }

.shopify-connect .onboarding-field {
  text-align: left;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin-bottom: 10px; }

.shopify-connect .onboarding-field .input-addon {
  position: absolute;
  right: 1px;
  bottom: 1px;
  background: none;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  font-size: 12px;
  color: #212121;
  letter-spacing: 0.032em;
  line-height: 20px; }

.shopify-connect .onboarding-field label {
  margin-bottom: 4px;
  margin-left: 4px;
  color: #898989;
  font-weight: 400;
  transform: none !important; }

.shopify-connect .shopify-connect-btn {
  background: #36c626;
  border-color: #36c626;
  color: white;
  width: 510px;
  border-radius: 2px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 16px;
  margin-bottom: 8px;
  height: 40px; }

.kyc-individual-organisation .checkbox-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -22px; }
  .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card {
    height: 216px;
    width: 240px;
    border: 3px solid #e3e3e3;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    cursor: pointer; }
    .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .flag {
      font-size: 12px;
      color: #000000;
      letter-spacing: 0.032em;
      line-height: 18px;
      padding: 16px;
      font-weight: 400; }
      .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .flag .registered-india {
        text-decoration: underline;
        font-weight: 500; }
      .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .flag .not-registered-india {
        text-decoration: underline;
        font-weight: 500; }
    .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .footer {
      flex: unset;
      width: 240px;
      font-size: 12px;
      color: white;
      padding: 8px 0;
      background: #36c626;
      position: relative;
      left: -3px;
      top: 12px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .anticon {
      margin: auto;
      margin-top: 24px; }
      .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .anticon svg {
        width: 80px;
        height: 80px; }
  .kyc-individual-organisation .checkbox-card .individual-card {
    position: relative;
    margin-right: 32px;
    margin-top: 56px; }
  .kyc-individual-organisation .checkbox-card .organisation-card {
    position: relative;
    margin-top: 56px; }
  .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card:hover {
    border: 3px solid #30c638; }
    .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card:hover .registered-india {
      font-weight: 600 !important; }
    .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card:hover .not-registered-india {
      font-weight: 600 !important; }

.kyc-individual-organisation .skip-now {
  margin-top: 64px; }

.kyc-individual-organisation .skip-now a {
  cursor: pointer;
  margin-top: 24px;
  width: 120px;
  height: 40px;
  border: 1px solid black;
  border-radius: 2px;
  background: none;
  font-size: 12px;
  color: #4b4b4b;
  padding: 8px; }

.kyc-individual-organisation .skip-now a:hover {
  background: #0031ff;
  color: white;
  border: none; }

.kyc-individual-organisation .back-btn {
  margin-top: 16px;
  background: none;
  border: none;
  font-size: 12px;
  letter-spacing: 0.032em;
  float: right;
  cursor: pointer;
  line-height: 16px; }
  .kyc-individual-organisation .back-btn:hover {
    font-weight: 500;
    text-decoration: underline;
    font-size: 12px;
    letter-spacing: 0.032em; }
  .kyc-individual-organisation .back-btn .anticon {
    vertical-align: middle;
    margin-left: 2px;
    margin-top: -1px; }
    .kyc-individual-organisation .back-btn .anticon svg {
      transform: rotate(89deg);
      width: 16;
      height: 8px; }

.kyc-individual-organisation .small-indian-flag .anticon svg {
  width: 24px !important;
  height: 24px !important;
  position: absolute;
  left: 20px;
  top: 20px; }

.kyc-individual-organisation .organisation-small-indian-flag .anticon svg {
  width: 24px !important;
  height: 24px !important;
  position: absolute;
  left: 20px;
  top: 20px; }

.onboardingkyc-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 100% auto; }
  .onboardingkyc-container .verified-div {
    position: absolute;
    top: 56%;
    z-index: 9;
    right: 12px; }
    .onboardingkyc-container .verified-div svg {
      font-size: 18px; }
  .onboardingkyc-container .verified-div-non-ind {
    position: absolute;
    top: 40%;
    z-index: 1000;
    right: 12px; }
    .onboardingkyc-container .verified-div-non-ind svg {
      font-size: 18px; }
  .onboardingkyc-container .verified-div-email {
    position: absolute;
    top: 42%;
    z-index: 9;
    right: 12px; }
    .onboardingkyc-container .verified-div-email svg {
      font-size: 18px; }
  .onboardingkyc-container .verified-div.ind-indv {
    position: absolute;
    top: 42%;
    z-index: 9;
    right: 12px; }
    .onboardingkyc-container .verified-div.ind-indv svg {
      font-size: 18px; }
  .onboardingkyc-container .ant-input-group-addon {
    border-color: transparent;
    background: #ffffff; }
    .onboardingkyc-container .ant-input-group-addon .ant-select-selection {
      border-radius: 2px; }
    .onboardingkyc-container .ant-input-group-addon div {
      margin-right: 3px;
      background: #efefef; }
  .onboardingkyc-container .address-input {
    margin-bottom: 8 !important; }
  .onboardingkyc-container .ant-input {
    background: #f5f5f5;
    border-radius: 0px;
    border-color: none;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }
  .onboardingkyc-container .ant-form-item-label {
    display: inline-block;
    overflow: hidden;
    line-height: 16px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle; }
  .onboardingkyc-container .ant-input::placeholder {
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.032em; }
  .onboardingkyc-container .ant-form-item-required::before {
    display: none; }
  .onboardingkyc-container .ant-form-item-required::after {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*"; }
  .onboardingkyc-container .ant-form-item {
    margin-bottom: 8px; }
  .onboardingkyc-container .ContinueEnable {
    background: #36c626;
    border-color: #36c626;
    color: white;
    width: 512px;
    border-radius: 2px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 8px;
    margin-bottom: 24px;
    height: 40px; }
  .onboardingkyc-container .btn-green:active {
    background: #31b422; }
  .onboardingkyc-container .ContinueDisable {
    pointer-events: none;
    background: #94e38f;
    border-color: #94e38f;
    color: white;
    width: 512px;
    border-radius: 2px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 8px;
    margin-bottom: 24px;
    height: 40px; }
  .onboardingkyc-container .onboarding {
    flex: 1 1;
    max-width: 100%;
    height: 100%;
    margin: auto;
    padding-left: 0px;
    padding-right: 0px; }
    .onboardingkyc-container .onboarding .logo {
      margin-bottom: 32px;
      max-width: 120px;
      height: 48px; }
    .onboardingkyc-container .onboarding .tagline {
      color: rgba(0, 0, 0, 0.45);
      margin: 0 0 42px 0; }
    .onboardingkyc-container .onboarding .ant-tabs-bar {
      border: 0;
      text-align: center; }
    .onboardingkyc-container .onboarding .login-form-forgot,
    .onboardingkyc-container .onboarding .login-form-signup-here {
      float: right; }
    .onboardingkyc-container .onboarding .ant-alert {
      margin-bottom: 15px; }
    .onboardingkyc-container .onboarding .login-form-button {
      width: 100%;
      margin: 0 0;
      box-shadow: 0px 1px 2px rgba(0, 104, 255, 0.59); }
    .onboardingkyc-container .onboarding .register-tag-line {
      margin: 15px 0 10px 0; }
    .onboardingkyc-container .onboarding .login-page-footer {
      position: -webkit-sticky;
      position: sticky;
      top: calc(100% - 55px); }
      .onboardingkyc-container .onboarding .login-page-footer .footer-links {
        list-style-type: none;
        padding: 0; }
        .onboardingkyc-container .onboarding .login-page-footer .footer-links li {
          display: inline-block;
          margin: 0 20px; }
          .onboardingkyc-container .onboarding .login-page-footer .footer-links li a {
            color: rgba(0, 0, 0, 0.45); }
          .onboardingkyc-container .onboarding .login-page-footer .footer-links li a:hover {
            color: black; }
    .onboardingkyc-container .onboarding .ant-row-flex {
      display: flex;
      flex-flow: row wrap;
      margin-top: 64px; }
  .onboardingkyc-container .onboarding-sidebar {
    width: 327px;
    background: url(/static/media/onboarding-sidebar-bg.c146e064.svg) #16273b;
    background-position: bottom right;
    background-repeat: no-repeat;
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    bottom: 0; }
    .onboardingkyc-container .onboarding-sidebar a {
      color: rgba(255, 255, 255, 0.4);
      font-size: 15px; }
    .onboardingkyc-container .onboarding-sidebar a:hover {
      color: white; }
    .onboardingkyc-container .onboarding-sidebar .steps {
      display: flex;
      flex: 1 1;
      align-items: center; }
      .onboardingkyc-container .onboarding-sidebar .steps ul {
        list-style-type: none;
        margin: 0;
        position: relative; }
        .onboardingkyc-container .onboarding-sidebar .steps ul li {
          color: #b2b2b2;
          font-size: 12px;
          position: relative;
          margin: 16px 0;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.032em; }
        .onboardingkyc-container .onboarding-sidebar .steps ul li:before {
          content: "";
          position: absolute;
          left: -34px;
          top: 4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #b2b2b2; }
        .onboardingkyc-container .onboarding-sidebar .steps ul li.active {
          color: #fff;
          letter-spacing: 0.032em;
          font-size: 14px; }
        .onboardingkyc-container .onboarding-sidebar .steps ul li.active:after {
          content: "";
          position: absolute;
          left: -31px;
          top: 7px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #16273b; }
        .onboardingkyc-container .onboarding-sidebar .steps ul li.completed:after {
          content: "";
          position: absolute;
          left: -31px;
          top: 7px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #36c626; }
        .onboardingkyc-container .onboarding-sidebar .steps ul li.active:before {
          background: #fff; }
        .onboardingkyc-container .onboarding-sidebar .steps ul .completed {
          color: #fff;
          font-size: 12px;
          position: relative;
          margin: 16px 0;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.032em; }
      .onboardingkyc-container .onboarding-sidebar .steps ul:before {
        content: "";
        position: absolute;
        top: 22px;
        left: 12px;
        width: 4px;
        height: calc(100% - 42px);
        background: #b2b2b2; }
    .onboardingkyc-container .onboarding-sidebar .testimonial {
      color: white;
      text-align: center;
      font-size: 14px; }
      .onboardingkyc-container .onboarding-sidebar .testimonial .testimonial-user {
        display: flex;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
        align-items: center; }
        .onboardingkyc-container .onboarding-sidebar .testimonial .testimonial-user img {
          width: 50px; }
        .onboardingkyc-container .onboarding-sidebar .testimonial .testimonial-user p {
          flex: 1 1;
          margin: 0 0 0 10px;
          text-align: left; }
    .onboardingkyc-container .onboarding-sidebar .go-dashboard {
      text-align: right;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 18px;
      text-decoration: underline;
      color: #ffffff; }
      .onboardingkyc-container .onboarding-sidebar .go-dashboard a {
        font-size: 12px; }
      .onboardingkyc-container .onboarding-sidebar .go-dashboard .anticon {
        vertical-align: middle;
        margin-left: 2px;
        margin-top: -1px; }
        .onboardingkyc-container .onboarding-sidebar .go-dashboard .anticon svg {
          transform: rotate(267deg);
          width: 16;
          height: 8px; }
  .onboardingkyc-container .skip-step {
    border: 1px solid #898989;
    margin-top: -16px;
    margin-bottom: 16px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  .onboardingkyc-container .skip-step a {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #212121;
    cursor: pointer; }
  .onboardingkyc-container .skip-step .skip-btn {
    width: 512px;
    border: none;
    background: transparent;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }
  .onboardingkyc-container .skip-btn:hover {
    color: #212121; }
  .onboardingkyc-container .ant-upload-drag-icon {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    line-height: 16px;
    margin-top: -9px; }
    .onboardingkyc-container .ant-upload-drag-icon .anticon svg {
      width: 16px;
      height: 14px;
      margin-right: 6px; }
  .onboardingkyc-container .ant-upload-drag {
    height: 32px;
    border-radius: 2px;
    border: none;
    background: #f5f5f5; }
  .onboardingkyc-container .has-error .ant-form-explain,
  .onboardingkyc-container .has-error .ant-form-split {
    color: #f5222d;
    font-size: 13px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .onboardingkyc-container .non-india-upload-file .ant-form-explain {
    margin-top: -16px !important;
    margin-bottom: 3px; }
  .onboardingkyc-container .upload-file .ant-form-explain {
    margin-top: -6px !important; }
  .onboardingkyc-container .phone-input-validation .ant-form-explain {
    margin-top: 3px !important; }
  .onboardingkyc-container .authorized_signatory {
    margin-top: -8px; }
  .onboardingkyc-container .verify-email {
    background: transparent;
    position: absolute;
    right: 45%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 4px;
    z-index: 99;
    color: #92bcf9;
    text-decoration: underline !important;
    pointer-events: none; }
  .onboardingkyc-container .verify-email2 {
    background: transparent;
    position: absolute;
    right: 45%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 4px;
    z-index: 99;
    color: #0063f2;
    text-decoration: underline !important; }
  .onboardingkyc-container .verify-phone {
    background: transparent;
    position: absolute;
    right: 44%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 4px;
    z-index: 99;
    color: #92bcf9;
    text-decoration: underline !important;
    pointer-events: none; }
  .onboardingkyc-container .verify-phone2 {
    background: transparent;
    position: absolute;
    right: 45%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 4px;
    z-index: 99;
    color: #0063f2;
    text-decoration: underline !important; }
  .onboardingkyc-container .verifyphone-tooltip {
    position: absolute;
    right: 43%;
    margin-top: 5px;
    z-index: 99; }
  .onboardingkyc-container .otherPayment {
    margin-top: -16px; }
  .onboardingkyc-container .etc-txt {
    float: left;
    margin-top: -24px;
    font-size: 12px;
    color: #898989;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: 8px; }
  .onboardingkyc-container .etc-txt2 {
    float: left;
    margin-top: -16px;
    font-size: 12px;
    color: #898989;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: 8px; }
  .onboardingkyc-container .etc-txt4 {
    float: left;
    margin-top: 8px;
    font-size: 12px;
    color: #898989;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: 8px; }
  .onboardingkyc-container .authorized-signatory .ant-form-item-label {
    text-align: left !important;
    width: 100% !important; }
  .onboardingkyc-container .phone-number {
    margin-bottom: 4px; }
    .onboardingkyc-container .phone-number .phone-number-value {
      color: #212121 !important;
      letter-spacing: 0.032em !important;
      line-height: 16px !important; }
  .onboardingkyc-container .placeholder-country-input {
    color: #212121 !important;
    letter-spacing: 0.032em !important;
    line-height: 16px !important; }
  .onboardingkyc-container .label-phone-no {
    line-height: 25px; }
  .onboardingkyc-container .input-phone-txt .ant-form-item-children {
    display: flex;
    justify-content: space-between;
    width: 100%; }
  .onboardingkyc-container .input-phone-txt .dialCode-txt {
    width: 20%;
    background: #f5f5f5;
    height: 32px;
    display: flex;
    font-size: 12px;
    align-items: center;
    padding-left: 16px;
    color: #212121;
    letter-spacing: 0.032em; }
  .onboardingkyc-container .input-phone-txt .ant-input {
    width: 78%; }
  .onboardingkyc-container .input-phone-txt .ind-91 {
    width: 20%;
    background: #f5f5f5;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12px; }
    .onboardingkyc-container .input-phone-txt .ind-91 .ant-select-selection {
      background: none;
      border: none;
      font-size: 12px;
      display: flex;
      justify-content: center;
      border-radius: 2px;
      color: #212121; }
    .onboardingkyc-container .input-phone-txt .ind-91 .ant-select-selection__rendered {
      line-height: 23px !important; }
    .onboardingkyc-container .input-phone-txt .ind-91 .ant-select-arrow {
      margin-top: -7px !important; }
  .onboardingkyc-container .aadhar-card-name {
    margin-bottom: 6px;
    margin-top: 19px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    font-weight: 500; }
  .onboardingkyc-container .welcomevfulfill-txt {
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 36%;
    left: 15%;
    text-decoration: underline;
    line-height: 22px;
    letter-spacing: 0.032em; }
  .onboardingkyc-container .right-side-step .kyc-step {
    height: 48px !important;
    padding: 0px 16px !important;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .onboardingkyc-container .right-side-step .kyc-step .flex-step {
      display: flex;
      align-items: center; }
      .onboardingkyc-container .right-side-step .kyc-step .flex-step .circle {
        height: 16px;
        width: 16px;
        background-color: #b2b2b2;
        border-radius: 50%;
        position: relative; }
      .onboardingkyc-container .right-side-step .kyc-step .flex-step .circle.completed {
        background-color: #2bc822; }
      .onboardingkyc-container .right-side-step .kyc-step .flex-step .circle.completed:after {
        height: 40px;
        width: 4px;
        background-color: #2bc822;
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translateX(-50%); }
      .onboardingkyc-container .right-side-step .kyc-step .flex-step .circle.active {
        background-color: #2bc822;
        outline: 4px solid #229d1b; }
      .onboardingkyc-container .right-side-step .kyc-step .flex-step .circle.active:after {
        height: 40px;
        width: 4px;
        background-color: #b2b2b2;
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translateX(-50%); }
      .onboardingkyc-container .right-side-step .kyc-step .flex-step .circle::after {
        content: "";
        height: 40px;
        width: 4px;
        background-color: #b2b2b2;
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translateX(-50%); }
      .onboardingkyc-container .right-side-step .kyc-step .flex-step .step-concat {
        margin-left: 16px; }
        .onboardingkyc-container .right-side-step .kyc-step .flex-step .step-concat .font-weight {
          color: #fff; }
  .onboardingkyc-container .right-side-step .kyc-step:last-of-type .circle:after {
    display: none; }
  .onboardingkyc-container .right-side-step .status-logs:last-of-type .circle::after {
    content: "";
    height: 0px;
    width: 0px; }
  .onboardingkyc-container .right-side-step .onboarding-step-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #ffffff; }
  .onboardingkyc-container .right-side-step .Profile-txt {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 22px; }
  .onboardingkyc-container .welcome-vfulfill {
    position: absolute;
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    letter-spacing: 0.032em;
    line-height: 22px;
    top: 30%;
    left: 20%; }
  .onboardingkyc-container .already-verify-email {
    background: transparent;
    position: absolute;
    right: 45%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 4px;
    z-index: 99;
    color: #28af21;
    text-decoration: underline !important;
    pointer-events: none; }
    .onboardingkyc-container .already-verify-email .save-ican {
      margin-right: 3px; }
      .onboardingkyc-container .already-verify-email .save-ican svg path {
        fill: #28af21; }
  .onboardingkyc-container .anticon-close {
    display: none; }
  .onboardingkyc-container .content-container {
    width: 100%;
    max-width: 510px;
    margin: auto;
    padding-bottom: 8px; }
    .onboardingkyc-container .content-container .profile-indian-global {
      display: flex;
      justify-content: center;
      align-items: center; }
      .onboardingkyc-container .content-container .profile-indian-global .profile-indian {
        margin-right: 32px; }
    .onboardingkyc-container .content-container .select-option-txt {
      font-size: 12px;
      color: #4b4b4b;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-bottom: 13px;
      margin-top: 69px; }
    .onboardingkyc-container .content-container .profile-type-card {
      height: 216px;
      width: 240px;
      border: 3px solid #e3e3e3;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      background: #fff;
      cursor: pointer; }
      .onboardingkyc-container .content-container .profile-type-card .flag {
        font-size: 12px;
        color: #000000;
        letter-spacing: 0.032em;
        line-height: 18px;
        margin-bottom: 16px;
        font-weight: 400; }
        .onboardingkyc-container .content-container .profile-type-card .flag .registered-india {
          text-decoration: underline;
          font-weight: 500; }
        .onboardingkyc-container .content-container .profile-type-card .flag .not-registered-india {
          text-decoration: underline;
          font-weight: 500; }
      .onboardingkyc-container .content-container .profile-type-card .footer {
        flex: unset;
        width: 240px;
        font-size: 12px;
        color: white;
        padding: 8px 0;
        background: #36c626;
        position: relative;
        left: -9px;
        top: 3px;
        font-weight: 500;
        letter-spacing: 0.032em;
        line-height: 16px; }
      .onboardingkyc-container .content-container .profile-type-card .Shopify_flag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer; }
        .onboardingkyc-container .content-container .profile-type-card .Shopify_flag .anticon svg {
          width: 96px;
          height: 96px; }
      .onboardingkyc-container .content-container .profile-type-card .wocommerce_flag {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer; }
        .onboardingkyc-container .content-container .profile-type-card .wocommerce_flag .anticon svg {
          width: 96px;
          height: 96px; }
      .onboardingkyc-container .content-container .profile-type-card .Shopify_footer {
        flex: unset;
        width: 240px;
        font-size: 12px;
        color: #212121;
        padding: 8px 0;
        position: relative;
        left: -9px;
        top: -24px;
        font-weight: 500;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .onboardingkyc-container .content-container .profile-type-card:hover {
      border: 3px solid #30c638; }
      .onboardingkyc-container .content-container .profile-type-card:hover .registered-india {
        font-weight: 600; }
      .onboardingkyc-container .content-container .profile-type-card:hover .not-registered-india {
        font-weight: 600; }

.Skip_woocommerce {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px; }

.support-email-phone-modal .ant-modal-content .ant-modal-close {
  display: none !important; }

.support-email-phone-modal .ant-modal-content .support-phone-number-txt {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.032em;
  line-height: 22px;
  margin-bottom: 0px;
  color: #fc125e; }

.support-email-phone-modal .ant-modal-content .number-verify-txt {
  font-size: 12px;
  color: #4b4b4b;
  line-height: 22px;
  letter-spacing: 0.032em;
  text-align: center; }

.support-email-phone-modal .ant-modal-content .ant-modal-header {
  border-bottom: none !important;
  padding: 32px 24px !important; }

.support-email-phone-modal .ant-modal-content .ant-modal-footer {
  border-top: none !important; }

.support-email-phone-modal .otp-verify {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px; }
  .support-email-phone-modal .otp-verify .otp-number {
    width: 15%;
    border: none;
    background: #f5f5f5;
    height: 32px;
    text-align: center; }

.support-email-phone-modal .otp-txt {
  font-size: 12px;
  margin-top: 24px;
  margin-bottom: 5px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #898989; }

.support-email-phone-modal .resend-code {
  float: right;
  margin-top: -16px;
  font-size: 12px;
  letter-spacing: 0.032em; }
  .support-email-phone-modal .resend-code .resend-code-txt {
    color: #3273cc;
    text-decoration: underline !important;
    margin-right: 4px;
    background: transparent;
    border: none;
    cursor: pointer; }
  .support-email-phone-modal .resend-code .resend-code-txt2 {
    color: #98b9e5;
    text-decoration: underline !important;
    margin-right: -3px;
    background: transparent;
    border: none;
    pointer-events: none; }

.support-email-phone-modal .header-support-txt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  font-size: 12px;
  letter-spacing: 0.032em;
  margin-top: -24px;
  color: #898989; }
  .support-email-phone-modal .header-support-txt .change-number-txt {
    color: #fc125e;
    border: none;
    background: none;
    text-decoration: underline !important;
    cursor: pointer; }
  .support-email-phone-modal .header-support-txt .save-number-txt {
    color: #28af21;
    border: none;
    background: none;
    text-decoration: underline !important;
    cursor: pointer; }

.support-email-phone-modal .edit-ican {
  margin-right: 3px; }

.support-email-phone-modal .save-ican {
  margin-right: 3px; }
  .support-email-phone-modal .save-ican svg path {
    fill: #28af21; }

.support-email-phone-modal .input-number-data {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .support-email-phone-modal .input-number-data .ind-91 {
    width: 20%;
    background: #f5f5f5;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12px; }
    .support-email-phone-modal .input-number-data .ind-91 .ant-select-selection {
      background: none;
      border: none;
      font-size: 12px;
      display: flex;
      justify-content: center;
      border-radius: 2px;
      color: #212121; }
    .support-email-phone-modal .input-number-data .ind-91 .ant-select-selection__rendered {
      line-height: 23px !important; }
    .support-email-phone-modal .input-number-data .ind-91 .ant-select-arrow {
      margin-top: -7px !important; }
  .support-email-phone-modal .input-number-data .input-email-txt {
    width: 100%; }
    .support-email-phone-modal .input-number-data .input-email-txt input {
      background: #f5f5f5;
      border: none;
      border-radius: 0px; }
  .support-email-phone-modal .input-number-data .input-number-txt {
    width: 78%; }
    .support-email-phone-modal .input-number-data .input-number-txt input {
      background: #f5f5f5;
      border: none;
      border-radius: 0px; }

.support-email-phone-modal .verify-continue-btn {
  width: 408px;
  margin-top: 8px;
  border: none;
  box-shadow: none;
  background: #2bc822 !important;
  border-radius: 2px;
  color: #fff;
  letter-spacing: 0.032em;
  height: 40px;
  line-height: 16px;
  margin-top: 16px; }

.support-email-phone-modal .verify-continue-btn2 {
  width: 408px;
  margin-top: 8px;
  border: none;
  box-shadow: none;
  background: #94e38f !important;
  border-radius: 2px;
  color: #fff;
  letter-spacing: 0.032em;
  height: 40px;
  line-height: 16px;
  pointer-events: none; }

.support-email-phone-modal .cancel-btn {
  width: 408px;
  margin-top: 8px;
  border: 1px solid #898989;
  box-shadow: none;
  border-radius: 2px;
  color: #898989;
  letter-spacing: 0.032em;
  height: 40px;
  line-height: 16px; }

.support-email-phone-modal .otp-number-txt {
  font-size: 12px;
  font-style: italic;
  color: #898989;
  margin-top: 4px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.support-email-phone-modal .ant-input {
  font-size: 12px !important;
  color: #212121;
  letter-spacing: 0.032em;
  line-height: 16px; }

.support-email-phone-modal .disable-phonenumber {
  background: #f5f5f5;
  font-size: 12px;
  height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.032em;
  color: #212121;
  padding-left: 16px; }

.kyc-individual-organisation .checkbox-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -22px; }
  .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card {
    height: 216px;
    width: 240px;
    border: 3px solid #e3e3e3;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    cursor: pointer; }
    .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .flag {
      font-size: 12px;
      color: #000000;
      letter-spacing: 0.032em;
      line-height: 18px;
      padding: 16px; }
      .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .flag .registered-india {
        text-decoration: underline; }
      .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .flag .not-registered-india {
        text-decoration: underline; }
    .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .footer {
      flex: unset;
      width: 240px;
      font-size: 12px;
      color: white;
      padding: 8px 0;
      background: #36c626;
      position: relative;
      left: -3px;
      top: 12px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .anticon {
      margin: auto;
      margin-top: 24px; }
      .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card .anticon svg {
        width: 80px;
        height: 80px; }
  .kyc-individual-organisation .checkbox-card .small-global-flag .anticon svg {
    width: 24px !important;
    height: 24px !important;
    position: absolute;
    left: 20px;
    top: 20px; }
  .kyc-individual-organisation .checkbox-card .organisation-small-global-flag .anticon svg {
    width: 24px !important;
    height: 24px !important;
    position: absolute;
    left: 20px;
    top: 20px; }
  .kyc-individual-organisation .checkbox-card .individual-card {
    margin-right: 32px;
    margin-top: 56px;
    position: relative; }
  .kyc-individual-organisation .checkbox-card .organisation-card {
    margin-top: 56px;
    position: relative; }
  .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card:hover {
    border: 3px solid #30c638; }
    .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card:hover .registered-india {
      font-weight: 500; }
    .kyc-individual-organisation .checkbox-card .checkbox-profile-type-card:hover .not-registered-india {
      font-weight: 500; }

.kyc-individual-organisation .skip-now a {
  cursor: pointer;
  margin-top: 24px;
  width: 120px;
  height: 40px;
  border: 1px solid black;
  border-radius: 2px;
  background: none;
  font-size: 12px;
  color: #4b4b4b;
  padding: 8px; }

.kyc-individual-organisation .skip-now a:hover {
  background: #0031ff;
  color: white;
  border: none; }

.kyc-individual-organisation .back-btn {
  margin-top: 16px;
  background: none;
  border: none;
  font-size: 12px;
  letter-spacing: 0.032em;
  float: right;
  cursor: pointer;
  line-height: 16px; }
  .kyc-individual-organisation .back-btn:hover {
    font-weight: 500;
    text-decoration: underline;
    font-size: 12px;
    letter-spacing: 0.032em; }
  .kyc-individual-organisation .back-btn .anticon {
    vertical-align: middle;
    margin-left: 2px;
    margin-top: -1px; }
    .kyc-individual-organisation .back-btn .anticon svg {
      transform: rotate(89deg);
      width: 16;
      height: 8px; }

.global-tooltip {
  display: flex;
  align-items: center;
  justify-content: center; }

.global-tooltip-icon {
  font-size: 16px;
  margin: 0px 8px !important; }

.global-tooltip:hover .global-tooltip-icon {
  background: #0058d6;
  border-radius: 50%;
  cursor: pointer; }
  .global-tooltip:hover .global-tooltip-icon svg g {
    stroke: #0058d6; }
  .global-tooltip:hover .global-tooltip-icon svg :last-child g {
    stroke: #fff; }

.global-tooltip:hover .global-tooltip-icon g text {
  fill: #fff; }

.global-tooltip:hover .global-tooltip-icon g > g {
  stroke: #0058d6; }

.verify-profile .first-step-footer {
  height: 228px;
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background: url(/static/media/test.d3a6d27a.svg); }

.verify-profile .label-row {
  display: flex;
  justify-content: space-between;
  text-align: left; }
  .verify-profile .label-row .label {
    position: relative;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989; }
  .verify-profile .label-row .required::after {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*"; }

.verify-profile .onboarding-skip {
  cursor: pointer;
  margin-top: 24px;
  width: 120px;
  height: 40px;
  border: 1px solid black;
  border-radius: 2px;
  background: none;
  font-size: 12px;
  color: #4b4b4b; }

.verify-profile .ant-row.ant-form-item {
  text-align: left;
  position: relative; }
  .verify-profile .ant-row.ant-form-item button {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 22px;
    background-color: none;
    border: none;
    cursor: pointer; }

.verify-profile .content-container {
  width: 100%;
  max-width: 510px;
  margin: auto;
  text-align: center;
  padding-bottom: 8px; }
  .verify-profile .content-container .profile-indian-global {
    display: flex;
    justify-content: center;
    align-items: center; }
    .verify-profile .content-container .profile-indian-global .profile-indian {
      margin-right: 32px; }
  .verify-profile .content-container .select-option-txt {
    font-size: 12px;
    color: #4b4b4b;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: 13px;
    margin-top: 69px; }

.verify-profile .profile-type-card {
  height: 216px;
  width: 240px;
  border: 3px solid #e3e3e3;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background: #fff;
  cursor: pointer; }
  .verify-profile .profile-type-card .flag {
    font-size: 12px;
    color: #000000;
    letter-spacing: 0.032em;
    line-height: 18px;
    margin-bottom: 16px;
    font-weight: 400; }
    .verify-profile .profile-type-card .flag .registered-india {
      text-decoration: underline;
      font-weight: 500; }
    .verify-profile .profile-type-card .flag .not-registered-india {
      text-decoration: underline;
      font-weight: 500; }
  .verify-profile .profile-type-card .footer {
    flex: unset;
    width: 240px;
    font-size: 12px;
    color: white;
    padding: 8px 0;
    background: #36c626;
    position: relative;
    left: -9px;
    top: 3px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .verify-profile .profile-type-card .anticon {
    margin: auto;
    margin-top: 24px; }
    .verify-profile .profile-type-card .anticon svg {
      width: 80px;
      height: 80px; }

.verify-profile .ant-upload {
  text-align: left;
  padding: 0 15px; }
  .verify-profile .ant-upload p {
    margin-bottom: 0 !important; }
    .verify-profile .ant-upload p .anticon {
      color: unset !important;
      font-size: 22px !important; }

.verify-profile .ant-checkbox-inner {
  width: 18px !important;
  height: 18px !important;
  border-radius: 50% !important;
  display: none; }

.verify-profile .iti--allow-dropdown input,
.verify-profile .iti--allow-dropdown input[type="text"],
.verify-profile .iti--allow-dropdown input[type="tel"],
.verify-profile .iti--separate-dial-code input,
.verify-profile .iti--separate-dial-code input[type="text"],
.verify-profile .iti--separate-dial-code input[type="tel"] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
  width: 100%;
  display: inline-block;
  border: none;
  background: #efefef;
  height: 32px;
  font-size: 14px; }

.verify-profile .iti {
  position: relative;
  display: inline-block;
  width: 100%;
  border: none;
  display: flex;
  justify-content: space-between; }
  .verify-profile .iti li {
    padding: 0px 8px !important;
    outline: none !important;
    border-bottom: 1px solid #f5f5f5; }
  .verify-profile .iti .iti__country-list {
    border: none !important; }
  .verify-profile .iti .iti__flag-container {
    background: #f5f5f5;
    height: 32px;
    width: 70px; }
  .verify-profile .iti input:focus-visible {
    outline: none !important;
    border-radius: 0px !important; }

.verify-profile .deDisclaimer-txt {
  background: #fffcf8;
  border: 2px solid #db8230;
  padding: 16px;
  color: #212121;
  font-weight: 400;
  letter-spacing: 0.032em;
  margin: 16px;
  line-height: 20px;
  border-radius: 4px;
  font-size: 12px;
  width: 650px;
  position: relative;
  right: 14%; }
  .verify-profile .deDisclaimer-txt .disclaimer {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.032em;
    font-weight: 600;
    color: #db8230; }

.verify-profile .ant-upload-list-item-info .anticon-paper-clip {
  display: none; }

.verify-profile .ant-upload-list-item-info > span > span {
  display: none; }

.verify-profile .ant-upload-list-item {
  position: relative;
  height: 0px !important;
  margin-top: 8px !important;
  font-size: 0px !important; }

.verify-profile .ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #fff !important; }

.verify-profile .uploadcross-btn {
  margin-top: 8px; }

.verify-profile .onboarding-header .title {
  color: #fc125e;
  margin: 0;
  font-size: 16px;
  letter-spacing: 0.032em;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 5px; }

.verify-profile .onboarding-header .subtitle {
  font-size: 12px !important;
  line-height: 22px;
  letter-spacing: 0.032em;
  color: #4b4b4b;
  line-height: 22px; }

.verify-profile .ant-form-item label {
  position: relative;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #898989; }

.verify-profile .upload-label {
  margin-bottom: 8px; }

.verify-profile .back-btn {
  background: none;
  border: none;
  font-size: 12px;
  letter-spacing: 0.032em;
  float: right;
  cursor: pointer;
  line-height: 16px; }
  .verify-profile .back-btn:hover {
    font-weight: 500;
    text-decoration: underline;
    font-size: 12px;
    letter-spacing: 0.032em;
    cursor: pointer; }
  .verify-profile .back-btn .anticon {
    vertical-align: middle;
    margin-left: 2px;
    margin-top: -1px; }
    .verify-profile .back-btn .anticon svg {
      transform: rotate(89deg);
      width: 16;
      height: 8px; }

.verify-profile .gst-individual {
  background: #f5f5f5;
  border: none;
  padding: 16px;
  color: #212121;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 18px;
  border-radius: 0px;
  font-size: 12px;
  width: 512px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px; }
  .verify-profile .gst-individual .anticon {
    margin-right: 8px; }
    .verify-profile .gst-individual .anticon svg {
      width: 48px;
      height: 48px; }

.verify-profile .flag {
  font-size: 12px;
  color: #000000;
  letter-spacing: 0.032em;
  line-height: 18px; }
  .verify-profile .flag .registered-india {
    text-decoration: underline;
    font-weight: 500; }
  .verify-profile .flag .not-registered-india {
    text-decoration: underline;
    font-weight: 500; }

.verify-profile .profile-form {
  margin-top: 8px; }

.verify-profile .top-header-steps {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  margin-bottom: 16px; }
  .verify-profile .top-header-steps p {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px; }

.verify-profile .country-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .verify-profile .country-wrapper .state-wrapper {
    width: 48%; }
    .verify-profile .country-wrapper .state-wrapper .state-select .ant-select-selection {
      box-shadow: none;
      border: none;
      background: #f5f5f5;
      border-radius: 0px; }
    .verify-profile .country-wrapper .state-wrapper .state-select .ant-select-selection-selected-value {
      font-size: 12px !important;
      letter-spacing: 0.032em; }
  .verify-profile .country-wrapper .city-wrapper {
    width: 48%; }
    .verify-profile .country-wrapper .city-wrapper .city-select .ant-select-selection {
      box-shadow: none;
      border: none;
      background: #f5f5f5;
      border-radius: 0px; }
    .verify-profile .country-wrapper .city-wrapper .city-select .ant-select-selection-selected-value {
      font-size: 12px !important;
      letter-spacing: 0.032em; }
  .verify-profile .country-wrapper .global-country {
    width: 48%; }
    .verify-profile .country-wrapper .global-country .city-select .ant-select-selection {
      box-shadow: none;
      border: none;
      background: #f5f5f5;
      border-radius: 0px; }
    .verify-profile .country-wrapper .global-country .city-select .ant-select-selection-selected-value {
      font-size: 12px !important;
      letter-spacing: 0.032em; }

.verify-profile .city-pincode-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .verify-profile .city-pincode-wrapper .india-country {
    width: 48%; }
    .verify-profile .city-pincode-wrapper .india-country .city-select .ant-select-selection {
      box-shadow: none;
      border: none;
      background: #f5f5f5;
      border-radius: 0px; }
    .verify-profile .city-pincode-wrapper .india-country .city-select .ant-select-selection-selected-value {
      font-size: 12px !important;
      letter-spacing: 0.032em; }
  .verify-profile .city-pincode-wrapper .pincode-wrapper {
    width: 48%; }

.verify-profile .iti--allow-dropdown input,
.verify-profile .iti--allow-dropdown input[type="text"],
.verify-profile .iti--allow-dropdown input[type="tel"],
.verify-profile .iti--separate-dial-code input,
.verify-profile .iti--separate-dial-code input[type="text"],
.verify-profile .iti--separate-dial-code input[type="tel"] {
  padding-left: 16px !important;
  margin-left: 0; }

.verify-profile ::-webkit-input-placeholder {
  color: #898989;
  letter-spacing: 0.032em; }

.verify-profile .iti--allow-dropdown input,
.verify-profile .iti--allow-dropdown input[type="text"],
.verify-profile .iti--allow-dropdown input[type="tel"],
.verify-profile .iti--separate-dial-code input,
.verify-profile .iti--separate-dial-code input[type="text"],
.verify-profile .iti--separate-dial-code input[type="tel"] {
  padding-right: 6px;
  padding-left: 52px;
  margin-left: 0;
  width: 100%;
  display: inline-block;
  border: none;
  background: #f5f5f5 !important;
  height: 32px;
  font-size: 12px !important;
  margin-left: 80px; }

.verify-profile .profile-type-card:hover {
  border: 3px solid #30c638; }
  .verify-profile .profile-type-card:hover .registered-india {
    font-weight: 600; }
  .verify-profile .profile-type-card:hover .not-registered-india {
    font-weight: 600; }

.country-list {
  max-height: 200px !important;
  background: hotpink; }

.profile-dropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 2px solid #b5dafe !important;
  border-radius: 0px !important; }

.profile-dropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 4px 8px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 32px; }

.phone-field-wraper {
  position: relative; }
  .phone-field-wraper .anchor {
    top: 35%;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    cursor: pointer !important;
    z-index: 999 !important;
    height: 35px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -1px !important;
    text-decoration: none !important; }
  .phone-field-wraper .verify-phone.anchor {
    text-decoration: none !important;
    opacity: 0.5 !important; }
  .phone-field-wraper .verify-phone2.anchor {
    opacity: 1 !important; }
  .phone-field-wraper .verify-email.anchor {
    text-decoration: none !important;
    opacity: 0.5 !important; }
  .phone-field-wraper .verify-email2.anchor {
    opacity: 1 !important; }

.phone-field-wraper.custom {
  height: 88px !important; }
  .phone-field-wraper.custom .anchor {
    top: 26%; }

.email-field-wraper {
  position: relative !important;
  min-height: 80px !important; }
  .email-field-wraper #email {
    position: relative; }
  .email-field-wraper .anchor {
    top: 27%;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    cursor: pointer !important;
    z-index: 999 !important;
    height: 33px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -1px !important;
    text-decoration: none !important; }
  .email-field-wraper .verify-phone.anchor {
    text-decoration: none !important;
    opacity: 0.5 !important; }
  .email-field-wraper .verify-phone2.anchor {
    opacity: 1 !important; }
  .email-field-wraper .verify-email.anchor {
    text-decoration: none !important;
    opacity: 0.5 !important; }
  .email-field-wraper .verify-email2.anchor {
    opacity: 1 !important; }

.plan-screen-wraper .scroll-area {
  height: calc(100vh - 98px);
  overflow: scroll !important;
  display: flex;
  flex-direction: column;
  align-items: center; }

.plan-screen-wraper .plan-card-wraper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 32px;
  gap: 32px;
  flex-wrap: wrap; }
  .plan-screen-wraper .plan-card-wraper .save {
    font-weight: 400;
    background-color: #ffdf56;
    color: #212121;
    padding: 3px 12px;
    border-radius: 12px; }
    .plan-screen-wraper .plan-card-wraper .save span {
      font-weight: 500 !important;
      font-size: 14px !important;
      color: #212121 !important; }

.plan-screen-wraper .plan-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 44px; }
  .plan-screen-wraper .plan-header h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px; }
  .plan-screen-wraper .plan-header .switch-wraper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px; }
    .plan-screen-wraper .plan-header .switch-wraper .ant-switch {
      min-width: 60px !important;
      height: 24px !important; }
    .plan-screen-wraper .plan-header .switch-wraper .ant-switch::after {
      top: 2px !important; }
    .plan-screen-wraper .plan-header .switch-wraper h2 {
      font-size: 14px;
      color: #212121;
      font-weight: 400; }
    .plan-screen-wraper .plan-header .switch-wraper span {
      font-size: 14px;
      font-weight: 500;
      color: #2bc822; }

.plan-screen-wraper .plan-footer {
  position: fixed;
  bottom: 0px;
  background-color: #ffdf56;
  width: 90vw !important;
  padding: 12px;
  display: flex;
  align-items: center;
  border: 0px;
  border-radius: 0px;
  justify-content: center; }
  .plan-screen-wraper .plan-footer button {
    background-color: #212121;
    border: none;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    padding-left: 52px !important;
    border-radius: 4px !important;
    padding-right: 52px !important; }

.plan-card {
  background-color: #fff;
  width: 334px !important;
  border: 1px solid #3b3b3b;
  border-radius: 8px;
  position: relative;
  padding: 26px 12px 42px 12px;
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  height: auto; }
  .plan-card button {
    background-color: #fff !important;
    border-radius: 2px;
    border: 1px solid #536be8;
    margin-top: 12px;
    width: 100%; }
    .plan-card button span {
      font-size: 14px;
      color: #536be8;
      font-weight: 500; }
  .plan-card .top-bar {
    height: 10px;
    background-color: #3b3b3b;
    border-radius: 6px 6px 0 0;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px; }
  .plan-card .bottom-bar {
    height: 10px;
    width: 100%;
    background-color: #3b3b3b;
    border-radius: 0px 0px 6px 6px;
    left: 0px;
    position: absolute;
    bottom: 0px; }
  .plan-card .price-card {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    background-color: #f4f4f4;
    border-radius: 6px;
    padding: 16px 12px 16px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 6px;
    gap: 6px; }
    .plan-card .price-card h1 {
      font-size: 24px;
      color: #4a4be4;
      font-weight: 600;
      margin-bottom: 2px; }
    .plan-card .price-card .price {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 6px;
      gap: 6px; }
      .plan-card .price-card .price h2 {
        font-size: 28px;
        color: #212121;
        font-weight: 700; }
      .plan-card .price-card .price span {
        color: #898989;
        font-size: 16px !important; }
    .plan-card .price-card span {
      font-size: 12px;
      color: #898989; }
    .plan-card .price-card button {
      background-color: #fff !important;
      border-radius: 2px;
      border: 1px solid #536be8;
      margin-top: 12px;
      width: 100%; }
      .plan-card .price-card button span {
        font-size: 14px;
        color: #536be8;
        font-weight: 500; }
    .plan-card .price-card .payment-disable {
      background-color: #2bc822 !important;
      border-color: #2bc822 !important; }
      .plan-card .price-card .payment-disable span {
        color: #fff; }
    .plan-card .price-card #payment-disable {
      background-color: #2bc822 !important;
      border-color: #2bc822 !important; }
      .plan-card .price-card #payment-disable span {
        color: #fff; }
  .plan-card .illustration-wraper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 12px;
    gap: 12px; }
    .plan-card .illustration-wraper svg {
      display: block;
      min-width: 72px; }
    .plan-card .illustration-wraper p {
      color: #212121;
      font-size: 12px;
      font-family: "Helvetica", "Arial", sans-serif !important;
      line-height: 18px; }
      .plan-card .illustration-wraper p span {
        text-decoration: underline; }
  .plan-card .features-card {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px; }
    .plan-card .features-card h1 {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 15px;
      text-align: left; }
    .plan-card .features-card .features {
      padding: 12px;
      background-color: #f4f4f4;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      grid-gap: 15px;
      gap: 15px; }
      .plan-card .features-card .features .feature-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 8px;
        gap: 8px;
        font-size: 12px;
        color: #212121; }
        .plan-card .features-card .features .feature-row p span {
          font-weight: 500; }
        .plan-card .features-card .features .feature-row svg {
          display: block;
          min-width: 16px; }
      .plan-card .features-card .features .feature-row.cross {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 8px;
        gap: 8px;
        font-size: 12px;
        color: #212121; }
        .plan-card .features-card .features .feature-row.cross svg {
          background-color: red;
          border-radius: 50%;
          display: block;
          min-width: 16px; }
      .plan-card .features-card .features .custom-lineheight p {
        line-height: 36px !important; }
  .plan-card .save-more-card h1 {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    grid-gap: 4px;
    gap: 4px; }
    .plan-card .save-more-card h1 span {
      font-size: 20px; }
  .plan-card .save-more-card .save-row-card {
    padding: 12px;
    background-color: #f4f4f4;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px; }
    .plan-card .save-more-card .save-row-card .save-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 8px;
      gap: 8px;
      font-size: 12px;
      color: #212121; }
      .plan-card .save-more-card .save-row-card .save-row svg {
        display: block;
        min-width: 16px; }
  .plan-card .brands-card h1 {
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    color: #212121;
    margin-bottom: 10px; }
  .plan-card .brands-card .brands-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px; }
    .plan-card .brands-card .brands-logo .prtool-logo rect {
      fill: #0c70f2 !important; }
    .plan-card .brands-card .brands-logo .checkout-logo path {
      fill: #0f55b1 !important; }

/* common */
.card-ribbon {
  width: 138px;
  height: 138px;
  overflow: hidden;
  position: absolute;
  z-index: 999; }

.card-ribbon::before,
.card-ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #2980b9; }

.card-ribbon span {
  position: absolute;
  display: block;
  width: 211px;
  padding: 12px 0;
  background-color: #3498db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center; }

/* top left*/
.card-ribbon-top-left {
  top: -10px;
  left: -10px; }

.card-ribbon-top-left::before,
.card-ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent; }

.card-ribbon-top-left::before {
  top: 0;
  right: 0; }

.card-ribbon-top-left::after {
  bottom: 0;
  left: 0; }

.card-ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg); }

.earth-quaker-card {
  order: 3; }

.mover-card {
  order: 1; }

.shaker-card {
  order: 2; }

.payment-disable {
  background-color: #2bc822 !important;
  border-color: #2bc822 !important; }
  .payment-disable span {
    color: #fff; }

#payment-disable {
  background-color: #2bc822 !important;
  border-color: #2bc822 !important; }
  #payment-disable span {
    color: #fff; }

.shaker-card.plan-card {
  background-color: #fff;
  width: 300px;
  border: 1px solid #536be8;
  border-radius: 8px;
  position: relative;
  padding: 26px 12px 42px 12px;
  display: flex;
  flex-direction: column;
  background: #ededff !important;
  grid-gap: 24px;
  gap: 24px;
  margin-top: -12px; }
  .shaker-card.plan-card .footer-btn button {
    background-color: #536be8 !important;
    border-radius: 2px;
    border: 1px solid #536be8;
    margin-top: 12px;
    width: 100%; }
    .shaker-card.plan-card .footer-btn button i svg {
      fill: #fff !important; }
    .shaker-card.plan-card .footer-btn button span {
      font-size: 14px;
      color: #fff;
      font-weight: 500; }
  .shaker-card.plan-card .shaker-cards-wraper {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
    padding-top: 12px; }
  .shaker-card.plan-card .top-bar {
    height: 64px;
    background-color: #536be8;
    border-radius: 6px 6px 0 0;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px; }
    .shaker-card.plan-card .top-bar .trend {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      color: #fff;
      margin-top: 8px; }
      .shaker-card.plan-card .top-bar .trend p {
        font-size: 14px;
        margin-left: 4px;
        font-weight: 600; }
  .shaker-card.plan-card .bottom-bar {
    height: 10px;
    width: 100%;
    background-color: #3b3b3b;
    border-radius: 0px 0px 6px 6px;
    left: 0px;
    position: absolute;
    bottom: 0px; }
  .shaker-card.plan-card .price-card {
    background-color: #fff;
    border-radius: 6px;
    padding: 16px 12px 16px 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 6px;
    gap: 6px; }
    .shaker-card.plan-card .price-card h1 {
      font-size: 24px;
      color: #4a4be4;
      font-weight: 600;
      margin-bottom: 2px; }
    .shaker-card.plan-card .price-card .price {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 6px;
      gap: 6px; }
      .shaker-card.plan-card .price-card .price h2 {
        font-size: 28px;
        color: #212121;
        font-weight: 700; }
      .shaker-card.plan-card .price-card .price span {
        color: #898989;
        font-size: 16px !important; }
    .shaker-card.plan-card .price-card span {
      font-size: 12px;
      color: #898989; }
    .shaker-card.plan-card .price-card button {
      background-color: #536be8 !important;
      border-radius: 2px;
      border: 1px solid #536be8;
      margin-top: 12px;
      width: 100%; }
      .shaker-card.plan-card .price-card button i svg {
        fill: #fff !important; }
      .shaker-card.plan-card .price-card button span {
        font-size: 14px;
        color: #fff;
        font-weight: 500; }
  .shaker-card.plan-card .illustration-wraper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 12px;
    gap: 12px; }
    .shaker-card.plan-card .illustration-wraper svg {
      display: block;
      min-width: 72px; }
    .shaker-card.plan-card .illustration-wraper p {
      color: #212121;
      font-size: 12px;
      font-family: "Helvetica", "Arial", sans-serif !important;
      line-height: 18px; }
      .shaker-card.plan-card .illustration-wraper p span {
        text-decoration: underline; }
  .shaker-card.plan-card .features-card {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px; }
    .shaker-card.plan-card .features-card h1 {
      margin-bottom: 8px;
      font-weight: 600;
      font-size: 15px;
      text-align: left; }
    .shaker-card.plan-card .features-card .features {
      padding: 12px;
      background-color: #fff;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      grid-gap: 15px;
      gap: 15px; }
      .shaker-card.plan-card .features-card .features .feature-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 8px;
        gap: 8px;
        font-size: 12px;
        color: #212121; }
        .shaker-card.plan-card .features-card .features .feature-row p span {
          font-weight: 500; }
        .shaker-card.plan-card .features-card .features .feature-row svg {
          display: block;
          min-width: 16px; }
  .shaker-card.plan-card .save-more-card h1 {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    color: #212121;
    margin-bottom: 8px;
    grid-gap: 4px;
    gap: 4px; }
    .shaker-card.plan-card .save-more-card h1 span {
      font-size: 20px; }
  .shaker-card.plan-card .save-more-card .save-row-card {
    padding: 12px;
    background-color: #fff;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px; }
    .shaker-card.plan-card .save-more-card .save-row-card .save-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 8px;
      gap: 8px;
      font-size: 12px;
      color: #212121; }
      .shaker-card.plan-card .save-more-card .save-row-card .save-row svg {
        display: block;
        min-width: 16px; }
  .shaker-card.plan-card .brands-card h1 {
    font-size: 12px;
    text-align: center;
    font-weight: 500;
    color: #212121;
    margin-bottom: 10px; }
  .shaker-card.plan-card .brands-card .brands-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 15px;
    gap: 15px; }
    .shaker-card.plan-card .brands-card .brands-logo .prtool-logo rect {
      fill: linear-gradient(red, yellow) !important; }

.unicorn-card-wraper {
  background-color: #fff;
  border: 1px solid #9f492f;
  border-radius: 8px;
  position: relative;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
  grid-gap: 24px;
  gap: 24px;
  margin-top: 56px;
  margin-left: 8rem;
  margin-right: 8rem; }
  .unicorn-card-wraper .price-card {
    background-color: #fc9f83;
    border-radius: 6px;
    padding: 16px 12px 24px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    flex: 1 1; }
    .unicorn-card-wraper .price-card h1 {
      font-size: 24px;
      color: #9f492f;
      font-weight: 600;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 4px;
      gap: 4px; }
      .unicorn-card-wraper .price-card h1 span {
        color: #fff;
        font-weight: 400; }
    .unicorn-card-wraper .price-card .price {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 6px;
      gap: 6px; }
      .unicorn-card-wraper .price-card .price h2 {
        font-size: 28px;
        color: #212121;
        font-weight: 700; }
      .unicorn-card-wraper .price-card .price span {
        color: #fff;
        font-size: 16px !important; }
    .unicorn-card-wraper .price-card span {
      font-size: 12px;
      color: #898989; }
    .unicorn-card-wraper .price-card button {
      background-color: #fff !important;
      border-radius: 2px;
      border: 1px solid #9f492f;
      padding-left: 24px !important;
      padding-right: 24px !important;
      margin-top: 12px;
      width: 100%; }
      .unicorn-card-wraper .price-card button span {
        font-size: 14px;
        color: #9f492f;
        font-weight: 500; }
  .unicorn-card-wraper .right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    grid-column-gap: 16px;
    column-gap: 16px;
    grid-row-gap: 24px;
    row-gap: 24px;
    flex: 2 1; }
    .unicorn-card-wraper .right .illustration-wraper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 22px;
      gap: 22px; }
      .unicorn-card-wraper .right .illustration-wraper svg {
        display: block;
        min-width: 72px; }
      .unicorn-card-wraper .right .illustration-wraper p {
        color: #212121;
        width: 40%;
        font-size: 12px;
        font-family: "Helvetica", "Arial", sans-serif !important;
        line-height: 18px; }
        .unicorn-card-wraper .right .illustration-wraper p span {
          text-decoration: underline; }
    .unicorn-card-wraper .right .features-card {
      display: flex; }
      .unicorn-card-wraper .right .features-card h1 {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 12px;
        text-align: center; }
      .unicorn-card-wraper .right .features-card .features {
        border-radius: 2px;
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: 36px;
        column-gap: 36px;
        grid-row-gap: 15px;
        row-gap: 15px; }
        .unicorn-card-wraper .right .features-card .features .feature-row {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          grid-gap: 8px;
          gap: 8px;
          font-size: 12px;
          color: #212121;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content; }
          .unicorn-card-wraper .right .features-card .features .feature-row svg {
            display: block;
            min-width: 16px; }

@media only screen and (max-width: 600px) {
  .illustration-wraper p {
    width: 100% !important; } }

.free-plan-card-wraper {
  background-color: #fff;
  border: 1px solid #3b3b3b;
  border-radius: 8px;
  position: relative;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
  grid-gap: 24px;
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 56px;
  margin-left: 8rem;
  margin-right: 8rem; }
  .free-plan-card-wraper #payment-disable {
    background-color: #2bc822 !important;
    border-color: #2bc822 !important; }
    .free-plan-card-wraper #payment-disable span {
      color: #fff; }
  .free-plan-card-wraper .price-card {
    background-color: #f4f4f4;
    border-radius: 6px;
    padding: 16px 12px 24px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 6px;
    gap: 6px;
    flex: 1 1; }
    .free-plan-card-wraper .price-card h1 {
      font-size: 24px;
      color: #4a4be4;
      font-weight: 600;
      margin-bottom: 2px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 4px;
      gap: 4px; }
      .free-plan-card-wraper .price-card h1 span {
        color: #fff;
        font-weight: 400; }
    .free-plan-card-wraper .price-card .price {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 6px;
      gap: 6px; }
      .free-plan-card-wraper .price-card .price h2 {
        font-size: 28px;
        color: #212121;
        font-weight: 700; }
      .free-plan-card-wraper .price-card .price span {
        color: #898989;
        font-size: 16px !important; }
    .free-plan-card-wraper .price-card span {
      font-size: 12px;
      color: #898989; }
    .free-plan-card-wraper .price-card button {
      background-color: #fff !important;
      border-radius: 2px;
      border: 1px solid #536be8;
      padding-left: 68px !important;
      padding-right: 68px !important;
      margin-top: 12px;
      width: 100%; }
      .free-plan-card-wraper .price-card button span {
        font-size: 14px;
        color: #536be8;
        font-weight: 500; }
  .free-plan-card-wraper .right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    grid-column-gap: 16px;
    column-gap: 16px;
    grid-row-gap: 24px;
    row-gap: 24px;
    flex: 2 1; }
    .free-plan-card-wraper .right .illustration-wraper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      grid-gap: 22px;
      gap: 22px; }
      .free-plan-card-wraper .right .illustration-wraper svg {
        display: block;
        min-width: 72px; }
      .free-plan-card-wraper .right .illustration-wraper p {
        color: #212121;
        font-size: 12px;
        width: 194px;
        font-family: "Helvetica", "Arial", sans-serif !important;
        line-height: 18px; }
        .free-plan-card-wraper .right .illustration-wraper p span {
          text-decoration: underline; }
    .free-plan-card-wraper .right .features-card .features {
      border-radius: 2px;
      display: flex;
      flex-wrap: wrap;
      width: 75%;
      grid-column-gap: 36px;
      column-gap: 36px;
      grid-row-gap: 15px;
      row-gap: 15px;
      font-size: 12px;
      color: #212121; }

@media only screen and (max-width: 600px) {
  .free-plan-card-wraper .illustration-wraper p {
    width: 100% !important; }
  .free-plan-card-wraper .features {
    width: 100% !important; } }

.transaction-complete-wraper {
  padding: 1rem;
  padding-top: 3rem;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .transaction-complete-wraper .payment-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; }
    .transaction-complete-wraper .payment-icon > div {
      height: 300px !important;
      width: 300px !important; }
    .transaction-complete-wraper .payment-icon svg {
      font-size: 240px; }
  .transaction-complete-wraper h1 {
    margin-top: 32px;
    text-align: center; }
  .transaction-complete-wraper h2 {
    text-align: center;
    margin-top: 12px; }

.coupon-code-field {
  margin-bottom: 4px !important; }
  .coupon-code-field input {
    height: 34px; }
  .coupon-code-field .ant-input-group-addon {
    padding: 0px !important; }
    .coupon-code-field .ant-input-group-addon > button {
      border: none;
      margin-top: 0px;
      box-shadow: none; }
  .coupon-code-field .anticon-spin {
    fill: blue; }

.card-footer .ant-divider {
  margin: 0 !important;
  background-color: #898989 !important; }

.card-footer .total-amount {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 12px; }
  .card-footer .total-amount h1 {
    font-size: 14px;
    color: #898989; }
  .card-footer .total-amount h2 {
    color: #212121;
    font-size: 20px;
    font-weight: 600; }
    .card-footer .total-amount h2 .custom-price-strike h3 {
      margin: 0;
      color: green;
      font-weight: 600;
      font-size: 18px; }
    .card-footer .total-amount h2 .custom-price-strike p {
      text-decoration: line-through;
      color: #898989;
      font-size: 14px; }

.card-footer .terms {
  text-align: center;
  font-size: 12px;
  color: #898989;
  margin-top: 12px; }
  .card-footer .terms a {
    color: #5171f8;
    text-decoration: underline; }

.stripe-screen-wraper .stripe-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 44px; }
  .stripe-screen-wraper .stripe-header h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px; }

.stripe-screen-wraper .stripe-card-wraper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 32px;
  gap: 32px; }
  .stripe-screen-wraper .stripe-card-wraper .payment-element-wraper h1 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px; }
  .stripe-screen-wraper .stripe-card-wraper .payment-element-wraper .payment-element {
    width: 500px;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px;
    background-color: #f7fafc !important; }
    .stripe-screen-wraper .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper {
      display: flex;
      align-items: center;
      justify-content: center; }
      .stripe-screen-wraper .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper button {
        height: 40px;
        border: 1px solid #536be8;
        margin-bottom: 8px;
        margin-top: 20px;
        padding-left: 40px;
        padding-right: 40px;
        background-color: #536be8; }
        .stripe-screen-wraper .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper button i svg {
          fill: #fff !important; }
        .stripe-screen-wraper .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper button span {
          color: #fff;
          font-size: 14px;
          font-weight: 600; }
  .stripe-screen-wraper .stripe-card-wraper .payment-element-wraper .secure {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 6px;
    gap: 6px;
    color: #898989;
    font-size: 14px;
    margin-top: 20px; }
  .stripe-screen-wraper .stripe-card-wraper .preview-card-wraper .plan-card {
    padding-bottom: 14px !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    padding-bottom: 20px; }
    .stripe-screen-wraper .stripe-card-wraper .preview-card-wraper .plan-card .price-card {
      grid-gap: 0px !important;
      gap: 0px !important; }
    .stripe-screen-wraper .stripe-card-wraper .preview-card-wraper .plan-card .bottom-bar {
      display: none; }
  .stripe-screen-wraper .stripe-card-wraper .preview-card-wraper .shaker-card.plan-card {
    margin-top: 0px !important; }
  .stripe-screen-wraper .stripe-card-wraper .preview-card-wraper .shaker-cards-wraper {
    grid-gap: 8px !important;
    gap: 8px !important; }
  .stripe-screen-wraper .stripe-card-wraper .preview-card-wraper .change-plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px; }
    .stripe-screen-wraper .stripe-card-wraper .preview-card-wraper .change-plan h1 {
      font-size: 16px;
      font-weight: 600; }
    .stripe-screen-wraper .stripe-card-wraper .preview-card-wraper .change-plan h2 {
      font-size: 12px;
      font-weight: 400;
      color: #536be8;
      cursor: pointer;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 4px;
      gap: 4px; }
      .stripe-screen-wraper .stripe-card-wraper .preview-card-wraper .change-plan h2 svg {
        fill: #536be8; }

@media screen and (max-width: 800px) {
  .stripe-screen-wraper {
    padding: 12px; }
    .stripe-screen-wraper .stripe-header h1 {
      text-align: center; }
    .stripe-screen-wraper .payment-element {
      width: 450px !important; } }

@media screen and (max-width: 500px) {
  .ant-modal.app-subscription-modal.screen-2 {
    width: calc(100vw - 16px) !important; }
  .stripe-screen-wraper {
    padding: 12px; }
    .stripe-screen-wraper .stripe-header h1 {
      text-align: center; }
    .stripe-screen-wraper .payment-element {
      width: 300px !important; } }

.ant-modal-wrap {
  z-index: 1000; }

.app-subscription-modal {
  padding: 0px !important;
  width: 90vw !important;
  top: 42px !important;
  border-radius: 0px; }
  .app-subscription-modal .logout {
    margin-right: 40px;
    padding-top: 18px;
    cursor: pointer;
    color: blue;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 4px;
    gap: 4px; }
  .app-subscription-modal .scroller {
    max-height: 270px !important; }
  .app-subscription-modal .ant-skeleton {
    padding: 12rem !important; }
  .app-subscription-modal * {
    letter-spacing: 0.032em !important; }
  .app-subscription-modal .ant-tabs-bar.ant-tabs-top-bar {
    display: none !important; }
  .app-subscription-modal h1,
  .app-subscription-modal h2,
  .app-subscription-modal h3,
  .app-subscription-modal p {
    margin: 0px; }
  .app-subscription-modal .ant-modal-content {
    border-radius: 8px !important; }
  .app-subscription-modal .ant-modal-body {
    padding: 0px !important;
    border-radius: 0px; }

.app-subscription-modal.screen-2 {
  width: 65vw !important; }
  .app-subscription-modal.screen-2 .ant-modal-content {
    border-radius: 8px !important; }
  .app-subscription-modal.screen-2 .stripe-card-wraper {
    margin-bottom: 22px !important; }

.app-subscription-modal.screen-3 {
  width: 65vw !important; }
  .app-subscription-modal.screen-3 .ant-modal-content {
    border-radius: 8px !important; }

.header {
  background: white;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 100px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .header .global-search {
    max-width: 500px;
    margin: auto;
    display: flex;
    line-height: 0; }
    .header .global-search input {
      padding: 12px 16px;
      border: 2px solid #fd104d;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      outline: none !important;
      flex: 1 1;
      font-size: var(--small-font-size);
      max-height: 40px; }
    .header .global-search button {
      background: #fd104d;
      color: white;
      padding: 12px 30px;
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border: 2px solid #fd104d;
      font-size: var(--small-font-size); }
  .header .navbar-ham {
    display: none; }
  .header .menu-left {
    line-height: 1.125rem;
    border: 0;
    display: flex !important;
    align-items: center !important;
    text-align: left !important;
    height: 60px; }
    .header .menu-left .ant-alert {
      font-size: var(--small-font-size); }
  .header .menu-right {
    float: right;
    border-bottom: none !important; }
    .header .menu-right .download-route a {
      display: flex;
      margin-bottom: 2px;
      margin-right: 8px; }
      .header .menu-right .download-route a i {
        font-size: 18px; }
    .header .menu-right .plan-info {
      border-radius: 4px;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-right: 12px;
      border-radius: 2px;
      padding: 5px 10px;
      background: #f5fafe;
      border: 1px solid #b5daff; }
      .header .menu-right .plan-info .red {
        color: red !important;
        border: none !important; }
      .header .menu-right .plan-info .green {
        color: green;
        border: none !important; }
      .header .menu-right .plan-info .blue {
        color: #1818b8;
        border: none !important; }
      .header .menu-right .plan-info .ng-margin {
        margin-left: 6px; }
      .header .menu-right .plan-info svg {
        height: 18px !important;
        width: 18px; }
      .header .menu-right .plan-info .plan-name {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: #52b4bf;
        font-size: 14px;
        text-transform: capitalize; }
      .header .menu-right .plan-info p {
        line-height: 18px;
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        grid-gap: 4px;
        gap: 4px;
        color: #212121;
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden; }
  .header .help-notification {
    display: flex !important;
    align-items: center; }
    .header .help-notification .help-icon {
      display: block; }
    .header .help-notification .help-icon-hover {
      display: none; }
  .header .help-notification:hover .help-icon {
    display: none; }
  .header .help-notification:hover .help-icon-hover {
    display: block; }
  .header .shopify-notification {
    display: flex !important;
    align-items: center; }
    .header .shopify-notification .icon-without-hover {
      display: block; }
    .header .shopify-notification .icon-with-hover {
      display: none; }
  .header .shopify-notification:hover .icon-without-hover {
    display: none; }
  .header .shopify-notification:hover .icon-with-hover {
    display: block; }
  .header .ant-menu {
    display: inline-block;
    font-size: var(--small-font-size); }
    .header .ant-menu .ant-menu-item,
    .header .ant-menu .ant-menu-item-selected {
      border-bottom: 0;
      padding: 0 0px;
      font-size: var(--small-font-size);
      font-weight: 400; }
  .header .navbar-user-detail {
    background: #f5faff;
    padding: 8px 5px !important; }
  .header .ant-avatar {
    background-color: #fc4747;
    border: 2px solid white;
    box-sizing: content-box; }
  .header .avatar-details {
    display: inline-block;
    font-weight: 500;
    margin: 0 10px;
    font-size: var(--small-font-size);
    line-height: 20px;
    color: #212121;
    text-transform: capitalize;
    vertical-align: middle; }
    .header .avatar-details span {
      font-size: 11px;
      font-weight: 400; }
  .header .ant-dropdown-menu-item a {
    padding: 10px 25px; }
  .header .div a {
    display: flex;
    font-size: var(--small-font-size);
    font-weight: 400;
    flex-direction: column;
    justify-content: center; }
    .header .div a span:first-of-type {
      margin-bottom: 0px; }
  .header .wallet {
    background: #f5faff;
    font-size: var(--small-font-size) !important;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: #212121; }
  .header .walletParent {
    margin-left: 1rem !important; }
    .header .walletParent div {
      border: 1px solid #b5daff; }

.shopify_img {
  width: 16px;
  height: 16px; }

.woocommerce_img {
  margin-right: 4px; }

.ogoWoocommerce_img {
  width: 32px;
  margin-right: 4px; }

.wocommerce_shopify {
  display: flex;
  justify-content: center;
  align-items: center; }

.storeStatusIcon svg {
  font-size: 32px !important; }

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  margin-right: 10px;
  min-width: auto; }

.navhead {
  position: absolute;
  width: auto;
  top: 0;
  z-index: 999; }

.upnav {
  transform: translateY(-100%);
  transition: all 0.3s linear; }

.downnav {
  transform: translateY(0%);
  transition: all 0.3s linear; }

.up2 {
  transform: translateY(-100%);
  transition: all 0.3s linear;
  box-shadow: none !important; }

.down2 {
  transform: translateY(0%);
  transition: all 0.3s linear;
  box-shadow: none !important; }

.header.full-width {
  width: 100%; }

.userli {
  font-size: var(--small-font-size) !important;
  display: flex;
  align-items: center;
  height: 40px;
  padding-right: 8px !important; }
  .userli .left_Store {
    width: 48%;
    display: flex;
    align-items: center; }
  .userli .right_store {
    width: 48%;
    display: flex;
    align-items: center; }
  .userli p {
    margin-left: 0 !important; }

.header.full-width {
  padding: 0 72px 0 0;
  box-shadow: none;
  z-index: 1; }

@media (max-width: 768px) {
  .header .ant-menu {
    display: none; }
  .header.ant-layout-header .navbar-ham {
    display: block;
    float: right; } }

.shop {
  margin-top: 22px;
  z-index: 999; }

.helpnav {
  margin-top: 11px; }

.usernav {
  margin-top: 11px; }

.userStore {
  margin-top: 16px !important;
  text-transform: capitalize;
  position: relative;
  margin: 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px #00000015;
  -webkit-transform: translate3d(0, 0, 0); }
  .userStore .userStore-nested {
    border-radius: 4px; }

.store-dropdown-list {
  position: relative; }

.add-shopify-store {
  position: absolute;
  bottom: 0;
  height: 39px;
  cursor: pointer;
  background: #fff;
  width: 100% !important;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-left: 16px;
  border-top: 1px solid #dfdfdf;
  box-shadow: 0px -1px 10px #00000010;
  margin-bottom: 0px !important; }

.storenav:hover {
  background: #f5faff; }

.add-shopify-store:hover {
  background: #f5faff; }

@media screen and (max-width: 1438px) {
  .navhead {
    position: absolute;
    width: calc(100% - 200px);
    top: 0;
    padding: 0 3.5rem;
    z-index: 999; } }

@media screen and (min-width: 1439px) and (max-width: 1445px) {
  .navhead {
    position: absolute;
    width: calc(100% - 200px);
    top: 0;
    padding: 0 104px !important;
    z-index: 999; }
  .header.full-width {
    width: 90%;
    padding-left: 5% !important;
    padding-right: 5.5% !important; } }

@media screen and (min-width: 1440px) and (max-width: 3200px) {
  .navhead {
    position: absolute;
    width: calc(100% - 240px);
    top: 0;
    z-index: 999;
    padding: 0 3.5rem; }
  .header.full-width {
    width: 100%;
    padding-left: 5% !important;
    padding-right: 5.5% !important; } }

.vis {
  top: 56px !important; }

.ant-spin.ant-spin-spinning {
  border: none !important; }

.plan-link a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 6px;
  gap: 6px; }

.notification {
  width: 300px;
  padding: 0px;
  border-radius: 3px; }
  .notification .img-wraper {
    margin-right: 16px;
    min-width: 56px;
    height: 56px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e8e8e8; }
  .notification a {
    display: flex;
    padding: 16px 16px 13px;
    border-bottom: 1px solid #f5f5f5;
    margin: 0px -12px 0px 0px; }
  .notification p {
    font-size: var(--extra-medium-small-font-size);
    margin-bottom: 0px;
    color: #898989; }
    .notification p span {
      color: #898989;
      font-weight: 100;
      font-size: var(--small-font-size);
      white-space: break-spaces;
      line-height: 16px;
      padding: 3px 0px;
      letter-spacing: 0.032em; }
  .notification span {
    color: #898989;
    font-weight: 400;
    font-size: 13px;
    float: right;
    letter-spacing: 0.032em; }
  .notification .notification-footer .ant-dropdown-menu-item-group-title {
    padding: 0px !important; }
  .notification .notification-heading {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    word-break: break-all;
    white-space: break-spaces;
    padding: 3px 0px 0px;
    text-decoration: underline;
    letter-spacing: 0.032em; }
  .notification .notification-header-color {
    color: #212121; }

.notification:hover p {
  color: #212121; }

.notification-header:hover {
  background: #fff; }

.notification-header button:hover {
  text-decoration: underline; }

.notification:hover a {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe; }

.notifications-dropdown {
  padding: 0px 10px 0px 10px;
  overflow: scroll;
  max-height: 345px;
  width: 312px;
  margin-top: 1.5rem; }

.notification-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  position: -webkit-sticky;
  position: sticky;
  background: white;
  top: 0;
  border-bottom: 1px solid #f5f5f5; }
  .notification-header p {
    margin-bottom: 0px;
    font-size: var(--small-font-size);
    font-weight: 500;
    color: #212121;
    letter-spacing: 0.032em; }
  .notification-header button {
    border: none;
    font-size: var(--extra-small-font-size);
    font-weight: 500;
    color: #000000b8;
    background: transparent;
    width: unset;
    padding: 0px;
    cursor: pointer;
    font-size: 12px;
    color: #0043ff;
    letter-spacing: 0.032em;
    font-weight: 400; }

button:focus {
  outline: unset; }

.notification-footer {
  padding: 0px;
  background: white; }
  .notification-footer ul {
    padding-left: 0px; }
    .notification-footer ul li {
      padding: 0px; }
  .notification-footer button {
    width: 100%;
    border: none;
    border-radius: 0px;
    background: #f9f9fb;
    padding: 10px;
    font-size: var(--small-font-size);
    font-weight: 500;
    letter-spacing: 0.032em;
    color: #548fd9;
    cursor: pointer; }
  .notification-footer .notification-detail-view {
    text-align: center;
    color: #2198ff;
    font-size: 12px;
    cursor: pointer;
    margin: 0; }

.nav-notification {
  display: flex !important;
  align-items: center; }
  .nav-notification .bell-without-hover {
    display: block;
    font-size: 21px;
    margin-right: 0px;
    color: black; }
  .nav-notification .bell-on-hover {
    display: none;
    font-size: 21px;
    margin-right: 0px;
    vertical-align: middle; }
  .nav-notification .notification-arrow {
    margin-left: 5px;
    font-size: 16px;
    transition: all 0.5s; }
  .nav-notification .notification-arrow.transform-up {
    transform: rotateX(180deg); }
    .nav-notification .notification-arrow.transform-up g {
      fill: #898989; }

.nav-notification:hover .bell-on-hover {
  display: block; }

.nav-notification:hover .bell-without-hover {
  display: none; }

.ant-badge {
  display: flex;
  align-items: center; }

.ant-badge-count {
  right: 25px; }

.sidebar {
  background: #192f4a;
  height: calc(100vh);
  overflow: auto;
  min-width: 200px;
  transition: all 0.3s linear;
  z-index: 999; }
  .sidebar .home svg {
    fill: none !important; }
  .sidebar .logo {
    background: #16273b;
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-self: center; }
    .sidebar .logo a {
      display: block; }
      .sidebar .logo a img {
        padding: 18px 23px;
        display: block;
        margin: auto;
        width: 75%; }
  .sidebar ul.ant-menu {
    background: #192f4a; }
    .sidebar ul.ant-menu .ant-menu-submenu {
      min-height: 2rem !important;
      margin-bottom: 8px; }
    .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
      min-height: 2rem !important;
      font-size: var(--small-font-size); }
    .sidebar ul.ant-menu a,
    .sidebar ul.ant-menu a:active,
    .sidebar ul.ant-menu a:focus,
    .sidebar ul.ant-menu a:hover {
      color: rgba(255, 255, 255, 0.65);
      text-decoration: none;
      font-weight: 400;
      letter-spacing: 0.3px;
      font-size: var(--small-font-size); }
    .sidebar ul.ant-menu i.anticon {
      margin-top: -2px;
      margin-right: 0.5rem !important;
      font-size: 1rem !important; }
    .sidebar ul.ant-menu .products-count {
      font-size: var(--small-font-size);
      background: red;
      padding: 2px 10px;
      color: white;
      border-radius: 13px;
      display: inline-block;
      height: 30px;
      line-height: 20px;
      min-width: 30px;
      max-width: 60px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 7px; }
    .sidebar ul.ant-menu .orders-count {
      font-size: var(--small-font-size);
      background: linear-gradient(135deg, #0077ff, #000aff);
      color: white;
      padding: 2px 10px;
      border: 0.25px solid white;
      border-radius: 13px;
      display: inline-block;
      height: 25px;
      line-height: 20px;
      min-width: 30px;
      max-width: 60px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 7px; }
    .sidebar ul.ant-menu .sidebar-count {
      margin-right: 10px;
      float: right; }
    .sidebar ul.ant-menu .anticon {
      font-size: 1rem;
      vertical-align: middle;
      opacity: 1 !important; }
    .sidebar ul.ant-menu .ant-menu-item {
      margin: 0;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      font-size: var(--medium-font-size);
      padding-left: 1rem !important;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 8px;
      height: 2rem !important;
      line-height: 2rem; }
    .sidebar ul.ant-menu .ant-menu-item:hover a {
      color: white; }
    .sidebar ul.ant-menu .ant-menu-item:active {
      background: transparent; }
    .sidebar ul.ant-menu .ant-menu-item-selected {
      background: #081321;
      width: 100%; }
      .sidebar ul.ant-menu .ant-menu-item-selected a {
        color: white; }
    .sidebar ul.ant-menu :after {
      display: none; }
    .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
      margin: 0;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.65);
      padding-right: 1rem;
      padding-left: 1rem !important; }
    .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-submenu-title:active {
      background: inherit; }
    .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-submenu-title:hover {
      color: white; }
    .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-submenu-arrow {
      display: none; }
    .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-sub .ant-menu-item {
      padding-left: 2.5rem !important; }
    .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-sub .ant-menu-item-selected {
      background: #050c15;
      border-left: 8px solid #0074ff;
      padding-left: 2rem !important;
      width: 100%;
      height: 2.5rem; }
    .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-sub :after {
      display: none; }
    .sidebar ul.ant-menu .ant-menu-submenu-open.ant-menu-submenu-selected .ant-menu-submenu-title {
      color: white;
      background: #081321; }
    .sidebar ul.ant-menu .ant-menu-submenu-open .ant-menu-submenu-title {
      background-color: #081321 !important;
      color: white !important;
      height: 32px !important; }
  .sidebar .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: 2rem !important; }
  .sidebar .navbarFlex {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0; }
  .sidebar .navbarFlex2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0; }
    .sidebar .navbarFlex2 a {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center; }
  .sidebar .navbarFlex3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 100%; }
    .sidebar .navbarFlex3 .prc {
      position: relative;
      background: #ff006c;
      color: white;
      font-size: var(--small-font-size);
      padding: 2px 10px;
      color: white;
      border-radius: 13px;
      display: inline-block;
      height: 1.5rem;
      min-width: 1.5rem;
      max-width: 3.3rem;
      vertical-align: middle;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: flex-end;
      align-items: center; }
  .sidebar .svI {
    color: white;
    fill: white; }
  .sidebar .navbarFlex3 > span {
    margin-right: 10px; }

.prc2 {
  position: absolute;
  background-image: linear-gradient(to right, #0063f2, #4398ed);
  color: white;
  font-size: var(--small-font-size);
  padding: 2px 10px;
  color: white;
  border-radius: 2px;
  display: inline-block;
  height: 1.5rem;
  min-width: 1.5rem;
  max-width: 3.3rem;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%); }

.navbarFlex2 .prc {
  position: relative;
  background: #ff006c;
  color: white;
  font-size: var(--small-font-size);
  padding: 2px 10px;
  color: white;
  border-radius: 13px;
  display: inline-block;
  height: 1.5rem;
  min-width: 1.5rem;
  max-width: 3.3rem;
  vertical-align: middle;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: -0.5rem; }

@media screen and (min-width: 180px) and (max-width: 1439px) {
  .sidebar {
    background: #192f4a;
    height: calc(100vh);
    overflow: auto;
    min-width: 200px !important;
    max-width: 200px !important;
    transition: all 0.3s linear; }
  .sidebar .navbarFlex2 a {
    margin-right: 1.5rem; }
  .prc2 {
    right: 0.65rem; } }

@media screen and (min-width: 1440px) and (max-width: 3200px) {
  .sidebar {
    background: #192f4a;
    height: calc(100vh);
    overflow: auto;
    min-width: 240px !important;
    max-width: 240px !important;
    transition: all 0.3s linear; }
  .sidebar ul.ant-menu .ant-menu-item {
    padding-left: 1.5rem !important;
    padding-right: 2rem !important;
    transition: all 0.3s linear; }
  .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    transition: all 0.3s linear; }
  .sidebar ul.ant-menu i.anticon {
    margin-right: 0.5rem !important;
    transition: all 0.3s linear; }
  .sidebar .navbarFlex2 a {
    width: 100% !important;
    transition: all 0.3s linear; }
  .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-sub .ant-menu-item {
    padding-left: 3rem !important;
    transition: all 0.3s linear; }
  .sidebar ul.ant-menu .ant-menu-submenu .ant-menu-sub .ant-menu-item-selected {
    padding-left: 2.5rem !important; }
  .sidebar ul.ant-menu .sidebar-count {
    margin-right: 0 !important; } }

.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 32px !important; }

.upheader {
  margin-top: 0px !important; }

.downheader {
  margin-top: 56px !important; }

.feedback-widget-overlay {
  left: auto !important;
  right: 18px !important; }

.lay {
  padding-top: 5.25rem; }

.lay2 {
  padding-top: 4.1rem; }

.dashboard-header {
  background: #f7f7fc;
  color: #000; }

.dashboard-content {
  padding: 1.25rem 3.5rem;
  width: 100%;
  margin: auto; }

.dashboard-content.no-sidebar {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-top: -20px;
  overflow-y: scroll;
  scroll-behavior: smooth; }

.dashboard-content > div {
  padding-bottom: 50px; }

@media (max-width: 768px) {
  .dashboard-content {
    padding: 15px; } }

@media screen and (max-width: 1438px) {
  .dashboard-content {
    padding: 1.25rem 3.5rem;
    width: 100%;
    margin: auto; } }

@media screen and (min-width: 1439px) and (max-width: 1445px) {
  .dashboard-content {
    padding: 1.25rem 104px;
    width: 100%;
    margin: auto; }
  .dashboard-content-padding {
    padding: 1.25rem 65px; } }

.ant-layout {
  background: #f0f3f7; }

.clickable.link {
  padding: 8px 12px; }

.store-chanel-modal .ant-modal-header {
  padding: 16px 8px !important;
  border-bottom: 1px solid #cecece !important;
  display: flex;
  margin: 0 auto;
  width: 456px; }

.store-chanel-modal .title_txt {
  font-size: 14px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 500; }

.store-chanel-modal .ant-modal-close-x {
  display: none; }

.store-chanel-modal .ant-modal-content {
  border-radius: 4px; }

.store-chanel-modal .footer_button_continue {
  padding: 16px 2px !important;
  border-top: 1px solid #cecece !important;
  display: flex;
  margin: 0 auto;
  margin-bottom: -16px;
  justify-content: flex-end; }

.store-chanel-modal .ant-modal-body {
  padding: 24px !important; }

.store-chanel-modal .shopify_and_wooCommerece {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px; }

.store-chanel-modal .flag_radio_btn {
  display: flex;
  justify-content: space-between; }
  .store-chanel-modal .flag_radio_btn .Wocoomerce-type-card {
    height: 136px;
    width: 136px;
    border: 1px solid #212121;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    margin-right: 16px;
    cursor: pointer;
    transition: all 0.3s linear; }
    .store-chanel-modal .flag_radio_btn .Wocoomerce-type-card .footer {
      flex: unset;
      width: 240px;
      font-size: 12px;
      color: white;
      padding: 8px 0;
      background: #36c626;
      position: relative;
      left: -9px;
      top: 3px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .store-chanel-modal .flag_radio_btn .Wocoomerce-type-card .anticon {
      margin: auto; }
      .store-chanel-modal .flag_radio_btn .Wocoomerce-type-card .anticon svg {
        width: 80px;
        height: 80px; }
  .store-chanel-modal .flag_radio_btn .Shopify-type-card {
    height: 136px;
    width: 136px;
    border: 1px solid #212121 !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    margin-right: 16px;
    cursor: pointer;
    transition: all 0.3s linear; }
    .store-chanel-modal .flag_radio_btn .Shopify-type-card .footer {
      flex: unset;
      width: 240px;
      font-size: 12px;
      color: white;
      padding: 8px 0;
      background: #36c626;
      position: relative;
      left: -9px;
      top: 3px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .store-chanel-modal .flag_radio_btn .Shopify-type-card .anticon {
      margin: auto; }
      .store-chanel-modal .flag_radio_btn .Shopify-type-card .anticon svg {
        width: 80px;
        height: 80px; }
  .store-chanel-modal .flag_radio_btn .custome_store-card {
    height: 136px;
    width: 136px;
    border: 1px solid #212121 !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
    transition: all 0.3s linear; }
    .store-chanel-modal .flag_radio_btn .custome_store-card .footer {
      flex: unset;
      width: 240px;
      font-size: 12px;
      color: white;
      padding: 8px 0;
      background: #36c626;
      position: relative;
      left: -9px;
      top: 3px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .store-chanel-modal .flag_radio_btn .custome_store-card .anticon {
      margin: auto; }
      .store-chanel-modal .flag_radio_btn .custome_store-card .anticon svg {
        width: 80px;
        height: 80px; }
  .store-chanel-modal .flag_radio_btn .logo_txt {
    font-size: 12px;
    color: #000000;
    letter-spacing: 0.032em;
    line-height: 24px;
    margin-top: 4px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px; }
  .store-chanel-modal .flag_radio_btn .continue_next {
    display: none; }

.store-chanel-modal .continue_btn {
  flex: unset;
  width: 238px;
  font-size: 12px;
  color: white;
  padding: 8px 0 !important;
  background: #36c626;
  position: relative;
  left: -2px;
  top: 2px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  text-align: center; }

.store-chanel-modal .Wocoomerce-type-card:hover {
  border: 2px solid #212121;
  background: #95bf46;
  transition: all 0.3s linear; }

.store-chanel-modal .shopify_flag_radio_btn * {
  text-transform: capitalize !important; }

.store-chanel-modal .Shopify-type-card:hover {
  border: 2px solid #212121;
  background: #7f54b3;
  transition: all 0.3s linear; }

.store-chanel-modal .custome_store-card:hover {
  border: 2px solid #212121;
  background: #56a1bf;
  transition: all 0.3s linear; }

.store-chanel-modal .store_objective {
  margin-bottom: -8px;
  letter-spacing: 0.032em;
  font-size: 12px;
  line-height: 24px;
  margin-top: 6px;
  color: #212121;
  font-weight: 400; }

.store-chanel-modal .Shopify_flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .store-chanel-modal .Shopify_flag img {
    width: 80px;
    height: 80px; }

.store-chanel-modal .wocommerce_flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .store-chanel-modal .wocommerce_flag img {
    width: 80px;
    height: 80px; }

.store-chanel-modal .ant-radio-inner {
  border-color: #fff !important;
  display: none !important; }

.store-chanel-modal .ant-radio-wrapper {
  width: 100%;
  height: 100%; }

.store-chanel-modal .wocommerce_flag span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.store-chanel-modal .Shopify_flag span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.no-table-data {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.11rem 0; }
  .no-table-data svg {
    width: 21.51rem;
    height: 16.44rem;
    max-width: 100%;
    margin-right: 1.36rem; }
  .no-table-data .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 24.21rem; }
    .no-table-data .text .heading {
      font-size: 1.43rem;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0.36rem; }
    .no-table-data .text .content {
      text-align: left;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 0;
      font-size: 1.143rem;
      font-weight: 400; }

.important-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 3.9rem;
  padding-right: 3.9rem;
  background-color: #fbc52d;
  height: 56px;
  position: absolute;
  top: 0;
  z-index: 1000; }
  .important-info .imp-div {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .important-info .imp-div .ic {
      margin-right: 12px;
      display: flex; }
    .important-info .imp-div h5 {
      margin: 0;
      padding: 0;
      text-decoration: underline;
      margin-right: 8px;
      font-size: 12px;
      font-weight: 500; }
    .important-info .imp-div p {
      margin: 0;
      padding: 0;
      margin-right: 12px;
      font-size: 12px;
      font-weight: 500; }
    .important-info .imp-div button {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      width: 147px; }

.update-store-modal {
  width: 424px !important; }

.imp-modal {
  padding: 26px 27px 24px 27px;
  width: 424px !important;
  border-radius: 2px; }
  .imp-modal * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }
  .imp-modal .icon {
    margin: auto;
    width: 80px;
    margin-bottom: 16px; }
  .imp-modal h1 {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #212121;
    margin-bottom: 4px;
    letter-spacing: 0.03em; }
  .imp-modal h2 {
    color: #898989;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.03em; }
  .imp-modal h3 {
    color: #898989;
    font-size: 12px;
    text-align: center;
    margin-bottom: 18px;
    font-weight: 400;
    letter-spacing: 0.03em; }
  .imp-modal .logo {
    height: 122px;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 2px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .imp-modal .logo svg {
      margin-left: 24px; }
    .imp-modal .logo .vc {
      height: 71px;
      width: 67px;
      background: gray; }
  .imp-modal p {
    margin-bottom: 14px;
    font-size: 12px;
    letter-spacing: 0.03em;
    color: #212121; }
    .imp-modal p a {
      color: #1370fc;
      text-decoration: underline !important; }
    .imp-modal p svg {
      margin-left: 4px; }
  .imp-modal .btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px; }
    .imp-modal .btn button:first-of-type {
      width: 155px;
      margin-right: 6;
      height: 32px; }
    .imp-modal .btn button:last-of-type {
      display: block;
      margin-left: 6px;
      height: 32px;
      width: 160px;
      color: #898989;
      font-size: 12px;
      border: 1px solid #898989;
      border-radius: 2px;
      background-color: transparent;
      font-weight: 400;
      cursor: pointer; }

.update-store-modal .update-store-modal {
  padding: 0;
  margin: 0; }

.update-store-modal .ant-modal-body {
  padding: 0;
  margin: 0; }

.update-store-modal .ant-modal-close-x {
  display: none; }

.arrow-image {
  height: 12px;
  position: absolute;
  animation: myfirst 0.5s infinite linear;
  animation-direction: alternate;
  margin: auto;
  margin-top: 7% !important;
  margin-left: -30% !important; }

@keyframes myfirst {
  0% {
    left: 0px;
    top: 0px; }
  100% {
    left: 20px;
    top: 0px; } }

.switch-store-modal-second .ant-modal-header {
  padding: 16px 8px !important;
  border-bottom: 1px solid #cecece !important;
  display: flex;
  margin: 0 auto;
  width: 456px; }

.switch-store-modal-second .title_txt {
  font-size: 14px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 500; }

.switch-store-modal-second .ant-modal-close-x {
  display: none; }

.switch-store-modal-second .ant-modal-content {
  border-radius: 0px; }

.switch-store-modal-second .footer_button_continue {
  padding: 16px 2px !important;
  border-top: 1px solid #cecece !important;
  display: flex;
  margin: 16px auto;
  width: 456px;
  margin-bottom: -16px;
  justify-content: flex-end; }

.switch-store-modal-second .ant-modal-body {
  padding: 24px !important; }

.switch-store-modal-second .global_and_indian_dropshipping {
  display: flex;
  justify-content: center;
  align-items: center; }

.switch-store-modal-second .flag_radio_btn {
  display: flex;
  justify-content: space-between; }
  .switch-store-modal-second .flag_radio_btn .Global-type-card {
    height: 136px;
    width: 136px;
    border: 1px solid #212121;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    margin-right: 16px;
    transition: all 0.3s linear;
    cursor: pointer; }
    .switch-store-modal-second .flag_radio_btn .Global-type-card .anticon {
      margin: auto; }
      .switch-store-modal-second .flag_radio_btn .Global-type-card .anticon svg {
        width: 80px;
        height: 80px; }
  .switch-store-modal-second .flag_radio_btn .India-type-card {
    height: 136px;
    width: 136px;
    border: 1px solid #212121;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    transition: all 0.3s linear;
    cursor: pointer; }
    .switch-store-modal-second .flag_radio_btn .India-type-card .anticon {
      margin: auto; }
      .switch-store-modal-second .flag_radio_btn .India-type-card .anticon svg {
        width: 80px;
        height: 80px; }
  .switch-store-modal-second .flag_radio_btn .flag {
    font-size: 12px;
    color: #000000;
    letter-spacing: 0.032em;
    line-height: 24px;
    margin-top: 4px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center; }

.switch-store-modal-second .India-type-card span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.switch-store-modal-second .Global-type-card span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.switch-store-modal-second .flag_radio_btn .Global-type-card:hover {
  border: 2px solid #212121;
  background: #ea8634;
  transition: all 0.3s linear; }

.switch-store-modal-second .flag_radio_btn .India-type-card:hover {
  border: 2px solid #212121;
  background: #03aa6f;
  transition: all 0.3s linear; }

.switch-store-modal-second .continue_btn {
  flex: unset;
  width: 238px;
  font-size: 12px;
  color: white;
  padding: 8px 0 !important;
  background: #36c626;
  position: relative;
  left: -2px;
  top: 2px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  text-align: center; }

.switch-store-modal-second .store_objective {
  margin-bottom: -8px;
  letter-spacing: 0.032em;
  font-size: 12px;
  line-height: 24px;
  margin-top: 6px;
  color: #212121;
  font-weight: 400; }

.switch-store-modal-second .indian_flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.switch-store-modal-second .global_flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.switch-store-modal-second .ant-radio-inner {
  border-color: #fff !important;
  display: none !important; }

.switch-store-modal-second .ant-radio-wrapper {
  width: 100%;
  height: 100%; }

.switch-store-modal-second .go_back_btn {
  margin-right: 8px;
  border: none;
  box-shadow: none;
  font-size: 12px;
  color: #212121;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center; }
  .switch-store-modal-second .go_back_btn .svg_go_back {
    margin-right: 4px; }
  .switch-store-modal-second .go_back_btn .go_back_txt {
    margin-top: -3px; }

.switch-store-modal-second .go_back_btn:hover {
  text-decoration: underline; }

.woCommerceModal .ant-modal-content {
  padding: 8px 0px;
  border-radius: 0px !important; }
  .woCommerceModal .ant-modal-content .ant-modal-header {
    padding: 16px 8px;
    width: 530px;
    margin: auto; }
  .woCommerceModal .ant-modal-content .flex-wraper {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .woCommerceModal .ant-modal-content .flex-wraper > div {
      margin-right: 3px; }
  .woCommerceModal .ant-modal-content .ant-modal-footer {
    padding: 16px 0px;
    text-align: left !important;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    width: 530px;
    margin: auto; }
  .woCommerceModal .ant-modal-content .bottom_button {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .woCommerceModal .ant-modal-content .bottom_button .clickable_btn {
      width: 120px;
      background: #28af21;
      height: 32px;
      border: none;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-right: 8px; }
    .woCommerceModal .ant-modal-content .bottom_button .cancel_close_btn {
      width: 120px;
      background: #f5f5f5;
      height: 32px;
      border: none;
      color: #898989;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-right: 8px; }
  .woCommerceModal .ant-modal-content .woCommerce_detail_input .common_label {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    width: 208px;
    margin-bottom: 4px; }
  .woCommerceModal .ant-modal-content .woCommerce_detail_input .upeer_label_margin {
    margin-right: 6px;
    margin-bottom: 16px; }
  .woCommerceModal .ant-modal-content .woCommerce_detail_input .shop_name {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }
  .woCommerceModal .ant-modal-content .woCommerce_detail_input .shop_url {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }
  .woCommerceModal .ant-modal-content .woCommerce_detail_input .shop_consumer_key {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }
  .woCommerceModal .ant-modal-content .woCommerce_detail_input .shop_consumer_secret {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }

.woCommerceModal .go_back_btn {
  border: none;
  box-shadow: none;
  font-size: 12px;
  color: #212121;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px; }
  .woCommerceModal .go_back_btn .svg_go_back {
    margin-right: 4px; }

.woCommerceModal .go_back_btn:hover {
  text-decoration: underline; }

.woCommerceModal .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 16px;
  font-size: 12px; }
  .woCommerceModal .modal-header p {
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin-bottom: 0px;
    letter-spacing: 0.032em;
    line-height: 16px; }

.woCommerceModal .ant-modal-close {
  display: none; }

.woCommerceModal .star {
  color: #c62929; }

.overdue-modal * {
  letter-spacing: 0.032em; }

.overdue-modal .ant-modal-header {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 24px;
  margin-right: 24px; }
  .overdue-modal .ant-modal-header .ant-modal-title h1 {
    margin: 0;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    color: #212121;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px; }
  .overdue-modal .ant-modal-header .ant-modal-title p {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #ff3434; }

.overdue-modal .ant-modal-body {
  padding-top: 12px !important; }
  .overdue-modal .ant-modal-body p {
    font-size: 12px !important;
    color: #212121 !important; }
    .overdue-modal .ant-modal-body p b {
      font-weight: 500; }
  .overdue-modal .ant-modal-body button {
    margin-top: 8px;
    height: 40px !important; }
  .overdue-modal .ant-modal-body .ant-btn-primary {
    margin-top: 16px !important;
    background-color: #2c9545 !important;
    border: 1px solid #2c9545; }
    .overdue-modal .ant-modal-body .ant-btn-primary span {
      font-weight: 500 !important;
      font-size: 14px; }
  .overdue-modal .ant-modal-body .ant-btn-cancel {
    border: 1px solid #212121; }
    .overdue-modal .ant-modal-body .ant-btn-cancel span {
      color: #212121 !important;
      font-weight: 500 !important;
      font-size: 14px; }

.shoipify_store_data .connect_shopify_store_btn {
  border-top: 1px solid #cecece !important;
  display: flex;
  justify-content: end;
  margin-bottom: -8px; }

.shoipify_store_data .connect_btn {
  margin-top: 16px;
  margin-right: 8px;
  width: 120px;
  background: #73d13d;
  border-color: #73d13d;
  color: #fff;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 24px;
  height: 32px; }

.shoipify_store_data .cancel_btn {
  margin-top: 16px;
  margin-right: 8px;
  width: 120px;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 24px;
  height: 32px; }

.shoipify_store_data .go_back_btn {
  margin-top: 16px;
  margin-right: 4px;
  height: 32px;
  border: none;
  box-shadow: none;
  font-size: 12px;
  color: #212121;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center; }
  .shoipify_store_data .go_back_btn .svg_go_back {
    margin-right: 4px; }
  .shoipify_store_data .go_back_btn .go_back_txt {
    margin-top: -3px; }

.shoipify_store_data .go_back_btn:hover {
  text-decoration: underline; }

.shoipify_store_data .ant-alert-info {
  background-color: #f5faff;
  border: 2px solid #0058d6;
  border-radius: 2px !important; }

.shoipify_store_data .ant-alert-info .ant-alert-icon {
  color: #0058d6; }

.shoipify_store_data .ant-alert-message {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #212121; }

.shoipify_store_data .shopify_label {
  margin-top: 10px;
  margin-bottom: 4px;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  margin-top: 16px; }

.shoipify_store_data .ant-input-suffix {
  right: 12px;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #212121; }

.shoipify_store_data .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 30px;
  font-size: 12px;
  border-radius: 0px;
  letter-spacing: 0.032em;
  line-height: 16px;
  background: #f5f5f5;
  border: none; }

.shoipify_store_data .shopify-store_link {
  margin-bottom: 16px;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  margin-top: -8px; }

.shoipify_store_data .has-error .ant-form-explain {
  color: #f5222d;
  font-size: 12px;
  margin-bottom: 4px;
  margin-top: 4px; }

.switch-store-modal3 .ant-modal-header {
  padding: 16px 8px !important;
  border-bottom: 1px solid #cecece !important;
  display: flex;
  margin: 0 auto;
  width: 580px; }

.switch-store-modal3 .title_txt {
  font-size: 14px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 500; }

.switch-store-modal3 .ant-modal-close-x {
  display: none; }

.connect-shopify-modal .ant-modal-footer {
  display: none; }

.connect-shopify-modal .ant-modal-close-x {
  display: none !important; }

.connect-shopify-modal .middle_data {
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  padding: 32px; }
  .connect-shopify-modal .middle_data p {
    margin-right: 32px; }

.connect-shopify-modal .footer_button {
  display: flex;
  justify-content: center; }

.connect-shopify-modal .ant-modal-header {
  border-bottom: none !important; }

.connect-shopify-modal .ant-modal-content {
  padding: 32px; }

.connect-shopify-modal .ant-modal-body {
  padding: 0 !important; }

.connect-shopify-modal .user_description {
  text-align: center;
  margin-bottom: 16px;
  margin-top: -16px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-size: 12px; }

.connect-shopify-modal .footer_description p {
  margin-bottom: 24px;
  color: #212121;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-size: 12px; }

.next-steps-card {
  margin-bottom: 2rem; }
  .next-steps-card .notification-app-reinstall {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 88px;
    border: 2px solid #ff005f;
    padding: 1rem;
    background: white;
    font-size: var(--extra-small-font-size);
    left: 0px;
    top: 0px; }
    .next-steps-card .notification-app-reinstall h3 {
      font-size: 16px;
      letter-spacing: 0.032em !important; }
    .next-steps-card .notification-app-reinstall .ic-wr {
      margin-left: 16px;
      letter-spacing: 0.032em !important; }
    .next-steps-card .notification-app-reinstall p {
      font-size: 14px; }
      .next-steps-card .notification-app-reinstall p a {
        padding: 0 !important;
        margin: 0 4px !important;
        text-decoration: underline !important; }
  .next-steps-card .quick-start {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1; }
    .next-steps-card .quick-start .left-quick-start {
      display: flex;
      align-items: flex-end; }
      .next-steps-card .quick-start .left-quick-start p {
        font-size: var(--medium-font-size);
        font-weight: 400;
        margin-bottom: 1rem; }
    .next-steps-card .quick-start .right-quick-start {
      margin-bottom: -21px;
      margin-right: 15px; }
      .next-steps-card .quick-start .right-quick-start img {
        max-width: 220px; }
      .next-steps-card .quick-start .right-quick-start .quick-start-jenny {
        width: 226px;
        height: 88px;
        border: 2px solid #ff005f;
        padding: 0.75rem;
        background: white;
        position: absolute;
        font-size: var(--extra-small-font-size);
        right: 185px;
        top: 20px; }
        .next-steps-card .quick-start .right-quick-start .quick-start-jenny h3 {
          font-size: var(--extra-small-font-size); }
      .next-steps-card .quick-start .right-quick-start h3 {
        font-size: var(--small-font-size);
        font-weight: 600;
        color: #222222;
        margin-bottom: 3px; }
  .next-steps-card .ant-collapse {
    background: #f0f2f5;
    position: relative;
    z-index: 3; }
    .next-steps-card .ant-collapse .ant-collapse-content-box {
      padding: 0px;
      padding-top: 0px !important; }
    .next-steps-card .ant-collapse .ant-collapse-item {
      background: white;
      border-radius: 4px;
      margin-bottom: 0.5rem;
      border: 0px;
      overflow: hidden;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
      .next-steps-card .ant-collapse .ant-collapse-item .ant-collapse-content {
        background-color: white; }
      .next-steps-card .ant-collapse .ant-collapse-item .ant-collapse-header {
        padding: 0 4rem 0 2rem;
        height: 4rem;
        color: #222222;
        font-size: var(--medium-font-size);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .next-steps-card .ant-collapse .ant-collapse-item .ant-collapse-header .anticon {
          font-size: 22px;
          margin-right: 10px; }
        .next-steps-card .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-extra {
          display: flex;
          align-items: center; }
          .next-steps-card .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-extra div {
            padding: 0px;
            margin: 0px; }
          .next-steps-card .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-extra span {
            font-size: var(--extra-medium-font-size); }
          .next-steps-card .ant-collapse .ant-collapse-item .ant-collapse-header .ant-collapse-extra span:last-child {
            font-size: var(--extra-medium-small-font-size) !important; }
        .next-steps-card .ant-collapse .ant-collapse-item .ant-collapse-header .step-complete {
          color: #3794f4;
          font-size: var(--small-font-size);
          font-weight: 500;
          margin-right: 0.25rem; }
      .next-steps-card .ant-collapse .ant-collapse-item .panel-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 6.5rem;
        padding: 0rem 4rem 1rem 4rem;
        border-bottom: 2px solid #f8f8f8; }
        .next-steps-card .ant-collapse .ant-collapse-item .panel-content .left-content {
          width: 60%; }
          .next-steps-card .ant-collapse .ant-collapse-item .panel-content .left-content h3 {
            color: #ff005b;
            font-size: var(--medium-font-size);
            font-weight: 400;
            line-height: 1.5rem;
            margin-bottom: 0px; }
          .next-steps-card .ant-collapse .ant-collapse-item .panel-content .left-content p {
            color: #595959;
            font-size: var(--small-font-size);
            font-weight: 400;
            line-height: 1.25rem;
            margin-bottom: 0px; }
        .next-steps-card .ant-collapse .ant-collapse-item .panel-content .right-content {
          font-size: var(--small-font-size);
          display: flex;
          align-items: center; }
          .next-steps-card .ant-collapse .ant-collapse-item .panel-content .right-content .ant-btn {
            background: #67d367;
            padding: 8px;
            color: white;
            font-size: var(--small-font-size);
            font-weight: 500;
            line-height: 12px;
            border: none;
            letter-spacing: 0.032em; }
          .next-steps-card .ant-collapse .ant-collapse-item .panel-content .right-content .anticon {
            font-size: 1rem;
            margin-right: 0.25rem; }
        .next-steps-card .ant-collapse .ant-collapse-item .panel-content .india-txt {
          position: relative;
          right: 74%; }
        .next-steps-card .ant-collapse .ant-collapse-item .panel-content .global-txt {
          position: relative;
          right: 52%; }
    .next-steps-card .ant-collapse .ant-collapse-item-active {
      background: #0d192a; }
      .next-steps-card .ant-collapse .ant-collapse-item-active .ant-collapse-header {
        color: white; }
        .next-steps-card .ant-collapse .ant-collapse-item-active .ant-collapse-header .anticon {
          font-size: 22px;
          margin-right: 10px; }
          .next-steps-card .ant-collapse .ant-collapse-item-active .ant-collapse-header .anticon path {
            fill: white; }

.contact-cen {
  width: 430px !important; }
  .contact-cen .ant-modal-body {
    padding: 0; }
  .contact-cen .ant-modal-close-x {
    display: none !important; }
  .contact-cen .contact-center-modal {
    padding: 32px 32px 20px 32px;
    background-color: #ffffff;
    width: 430px;
    border-radius: 2px;
    box-shadow: 0 3px 10px transparent; }
    .contact-cen .contact-center-modal * {
      margin: 0;
      padding: 0;
      box-sizing: border-box; }
    .contact-cen .contact-center-modal h1 {
      font-size: 16px;
      text-align: center;
      font-weight: 500;
      color: #212121;
      margin-bottom: 4px; }
    .contact-cen .contact-center-modal h2 {
      color: #898989;
      font-size: 12px;
      text-align: center;
      margin-bottom: 34px; }
    .contact-cen .contact-center-modal .logo {
      height: 122px;
      width: 100%;
      background-color: #f5f5f5;
      border-radius: 2px;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .contact-cen .contact-center-modal .logo svg {
        margin-left: 24px; }
      .contact-cen .contact-center-modal .logo .vc {
        height: 71px;
        width: 67px; }
    .contact-cen .contact-center-modal p {
      margin-bottom: 12px;
      color: #212121; }
      .contact-cen .contact-center-modal p a {
        color: #1370fc;
        margin-left: 4px;
        text-decoration: none; }
      .contact-cen .contact-center-modal p svg {
        margin-left: 4px; }
    .contact-cen .contact-center-modal button {
      display: block;
      margin: auto;
      height: 32px;
      width: 160px;
      color: #898989;
      font-size: 12px;
      border: 1px solid #898989;
      border-radius: 2px;
      background-color: transparent;
      font-weight: 400;
      cursor: pointer; }

.extra-stats {
  box-shadow: 0 1px 2px #ff3c3c29;
  cursor: pointer;
  padding-left: 1rem !important;
  padding-right: 1.5rem !important; }
  .extra-stats .ant-card-body {
    display: flex;
    align-items: center;
    padding-left: 1rem !important;
    padding: 0 !important;
    height: 100%; }
    .extra-stats .ant-card-body span {
      vertical-align: middle; }
    .extra-stats .ant-card-body .icon {
      font-size: 1.312rem; }
    .extra-stats .ant-card-body .title {
      margin-left: 12px;
      font-size: var(--medium-font-size);
      color: #585858;
      font-weight: 500; }
    .extra-stats .ant-card-body .number {
      display: block;
      width: 30%;
      display: flex;
      justify-content: flex-end;
      font-size: var(--extra-medium-font-size);
      color: #585858;
      font-weight: 500; }

.sc {
  margin: 1rem 0 2rem 0rem !important;
  padding: 0 !important;
  width: 100%;
  display: flex;
  justify-content: space-between; }
  .sc .scwrap {
    width: 70%; }
  .sc .ant-col {
    padding: 0 !important;
    height: 3.5rem; }
    .sc .ant-col .ant-card {
      border: 1px solid #d6d6d6 !important;
      height: 100%;
      box-shadow: 0 1px 2px #00000016 !important; }
      .sc .ant-col .ant-card .ant-card-body {
        height: 100%;
        width: 100%; }
    .sc .ant-col .ant-card:nth-of-type(2) {
      padding: 0 1.5rem !important; }

.sc > div {
  width: 31.5%; }

.extra-stats.is-zero {
  opacity: 0.8;
  box-shadow: none; }

.statistic-card .ant-card-body .header {
  line-height: 20px;
  font-size: 12px !important;
  letter-spacing: 0.032em;
  font-weight: 500; }

.recharts-wrapper {
  margin-top: 50px !important;
  position: relative !important; }
  .recharts-wrapper .recharts-legend-wrapper {
    width: 400px !important;
    height: auto !important;
    left: -40px !important;
    bottom: 26rem !important;
    margin-top: 10px !important; }
  .recharts-wrapper .ant-tabs-tabpane {
    background-color: white !important;
    padding-top: 0px !important; }
  .recharts-wrapper .recharts-default-legend {
    display: none !important;
    margin-bottom: -1rem !important; }
    .recharts-wrapper .recharts-default-legend .recharts-surface {
      width: auto !important; }
  .recharts-wrapper .recharts-wrapper > .recharts-surface {
    width: 99%; }

.overall_progress .ant-tabs-nav-scroll {
  border-radius: 4px;
  padding: 10px !important;
  padding-top: 20px !important;
  padding-bottom: 0 !important;
  background: #fafbfc !important;
  padding-left: 2rem !important; }

.gr .ant-tabs-nav .ant-tabs-tab {
  padding-right: 0 !important;
  padding-left: 0 !important;
  Color: #212121; }

.gr .ant-tabs-nav .ant-tabs-tab-active {
  color: #0068FF;
  font-weight: 500; }

.gr .ant-tabs-ink-bar-animated {
  background-color: #0068FF; }

.ic-sales {
  margin-top: 7px;
  display: flex; }
  .ic-sales .svg {
    display: flex;
    align-items: flex-start;
    margin-right: 6px;
    margin-top: 6px; }
  .ic-sales h5 {
    color: #fff;
    font-size: 12px;
    margin: 0;
    padding: 0; }
  .ic-sales .content span {
    font-weight: 500; }

.ic-orders {
  margin-top: 14px;
  display: flex; }
  .ic-orders .svg {
    display: flex;
    align-items: flex-start;
    margin-right: 6px;
    margin-top: 6px; }
  .ic-orders h5 {
    color: #fff;
    font-size: 12px;
    margin: 0;
    padding: 0; }
  .ic-orders .content span {
    font-weight: 500; }

.data-point-sales {
  position: absolute;
  display: flex;
  align-items: center;
  top: 1rem;
  left: 2rem;
  font-size: var(--small-font-size); }
  .data-point-sales .first {
    display: flex;
    align-items: center;
    margin-right: 2rem; }
    .data-point-sales .first p {
      font-size: var(--small-font-size);
      font-weight: 500; }
  .data-point-sales .second p {
    font-size: var(--small-font-size);
    font-weight: 500; }

.recharts-wrapper {
  margin-top: 50px !important;
  position: relative !important; }
  .recharts-wrapper .recharts-surface {
    width: 99%; }
  .recharts-wrapper .recharts-legend-wrapper {
    width: 458px !important;
    height: auto !important;
    left: -50px !important;
    bottom: 38rem !important;
    margin-top: 10px !important; }

.yAxis line {
  display: none !important; }

.xAxis line {
  display: none !important; }

.Sold_graph-revenue {
  font-size: var(--small-font-size);
  position: relative; }
  .Sold_graph-revenue .ant-tabs-nav .ant-tabs-tab {
    padding-left: 0;
    padding-right: 0; }

.data-point-revenue {
  display: flex;
  align-items: center;
  position: absolute;
  left: 2rem;
  top: -2rem;
  font-size: var(--small-font-size); }
  .data-point-revenue .first {
    margin-right: 3rem; }
    .data-point-revenue .first p {
      font-size: var(--small-font-size);
      font-weight: 500; }
  .data-point-revenue .second p {
    font-size: var(--small-font-size);
    font-weight: 500; }

.notifications .notification-list {
  border-bottom: 1px solid #f5f5f5; }
  .notifications .notification-list .notification-icon-content p span {
    color: #898989; }
  .notifications .notification-list .notification-icon-content p {
    color: #898989; }
  .notifications .notification-list .notification-date span {
    color: #898989; }

.notifications .notification-list:hover {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe;
  cursor: pointer; }
  .notifications .notification-list:hover .notification-icon-content p span {
    color: #212121; }
  .notifications .notification-list:hover .notification-icon-content p {
    color: #212121; }
  .notifications .notification-list:hover .notification-date span {
    color: #212121; }

.notifications .all-notification-container .recent_notifications p {
  margin-bottom: 8px;
  font-size: 16px;
  letter-spacing: 0.032em;
  color: #262626;
  line-height: 22px;
  font-weight: 500; }


.Sold_Products {
  background: #fafbfc;
  border-radius: 4px;
  margin-bottom: 2rem;
  margin-top: 2rem; }
  .Sold_Products .Sold_Products_container {
    display: flex;
    justify-content: space-between;
    padding: 20px 32px;
    margin-bottom: -24px; }
    .Sold_Products .Sold_Products_container .Selected_Period_text {
      font-size: var(--medium-font-size);
      font-weight: 500;
      color: #000000;
      line-height: 22px;
      letter-spacing: 0.032em; }
    .Sold_Products .Sold_Products_container .right_text span {
      font-size: var(--small-font-size);
      margin-right: 8px;
      color: #000000;
      line-height: 22px;
      letter-spacing: 0.032em; }
      .Sold_Products .Sold_Products_container .right_text span .Via_Shopify_Name {
        cursor: pointer;
        background: no-repeat;
        border: none;
        color: #1162fb;
        letter-spacing: 0.032em;
        line-height: 22px;
        text-decoration: underline; }
      .Sold_Products .Sold_Products_container .right_text span .color_change {
        cursor: pointer;
        background: no-repeat;
        border: none;
        color: #000000;
        letter-spacing: 0.032em;
        line-height: 22px; }
      .Sold_Products .Sold_Products_container .right_text span .color_change:hover {
        text-decoration: underline; }
      .Sold_Products .Sold_Products_container .right_text span .Via_Catalog_Name {
        cursor: pointer;
        background: no-repeat;
        border: none;
        color: #1162fb;
        letter-spacing: 0.032em;
        line-height: 22px;
        text-decoration: underline; }
  .Sold_Products .Sold_Products_table {
    width: 100%;
    font-size: var(--small-font-size);
    display: inline-block; }
    .Sold_Products .Sold_Products_table th {
      color: #262626;
      font-weight: 500;
      padding: 0.5rem;
      white-space: normal;
      letter-spacing: 0.032em;
      line-height: 22px;
      width: 186px; }
    .Sold_Products .Sold_Products_table th:first-child {
      padding-left: 0px !important;
      width: 351px; }
    .Sold_Products .Sold_Products_table td {
      padding: 8px !important;
      width: 180px; }
      .Sold_Products .Sold_Products_table td span img {
        width: 40px;
        height: 40px;
        border: 1px solid #f5f5f5;
        border-radius: 4px;
        padding: 2px;
        margin-right: 1rem; }
      .Sold_Products .Sold_Products_table td span a {
        white-space: nowrap;
        line-height: 22px;
        font-weight: 400;
        letter-spacing: 0.032em; }
    .Sold_Products .Sold_Products_table td:first-child {
      padding-left: 0px !important;
      width: 300px; }
    .Sold_Products .Sold_Products_table .table_parrent tbody tr:nth-child(even) {
      background: #f5f5f5; }
    .Sold_Products .Sold_Products_table .table_parrent table {
      border-radius: 4px; }
    .Sold_Products .Sold_Products_table .table_parrent tbody {
      border-radius: 4px; }
    .Sold_Products .Sold_Products_table .table_parrent > div {
      border-radius: 4px; }
    .Sold_Products .Sold_Products_table tbody {
      padding: 20px 32px;
      background: #ffffff;
      width: 100%;
      display: inline-grid;
      margin-top: -16px;
      border-radius: 4px; }
  .Sold_Products .ant-empty-description {
    display: none !important; }
  .Sold_Products .svI {
    opacity: 0;
    transition: opacity 0.5s;
    margin-left: 5px; }
  .Sold_Products .spn:hover .svI {
    opacity: 1;
    margin-left: 5px;
    transition: opacity 0.5s; }

@media screen and (min-width: 1360px) {
  .Sold_Products_table {
    width: 100%;
    font-size: var(--small-font-size);
    display: inline-block; }
    .Sold_Products_table th {
      color: #262626;
      font-weight: 500;
      padding: 0.5rem;
      white-space: normal;
      letter-spacing: 0.032em;
      line-height: 22px; }
    .Sold_Products_table th:first-child {
      padding-left: 0px !important;
      width: 300px; }
    .Sold_Products_table .table_parrent tbody tr:nth-child(2) {
      background: #f5f5f5; }
    .Sold_Products_table tbody {
      padding: 20px 32px;
      background: #ffffff;
      background: #ffffff;
      width: 100%;
      display: inline-grid; } }

.statistic-card {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border: 1px solid #d6d6d6 !important; }
  .statistic-card .ant-card-body {
    padding: 0; }
    .statistic-card .ant-card-body .header {
      height: 3rem;
      color: white;
      background: linear-gradient(90deg, #16273b, #192f4a);
      padding: 0.5rem 1.5rem;
      font-size: var(--small-font-size);
      margin-bottom: 0.5rem; }
      .statistic-card .ant-card-body .header .header-tooltip {
        float: right; }
    .statistic-card .ant-card-body .body {
      padding: 0.5rem 1.5rem;
      font-size: var(--medium-font-size);
      letter-spacing: 0.3px; }
    .statistic-card .ant-card-body .custom-body {
      min-height: 79px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .statistic-card .ant-card-body .divider {
      margin: 0 20px;
      border-top: 1px solid #e8e8e8; }
    .statistic-card .ant-card-body .footer {
      padding: 0.5rem 1.5rem;
      font-size: var(--extra-medium-small-font-size); }
      .statistic-card .ant-card-body .footer p span:first-of-type {
        font-size: var(--small-font-size); }
      .statistic-card .ant-card-body .footer hr {
        border: 0;
        border-top: 1px solid #e8e8e8; }

.welcome-modal .ant-btn {
  background: #2bc80a;
  color: white;
  border: #36c626;
  height: 38px;
  width: 148px;
  font-size: var(--small-font-size);
  font-weight: 500;
  letter-spacing: 1px; }

.welcome-modal h3 {
  color: #ff005e;
  font-size: 21px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 0px;
  margin-top: 12px; }

.welcome-modal p {
  font-size: var(--extra-medium-small-font-size);
  font-weight: 400;
  line-height: 22px;
  color: #4b4b4b; }

.snapshot-page .heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px; }
  .snapshot-page .heading .Onboarding_Steps button {
    height: 47px;
    background: #fff;
    border: none;
    font-size: var(--small-font-size);
    color: #000000;
    line-height: 22px;
    letter-spacing: 0.032em;
    padding: 8px 16px 8px 16px;
    cursor: pointer; }
    .snapshot-page .heading .Onboarding_Steps button .green_text {
      color: #3ace36;
      font-size: 20px;
      font-weight: 500;
      line-height: 22px; }
    .snapshot-page .heading .Onboarding_Steps button .red_text {
      color: #dd2525;
      font-size: 20px;
      font-weight: 500;
      line-height: 22px; }
    .snapshot-page .heading .Onboarding_Steps button .ant-dropdown-link span .up-arrow {
      display: none; }
    .snapshot-page .heading .Onboarding_Steps button .ant-dropdown-link span .down-arrow {
      display: none; }
    .snapshot-page .heading .Onboarding_Steps button .ant-dropdown-link span .up-arrow {
      display: inline-block; }
    .snapshot-page .heading .Onboarding_Steps button .ant-dropdown-link span .up-arrow {
      display: none; }

.snapshot-page .content-page-title {
  font-size: var(--extra-medium-font-size);
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .snapshot-page .content-page-title .global-tooltip-video {
    margin-left: 8px !important; }
  .snapshot-page .content-page-title svg {
    margin-bottom: 4px !important; }

.snapshot-page .content-page-subtitle {
  font-size: var(--small-font-size); }

.snapshot-page .gr {
  box-shadow: 0px 3px 6px #00000016;
  border-radius: 4px; }

.snapshot-picker {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .snapshot-picker .Your_Snapshot {
    display: flex; }
  .snapshot-picker .Your_Snapshot p {
    color: #000000;
    font-size: var(--medium-font-size);
    line-height: 22px;
    letter-spacing: 0.032em; }
  .snapshot-picker .right_datepicker span {
    color: #000000;
    font-size: var(--extra-medium-small-font-size);
    line-height: 22px;
    letter-spacing: 0.032em; }

.snapshot_card {
  display: flex;
  justify-content: space-between; }
  .snapshot_card .left {
    width: 49%; }
  .snapshot_card .right {
    width: 49%; }
  .snapshot_card .ant-card-body {
    padding: 16px !important;
    padding-bottom: 22px !important; }
  .snapshot_card .ant-card.ant-card-bordered:hover {
    width: 23%;
    cursor: pointer;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.192) !important; }
  .snapshot_card .research_tool p {
    color: #595959;
    font-size: var(--small-font-size);
    font-weight: 400;
    line-height: 1.25rem;
    margin-bottom: 0px; }
  .snapshot_card .ant-card-meta-title {
    overflow: hidden;
    color: #212121;
    font-weight: 500;
    white-space: normal;
    text-overflow: ellipsis;
    font-size: var(--medium-font-size);
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 0px; }
  .snapshot_card button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px; }
    .snapshot_card button span:nth-of-type(2) {
      display: none; }
    .snapshot_card button svg {
      font-size: 16px;
      margin-top: 1px;
      transition: all 0.35s ease 0s; }
      .snapshot_card button svg g path:nth-of-type(2) {
        fill: #fff; }
  .snapshot_card .clickable.new-tab svg {
    font-size: 12px; }
  .snapshot_card .clickable.open svg {
    transition: all 0.35s ease 0s;
    transform: rotate(180deg); }
    .snapshot_card .clickable.open svg g path {
      fill: #fff; }

.steps-modal-let * {
  letter-spacing: 0.03em; }

.steps-modal-let .ant-modal-content {
  border-radius: 2px; }

.steps-modal-let .ant-modal-body {
  padding: 36px 36px;
  position: relative; }
  .steps-modal-let .ant-modal-body .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer; }
  .steps-modal-let .ant-modal-body .top-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.56rem; }
    .steps-modal-let .ant-modal-body .top-div .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start; }
      .steps-modal-let .ant-modal-body .top-div .left .title {
        font-size: 24px;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 0.21rem; }
        .steps-modal-let .ant-modal-body .top-div .left .title img {
          max-width: 100%;
          width: 1.786rem;
          height: 1.786rem;
          margin-right: 0.25rem; }
      .steps-modal-let .ant-modal-body .top-div .left .sub-title {
        font-size: 16px;
        font-weight: 400;
        color: #8c8c8c;
        margin-block-end: 0.71rem; }
      .steps-modal-let .ant-modal-body .top-div .left .steps-ratio {
        border-radius: 3px;
        background: rgba(54, 198, 38, 0.33);
        height: 3rem;
        width: 16.86rem;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 16px;
        color: #020429;
        font-weight: 600; }
    .steps-modal-let .ant-modal-body .top-div .right svg {
      width: 12rem;
      height: 8rem;
      max-width: 100%;
      max-height: 100%; }
  .steps-modal-let .ant-modal-body .list-div .step {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 24px; }
    .steps-modal-let .ant-modal-body .list-div .step svg {
      width: 1.71rem;
      height: 1.71rem;
      margin-right: 1.43rem; }
    .steps-modal-let .ant-modal-body .list-div .step .number {
      font-size: 1.143rem;
      color: #9c9c9c;
      border: 2px solid #9c9c9c;
      border-radius: 50%;
      height: 1.71rem;
      width: 1.71rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.43rem; }
    .steps-modal-let .ant-modal-body .list-div .step .text .heading {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0.071rem;
      color: #36c626; }
      .steps-modal-let .ant-modal-body .list-div .step .text .heading svg {
        width: 1.286rem;
        height: 1.286rem;
        vertical-align: text-bottom;
        margin-right: 0.286rem;
        display: none; }
    .steps-modal-let .ant-modal-body .list-div .step .text .content {
      font-size: 12px;
      font-weight: 400;
      color: #8c8c8c; }
  .steps-modal-let .ant-modal-body .list-div .step:last-of-type .text .content {
    margin-bottom: 0; }
  .steps-modal-let .ant-modal-body .list-div .step.incomplete .text .heading {
    color: #8c8c8c; }
  .steps-modal-let .ant-modal-body .list-div .step.incomplete .text .heading:hover {
    color: #36c525; }
    .steps-modal-let .ant-modal-body .list-div .step.incomplete .text .heading:hover svg {
      display: inline-block; }
  .steps-modal-let .ant-modal-body .list-div .step.first-incomplete .text .heading,
  .steps-modal-let .ant-modal-body .list-div .step.first-incomplete .number {
    color: #fff;
    background: #36c525;
    border-color: #36c525; }
  .steps-modal-let .ant-modal-body .list-div .step.first-incomplete .text .heading {
    border-radius: 3px;
    padding: 0.2rem 0.5rem; }
    .steps-modal-let .ant-modal-body .list-div .step.first-incomplete .text .heading svg * {
      fill: #fff; }
  .steps-modal-let .ant-modal-body .list-div .step.first-incomplete .text .heading:hover svg {
    display: inline-block; }
  .steps-modal-let .ant-modal-body .list-div .first-incomplete .content {
    margin-top: 6px; }

.view-steps-card {
  transition: all 0.4s linear;
  max-height: 0px;
  overflow: hidden;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff; }
  .view-steps-card * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    letter-spacing: 0.032em;
    color: #212121; }
  .view-steps-card .steps-count {
    width: 260px !important;
    margin-right: 44px;
    border: 1px solid #898989;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 12px;
    margin: 24px 0;
    margin-left: 32px;
    margin-right: 40px; }
    .view-steps-card .steps-count h1 {
      font-size: 32px; }
    .view-steps-card .steps-count button {
      margin-top: 16px;
      padding: 6px 12px;
      font-size: 16px; }
      .view-steps-card .steps-count button span {
        color: #fff !important; }
      .view-steps-card .steps-count button svg {
        fill: #fff; }
  .view-steps-card .content {
    width: 600px !important; }
    .view-steps-card .content h1 {
      font-size: 26px;
      margin-bottom: 8px; }
    .view-steps-card .content h2 {
      font-size: 14px;
      color: #898989;
      font-weight: 500; }
    .view-steps-card .content button {
      font-size: 14px;
      margin: 8px 0;
      padding: 4px 4px;
      border-radius: 2px; }
      .view-steps-card .content button span {
        color: #fff; }
    .view-steps-card .content h4 {
      font-size: 12px;
      color: #898989;
      font-weight: 400; }
    .view-steps-card .content h5 {
      font-size: 16px;
      font-style: italic;
      margin-top: 8px;
      color: #898989; }
  .view-steps-card .img {
    width: 320px;
    margin-right: 32px !important; }
    .view-steps-card .img img {
      width: 100%;
      height: 100%; }

.view-steps-card.clicked {
  max-height: 400px !important;
  transition: all 0.4s linear;
  margin: 24px 0; }

.__floater.__floater__open {
  z-index: 13000 !important; }
  .__floater.__floater__open .__floater__arrow span {
    height: 2px;
    left: 5px !important;
    z-index: 9; }
    .__floater.__floater__open .__floater__arrow span svg polygon {
      fill: #1f9d82 !important; }

.react-joyride__tooltip {
  border-radius: 4px !important;
  width: 400px !important;
  box-shadow: 0 2px 3px #000 !important;
  background-image: linear-gradient(to right, #1f9d82, #1a8950) !important; }
  .react-joyride__tooltip > div > div {
    padding: 0 !important; }
    .react-joyride__tooltip > div > div .product-tour {
      padding-bottom: 0px; }
      .react-joyride__tooltip > div > div .product-tour * {
        letter-spacing: 0.032em !important;
        margin: 0;
        padding: 0;
        font-size: 12px;
        color: #212121; }
    .react-joyride__tooltip > div > div .tour-progress {
      height: 26px;
      border-radius: 12px;
      width: 100%;
      border: 2px solid #fed972;
      margin-bottom: 15px;
      overflow: hidden; }
      .react-joyride__tooltip > div > div .tour-progress .bar {
        background-color: #ffd972;
        height: 100%;
        position: relative; }
      .react-joyride__tooltip > div > div .tour-progress .bar::before {
        content: "Quick Overview Tour";
        display: block;
        position: absolute;
        height: 100%;
        top: 20%;
        font-size: 10px;
        font-weight: 500;
        left: 16px; }
      .react-joyride__tooltip > div > div .tour-progress .bar::after {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        width: 16px;
        background-color: #fff6de; }
    .react-joyride__tooltip > div > div .tour-head h2 {
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      opacity: 0.5;
      text-align: left;
      margin-bottom: 3px; }
    .react-joyride__tooltip > div > div p {
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff !important;
      margin-bottom: 30px !important; }
  .react-joyride__tooltip button {
    height: 32px !important;
    border-radius: 2px !important; }
  .react-joyride__tooltip button[data-action="primary"] {
    transition: all 0.3s ease-out;
    background-color: #fff !important;
    color: #1a816b !important;
    border-radius: 2px !important;
    width: 82px !important;
    font-size: 12px !important;
    font-weight: 500 !important; }
  .react-joyride__tooltip button[data-action="primary"]:hover {
    transition: all 0.3s ease-in;
    background: #1a816b !important;
    border: 1px solid #fed972 !important;
    color: #fed972 !important;
    border-radius: 2px !important;
    width: 82px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    box-shadow: inset 1px 2px 3px 2px #00000050; }
  .react-joyride__tooltip button[data-action="primary"]:active {
    transition: all 0.3s ease-out;
    background-color: #fff !important;
    color: #1a816b !important;
    border: none !important;
    border-radius: 2px !important;
    width: 82px !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    box-shadow: none !important; }
  .react-joyride__tooltip button[data-action="back"] {
    transition: all 0.3s ease-in;
    color: #fff !important;
    font-weight: 500;
    width: 82px !important;
    font-size: 12px !important;
    margin-right: 8px !important;
    background-color: #1f9d82 !important; }
  .react-joyride__tooltip button[data-action="back"]:hover {
    transition: all 0.3s ease-out;
    box-shadow: inset 1px 2px 3px 2px #00000050;
    color: #fff !important;
    font-weight: 500;
    width: 82px !important;
    font-size: 12px !important;
    margin-right: 8px !important;
    background-color: #1f9d82 !important; }
  .react-joyride__tooltip button[data-action="back"]:active {
    transition: all 0.3s ease-out;
    box-shadow: inset 1px 2px 3px 2px #00000050;
    color: #3284c9 !important;
    font-weight: 500;
    width: 82px !important;
    font-size: 12px !important;
    margin-right: 8px !important;
    background-color: #fff !important; }
  .react-joyride__tooltip button[data-action="skip"] {
    transition: all 0.3s ease-in;
    color: #898989 !important;
    font-size: 12px !important;
    border: 1px solid #ffffff90 !important;
    color: #ffffff80 !important; }
  .react-joyride__tooltip button[data-action="skip"]:hover {
    transition: all 0.3s ease-out;
    color: #898989 !important;
    font-size: 12px !important;
    border: 1px solid #ffffff !important;
    color: #ffffff !important; }
  .react-joyride__tooltip > button {
    display: none; }

.react-joyride__overlay {
  z-index: 12999 !important;
  background-color: rgba(0, 0, 0, 0.9) !important; }

.react-joyride__spotlight {
  border: 3px solid #1f9d82 !important;
  border-radius: 0px !important;
  left: 0px !important;
  width: 240px !important;
  z-index: 99; }

.tour-end-modal {
  width: 400px !important; }
  .tour-end-modal .ant-modal-close-x {
    display: none !important; }
  .tour-end-modal .ant-modal-close {
    display: none !important; }
  .tour-end-modal .ant-modal-content {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 4px !important; }
  .tour-end-modal .ant-modal-body {
    padding: 24px 16px !important;
    border-radius: 4px !important;
    width: 400px !important;
    box-shadow: 0 2px 3px #000 !important;
    background-image: linear-gradient(to right, #1f9d82, #1a8950) !important; }
  .tour-end-modal .tour-modal-content .bulb-icon-wraper {
    display: flex;
    align-items: center;
    justify-content: center; }
    .tour-end-modal .tour-modal-content .bulb-icon-wraper .bulb-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: #fed972; }
      .tour-end-modal .tour-modal-content .bulb-icon-wraper .bulb-icon svg {
        font-size: 40px; }
  .tour-end-modal h1 {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    opacity: 0.6;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 3px; }
  .tour-end-modal h2 {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 0 45px;
    color: #ffffff !important;
    margin-bottom: 24px; }
  .tour-end-modal .btn-wraper {
    display: flex;
    align-items: center;
    justify-content: center; }
    .tour-end-modal .btn-wraper button {
      transition: all 0.3s ease-out;
      background-color: #fff !important;
      color: #1a816b !important;
      border-radius: 2px !important;
      width: 136px !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      height: 32px !important;
      border: none !important;
      cursor: pointer !important; }
    .tour-end-modal .btn-wraper button:hover {
      transition: all 0.3s ease-in;
      background: #1a816b !important;
      border: 1px solid #fed972 !important;
      color: #fed972 !important;
      border-radius: 2px !important;
      font-size: 12px !important;
      font-weight: 600 !important;
      box-shadow: inset 1px 2px 3px 2px #00000050; }
    .tour-end-modal .btn-wraper button:active {
      transition: all 0.3s ease-out;
      background-color: #fff !important;
      color: #1a816b !important;
      border: none !important;
      border-radius: 2px !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      box-shadow: none !important; }

@media screen and (min-width: 180px) and (max-width: 1439px) {
  .react-joyride__spotlight {
    width: 200px !important; } }

.global-tooltip-video {
  height: 28px; }
  .global-tooltip-video .icon-wraper {
    height: 24px;
    width: 24px;
    background-color: #ffd33c;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s linear;
    position: relative;
    z-index: 999 !important; }
    .global-tooltip-video .icon-wraper > div {
      height: 24px;
      width: 24px; }

.tooltip-video-div-overlay {
  z-index: 999; }
  .tooltip-video-div-overlay .ant-tooltip-arrow {
    top: 0px !important;
    left: 50%;
    margin-left: -11px !important;
    border-width: 0 11px 8px !important;
    border-bottom-color: #212121 !important; }
  .tooltip-video-div-overlay .ant-tooltip-content {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content; }
  .tooltip-video-div-overlay .tooltip-video-div {
    display: flex !important;
    justify-content: space-between; }
    .tooltip-video-div-overlay .tooltip-video-div * {
      letter-spacing: 0.032em;
      color: #fff;
      margin: 0;
      padding: 0; }
    .tooltip-video-div-overlay .tooltip-video-div .left {
      margin-right: 16px; }
    .tooltip-video-div-overlay .tooltip-video-div .right {
      width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .tooltip-video-div-overlay .tooltip-video-div .right * {
        letter-spacing: 0.032em; }
    .tooltip-video-div-overlay .tooltip-video-div h1 {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
      color: #4398ed;
      margin-top: 2px; }
    .tooltip-video-div-overlay .tooltip-video-div h2 {
      text-align: left;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 28px; }
    .tooltip-video-div-overlay .tooltip-video-div h3 {
      text-align: left;
      font-size: 12px;
      font-weight: 500;
      line-height: 26px; }
    .tooltip-video-div-overlay .tooltip-video-div .html-div {
      border-radius: 2px;
      padding-bottom: 0 !important;
      background: #2e2e2e;
      position: relative; }
      .tooltip-video-div-overlay .tooltip-video-div .html-div p {
        text-align: left;
        display: flex;
        flex-direction: column;
        color: #b2b2b2 !important;
        font-size: 12px; }
        .tooltip-video-div-overlay .tooltip-video-div .html-div p b {
          margin-top: 6px;
          font-weight: 400 !important;
          color: #fff !important; }
    .tooltip-video-div-overlay .tooltip-video-div .image-div {
      cursor: pointer;
      width: 344px;
      position: relative;
      padding: 2px;
      background-color: #3e3e3e;
      height: 256px;
      object-fit: cover; }
      .tooltip-video-div-overlay .tooltip-video-div .image-div img {
        height: 100%;
        object-fit: cover; }
      .tooltip-video-div-overlay .tooltip-video-div .image-div video {
        height: 100%; }
      .tooltip-video-div-overlay .tooltip-video-div .image-div .svg {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        left: 50%;
        color: #fff !important;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 160px;
        height: 48px;
        font-size: 14px;
        font-weight: 500;
        color: #212121;
        border-radius: 24px;
        background-image: linear-gradient(to right, #0e58d6, #115bd7, #4398ed); }
        .tooltip-video-div-overlay .tooltip-video-div .image-div .svg svg {
          margin-right: 10px; }
      .tooltip-video-div-overlay .tooltip-video-div .image-div img {
        width: 100%; }
      .tooltip-video-div-overlay .tooltip-video-div .image-div video {
        width: 100%; }
    .tooltip-video-div-overlay .tooltip-video-div .image-div::after {
      content: "";
      width: 100%;
      z-index: 0;
      height: 100%;
      z-index: 1;
      opacity: 0.5;
      background-color: black;
      position: absolute;
      top: 0;
      left: 0; }
  .tooltip-video-div-overlay .ant-tooltip-inner {
    padding: 24px !important;
    box-shadow: 20px 20px 20px rgba(0, 0, 0, 0.4);
    background-color: #212121 !important; }
  .tooltip-video-div-overlay .slick-dots.slick-dots-bottom {
    height: 40px;
    background-color: #434343;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: 0 !important;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; }
    .tooltip-video-div-overlay .slick-dots.slick-dots-bottom li {
      margin-right: 4px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
    .tooltip-video-div-overlay .slick-dots.slick-dots-bottom button {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: #212121;
      opacity: 1; }
  .tooltip-video-div-overlay .slick-active button {
    width: 32px !important;
    border-radius: 8px !important; }
  .tooltip-video-div-overlay .bottom-area {
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .tooltip-video-div-overlay .bottom-area button {
      margin-top: 16px;
      transition: all 0.3s ease-out;
      border-radius: 2px !important;
      padding: 0 24px !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      height: 32px !important;
      border: none !important;
      cursor: pointer !important; }
    .tooltip-video-div-overlay .bottom-area .quick-overview {
      transition: all 0.3s ease-in;
      color: #898989 !important;
      font-size: 12px !important;
      border: 1px solid #ffffff90 !important;
      color: #ffffff80 !important;
      background-color: rgba(0, 0, 0, 0); }
    .tooltip-video-div-overlay .bottom-area .quick-overview:hover {
      transition: all 0.3s ease-out;
      color: #898989 !important;
      font-size: 12px !important;
      border: 1px solid #ffffff !important;
      color: #ffffff !important; }
    .tooltip-video-div-overlay .bottom-area .quick-page {
      color: #000 !important;
      margin-left: 8px; }
    .tooltip-video-div-overlay .bottom-area .quick-page:hover {
      background-color: #4398ed !important;
      color: #fff !important; }

.tooltip-video-div-overlay::before {
  content: "";
  transition: all 2s linear;
  position: fixed;
  background-color: #7979796e;
  z-index: -1;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  pointer-events: none; }

.video-react-controls-enabled {
  padding-top: 0 !important; }

.corousel-div {
  position: relative;
  padding: 16px !important;
  padding-bottom: 56px !important; }
  .corousel-div * {
    letter-spacing: 0.032em; }
  .corousel-div .corousel-heading {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .corousel-div .corousel-heading .serial {
      height: 16px;
      width: 16px;
      background-color: #fd9226;
      margin-right: 8px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      font-size: 10px;
      font-weight: 600;
      color: #2e2e2e;
      justify-content: center;
      margin-bottom: 1px; }
    .corousel-div .corousel-heading div {
      color: #fd9226;
      font-weight: 600;
      font-size: 14px; }
  .corousel-div p {
    text-align: left;
    display: flex;
    flex-direction: column;
    color: #b2b2b2 !important;
    padding-bottom: 7px;
    font-weight: 500 !important; }
    .corousel-div p b {
      font-weight: 500 !important;
      color: #fff !important; }

.video-modal {
  width: 700px !important; }
  .video-modal .ant-modal-body {
    padding: 0 !important; }
  .video-modal video {
    width: 100%; }

.editable-text textarea.ant-input {
  margin-bottom: 1em;
  padding: 0;
  width: calc(100% - 20px); }

.importlistclass {
  margin-bottom: 1em;
  padding: 0.5rem !important;
  font-size: 0.75rem;
  border-radius: 0.125rem;
  width: calc(100% - 20px); }

.ant-input:hover .ant-input:focus,
.ant-input:active {
  box-shadow: none;
  border-color: unset; }

.ant-input:hover {
  border-color: unset;
  border-right-width: 1px !important; }

.ant-input:focus {
  border-color: unset;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: unset; }

.import-list-product-basic {
  color: black;
  margin-left: 0 !important;
  margin-right: 0 !important;
  font-size: 0.75rem; }
  .import-list-product-basic .id-copy {
    margin-right: 8px;
    color: #898989;
    font-size: 12px; }
  .import-list-product-basic b {
    font-weight: 400; }
  .import-list-product-basic .selling-price {
    position: relative; }
    .import-list-product-basic .selling-price .abs {
      position: absolute;
      right: 5px;
      top: -1px;
      color: #898989; }
    .import-list-product-basic .selling-price .special-cost-product-tooltip {
      position: absolute;
      left: -32px;
      top: -1px; }
  .import-list-product-basic .product-basic-title-label {
    margin-bottom: 10px; }
  .import-list-product-basic .product-img-container {
    min-width: 173px !important;
    max-width: 173px !important; }
    .import-list-product-basic .product-img-container img {
      border: 2px solid #f5f5f5 !important;
      min-width: 173px;
      min-height: 173px;
      max-width: 173px;
      max-height: 173px; }
  .import-list-product-basic .product-supplier-link .external-link-icon {
    display: none;
    fill: #5e73ff; }
  .import-list-product-basic .product-supplier-link:hover .external-link-icon {
    display: inline-block; }
  .import-list-product-basic .ant-select-focused .ant-select-selection,
  .import-list-product-basic .ant-select-selection:focus,
  .import-list-product-basic .ant-select-selection:active {
    box-shadow: none; }
  .import-list-product-basic .ant-select-selection {
    border: none;
    height: 24px;
    min-height: 24px;
    background-color: #f5f5f5;
    border-radius: 0px; }
    .import-list-product-basic .ant-select-selection .ant-select-selection__rendered {
      line-height: 24px; }
    .import-list-product-basic .ant-select-selection ul > li,
    .import-list-product-basic .ant-select-selection .ant-select-selection__rendered > ul > li {
      height: 22px;
      margin-top: 1px;
      line-height: 18px; }
    .import-list-product-basic .ant-select-selection .ant-select-selection__choice__content {
      font-size: 0.75rem;
      line-height: 20px; }
    .import-list-product-basic .ant-select-selection .ant-select-selection__rendered {
      margin-left: 1px; }
    .import-list-product-basic .ant-select-selection .ant-select-selection__placeholder,
    .import-list-product-basic .ant-select-selection .ant-select-search__field__placeholder {
      color: #898989;
      font-size: 12px; }
  .import-list-product-basic .next-img {
    right: 0; }
  .import-list-product-basic .prev-img {
    left: 0; }
  .import-list-product-basic .product-img-container button {
    position: absolute;
    top: 0%;
    height: 100%;
    background: #212121;
    cursor: pointer;
    display: none;
    border: none;
    opacity: 0.4;
    z-index: 99; }
  .import-list-product-basic .product-img-container:hover button {
    display: block; }
  .import-list-product-basic .rejected {
    outline: none !important;
    border: none !important; }
  .import-list-product-basic .rejected:hover {
    outline: none;
    border: none; }

.collection-select,
.tag-select,
.type-select {
  font-size: 0.75rem; }

.req-latest-price-import {
  text-decoration: underline !important;
  color: #1268fb;
  cursor: pointer; }

.badge[contenteditable]:empty::before {
  content: "Make a new tag";
  display: inline-block; }

.editable-text span[contenteditable]:empty::before {
  content: "Enter product name" !important;
  display: inline-block !important;
  color: #898989; }

.special-cost-product-tooltip:hover svg.product-tooltip-icon {
  background: #1268fb;
  border-radius: 50%;
  cursor: pointer; }

.special-cost-product-tooltip:hover svg.product-tooltip-icon g text {
  fill: #fff; }

.special-cost-product-tooltip:hover svg.product-tooltip-icon g > g {
  stroke: #1268fb; }

.ff3 {
  padding: 0 !important; }

.view-in-store {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 2px;
  margin-top: 4px; }
  .view-in-store .view-in-vf-link {
    width: 139px;
    color: #ff005e;
    font-size: 12px;
    margin-top: 4px;
    display: flex;
    line-height: 24px;
    letter-spacing: 0.032em;
    justify-content: center;
    align-items: center; }
  .view-in-store p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100%;
    cursor: pointer;
    width: auto !important; }
  .view-in-store .view-in-store-hover-underline-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #fd104d; }
  .view-in-store .view-in-store-hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 85%;
    transform: scaleX(0);
    height: 1px;
    bottom: 3px;
    left: 2px;
    background-color: #e61047;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .view-in-store .view-in-store-hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left; }
  .view-in-store a span {
    color: #ce1543; }
  .view-in-store a svg g path {
    stroke: #ce1543; }

.view-in-store-view-in-store:hover a span {
  color: #e61047; }

.ali-in-store {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 2px;
  margin-top: 4px; }
  .ali-in-store p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100%;
    cursor: pointer;
    width: auto !important; }
  .ali-in-store .ali-in-store-hover-underline-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #fd104d; }
  .ali-in-store .ali-in-store-hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 85%;
    transform: scaleX(0);
    height: 1px;
    bottom: 3px;
    left: 2px;
    background-color: #e61047;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .ali-in-store .ali-in-store-hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left; }
  .ali-in-store a span {
    color: #ce1543; }
  .ali-in-store a svg g path {
    stroke: #ce1543; }

.ali-in-store-ali-in-store:hover a span {
  color: #e61047; }


.pStore-type .ant-modal-header {
  padding: 16px 8px !important;
  border-bottom: 1px solid #cecece !important;
  display: flex;
  margin: 0 auto;
  width: 456px; }

.pStore-type .title_txt {
  font-size: 14px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 500; }

.pStore-type .ant-modal-close-x {
  display: none; }

.pStore-type .ant-modal-content {
  border-radius: 4px; }

.pStore-type .footer_button_continue {
  padding: 16px 2px !important;
  border-top: 1px solid #cecece !important;
  display: flex;
  margin: 0 auto;
  margin-bottom: -16px;
  justify-content: flex-end; }

.pStore-type .ant-modal-body {
  padding: 24px !important; }

.pStore-type .shopify_and_wooCommerece {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px; }

.pStore-type .flag_radio_btn {
  display: flex;
  justify-content: space-between; }
  .pStore-type .flag_radio_btn .Wocoomerce-type-card {
    height: 136px;
    width: 136px;
    border: 1px solid #212121;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    margin-right: 16px;
    cursor: pointer;
    transition: all 0.3s linear; }
    .pStore-type .flag_radio_btn .Wocoomerce-type-card .footer {
      flex: unset;
      width: 240px;
      font-size: 12px;
      color: white;
      padding: 8px 0;
      background: #36c626;
      position: relative;
      left: -9px;
      top: 3px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .pStore-type .flag_radio_btn .Wocoomerce-type-card .anticon {
      margin: auto; }
      .pStore-type .flag_radio_btn .Wocoomerce-type-card .anticon svg {
        width: 80px;
        height: 80px; }
  .pStore-type .flag_radio_btn .Shopify-type-card {
    height: 136px;
    width: 136px;
    border: 1px solid #212121 !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    margin-right: 16px;
    cursor: pointer;
    transition: all 0.3s linear; }
    .pStore-type .flag_radio_btn .Shopify-type-card .footer {
      flex: unset;
      width: 240px;
      font-size: 12px;
      color: white;
      padding: 8px 0;
      background: #36c626;
      position: relative;
      left: -9px;
      top: 3px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .pStore-type .flag_radio_btn .Shopify-type-card .anticon {
      margin: auto; }
      .pStore-type .flag_radio_btn .Shopify-type-card .anticon svg {
        width: 80px;
        height: 80px; }
  .pStore-type .flag_radio_btn .custome_store-card {
    height: 136px;
    width: 136px;
    border: 1px solid #212121 !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;
    transition: all 0.3s linear; }
    .pStore-type .flag_radio_btn .custome_store-card .footer {
      flex: unset;
      width: 240px;
      font-size: 12px;
      color: white;
      padding: 8px 0;
      background: #36c626;
      position: relative;
      left: -9px;
      top: 3px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .pStore-type .flag_radio_btn .custome_store-card .anticon {
      margin: auto; }
      .pStore-type .flag_radio_btn .custome_store-card .anticon svg {
        width: 80px;
        height: 80px; }
  .pStore-type .flag_radio_btn .logo_txt {
    font-size: 12px;
    color: #000000;
    letter-spacing: 0.032em;
    line-height: 24px;
    margin-top: 4px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px; }
  .pStore-type .flag_radio_btn .continue_next {
    display: none; }

.pStore-type .continue_btn {
  flex: unset;
  width: 238px;
  font-size: 12px;
  color: white;
  padding: 8px 0 !important;
  background: #36c626;
  position: relative;
  left: -2px;
  top: 2px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  text-align: center; }

.pStore-type .Wocoomerce-type-card:hover {
  border: 2px solid #212121;
  background: #95bf46;
  transition: all 0.3s linear; }

.pStore-type .Shopify-type-card:hover {
  border: 2px solid #212121;
  background: #7f54b3;
  transition: all 0.3s linear; }

.pStore-type .custome_store-card:hover {
  border: 2px solid #212121;
  background: #56a1bf;
  transition: all 0.3s linear; }

.pStore-type .store_objective {
  margin-bottom: 8px;
  letter-spacing: 0.032em;
  font-size: 12px;
  line-height: 24px;
  margin-top: 6px;
  color: #212121;
  font-weight: 400; }

.pStore-type .Shopify_flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .pStore-type .Shopify_flag img {
    width: 80px;
    height: 80px; }

.pStore-type .wocommerce_flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .pStore-type .wocommerce_flag img {
    width: 80px;
    height: 80px; }

.pStore-type .ant-radio-inner {
  border-color: #fff !important;
  display: none !important; }

.pStore-type .ant-radio-wrapper {
  width: 100%;
  height: 100%; }

.pStore-type .wocommerce_flag span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.pStore-type .Shopify_flag span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.PLocation-contant-cointainer .ant-modal-header {
  padding: 16px 8px !important;
  border-bottom: 1px solid #cecece !important;
  display: flex;
  margin: 0 auto;
  width: 456px; }

.PLocation-contant-cointainer .title_txt {
  font-size: 14px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 500; }

.PLocation-contant-cointainer .ant-modal-close-x {
  display: none; }

.PLocation-contant-cointainer .ant-modal-content {
  border-radius: 0px; }

.PLocation-contant-cointainer .footer_button_continue {
  padding: 16px 2px !important;
  border-top: 1px solid #cecece !important;
  display: flex;
  margin: 16px auto;
  width: 456px;
  margin-bottom: -16px;
  justify-content: flex-end; }

.PLocation-contant-cointainer .ant-modal-body {
  padding: 24px !important; }

.PLocation-contant-cointainer .global_and_indian_dropshipping {
  display: flex;
  justify-content: center;
  align-items: center; }

.PLocation-contant-cointainer .flag_radio_btn {
  display: flex;
  justify-content: space-between; }
  .PLocation-contant-cointainer .flag_radio_btn .Global-type-card {
    height: 136px;
    width: 136px;
    border: 1px solid #212121;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    margin-right: 16px;
    transition: all 0.3s linear;
    cursor: pointer; }
    .PLocation-contant-cointainer .flag_radio_btn .Global-type-card .anticon {
      margin: auto; }
      .PLocation-contant-cointainer .flag_radio_btn .Global-type-card .anticon svg {
        width: 80px;
        height: 80px; }
  .PLocation-contant-cointainer .flag_radio_btn .India-type-card {
    height: 136px;
    width: 136px;
    border: 1px solid #212121;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #fff;
    transition: all 0.3s linear;
    cursor: pointer; }
    .PLocation-contant-cointainer .flag_radio_btn .India-type-card .anticon {
      margin: auto; }
      .PLocation-contant-cointainer .flag_radio_btn .India-type-card .anticon svg {
        width: 80px;
        height: 80px; }
  .PLocation-contant-cointainer .flag_radio_btn .flag {
    font-size: 12px;
    color: #000000;
    letter-spacing: 0.032em;
    line-height: 24px;
    margin-top: 4px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center; }

.PLocation-contant-cointainer .India-type-card span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.PLocation-contant-cointainer .Global-type-card span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.PLocation-contant-cointainer .flag_radio_btn .Global-type-card:hover {
  border: 2px solid #212121;
  background: #ea8634;
  transition: all 0.3s linear; }

.PLocation-contant-cointainer .flag_radio_btn .India-type-card:hover {
  border: 2px solid #212121;
  background: #03aa6f;
  transition: all 0.3s linear; }

.PLocation-contant-cointainer .continue_btn {
  flex: unset;
  width: 238px;
  font-size: 12px;
  color: white;
  padding: 8px 0 !important;
  background: #36c626;
  position: relative;
  left: -2px;
  top: 2px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  text-align: center; }

.PLocation-contant-cointainer .store_objective {
  margin-bottom: 8px;
  letter-spacing: 0.032em;
  font-size: 12px;
  line-height: 24px;
  margin-top: 6px;
  color: #212121;
  font-weight: 400; }

.PLocation-contant-cointainer .indian_flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.PLocation-contant-cointainer .global_flag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; }

.PLocation-contant-cointainer .ant-radio-inner {
  border-color: #fff !important;
  display: none !important; }

.PLocation-contant-cointainer .ant-radio-wrapper {
  width: 100%;
  height: 100%; }

.PLocation-contant-cointainer .go_back_btn {
  margin-right: 8px;
  border: none;
  box-shadow: none;
  font-size: 12px;
  color: #212121;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center; }
  .PLocation-contant-cointainer .go_back_btn .svg_go_back {
    margin-right: 4px; }
  .PLocation-contant-cointainer .go_back_btn .go_back_txt {
    margin-top: -3px; }

.PLocation-contant-cointainer .go_back_btn:hover {
  text-decoration: underline; }


.woCommerceContant .ant-modal-content {
  padding: 8px 0px;
  border-radius: 0px !important; }
  .woCommerceContant .ant-modal-content .ant-modal-header {
    padding: 16px 8px;
    width: 530px;
    margin: auto; }
  .woCommerceContant .ant-modal-content .flex-wraper {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .woCommerceContant .ant-modal-content .flex-wraper > div {
      margin-right: 3px; }
  .woCommerceContant .ant-modal-content .ant-modal-footer {
    padding: 16px 0px;
    text-align: left !important;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    width: 530px;
    margin: auto; }
  .woCommerceContant .ant-modal-content .woCommerce_detail_input .common_label {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    width: 208px;
    margin-bottom: 4px; }
  .woCommerceContant .ant-modal-content .woCommerce_detail_input .upeer_label_margin {
    margin-right: 6px;
    margin-bottom: 16px; }
  .woCommerceContant .ant-modal-content .woCommerce_detail_input .shop_name {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }
  .woCommerceContant .ant-modal-content .woCommerce_detail_input .shop_url {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }
  .woCommerceContant .ant-modal-content .woCommerce_detail_input .shop_consumer_key {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }
  .woCommerceContant .ant-modal-content .woCommerce_detail_input .shop_consumer_secret {
    width: 100%;
    border-radius: 0px;
    background: #f5f5f5;
    border: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121; }

.woCommerceContant .bottom_button {
  text-align: right !important;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  margin: auto;
  padding-top: 5px; }

.woCommerceContant .go_back_btn {
  border: none;
  box-shadow: none;
  font-size: 12px;
  color: #212121;
  font-weight: 400;
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  margin-right: 4px; }
  .woCommerceContant .go_back_btn .svg_go_back {
    margin-right: 4px;
    position: relative;
    top: 4px; }

.woCommerceContant .go_back_btn:hover {
  text-decoration: underline; }

.woCommerceContant .not-fill {
  pointer-events: none;
  opacity: 0.5; }

.woCommerceContant .clickable_btn {
  width: 120px;
  background: #28af21;
  height: 32px;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  margin-right: 8px; }

.woCommerceContant .cancel_close_btn {
  width: 120px;
  background: #f5f5f5;
  height: 32px;
  border: none;
  color: #898989;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  margin-right: 8px; }

.woCommerceContant .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 16px;
  font-size: 12px; }
  .woCommerceContant .modal-header p {
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin-bottom: 0px;
    letter-spacing: 0.032em;
    line-height: 16px; }

.woCommerceContant .ant-modal-close {
  display: none; }

.woCommerceContant .star {
  color: #c62929; }

.click-to-copy {
  cursor: pointer; }
  .click-to-copy .sp {
    color: #898989;
    font-size: 12px;
    margin-right: 4px; }
  .click-to-copy .hover-underline-animation1 {
    display: inline-block;
    position: relative; }
    .click-to-copy .hover-underline-animation1 span {
      color: #0058d6; }
  .click-to-copy .hover-underline-animation1:after {
    content: "";
    position: absolute;
    width: 75%;
    transform: scaleX(0);
    height: 1px;
    bottom: 2px;
    left: 0;
    background-color: #0058d6;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .click-to-copy .hover-underline-animation1:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left; }
  .click-to-copy .hover-underline-animation2:after {
    content: "";
    position: absolute;
    width: 81%;
    transform: scaleX(0);
    height: 1px;
    bottom: 2px;
    left: 0;
    background-color: #0058d6;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .click-to-copy .hover-underline-animation2:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left; }
  .click-to-copy .anticon svg {
    font-size: 12px !important; }

.click-to-copy:hover a {
  color: #0058d6; }
  .click-to-copy:hover a span {
    color: #0058d6; }
  .click-to-copy:hover a g {
    fill: #0058d6; }

.ql-container.ql-snow {
  background: white;
  min-height: 300px;
  font-size: 0.75rem; }
  .ql-container.ql-snow .ql-editor {
    line-height: 1.125rem; }
    .ql-container.ql-snow .ql-editor p strong {
      margin-right: 0.125rem; }

.ql-toolbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  background: white; }

.hidden {
  display: none; }

.image-modal .ant-modal-body {
  min-height: 400px; }

.image-modal .ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 29px; }

.image-modal .image-arrow {
  position: absolute;
  top: 50%;
  z-index: 99;
  background: transparent;
  padding: 0px;
  height: 40px;
  width: 40px;
  border: none;
  box-shadow: none; }
  .image-modal .image-arrow .anticon {
    font-size: 40px; }

.img-gallery .all-imgs img {
  width: 100%; }

.img-gallery .main-img img {
  width: 100%; }

.img-gallery .img-container:hover {
  border: 2px solid #36c626; }
  .img-gallery .img-container:hover .eyeIcon {
    transition-duration: 0.5s;
    bottom: 0;
    right: 0;
    height: 1.25rem;
    padding: 0.125rem 0.3125rem; }

.img-gallery .img-container {
  position: relative;
  border: 2px solid #eee;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  cursor: pointer; }
  .img-gallery .img-container .select-container {
    position: absolute;
    top: 0;
    left: 0;
    background: #36c626;
    color: white;
    padding: 0px 0.25rem;
    border: none; }
    .img-gallery .img-container .select-container .anticon {
      vertical-align: -0.0625rem;
      font-size: 0.875rem; }
  .img-gallery .img-container .eyeIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    background: #0366fb;
    padding: 0 0;
    border: none;
    box-shadow: unset;
    border-radius: 2px;
    height: 0;
    transition-duration: 0.5s; }
    .img-gallery .img-container .eyeIcon .anticon {
      font-size: 1rem; }
  .img-gallery .img-container .anticon-check {
    font-size: 0.75rem; }

.img-gallery .img-container.selected {
  border: 2px solid #36c626;
  border-radius: 0.25rem; }

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #36c626; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #36c626;
  border-color: #36c626; }

.select-deselect p {
  margin-bottom: 0;
  font-size: 0.75rem;
  float: left; }

.select-deselect .ant-checkbox-wrapper span {
  font-size: 0.75rem; }

@media (max-width: 576px) {
  .img-gallery .all-imgs img {
    width: 100%; }
  .img-gallery .main-img img {
    width: 100%; } }

.product-list-variants .product-card-switch {
  font-size: 0.75rem;
  margin: 0 0.5rem;
  color: rgba(0, 0, 0, 0.65); }

.product-list-variants .ant-switch-checked {
  background-color: #36c626; }

.product-list-variants table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .product-list-variants table thead tr th {
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important;
    text-align: center; }
  .product-list-variants table thead tr th:first-child {
    padding-right: 4px;
    text-align: end; }
  .product-list-variants table thead tr th:nth-child(2) {
    padding-left: 4px;
    width: 115px; }
  .product-list-variants table thead tr th:nth-child(7) {
    padding-left: 4px;
    width: 139px; }
  .product-list-variants table thead tr th:nth-child(6) {
    width: 100px; }
  .product-list-variants table thead tr th:nth-child(9) {
    width: 100px; }
  .product-list-variants table tbody {
    background: white; }
    .product-list-variants table tbody tr td {
      font-size: 0.75rem;
      border-bottom-color: #f2f2f2 !important;
      background: #f8f8f8;
      text-align: center; }
      .product-list-variants table tbody tr td input {
        font-size: 0.75rem;
        text-align: center; }
    .product-list-variants table tbody tr td:first-child {
      padding-right: 4px;
      text-align: end; }
    .product-list-variants table tbody tr td:nth-child(2) {
      padding-left: 4px; }
    .product-list-variants table tbody tr td:nth-child(6) {
      width: 100px; }
    .product-list-variants table tbody tr td:nth-child(9) {
      width: 100px; }

.editPrice .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-item {
  font-size: 0.75rem; }

.flex-variants {
  display: flex;
  align-items: center;
  justify-content: center; }
  .flex-variants .sp {
    margin-right: 4px; }

.product-override-modal {
  width: 800px !important;
  max-width: 100%; }
  .product-override-modal .input-label {
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 1rem; }
  .product-override-modal .checkbox-container {
    margin-top: 3rem; }
    .product-override-modal .checkbox-container .ant-checkbox-wrapper {
      font-weight: 500; }
  .product-override-modal .sub-text {
    font-weight: 400;
    font-size: 0.85rem; }

.product-split-modal {
  width: 800px !important;
  max-width: 100%; }
  .product-split-modal .bold-text {
    font-weight: 600;
    color: black; }
  .product-split-modal .block-column {
    padding: 0.5rem 1rem;
    border: 1.5px solid #979797;
    border-radius: 4px;
    margin-bottom: 1rem; }
    .product-split-modal .block-column .ant-checkbox-group {
      margin-left: 3rem; }
      .product-split-modal .block-column .ant-checkbox-group .ant-checkbox-group-item {
        margin-right: 3rem;
        font-weight: 500; }
  .product-split-modal .ant-radio-group {
    display: block; }
    .product-split-modal .ant-radio-group .radio-column {
      cursor: pointer; }
      .product-split-modal .ant-radio-group .radio-column .ant-radio-wrapper {
        font-weight: 700;
        color: black; }
      .product-split-modal .ant-radio-group .radio-column .sub-text {
        margin-left: 1rem;
        font-weight: 500; }
  .product-split-modal .sub-text {
    font-weight: 400;
    font-size: 0.85rem; }

.import-list-product-container {
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0px 3px 10px #0000000d !important;
  border: 0px !important;
  letter-spacing: 0.032em;
  border-radius: 4px; }
  .import-list-product-container .ant-tabs-nav-scroll {
    background: none !important; }
  .import-list-product-container .ant-tabs-nav-wrap {
    margin-bottom: 0px;
    padding-top: 10px; }
  .import-list-product-container .ant-card-body {
    padding: 16px 32px 32px; }
    .import-list-product-container .ant-card-body .ant-tabs-extra-content {
      line-height: 36px; }
  .import-list-product-container .ant-tabs {
    color: #898989; }
  .import-list-product-container .ant-tabs-ink-bar {
    bottom: 0px;
    height: 1px; }
  .import-list-product-container .ant-tabs-bar {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 24px;
    margin: 0 0 16px 0 !important; }
    .import-list-product-container .ant-tabs-bar .ant-tabs-nav {
      margin-left: 3rem; }
      .import-list-product-container .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab-active {
        font-weight: 400; }
      .import-list-product-container .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
        padding: 0px 0px 2px;
        font-size: 12px; }
  .import-list-product-container .product-selection-container {
    position: absolute;
    top: 6px;
    left: 1px; }
    .import-list-product-container .product-selection-container .ant-checkbox-inner {
      margin: 0 0 0 -1px;
      border: 1px solid #e3e3e3;
      background-color: #ededed; }
    .import-list-product-container .product-selection-container .ant-checkbox:hover .ant-checkbox-inner {
      border-color: #36c626; }
    .import-list-product-container .product-selection-container .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #36c626;
      border-color: #36c626; }
  .import-list-product-container .ant-tabs-tabpane {
    max-height: 600px;
    scrollbar-width: none;
    overflow-y: scroll;
    overflow-x: hidden; }
  .import-list-product-container a:hover {
    color: #fff !important; }
  .import-list-product-container .menudropdownacton {
    margin-left: 8px; }

.ant-dropdown .menudropdownacton ul {
  border: none;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.08) !important;
  border-radius: 2px; }
  .ant-dropdown .menudropdownacton ul .menu-items-modal {
    padding: 12px 0 12px 24px !important;
    color: #898989 !important;
    line-height: 16px !important;
    margin: 0 !important;
    font-size: 12px !important; }
    .ant-dropdown .menudropdownacton ul .menu-items-modal:hover {
      background-color: #f5faff !important;
      color: #212121 !important;
      font-weight: 500; }
  .ant-dropdown .menudropdownacton ul .menu-items-modal:hover + .ant-menu-item-divider {
    background-color: #b5dafe;
    margin: 0px 0; }
  .ant-dropdown .menudropdownacton ul .ant-menu-item-divider {
    margin: 0px 0 !important; }

.import-list-product-container.selected {
  border: 1px solid #36c626 !important; }

.ant-dropdown-open:hover > .action-arrow {
  transform: rotate(180deg); }

.ant-dropdown-link {
  color: #898989; }

.importlist_takeaction {
  font-size: 12px;
  transform: translate(-50%, 34%);
  line-height: 16px;
  cursor: pointer;
  padding: 12px 24px;
  color: #fff;
  font-weight: 500;
  width: 128px;
  height: 32px;
  background: #1161fb;
  border-radius: 2px; }
  .importlist_takeaction .ant-dropdown-trigger {
    letter-spacing: 0.032em; }
    .importlist_takeaction .ant-dropdown-trigger .up-arrow {
      display: none; }

.ant-dropdown-link.ant-dropdown-trigger.ant-dropdown-open .down-arrow {
  display: none; }

.up-arrow {
  display: none; }

.ant-dropdown-link.ant-dropdown-trigger.ant-dropdown-open .up-arrow {
  display: inline-block; }

.product-import-modal .ant-modal-body p.input-label {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  margin-bottom: 5px; }

.product-import-modal .ant-modal-body input:first-of-type {
  margin-bottom: 8px; }

.product-import-modal .ant-modal-body .bulk-import .icon-container {
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #979797;
  border-radius: 4px;
  color: #979797;
  text-align: center;
  cursor: pointer; }
  .product-import-modal .ant-modal-body .bulk-import .icon-container i svg {
    width: 5rem;
    height: 5rem; }

.product-import-modal .ant-modal-body .bulk-import .icon-container:hover {
  border-color: #5e73ff;
  color: #5e73ff; }

.product-import-modal .ant-modal-content {
  border-radius: 2px; }

.product-import-modal .ant-modal-title {
  margin: 0;
  color: #212121;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 8px; }

.product-import-modal .ant-modal-close {
  display: none; }

.product-import-modal .ant-modal-header {
  margin: 0 auto;
  padding: 16px 0px;
  border-bottom: 1px solid #cecece;
  width: 432px; }

.product-import-modal .ant-modal-body {
  padding: 16px 24px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-weight: 400; }

.product-import-modal .or-txt {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-weight: 400;
  margin-bottom: 8px; }

.product-import-modal .or-txt2 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 8px; }

.product-import-modal .ant-input {
  background-image: none !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: #f5f5f5;
  color: #212121;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-size: 12px;
  padding: 8px; }

.product-import-modal .ant-input::placeholder {
  font-size: 12px;
  font-weight: 400px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #898989; }

.product-import-modal .ant-modal-footer {
  padding: 16px 0px;
  text-align: right;
  border-top: 1px solid #cecece;
  width: 432px;
  margin: 0 auto; }

.product-import-modal .add-products-bulk-link {
  margin-top: 16px;
  cursor: pointer;
  position: relative; }
  .product-import-modal .add-products-bulk-link a {
    color: #fd104d; }

.product-import-modal .add-products-bulk-link:after {
  content: "";
  position: absolute;
  width: 30%;
  transform: scaleX(0);
  height: 1px;
  bottom: -2px;
  left: 2px;
  background-color: #e61047;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out; }

.product-import-modal .add-products-bulk-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left; }

.product-import-modal .add-import-btn {
  background: #2bc822;
  border: none;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px; }

.product-import-modal .import-cancel-modal {
  border: 1px solid #898989;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px;
  color: #898989; }

.product-import-modal .arrow-right {
  margin-left: 5px; }

.import-list {
  width: 100%;
  overflow-x: scroll; }
  .import-list .import-product-menu {
    color: #898989; }
  .import-list .menudropdownacton a .import-product-menu {
    color: #898989 !important; }
  .import-list .import-product-menu :hover #discover-icon span.import-product-menu {
    color: #212121 !important; }
  .import-list a .import-product-menu:hover {
    color: #212121 !important; }
  .import-list .import-product-menu :hover .download-icon svg g path {
    fill: #212121; }
  .import-list .import-product-menu :hover .discover-icon svg g path {
    stroke: #212121; }
  .import-list .ant-tabs-bar {
    margin: 0 0 25px 0; }
  .import-list .ant-input-search .ant-input {
    font-size: 0.75rem; }
  .import-list .ant-input-search .ant-input::placeholder {
    font-size: 0.75rem; }
  .import-list .select-button .ant-btn {
    margin-left: 5px;
    font-size: 0.75rem;
    height: 1.75rem;
    padding: 0px 8px; }
  .import-list .content-page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 0.032em;
    margin: 0px 8px 0px 0px; }
  .import-list .content-page-subtitle-right {
    text-align: end;
    font-size: 0.75rem; }
  .import-list .ant-tabs-nav-container {
    font-size: 0.875rem; }
  .import-list .import-product-btn {
    color: white !important;
    background-color: #fff0;
    border-color: #0068ff;
    font-size: 0.75rem;
    height: 1.75rem;
    cursor: "pointer"; }
  .import-list .select-all-checkbox:hover .ant-checkbox-inner {
    border-color: #36c626; }
  .import-list .menu-items-modal:hover {
    background-color: red; }
  .import-list .slect-all-order-checkbox {
    margin-bottom: 0.5rem;
    margin-top: -16px; }

.product-import-list .ant-alert {
  word-break: break-all; }

.product-import-list .ql-toolbar.ql-snow {
  z-index: 9 !important; }

.select-all-checkbox .ant-checkbox-inner {
  margin: 0;
  margin-top: 0.125rem; }

.select-all-checkbox .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #36c626; }

.select-all-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #36c626; }

.select-all-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #36c626;
  border-color: #36c626; }

.import-product-search .ant-input-group .ant-input {
  height: 40px;
  box-shadow: 0px 2px 8px #00000014;
  border-radius: 2px 0 0 2px;
  border: 0px; }

.menu-items-modal .hov :hover {
  background-color: #36c626; }

.ant-dropdown
.menudropdownacton
ul
.menu-items-modal:nth-child(1):hover
svg
g
path {
  fill: #212121; }

.ant-dropdown
.menudropdownacton
ul
.menu-items-modal:nth-child(2):hover
svg
g
path {
  stroke: #212121; }

.newValueModal .ant-modal-header {
  padding: 1.5rem 1.5rem 0px;
  border-bottom: none; }

.newValueModal .newValueTitle h2 {
  font-size: 1rem;
  margin-bottom: 0px; }

.newValueModal .newValueTitle p {
  font-size: 0.875rem;
  margin-bottom: 0px;
  font-weight: 400;
  color: #7d7d7d; }

.newValueModal .ant-input-number {
  width: 100%;
  border-radius: 0.125rem; }

.newValueModal .ant-input-number:hover {
  border-color: unset; }

.newValueModal .ant-input-number:focus {
  border-color: unset;
  box-shadow: unset; }

.newValueModal .ant-modal-footer {
  padding: 0 1.5rem 1.5rem;
  border-top: none;
  text-align: center; }
  .newValueModal .ant-modal-footer .ant-btn {
    font-size: 0.75rem;
    width: 6rem;
    box-shadow: none; }

.setMultiplier .ant-modal-header {
  padding: 1.5rem 1.5rem 0px;
  border-bottom: none; }

.setMultiplier .setMultiplierTitle h2 {
  font-size: 1rem;
  margin-bottom: 0px; }

.setMultiplier .setMultiplierTitle p {
  font-size: 0.875rem;
  margin-bottom: 0px;
  font-weight: 400;
  color: #7d7d7d; }

.setMultiplier .ant-input-number {
  width: 100%;
  border-radius: 0.125rem; }

.setMultiplier .ant-input-number:hover {
  border-color: unset; }

.setMultiplier .ant-input-number:focus {
  border-color: unset;
  box-shadow: unset; }

.setMultiplier .ant-modal-footer {
  padding: 0 1.5rem 1.5rem;
  border-top: none;
  text-align: center; }
  .setMultiplier .ant-modal-footer .ant-btn {
    font-size: 0.75rem;
    width: 6rem;
    box-shadow: none; }

.reconnect_storeModal .ant-modal-close span {
  display: none; }

.reconnect_storeModal .ant-modal-body {
  padding: 18px;
  padding-bottom: 5px; }

.reconnect_storeModal .ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  margin-bottom: -16px; }

.reconnect_storeModal .middledata p {
  text-align: center;
  color: #898989;
  margin-bottom: 16px;
  line-height: 16px;
  letter-spacing: 0.032em;
  font-size: 12px; }

.reconnect_storeModal .middledata a {
  text-align: center;
  border: 1px solid #fd9526;
  padding: 8px;
  border-radius: 4px;
  background: #fd9526;
  color: #ffff;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.032em; }

.reconnect_storeModal .ant-modal-footer {
  border-top: none !important;
  padding-top: 0;
  padding-bottom: 16px; }

.reconnect_storeModal .ant-btn {
  border: none;
  box-shadow: none; }

.reconnect_storeModal .d {
  display: flex;
  justify-content: center;
  align-items: center; }

.reconnect_storeModal .bt {
  padding-left: 4px !important; }

.reconnect_storeModal .d:hover svg path {
  fill: #2986ff; }

.importlist-nav .importlistnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .importlist-nav .importlistnav .importlist-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .importlist-nav .importlistnav .importlist-wrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .importlist-nav .importlistnav .importlist-wrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .importlist-nav .importlistnav .importlist-wrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .importlist-nav .importlistnav .importlist-wrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .importlist-nav .importlistnav .importlist-wrap .right .filter svg {
        font-size: 20px; }
      .importlist-nav .importlistnav .importlist-wrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .importlist-nav .importlistnav .importlist-wrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .importlist-nav .importlistnav .importlist-wrap .right .show_data_from {
        font-size: 12px; }
      .importlist-nav .importlistnav .importlist-wrap .right .dispute-status {
        font-size: 12px; }
    .importlist-nav .importlistnav .importlist-wrap .left {
      margin-left: 7px; }
  .importlist-nav .importlistnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .importlist-nav .importlistnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .importlist-nav .importlistnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .importlist-nav .importlistnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .importlist-nav .importlistnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .importlist-nav .importlistnav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .importlist-nav .importlistnav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1161fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1161fb; }
      .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation {
        position: relative; }
        .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation .private-products-txt a {
          color: #898989; }
        .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation .store-product-txt a {
          color: #898989; }
        .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation .wishlist-product-txt a {
          color: #898989; }
        .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation .import-list-txt a {
          color: #898989; }
      .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation:hover {
        color: #4398ed !important; }
        .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation:hover a {
          color: #4398ed; }
        .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation-click {
        position: relative; }
      .importlist-nav .importlistnav .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .importlist-nav .importlistnav .secondwrap .download_csv {
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px;
      margin-right: 100px; }
  .importlist-nav .importlistnav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .importlist-nav .importlistnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .importlist-nav .importlistnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .importlist-nav .importlistnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .importlist-nav .importlistnav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .importlist-nav .importlistnav .ant-calendar-picker-clear,
  .importlist-nav .importlistnav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }
  .importlist-nav .importlistnav .breadcurmb-txt {
    color: #000;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px; }
    .importlist-nav .importlistnav .breadcurmb-txt .breadcurmb-underline {
      text-decoration: underline; }

.importlist-nav .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 76px;
  margin-bottom: 32px; }

.importlist-nav .ant-select-selection__placeholder {
  color: #1268fb; }

@media screen and (min-width: 1440px) {
  .importlistnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6% !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1442px) and (max-width: 3200px) {
  .importlistnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.import-product-search .ant-input-group {
  width: 425px !important; }
  .import-product-search .ant-input-group .ant-input {
    height: 40px !important;
    box-shadow: 0px 2px 8px #66656514;
    border-radius: 2px 0 0 2px;
    background-color: #f8f8f8;
    border: 1px solid #e3e3e3 !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .import-product-search .ant-input-group .ant-input::placeholder {
    font-size: 12px !important;
    line-height: none !important; }
  .import-product-search .ant-input-group .ant-input:hover {
    border: 1px solid #b2b2b2 !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .import-product-search .ant-input-group .ant-input:focus {
    border: 2px solid #fd104d !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }

.import-product-search button {
  width: 122px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  line-height: 8px !important;
  background: #fd104d !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  box-shadow: 2px 2px 8px #002f7225 !important;
  border: none;
  border: 2px solid #fd104d !important; }

.import-product-search button:hover {
  box-shadow: 0px 2px 8px #a01d1d14;
  background-color: #e61047 !important;
  border: 2px solid #c70c3c !important;
  color: #fff !important; }

.import-product-search button:active {
  background-color: #c70c3c !important; }

.import-product-search .store-listing {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.import-product-search-large .ant-input-group {
  width: 608px !important; }

.five-lg .ant-input-group {
  width: 550px !important; }

.nextwidth .ant-input-group {
  width: 378px !important; }
  .nextwidth .ant-input-group .ant-input {
    height: 40px !important;
    box-shadow: 0px 2px 8px #66656514;
    border-radius: 2px 0 0 2px;
    background-color: #f8f8f8 !important;
    border: 1px solid #e3e3e3 !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .nextwidth .ant-input-group .ant-input::placeholder {
    font-size: 12px !important;
    line-height: none !important; }
  .nextwidth .ant-input-group .ant-input:hover {
    border: 1px solid #b2b2b2 !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .nextwidth .ant-input-group .ant-input:focus {
    border: 2px solid #fd104d !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }

.nextwidth button {
  width: 112px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  line-height: 8px !important;
  background: #fd104d !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  box-shadow: 2px 2px 8px #002f7225 !important;
  border: none;
  border: 2px solid #fd104d !important; }

.nextwidth button:hover {
  box-shadow: 0px 2px 8px #a01d1d14;
  background-color: #e61047 !important;
  border: 2px solid #c70c3c !important;
  color: #fff !important; }

.nextwidth button:active {
  background-color: #c70c3c !important; }

.not-found-wraper {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0 3px 10px #0000000d;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 52px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .not-found-wraper * {
    letter-spacing: 0.032em !important;
    color: #898989;
    font-size: 16px; }
  .not-found-wraper > div {
    height: 200px;
    width: 200px; }
    .not-found-wraper > div img {
      height: 100%;
      width: 100%; }
  .not-found-wraper h2 {
    margin-bottom: 4px; }
  .not-found-wraper p {
    font-size: 12px;
    line-height: 16px; }
    .not-found-wraper p span {
      text-decoration: underline;
      font-style: italic;
      font-size: 12px; }

.error-card-wraper {
  border-radius: 3px;
  background-color: #fff7f5;
  border: 1px solid #fd4726;
  padding: 25px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px; }
  .error-card-wraper .error-message-with-ic {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px; }
    .error-card-wraper .error-message-with-ic .icon svg {
      font-size: 24px; }
    .error-card-wraper .error-message-with-ic h2 {
      font-size: 16px;
      font-weight: 600;
      margin-left: 8px;
      color: #212121;
      letter-spacing: 0.032em; }
  .error-card-wraper .error-description {
    font-size: 12px;
    color: #212121;
    letter-spacing: 0.032em;
    margin-bottom: 12px; }
  .error-card-wraper a {
    letter-spacing: 0.032em;
    border-radius: 2px;
    background-color: #fd4726;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    border: none;
    padding: 8px 18px; }

.product-id-g {
  transition: all 0.5s ease;
  font-size: 12px;
  margin-bottom: 6px;
  color: #898989; }
  .product-id-g svg {
    height: 14px;
    width: 14px;
    margin: 0 !important; }
  .product-id-g .id {
    transition: all 0.5s ease; }
  .product-id-g .id:hover {
    transition: all 0.5s ease;
    color: #0058d6;
    text-decoration: underline; }
    .product-id-g .id:hover #Rectangle_2461 {
      transition: all 0.5s ease;
      fill: #0058d6;
      stroke: #0058d6; }

.request-tooltip svg {
  animation: spinend 0.2s ease-out !important; }

.request-tooltip:hover svg {
  animation: spinStart 0.2s ease-out !important;
  fill: #0068FF; }

.request-tooltip:active {
  opacity: 0; }

@keyframes spinend {
  to {
    transform: rotate(-180deg); } }

@keyframes spinStart {
  to {
    transform: rotate(180deg); } }

@keyframes spinStart {
  to {
    -webkit-transform: rotate(180deg); } }

.specialTags li svg:hover rect {
  filter: brightness(0.8); }

.specialTags li svg:hover circle {
  filter: brightness(0.8); }

.ViewInVFCatalog_Button {
  width: 100%; }
  .ViewInVFCatalog_Button button {
    position: relative;
    width: 80%; }
    .ViewInVFCatalog_Button button a {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%; }
      .ViewInVFCatalog_Button button a .ml8 {
        transition: all linear 0.4s;
        transform: translate(0px); }
  .ViewInVFCatalog_Button .hover-underline-animation {
    position: relative;
    color: #0087ca; }
  .ViewInVFCatalog_Button .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 47%;
    transform: scaleX(0);
    height: 1px;
    left: -30px;
    top: 32px;
    margin-left: 33.2%;
    background-color: #2986ff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .ViewInVFCatalog_Button .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: top left; }

.ViewInVFCatalog_Button:hover a .ml8 {
  transition: all linear 0.4s;
  transform: translate(4px); }

.dropdown_link:hover .ml8 {
  transition: all linear 0.4s;
  transform: translate(0px) !important; }

.dropdown_link:hover .ant-dropdown-open {
  color: #fff !important; }

.dropdown_link .ant-dropdown-open {
  color: #fff !important; }

.open-in-store {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 2px;
  margin-top: -2px; }
  .open-in-store p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer; }
  .open-in-store .open-in-store-hover-underline-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #fd104d; }
  .open-in-store .open-in-store-hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 51%;
    transform: scaleX(0);
    height: 1px;
    bottom: 3px;
    left: 33px;
    background-color: #e61047;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .open-in-store .store:after {
    content: "";
    position: absolute;
    width: 56%;
    transform: scaleX(0);
    height: 1px;
    bottom: 3px;
    left: 26px;
    background-color: #e61047;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .open-in-store .open-in-store-hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left; }
  .open-in-store a span {
    color: #ce1543; }
  .open-in-store a svg g path {
    stroke: #ce1543; }

.open-in-store-open-in-store:hover a span {
  color: #e61047; }

.add-to-store {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
  margin-top: 2px;
  cursor: pointer; }
  .add-to-store p a {
    height: 100%; }
    .add-to-store p a svg {
      margin-right: 4px; }
  .add-to-store .add-store-hover-underline-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #fd9526;
    height: 100%;
    cursor: pointer; }
  .add-to-store .add-store-hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 67%;
    transform: scaleX(0);
    height: 1px;
    bottom: 4px;
    left: 20px;
    background-color: #dd8222;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .add-to-store .add-store-hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left; }

.add-store-hover-underline-animation:hover a span {
  color: #dd8222 !important; }

.add-store-hover-underline-animation:hover svg:first-of-type path {
  fill: #dd8222 !important; }

.add-store-hover-underline-animation:hover svg:last-of-type path {
  stroke: #dd8222 !important; }

.product-title .clamp1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden; }

.product-title a {
  color: #1268fb !important; }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .f12.mb4.hover-underline-animation {
    font-size: 14px !important; } }

.main {
  display: flex;
  justify-content: center;
  align-items: center; }

.parent {
  height: 32px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px; }
  .parent h1 {
    font-size: 16px;
    color: #1c6513;
    font-weight: medium;
    margin: 0;
    padding: 0;
    line-height: 0;
    margin-right: 16px; }

.gst-partner {
  font-size: 10px;
  font-weight: 500;
  color: #898989; }

.inventry .inventry-count {
  height: 32px;
  width: 100%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center; }
  .inventry .inventry-count .second {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .inventry .inventry-count .second .inventry-right h2 {
      font-size: 14px;
      color: #212121;
      font-weight: medium;
      margin: 0;
      padding: 0;
      margin-left: 4px; }

.inventry .inventry-filling-day {
  font-size: 10px;
  font-style: italic;
  letter-spacing: 0.032em;
  line-height: 24px; }
  .inventry .inventry-filling-day .restocking-time-txt {
    margin-right: 4px; }

.inventry .f12 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 0.4rem; }
  .inventry .f12 .inventry-available-txt {
    color: #898989;
    margin-right: 8px; }

.inventry .inventry-card-count {
  margin-bottom: 4px;
  align-items: center;
  display: flex; }

.inventry .sv:hover {
  fill: #0068ff; }

.inventry .anticon:hover svg #Ellipse_44 {
  stroke: #1268fb;
  fill: #1268fb; }

.inventry .anticon:hover svg #Ellipse_47 {
  stroke: #fff;
  fill: #ffff; }

.inventry .anticon:hover svg #Rectangle_2462 {
  stroke: #fff;
  fill: #ffff; }

.inventry .inventry-tooltip svg {
  width: 16px;
  height: 16px; }

.inventry .inventry-tooltip-txt {
  font-size: 10px; }

.inventry .inventry-date {
  color: #212121;
  letter-spacing: 0.032em; }

.Private-catalog-listing {
  background: #f0f2f5; }
  .Private-catalog-listing .f8 {
    font-size: 10px; }
  .Private-catalog-listing .f12 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em; }
  .Private-catalog-listing .f16 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032em; }
  .Private-catalog-listing .f20 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.032em; }
  .Private-catalog-listing .mr0 {
    margin-right: 0px; }
  .Private-catalog-listing .ml0 {
    margin-left: 0px; }
  .Private-catalog-listing .mt0 {
    margin-top: 0px; }
  .Private-catalog-listing .mb0 {
    margin-bottom: 0px; }
  .Private-catalog-listing .mb4 {
    margin-bottom: 2px; }
  .Private-catalog-listing .mr8 {
    margin-right: 8px; }
  .Private-catalog-listing .ml8 {
    margin-left: 8px; }
  .Private-catalog-listing .mt8 {
    margin-top: 8px; }
  .Private-catalog-listing .mb8 {
    margin-bottom: 8px; }
  .Private-catalog-listing .mr16 {
    margin-right: 16px; }
  .Private-catalog-listing .ml16 {
    margin-left: 16px; }
  .Private-catalog-listing .mt16 {
    margin-top: 10px; }
  .Private-catalog-listing .mb16 {
    margin-bottom: 0px; }
  .Private-catalog-listing .w100 {
    width: 100%; }
  .Private-catalog-listing .py8 {
    padding-top: 8px;
    padding-bottom: 8px; }
  .Private-catalog-listing .px8 {
    padding-left: 8px;
    padding-right: 8px; }
  .Private-catalog-listing .py2 {
    padding-top: 2px;
    padding-bottom: 2px; }
  .Private-catalog-listing .px2 {
    padding-left: 2px;
    padding-right: 2px; }
  .Private-catalog-listing .semibold {
    font-weight: 500; }
  .Private-catalog-listing .bold {
    font-weight: 500; }
    .Private-catalog-listing .bold .default_retail_price {
      font-size: 12px;
      margin-left: -40px;
      margin-right: 8px;
      font-style: italic;
      letter-spacing: 0.032em;
      text-decoration: line-through;
      -webkit-text-decoration-color: #898989;
              text-decoration-color: #898989; }
  .Private-catalog-listing .italic {
    font-style: italic;
    margin-top: 8px; }
  .Private-catalog-listing .underline {
    text-decoration: underline !important; }
  .Private-catalog-listing button {
    cursor: pointer; }
  .Private-catalog-listing .clamp2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .Private-catalog-listing .niche-item {
    font-size: 12px;
    color: #212121;
    text-transform: capitalize; }
  .Private-catalog-listing .catalog-listing-content {
    width: 100%;
    margin: auto;
    display: flex;
    padding-top: 24px; }
    .Private-catalog-listing .catalog-listing-content .content-sidebar {
      width: 248px;
      margin-right: 24px; }
      .Private-catalog-listing .catalog-listing-content .content-sidebar .filters {
        background: white;
        padding: 15px 16px 24px 16px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
        .Private-catalog-listing .catalog-listing-content .content-sidebar .filters .filter-tag {
          display: block;
          color: #acacac;
          background: #eeeeee;
          padding: 8px 16px;
          margin-bottom: 16px;
          letter-spacing: 0.025em; }
        .Private-catalog-listing .catalog-listing-content .content-sidebar .filters .filter-tag.active {
          color: #212121; }
        .Private-catalog-listing .catalog-listing-content .content-sidebar .filters .location-tag.active {
          background: #2bc822;
          color: white; }
    .Private-catalog-listing .catalog-listing-content .content {
      flex: 1 1; }
  .Private-catalog-listing .product-tooltipican:hover svg.product-tooltip-icon {
    background: #1268fb;
    border-radius: 50%;
    cursor: pointer; }
  .Private-catalog-listing .product-tooltipican:hover svg.product-tooltip-icon g text {
    fill: #fff; }
  .Private-catalog-listing .product-tooltipican:hover svg.product-tooltip-icon g > g {
    stroke: #1268fb; }
  .Private-catalog-listing .top-import-button {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    background: #2bc822;
    padding: 8px 16px;
    color: #fff;
    max-height: 40px;
    border-radius: 2px;
    border: none; }
  .Private-catalog-listing .product-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px; }
    .Private-catalog-listing .product-grid .product-card {
      background: white;
      margin-bottom: 24px;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
      position: relative;
      display: flex;
      height: auto;
      flex-direction: column; }
      .Private-catalog-listing .product-grid .product-card .potd-label {
        position: absolute;
        background: linear-gradient(150deg, #fd104d, #f0320f);
        padding: 4px 8px;
        color: white;
        top: 8px;
        z-index: 2; }
      .Private-catalog-listing .product-grid .product-card .product-watchlist-btn {
        position: absolute;
        top: 0;
        right: 16px;
        padding: 0;
        border: none;
        background: transparent; }
      .Private-catalog-listing .product-grid .product-card .content {
        padding: 16px;
        padding-bottom: 0px;
        display: flex; }
        .Private-catalog-listing .product-grid .product-card .content .left {
          margin-right: 24px; }
        .Private-catalog-listing .product-grid .product-card .content .product-img {
          display: flex;
          flex-direction: column;
          position: relative;
          overflow: hidden;
          border-radius: 2px; }
          .Private-catalog-listing .product-grid .product-card .content .product-img img {
            width: 168px;
            height: 168px;
            object-fit: cover;
            border-radius: 2px;
            border: 2px solid #f5f5f5;
            transition: all 0.5s ease; }
          .Private-catalog-listing .product-grid .product-card .content .product-img .prev-img,
          .Private-catalog-listing .product-grid .product-card .content .product-img .next-img {
            transition: all 0.5s ease;
            position: absolute;
            width: 32px;
            background: rgba(33, 33, 33, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            opacity: 0;
            height: 168px; }
          .Private-catalog-listing .product-grid .product-card .content .product-img .prev-img {
            top: 0;
            left: 0; }
          .Private-catalog-listing .product-grid .product-card .content .product-img .next-img {
            top: 0;
            right: 0; }
        .Private-catalog-listing .product-grid .product-card .content .product-img:hover img {
          transition: all 0.5s ease;
          transform: scale(1.05); }
        .Private-catalog-listing .product-grid .product-card .content .product-img:hover .prev-img,
        .Private-catalog-listing .product-grid .product-card .content .product-img:hover .next-img {
          transition: all 0.5s ease;
          opacity: 1;
          transform: scale(1.05); }
        .Private-catalog-listing .product-grid .product-card .content .details {
          flex: 1 1; }
      .Private-catalog-listing .product-grid .product-card .footer {
        padding: 0;
        display: flex;
        text-align: center;
        justify-content: space-between; }
        .Private-catalog-listing .product-grid .product-card .footer .product_detail {
          background-color: #f5faff; }
        .Private-catalog-listing .product-grid .product-card .footer .view-product-details {
          border: none;
          box-shadow: none;
          color: #0068ff;
          font-weight: 700;
          background-color: #f5faff;
          border-radius: 0;
          font-weight: 600;
          font-size: 12px;
          height: 48px; }
      .Private-catalog-listing .product-grid .product-card .dropdown_link {
        width: 20%; }
      .Private-catalog-listing .product-grid .product-card .ant-dropdown-link {
        width: 100%;
        background: #36c626;
        border: 0;
        font-weight: 700;
        border-radius: 0;
        color: #fff;
        font-size: 12px;
        height: 48px; }
  .Private-catalog-listing .request_lattest_ican {
    display: flex;
    justify-content: space-between;
    margin-bottom: -19px; }
  .Private-catalog-listing .request_lattest_ican2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px; }
  .Private-catalog-listing .product-tooltip-icon {
    margin-top: -2px; }
  .Private-catalog-listing .sv:hover {
    fill: #0068ff; }
  .Private-catalog-listing .anticon:hover svg #Ellipse_44 {
    stroke: #1268fb;
    fill: #1268fb; }
  .Private-catalog-listing .anticon:hover svg #Ellipse_47 {
    stroke: #fff;
    fill: #ffff; }
  .Private-catalog-listing .anticon:hover svg #Rectangle_2462 {
    stroke: #fff;
    fill: #ffff; }
  .Private-catalog-listing .product-tooltipican svg {
    width: 16px;
    height: 16px; }
  .Private-catalog-listing .prev-img svg {
    width: 18px;
    height: 41px; }
  .Private-catalog-listing .next-img svg {
    width: 18px;
    height: 41px; }
  .Private-catalog-listing .cost-update-date {
    color: #212121; }
  .Private-catalog-listing .req-latest-price svg {
    margin-bottom: 2px !important; }

.Private_ali-link-menu {
  padding: 10px !important; }
  .Private_ali-link-menu li a {
    padding: 2px 0;
    line-height: 22px;
    font-size: 12px;
    color: #ff005e; }

@media screen and (min-width: 1360px) {
  .Private-catalog-listing .catalog-listing-content {
    width: 100%;
    margin: auto; }
  .dropdown_link {
    width: 21%; } }

.sv2 {
  pointer-events: none; }
  .sv2 svg {
    fill: #b2b2b2; }
  .sv2 svg:hover {
    fill: #b2b2b2; }

#test {
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1s; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

.private-product-nav .privateproductnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .private-product-nav .privateproductnav .private-product-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .private-product-nav .privateproductnav .private-product-wrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .private-product-nav .privateproductnav .private-product-wrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .private-product-nav .privateproductnav .private-product-wrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .private-product-nav .privateproductnav .private-product-wrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .private-product-nav .privateproductnav .private-product-wrap .right .filter svg {
        font-size: 20px; }
      .private-product-nav .privateproductnav .private-product-wrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .private-product-nav .privateproductnav .private-product-wrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .private-product-nav .privateproductnav .private-product-wrap .right .show_data_from {
        font-size: 12px; }
      .private-product-nav .privateproductnav .private-product-wrap .right .dispute-status {
        font-size: 12px; }
    .private-product-nav .privateproductnav .private-product-wrap .left {
      margin-left: "7px"; }
  .private-product-nav .privateproductnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .private-product-nav .privateproductnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .private-product-nav .privateproductnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .private-product-nav .privateproductnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .private-product-nav .privateproductnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .private-product-nav .privateproductnav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .private-product-nav .privateproductnav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1161fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1161fb; }
      .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation {
        position: relative; }
        .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation .private-products-txt a {
          color: #898989; }
        .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation .store-products-txt a {
          color: #898989; }
        .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation .wishlist-product-txt a {
          color: #898989; }
        .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation .import-list-txt a {
          color: #898989; }
      .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation:hover {
        color: #4398ed !important; }
        .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation:hover a {
          color: #4398ed; }
        .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation-click {
        position: relative; }
      .private-product-nav .privateproductnav .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
  .private-product-nav .privateproductnav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .private-product-nav .privateproductnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .private-product-nav .privateproductnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .private-product-nav .privateproductnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .private-product-nav .privateproductnav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .private-product-nav .privateproductnav .ant-calendar-picker-clear,
  .private-product-nav .privateproductnav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }
  .private-product-nav .privateproductnav .breadcurmb-txt {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px; }
    .private-product-nav .privateproductnav .breadcurmb-txt > span {
      margin: 0px 4px; }
    .private-product-nav .privateproductnav .breadcurmb-txt .breadcurmb-underline {
      text-decoration: underline; }

.private-product-nav .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 76px;
  margin-bottom: -32px; }

.private-product-nav .ant-select-selection__placeholder {
  color: #1268fb; }

@media screen and (min-width: 1440px) {
  .privateproductnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6% !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1442px) and (max-width: 3200px) {
  .privateproductnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.my-products-card {
  margin-bottom: 1rem;
  letter-spacing: 0.032em;
  font-size: 12px;
  margin-bottom: 24px; }
  .my-products-card .img-container {
    border: 2px solid #f5f5f5;
    background: white;
    padding: 0;
    text-align: right;
    width: 100%;
    height: 160px;
    background-size: cover !important;
    background-position: center !important; }
  .my-products-card .flex-on {
    color: #2bc822;
    position: relative;
    margin-left: 5px; }
    .my-products-card .flex-on span {
      color: #cecece;
      display: inline-block;
      margin-left: 3px;
      margin-right: 3px; }
    .my-products-card .flex-on svg {
      position: relative;
      border-radius: 8px;
      top: 3px; }
  .my-products-card .my-products-card-dropdown-menu li {
    text-align: left; }
  .my-products-card .switch {
    margin-right: 8px; }
  .my-products-card .text-container {
    padding-left: 32px;
    color: #000;
    margin-left: -13px; }
    .my-products-card .text-container p {
      font-size: 0.9rem; }
    .my-products-card .text-container b {
      font-weight: 500; }
    .my-products-card .text-container .product-name {
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 8px; }
    .my-products-card .text-container .supplier-name {
      color: #5e73ff; }
    .my-products-card .text-container .price-details {
      margin-bottom: 0;
      font-weight: 300; }
      .my-products-card .text-container .price-details b {
        font-size: 0.95rem; }
  .my-products-card .next-img {
    right: 0; }
  .my-products-card .prev-img {
    left: 0; }
  .my-products-card .img-container button {
    position: absolute;
    top: 0%;
    height: 100%;
    background: #212121;
    cursor: pointer;
    display: none;
    border: none;
    opacity: 0.4; }
  .my-products-card .img-container:hover button {
    display: block; }
  .my-products-card .mapping-product {
    text-align: center;
    margin-top: 8px; }
    .my-products-card .mapping-product .link-product {
      background-color: #fd4726;
      color: #fff;
      padding: 8px 16px;
      margin: 16px;
      display: inline-block;
      border-radius: 2px;
      border: 2px solid #fd4726;
      cursor: pointer; }
      .my-products-card .mapping-product .link-product:hover {
        background: #e61047;
        border: 2px solid #c70c3c;
        color: #fff;
        padding: 8px 16px;
        margin: 16px;
        display: inline-block;
        border-radius: 2px;
        cursor: pointer; }
    .my-products-card .mapping-product .bundle-product {
      border: none;
      background: transparent;
      cursor: pointer;
      color: red;
      padding: 8px 16px;
      letter-spacing: 0.032em;
      line-height: 16px;
      border: 1px solid #fd4726;
      cursor: pointer; }

.not-available-txt {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 400; }

.my-products-card.not-linked {
  border: none !important;
  box-shadow: none !important; }

.my-products-card.provisional {
  border: none !important;
  box-shadow: none !important;
  font-size: 12px;
  letter-spacing: 0.032em; }
  .my-products-card.provisional .ant-card-body {
    padding-bottom: 18px;
    padding: 32px 32px 32px !important; }
    .my-products-card.provisional .ant-card-body .ribbon span {
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      text-transform: capitalize;
      text-align: center;
      line-height: 34px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      width: 198px;
      display: block;
      background: #ff9400;
      position: absolute;
      top: 35px;
      left: -50px;
      z-index: 999; }
  .my-products-card.provisional .ant-btn:hover,
  .my-products-card.provisional .ant-btn:focus {
    border-color: #ff9500; }
  .my-products-card.provisional .escalated-now-alert {
    padding: 16px 16px 16px 56px; }
    .my-products-card.provisional .escalated-now-alert .ant-alert-icon {
      font-size: 22px;
      top: 14.5px; }
    .my-products-card.provisional .escalated-now-alert .ant-btn {
      background: #ff9500;
      color: white;
      margin-left: 15px;
      font-size: 14px;
      font-weight: 400;
      box-shadow: none;
      border: none; }
  .my-products-card.provisional .ant-alert.ant-alert-warning.escalated-now-alert {
    border: 1px solid #0068ff;
    background-color: #f5faff; }
  .my-products-card.provisional .ant-alert-warning .ant-alert-icon {
    color: #0068ff; }
  .my-products-card.provisional .escalated-alert {
    padding: 16px 16px 16px 56px;
    background-color: #f5faff;
    border: 1px solid #2bc822; }
    .my-products-card.provisional .escalated-alert .ant-alert-icon {
      font-size: 22px;
      top: 14.5px;
      color: #70d35c; }
    .my-products-card.provisional .escalated-alert .ant-btn {
      background: #70d35c;
      color: white;
      margin-left: 15px;
      font-size: 14px;
      font-weight: 400;
      box-shadow: none;
      border: none; }

.cost-breakup-button {
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.5px;
  height: 18px;
  line-height: 19px;
  border-radius: 2px;
  box-shadow: none;
  border: none;
  padding: 0px 7px;
  margin-left: 14px; }

.shopify-sell-tooltip {
  display: none; }

.shopify-sell:hover .shopify-sell-tooltip {
  display: block; }

.shopify-sell .shopify-sell-tooltip::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 1%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #ff9500 transparent; }

.my-product-card-dropdown-menu .ant-dropdown-menu-item {
  border-bottom: 1px solid #cecece !important;
  padding: 12px 16px !important;
  color: #898989 !important;
  line-height: 16px !important;
  margin: 0 !important;
  font-size: 12px !important;
  letter-spacing: 0.032em;
  text-align: left; }
  .my-product-card-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: #f5faff !important;
    color: #212121 !important;
    font-weight: 500; }
  .my-product-card-dropdown-menu .ant-dropdown-menu-item span.switch .ant-switch {
    min-width: 36px !important;
    height: 16px !important;
    margin-left: 16px; }
  .my-product-card-dropdown-menu .ant-dropdown-menu-item span.switch .ant-switch::after {
    top: 0px;
    width: 14px !important;
    height: 14px !important; }

.my-product-card-dropdown-menu .ant-dropdown-menu-item-active {
  box-shadow: 0px -1px 0px #b5dafe inset;
  border-bottom: none !important; }

.action_btn .dropdownaction:hover {
  color: #212121 !important;
  font-weight: 400 !important; }

.ant-card {
  border: 0px !important;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05) !important; }

.notlinked .ribbon span {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  line-height: 34px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 198px;
  display: block;
  background: #e14643;
  position: absolute;
  top: 35px;
  left: -50px;
  z-index: 999; }

.sv3 {
  pointer-events: none;
  color: #898989;
  text-decoration: none !important; }

.product-name-id a {
  text-decoration: underline !important; }

.blue-store-product-title a:hover {
  text-decoration: underline !important; }

.green-dot {
  position: relative;
  right: 5px; }
  .green-dot svg circle {
    fill: #2bc822; }

.product-link-modal .ant-modal-content {
  border-radius: 0px; }

.product-link-modal .ant-radio-group {
  display: block; }
  .product-link-modal .ant-radio-group .ant-radio-wrapper {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    font-size: 12px;
    border-radius: 0px;
    padding: 10px;
    display: block;
    margin-bottom: 1rem; }
    .product-link-modal .ant-radio-group .ant-radio-wrapper p {
      font-size: 12px; }
    .product-link-modal .ant-radio-group .ant-radio-wrapper .label-text {
      font-weight: 400;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121; }
  .product-link-modal .ant-radio-group .ant-radio-wrapper:hover:hover {
    background-color: #f0ffef;
    border: 1px solid #2bc822; }

.product-link-modal .next-button {
  background-color: #2bc822;
  outline: none;
  border: none;
  width: 104px;
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.032em; }

.product-link-modal .cancle-button {
  width: 104px;
  border-radius: 2px;
  color: #898989;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-size: 12px;
  font-weight: 500; }

.product-link-modal .ant-radio-inner::after {
  background-color: #fff; }

.product-link-modal .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  background-color: #36c626; }

.product-link-modal .ant-btn-primary-disabled,
.product-link-modal .ant-btn-primary.disabled,
.product-link-modal .ant-btn-primary[disabled],
.product-link-modal .ant-btn-primary-disabled:hover,
.product-link-modal .ant-btn-primary.disabled:hover,
.product-link-modal .ant-btn-primary[disabled]:hover,
.product-link-modal .ant-btn-primary-disabled:focus,
.product-link-modal .ant-btn-primary.disabled:focus,
.product-link-modal .ant-btn-primary[disabled]:focus,
.product-link-modal .ant-btn-primary-disabled:active,
.product-link-modal .ant-btn-primary.disabled:active,
.product-link-modal .ant-btn-primary[disabled]:active,
.product-link-modal .ant-btn-primary-disabled.active,
.product-link-modal .ant-btn-primary.disabled.active,
.product-link-modal .ant-btn-primary[disabled].active {
  color: #fff;
  background-color: #8bd987;
  border-color: #8bd987;
  text-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  width: 104px;
  font-weight: 500;
  line-height: 16px;
  font-size: 12px; }

.product-link-modal .link-product-txt {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.032em;
  color: #212121;
  line-height: 16px; }

.product-link-modal .ant-modal-body {
  padding: 16px 24px;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word; }

.product-link-modal .ant-modal-footer {
  padding: 16px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #e8e8e8;
  border-radius: 0 0 4px 4px;
  margin-top: -16px; }

.product-link-modal .link-product-note-txt {
  color: #898989;
  font-size: 12px;
  letter-spacing: 0.032em;
  font-style: italic;
  line-height: 16px;
  margin-bottom: 16px; }

.product-link-modal .ant-modal-close-x {
  display: none; }

.link-product-ali-express-modal .ant-modal-footer {
  text-align: left; }

.link-product-ali-express-modal .ant-input {
  background-color: #f5f5f5;
  border: none; }

.link-product-ali-express-modal .ant-modal-content {
  border-radius: 0px; }

.link-product-ali-express-modal .ant-modal-body p.input-label {
  margin-bottom: 0;
  font-weight: 400;
  color: #898989;
  font-size: 12px; }

.link-product-ali-express-modal .ant-modal-body input:first-of-type {
  margin-bottom: 15px; }

.link-product-ali-express-modal .ant-modal-body .bulk-import .icon-container {
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #979797;
  border-radius: 4px;
  color: #979797;
  text-align: center;
  cursor: pointer; }
  .link-product-ali-express-modal .ant-modal-body .bulk-import .icon-container i svg {
    width: 5rem;
    height: 5rem; }

.link-product-ali-express-modal .ant-modal-body .bulk-import .icon-container:hover {
  border-color: #5e73ff;
  color: #5e73ff; }

.link-product-ali-express-modal .next-button {
  background-color: #2bc822;
  outline: none;
  border: none;
  width: 161px;
  border-radius: 2px; }

.link-product-ali-express-modal .cancle-button {
  width: 161px;
  border-radius: 2px; }

.link-product-import-list-modal {
  width: 800px !important;
  max-width: 100%; }
  .link-product-import-list-modal .ant-select-selection--single {
    font-weight: 500;
    border-radius: 0px;
    background-color: #f5f5f5;
    border: none;
    padding: 0 8px;
    font-size: 12px; }
  .link-product-import-list-modal .ant-select-lg .ant-select-selection--single {
    height: 56px; }
  .link-product-import-list-modal .ant-select-lg .ant-select-selection__rendered {
    line-height: 56px; }
  .link-product-import-list-modal .checkbox-container {
    margin-top: 3rem; }
    .link-product-import-list-modal .checkbox-container .ant-checkbox-wrapper {
      font-weight: 500; }
  .link-product-import-list-modal .sub-text {
    font-weight: 400;
    font-size: 0.85rem; }
  .link-product-import-list-modal .next-button {
    background-color: #2bc822;
    border-color: #2bc822;
    outline: none;
    border: none;
    width: 161px;
    border-radius: 2px;
    color: #fff;
    height: 40px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500; }
  .link-product-import-list-modal .cancle-button {
    width: 161px;
    border-radius: 2px;
    height: 40px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500; }
  .link-product-import-list-modal .input-label {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 4px;
    color: #898989; }
  .link-product-import-list-modal .existing_product_txt {
    font-size: 12px;
    color: #212121;
    margin-bottom: 8px;
    margin-top: -8px; }
  .link-product-import-list-modal .ant-btn-primary-disabled,
  .link-product-import-list-modal .ant-btn-primary.disabled,
  .link-product-import-list-modal .ant-btn-primary[disabled],
  .link-product-import-list-modal .ant-btn-primary-disabled:hover,
  .link-product-import-list-modal .ant-btn-primary.disabled:hover,
  .link-product-import-list-modal .ant-btn-primary[disabled]:hover,
  .link-product-import-list-modal .ant-btn-primary-disabled:focus,
  .link-product-import-list-modal .ant-btn-primary.disabled:focus,
  .link-product-import-list-modal .ant-btn-primary[disabled]:focus,
  .link-product-import-list-modal .ant-btn-primary-disabled:active,
  .link-product-import-list-modal .ant-btn-primary.disabled:active,
  .link-product-import-list-modal .ant-btn-primary[disabled]:active,
  .link-product-import-list-modal .ant-btn-primary-disabled.active,
  .link-product-import-list-modal .ant-btn-primary.disabled.active,
  .link-product-import-list-modal .ant-btn-primary[disabled].active {
    color: #fff;
    background-color: #8bd987;
    border-color: #8bd987;
    text-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    width: 161px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500;
    height: 40px; }
  .link-product-import-list-modal .link-import-list {
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500;
    color: #212121; }
  .link-product-import-list-modal .existing-product-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 400;
    color: #212121;
    margin-bottom: -8px; }
  .link-product-import-list-modal .ant-modal-body {
    font-size: 12px;
    line-height: 1.5;
    word-wrap: break-word;
    padding-top: 16px !important;
    padding-right: 0px !important;
    padding-left: 0px !important; }
  .link-product-import-list-modal .ant-modal-close-x {
    display: none; }
  .link-product-import-list-modal .link-import-list-modal {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .link-product-import-list-modal .link-import-list-modal .existing-product {
      width: 50%;
      margin-right: 16px; }
    .link-product-import-list-modal .link-import-list-modal .link-product {
      width: 50%; }
  .link-product-import-list-modal .ant-modal-footer {
    padding-top: 16px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
    padding-bottom: 0px !important; }
  .link-product-import-list-modal .image-with-txt {
    background: #f5f5f5;
    padding: 8px 8px;
    display: inline-block;
    width: 100%;
    height: 56px;
    align-items: center;
    display: flex !important; }
    .link-product-import-list-modal .image-with-txt img {
      vertical-align: middle;
      border: 1px solid #898989;
      padding: 4px;
      width: 40px;
      height: 40px; }
    .link-product-import-list-modal .image-with-txt .image-product-name {
      margin: 0 0 0 20px;
      vertical-align: middle;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 50px);
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-left: 8px; }
  .link-product-import-list-modal .option-image {
    vertical-align: middle;
    border: 1px solid #898989;
    padding: 4px;
    width: 40px;
    height: 40px; }
  .link-product-import-list-modal .footer-data {
    display: flex;
    justify-content: space-between; }
    .link-product-import-list-modal .footer-data .link-product-note-txt {
      color: #898989;
      letter-spacing: 0.032em;
      font-style: italic;
      line-height: 16px;
      width: 389px;
      font-size: 12px !important;
      text-align: left; }
  .link-product-import-list-modal .ant-modal-content {
    padding: 24px !important; }
  .link-product-import-list-modal .ant-modal-header {
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 4px 4px 0 0;
    padding-bottom: 16px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important; }

.link-product-bundle-modal .ant-modal-body {
  font-size: 12px;
  line-height: 1.5;
  word-wrap: break-word;
  padding-top: 16px !important;
  padding-right: 0px !important;
  padding-left: 0px !important; }
  .link-product-bundle-modal .ant-modal-body .ant-select-lg .ant-select-selection--multiple {
    min-height: 47px;
    border: none;
    background: #f5f5f5;
    border-radius: unset; }
  .link-product-bundle-modal .ant-modal-body .ant-select-selection--multiple .ant-select-selection__placeholder {
    margin-left: 6px;
    font-size: 12px;
    line-height: 35px;
    height: 47px; }
  .link-product-bundle-modal .ant-modal-body .ant-select-selection--multiple .ant-select-selection__choice {
    color: #212121 !important;
    background-color: #fff !important;
    border: none !important;
    border-radius: 0px;
    font-size: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 0.032em;
    margin-bottom: 5px; }
  .link-product-bundle-modal .ant-modal-body .ant-radio-group {
    display: block; }
    .link-product-bundle-modal .ant-modal-body .ant-radio-group .ant-radio-wrapper {
      border: 1.5px solid #979797;
      border-radius: 4px;
      padding: 0.5rem 1rem;
      display: block;
      margin: 1rem auto; }
      .link-product-bundle-modal .ant-modal-body .ant-radio-group .ant-radio-wrapper .label-text {
        font-weight: 700; }
  .link-product-bundle-modal .ant-modal-body p.input-label {
    margin-bottom: 0;
    font-weight: 600; }
  .link-product-bundle-modal .ant-modal-body input:first-of-type {
    margin-bottom: 15px; }
  .link-product-bundle-modal .ant-modal-body .bulk-import .icon-container {
    margin-top: 10px;
    padding: 10px;
    border: 2px solid #979797;
    border-radius: 4px;
    color: #979797;
    text-align: center;
    cursor: pointer; }
    .link-product-bundle-modal .ant-modal-body .bulk-import .icon-container i svg {
      width: 5rem;
      height: 5rem; }
  .link-product-bundle-modal .ant-modal-body .bulk-import .icon-container:hover {
    border-color: #5e73ff;
    color: #5e73ff; }

.link-product-bundle-modal .ant-modal-footer {
  padding-top: 16px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important; }

.link-product-bundle-modal .next-button {
  background-color: #2bc822;
  outline: none;
  border: none;
  width: 161px;
  border-radius: 2px; }

.link-product-bundle-modal .cancle-button {
  width: 161px;
  border-radius: 2px; }

.link-product-bundle-modal .ant-btn-primary-disabled,
.link-product-bundle-modal .ant-btn-primary.disabled,
.link-product-bundle-modal .ant-btn-primary[disabled],
.link-product-bundle-modal .ant-btn-primary-disabled:hover,
.link-product-bundle-modal .ant-btn-primary.disabled:hover,
.link-product-bundle-modal .ant-btn-primary[disabled]:hover,
.link-product-bundle-modal .ant-btn-primary-disabled:focus,
.link-product-bundle-modal .ant-btn-primary.disabled:focus,
.link-product-bundle-modal .ant-btn-primary[disabled]:focus,
.link-product-bundle-modal .ant-btn-primary-disabled:active,
.link-product-bundle-modal .ant-btn-primary.disabled:active,
.link-product-bundle-modal .ant-btn-primary[disabled]:active,
.link-product-bundle-modal .ant-btn-primary-disabled.active,
.link-product-bundle-modal .ant-btn-primary.disabled.active,
.link-product-bundle-modal .ant-btn-primary[disabled].active {
  color: #fff;
  background-color: #8bd987;
  border-color: #8bd987;
  text-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  width: 161px; }

.link-product-bundle-modal .ant-select-selection__rendered {
  line-height: 29px !important; }
  .link-product-bundle-modal .ant-select-selection__rendered li {
    height: 38px !important;
    line-height: 32px !important;
    margin-top: 5px !important;
    width: 381px !important; }

.link-product-bundle-modal .input-label {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 400 !important;
  margin-bottom: 4px !important; }

.link-product-bundle-modal .link-product-note-txt {
  color: #898989;
  letter-spacing: 0.032em;
  font-style: italic;
  line-height: 16px;
  margin-bottom: 14px;
  width: 433px;
  font-size: 12px !important;
  margin-top: 8px;
  text-align: start !important; }

.link-product-bundle-modal .bundle-product-list {
  font-size: 16px;
  letter-spacing: 0.032em;
  line-height: 24px;
  font-weight: 500;
  color: #212121; }

.link-product-bundle-modal .bundle-product-txt {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 24px;
  font-weight: 400;
  color: #212121;
  margin-bottom: -8px; }

.link-product-bundle-modal .ant-modal-close-x {
  display: none; }

.link-product-bundle-modal .ant-modal-content {
  padding: 24px !important; }

.link-product-bundle-modal .ant-modal-header {
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  padding-bottom: 16px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-left: 0px !important; }

.cost-brakup-modal .ant-modal-content {
  padding: 8px; }
  .cost-brakup-modal .ant-modal-content .ant-modal-header {
    border-bottom: none;
    padding-bottom: 0px;
    padding-top: 24px; }
    .cost-brakup-modal .ant-modal-content .ant-modal-header .ant-modal-title {
      font-weight: 600;
      font-size: 24px; }
  .cost-brakup-modal .ant-modal-content .ant-modal-body {
    padding-top: 0px; }
    .cost-brakup-modal .ant-modal-content .ant-modal-body .cost-breakeup-heading {
      margin-bottom: 5px;
      font-size: 16px !important;
      font-weight: 500;
      color: #000000d9; }

.cost-brakup-modal .ant-modal-close {
  top: 20px;
  right: 15px; }
  .cost-brakup-modal .ant-modal-close .anticon {
    font-size: 24px; }

.cost-brakup-modal .margin-class {
  margin-bottom: 8px;
  margin-top: 37px; }

.cost-brakup-modal .ant-select-selection {
  width: 100%;
  height: 36px;
  padding: 4px 11px;
  color: #000000d9;
  font-size: 16px;
  font-weight: 500;
  background-color: #f8f8f8;
  border: 1px solid #707070;
  border-radius: 0px; }

.cost-brakup-modal .cost-breakup-container {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .cost-brakup-modal .cost-breakup-container .cost-breakup-inputs {
    flex: 1 1;
    margin: 5px; }
    .cost-brakup-modal .cost-breakup-container .cost-breakup-inputs .ant-input {
      width: 100%;
      height: 36px;
      padding: 4px 11px;
      color: #000000d9;
      font-size: 16px;
      font-weight: 500;
      background-color: #f8f8f8;
      border: 1px solid #707070;
      border-radius: 0px; }
  .cost-brakup-modal .cost-breakup-container .cost-breakup-inputs:first-child {
    margin-left: 0; }
  .cost-brakup-modal .cost-breakup-container .cost-breakup-inputs:last-child {
    margin-right: 0; }
  .cost-brakup-modal .cost-breakup-container .ali-pricing {
    padding: 12px 20px;
    background: #fdfeff;
    width: 50%; }
    .cost-brakup-modal .cost-breakup-container .ali-pricing p {
      font-size: 14px;
      color: #000000d9;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .cost-brakup-modal .cost-breakup-container .ali-pricing span {
      color: #005cff;
      font-weight: 500; }
  .cost-brakup-modal .cost-breakup-container .vf-pricing {
    padding: 12px 20px;
    background: #f4f8ff;
    width: 50%; }
    .cost-brakup-modal .cost-breakup-container .vf-pricing p {
      font-size: 14px;
      color: #000000d9;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .cost-brakup-modal .cost-breakup-container .vf-pricing span {
      color: #005cff;
      font-weight: 500; }
  .cost-brakup-modal .cost-breakup-container .total-ali-pricing {
    padding: 12px 20px;
    background: #fdfeff;
    width: 50%; }
    .cost-brakup-modal .cost-breakup-container .total-ali-pricing p {
      font-size: 14px;
      color: #000000d9;
      margin-bottom: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .cost-brakup-modal .cost-breakup-container .total-ali-pricing span {
      color: #cf230a;
      font-weight: 500; }
  .cost-brakup-modal .cost-breakup-container .total-vf-pricing {
    padding: 12px 20px;
    background: #f4f8ff;
    width: 50%; }
    .cost-brakup-modal .cost-breakup-container .total-vf-pricing p {
      font-size: 14px;
      color: #000000d9;
      margin-bottom: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .cost-brakup-modal .cost-breakup-container .total-vf-pricing span {
      color: #00cb00;
      font-weight: 500; }

.cost-brakup-modal .cost-brakup-footer {
  padding: 24px 24px 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .cost-brakup-modal .cost-brakup-footer .ant-btn {
    font-size: 16px;
    color: #707070;
    border-color: #707070;
    border-radius: 3px;
    height: 36px; }
  .cost-brakup-modal .cost-brakup-footer .ant-btn-primary {
    text-shadow: none;
    box-shadow: none;
    color: white;
    border: none;
    margin-right: 20px; }

.cost-brakup-modal .vf-buyback-price-container .vf-buyback-price {
  width: 48%; }
  .cost-brakup-modal .vf-buyback-price-container .vf-buyback-price .buyback-value {
    background: #f8f8f8;
    color: #005cff;
    padding: 10px; }

.cost-brakup-modal .italic-subheading {
  font-size: 12px;
  margin-bottom: 0px; }

.cost-brakup-modal hr {
  margin-top: 24px;
  margin-bottom: 24px; }

.usersettingnav .settingnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .usersettingnav .settingnav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .usersettingnav .settingnav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .usersettingnav .settingnav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .usersettingnav .settingnav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .usersettingnav .settingnav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .usersettingnav .settingnav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .usersettingnav .settingnav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .usersettingnav .settingnav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .usersettingnav .settingnav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .usersettingnav .settingnav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
    .usersettingnav .settingnav .ordfillwrap .breadcurmb-txt {
      color: #000;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 24px; }
      .usersettingnav .settingnav .ordfillwrap .breadcurmb-txt .breadcurmb-underline {
        text-decoration: underline; }
    .usersettingnav .settingnav .ordfillwrap .left {
      margin-left: 7px; }
  .usersettingnav .settingnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .usersettingnav .settingnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .usersettingnav .settingnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .usersettingnav .settingnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .usersettingnav .settingnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .usersettingnav .settingnav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .usersettingnav .settingnav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1161fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1161fb; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation {
        position: relative; }
        .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation .import-list-txt a {
          color: #898989; }
        .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation .store-product-txt a {
          color: #898989; }
        .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation .private-products-txt a {
          color: #898989; }
        .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation .wishlist-product-txt a {
          color: #898989; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:hover {
        color: #4398ed !important; }
        .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:hover a {
          color: #4398ed; }
        .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation-click {
        position: relative; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
  .usersettingnav .settingnav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .usersettingnav .settingnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .usersettingnav .settingnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .usersettingnav .settingnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .usersettingnav .settingnav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .usersettingnav .settingnav .ant-calendar-picker-clear,
  .usersettingnav .settingnav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.usersettingnav .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 76px;
  margin-bottom: 32px; }

.usersettingnav .ant-select-selection__placeholder {
  color: #1268fb; }

@media screen and (min-width: 1440px) and (max-width: 3200px) {
  .settingnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.my-products {
  margin-bottom: 30px; }
  .my-products .ant-card-body {
    padding: 32px;
    position: relative;
    overflow: hidden; }
  .my-products .ant-card.my-products-card.provisional.ant-card-bordered {
    box-shadow: 0px 3px 10px #0000000d; }
  .my-products .import-product-search .ant-input-group .ant-input {
    height: 40px;
    box-shadow: 0px 2px 8px #00000014;
    border-radius: 2px 0 0 2px;
    border: 0px;
    background: #f8f8f8; }
  .my-products .linkedStatusDropdown {
    background: #f8f8f8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !important;
    width: 288px; }
    .my-products .linkedStatusDropdown .ant-select-selection {
      width: 288px;
      border: none !important;
      background: #f8f8f8 !important; }
    .my-products .linkedStatusDropdown .ant-select-selection__rendered {
      margin-left: 16px;
      background-color: #f8f8f8 !important;
      margin-right: 2px;
      height: 100% !important;
      margin-top: 4px;
      font-size: 12px;
      font-weight: 500; }
      .my-products .linkedStatusDropdown .ant-select-selection__rendered .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb; }
  .my-products .product-status {
    display: flex;
    float: left;
    align-items: center; }
  .my-products .ProductLinkStatusDropdown {
    margin-bottom: 0;
    font-size: 12px;
    color: #898989;
    margin-right: 13px;
    margin: 11px 0px 0px 8px; }
  .my-products .Product_status .ant-select-selection.ant-select-selection--single {
    height: 25px !important;
    border: none !important;
    background: transparent;
    width: 100px !important;
    margin: 5px 0 0 -11px;
    box-shadow: none !important; }
  .my-products .Product_status .ant-select-selection-selected-value {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #1268fb; }

.Product_status .ant-select-selection__rendered {
  margin-left: -7px !important;
  color: #1268fb !important;
  font-size: 12px; }

.Product_status .ant-select-dropdown-menu {
  font-size: 12px !important; }

.ant-select-dropdown-menu-item:last-child {
  border-bottom: none !important; }

.commondropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 32px; }
  .commondropdown .anticon svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    padding-top: 2px; }

.commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 1px solid #b5dafe !important;
  color: #212121;
  font-weight: 500; }
  .commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) .cancel-status:hover {
    display: none; }

.not-linked-status {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 32px; }

.not-linked-status:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  color: #212121;
  font-weight: 500; }
  .not-linked-status:hover:not(.ant-select-dropdown-menu-item-disabled) .cancel-status:hover {
    display: none; }

@media screen and (min-width: 1440px) and (max-width: 3200px) {
  .filter-option .ant-select-arrow {
    top: 44% !important;
    left: 89% !important; } }

.wishlit-product-listing {
  background: #f0f2f5; }
  .wishlit-product-listing .f8 {
    font-size: 10px; }
  .wishlit-product-listing .f12 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em; }
  .wishlit-product-listing .f16 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032em; }
  .wishlit-product-listing .f20 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.032em; }
  .wishlit-product-listing .mr0 {
    margin-right: 0px; }
  .wishlit-product-listing .ml0 {
    margin-left: 0px; }
  .wishlit-product-listing .mt0 {
    margin-top: 0px; }
  .wishlit-product-listing .mb0 {
    margin-bottom: 0px; }
  .wishlit-product-listing .mb4 {
    margin-bottom: 2px; }
  .wishlit-product-listing .mr8 {
    margin-right: 8px; }
  .wishlit-product-listing .ml8 {
    margin-left: 8px; }
  .wishlit-product-listing .mt8 {
    margin-top: 8px; }
  .wishlit-product-listing .mb8 {
    margin-bottom: 8px; }
  .wishlit-product-listing .mr16 {
    margin-right: 16px; }
  .wishlit-product-listing .ml16 {
    margin-left: 16px; }
  .wishlit-product-listing .mt16 {
    margin-top: 10px; }
  .wishlit-product-listing .mb16 {
    margin-bottom: 0px; }
  .wishlit-product-listing .w100 {
    width: 100%; }
  .wishlit-product-listing .py8 {
    padding-top: 8px;
    padding-bottom: 8px; }
  .wishlit-product-listing .px8 {
    padding-left: 8px;
    padding-right: 8px; }
  .wishlit-product-listing .py2 {
    padding-top: 2px;
    padding-bottom: 2px; }
  .wishlit-product-listing .px2 {
    padding-left: 2px;
    padding-right: 2px; }
  .wishlit-product-listing .semibold {
    font-weight: 500; }
  .wishlit-product-listing .bold {
    font-weight: 500; }
    .wishlit-product-listing .bold .default_retail_price {
      font-size: 12px;
      margin-left: -40px;
      margin-right: 8px;
      font-style: italic;
      letter-spacing: 0.032em;
      text-decoration: line-through;
      -webkit-text-decoration-color: #898989;
              text-decoration-color: #898989; }
  .wishlit-product-listing .italic {
    font-style: italic;
    margin-top: 8px; }
  .wishlit-product-listing .underline {
    text-decoration: underline !important; }
  .wishlit-product-listing button {
    cursor: pointer; }
  .wishlit-product-listing .clamp2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .wishlit-product-listing .niche-item {
    font-size: 12px;
    color: #212121;
    text-transform: capitalize; }
  .wishlit-product-listing .wishlist-content {
    width: 100%;
    margin: auto;
    display: flex;
    padding-top: 24px; }
    .wishlit-product-listing .wishlist-content .content-sidebar {
      width: 248px;
      margin-right: 24px; }
      .wishlit-product-listing .wishlist-content .content-sidebar .filters {
        background: white;
        padding: 15px 16px 24px 16px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
        .wishlit-product-listing .wishlist-content .content-sidebar .filters .filter-tag {
          display: block;
          color: #acacac;
          background: #eeeeee;
          padding: 8px 16px;
          margin-bottom: 16px;
          letter-spacing: 0.025em; }
        .wishlit-product-listing .wishlist-content .content-sidebar .filters .filter-tag.active {
          color: #212121; }
        .wishlit-product-listing .wishlist-content .content-sidebar .filters .location-tag.active {
          background: #2bc822;
          color: white; }
    .wishlit-product-listing .wishlist-content .content {
      flex: 1 1; }
  .wishlit-product-listing .top-import-button {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    background: #2bc822;
    padding: 8px 16px;
    color: #fff;
    max-height: 40px;
    border-radius: 2px;
    border: none; }
  .wishlit-product-listing .product-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px; }
    .wishlit-product-listing .product-grid .product-card {
      background: white;
      margin-bottom: 24px;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
      position: relative;
      display: flex;
      height: auto;
      flex-direction: column; }
      .wishlit-product-listing .product-grid .product-card .potd-label {
        position: absolute;
        background: linear-gradient(150deg, #fd104d, #f0320f);
        padding: 4px 8px;
        color: white;
        top: 8px;
        z-index: 2; }
      .wishlit-product-listing .product-grid .product-card .product-watchlist-btn {
        position: absolute;
        top: 0;
        right: 16px;
        padding: 0;
        border: none;
        background: transparent; }
        .wishlit-product-listing .product-grid .product-card .product-watchlist-btn .anticon {
          position: relative;
          left: 16px; }
          .wishlit-product-listing .product-grid .product-card .product-watchlist-btn .anticon svg {
            width: 48px;
            height: 48px; }
      .wishlit-product-listing .product-grid .product-card .content {
        padding: 16px;
        padding-bottom: 0px;
        display: flex; }
        .wishlit-product-listing .product-grid .product-card .content .left {
          margin-right: 24px; }
        .wishlit-product-listing .product-grid .product-card .content .product-img {
          display: flex;
          flex-direction: column;
          position: relative;
          overflow: hidden;
          border-radius: 2px; }
          .wishlit-product-listing .product-grid .product-card .content .product-img img {
            width: 168px;
            height: 168px;
            object-fit: cover;
            border-radius: 2px;
            border: 2px solid #f5f5f5;
            transition: all 0.5s ease; }
          .wishlit-product-listing .product-grid .product-card .content .product-img .prev-img,
          .wishlit-product-listing .product-grid .product-card .content .product-img .next-img {
            transition: all 0.5s ease;
            position: absolute;
            width: 32px;
            background: rgba(33, 33, 33, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            opacity: 0;
            height: 168px; }
          .wishlit-product-listing .product-grid .product-card .content .product-img .prev-img {
            top: 0;
            left: 0; }
          .wishlit-product-listing .product-grid .product-card .content .product-img .next-img {
            top: 0;
            right: 0; }
        .wishlit-product-listing .product-grid .product-card .content .product-img:hover img {
          transition: all 0.5s ease;
          transform: scale(1.05); }
        .wishlit-product-listing .product-grid .product-card .content .product-img:hover .prev-img,
        .wishlit-product-listing .product-grid .product-card .content .product-img:hover .next-img {
          transition: all 0.5s ease;
          opacity: 1;
          transform: scale(1.05); }
        .wishlit-product-listing .product-grid .product-card .content .details {
          flex: 1 1; }
      .wishlit-product-listing .product-grid .product-card .footer {
        padding: 0;
        display: flex;
        text-align: center;
        justify-content: space-between; }
        .wishlit-product-listing .product-grid .product-card .footer .product_detail {
          background-color: #f5faff; }
        .wishlit-product-listing .product-grid .product-card .footer .view-product-details {
          border: none;
          box-shadow: none;
          color: #0068ff;
          font-weight: 700;
          background-color: #f5faff;
          border-radius: 0;
          font-weight: 600;
          font-size: 12px;
          height: 48px; }
      .wishlit-product-listing .product-grid .product-card .dropdown_link {
        width: 20%; }
      .wishlit-product-listing .product-grid .product-card .ant-dropdown-link {
        width: 100%;
        background: #36c626;
        border: 0;
        font-weight: 700;
        border-radius: 0;
        color: #fff;
        font-size: 12px;
        height: 48px; }
  .wishlit-product-listing .request_lattest_ican {
    display: flex;
    justify-content: space-between;
    margin-bottom: -19px; }
  .wishlit-product-listing .request_lattest_ican2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px; }
  .wishlit-product-listing .sv:hover {
    fill: #0068ff; }
  .wishlit-product-listing .anticon:hover svg #Ellipse_44 {
    stroke: #1268fb;
    fill: #1268fb; }
  .wishlit-product-listing .anticon:hover svg #Ellipse_47 {
    stroke: #fff;
    fill: #ffff; }
  .wishlit-product-listing .anticon:hover svg #Rectangle_2462 {
    stroke: #fff;
    fill: #ffff; }
  .wishlit-product-listing .product-tooltipican svg {
    width: 16px;
    height: 16px; }
  .wishlit-product-listing .prev-img svg {
    width: 18px;
    height: 41px; }
  .wishlit-product-listing .next-img svg {
    width: 18px;
    height: 41px; }
  .wishlit-product-listing .cost-update-date {
    color: #212121; }
  .wishlit-product-listing .req-latest-price svg {
    margin-bottom: 2px !important; }
  .wishlit-product-listing .nodata-card {
    margin-bottom: 20px;
    text-align: center; }
    .wishlit-product-listing .nodata-card .no-product-found {
      margin: 0; }
  .wishlit-product-listing .product-stock-count {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    line-height: 16px; }
  .wishlit-product-listing .stock-processing-time {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    line-height: 16px; }

.wishlist_ali-link-menu {
  padding: 10px !important; }
  .wishlist_ali-link-menu li a {
    padding: 2px 0;
    line-height: 22px;
    font-size: 12px;
    color: #ff005e; }

@media screen and (min-width: 1360px) {
  .wishlit-product-listing .wishlist-content {
    width: 100%;
    margin: auto; }
  .dropdown_link {
    width: 21%; } }

.sv2 {
  pointer-events: none; }
  .sv2 svg {
    fill: #b2b2b2; }
  .sv2 svg:hover {
    fill: #b2b2b2; }

#test {
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1s; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

.wishlist-product-nav .wishlist-nav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .wishlist-product-nav .wishlist-nav .wishlist-product-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .wishlist-product-nav .wishlist-nav .wishlist-product-wrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .wishlist-product-nav .wishlist-nav .wishlist-product-wrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .wishlist-product-nav .wishlist-nav .wishlist-product-wrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .wishlist-product-nav .wishlist-nav .wishlist-product-wrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .wishlist-product-nav .wishlist-nav .wishlist-product-wrap .right .filter svg {
        font-size: 20px; }
      .wishlist-product-nav .wishlist-nav .wishlist-product-wrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .wishlist-product-nav .wishlist-nav .wishlist-product-wrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .wishlist-product-nav .wishlist-nav .wishlist-product-wrap .right .show_data_from {
        font-size: 12px; }
      .wishlist-product-nav .wishlist-nav .wishlist-product-wrap .right .dispute-status {
        font-size: 12px; }
    .wishlist-product-nav .wishlist-nav .wishlist-product-wrap .left {
      margin-left: 7px; }
  .wishlist-product-nav .wishlist-nav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .wishlist-product-nav .wishlist-nav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1161fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1161fb; }
      .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation {
        position: relative; }
        .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation .import-list-txt a {
          color: #898989; }
        .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation .store-products-txt a {
          color: #898989; }
        .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation .private-products-txt a {
          color: #898989; }
        .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation .wishlist-product-txt a {
          color: #898989; }
      .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation:hover {
        color: #4398ed !important; }
        .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation:hover a {
          color: #4398ed; }
        .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation-click {
        position: relative; }
      .wishlist-product-nav .wishlist-nav .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
  .wishlist-product-nav .wishlist-nav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .wishlist-product-nav .wishlist-nav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .wishlist-product-nav .wishlist-nav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .wishlist-product-nav .wishlist-nav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .wishlist-product-nav .wishlist-nav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .wishlist-product-nav .wishlist-nav .ant-calendar-picker-clear,
  .wishlist-product-nav .wishlist-nav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }
  .wishlist-product-nav .wishlist-nav .breadcurmb-txt {
    color: #000;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px; }
    .wishlist-product-nav .wishlist-nav .breadcurmb-txt .breadcurmb-underline {
      text-decoration: underline; }

.wishlist-product-nav .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 76px;
  margin-bottom: -32px; }

.wishlist-product-nav .ant-select-selection__placeholder {
  color: #1268fb; }

@media screen and (min-width: 1440px) {
  .wishlist-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6% !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1442px) and (max-width: 3200px) {
  .wishlist-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.pr-viewvf {
  padding: 0;
  display: flex; }
  .pr-viewvf a,
  .pr-viewvf button {
    flex: 1 1;
    text-align: center;
    padding: 16px 0;
    font-weight: 500; }
  .pr-viewvf a {
    background: #f5faff;
    color: #0068ff; }
  .pr-viewvf button {
    background: #36c626;
    color: white;
    border: none; }
  .pr-viewvf .add-to-import-list-button {
    transition-duration: 0.5s;
    flex: unset;
    padding: 0;
    width: 48px;
    height: 48px; }
    .pr-viewvf .add-to-import-list-button .text {
      display: none;
      overflow: hidden;
      text-overflow: hidden;
      font-size: 12px;
      white-space: nowrap; }
    .pr-viewvf .add-to-import-list-button .icon {
      display: flex;
      align-items: center;
      justify-content: center; }
  .pr-viewvf .add-to-import-list-button:hover {
    width: 180px;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .pr-viewvf .add-to-import-list-button:hover .text {
      display: block; }
    .pr-viewvf .add-to-import-list-button:hover .icon {
      padding-left: 24px;
      padding-right: 8px; }
  .pr-viewvf .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca; }
  .pr-viewvf .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 28.9%;
    transform: scaleX(0);
    height: 1px;
    left: -3px;
    top: 32px;
    margin-left: 33.2%;
    background-color: #2986ff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .pr-viewvf .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: top left; }
  .pr-viewvf .ml8:nth-of-type(1) {
    transition: all linear 0.4s;
    transform: translate(0px); }
  .pr-viewvf .heartblank-ican {
    width: 48px;
    height: 48px; }
    .pr-viewvf .heartblank-ican .heartblank-ican-btn {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 55px;
      width: 24px; }
      .pr-viewvf .heartblank-ican .heartblank-ican-btn .anticon {
        margin-left: 24px; }
      .pr-viewvf .heartblank-ican .heartblank-ican-btn .heartblank.anticon svg {
        height: 48px;
        width: 24px;
        margin-top: 6px; }
      .pr-viewvf .heartblank-ican .heartblank-ican-btn .heartfill.anticon svg {
        height: 48px;
        width: 48px;
        margin-top: 6px; }
    .pr-viewvf .heartblank-ican .wishlisttabheart {
      height: 42px; }
  .pr-viewvf .heartfill-ican {
    width: 48px;
    height: 48px; }
    .pr-viewvf .heartfill-ican .heartfill-ican-btn {
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: 24px; }
      .pr-viewvf .heartfill-ican .heartfill-ican-btn .anticon {
        margin-left: 24px; }
      .pr-viewvf .heartfill-ican .heartfill-ican-btn .heartfill.anticon svg {
        height: 48px;
        width: 48px;
        margin-top: 6px; }

.pr-viewvf:hover .ml8:nth-of-type(1) {
  transition: all linear 0.4s;
  transform: translate(4px); }

.custom-notification {
  width: 307px;
  padding: 10px 8px;
  padding-right: 30px; }
  .custom-notification .ant-notification-notice-message {
    display: none; }
  .custom-notification .ant-notification-notice-close {
    right: 8px;
    top: 8px; }
    .custom-notification .ant-notification-notice-close svg path {
      fill: #2a9c27; }
  .custom-notification .notification-wraper {
    display: flex; }
    .custom-notification .notification-wraper .notification-icon-svg {
      display: flex;
      align-items: center;
      margin-right: 16px; }
      .custom-notification .notification-wraper .notification-icon-svg svg {
        height: 48px;
        width: 48px; }
    .custom-notification .notification-wraper .content h5 {
      display: flex;
      align-items: center;
      margin: 0; }
      .custom-notification .notification-wraper .content h5 h2 {
        text-decoration: underline;
        font-weight: 600;
        font-size: 12px;
        margin: 0;
        margin-bottom: 3px;
        color: #212121;
        letter-spacing: 0.032em; }
      .custom-notification .notification-wraper .content h5 span {
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
        margin: 0;
        margin-bottom: 3px;
        color: #212121;
        letter-spacing: 0.032em; }
    .custom-notification .notification-wraper .content p {
      font-size: 12px;
      color: #383838;
      letter-spacing: 0.032em; }
    .custom-notification .notification-wraper .content h3 {
      height: 22px; }
    .custom-notification .notification-wraper .content h4 {
      margin: 0;
      padding: 0;
      margin-top: 5px;
      position: absolute;
      right: 8px;
      bottom: 10px;
      font-weight: 400;
      font-size: 12px;
      color: #383838;
      font-style: italic;
      display: inline-block; }

.custom-notification-hold .ant-notification-notice-close {
  right: 8px;
  top: 8px; }
  .custom-notification-hold .ant-notification-notice-close svg path {
    fill: #f3b62d; }

.custom-notification-hold .notification-wraper .notification-icon-svg svg {
  height: 48px;
  width: 48px; }
  .custom-notification-hold .notification-wraper .notification-icon-svg svg g g {
    stroke: #f3b62d; }
  .custom-notification-hold .notification-wraper .notification-icon-svg svg g path {
    stroke: #f3b62d; }

.custom-notification-cancel .ant-notification-notice-close {
  right: 8px;
  top: 8px; }
  .custom-notification-cancel .ant-notification-notice-close svg path {
    fill: #c70c3c; }

.custom-notification-cancel .notification-wraper .notification-icon-svg svg {
  height: 48px;
  width: 48px; }
  .custom-notification-cancel .notification-wraper .notification-icon-svg svg g g {
    stroke: #c70c3c; }
  .custom-notification-cancel .notification-wraper .notification-icon-svg svg g path {
    stroke: #c70c3c; }

.escalate {
  width: 303px;
  padding: 10px 8px;
  padding-right: 30px; }

.import-ali {
  width: 315px; }

.variant-nav-data .variant-nav {
  padding-left: 59px;
  padding-top: 58px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.variant-nav-data .ordfillwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }

.variant-nav-data .variant-mapping-txt {
  text-decoration: underline;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #000; }

.variant-nav-data .arrow-txt {
  margin: 0 4px; }

@media screen and (min-width: 1440px) {
  .variant-nav {
    padding-left: 104px !important;
    padding-top: 58px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .variant-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.right-varaint-image-data {
  width: 50%; }

.allspec-data {
  display: flex; }

.allspec-dropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 24px; }

.allspec-dropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 2px solid #b5dafe !important;
  border-radius: 0px !important; }

.option-varaint.allspecs-product-name {
  margin-bottom: -5px; }

.variant-matching .ant-select-selection {
  min-height: 64px;
  border: none;
  background: #f5f5f5;
  border-radius: 2px; }
  .variant-matching .ant-select-selection .ant-select-selection__rendered {
    min-height: 48px;
    padding-top: 6px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    color: #212121; }
  .variant-matching .ant-select-selection .ant-select-search--inline {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: -2px; }

.variant-matching .ali-product-name {
  font-weight: 400;
  margin-top: 72px;
  margin-bottom: 24px;
  color: #898989;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  background: #f5faff;
  padding: 8px;
  border-radius: 4px; }

.variant-matching .header-txt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px; }
  .variant-matching .header-txt p {
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 400; }

.variant-matching .varaint-matching-data {
  display: flex;
  justify-self: space-between;
  width: 100%; }
  .variant-matching .varaint-matching-data .left-varaint-image-data {
    width: 50%;
    margin-right: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 16px; }
    .variant-matching .varaint-matching-data .left-varaint-image-data img {
      margin-left: 16px; }

.variant-matching .ant-select-selection__placeholder {
  top: 72%;
  font-size: 12px;
  color: #898989;
  letter-spacing: 0.032em;
  line-height: 16px; }

.variant-matching .save-continue {
  background: #2bc822;
  border: none;
  border-radius: 2px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 500; }

.variant-matching .link-product-name {
  color: #0068ff;
  letter-spacing: 0.032em;
  font-weight: 400; }

.variant-nav-data .variant-nav {
  padding-left: 59px;
  padding-top: 58px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.variant-nav-data .ordfillwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }

.variant-nav-data .variant-mapping-txt {
  text-decoration: underline;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #000; }

.variant-nav-data .arrow-txt {
  margin: 0 4px; }

@media screen and (min-width: 1440px) {
  .variant-nav {
    padding-left: 104px !important;
    padding-top: 58px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .variant-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.variant-link-view-right-varaint-image-data {
  width: 50%;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 2px; }
  .variant-link-view-right-varaint-image-data .image-allspec-data {
    display: flex;
    align-items: center;
    padding: 8px 16px; }
    .variant-link-view-right-varaint-image-data .image-allspec-data img {
      height: 48px;
      width: 48px;
      margin-right: 16px;
      border: 1px solid #979797;
      padding: 2px; }
  .variant-link-view-right-varaint-image-data .variant-link-view-allspec-dropdown {
    letter-spacing: 0.032em;
    background: #f5f5f5;
    margin-bottom: 8px; }
  .variant-link-view-right-varaint-image-data .option-varaint.allspecs-product-name {
    margin-bottom: -5px; }
  .variant-link-view-right-varaint-image-data .varaint-name-spac {
    font-size: 12px;
    color: #1161fb;
    line-height: 16px; }
  .variant-link-view-right-varaint-image-data .allspecs-product-name-id {
    color: #898989; }
    .variant-link-view-right-varaint-image-data .allspecs-product-name-id .vfsku-id {
      color: #000; }
  .variant-link-view-right-varaint-image-data .image-allspec-data-notlinlk {
    display: flex;
    margin-top: 16px;
    align-items: center;
    background: #fff7f5;
    padding: 8px;
    border: 1px solid #fd4726; }
    .variant-link-view-right-varaint-image-data .image-allspec-data-notlinlk .redalert-ican {
      margin-right: 8px;
      font-size: 24px; }
    .variant-link-view-right-varaint-image-data .image-allspec-data-notlinlk .notlink-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121; }
    .variant-link-view-right-varaint-image-data .image-allspec-data-notlinlk .notlink-allspecs-product-name {
      color: #212121;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .variant-link-view-right-varaint-image-data .image-allspec-data-notlinlk .notlink-allspecs-product-name .sku-txt {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989; }
      .variant-link-view-right-varaint-image-data .image-allspec-data-notlinlk .notlink-allspecs-product-name .vfsku-id {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
  .variant-link-view-right-varaint-image-data .image-allspec-bundle-notlinlk {
    display: flex;
    margin-top: 16px;
    align-items: center;
    background: #fff7f5;
    padding: 8px;
    border: 1px solid #fd4726;
    margin-top: -3px; }
    .variant-link-view-right-varaint-image-data .image-allspec-bundle-notlinlk .redalert-ican {
      margin-right: 8px;
      font-size: 24px; }
    .variant-link-view-right-varaint-image-data .image-allspec-bundle-notlinlk .notlink-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121; }
    .variant-link-view-right-varaint-image-data .image-allspec-bundle-notlinlk .notlink-allspecs-product-name {
      color: #212121;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .variant-link-view-right-varaint-image-data .image-allspec-bundle-notlinlk .notlink-allspecs-product-name .sku-txt {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989; }
      .variant-link-view-right-varaint-image-data .image-allspec-bundle-notlinlk .notlink-allspecs-product-name .vfsku-id {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }

.link-product-import-list-modal {
  width: 800px !important;
  max-width: 100%; }
  .link-product-import-list-modal .ant-select-selection--single {
    font-weight: 500;
    border-radius: 0px;
    background-color: #f5f5f5;
    border: none;
    padding: 0 8px;
    font-size: 12px; }
  .link-product-import-list-modal .ant-select-lg .ant-select-selection--single {
    height: 56px; }
  .link-product-import-list-modal .ant-select-lg .ant-select-selection__rendered {
    line-height: 56px; }
  .link-product-import-list-modal .checkbox-container {
    margin-top: 3rem; }
    .link-product-import-list-modal .checkbox-container .ant-checkbox-wrapper {
      font-weight: 500; }
  .link-product-import-list-modal .sub-text {
    font-weight: 400;
    font-size: 0.85rem; }
  .link-product-import-list-modal .next-button {
    background-color: #2bc822;
    border-color: #2bc822;
    outline: none;
    border: none;
    width: 161px;
    border-radius: 2px;
    color: #fff;
    height: 40px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500; }
  .link-product-import-list-modal .cancle-button {
    width: 161px;
    border-radius: 2px;
    height: 40px;
    color: #898989 !important;
    border-color: #898989 !important; }
    .link-product-import-list-modal .cancle-button:hover {
      color: #898989 !important;
      border-color: #898989 !important; }
  .link-product-import-list-modal .input-label {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 400;
    margin-bottom: 4px;
    color: #898989; }
  .link-product-import-list-modal .existing_product_txt {
    font-size: 12px;
    color: #212121;
    margin-bottom: 8px;
    margin-top: -8px; }
  .link-product-import-list-modal .ant-btn-primary-disabled,
  .link-product-import-list-modal .ant-btn-primary.disabled,
  .link-product-import-list-modal .ant-btn-primary[disabled],
  .link-product-import-list-modal .ant-btn-primary-disabled:hover,
  .link-product-import-list-modal .ant-btn-primary.disabled:hover,
  .link-product-import-list-modal .ant-btn-primary[disabled]:hover,
  .link-product-import-list-modal .ant-btn-primary-disabled:focus,
  .link-product-import-list-modal .ant-btn-primary.disabled:focus,
  .link-product-import-list-modal .ant-btn-primary[disabled]:focus,
  .link-product-import-list-modal .ant-btn-primary-disabled:active,
  .link-product-import-list-modal .ant-btn-primary.disabled:active,
  .link-product-import-list-modal .ant-btn-primary[disabled]:active,
  .link-product-import-list-modal .ant-btn-primary-disabled.active,
  .link-product-import-list-modal .ant-btn-primary.disabled.active,
  .link-product-import-list-modal .ant-btn-primary[disabled].active {
    color: #fff;
    background-color: #8bd987;
    border-color: #8bd987;
    text-shadow: none;
    box-shadow: none;
    border-radius: 2px;
    width: 161px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500;
    height: 40px; }
  .link-product-import-list-modal .link-import-list {
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 500;
    color: #212121; }
  .link-product-import-list-modal .existing-product-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 400;
    color: #212121;
    margin-bottom: -8px; }
  .link-product-import-list-modal .ant-modal-body {
    padding: 16px 24px;
    font-size: 12px;
    line-height: 1.5;
    word-wrap: break-word; }
  .link-product-import-list-modal .ant-modal-close-x {
    display: none; }
  .link-product-import-list-modal .link-import-list-modal {
    display: flex;
    justify-content: space-between;
    width: 100%; }
    .link-product-import-list-modal .link-import-list-modal .existing-product {
      width: 50%;
      margin-right: 16px; }
    .link-product-import-list-modal .link-import-list-modal .link-product {
      width: 50%; }
  .link-product-import-list-modal .ant-modal-footer {
    padding: 16px 24px; }
  .link-product-import-list-modal .image-with-txt {
    background: #f5f5f5;
    padding: 8px 8px;
    display: inline-block;
    width: 100%; }
    .link-product-import-list-modal .image-with-txt img {
      vertical-align: middle;
      border: 1px solid #898989;
      padding: 4px;
      width: 40px;
      height: 40px; }
    .link-product-import-list-modal .image-with-txt .image-product-name {
      margin: 0 0 0 20px;
      vertical-align: middle;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: calc(100% - 50px);
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-left: 8px; }
  .link-product-import-list-modal .option-image {
    vertical-align: middle;
    border: 1px solid #898989;
    padding: 4px;
    width: 40px;
    height: 40px; }

.link-product-bundle-modal .ant-modal-body .ant-select-lg .ant-select-selection--multiple {
  min-height: 47px;
  border: none;
  background: #f5f5f5;
  border-radius: unset; }

.link-product-bundle-modal .ant-modal-body .ant-select-selection--multiple .ant-select-selection__placeholder {
  margin-left: 6px;
  font-size: 12px;
  line-height: 35px;
  height: 47px; }

.link-product-bundle-modal .ant-modal-body .ant-radio-group {
  display: block; }
  .link-product-bundle-modal .ant-modal-body .ant-radio-group .ant-radio-wrapper {
    border: 1.5px solid #979797;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    display: block;
    margin: 1rem auto; }
    .link-product-bundle-modal .ant-modal-body .ant-radio-group .ant-radio-wrapper .label-text {
      font-weight: 700; }

.link-product-bundle-modal .ant-modal-body p.input-label {
  margin-bottom: 0;
  font-weight: 600; }

.link-product-bundle-modal .ant-modal-body input:first-of-type {
  margin-bottom: 15px; }

.link-product-bundle-modal .ant-modal-body .bulk-import .icon-container {
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #979797;
  border-radius: 4px;
  color: #979797;
  text-align: center;
  cursor: pointer; }
  .link-product-bundle-modal .ant-modal-body .bulk-import .icon-container i svg {
    width: 5rem;
    height: 5rem; }

.link-product-bundle-modal .ant-modal-body .bulk-import .icon-container:hover {
  border-color: #5e73ff;
  color: #5e73ff; }

.link-product-bundle-modal .next-button {
  background-color: #2bc822;
  outline: none;
  border: none;
  width: 161px;
  border-radius: 2px;
  height: 40px; }

.link-product-bundle-modal .cancle-button {
  width: 161px;
  border-radius: 2px;
  height: 40px;
  color: #898989 !important;
  border-color: #898989 !important; }
  .link-product-bundle-modal .cancle-button:hover {
    color: #898989 !important;
    border-color: #898989 !important; }

.link-product-bundle-modal .ant-btn-primary-disabled,
.link-product-bundle-modal .ant-btn-primary.disabled,
.link-product-bundle-modal .ant-btn-primary[disabled],
.link-product-bundle-modal .ant-btn-primary-disabled:hover,
.link-product-bundle-modal .ant-btn-primary.disabled:hover,
.link-product-bundle-modal .ant-btn-primary[disabled]:hover,
.link-product-bundle-modal .ant-btn-primary-disabled:focus,
.link-product-bundle-modal .ant-btn-primary.disabled:focus,
.link-product-bundle-modal .ant-btn-primary[disabled]:focus,
.link-product-bundle-modal .ant-btn-primary-disabled:active,
.link-product-bundle-modal .ant-btn-primary.disabled:active,
.link-product-bundle-modal .ant-btn-primary[disabled]:active,
.link-product-bundle-modal .ant-btn-primary-disabled.active,
.link-product-bundle-modal .ant-btn-primary.disabled.active,
.link-product-bundle-modal .ant-btn-primary[disabled].active {
  color: #fff;
  background-color: #8bd987;
  border-color: #8bd987;
  text-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  width: 161px; }

.link-product-bundle-modal .ant-select-selection__rendered {
  line-height: 29px !important; }
  .link-product-bundle-modal .ant-select-selection__rendered li {
    height: 38px !important;
    line-height: 32px !important;
    margin-top: 5px !important;
    width: 381px !important; }

.link-product-bundle-modal .input-label {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 400 !important;
  margin-bottom: 4px !important; }

.product-link-variant-matching-view .ant-select-selection {
  min-height: 64px;
  border: none;
  background: #f5f5f5;
  border-radius: 2px; }
  .product-link-variant-matching-view .ant-select-selection .ant-select-selection__rendered {
    min-height: 48px;
    padding-top: 6px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    color: #212121; }
  .product-link-variant-matching-view .ant-select-selection .ant-select-search--inline {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: -2px; }

.product-link-variant-matching-view .flexible-fullfillment-bar {
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  padding: 3px 7px;
  margin-bottom: 24px;
  color: #898989;
  font-size: 12px;
  line-height: 16px;
  background: #f5faff;
  padding: 8px;
  border-radius: 4px; }

.product-link-variant-matching-view .ali-product-name {
  font-weight: 400;
  margin-top: 72px;
  margin-bottom: 24px;
  color: #898989;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  background: #f5faff;
  padding: 8px;
  border-radius: 4px; }

.product-link-variant-matching-view .header-txt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px; }
  .product-link-variant-matching-view .header-txt p {
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 400; }

.product-link-variant-matching-view .view-varaint-matching-data {
  display: flex;
  justify-self: space-between;
  width: 100%; }
  .product-link-variant-matching-view .view-varaint-matching-data .left-varaint-image-data {
    width: 50%;
    margin-right: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 2px;
    background: #f5f5f5;
    height: 64px; }
    .product-link-variant-matching-view .view-varaint-matching-data .left-varaint-image-data img {
      margin-left: 16px;
      max-width: 48px;
      padding: 2px;
      border: 1px solid #979797; }
    .product-link-variant-matching-view .view-varaint-matching-data .left-varaint-image-data .image-allspecs {
      margin-left: 16px;
      font-size: 12px;
      letter-spacing: 0.032em;
      color: #212121;
      line-height: 16px; }

.product-link-variant-matching-view .ant-select-selection__placeholder {
  top: 72%;
  font-size: 12px;
  color: #898989;
  letter-spacing: 0.032em;
  line-height: 16px; }

.product-link-variant-matching-view .save-continue {
  background: #2bc822;
  border: none;
  border-radius: 2px;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 500; }

.product-link-variant-matching-view .back-store-product-link {
  margin-top: 16px;
  display: flex;
  justify-content: end; }
  .product-link-variant-matching-view .back-store-product-link .backBtn {
    margin-right: 8px; }

.product-link-variant-matching-view .back-store-product-link a {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #898989 !important; }

.product-link-variant-matching-view .back-store-product-link a:hover {
  font-size: 12px;
  color: #1370fc !important;
  font-weight: 500;
  text-decoration: underline !important; }

.product-link-variant-matching-view .back-store-product-link a {
  display: flex;
  align-items: center; }
  .product-link-variant-matching-view .back-store-product-link a svg {
    transform: rotate(91deg) !important;
    margin-right: 3px; }

.product-link-variant-matching-view .link-product-name {
  color: #0068ff;
  letter-spacing: 0.032em;
  font-weight: 400; }

.product-link-variant-matching-view .cantainer-flexible-data {
  margin-top: 20px;
  padding: 24px;
  background-color: #fff; }
  .product-link-variant-matching-view .cantainer-flexible-data .heading-flexble-fullfillment {
    display: flex; }
    .product-link-variant-matching-view .cantainer-flexible-data .heading-flexble-fullfillment .item {
      flex-basis: 50%;
      font-size: 12px;
      padding: 7px; }

.fullfillment-card {
  display: flex;
  padding: 2px 5px; }
  .fullfillment-card .left-varaint-image-data {
    flex-basis: 50%;
    background-color: #f5f5f5;
    padding: 8px;
    padding-left: 16px; }
    .fullfillment-card .left-varaint-image-data img {
      height: 48px;
      width: 48px;
      border: 1px solid #979797;
      padding: 2px; }
    .fullfillment-card .left-varaint-image-data .image-name {
      margin-left: 16px;
      font-size: 12px;
      letter-spacing: 0.032em;
      color: #212121;
      line-height: 16px; }
  .fullfillment-card .switch-cointainer {
    background-color: #f5f5f5;
    padding-right: 64px;
    padding-left: 68px; }
    .fullfillment-card .switch-cointainer .switchFullfillment {
      margin-top: 18px; }

.orders-send-msg-to-customer-modal .input-label {
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  color: #000; }

.orders-send-msg-to-customer-modal .no-top-margin {
  margin-top: 0rem; }

.orders-send-msg-to-customer-modal input,
.orders-send-msg-to-customer-modal textarea {
  border: 1.5px solid #979797;
  border-radius: 4px; }

.orders-send-msg-to-customer-modal .drop {
  display: none; }

.orders-add-note-modal .ant-modal-content {
  border-radius: 2px !important; }

.orders-add-note-modal .input-label {
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  color: #000; }

.orders-add-note-modal .no-top-margin {
  margin-top: 0rem; }

.orders-add-note-modal input,
.orders-add-note-modal textarea {
  border: 1.5px solid #979797;
  border-radius: 4px;
  padding-top: 8px !important; }

.orders-add-note-modal .ant-modal-close {
  display: none; }

.orders-add-note-modal .ant-input {
  background-image: none !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: #f5f5f5;
  color: #212121; }

.orders-add-note-modal .ant-input::placeholder {
  font-size: 12px;
  font-weight: 400px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #898989; }

.orders-add-note-modal .ant-modal-body {
  padding: 16px 24px;
  font-size: 12px;
  line-height: 1.5;
  word-wrap: break-word; }

.orders-add-note-modal .ant-modal-header {
  padding: 16px 0px;
  border-bottom: 1px solid #cecece;
  display: flex;
  margin: 0 auto;
  width: 512px; }

.orders-add-note-modal .ant-modal-footer {
  padding: 16px 0px;
  text-align: right;
  border-top: 1px solid #cecece;
  width: 512px;
  margin: 0 auto; }

.orders-add-note-modal .ant-modal-content {
  border-radius: 0px; }

.orders-add-note-modal .add-note-btn {
  background: #2bc822;
  border: none;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px; }

.orders-add-note-modal .add-note-cancel {
  border: 1px solid #898989;
  width: 88px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px;
  color: #898989; }

.orders-add-note-modal .ant-modal-title {
  color: #212121;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 16px; }

.orders-add-note-modal .trunRed .ant-select-selection {
  border: 1px solid red; }

.orders-add-note-modal .select-type {
  width: 160px;
  position: relative;
  left: -113px;
  top: -3px; }
  .orders-add-note-modal .select-type .ant-select-selection {
    height: 40px;
    border-radius: 2px; }
  .orders-add-note-modal .select-type .ant-select-selection__rendered {
    height: 40px; }

.orders-add-note-modal .ant-select-selection-selected-value {
  margin-top: 4px; }

.orders-add-note-modal .off-btn {
  opacity: 0.5; }

.orders-confirmation-modal {
  width: 1148px !important; }
  .orders-confirmation-modal .fulfilment {
    background-color: #fef7c8 !important; }
  .orders-confirmation-modal .itemCost {
    background-color: #fdf3e4 !important; }
  .orders-confirmation-modal .both {
    background-color: #cee6f8 !important; }
  .orders-confirmation-modal .custom-width-120 {
    width: 120px !important;
    display: flex;
    align-items: center;
    justify-content: center; }
  .orders-confirmation-modal .ant-modal-content {
    border-radius: 2px;
    padding: 0 24px; }
  .orders-confirmation-modal .ant-modal-header {
    padding: 24px 0px;
    border-bottom: 1px solid #cecece;
    display: flex;
    margin: 0 auto;
    width: 1100px; }
    .orders-confirmation-modal .ant-modal-header .ant-modal-title {
      font-size: 16px;
      line-height: 32px;
      letter-spacing: 0.032em;
      width: 100%; }
      .orders-confirmation-modal .ant-modal-header .ant-modal-title .flex-between {
        display: flex;
        align-items: flex-start;
        justify-content: space-between; }
        .orders-confirmation-modal .ant-modal-header .ant-modal-title .flex-between .colors-grad {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-size: 12px;
          width: 460px !important; }
          .orders-confirmation-modal .ant-modal-header .ant-modal-title .flex-between .colors-grad .r {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-right: 12px; }
            .orders-confirmation-modal .ant-modal-header .ant-modal-title .flex-between .colors-grad .r span {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #fef7c8;
              margin-right: 6px; }
          .orders-confirmation-modal .ant-modal-header .ant-modal-title .flex-between .colors-grad .g {
            display: flex;
            align-items: center;
            justify-content: start;
            margin-right: 12px;
            opacity: 0.7; }
            .orders-confirmation-modal .ant-modal-header .ant-modal-title .flex-between .colors-grad .g span {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: #fdf3e4;
              margin-right: 6px; }
          .orders-confirmation-modal .ant-modal-header .ant-modal-title .flex-between .colors-grad .b {
            display: flex;
            align-items: center;
            justify-content: start; }
            .orders-confirmation-modal .ant-modal-header .ant-modal-title .flex-between .colors-grad .b span {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-right: 6px;
              background-color: #cee6f8; }
    .orders-confirmation-modal .ant-modal-header .ant-modal-content {
      border-radius: 0px; }
  .orders-confirmation-modal .ant-modal-footer {
    padding: 8px 0px;
    text-align: right;
    border-top: 1px solid #cecece;
    width: 1100px;
    margin: 0 auto;
    margin-top: 10px; }
    .orders-confirmation-modal .ant-modal-footer .footer-line-item {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .orders-confirmation-modal .ant-modal-footer .footer-line-item .ant-input {
        border: none;
        border-radius: 2px;
        font-size: 12px;
        width: 220px;
        background: #f5f5f5; }
      .orders-confirmation-modal .ant-modal-footer .footer-line-item .snooze-until {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.032em;
        color: #898989; }
  .orders-confirmation-modal .ant-modal-close-x {
    display: none; }
  .orders-confirmation-modal .ant-modal-body {
    padding: 0;
    max-height: 560px;
    overflow: auto; }
  .orders-confirmation-modal .subheading-txt {
    padding: 8px 0px;
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: -24px;
    margin-top: -8px;
    font-weight: 400; }
  .orders-confirmation-modal .selected-orders .header-row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0px 8px 16px;
    align-items: center;
    width: 1100px !important;
    margin: 8px auto;
    margin-bottom: -4px; }
    .orders-confirmation-modal .selected-orders .header-row .order-number-txt {
      width: 140px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989; }
    .orders-confirmation-modal .selected-orders .header-row .order-items-txt {
      width: 200px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989; }
    .orders-confirmation-modal .selected-orders .header-row .product-price-txt {
      width: 90px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      text-align: center; }
    .orders-confirmation-modal .selected-orders .header-row .fulfillment-price-txt {
      width: 100px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      text-align: center; }
    .orders-confirmation-modal .selected-orders .header-row .forward-shipping-txt {
      width: 115px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      text-align: center; }
    .orders-confirmation-modal .selected-orders .header-row .order-cost-txt {
      width: 110px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      text-align: center; }
    .orders-confirmation-modal .selected-orders .header-row .quantity-txt {
      width: 80px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      text-align: center; }
    .orders-confirmation-modal .selected-orders .header-row .cod-txt {
      width: 90px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      text-align: center; }
  .orders-confirmation-modal .selected-orders .line-item {
    display: flex;
    justify-content: space-between;
    padding: 6px 0px 6px 16px;
    background: #f5f5f5;
    align-items: center;
    width: 1100px !important;
    margin: 0 auto;
    margin-bottom: 8px;
    position: relative; }
    .orders-confirmation-modal .selected-orders .line-item .rto-badge {
      top: 0px;
      left: 0px;
      margin-top: 6px;
      color: #fff;
      padding: 6px 18px;
      font-size: 12px;
      font-weight: 500 !important;
      border-radius: 5px; }
    .orders-confirmation-modal .selected-orders .line-item .rto-badge.high {
      background-color: #e61047; }
    .orders-confirmation-modal .selected-orders .line-item .rto-badge.no {
      background-color: #73d13d; }
    .orders-confirmation-modal .selected-orders .line-item .rto-badge.moderate {
      background-color: #ffc53d;
      color: #212121; }
    .orders-confirmation-modal .selected-orders .line-item .orderid-txt {
      width: 155px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      display: flex;
      flex-direction: column;
      align-items: baseline; }
      .orders-confirmation-modal .selected-orders .line-item .orderid-txt .order-id {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
        .orders-confirmation-modal .selected-orders .line-item .orderid-txt .order-id a {
          color: #1161fb; }
      .orders-confirmation-modal .selected-orders .line-item .orderid-txt .vFulfill-txt {
        margin-bottom: 2px; }
    .orders-confirmation-modal .selected-orders .line-item .line-item-img {
      width: 48px;
      height: 48px;
      margin-right: 8px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important;
      border: 1px solid;
      padding: 12px; }
    .orders-confirmation-modal .selected-orders .line-item .line-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      color: #16273b; }
    .orders-confirmation-modal .selected-orders .line-item .line-item-sku {
      font-size: 12px;
      color: #898989;
      margin: 0; }
  .orders-confirmation-modal .selected-orders .line-item-total-order-cost {
    width: 1100px !important; }
  .orders-confirmation-modal .ant-input {
    border-radius: 2px; }
  .orders-confirmation-modal .line-item-product-detail {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    width: 200px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px; }
    .orders-confirmation-modal .line-item-product-detail img {
      border: 1.5px solid #b2b2b2;
      margin-right: 8px;
      padding: 2px;
      width: 56px;
      height: 56px;
      object-fit: cover; }
  .orders-confirmation-modal .line-item-quantity-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    width: 80px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 8px;
    height: 48px;
    justify-content: center; }
    .orders-confirmation-modal .line-item-quantity-txt .piece {
      font-size: 12px;
      color: #212121;
      letter-spacing: 0.032em;
      margin-left: 4px; }
  .orders-confirmation-modal .lineitem-product-price {
    width: 100px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    text-align: center; }
  .orders-confirmation-modal .lineitem-fulfilment-price {
    width: 100px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    text-align: center; }
  .orders-confirmation-modal .linitem-forwading-price {
    width: 115px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    text-align: center; }
  .orders-confirmation-modal .linitem-cod-price {
    width: 90px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    text-align: center; }
  .orders-confirmation-modal .lineitem-total-product-price {
    width: 110px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    text-align: center; }
  .orders-confirmation-modal .line-item-total-order-cost {
    padding: 16px;
    background: #f5f5f5;
    width: 1100px;
    margin: 0 auto;
    margin-bottom: 8px;
    text-align: right; }
    .orders-confirmation-modal .line-item-total-order-cost .total-order-value {
      margin-right: 24px;
      color: #212121;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-right: 14px; }
    .orders-confirmation-modal .line-item-total-order-cost .total-order-cost {
      color: #898989;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-right: 48px; }
  .orders-confirmation-modal .lineitem-cancel-btn {
    border: 1px solid #898989;
    width: 144px;
    height: 40px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 16px;
    border-radius: 2px;
    color: #898989;
    margin-bottom: 8px;
    margin-top: 8px; }
  .orders-confirmation-modal .lineitem-conform-btn {
    background: #2bc822;
    border: none;
    width: 208px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 16px;
    border-radius: 2px; }
  .orders-confirmation-modal .line-item-select-resons .ant-select-selection__rendered {
    margin-left: 16px;
    background-color: #f8f8f8 !important;
    margin-right: 2px;
    height: 100% !important; }
    .orders-confirmation-modal .line-item-select-resons .ant-select-selection__rendered .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .orders-confirmation-modal .line-item-select-resons .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .orders-confirmation-modal .line-item-select-resons p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #898989 !important; }
  .orders-confirmation-modal .line-item-select-resons .ant-select-selection-selected-value {
    height: 100% !important;
    line-height: 32px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: 13px;
    color: #1268fb; }
    .orders-confirmation-modal .line-item-select-resons .ant-select-selection-selected-value .anticon svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px; }
  .orders-confirmation-modal .line-item-select-resons .ant-select-selection {
    width: 500px;
    border: none !important;
    background: #f8f8f8 !important;
    height: 40px !important;
    border-radius: 2px; }
    .orders-confirmation-modal .line-item-select-resons .ant-select-selection .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .orders-confirmation-modal .line-item-select-resons .ant-select-selection .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .orders-confirmation-modal .line-item-select-resons .ant-select-arrow {
    top: 47%;
    left: 88% !important; }
  .orders-confirmation-modal .ant-input-number {
    border: none !important;
    border-radius: 2px !important;
    background: #f5f5f5;
    height: 40px;
    font-size: 12px;
    padding: 4px; }
  .orders-confirmation-modal .refundAmount-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-right: -8px;
    color: #898989; }
  .orders-confirmation-modal .refunded-txt2 {
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .orders-confirmation-modal .refunded-txt {
    color: #212121;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .orders-confirmation-modal .linitem-checkbox-selection {
    padding: 5px 0px 0px 24px; }
  .orders-confirmation-modal .not-available {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    width: 59px; }
  .orders-confirmation-modal .bundleitem-vfsku {
    color: #212121;
    line-height: 16px;
    letter-spacing: 0.032em; }
  .orders-confirmation-modal .side p {
    color: #212121;
    line-height: 16px;
    letter-spacing: 0.032em; }
  .orders-confirmation-modal .order-id a:hover {
    text-decoration: underline !important; }
  .orders-confirmation-modal .side p a:hover {
    text-decoration: underline !important; }
  .orders-confirmation-modal .disbaledropdown {
    background: #94e38f;
    border: none;
    width: 208px;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 16px;
    border-radius: 2px;
    color: #fff;
    pointer-events: none; }
  .orders-confirmation-modal .ant-btn-primary-disabled,
  .orders-confirmation-modal .ant-btn-primary.disabled,
  .orders-confirmation-modal .ant-btn-primary[disabled],
  .orders-confirmation-modal .ant-btn-primary-disabled:hover,
  .orders-confirmation-modal .ant-btn-primary.disabled:hover,
  .orders-confirmation-modal .ant-btn-primary[disabled]:hover,
  .orders-confirmation-modal .ant-btn-primary-disabled:focus,
  .orders-confirmation-modal .ant-btn-primary.disabled:focus,
  .orders-confirmation-modal .ant-btn-primary[disabled]:focus,
  .orders-confirmation-modal .ant-btn-primary-disabled:active,
  .orders-confirmation-modal .ant-btn-primary.disabled:active,
  .orders-confirmation-modal .ant-btn-primary[disabled]:active,
  .orders-confirmation-modal .ant-btn-primary-disabled.active,
  .orders-confirmation-modal .ant-btn-primary.disabled.active,
  .orders-confirmation-modal .ant-btn-primary[disabled].active {
    color: #fff;
    background-color: #94e38f;
    border-color: #94e38f;
    text-shadow: none;
    box-shadow: none; }
  .orders-confirmation-modal .check-box-wraper {
    display: flex; }
    .orders-confirmation-modal .check-box-wraper .notfulfill-check-txt2 {
      text-align: left;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      width: 82%;
      color: #898989; }
    .orders-confirmation-modal .check-box-wraper .notfulfill-check-txt {
      text-align: left;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      width: 82%;
      color: #212121; }
  .orders-confirmation-modal .footer-right-line-item-btn {
    display: flex;
    align-items: center; }
    .orders-confirmation-modal .footer-right-line-item-btn button[disabled] {
      background-color: gray !important;
      border-color: gray !important; }
  .orders-confirmation-modal .sku_container .sku_data_heading {
    display: flex;
    justify-content: space-between;
    padding: 8px 0px 8px 16px;
    width: 1100px !important;
    margin: 8px auto;
    margin-bottom: -4px; }
    .orders-confirmation-modal .sku_container .sku_data_heading p {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989; }
    .orders-confirmation-modal .sku_container .sku_data_heading .sku_txt {
      width: 200px; }
    .orders-confirmation-modal .sku_container .sku_data_heading .order_count_txt {
      width: 220px; }
    .orders-confirmation-modal .sku_container .sku_data_heading .current_qty_tx {
      width: 220px; }
    .orders-confirmation-modal .sku_container .sku_data_heading .placing_order_txt {
      width: 250px; }
  .orders-confirmation-modal .sku_container .sku_value {
    display: flex;
    justify-content: space-between;
    padding: 8px 0px 8px 16px;
    background: #f5f5f5;
    align-items: center;
    width: 1100px !important;
    margin: 0 auto;
    margin-bottom: 8px; }
    .orders-confirmation-modal .sku_container .sku_value p {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121; }
    .orders-confirmation-modal .sku_container .sku_value .sku_txt {
      width: 200px; }
    .orders-confirmation-modal .sku_container .sku_value .order_count_txt {
      width: 220px; }
    .orders-confirmation-modal .sku_container .sku_value .current_qty_tx {
      width: 220px; }
    .orders-confirmation-modal .sku_container .sku_value .placing_order_txt {
      width: 250px; }
  .orders-confirmation-modal .alert_txt_ican .anticon {
    width: 16px;
    height: 16px;
    margin-right: 8px; }
  .orders-confirmation-modal .alert_txt {
    padding: 8px 0px;
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    margin-bottom: -24px;
    margin-top: -8px;
    font-weight: 400; }
  .orders-confirmation-modal .link_branding {
    padding-left: 24px;
    margin-top: 3px;
    font-size: 12px;
    letter-spacing: 0.032em; }
  .orders-confirmation-modal .link_branding a:hover {
    text-decoration: underline !important; }

.inv-error {
  color: red;
  font-size: 12px;
  margin: 12px 0; }

.retail-not-allowed {
  opacity: 0.7;
  cursor: not-allowed !important; }

.ndr-modal .input-label {
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  color: #000; }

.ndr-modal .no-top-margin {
  margin-top: 0rem; }

.ndr-modal input,
.ndr-modal textarea {
  border: 1.5px solid #979797;
  border-radius: 4px; }

.ndr-modal .input-label {
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  color: #000; }

.ndr-modal .no-top-margin {
  margin-top: 0rem; }

.ndr-modal input,
.ndr-modal textarea {
  border: 1.5px solid #979797;
  border-radius: 4px; }

.my-orders .mav-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 12px; }
  .my-orders .mav-filter .mav-filter-wraper {
    margin-right: 15px;
    margin-bottom: 24px; }

.my-orders .bulk-list-wraper .detailswrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 8px; }
  .my-orders .bulk-list-wraper .detailswrp > p {
    font-size: 12px;
    display: flex;
    align-items: center; }
    .my-orders .bulk-list-wraper .detailswrp > p span {
      font-size: 14px;
      font-weight: 500;
      margin: 0px 6px; }
  .my-orders .bulk-list-wraper .detailswrp .right {
    margin-left: 16px; }
    .my-orders .bulk-list-wraper .detailswrp .right .err {
      border-color: red !important;
      background-color: transparent !important;
      color: red !important; }

.my-orders .bulk-list-wraper .lst-wraper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 12px; }
  .my-orders .bulk-list-wraper .lst-wraper .left {
    transition: all 1s linear;
    background: #e6eaee;
    width: 100%;
    padding: 8px 12px 0px 12px;
    border-radius: 4px;
    border: 1px solid #b2b2b2; }
  .my-orders .bulk-list-wraper .lst-wraper .ant-tag {
    margin-bottom: 8px;
    border-radius: 2px;
    padding: 2px 6px;
    border-color: #b5daff;
    background-color: #f5faff; }
  .my-orders .bulk-list-wraper .lst-wraper .left::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px; }
  .my-orders .bulk-list-wraper .lst-wraper .left::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
  .my-orders .bulk-list-wraper .lst-wraper .left::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #898989; }
  .my-orders .bulk-list-wraper .lst-wraper .left {
    display: flex;
    align-items: center;
    min-height: 20px;
    max-height: 80px;
    overflow-y: scroll;
    flex-wrap: wrap; }
    .my-orders .bulk-list-wraper .lst-wraper .left p {
      margin-right: 8px; }
  .my-orders .bulk-list-wraper .lst-wraper .right {
    margin-bottom: 12px; }

.my-orders .filter .ant-select-selection {
  border-radius: 0;
  padding-left: 10px; }

.my-orders .pick {
  height: 41px !important; }
  .my-orders .pick .ant-calendar-picker-input {
    height: 40px; }

.my-orders .fill .active {
  color: #1268fb;
  border-bottom: 2px #1268fb solid; }

.my-orders .fill2 .active {
  color: #1268fb;
  border-bottom: 2px #1268fb solid; }

.my-orders .se input {
  height: 40px; }

.my-orders .ss .ant-select-selection {
  border-radius: 0 !important; }

.my-orders .ss .ant-select-selection__rendered {
  border-radius: 0; }

.my-orders .drop {
  background-color: #f8f8f8 !important;
  box-shadow: 0px 2px 8px #00000014;
  letter-spacing: 0 !important; }
  .my-orders .drop .ant-select-selection__rendered {
    margin-left: 16px;
    background-color: #f8f8f8 !important;
    margin-right: 2px;
    height: 100% !important; }
    .my-orders .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .my-orders .drop .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .my-orders .drop p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #898989 !important; }
  .my-orders .drop .ant-select-selection-selected-value {
    height: 100% !important;
    line-height: 32px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: 13px;
    color: #1268fb; }
    .my-orders .drop .ant-select-selection-selected-value .anticon svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px; }
  .my-orders .drop .ant-select-selection {
    width: 159px;
    border: none !important;
    background: #f8f8f8 !important; }
    .my-orders .drop .ant-select-selection .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .my-orders .drop .ant-select-selection .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .my-orders .drop .ant-select-arrow {
    top: 53%;
    left: 159% !important; }

.my-orders .ant-checkbox-inner::after {
  left: 20%; }

.my-orders .ant-btn-group-lg > .ant-btn,
.my-orders .ant-btn-group-lg > span > .ant-btn {
  height: 32px !important;
  padding: 0 8px !important;
  font-size: 12px !important;
  border-radius: 0 !important;
  line-height: 22px !important;
  font-weight: 500;
  letter-spacing: 0.032em; }

.button-row {
  margin-top: 20px; }
  .button-row span {
    font-size: 12px;
    color: #6e6e6e; }
  .button-row button {
    background: none;
    border: none;
    color: #6e6e6e;
    margin-left: 30px;
    font-size: 12px;
    cursor: pointer; }

.ant-select-arrow {
  margin-top: -4px; }

.mo .ant-select-selection {
  background-color: #f8f8f8; }
  .mo .ant-select-selection .ant-select-selection-selected-value {
    width: 100px; }
  .mo .ant-select-selection :hover {
    background-color: white !important; }

@media screen and (min-width: 180px) and (max-width: 1439px) {
  .drop {
    background-color: #f8f8f8 !important;
    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important; }
    .drop .ant-select-selection__rendered {
      margin-left: 16px;
      background-color: #f8f8f8 !important;
      margin-right: 2px;
      height: 100% !important; }
      .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb; }
        .drop .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px; }
    .drop p {
      font-size: 12px !important;
      line-height: 16px !important;
      color: #898989 !important; }
    .drop .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .drop .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
    .drop .ant-select-selection {
      width: 159px;
      border: none !important;
      background: #f8f8f8 !important; }
      .drop .ant-select-selection .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        padding-right: 13px;
        color: #1268fb; }
        .drop .ant-select-selection .ant-select-selection-selected-value .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px; }
    .drop .ant-select-arrow {
      top: 53%;
      left: 159% !important; } }

.custom-feedback {
  background-color: #ffffff;
  padding: 20px 32px;
  width: 300px;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  border-radius: 0px !important; }
  .custom-feedback .ant-notification-notice-message {
    display: none; }
  .custom-feedback .ant-notification-notice-close {
    right: 24px;
    top: 21px; }
    .custom-feedback .ant-notification-notice-close svg path {
      fill: #898989; }
  .custom-feedback * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-family: "Poppins", sans-serif; }
  .custom-feedback .svg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px; }
  .custom-feedback h1 {
    font-family: "IBM Plex Serif", serif !important;
    font-size: 20px;
    font-weight: 600;
    margin-left: 46px;
    margin-right: 46px;
    text-align: center;
    margin: auto;
    line-height: 26px;
    margin-bottom: 9px;
    letter-spacing: 0 !important; }
  .custom-feedback h2 {
    color: #383838;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 9px; }
    .custom-feedback h2 a {
      color: #0068ff;
      cursor: pointer;
      text-decoration: none; }
  .custom-feedback .line {
    height: 1px;
    background-color: #e3e3e3;
    margin-bottom: 16px; }
  .custom-feedback h3 {
    color: #212121;
    text-decoration: underline;
    margin-bottom: 9px;
    font-size: 12px;
    font-weight: 400; }
  .custom-feedback .paragraph {
    width: 95%;
    margin: auto;
    margin-bottom: 20px; }
    .custom-feedback .paragraph :first-child {
      font-weight: 400;
      margin-bottom: 24px;
      font-size: 12px;
      color: #212121; }
    .custom-feedback .paragraph :last-child {
      margin-bottom: 24px;
      font-weight: 400;
      font-size: 12px;
      color: #212121; }
  .custom-feedback .btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .custom-feedback .btns :first-child {
      width: 224px;
      height: 32px;
      background-color: #2a9c27;
      color: white;
      outline: none;
      border: none;
      font-size: 12px;
      margin-bottom: 12px;
      font-weight: 400;
      cursor: pointer; }
    .custom-feedback .btns :last-child {
      width: 224px;
      position: relative;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #898989;
      border: none;
      font-weight: 400;
      cursor: pointer; }
      .custom-feedback .btns :last-child svg {
        position: absolute;
        height: 16px;
        right: -52px;
        top: 50%;
        transform: translateY(-50%); }

.old-user-custom-feedback {
  background-color: #ffffff;
  padding: 20px 32px;
  width: 300px;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  border-radius: 0px !important; }
  .old-user-custom-feedback .ant-notification-notice-message {
    display: none; }
  .old-user-custom-feedback * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: 0.032em;
    font-family: "Poppins", sans-serif; }
  .old-user-custom-feedback .svg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px; }
  .old-user-custom-feedback h1 {
    font-family: "IBM Plex Serif", serif !important;
    font-size: 20px;
    font-weight: 600;
    margin-left: 46px;
    margin-right: 46px;
    text-align: center;
    margin: auto;
    margin-bottom: 9px;
    line-height: 26px;
    letter-spacing: 0 !important; }
  .old-user-custom-feedback h2 {
    color: #383838;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 9px; }
    .old-user-custom-feedback h2 span {
      color: #0068ff;
      cursor: pointer; }
  .old-user-custom-feedback .line {
    height: 1px;
    background-color: #e3e3e3;
    margin-bottom: 19px; }
  .old-user-custom-feedback h3 {
    color: #212121;
    text-decoration: underline;
    margin-bottom: 9px;
    font-size: 12px;
    font-weight: 400; }
  .old-user-custom-feedback .paragraph {
    margin: auto;
    margin-bottom: 20px;
    line-height: 16px; }
    .old-user-custom-feedback .paragraph :first-child {
      font-weight: 400;
      margin-bottom: 24px;
      font-size: 12px;
      color: #212121; }
    .old-user-custom-feedback .paragraph :last-child {
      margin-bottom: 24px;
      font-weight: 400;
      margin: auto;
      font-size: 12px;
      color: #212121;
      width: 80%;
      text-align: center; }
  .old-user-custom-feedback .btns {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .old-user-custom-feedback .btns :first-child {
      width: 224px;
      height: 32px;
      font-size: 12px;
      background-color: #2a9c27;
      color: white;
      outline: none;
      border: none;
      margin-bottom: 12px;
      font-weight: 400;
      cursor: pointer; }
    .old-user-custom-feedback .btns :last-child {
      width: 224px;
      position: relative;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      color: #898989;
      border: none;
      font-size: 12px;
      cursor: pointer; }
      .old-user-custom-feedback .btns :last-child svg {
        position: absolute;
        height: 16px;
        right: -52px;
        top: 50%;
        transform: translateY(-50%); }

.content-page-subtitle {
  font-size: 12px;
  color: #212121;
  letter-spacing: 0.032em; }

.view-product-bundle-modal .ant-modal-content {
  border-radius: 2px; }
  .view-product-bundle-modal .ant-modal-content .ant-modal-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }

.view-product-bundle-modal .ant-modal-close-x {
  display: none; }

.view-product-bundle-modal .ant-modal-body {
  padding: 0px; }

.view-product-bundle-modal .ant-table-thead > tr > th,
.view-product-bundle-modal .ant-table-tbody > tr > td {
  padding: 8px 24px;
  font-size: 12px; }

.view-product-bundle-modal .bundel-variant-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  padding-bottom: 8px; }
  .view-product-bundle-modal .bundel-variant-header .variant-detail-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    width: 225px; }
  .view-product-bundle-modal .bundel-variant-header .specifications-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    width: 130px; }
  .view-product-bundle-modal .bundel-variant-header .quantity-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    width: 56px; }

.view-product-bundle-modal .ant-modal-header {
  padding: 32px 0px;
  border-bottom: 1px solid #cecece;
  display: flex;
  margin: 0 auto;
  width: 512px; }

.view-product-bundle-modal .ant-modal-footer {
  padding: 8px 0px;
  text-align: right;
  border-top: 1px solid #cecece;
  width: 512px;
  margin: 0 auto; }

.view-product-bundle-modal .ant-modal-title {
  color: #212121;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-bottom: -16px; }

.view-product-bundle-modal .bundle-variant-bottom-data {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px;
  background: #f5f5f5;
  margin-bottom: 16px;
  width: 512px;
  margin: 8px auto;
  align-items: center; }
  .view-product-bundle-modal .bundle-variant-bottom-data .variant-detail-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    width: 225px;
    display: flex;
    align-items: center; }
    .view-product-bundle-modal .bundle-variant-bottom-data .variant-detail-txt img {
      border: 1.5px solid #b2b2b2;
      margin-right: 8px;
      padding: 2px;
      width: 56px;
      height: 56px;
      object-fit: cover; }
  .view-product-bundle-modal .bundle-variant-bottom-data .specifications-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    width: 130px; }
  .view-product-bundle-modal .bundle-variant-bottom-data .quantity-txt {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    width: 56px; }

.view-product-bundle-modal .bundle-variant-bottom-data:first-of-type {
  margin-top: 0px !important; }

.view-product-bundle-modal .bundle-cancel-modal {
  border: 1px solid #898989;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px;
  color: #898989;
  margin-bottom: 8px;
  margin-top: 8px; }

.view-product-bundle-modal .bundleitem-vfsku {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #212121; }

.view-product-bundle-modal .side p a:hover {
  text-decoration: underline !important; }

.view-product-bundle-modal .not-available {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  width: 59px; }

.view-product-bundle-modal .bundle-bottom-variant-data {
  max-height: 232px;
  overflow: auto; }

.view-product-bundle-modal .no-bundle-data {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.oreder_card_new {
  margin-bottom: 32px;
  letter-spacing: 0.032em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 32px !important;
  position: relative; }
  .oreder_card_new .rto-badge {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #fff;
    padding: 1px 6px;
    font-size: 12px;
    font-weight: 500 !important;
    border-radius: 5px; }
  .oreder_card_new .rto-badge.custom-pos {
    right: 160px !important; }
  .oreder_card_new .rto-badge.high {
    background-color: #e61047; }
  .oreder_card_new .rto-badge.no {
    background-color: #73d13d; }
  .oreder_card_new .rto-badge.moderate {
    background-color: #ffc53d;
    color: #212121; }
  .oreder_card_new .in-proces-date {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .oreder_card_new .in-proces-date span {
      font-size: 12px;
      color: #898989; }
    .oreder_card_new .in-proces-date p span {
      color: #212121 !important; }
  .oreder_card_new .ant-card-body {
    padding: 0 !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .oreder_card_new .btn-on-hold {
    color: #898989;
    font-size: 12px;
    font-weight: 500;
    border-radius: 2px; }
  .oreder_card_new .vforderid_tag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 12px;
    gap: 12px; }
    .oreder_card_new .vforderid_tag .vf_order {
      left: 0px;
      top: 23px; }
    .oreder_card_new .vforderid_tag .product-tag-wraper {
      left: 220px;
      top: 23px;
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .oreder_card_new .vforderid_tag .product-tag-wraper > p {
        margin-right: 6px;
        font-size: 12px;
        color: #898989; }
      .oreder_card_new .vforderid_tag .product-tag-wraper .more {
        font-size: 12px;
        color: #898989;
        cursor: pointer; }
    .oreder_card_new .vforderid_tag .not_serviceable {
      left: 196px;
      top: 22px;
      background: #dd5725;
      color: white;
      font-size: 8px;
      margin-right: 8px;
      border-radius: 3px;
      height: 24px;
      padding: 4px;
      display: flex;
      align-items: center;
      margin-top: 1px;
      justify-content: center;
      width: 152px;
      height: 24px; }
    .oreder_card_new .vforderid_tag .order_tooltip svg {
      position: absolute;
      left: 163px;
      top: 3px;
      color: #191818;
      font-size: 8px;
      display: flex;
      align-items: center; }
  .oreder_card_new .middleupperdata {
    display: flex;
    width: 100%;
    text-align: center;
    padding: 24px 10px 24px 24px;
    margin: 0px 0 -8px 0px; }
    .oreder_card_new .middleupperdata .right_data {
      width: 80%;
      display: flex;
      justify-content: flex-start; }
      .oreder_card_new .middleupperdata .right_data h3 {
        margin-right: 48px;
        display: flex;
        align-items: center; }
    .oreder_card_new .middleupperdata .left_data {
      width: 28%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 24px; }
      .oreder_card_new .middleupperdata .left_data .dropdownmenu {
        width: 128px;
        height: 32px;
        background: #1161fb;
        color: #fff;
        position: relative;
        border-radius: 2px; }
      .oreder_card_new .middleupperdata .left_data .dropdownmenu2 {
        width: 128px;
        height: 32px;
        background: #e61047;
        color: #fff;
        position: relative;
        border-radius: 2px; }
      .oreder_card_new .middleupperdata .left_data .dropdownmenu2:hover {
        background: #c70c3c; }
      .oreder_card_new .middleupperdata .left_data .verified-dropdownmenu {
        display: flex;
        align-items: center;
        font-size: 12px;
        justify-content: center; }
      .oreder_card_new .middleupperdata .left_data .tooltip-title {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        text-align: center; }
  .oreder_card_new .mr-32 {
    width: 40px;
    display: flex;
    justify-content: center; }
  .oreder_card_new .mr-33 {
    width: 40px;
    display: flex;
    justify-content: center; }
  .oreder_card_new .mr-3 {
    margin-right: 8px; }
  .oreder_card_new .mr-4 {
    margin-right: 8px; }
  .oreder_card_new span {
    vertical-align: middle; }
    .oreder_card_new span i {
      vertical-align: middle; }
  .oreder_card_new .blue-text {
    color: #0068ff; }
  .oreder_card_new .tabs-row {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .oreder_card_new .tabs-row ul {
      list-style-type: none;
      margin: 0;
      padding: 0 0 0 24px;
      height: 40px;
      background: #16273b; }
      .oreder_card_new .tabs-row ul li {
        display: inline-block;
        padding: 8px 24px;
        color: white;
        cursor: pointer;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 24px; }
      .oreder_card_new .tabs-row ul li.active,
      .oreder_card_new .tabs-row ul li:hover {
        background: #244060; }
    .oreder_card_new .tabs-row .bulk-action-btn {
      letter-spacing: 0.032em;
      background: transparent;
      color: white;
      border: 1px solid #fff;
      border-radius: 2px;
      font-weight: 400;
      line-height: 16px; }
    .oreder_card_new .tabs-row .ant-avatar {
      width: 20px;
      height: 20px;
      line-height: 20px;
      font-size: 12px; }
  .oreder_card_new .add-note-btn,
  .oreder_card_new .view-notes-btn {
    border-color: #0068ff;
    color: #0068ff;
    font-size: 12px; }
  .oreder_card_new .add-note-btn:hover,
  .oreder_card_new .view-notes-btn:hover {
    border: 1px solid #034eb8 !important;
    color: #034eb8 !important;
    box-shadow: none; }
    .oreder_card_new .add-note-btn:hover svg path,
    .oreder_card_new .view-notes-btn:hover svg path {
      fill: #034eb8; }
  .oreder_card_new .order-card span {
    vertical-align: middle; }
  .oreder_card_new .ant-dropdown-button.ant-btn-group
> .ant-btn:last-child:not(:first-child):not(:disabled) {
    background: #1370fc; }
  .oreder_card_new .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .oreder_card_new .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .oreder_card_new .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .oreder_card_new .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .oreder_card_new .ant-dropdown-menu-item-disabled,
  .oreder_card_new .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed !important;
    background: rgba(0, 0, 0, 0.15) !important; }
  .oreder_card_new .ant-btn-group .ant-btn {
    vertical-align: middle; }
  .oreder_card_new .tab-container {
    background: #f5f5f5;
    padding: 0 24px 24px 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .oreder_card_new .tab-container .or-txt {
      text-align: center; }
  .oreder_card_new .line-items-tab {
    padding: 16px 16px 8px 16px;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .oreder_card_new .line-items-tab .line-item {
      background: #f2f2f2;
      margin-bottom: 10px;
      padding: 14px 12px;
      display: flex;
      justify-content: space-between;
      border: 1px solid transparent; }
      .oreder_card_new .line-items-tab .line-item .quantity-field {
        width: 70px !important;
        margin-right: 8px !important;
        margin-left: -6px !important;
        border-radius: 2px !important; }
      .oreder_card_new .line-items-tab .line-item .anticon {
        font-size: 22px; }
      .oreder_card_new .line-items-tab .line-item .line-item-product-detail {
        display: flex;
        align-items: center;
        width: 250px;
        margin-left: 0px; }
        .oreder_card_new .line-items-tab .line-item .line-item-product-detail span p {
          margin-bottom: -2px; }
      .oreder_card_new .line-items-tab .line-item .line-item-img {
        width: 56px;
        height: 56px;
        margin-right: 8px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
        border: 0px solid #c8c8c8; }
      .oreder_card_new .line-items-tab .line-item .line-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 12px;
        width: 150px; }
      .oreder_card_new .line-items-tab .line-item .line-item-sku {
        font-size: 12px;
        color: #000;
        font-weight: 400;
        margin: 0;
        overflow: inherit !important; }
        .oreder_card_new .line-items-tab .line-item .line-item-sku a {
          color: #1161fb; }
        .oreder_card_new .line-items-tab .line-item .line-item-sku a:hover {
          text-decoration: underline !important; }
      .oreder_card_new .line-items-tab .line-item .line-item-name-sku {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 12px;
        width: 150px; }
      .oreder_card_new .line-items-tab .line-item .line-item-name-Specs {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        width: 150px;
        color: #000;
        font-weight: 400;
        margin: 0; }
    .oreder_card_new .line-items-tab .line-item.selected {
      background: #eeffe5;
      border: 1px solid #73d13d; }
    .oreder_card_new .line-items-tab .edit-quantity-btn {
      background: transparent;
      color: #ff005e;
      border: none;
      cursor: pointer; }
    .oreder_card_new .line-items-tab .line-items-tab_header_upper_data {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      padding: 16px 16px 8px;
      margin: -16px 0 -2px 0; }
    .oreder_card_new .line-items-tab .flex33 {
      width: 250px; }
    .oreder_card_new .line-items-tab .flex44 {
      width: 85px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .oreder_card_new .line-items-tab .flex55 {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-evenly; }
      .oreder_card_new .line-items-tab .flex55 span {
        margin-right: 20px; }
      .oreder_card_new .line-items-tab .flex55 .Quantity {
        width: 70px; }
      .oreder_card_new .line-items-tab .flex55 .star {
        width: 30px; }
      .oreder_card_new .line-items-tab .flex55 .Shopify_Selling_Price {
        width: 150px; }
    .oreder_card_new .line-items-tab .flex66 {
      width: 115px;
      display: flex;
      justify-content: flex-start; }
    .oreder_card_new .line-items-tab .verify-linked-bundle {
      width: 115px;
      display: flex;
      padding-left: 6px; }
    .oreder_card_new .line-items-tab .openordersvg {
      width: 85px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .oreder_card_new .line-items-tab .openordersvg svg {
        height: 18px; }
    .oreder_card_new .line-items-tab .quntyandpices {
      display: flex;
      width: 250px;
      justify-content: space-evenly;
      align-items: center;
      font-size: 12px; }
      .oreder_card_new .line-items-tab .quntyandpices .Quantity {
        width: 70px; }
      .oreder_card_new .line-items-tab .quntyandpices .star {
        width: 30px; }
      .oreder_card_new .line-items-tab .quntyandpices .Shopify_Selling_Price {
        width: 150px; }
    .oreder_card_new .line-items-tab .editbtn {
      width: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px; }
    .oreder_card_new .line-items-tab .shopify_selling_price {
      text-align: center;
      background: #f2f2f2;
      margin-bottom: 10px;
      padding: 8px 16px;
      border: 1px solid transparent;
      font-size: 12px; }
  .oreder_card_new .shipments-tab {
    padding: 16px 8px;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .oreder_card_new .shipments-tab .no-shipment {
      text-align: center; }
    .oreder_card_new .shipments-tab .shipment-header {
      background: #f2f2f2;
      padding: 16px 16px;
      margin-bottom: 5px;
      color: black; }
    .oreder_card_new .shipments-tab .shipment-items {
      background: #eff5ff;
      margin-top: -5px; }
    .oreder_card_new .shipments-tab .shipment-item {
      background: transparent;
      padding: 8px 8px;
      display: flex;
      align-items: center; }
      .oreder_card_new .shipments-tab .shipment-item .ant-checkbox-wrapper {
        transform: scale(1.15); }
      .oreder_card_new .shipments-tab .shipment-item .ant-checkbox .ant-checkbox-inner {
        border: 1px solid #aaa; }
      .oreder_card_new .shipments-tab .shipment-item .anticon {
        font-size: 18px; }
      .oreder_card_new .shipments-tab .shipment-item .shipment-item-product-detail {
        display: flex;
        align-items: center; }
      .oreder_card_new .shipments-tab .shipment-item .shipment-item-img {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        display: inline-block;
        background-size: cover !important;
        background-position: center !important;
        border: 1px solid #8989; }
      .oreder_card_new .shipments-tab .shipment-item .shipment-item-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        font-size: 12px; }
      .oreder_card_new .shipments-tab .shipment-item .shipment-item-sku {
        font-size: 12px;
        color: #898989;
        font-weight: 400;
        margin: 0;
        margin-top: -2px; }
    .oreder_card_new .shipments-tab .shipment-item:nth-of-type(2n) {
      background: white; }
    .oreder_card_new .shipments-tab .font_text_size {
      font-size: 12px;
      font-weight: 500;
      color: #898989; }
  .oreder_card_new .customer-info-tab {
    background: white;
    padding: 8px 16px; }
    .oreder_card_new .customer-info-tab .ant-row {
      margin-bottom: 15px; }
    .oreder_card_new .customer-info-tab .ant-input:read-only {
      background: #f5f5f5;
      cursor: no-drop; }
    .oreder_card_new .customer-info-tab .ant-form-item-control {
      width: 32%;
      line-height: 32px; }
      .oreder_card_new .customer-info-tab .ant-form-item-control .ant-form-explain {
        font-size: 12px !important;
        padding-top: 4px; }
    .oreder_card_new .customer-info-tab .btn-edit-customer {
      color: #ff005e;
      border: 1px solid #ff005e;
      border-radius: 0px;
      font-size: 12px;
      width: 168px;
      letter-spacing: 0.032em;
      line-height: 22px; }
    .oreder_card_new .customer-info-tab .btn-edit-customer:hover {
      color: #fff;
      background: #ff005e; }
    .oreder_card_new .customer-info-tab .btn-save-customer {
      background: #73d13d;
      border-color: #73d13d;
      color: white;
      border-radius: 0px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 22px;
      width: 168px; }
    .oreder_card_new .customer-info-tab .customerinfo_header {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .oreder_card_new .customer-info-tab .customerinfo_header label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989; }
      .oreder_card_new .customer-info-tab .customerinfo_header .ant-input {
        border-radius: 0px;
        font-size: 12px;
        margin-right: 24px;
        border: 1px solid #212121;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
      .oreder_card_new .customer-info-tab .customerinfo_header .ant-input-number {
        position: relative;
        font-size: 12px;
        margin: 0;
        padding: 0;
        border-radius: 0px;
        margin-right: 8px;
        border: 1px solid #212121;
        width: 100%;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
    .oreder_card_new .customer-info-tab .customerinfo-header-editable-state {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .oreder_card_new .customer-info-tab .customerinfo-header-editable-state label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989; }
      .oreder_card_new .customer-info-tab .customerinfo-header-editable-state .ant-input {
        border-radius: 0px;
        font-size: 12px;
        margin-right: 24px;
        border: none;
        letter-spacing: 0.032em;
        line-height: 16px;
        font-weight: 400;
        color: #898989; }
      .oreder_card_new .customer-info-tab .customerinfo-header-editable-state .ant-input-number {
        position: relative;
        font-size: 12px;
        margin: 0;
        padding: 0;
        border-radius: 0px;
        margin-right: 8px;
        border: none;
        width: 100%;
        letter-spacing: 0.032em;
        line-height: 16px;
        font-weight: 400;
        color: #898989; }
    .oreder_card_new .customer-info-tab .customerinfo_middledata {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .oreder_card_new .customer-info-tab .customerinfo_middledata label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989; }
      .oreder_card_new .customer-info-tab .customerinfo_middledata .ant-input {
        border-radius: 0px;
        font-size: 12px;
        margin-right: 24px;
        border: 1px solid #212121;
        color: #212121;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .oreder_card_new .customer-info-tab .customerinfo-middledata-editable-state {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .oreder_card_new .customer-info-tab .customerinfo-middledata-editable-state label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989; }
      .oreder_card_new .customer-info-tab .customerinfo-middledata-editable-state .ant-input {
        border-radius: 0px;
        font-size: 12px;
        border: none;
        margin-right: 24px;
        color: #898989;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .oreder_card_new .customer-info-tab .customerinfo_footerdata {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .oreder_card_new .customer-info-tab .customerinfo_footerdata label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989; }
      .oreder_card_new .customer-info-tab .customerinfo_footerdata .ant-input {
        border-radius: 0px;
        font-size: 12px;
        margin-right: 24px;
        border: 1px solid #212121;
        color: #212121;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .oreder_card_new .customer-info-tab .customerinfo-footerdata-editable-state {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .oreder_card_new .customer-info-tab .customerinfo-footerdata-editable-state label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989; }
      .oreder_card_new .customer-info-tab .customerinfo-footerdata-editable-state .ant-input {
        border-radius: 0px;
        font-size: 12px;
        border: none;
        margin-right: 24px;
        color: #898989;
        letter-spacing: 0.032em;
        line-height: 16px; }
  .oreder_card_new .myinput .ant-checkbox-inner {
    background-color: #ededed; }
  .oreder_card_new .ant-checkbox-inner::after {
    width: 5.71428571px;
    height: 9.14285714px; }
  .oreder_card_new .ant-btn-primary {
    color: #fff;
    background-color: #1370fc;
    font-size: 12px; }
  .oreder_card_new .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .oreder_card_new .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #1370fc; }
  .oreder_card_new .ant-btn-primary:hover,
  .oreder_card_new .ant-btn-primary:focus {
    color: #fff;
    background-color: #1370fc;
    border-color: #1370fc; }
  .oreder_card_new .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: transparent; }
  .oreder_card_new .line-item-sku svg g path {
    stroke: #1161fb;
    text-decoration: underline; }
  .oreder_card_new .line-item-sku:hover svg g path {
    stroke: #1161fb;
    text-decoration: underline; }
  .oreder_card_new .error_tag {
    padding: 3px;
    padding-left: 15px;
    padding-right: 15px;
    position: absolute;
    right: 0px;
    top: 8px;
    background: #f44336;
    color: white;
    font-size: 12px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }

.line-item-name a:hover {
  text-decoration: underline !important; }

.custom_dropdown2 .ant-dropdown-menu-item {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important; }

.custom_dropdown2 .ant-dropdown-menu-item-active {
  box-shadow: 0px -1px 0px #b5dafe inset;
  border-bottom: none !important; }
  .custom_dropdown2 .ant-dropdown-menu-item-active a {
    color: #212121; }

.oreder_card_new.provisional {
  border: 1px solid #ff7d7b !important; }
  .oreder_card_new.provisional .left_data .dropdownmenu {
    background-color: gray !important; }

.custom_dropdown {
  cursor: pointer; }
  .custom_dropdown .disable {
    cursor: not-allowed !important;
    background-color: gray !important; }

.disable-dropdown {
  cursor: not-allowed !important;
  background-color: gray !important; }

.diable-dropdownmenu {
  background: gray !important; }

.order_tooltip .order-tooltip-icon {
  margin-right: 8px; }

.order_tooltip:hover svg.order-tooltip-icon {
  background: #c70c3c;
  border-radius: 50%;
  cursor: pointer; }

.order_tooltip:hover svg.order-tooltip-icon g text {
  fill: #fff; }

.order_tooltip:hover svg.order-tooltip-icon g > g {
  stroke: #fff; }

.order_tooltip2:hover svg.order-tooltip-icon {
  background: #1370fc;
  border-radius: 50%;
  cursor: pointer; }

.order_tooltip2:hover svg.order-tooltip-icon g text {
  fill: #fff; }

.order_tooltip2:hover svg.order-tooltip-icon g > g {
  stroke: #fff; }

.provisional-remark {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  text-align: center; }

.tag-overlay .ant-tooltip-inner {
  background: #fff !important;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  grid-gap: 10px;
  gap: 10px; }

.tag-overlay .ant-tooltip-arrow::before {
  background: #fff !important; }

.shipmentprogressbar_data .shipmentprogressbar_data3 {
  background: #eff5ff;
  margin-bottom: 10px;
  padding: 8px 8px;
  border: 1px solid transparent; }
  .shipmentprogressbar_data .shipmentprogressbar_data3 .line-container {
    margin-top: 24px;
    display: flex;
    width: 100%;
    margin-bottom: 16px; }
    .shipmentprogressbar_data .shipmentprogressbar_data3 .line-container .progress-line {
      position: relative;
      margin: 0 auto;
      border-radius: 10px;
      width: 60%;
      background: #cecece;
      height: 8px;
      z-index: 90;
      display: flex;
      justify-content: space-between;
      margin-left: 32px; }
      .shipmentprogressbar_data .shipmentprogressbar_data3 .line-container .progress-line .progress {
        position: absolute;
        width: 75%;
        height: 100%;
        background: #2bc822;
        transition: width 1s ease-in-out; }
    .shipmentprogressbar_data .shipmentprogressbar_data3 .line-container .status {
      position: relative;
      top: -6px;
      left: -1px; }
      .shipmentprogressbar_data .shipmentprogressbar_data3 .line-container .status .dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #cecece;
        cursor: pointer; }
        .shipmentprogressbar_data .shipmentprogressbar_data3 .line-container .status .dot .current {
          background: white;
          border: 5px solid orange; }
        .shipmentprogressbar_data .shipmentprogressbar_data3 .line-container .status .dot.completed {
          position: relative;
          background: #2bc822;
          border: 5px solid #2bc822; }
  .shipmentprogressbar_data .shipmentprogressbar_data3 .shipment_step_color {
    color: #2bc822;
    margin-left: -16px;
    font-size: 12px; }
  .shipmentprogressbar_data .shipmentprogressbar_data3 .original_color {
    color: #cecece;
    margin-left: -16px;
    font-size: 12px; }
  .shipmentprogressbar_data .shipmentprogressbar_data3 .Order_tracking_status p {
    letter-spacing: 0.032em;
    font-size: 12px;
    line-height: 16px; }

.tab-container {
  background: #f5f5f5;
  padding: 0 24px 24px 24px; }
  .tab-container .notes-tab {
    padding: 16px 8px;
    background: #fff; }
    .tab-container .notes-tab .note {
      background: #f2f2f2;
      margin-bottom: 8px;
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      font-size: 12px; }
      .tab-container .notes-tab .note .flex-note {
        width: 45%; }
      .tab-container .notes-tab .note .flex-date {
        width: 27%; }
      .tab-container .notes-tab .note .flex-addedby {
        width: 25%; }
    .tab-container .notes-tab .header_upper_data {
      font-size: 12px;
      padding: 0 16px;
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px; }
      .tab-container .notes-tab .header_upper_data .flex-note {
        width: 45%; }
      .tab-container .notes-tab .header_upper_data .flex-date {
        width: 27%; }
      .tab-container .notes-tab .header_upper_data .flex-addedby {
        width: 25%; }
  .tab-container .flex-note svg {
    height: 18px;
    width: 18px;
    transform: rotate(29deg);
    position: relative;
    top: 5px;
    margin-right: 3px; }

.tab-container {
  background: #f5f5f5;
  padding: 0 24px 24px 24px; }
  .tab-container .notes-tab {
    padding: 16px 8px;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .tab-container .notes-tab .note {
      background: #f2f2f2;
      margin-bottom: 8px;
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      font-size: 12px; }
      .tab-container .notes-tab .note .flex-action {
        width: 45%; }
      .tab-container .notes-tab .note .flex-date {
        width: 27%; }
      .tab-container .notes-tab .note .flex-addedby {
        width: 25%; }
    .tab-container .notes-tab .header_upper_data {
      font-size: 12px;
      padding: 0 16px;
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px; }
      .tab-container .notes-tab .header_upper_data .flex-action {
        width: 45%; }
      .tab-container .notes-tab .header_upper_data .flex-date {
        width: 27%; }
      .tab-container .notes-tab .header_upper_data .flex-addedby {
        width: 25%; }

.tab-container {
  background: #f5f5f5;
  padding: 0 24px 24px 24px; }
  .tab-container .notes-tab {
    padding: 16px 8px;
    background: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
    .tab-container .notes-tab .note {
      background: #f2f2f2;
      margin-bottom: 8px;
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;
      font-size: 12px; }
      .tab-container .notes-tab .note .flex-action {
        width: 45%; }
      .tab-container .notes-tab .note .flex-date {
        width: 27%; }
      .tab-container .notes-tab .note .flex-addedby {
        width: 25%; }
    .tab-container .notes-tab .note.repitiveOrders div {
      text-transform: capitalize; }
    .tab-container .notes-tab .header_upper_data {
      font-size: 12px;
      padding: 0 16px;
      margin-top: 0px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px; }
      .tab-container .notes-tab .header_upper_data .flex-action {
        width: 45%; }
      .tab-container .notes-tab .header_upper_data .flex-date {
        width: 27%; }
      .tab-container .notes-tab .header_upper_data .flex-addedby {
        width: 25%; }

.tab-container.repitiveOrders .header_upper_data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 12px;
  gap: 12px; }
  .tab-container.repitiveOrders .header_upper_data > p {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .tab-container.repitiveOrders .header_upper_data p:first-of-type {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .tab-container.repitiveOrders .header_upper_data p:last-of-type {
    display: flex;
    align-items: center;
    justify-content: flex-end; }

.tab-container.repitiveOrders .note.repitiveOrders {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 12px;
  gap: 12px; }
  .tab-container.repitiveOrders .note.repitiveOrders > div {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .tab-container.repitiveOrders .note.repitiveOrders div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .tab-container.repitiveOrders .note.repitiveOrders div:last-of-type {
    display: flex;
    align-items: center;
    justify-content: flex-end; }

.click-id-badge {
  cursor: pointer;
  height: 24px;
  padding: 0 10px;
  background: #1161fb;
  color: white;
  border-bottom-left-radius: 0;
  font-size: 12px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500; }
  .click-id-badge .anticon {
    margin-right: 0px !important; }
  .click-id-badge svg {
    fill: #fff;
    margin-left: 8px;
    transition: all 2s ease-in-out;
    font-size: 12px;
    margin-right: 0px !important; }
    .click-id-badge svg g {
      stroke: #fff;
      animation: fillAnimation2 0.5s ease-in-out forwards; }
      .click-id-badge svg g path {
        stroke: #fff; }

.click-id-badge:hover svg {
  fill: #fff;
  margin-left: 8px;
  transition: all 2s ease-in-out; }
  .click-id-badge:hover svg g {
    stroke: #fff;
    fill: #fff;
    animation: fillAnimation 0.5s ease-in-out forwards; }
    .click-id-badge:hover svg g path {
      stroke: #fff; }

@keyframes fillAnimation {
  0% {
    fill: #1161fb; }
  100% {
    fill: #fff; } }

@keyframes fillAnimation2 {
  0% {
    fill: #fff; }
  100% {
    fill: #1161fb; } }

.fullorder_data {
  margin-bottom: 16px; }
  .fullorder_data .custom-lineheight {
    line-height: 35px !important; }
  .fullorder_data .ordernav .Globalsearch-order2 .drop {
    height: 40px !important; }
  .fullorder_data .ordernav .Globalsearch-order2 .ant-input-group {
    width: inherit !important; }
  .fullorder_data .ordernav .data-sorter .ant-select-selection-selected-value {
    margin-right: 8px !important; }
  .fullorder_data .ordernav .store-filters .ant-select-selection-selected-value {
    margin-right: 8px !important; }
  .fullorder_data .opennav {
    padding-left: 59px;
    padding-top: 85px;
    padding-right: 158px;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    transform: translateY(-125.6px);
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 21.7%;
    z-index: 99;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
    .fullorder_data .opennav .ordfillwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px; }
      .fullorder_data .opennav .ordfillwrap .store-filters {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .fullorder_data .opennav .ordfillwrap .store-filters p {
          margin-bottom: 0px;
          font-size: 12px;
          color: #6e6e6e;
          margin-right: 0px; }
      .fullorder_data .opennav .ordfillwrap .right {
        display: flex;
        align-items: center;
        justify-content: center; }
        .fullorder_data .opennav .ordfillwrap .right .filter .ant-select-selection {
          padding: 0 !important;
          background: none;
          border: none;
          margin-right: 0; }
        .fullorder_data .opennav .ordfillwrap .right .filter svg {
          font-size: 20px; }
        .fullorder_data .opennav .ordfillwrap .right .filter .ant-select-arrow {
          margin-top: -10px; }
        .fullorder_data .opennav .ordfillwrap .right .filter .ant-select-selection-selected-value {
          padding-right: 26px; }
        .fullorder_data .opennav .ordfillwrap .right .show_data_from {
          font-size: 12px;
          color: #6e6e6e; }
        .fullorder_data .opennav .ordfillwrap .right .dispute-status {
          font-size: 12px; }
    .fullorder_data .opennav .secondwrap {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .fullorder_data .opennav .secondwrap .filtertab {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px; }
        .fullorder_data .opennav .secondwrap .filtertab .tab {
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 20px;
          color: #898989; }
          .fullorder_data .opennav .secondwrap .filtertab .tab .active {
            color: #1268fb; }
          .fullorder_data .opennav .secondwrap .filtertab .tab button {
            cursor: pointer;
            border: none;
            background: transparent;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 500; }
        .fullorder_data .opennav .secondwrap .filtertab .circle {
          position: relative;
          background: #f5f5f5;
          font-size: 12px;
          padding: 2px 10px;
          color: #898989;
          border-radius: 13px;
          display: inline-block;
          height: 1.5rem;
          min-width: 1.5rem;
          max-width: 3.3rem;
          vertical-align: middle;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #898989; }
        .fullorder_data .opennav .secondwrap .filtertab .backgroundcircle {
          position: relative;
          background: #1268fb;
          font-size: 12px;
          padding: 2px 10px;
          color: #fff;
          border-radius: 13px;
          display: inline-block;
          height: 1.5rem;
          min-width: 1.5rem;
          max-width: 3.3rem;
          vertical-align: middle;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          border: 1px solid #1268fb; }
        .fullorder_data .opennav .secondwrap .filtertab .hover-order-underline-animation {
          position: relative; }
        .fullorder_data .opennav .secondwrap .filtertab .hover-order-underline-animation:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #4398ed;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out; }
        .fullorder_data .opennav .secondwrap .filtertab .hover-order-underline-animation:hover {
          color: #4398ed !important; }
          .fullorder_data .opennav .secondwrap .filtertab .hover-order-underline-animation:hover .circle {
            border: #4398ed 1px solid !important;
            color: #4398ed !important;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 400; }
        .fullorder_data .opennav .secondwrap .filtertab .hover-order-underline-animation:hover:after {
          transform: scaleX(1);
          transform-origin: top left; }
        .fullorder_data .opennav .secondwrap .filtertab .hover-order-underline-animation-click {
          position: relative; }
        .fullorder_data .opennav .secondwrap .filtertab .hover-order-underline-animation-click:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #1268fb;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
          transform: scaleX(1);
          transform-origin: top left; }
      .fullorder_data .opennav .secondwrap .download_csv {
        margin-right: 100px;
        margin-bottom: 10px;
        line-height: 30px;
        height: 32px;
        line-height: 30px; }
    .fullorder_data .opennav .ant-input {
      width: 82%;
      font-size: 12px;
      background-color: #f5f5f5;
      color: #1268fb;
      border: none;
      border-radius: 0px;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 24px;
      padding-left: 0px; }
    .fullorder_data .opennav .ant-calendar-range-picker-separator {
      color: #1268fb !important; }
    .fullorder_data .opennav .ant-select-selection {
      background-color: #f5f5f5;
      border: none;
      border-radius: 0px; }
    .fullorder_data .opennav .ant-select-selection-selected-value {
      font-size: 12px;
      color: #1268fb;
      letter-spacing: 0.032em; }
    .fullorder_data .opennav .ant-calendar-range-picker-input {
      width: 36% !important;
      letter-spacing: 0.032em;
      line-height: 24px; }
    .fullorder_data .opennav .ant-calendar-picker-clear,
    .fullorder_data .opennav .ant-calendar-picker-icon {
      top: 50%;
      right: 47px;
      width: 14px;
      height: 14px;
      font-size: 12px; }
  .fullorder_data .bottom_data {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 24px;
    padding-top: 90px;
    grid-gap: 16px;
    gap: 16px; }
    .fullorder_data .bottom_data .drop {
      height: 40px !important; }
  .fullorder_data .ant-select-selection__placeholder {
    color: #1268fb; }
  .fullorder_data .fullorder_data .ant-select-selection__placeholder {
    color: #898989; }
  .fullorder_data .ant-select-dropdown {
    font-size: 12px !important;
    border-radius: 0px !important;
    background: #f8f8f8;
    margin-top: 19px !important; }
  .fullorder_data .servicebale-select .ant-select-selection {
    width: 176px !important; }
    .fullorder_data .servicebale-select .ant-select-selection .ant-select-arrow {
      left: 146% !important; }

#orders-page-nav .ant-input-group-addon button {
  padding-right: 20px !important; }

.commondropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 32px; }
  .commondropdown .anticon svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    padding-top: 2px; }

.commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 2px solid #b5dafe !important;
  border-radius: 0px !important; }

.fullorder_data .ant-select-selection__placeholder {
  color: #898989; }

@media screen and (min-width: 1440px) {
  .opennav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; }
  .commondropdown {
    border-bottom: 1px solid #cecece !important;
    padding: 8px 16px !important;
    background: #f8f8f8 !important;
    color: #898989;
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 32px; }
    .commondropdown .anticon svg {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 8px; }
  .fullorder_data .ant-select-selection__placeholder {
    color: #898989; }
  .ant-select-dropdown {
    font-size: 12px !important;
    border-radius: 0px !important;
    background: #f8f8f8;
    margin-top: 19px !important; }
  .commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f4ff !important;
    border-bottom: 2px solid #b5dafe !important;
    border-radius: 0px !important; } }

.Globalsearch-order2 {
  position: relative; }
  .Globalsearch-order2 > div {
    height: 40px !important; }
  .Globalsearch-order2 .import-product-search {
    width: 300px !important; }
  .Globalsearch-order2 .ant-input-group {
    width: 300px !important; }
    .Globalsearch-order2 .ant-input-group button {
      width: 100px !important; }
  .Globalsearch-order2 .search-click {
    border-radius: 0px !important; }
  .Globalsearch-order2 .ant-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    height: 40px;
    background-color: #cf3341;
    border: 0; }
    .Globalsearch-order2 .ant-btn svg {
      transition: all 0.5s;
      fill: white; }
      .Globalsearch-order2 .ant-btn svg path {
        stroke: white; }
  .Globalsearch-order2 .ant-btn:hover {
    border-color: transparent; }
  .Globalsearch-order2 .ant-btn:hover svg {
    transition: all 0.5s;
    transform: rotateX(180deg); }
    .Globalsearch-order2 .ant-btn:hover svg g {
      fill: white; }
    .Globalsearch-order2 .ant-btn:hover svg path {
      stroke: white; }
  .Globalsearch-order2 svg {
    position: relative;
    left: -9px; }
  .Globalsearch-order2 .drop-down-bulk {
    position: absolute;
    right: 0;
    top: 0;
    width: 22px;
    height: 100%;
    border: 1px solid #770f0f;
    background-color: #770f0f;
    border-radius: 0 2px 2px 0; }
    .Globalsearch-order2 .drop-down-bulk svg {
      position: relative;
      top: 8px;
      left: 2px;
      transition: all 0.5s;
      transform: rotateX(0deg); }
      .Globalsearch-order2 .drop-down-bulk svg g {
        fill: none; }
  .Globalsearch-order2 .drop-down-bulk:hover .bulksearch {
    display: block; }
  .Globalsearch-order2 .drop-down-bulk:hover svg {
    transition: all 0.5s;
    transform: rotateX(180deg); }
    .Globalsearch-order2 .drop-down-bulk:hover svg g {
      fill: #898989; }
  .Globalsearch-order2 .bulksearch {
    position: absolute;
    display: none;
    right: -1px;
    bottom: -51px;
    width: 112px;
    height: 50px;
    z-index: 9999; }
    .Globalsearch-order2 .bulksearch .bulk-search-button {
      height: 100%;
      width: 100%;
      margin-right: 16px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      background: #0068ff !important;
      font-weight: 500 !important;
      box-shadow: 2px 2px 8px !important;
      border: none;
      color: #fff; }
    .Globalsearch-order2 .bulksearch .bulk-search-button:hover {
      color: #fff;
      background-color: #0063f2 !important;
      border: 2px solid #0058d6 !important; }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .opennav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.non-cursor-pointer {
  pointer-events: none; }

.bulk-search-menu {
  width: 132px;
  text-align: center; }

.flex-wraper-all-orders {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.import-product-search2 .ant-input-group {
  width: 425px !important; }
  .import-product-search2 .ant-input-group .ant-input {
    height: 40px !important;
    box-shadow: 0px 2px 8px #66656514;
    border-radius: 2px 0 0 2px;
    background-color: #f8f8f8;
    border: 1px solid #e3e3e3 !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .import-product-search2 .ant-input-group .ant-input::placeholder {
    font-size: 12px !important;
    line-height: none !important; }
  .import-product-search2 .ant-input-group .ant-input:hover {
    border: 1px solid #b2b2b2 !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .import-product-search2 .ant-input-group .ant-input:focus {
    border: 2px solid #fd104d !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }

.import-product-search2 button {
  width: 122px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  line-height: 8px !important;
  background: #fd104d !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  box-shadow: 2px 2px 8px #002f7225 !important;
  border: none;
  border: 2px solid #fd104d !important; }

.import-product-search2 button:hover {
  box-shadow: 0px 2px 8px #a01d1d14;
  background-color: #e61047 !important;
  border: 2px solid #c70c3c !important;
  color: #fff !important; }

.import-product-search2 button:active {
  background-color: #c70c3c !important; }

.import-product-search2 .store-listing {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.import-product-search-large .ant-input-group {
  width: 608px !important; }

.five-lg .ant-input-group {
  width: 550px !important; }

.import-product-search2.nextwidth2 .ant-input-group {
  width: 288px !important; }
  .import-product-search2.nextwidth2 .ant-input-group .ant-input {
    height: 40px !important;
    box-shadow: 0px 2px 8px #66656514;
    border-radius: 2px 0 0 2px;
    background-color: #f8f8f8 !important;
    border: 1px solid #e3e3e3 !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .import-product-search2.nextwidth2 .ant-input-group .ant-input::placeholder {
    font-size: 12px !important;
    line-height: none !important; }
  .import-product-search2.nextwidth2 .ant-input-group .ant-input:hover {
    border: 1px solid #b2b2b2 !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }
  .import-product-search2.nextwidth2 .ant-input-group .ant-input:focus {
    border: 2px solid #fd104d !important;
    border-right: none !important;
    transition: all 0.2s ease-in-out !important; }

.import-product-search2.nextwidth2 button {
  width: 112px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  line-height: 8px !important;
  background: #fd104d !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  box-shadow: 2px 2px 8px #002f7225 !important;
  border: none;
  border: 2px solid #fd104d !important; }

.import-product-search2.nextwidth2 button:hover {
  box-shadow: 0px 2px 8px #a01d1d14;
  background-color: #e61047 !important;
  border: 2px solid #c70c3c !important;
  color: #fff !important; }

.import-product-search2.nextwidth2 button:active {
  background-color: #c70c3c !important; }

.data-sorter {
  display: flex;
  align-items: center; }
  .data-sorter .ant-dropdown-trigger {
    display: flex;
    align-items: center; }
  .data-sorter span {
    font-size: 12px;
    color: #6e6e6e; }
  .data-sorter .ant-select-selection {
    background-color: transparent;
    border: none;
    border-radius: 0px; }
  .data-sorter .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .data-sorter a {
    font-size: 12px !important;
    color: #1268fb !important;
    letter-spacing: 0.032em !important;
    font-weight: 500 !important; }
  .data-sorter .anticon {
    font-size: 16px;
    margin-left: 4px;
    transition: all 0.5s; }
  .data-sorter .arrow-down {
    transform: rotateX(0deg); }
  .data-sorter .arrow-up {
    transform: rotateX(180deg); }
    .data-sorter .arrow-up g {
      fill: #898989; }

.sorter-dropdwon-item .active {
  font-weight: 500;
  color: #000000a6; }

.mav-filter-wraper-neg {
  overflow: hidden;
  box-shadow: 0px 2px 8px #00000014;
  margin-bottom: 48px;
  position: relative; }
  .mav-filter-wraper-neg * {
    letter-spacing: 0.032em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer !important; }
  .mav-filter-wraper-neg .lock-container svg {
    fill: #6e6b6b; }
  .mav-filter-wraper-neg .ant-select-search.ant-select-search--inline {
    width: 30px;
    margin-top: 5px;
    color: #1268fb; }
  .mav-filter-wraper-neg .ant-select-selection__choice__content {
    max-width: 30px; }
  .mav-filter-wraper-neg .ant-select-selection__rendered {
    height: 40px;
    margin-bottom: 0px;
    padding-left: 116px !important; }
    .mav-filter-wraper-neg .ant-select-selection__rendered .ant-select-selection__choice {
      max-width: 30px;
      position: relative;
      margin-top: 8px;
      color: #1268fb;
      font-weight: 500;
      font-size: 12px;
      background: none;
      border: 1px solid transparent;
      z-index: 10;
      margin-right: 2px;
      padding: 0 2px !important; }
      .mav-filter-wraper-neg .ant-select-selection__rendered .ant-select-selection__choice span {
        display: none; }
    .mav-filter-wraper-neg .ant-select-selection__rendered li:nth-last-child(2) {
      position: relative; }
      .mav-filter-wraper-neg .ant-select-selection__rendered li:nth-last-child(2) ::after {
        content: "";
        position: absolute;
        right: -1px;
        top: 1px; }
    .mav-filter-wraper-neg .ant-select-selection__rendered .ant-select-search__field {
      margin-top: 3.4px;
      font-size: 12px; }
  .mav-filter-wraper-neg .ant-select-selection__choice {
    position: relative; }
    .mav-filter-wraper-neg .ant-select-selection__choice ::after {
      content: ",";
      position: absolute;
      right: -1px;
      top: 1px; }
  .mav-filter-wraper-neg .ant-select-selection__choice:last-of-type {
    position: relative;
    display: none !important; }
    .mav-filter-wraper-neg .ant-select-selection__choice:last-of-type ::after {
      content: "--";
      position: absolute;
      right: -1px;
      top: 1px; }
  .mav-filter-wraper-neg .ant-select-selection-item {
    display: none !important; }
  .mav-filter-wraper-neg .custom-text {
    color: "#6E6E6E";
    font-size: 12px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    pointer-events: none; }
    .mav-filter-wraper-neg .custom-text span {
      color: #1268fb;
      font-weight: 500;
      margin-left: 4px; }
  .mav-filter-wraper-neg .custom-arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotateX(0);
    right: 12px;
    transition: all 0.3s ease 0s;
    pointer-events: none;
    z-index: 9999; }
  .mav-filter-wraper-neg .custom-arrow-icon.open {
    transition: all 0.35s ease 0s;
    transform: translateY(-50%) rotate(180deg); }
    .mav-filter-wraper-neg .custom-arrow-icon.open g {
      fill: #898989; }
  .mav-filter-wraper-neg .ant-select.ant-select-enabled {
    height: 40px; }
  .mav-filter-wraper-neg .ant-select-selection--multiple {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px; }
    .mav-filter-wraper-neg .ant-select-selection--multiple .ant-select-selection__rendered {
      height: 40px;
      margin-bottom: 0px;
      padding-left: 130px !important; }
      .mav-filter-wraper-neg .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice {
        max-width: 100px;
        position: relative;
        margin-top: 8px;
        color: #1268fb;
        font-weight: 500;
        font-size: 12px;
        background: none;
        border: 1px solid transparent;
        z-index: 10;
        margin-right: 2px;
        padding: 0 2px !important; }
        .mav-filter-wraper-neg .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice span {
          display: none; }
      .mav-filter-wraper-neg .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-search__field {
        margin-top: 3.4px;
        font-size: 12px; }
  .mav-filter-wraper-neg .ant-select-search.ant-select-search--inline {
    width: 50px;
    margin-top: 5px;
    color: #1268fb; }
  .mav-filter-wraper-neg .ant-checkbox.ant-checkbox-checked {
    display: none; }

.lock-mav {
  opacity: 0.5;
  border: 2px solid transparent; }

.lock-mav:hover {
  border: 2px solid #898989b3; }

.mav-filter-wraper-neg.custom-padding .ant-select-search.ant-select-search--inline {
  width: 30px;
  margin-top: 5px;
  color: #1268fb; }

.mav-filter-wraper-neg.custom-padding .ant-select-selection__rendered {
  height: 40px;
  margin-bottom: 0px;
  padding-left: 164px !important; }
  .mav-filter-wraper-neg.custom-padding .ant-select-selection__rendered .ant-select-selection__choice {
    max-width: 100px;
    position: relative;
    margin-top: 8px;
    color: #1268fb;
    font-weight: 500;
    font-size: 12px;
    background: none;
    border: 1px solid transparent;
    z-index: 10;
    margin-right: 2px;
    padding: 0 2px !important; }
    .mav-filter-wraper-neg.custom-padding .ant-select-selection__rendered .ant-select-selection__choice span {
      display: none; }
  .mav-filter-wraper-neg.custom-padding .ant-select-selection__rendered li:nth-last-child(2) {
    position: relative; }
    .mav-filter-wraper-neg.custom-padding .ant-select-selection__rendered li:nth-last-child(2) ::after {
      content: "";
      position: absolute;
      right: -1px;
      top: 1px; }
  .mav-filter-wraper-neg.custom-padding .ant-select-selection__rendered .ant-select-search__field {
    margin-top: 3.4px;
    font-size: 12px; }

.mav-filter-wraper-neg.custom-padding .ant-select-selection__choice {
  position: relative; }
  .mav-filter-wraper-neg.custom-padding .ant-select-selection__choice ::after {
    content: ",";
    position: absolute;
    right: -1px;
    top: 1px; }

.mav-filter-wraper-neg.custom-padding .ant-select-selection__choice:last-of-type {
  position: relative;
  display: none !important; }
  .mav-filter-wraper-neg.custom-padding .ant-select-selection__choice:last-of-type ::after {
    content: "--";
    position: absolute;
    right: -1px;
    top: 1px; }

.mav-filter-overlay * {
  letter-spacing: 0.032em; }

.mav-filter-overlay .ant-select-dropdown-menu-item-disabled i {
  display: none; }

.mav-filter-overlay .ant-select-dropdown-menu-item-group-title {
  font-weight: 500 !important;
  color: #212121 !important; }

.mav-filter-overlay .ant-select-dropdown-menu-item {
  color: #898989;
  min-height: 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #cecece;
  padding-left: 40px; }
  .mav-filter-overlay .ant-select-dropdown-menu-item i {
    position: absolute;
    left: 16px !important;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #e3e3e3;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .mav-filter-overlay .ant-select-dropdown-menu-item i svg {
      font-size: 12px; }

.mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  color: #212121;
  font-weight: 500; }
  .mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i {
    background-color: #2bc822;
    border-color: #2bc822 !important;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i svg {
      color: #fff;
      stroke: 2px; }

.mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled i {
  display: none; }

.mav-filter-overlay .ant-select-dropdown-menu-item:hover {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe; }
  .mav-filter-overlay .ant-select-dropdown-menu-item:hover svg {
    color: #898989; }

.mav-filter-overlay .select-divider {
  background-color: #898989 !important;
  min-height: 1px !important;
  padding: 0px !important;
  pointer-events: none !important; }
  .mav-filter-overlay .select-divider i {
    display: none !important; }

.overlay-footer {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .overlay-footer button {
    height: 26px !important;
    padding: 2px 8px !important; }
  .overlay-footer span {
    font-size: 12px; }

.mav-filter-wraper {
  overflow: hidden;
  box-shadow: 0px 2px 8px #00000014;
  margin-bottom: 48px;
  position: relative; }
  .mav-filter-wraper .lock-container svg {
    fill: #6e6b6b; }
  .mav-filter-wraper * {
    letter-spacing: 0.032em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer !important; }
  .mav-filter-wraper .ant-select-search.ant-select-search--inline {
    width: 30px;
    margin-top: 5px;
    color: #1268fb; }
  .mav-filter-wraper .ant-select-selection__choice__content {
    max-width: 30px; }
  .mav-filter-wraper .ant-select-selection__rendered {
    height: 40px;
    margin-bottom: 0px;
    padding-left: 116px !important; }
    .mav-filter-wraper .ant-select-selection__rendered .ant-select-selection__choice {
      max-width: 50px;
      position: relative;
      margin-top: 8px;
      color: #1268fb;
      font-weight: 500;
      font-size: 12px;
      background: none;
      border: 1px solid transparent;
      z-index: 10;
      margin-right: 2px;
      padding: 0 2px !important; }
      .mav-filter-wraper .ant-select-selection__rendered .ant-select-selection__choice span {
        display: none; }
    .mav-filter-wraper .ant-select-selection__rendered li:nth-last-child(2) {
      position: relative; }
      .mav-filter-wraper .ant-select-selection__rendered li:nth-last-child(2) ::after {
        content: "";
        position: absolute;
        right: -1px;
        top: 1px; }
    .mav-filter-wraper .ant-select-selection__rendered .ant-select-search__field {
      margin-top: 3.4px;
      font-size: 12px; }
  .mav-filter-wraper .ant-select-selection__choice {
    position: relative; }
    .mav-filter-wraper .ant-select-selection__choice ::after {
      content: ",";
      position: absolute;
      right: -1px;
      top: 1px; }
  .mav-filter-wraper .ant-select-selection__choice:last-of-type {
    position: relative;
    display: none !important; }
    .mav-filter-wraper .ant-select-selection__choice:last-of-type ::after {
      content: "--";
      position: absolute;
      right: -1px;
      top: 1px; }
  .mav-filter-wraper .ant-select-selection-item {
    display: none !important; }
  .mav-filter-wraper .custom-text {
    color: "#6E6E6E";
    font-size: 12px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    pointer-events: none; }
    .mav-filter-wraper .custom-text span {
      color: #1268fb;
      font-weight: 500;
      margin-left: 4px; }
  .mav-filter-wraper .custom-arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotateX(0);
    right: 12px;
    transition: all 0.3s ease 0s;
    pointer-events: none; }
  .mav-filter-wraper .custom-arrow-icon.open {
    transition: all 0.35s ease 0s;
    transform: translateY(-50%) rotate(180deg); }
    .mav-filter-wraper .custom-arrow-icon.open g {
      fill: #898989; }
  .mav-filter-wraper .ant-select.ant-select-enabled {
    height: 40px; }
  .mav-filter-wraper .ant-select-selection--multiple {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px; }
    .mav-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered {
      height: 40px;
      margin-bottom: 0px;
      padding-left: 130px !important; }
      .mav-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice {
        max-width: 100px;
        position: relative;
        margin-top: 8px;
        color: #1268fb;
        font-weight: 500;
        font-size: 12px;
        background: none;
        border: 1px solid transparent;
        z-index: 10;
        margin-right: 2px;
        padding: 0 2px !important; }
        .mav-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice span {
          display: none; }
      .mav-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-search__field {
        margin-top: 3.4px;
        font-size: 12px; }
  .mav-filter-wraper .ant-select-search.ant-select-search--inline {
    width: 50px;
    margin-top: 5px;
    color: #1268fb; }
  .mav-filter-wraper .ant-checkbox.ant-checkbox-checked {
    display: none; }

.lock-mav {
  opacity: 0.5;
  border: 2px solid transparent; }

.lock-mav:hover {
  border: 2px solid #898989b3; }

.mav-filter-wraper.custom-padding .ant-select-search.ant-select-search--inline {
  width: 30px;
  margin-top: 5px;
  color: #1268fb; }

.mav-filter-wraper.custom-padding .ant-select-selection__rendered {
  height: 40px;
  margin-bottom: 0px;
  padding-left: 164px !important; }
  .mav-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-selection__choice {
    max-width: 100px;
    position: relative;
    margin-top: 8px;
    color: #1268fb;
    font-weight: 500;
    font-size: 12px;
    background: none;
    border: 1px solid transparent;
    z-index: 10;
    margin-right: 2px;
    padding: 0 2px !important; }
    .mav-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-selection__choice span {
      display: none; }
  .mav-filter-wraper.custom-padding .ant-select-selection__rendered li:nth-last-child(2) {
    position: relative; }
    .mav-filter-wraper.custom-padding .ant-select-selection__rendered li:nth-last-child(2) ::after {
      content: "";
      position: absolute;
      right: -1px;
      top: 1px; }
  .mav-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-search__field {
    margin-top: 3.4px;
    font-size: 12px; }

.mav-filter-wraper.custom-padding .ant-select-selection__choice {
  position: relative; }
  .mav-filter-wraper.custom-padding .ant-select-selection__choice ::after {
    content: ",";
    position: absolute;
    right: -1px;
    top: 1px; }

.mav-filter-wraper.custom-padding .ant-select-selection__choice:last-of-type {
  position: relative;
  display: none !important; }
  .mav-filter-wraper.custom-padding .ant-select-selection__choice:last-of-type ::after {
    content: "--";
    position: absolute;
    right: -1px;
    top: 1px; }

.mav-filter-overlay * {
  letter-spacing: 0.032em; }

.mav-filter-overlay .ant-select-dropdown-menu-item-disabled i {
  display: none; }

.mav-filter-overlay .ant-select-dropdown-menu-item {
  color: #898989;
  min-height: 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #cecece;
  padding-left: 40px; }
  .mav-filter-overlay .ant-select-dropdown-menu-item i {
    position: absolute;
    left: 16px !important;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #e3e3e3;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .mav-filter-overlay .ant-select-dropdown-menu-item i svg {
      font-size: 12px; }

.mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  color: #212121;
  font-weight: 500; }
  .mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i {
    background-color: #2bc822;
    border-color: #2bc822 !important;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i svg {
      color: #fff;
      stroke: 2px; }

.mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled i {
  display: none; }

.mav-filter-overlay .ant-select-dropdown-menu-item:hover {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe; }
  .mav-filter-overlay .ant-select-dropdown-menu-item:hover svg {
    color: #898989; }

.mav-filter-overlay .select-divider {
  background-color: #898989 !important;
  min-height: 1px !important;
  padding: 0px !important;
  pointer-events: none !important; }
  .mav-filter-overlay .select-divider i {
    display: none !important; }

.overlay-footer {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .overlay-footer button {
    height: 26px !important;
    padding: 2px 8px !important; }
  .overlay-footer span {
    font-size: 12px; }

.tag-filter-wraper {
  overflow: hidden;
  box-shadow: 0px 2px 8px #00000014;
  margin-bottom: 48px;
  position: relative; }
  .tag-filter-wraper * {
    letter-spacing: 0.032em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer !important; }
  .tag-filter-wraper .ant-select-search.ant-select-search--inline {
    width: 30px;
    margin-top: 5px;
    color: #1268fb; }
  .tag-filter-wraper .ant-select-selection__choice__content {
    max-width: 60px; }
  .tag-filter-wraper .ant-select-selection__rendered {
    height: 40px;
    margin-bottom: 0px;
    padding-left: 116px !important; }
    .tag-filter-wraper .ant-select-selection__rendered .ant-select-selection__choice {
      max-width: 60px;
      position: relative;
      margin-top: 8px;
      color: #1268fb;
      font-weight: 500;
      font-size: 12px;
      background: none;
      border: 1px solid transparent;
      z-index: 10;
      margin-right: 2px;
      padding: 0 2px !important; }
      .tag-filter-wraper .ant-select-selection__rendered .ant-select-selection__choice span {
        display: none; }
    .tag-filter-wraper .ant-select-selection__rendered li:nth-last-child(2) {
      position: relative; }
      .tag-filter-wraper .ant-select-selection__rendered li:nth-last-child(2) ::after {
        content: "";
        position: absolute;
        right: -1px;
        top: 1px; }
    .tag-filter-wraper .ant-select-selection__rendered .ant-select-search__field {
      margin-top: 3.4px;
      font-size: 12px; }
  .tag-filter-wraper .ant-select-selection__choice {
    position: relative; }
    .tag-filter-wraper .ant-select-selection__choice ::after {
      content: ",";
      position: absolute;
      right: -1px;
      top: 1px; }
  .tag-filter-wraper .ant-select-selection__choice:last-of-type {
    position: relative;
    display: none !important; }
    .tag-filter-wraper .ant-select-selection__choice:last-of-type ::after {
      content: "--";
      position: absolute;
      right: -1px;
      top: 1px; }
  .tag-filter-wraper .ant-select-selection-item {
    display: none !important; }
  .tag-filter-wraper .custom-text {
    color: "#6E6E6E";
    font-size: 12px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    pointer-events: none; }
    .tag-filter-wraper .custom-text span {
      color: #1268fb;
      font-weight: 500;
      margin-left: 4px; }
  .tag-filter-wraper .custom-arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotateX(0);
    right: 12px;
    transition: all 0.3s ease 0s;
    pointer-events: none; }
  .tag-filter-wraper .custom-arrow-icon.open {
    transition: all 0.35s ease 0s;
    transform: translateY(-50%) rotate(180deg); }
    .tag-filter-wraper .custom-arrow-icon.open g {
      fill: #898989; }
  .tag-filter-wraper .ant-select.ant-select-enabled {
    height: 40px; }
  .tag-filter-wraper .ant-select-selection--multiple {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px; }
    .tag-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered {
      height: 40px;
      margin-bottom: 0px;
      padding-left: 100px !important; }
      .tag-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice {
        max-width: 100px;
        position: relative;
        margin-top: 8px;
        color: #1268fb;
        font-weight: 500;
        font-size: 12px;
        background: none;
        border: 1px solid transparent;
        z-index: 10;
        margin-right: 2px;
        padding: 0 2px !important; }
        .tag-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice span {
          display: none; }
      .tag-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-search__field {
        margin-top: 3.4px;
        font-size: 12px; }
  .tag-filter-wraper .ant-select-search.ant-select-search--inline {
    width: 50px;
    margin-top: 5px;
    color: #1268fb; }
  .tag-filter-wraper .ant-checkbox.ant-checkbox-checked {
    display: none; }

.tag-filter-wraper.custom-padding .ant-select-search.ant-select-search--inline {
  width: 30px;
  margin-top: 5px;
  color: #1268fb; }

.tag-filter-wraper.custom-padding .ant-select-selection__rendered {
  height: 40px;
  margin-bottom: 0px;
  padding-left: 164px !important; }
  .tag-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-selection__choice {
    max-width: 100px;
    position: relative;
    margin-top: 8px;
    color: #1268fb;
    font-weight: 500;
    font-size: 12px;
    background: none;
    border: 1px solid transparent;
    z-index: 10;
    margin-right: 2px;
    padding: 0 2px !important; }
    .tag-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-selection__choice span {
      display: none; }
  .tag-filter-wraper.custom-padding .ant-select-selection__rendered li:nth-last-child(2) {
    position: relative; }
    .tag-filter-wraper.custom-padding .ant-select-selection__rendered li:nth-last-child(2) ::after {
      content: "";
      position: absolute;
      right: -1px;
      top: 1px; }
  .tag-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-search__field {
    margin-top: 3.4px;
    font-size: 12px; }

.tag-filter-wraper.custom-padding .ant-select-selection__choice {
  position: relative; }
  .tag-filter-wraper.custom-padding .ant-select-selection__choice ::after {
    content: ",";
    position: absolute;
    right: -1px;
    top: 1px; }

.tag-filter-wraper.custom-padding .ant-select-selection__choice:last-of-type {
  position: relative;
  display: none !important; }
  .tag-filter-wraper.custom-padding .ant-select-selection__choice:last-of-type ::after {
    content: "--";
    position: absolute;
    right: -1px;
    top: 1px; }

.mav-filter-overlay * {
  letter-spacing: 0.032em; }

.mav-filter-overlay .ant-select-dropdown-menu-item-disabled i {
  display: none; }

.mav-filter-overlay .ant-select-dropdown-menu-item {
  color: #898989;
  min-height: 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #cecece;
  padding-left: 40px; }
  .mav-filter-overlay .ant-select-dropdown-menu-item i {
    position: absolute;
    left: 16px !important;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #e3e3e3;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .mav-filter-overlay .ant-select-dropdown-menu-item i svg {
      font-size: 12px; }

.mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  color: #212121;
  font-weight: 500; }
  .mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i {
    background-color: #2bc822;
    border-color: #2bc822 !important;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i svg {
      color: #fff;
      stroke: 2px; }

.mav-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled i {
  display: none; }

.mav-filter-overlay .ant-select-dropdown-menu-item:hover {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe; }
  .mav-filter-overlay .ant-select-dropdown-menu-item:hover svg {
    color: #898989; }

.mav-filter-overlay .select-divider {
  background-color: #898989 !important;
  min-height: 1px !important;
  padding: 0px !important;
  pointer-events: none !important; }
  .mav-filter-overlay .select-divider i {
    display: none !important; }

.overlay-footer {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .overlay-footer button {
    height: 26px !important;
    padding: 2px 8px !important; }
  .overlay-footer span {
    font-size: 12px; }

.bulk-search-modal .heading-cointainer {
  display: flex;
  justify-content: space-between; }

.bulk-search-modal .ant-modal-body p.input-label {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  margin-bottom: 5px; }

.bulk-search-modal .ant-modal-body input:first-of-type {
  margin-bottom: 8px; }

.bulk-search-modal .ant-modal-body .bulk-import .icon-container {
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #979797;
  border-radius: 4px;
  color: #979797;
  text-align: center;
  cursor: pointer; }
  .bulk-search-modal .ant-modal-body .bulk-import .icon-container i svg {
    width: 5rem;
    height: 5rem; }

.bulk-search-modal .ant-modal-body .bulk-import .icon-container:hover {
  border-color: #5e73ff;
  color: #5e73ff; }

.bulk-search-modal .ant-modal-content {
  border-radius: 2px; }

.bulk-search-modal .ant-modal-title {
  margin: 0;
  color: #212121;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 8px; }

.bulk-search-modal .ant-modal-close {
  display: none; }

.bulk-search-modal .ant-modal-header {
  margin: 0 auto;
  padding: 16px 0px;
  border-bottom: 1px solid #cecece;
  width: 432px; }

.bulk-search-modal .ant-modal-body {
  padding: 16px 24px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-weight: 400; }

.bulk-search-modal .or-txt {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-weight: 400;
  margin-bottom: 8px; }

.bulk-search-modal .or-txt2 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 8px; }

.bulk-search-modal .ant-input {
  background-image: none !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: #f5f5f5;
  color: #212121;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-size: 12px;
  padding: 8px;
  height: 120px; }

.bulk-search-modal .ant-input::placeholder {
  font-size: 12px;
  font-weight: 400px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #898989; }

.bulk-search-modal .ant-modal-footer {
  padding: 16px 0px;
  text-align: right;
  border-top: 1px solid #cecece;
  width: 432px;
  margin: 0 auto; }

.bulk-search-modal .add-products-bulk-link {
  margin-top: 16px;
  cursor: pointer;
  position: relative; }
  .bulk-search-modal .add-products-bulk-link a {
    color: #fd104d; }

.bulk-search-modal .add-products-bulk-link:after {
  content: "";
  position: absolute;
  width: 30%;
  transform: scaleX(0);
  height: 1px;
  bottom: -2px;
  left: 2px;
  background-color: #e61047;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out; }

.bulk-search-modal .add-products-bulk-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left; }

.bulk-search-modal .bulk-search-btn {
  background: #2bc822;
  border: none;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px; }

.bulk-search-modal .disabled-bulk-search-btn {
  background: #94e38f;
  border: none;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px;
  pointer-events: none; }

.bulk-search-modal .bulk-search-cancel-modal {
  border: 1px solid #898989;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px;
  color: #898989; }

.bulk-search-modal .arrow-right {
  margin-left: 5px; }

.bulk-search-modal .bulk-search .bulk-search-radio-btn {
  margin-top: -16px; }

.bulk-search-modal .bulk-search .label-text-enabled {
  color: #212121;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.bulk-search-modal .bulk-search .label-text-disabled {
  color: #898989;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.bulk-search-modal .bulk-search .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  background-color: #36c626; }

.bulk-search-modal .bulk-search .ant-radio-inner::after {
  background-color: #fff; }

.bulk-search-modal .bulk-search .ant-radio-wrapper:hover .ant-radio,
.bulk-search-modal .bulk-search .ant-radio:hover .ant-radio-inner,
.bulk-search-modal .bulk-search .ant-radio-input:focus + .ant-radio-inner {
  border-color: #36c626; }

.bulk-search-modal .bulk-search .ant-radio-checked .ant-radio-inner {
  border-color: #36c626; }

.bulk-search-modal .clear-data {
  float: right;
  margin-top: -12px;
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
  margin-top: -5px;
  font-weight: 400;
  color: #898989; }

.bulk-search-modal .bulk-search-txt {
  margin-bottom: 4px;
  letter-spacing: 0.032em;
  line-height: 22px; }

.bulk-search-modal .clear-data:hover {
  float: right;
  margin-top: -12px;
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
  margin-top: -5px;
  font-weight: 500;
  color: #212121;
  text-decoration: underline !important; }

.bulk-place-modal .anticon-info-circle {
  left: 12px; }

.bulk-place-modal .ant-alert-info {
  padding-left: 48px; }

.bulk-place-modal svg {
  transform: scale(0.75); }

.bulk-place-modal .heading-cointainer {
  display: flex;
  justify-content: space-between; }

.bulk-place-modal .ant-modal-body p.input-label {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  margin-bottom: 5px; }

.bulk-place-modal .ant-modal-body input:first-of-type {
  margin-bottom: 8px; }

.bulk-place-modal .ant-modal-body .bulk-import .icon-container {
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #979797;
  border-radius: 4px;
  color: #979797;
  text-align: center;
  cursor: pointer; }
  .bulk-place-modal .ant-modal-body .bulk-import .icon-container i svg {
    width: 5rem;
    height: 5rem; }

.bulk-place-modal .ant-modal-body .bulk-import .icon-container:hover {
  border-color: #5e73ff;
  color: #5e73ff; }

.bulk-place-modal .ant-modal-content {
  border-radius: 2px; }

.bulk-place-modal .ant-modal-title {
  margin: 0;
  color: #212121;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 8px; }

.bulk-place-modal .ant-modal-close {
  display: none; }

.bulk-place-modal .ant-modal-header {
  margin: 0 auto;
  padding: 16px 0px;
  border-bottom: 1px solid #cecece;
  width: 432px; }

.bulk-place-modal .ant-modal-body {
  padding: 16px 24px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-weight: 400; }

.bulk-place-modal .or-txt {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-weight: 400;
  margin-bottom: 8px; }

.bulk-place-modal .or-txt2 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 8px; }

.bulk-place-modal .ant-input {
  background-image: none !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: #f5f5f5;
  color: #212121;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-size: 12px;
  padding: 8px;
  height: 120px; }

.bulk-place-modal .ant-input::placeholder {
  font-size: 12px;
  font-weight: 400px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #898989; }

.bulk-place-modal .ant-modal-footer {
  padding: 16px 0px;
  text-align: right;
  border-top: 1px solid #cecece;
  width: 432px;
  margin: 0 auto; }

.bulk-place-modal .add-products-bulk-link {
  margin-top: 16px;
  cursor: pointer;
  position: relative; }
  .bulk-place-modal .add-products-bulk-link a {
    color: #fd104d; }

.bulk-place-modal .add-products-bulk-link:after {
  content: "";
  position: absolute;
  width: 30%;
  transform: scaleX(0);
  height: 1px;
  bottom: -2px;
  left: 2px;
  background-color: #e61047;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out; }

.bulk-place-modal .add-products-bulk-link:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left; }

.bulk-place-modal .bulk-search-btn {
  background: #2bc822;
  border: none;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px; }

.bulk-place-modal .disabled-bulk-search-btn {
  background: #94e38f;
  border: none;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px;
  pointer-events: none; }

.bulk-place-modal .bulk-search-cancel-modal {
  border: 1px solid #898989;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px;
  color: #898989; }

.bulk-place-modal .arrow-right {
  margin-left: 5px; }

.bulk-place-modal .bulk-search .bulk-search-radio-btn {
  margin-top: -16px; }

.bulk-place-modal .bulk-search .label-text-enabled {
  color: #212121;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.bulk-place-modal .bulk-search .label-text-disabled {
  color: #898989;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.bulk-place-modal .bulk-search .ant-radio-wrapper.ant-radio-wrapper-checked .ant-radio-inner {
  background-color: #36c626; }

.bulk-place-modal .bulk-search .ant-radio-inner::after {
  background-color: #fff; }

.bulk-place-modal .bulk-search .ant-radio-wrapper:hover .ant-radio,
.bulk-place-modal .bulk-search .ant-radio:hover .ant-radio-inner,
.bulk-place-modal .bulk-search .ant-radio-input:focus + .ant-radio-inner {
  border-color: #36c626; }

.bulk-place-modal .bulk-search .ant-radio-checked .ant-radio-inner {
  border-color: #36c626; }

.bulk-place-modal .clear-data {
  float: right;
  margin-top: -12px;
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
  margin-top: -5px;
  font-weight: 400;
  color: #898989; }

.bulk-place-modal .bulk-search-txt {
  margin-bottom: 12px;
  letter-spacing: 0.032em;
  line-height: 22px; }

.bulk-place-modal .clear-data:hover {
  float: right;
  margin-top: -12px;
  background: transparent;
  border: none;
  font-size: 12px;
  cursor: pointer;
  margin-top: -5px;
  font-weight: 500;
  color: #212121;
  text-decoration: underline !important; }

.redirect-kyc .ant-modal-content {
  border-radius: 2px;
  padding: 24px 24px 0 24px; }
  .redirect-kyc .ant-modal-content .ant-modal-header {
    padding: 0; }
    .redirect-kyc .ant-modal-content .ant-modal-header .icon-container {
      position: relative;
      top: 7px;
      margin-right: 6px; }
    .redirect-kyc .ant-modal-content .ant-modal-header .ant-modal-title {
      padding: 0 0 8px; }
      .redirect-kyc .ant-modal-content .ant-modal-header .ant-modal-title .heading-main {
        font-size: 16px;
        font-weight: 500; }
  .redirect-kyc .ant-modal-content .ant-modal-body {
    padding: 16px 0; }
    .redirect-kyc .ant-modal-content .ant-modal-body .steps-kyc-modal {
      font-size: 12px;
      color: #898989; }
      .redirect-kyc .ant-modal-content .ant-modal-body .steps-kyc-modal .upperheading {
        color: #898989;
        margin-bottom: 24px; }
      .redirect-kyc .ant-modal-content .ant-modal-body .steps-kyc-modal .sub-heading {
        margin-bottom: 5px; }
      .redirect-kyc .ant-modal-content .ant-modal-body .steps-kyc-modal .step {
        font-weight: 600; }
      .redirect-kyc .ant-modal-content .ant-modal-body .steps-kyc-modal .footer-info {
        margin-top: 5px; }
  .redirect-kyc .ant-modal-content .ant-modal-footer {
    padding: 16px 0; }
    .redirect-kyc .ant-modal-content .ant-modal-footer .clickable-green {
      background-color: #2bc822;
      color: #ffffff;
      border-radius: 2px !important; }
    .redirect-kyc .ant-modal-content .ant-modal-footer .clickable-green:hover {
      border: 1px solid #2bc822; }

.basic-details-container {
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #898989;
  line-height: 16px;
  padding: 48px; }
  .basic-details-container p {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    line-height: 16px; }
  .basic-details-container .ant-card-body {
    padding: 0px !important;
    background-color: #f6f6f6 !important; }
  .basic-details-container .ant-tabs-tab {
    margin: 0px !important;
    text-align: left !important;
    padding: 12px 32px !important;
    font-size: 12px; }
  .basic-details-container .tabs-nav-wrap {
    height: 100vh !important; }
  .basic-details-container .ant-tabs-left-content {
    background-color: #fff;
    border-left: none;
    padding: 40px 40px 40px 40px; }
  .basic-details-container .ant-tabs .ant-tabs-left-bar {
    border-right: none;
    margin-right: 0px;
    padding-top: 8px;
    width: 200px; }
  .basic-details-container .ant-tabs-ink-bar {
    width: 0px !important;
    background-color: none !important;
    height: 0px !important; }
  .basic-details-container .ant-tabs-nav .ant-tabs-tab-active {
    color: #212121;
    font-weight: 400;
    background: #fff; }
  .basic-details-container .ant-form-item-required::before {
    display: none; }
  .basic-details-container .fname {
    background: #f5f5f5;
    display: block;
    margin-bottom: 12px;
    padding: 8px 16px;
    font-size: 12px;
    margin-top: 5px;
    color: #212121;
    height: 32px;
    text-transform: capitalize; }
  .basic-details-container .basic-detail-field {
    background: #f5f5f5;
    display: block;
    margin-bottom: 12px;
    padding: 8px 16px;
    font-size: 12px;
    margin-top: 5px;
    color: #212121;
    height: 32px; }
  .basic-details-container .ant-divider-horizontal {
    margin: 16px 0; }
  .basic-details-container .ant-input-lg {
    height: unset;
    padding: 7px 16px;
    font-size: 12px;
    line-height: 16px;
    color: #212121;
    border: 1px solid #f5f5f5;
    background: #f5f5f5;
    border-radius: 0px; }
  .basic-details-container .ant-btn-lg {
    height: unset;
    padding: 8px 16px;
    border-radius: 2px;
    margin-top: 24px;
    font-size: 12px; }
  .basic-details-container .ant-input .has-error .ant-input-affix-wrapper .ant-input:hover {
    background-color: #f5f5f5; }
  .basic-details-container .ant-input .has-error .ant-input-affix-wrapper .ant-input-lg {
    border: 1px solid #f5222d; }
  .basic-details-container .has-error .ant-form-explain {
    font-size: 12px; }
  .basic-details-container .ant-form-item-label {
    line-height: 16px; }
  .basic-details-container .ant-form-item label {
    font-size: 12px;
    color: #898989;
    line-height: 16px; }
  .basic-details-container .ant-form-item {
    margin: 0px; }
  .basic-details-container .ant-btn-primary {
    background-color: #2bc822 !important;
    border-color: #2bc822 !important; }
  .basic-details-container .clickablebtn2 {
    background-color: #94e38f !important;
    border-color: #94e38f !important; }

.advanceDetailContainer {
  padding: 48px;
  border-radius: 4px; }
  .advanceDetailContainer .i-j svg {
    height: 12px; }
  .advanceDetailContainer .fl {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .advanceDetailContainer .fl p {
      color: #212121; }
      .advanceDetailContainer .fl p span {
        margin-left: 4px !important; }
    .advanceDetailContainer .fl .right-gstdata-org-id {
      display: flex;
      align-items: center; }
      .advanceDetailContainer .fl .right-gstdata-org-id .gst-enabled-txt {
        margin-right: 8px;
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        letter-spacing: 0.032em; }
      .advanceDetailContainer .fl .right-gstdata-org-id .border-line {
        margin-right: 8px;
        color: #b2b2b2; }
      .advanceDetailContainer .fl .right-gstdata-org-id .right-org-data {
        display: flex;
        align-items: center; }
        .advanceDetailContainer .fl .right-gstdata-org-id .right-org-data .gst-enabled-gst {
          height: 100%;
          margin-top: 2px; }
  .advanceDetailContainer .edit-fields {
    color: #fd115e;
    margin-left: 8px;
    font-size: 12px; }
  .advanceDetailContainer .save-change {
    background: #31c30f;
    margin-right: 8px;
    font-size: 12px;
    line-height: 16px;
    padding: 8px 20px;
    border: none;
    border-radius: 3px;
    letter-spacing: 0.032em;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 3px;
    color: white;
    cursor: pointer; }
  .advanceDetailContainer .save-change-data {
    color: #2bc822;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px; }
  .advanceDetailContainer .save-change-data svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-top: -2px;
    vertical-align: middle; }
  .advanceDetailContainer .cancle-edit-data {
    width: 120px;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer; }
  .advanceDetailContainer .cancle-edit-data svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    margin-top: -2px;
    vertical-align: middle; }
  .advanceDetailContainer .kyc-blur-container a,
  .advanceDetailContainer .bank-detail-blur-container a {
    width: 100%; }
  .advanceDetailContainer .kyc-blur-container button,
  .advanceDetailContainer .bank-detail-blur-container button {
    min-width: 150px; }
  .advanceDetailContainer .orgination-data-edit {
    border: 1px solid #898989;
    margin-bottom: 0;
    line-height: 16px;
    color: #212121;
    font-weight: 400;
    font-size: 12px;
    padding: 7px 16px;
    background: #f5f5f5;
    width: 100%;
    min-height: 32px;
    outline: none; }
  .advanceDetailContainer .cancle-edit {
    width: 120px;
    margin-left: 16px;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer; }
  .advanceDetailContainer .org-data {
    display: flex;
    justify-content: space-between;
    min-height: 22px; }
  .advanceDetailContainer .kyc-data {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    cursor: pointer; }
    .advanceDetailContainer .kyc-data .clickable {
      cursor: pointer;
      background: #0068ff;
      color: white;
      border: none;
      height: 40px;
      padding: 4px 16px;
      border-radius: 2px;
      font-size: 12px; }
    .advanceDetailContainer .kyc-data .disbalebtn {
      background: #a2c7ff;
      color: white;
      border: none;
      height: 40px;
      padding: 4px 16px;
      border-radius: 2px;
      font-size: 12px; }
  .advanceDetailContainer .banking-data {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    cursor: pointer; }
  .advanceDetailContainer .field {
    flex: 1 1;
    margin-right: 8px; }
    .advanceDetailContainer .field .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px; }
    .advanceDetailContainer .field .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize; }
  .advanceDetailContainer .field1 {
    flex: 1 1;
    margin-left: 8px; }
    .advanceDetailContainer .field1 .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px; }
    .advanceDetailContainer .field1 .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize; }
  .advanceDetailContainer .field2 {
    margin-right: 8px;
    flex: 1 1; }
    .advanceDetailContainer .field2 .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px; }
    .advanceDetailContainer .field2 .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize; }
  .advanceDetailContainer .field3 {
    margin-left: 8px;
    margin-right: 8px;
    flex: 1 1; }
    .advanceDetailContainer .field3 .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px; }
    .advanceDetailContainer .field3 .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize; }
  .advanceDetailContainer .field4 {
    margin-left: 8px;
    margin-right: 8px;
    flex: 1 1; }
    .advanceDetailContainer .field4 .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px; }
    .advanceDetailContainer .field4 .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize; }
  .advanceDetailContainer .field5 {
    margin-left: 8px;
    flex: 1 1; }
    .advanceDetailContainer .field5 .fieldLabel {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-bottom: 8px; }
    .advanceDetailContainer .field5 .fieldvalue {
      margin-bottom: 0;
      line-height: 16px;
      color: #212121;
      font-weight: 400;
      font-size: 12px;
      padding: 7px 16px;
      background: #f5f5f5;
      width: 100%;
      min-height: 32px;
      border: 1px solid #f5f5f5;
      text-transform: capitalize; }
  .advanceDetailContainer .orgDetails {
    letter-spacing: 0.032em;
    font-weight: 400; }
    .advanceDetailContainer .orgDetails .orgbasicupperdetail {
      display: flex;
      justify-content: space-between;
      margin: 24px 0 0 0; }
    .advanceDetailContainer .orgDetails .orgbasicadressMiddledetail {
      display: flex;
      justify-content: space-between;
      margin: 16px 0 0 0; }
    .advanceDetailContainer .orgDetails .orgbasicdetailfooter {
      display: flex;
      justify-content: space-between;
      margin: 16px 0 0 0; }
  .advanceDetailContainer .KYCDetails {
    letter-spacing: 0.032em;
    border-top: 1px solid #cecece;
    margin: 24px 0px 0px 0px;
    font-weight: 400;
    position: relative; }
    .advanceDetailContainer .KYCDetails .KYCDetailsBasicdetail {
      display: flex;
      justify-content: space-between;
      margin: 24px 0 0 0; }
    .advanceDetailContainer .KYCDetails .kycmiddledDetail {
      display: flex;
      justify-content: space-between;
      margin: 16px 0 0 0; }
      .advanceDetailContainer .KYCDetails .kycmiddledDetail .flex-1 {
        flex: 1 1;
        margin-bottom: 0;
        line-height: 16px;
        color: #212121;
        font-weight: 400;
        font-size: 12px;
        padding: 8px 16px;
        background: #f5f5f5;
        margin-right: 8px;
        height: 32px; }
      .advanceDetailContainer .KYCDetails .kycmiddledDetail .flex-3 {
        height: 32px;
        flex: 3 1;
        margin-bottom: 0;
        line-height: 16px;
        color: #212121;
        font-weight: 400;
        font-size: 12px;
        padding: 8px 16px;
        background: #f5f5f5; }
  .advanceDetailContainer .bankingdetail {
    letter-spacing: 0.032em;
    border-top: 1px solid #cecece;
    margin: 24px 0px 0px 0px;
    font-weight: 400;
    position: relative; }
    .advanceDetailContainer .bankingdetail .banking {
      margin: 24px 0px 0px 0px; }
    .advanceDetailContainer .bankingdetail .bankingBasicDetail {
      display: flex;
      justify-content: space-between;
      margin: 24px 0 0 0; }
  .advanceDetailContainer .ant-checkbox-inner {
    border-radius: 10px !important; }
  .advanceDetailContainer .org-vfid {
    color: #212121; }

.profilenav .userprofilennav {
  padding-left: 59px;
  padding-top: 100px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .profilenav .userprofilennav .profilefillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
  .profilenav .userprofilennav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .profilenav .userprofilennav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .profilenav .userprofilennav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .profilenav .userprofilennav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .profilenav .userprofilennav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .profilenav .userprofilennav .secondwrap .filtertab .hover-order-underline-animation {
        position: relative; }
      .profilenav .userprofilennav .secondwrap .filtertab .hover-order-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .profilenav .userprofilennav .secondwrap .filtertab .hover-order-underline-animation:hover {
        color: #4398ed !important; }
        .profilenav .userprofilennav .secondwrap .filtertab .hover-order-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .profilenav .userprofilennav .secondwrap .filtertab .hover-order-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .profilenav .userprofilennav .secondwrap .filtertab .hover-order-underline-animation-click {
        position: relative; }
      .profilenav .userprofilennav .secondwrap .filtertab .hover-order-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }

@media screen and (min-width: 1440px) {
  .userprofilennav {
    padding-left: 104px !important;
    padding-top: 100px;
    padding-right: 224px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .userprofilennav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.profile-settings {
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #898989;
  line-height: 16px; }
  .profile-settings p {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    line-height: 16px; }
  .profile-settings .ant-card-body {
    padding: 0px !important;
    background-color: #fff;
    margin-top: 40px;
    border-radius: 4px !important; }
  .profile-settings .ant-tabs-tab {
    margin: 0px !important;
    text-align: left !important;
    padding: 12px 32px !important;
    font-size: 12px; }
  .profile-settings .tabs-nav-wrap {
    height: 100vh !important; }
  .profile-settings .ant-tabs-left-content {
    background-color: #fff;
    border-left: none;
    padding: 35px 40px;
    border-radius: 0px 0px 4px 0px; }
  .profile-settings .ant-tabs .ant-tabs-left-bar {
    border-right: none;
    margin-right: 0px;
    padding-top: 8px;
    width: 200px; }
  .profile-settings .ant-tabs-ink-bar {
    width: 0px !important;
    background-color: none !important;
    height: 0px !important; }
  .profile-settings .ant-tabs-nav .ant-tabs-tab-active {
    color: #212121;
    font-weight: 400;
    background: #fff; }
  .profile-settings .ant-card {
    border-radius: 4px !important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05) !important; }
  .profile-settings .ant-card .ant-card-body {
    border-radius: 0px 0px 4px 4px; }

.store-listing .ant-pagination {
  margin-top: 20px; }

.store-listing .content-page-title {
  margin-bottom: 0px; }

.store-listing .page-subtitle {
  font-size: 16px;
  color: black;
  margin-bottom: 0px; }

.store-listing table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 20px; }
  .store-listing table * {
    line-height: 16px !important; }
  .store-listing table .store-info {
    flex: 1 1; }
    .store-listing table .store-info p {
      font-size: 12px;
      margin-bottom: 0px;
      letter-spacing: 0.032px;
      line-height: 24px;
      color: #212121;
      font-weight: 400; }
  .store-listing table .store-status {
    display: flex;
    align-items: center; }
    .store-listing table .store-status p {
      font-size: 12px;
      margin-bottom: 0px;
      margin-left: 8px;
      letter-spacing: 0.032px;
      line-height: 24px;
      color: #212121;
      font-weight: 500; }
    .store-listing table .store-status .ant-switch-checked {
      background-color: #229d1b; }
  .store-listing table thead tr th {
    font-size: 12px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important;
    letter-spacing: 0.032px;
    line-height: 24px;
    font-weight: 400; }
  .store-listing table tbody {
    background: white; }
    .store-listing table tbody tr td {
      font-size: 12px;
      border-bottom-color: #f2f2f2 !important;
      letter-spacing: 0.032px;
      line-height: 24px; }

.store-listing .ant-switch-small {
  min-width: 32px;
  height: 16px;
  line-height: 14px; }

.store-listing .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px !important; }

.store-listing .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 4px !important; }

.store-listing .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 4px !important; }

.store-listing .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 4px !important; }

.store-listing .ant-table-tbody > tr > td :last-child {
  border-bottom: 0px !important; }

.store-listing .ant-table-tbody > tr > td {
  border-bottom: 0px !important; }

.store-listing .ant-table-tbody > tr {
  border-bottom: 1px solid #cecece !important; }

.store-listing .ant-table-tbody > tr:last-child {
  border-bottom: 0px !important; }

.store-listing .shopify-link {
  font-weight: 500;
  letter-spacing: 0.032px;
  line-height: 24px;
  font-size: 12px; }
  .store-listing .shopify-link a {
    color: #1268fb; }
  .store-listing .shopify-link .add-on {
    font-weight: 500;
    color: #212121;
    font-size: 12px; }

.Re-verify-details {
  font-size: 12px;
  font-weight: 500;
  color: #212121;
  text-decoration: underline !important;
  line-height: 16px;
  margin-bottom: 8px; }

.reverify_geo {
  font-weight: 600 !important;
  text-decoration: underline !important; }

.select-geo .ant-dropdown-menu {
  background-color: #f8f8f8;
  border-radius: 2px; }

.select-geo .ant-dropdown-menu-item {
  height: 40px;
  display: flex;
  align-items: center;
  width: 105px; }

.select-geo .ant-dropdown-menu-item:nth-of-type(1) {
  border-bottom: 1px solid #cecece; }

.select-geo .flex {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .select-geo .flex h3 {
    font-size: 12px;
    color: #898989; }

.storgeo-dropdown {
  display: flex;
  align-items: center; }
  .storgeo-dropdown span {
    font-weight: 500;
    color: #898989; }
  .storgeo-dropdown .arrow {
    transition: 0.3s all; }
  .storgeo-dropdown .arrow-fill {
    transform: rotateX(180deg); }
    .storgeo-dropdown .arrow-fill g {
      fill: #898989; }

.store-geo-modal {
  padding: 0;
  width: 398px !important; }
  .store-geo-modal * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }
  .store-geo-modal .ant-modal-content {
    border-radius: 2px; }
  .store-geo-modal .ant-modal-close-x {
    display: none; }
  .store-geo-modal .ant-modal-body {
    padding: 24px; }
  .store-geo-modal h1 {
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    color: #212121;
    margin-bottom: 5px;
    text-align: center; }
  .store-geo-modal h2 {
    font-size: 12px;
    color: #212121;
    margin: auto;
    text-align: center;
    font-weight: 400; }
  .store-geo-modal h5 {
    font-size: 12px;
    color: #212121;
    margin: auto;
    text-align: center;
    font-weight: 400;
    margin-bottom: 24px; }
    .store-geo-modal h5 span {
      font-weight: 500; }
  .store-geo-modal .content {
    background-color: #f8f8f8;
    padding: 0 16px;
    margin-bottom: 24px; }
    .store-geo-modal .content .div1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 49px; }
      .store-geo-modal .content .div1 p {
        font-size: 12px;
        color: #212121; }
    .store-geo-modal .content .flex {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .store-geo-modal .content .flex h3 {
        font-size: 12px;
        color: #898989; }
  .store-geo-modal .btns {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .store-geo-modal .btns .confirm {
      width: 176px;
      background-color: #2bc822;
      color: white;
      font-size: 16px !important;
      font-weight: 500;
      border-radius: 2px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
    .store-geo-modal .btns .cancel {
      width: 161px;
      background-color: white;
      border: 2px solid #898989;
      color: 898989;
      font-size: 16px;
      font-weight: 500;
      border-radius: 2px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }

.rate-cards-page-cointainer .table-cointainer {
  margin-bottom: 32px; }
  .rate-cards-page-cointainer .table-cointainer .ant-table-tbody > tr > td {
    border-bottom: 1px solid #cecece !important; }
  .rate-cards-page-cointainer .table-cointainer .ant-table-tbody > tr:last-of-type > td {
    border-bottom: none !important; }
  .rate-cards-page-cointainer .table-cointainer .ant-table-tbody > tr:nth-of-type(even) {
    background-color: #f5f5f5; }

.planandbilldetailcontainer {
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #898989;
  padding: 48px; }
  .planandbilldetailcontainer .red-badge {
    background-color: #fc125e;
    color: #fff;
    border-radius: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 13px;
    font-weight: 500;
    padding: 3px 8px; }
  .planandbilldetailcontainer .green-badge {
    background-color: #049d23;
    color: #fff;
    border-radius: 10px;
    font-size: 13px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-weight: 500;
    padding: 3px 8px; }
  .planandbilldetailcontainer .overdue-bar-wraper {
    display: flex;
    align-items: center;
    justify-content: center; }
  .planandbilldetailcontainer .overdue-bar {
    font-size: 13px;
    border-radius: 5px;
    font-weight: 500;
    background-color: #cc0042;
    border: 2px solid #ac0036;
    color: #fff;
    line-height: 20px;
    padding: 12px;
    margin-bottom: 24px;
    display: inline-flex;
    padding-top: 0px;
    padding-bottom: 0px;
    align-items: center;
    grid-gap: 12px;
    gap: 12px; }
    .planandbilldetailcontainer .overdue-bar svg {
      fill: #fff; }
    .planandbilldetailcontainer .overdue-bar .ant-btn span {
      color: #cc0042; }
    .planandbilldetailcontainer .overdue-bar .ant-btn:hover {
      border-color: #ac0036 !important; }
  .planandbilldetailcontainer .credit-card-wraper {
    border-radius: 5px;
    padding: 6px 6px;
    border: 1px solid;
    text-transform: uppercase; }
  .planandbilldetailcontainer .plan-status-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 4px;
    gap: 4px; }
    .planandbilldetailcontainer .plan-status-wrap p {
      font-weight: 500; }
  .planandbilldetailcontainer .uppercontainer {
    display: flex;
    justify-content: space-between; }
    .planandbilldetailcontainer .uppercontainer .currentplan-container {
      border: 1px solid #cecece;
      border-radius: 4px;
      width: 400px;
      padding: 16px; }
      .planandbilldetailcontainer .uppercontainer .currentplan-container > div button {
        width: 100% !important; }
      .planandbilldetailcontainer .uppercontainer .currentplan-container .firstline {
        display: flex;
        color: #212121;
        justify-content: space-between;
        margin-bottom: 18px; }
        .planandbilldetailcontainer .uppercontainer .currentplan-container .firstline .plan {
          color: #4a4be4;
          font-weight: 500;
          text-transform: capitalize; }
      .planandbilldetailcontainer .uppercontainer .currentplan-container .secline {
        text-align: center;
        font-size: 16px;
        margin-bottom: 12px; }
        .planandbilldetailcontainer .uppercontainer .currentplan-container .secline .cost {
          font-size: 28px;
          font-weight: 600;
          color: #212121; }
      .planandbilldetailcontainer .uppercontainer .currentplan-container .free-line {
        text-align: center;
        font-size: 48px;
        margin-top: 26px; }
      .planandbilldetailcontainer .uppercontainer .currentplan-container .free-line-bottom {
        text-align: center;
        font-size: 14px;
        margin-top: 17px;
        margin-bottom: 20px; }
      .planandbilldetailcontainer .uppercontainer .currentplan-container .third-line {
        text-align: center;
        margin-bottom: 16px; }
      .planandbilldetailcontainer .uppercontainer .currentplan-container .forth-line {
        text-align: center;
        margin-bottom: 6px; }
        .planandbilldetailcontainer .uppercontainer .currentplan-container .forth-line .ant-btn {
          width: 190px;
          height: 32px;
          background-color: #0068ff;
          font-size: 14px;
          font-weight: 600;
          border: 1px solid transparent;
          border-radius: 4px !important;
          color: #ffffff; }
        .planandbilldetailcontainer .uppercontainer .currentplan-container .forth-line .ant-btn:hover {
          background-color: #536be8; }
      .planandbilldetailcontainer .uppercontainer .currentplan-container .fifth-line {
        text-align: center; }
        .planandbilldetailcontainer .uppercontainer .currentplan-container .fifth-line .ant-btn {
          width: 190px;
          height: 32px;
          font-size: 14px;
          color: #898989;
          border-radius: 4px !important; }
      .planandbilldetailcontainer .uppercontainer .currentplan-container .six-line {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 12px;
        gap: 12px; }
        .planandbilldetailcontainer .uppercontainer .currentplan-container .six-line .red-btn {
          color: red;
          border-color: red !important; }
        .planandbilldetailcontainer .uppercontainer .currentplan-container .six-line .orange-btn {
          color: orange !important;
          border-color: orange !important; }
        .planandbilldetailcontainer .uppercontainer .currentplan-container .six-line button {
          flex: 1 1 !important;
          font-weight: 500 !important; }
    .planandbilldetailcontainer .uppercontainer .currentplan-container.overdue {
      border-color: #fc125e; }
    .planandbilldetailcontainer .uppercontainer .payment-method-container {
      padding: 16px;
      border: 1px solid #cecece;
      border-radius: 4px;
      width: 440px;
      align-self: flex-start; }
      .planandbilldetailcontainer .uppercontainer .payment-method-container .first-line {
        font-size: 12px;
        font-weight: 500px;
        color: #212121; }
      .planandbilldetailcontainer .uppercontainer .payment-method-container .sec-line {
        padding-bottom: 8px;
        border-bottom: 1px solid #cecece;
        margin-bottom: 16px; }
      .planandbilldetailcontainer .uppercontainer .payment-method-container .details-container {
        color: #212121;
        display: flex; }
        .planandbilldetailcontainer .uppercontainer .payment-method-container .details-container .item-first {
          margin-right: 14px; }
        .planandbilldetailcontainer .uppercontainer .payment-method-container .details-container .item-sec {
          flex: 1 1; }
          .planandbilldetailcontainer .uppercontainer .payment-method-container .details-container .item-sec .sec-1 {
            font-weight: 500; }
          .planandbilldetailcontainer .uppercontainer .payment-method-container .details-container .item-sec .sec-2 {
            font-weight: 500;
            margin-bottom: 9px; }
        .planandbilldetailcontainer .uppercontainer .payment-method-container .details-container .item-third {
          color: #fc125e;
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 4px;
          gap: 4px; }
  .planandbilldetailcontainer table {
    filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.16));
    border-radius: 20px; }
    .planandbilldetailcontainer table * {
      line-height: 16px !important; }
    .planandbilldetailcontainer table .do-btn {
      display: inline-block;
      padding: 6px;
      width: 140px;
      height: 32px;
      background-color: #0068ff;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid transparent;
      border-radius: 2px !important;
      color: #ffffff;
      text-align: center; }
    .planandbilldetailcontainer table .do-btn:hover {
      background-color: #536be8; }
    .planandbilldetailcontainer table .store-info {
      flex: 1 1; }
      .planandbilldetailcontainer table .store-info p {
        font-size: 12px;
        margin-bottom: 0px;
        letter-spacing: 0.032px;
        line-height: 24px;
        color: #212121;
        font-weight: 400; }
    .planandbilldetailcontainer table .store-status {
      display: flex;
      align-items: center; }
      .planandbilldetailcontainer table .store-status p {
        font-size: 12px;
        margin-bottom: 0px;
        margin-left: 8px;
        letter-spacing: 0.032px;
        line-height: 24px;
        color: #212121;
        font-weight: 500; }
      .planandbilldetailcontainer table .store-status .ant-switch-checked {
        background-color: #229d1b; }
    .planandbilldetailcontainer table thead tr th {
      font-size: 12px;
      background: #16273b !important;
      border-radius: 0 !important;
      color: white !important;
      letter-spacing: 0.032px;
      line-height: 24px;
      font-weight: 400; }
    .planandbilldetailcontainer table tbody {
      background: white; }
      .planandbilldetailcontainer table tbody tr td {
        font-size: 12px;
        border-bottom-color: #f2f2f2 !important;
        letter-spacing: 0.032px;
        line-height: 24px; }
  .planandbilldetailcontainer .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 4px !important; }
  .planandbilldetailcontainer .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 4px !important; }
  .planandbilldetailcontainer .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 4px !important; }
  .planandbilldetailcontainer .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 4px !important; }
  .planandbilldetailcontainer .ant-table-tbody > tr > td :last-child {
    border-bottom: 0px !important; }
  .planandbilldetailcontainer .ant-table-tbody > tr > td {
    border-bottom: 0px !important; }
  .planandbilldetailcontainer .ant-table-tbody > tr {
    border-bottom: 1px solid #cecece !important; }
  .planandbilldetailcontainer .ant-table-tbody > tr:last-child {
    border-bottom: 0px !important; }
  .planandbilldetailcontainer .table-cointainer {
    margin-bottom: 32px;
    margin-top: 24px; }
    .planandbilldetailcontainer .table-cointainer .ant-table-tbody > tr > td {
      border-bottom: 1px solid #cecece !important; }
    .planandbilldetailcontainer .table-cointainer .ant-table-tbody > tr:last-of-type > td {
      border-bottom: none !important; }

.bottom-copy {
  text-align: center;
  color: #898989;
  margin-top: 49px; }

.ant-popover-placement-right .ant-popover-inner-content {
  width: 300px !important; }

.edit-payment-modal {
  width: 560px !important; }
  .edit-payment-modal .stripe-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 24px; }
    .edit-payment-modal .stripe-header h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px; }
  .edit-payment-modal .stripe-card-wraper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 32px;
    gap: 32px; }
    .edit-payment-modal .stripe-card-wraper .payment-element-wraper h1 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 8px; }
    .edit-payment-modal .stripe-card-wraper .payment-element-wraper .payment-element {
      transition: 1s all linear !important;
      width: 500px !important;
      min-height: 150px;
      max-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 12px;
      background-color: #f5f5f5 !important; }
      .edit-payment-modal .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper {
        display: flex;
        align-items: center;
        justify-content: center; }
        .edit-payment-modal .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper button {
          height: 40px;
          border: 1px solid #536be8;
          margin-bottom: 8px;
          margin-top: 20px;
          padding-left: 40px;
          padding-right: 40px;
          background-color: #536be8; }
          .edit-payment-modal .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper button i svg {
            fill: #fff !important; }
          .edit-payment-modal .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper button span {
            color: #fff;
            font-size: 14px;
            font-weight: 600; }
    .edit-payment-modal .stripe-card-wraper .payment-element-wraper .secure {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 6px;
      gap: 6px;
      color: #898989;
      font-size: 14px;
      margin-top: 20px; }
    .edit-payment-modal .stripe-card-wraper .preview-card-wraper .plan-card {
      padding-bottom: 14px !important;
      grid-gap: 8px !important;
      gap: 8px !important;
      padding-bottom: 20px; }
      .edit-payment-modal .stripe-card-wraper .preview-card-wraper .plan-card .price-card {
        grid-gap: 0px !important;
        gap: 0px !important; }
      .edit-payment-modal .stripe-card-wraper .preview-card-wraper .plan-card .bottom-bar {
        display: none; }
    .edit-payment-modal .stripe-card-wraper .preview-card-wraper .shaker-card.plan-card {
      margin-top: 0px !important; }
    .edit-payment-modal .stripe-card-wraper .preview-card-wraper .shaker-cards-wraper {
      grid-gap: 8px !important;
      gap: 8px !important; }
    .edit-payment-modal .stripe-card-wraper .preview-card-wraper .change-plan {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px; }
      .edit-payment-modal .stripe-card-wraper .preview-card-wraper .change-plan h1 {
        font-size: 16px;
        font-weight: 600; }
      .edit-payment-modal .stripe-card-wraper .preview-card-wraper .change-plan h2 {
        font-size: 12px;
        font-weight: 400;
        color: #536be8;
        cursor: pointer;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-gap: 4px;
        gap: 4px; }
        .edit-payment-modal .stripe-card-wraper .preview-card-wraper .change-plan h2 svg {
          fill: #536be8; }
  .edit-payment-modal .error-div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: red; }
  .edit-payment-modal .payment-btn-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px; }
  .edit-payment-modal .StripeElement {
    border: 1px solid #f5f5f5;
    margin-bottom: 4px;
    background-color: #fff !important;
    padding: 6px 12px !important; }
  .edit-payment-modal .StripeElement--invalid {
    border: 1px solid red; }

.options-container .select-wrap i {
  color: #898989;
  font-size: 12px; }

.options-container .spn {
  margin-left: 0px;
  font-weight: 400;
  color: #898989; }
  .options-container .spn span {
    font-weight: 400;
    color: #898989; }

.options-container .spn.true {
  margin-left: 0px;
  font-weight: 500;
  color: #212121; }
  .options-container .spn.true span {
    font-weight: 500;
    color: #212121; }

.options-container .ant-checkbox {
  margin-left: 5px; }

.options-container .sub-text {
  margin-left: 29px;
  margin-bottom: 1.5rem;
  color: #898989;
  font-size: 12px; }

.options-container .defaultTime {
  color: #898989;
  margin-top: 24px; }

.options-container .ant-select-selection {
  background-color: #f5f5f5; }

.updated {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center; }
  .updated span {
    font-size: 14px; }

.time-zone-tooltip .anticon svg {
  width: 16px;
  height: 16px;
  margin-left: 5px; }

.time-zone-tooltip .anticon:hover svg #Ellipse_44 {
  stroke: #1268fb;
  fill: #1268fb; }

.time-zone-tooltip .anticon:hover svg #Ellipse_47 {
  stroke: #fff;
  fill: #ffff; }

.time-zone-tooltip .anticon:hover svg #Rectangle_2462 {
  stroke: #fff;
  fill: #ffff; }

.time-zone-dropdown {
  border-radius: 0px;
  height: 40px;
  margin-top: 6px; }
  .time-zone-dropdown .ant-select-selection.ant-select-selection--single {
    border-radius: 0px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1) !important;
    background-color: #f8f8f8; }

.other-settings-container .sub-text {
  margin-top: -0.5rem; }

.other-settings-container .rto-address .ant-form-item-control {
  margin-top: 12px; }
  .other-settings-container .rto-address .ant-form-item-control label {
    font-weight: 500;
    font-size: 12px;
    color: black;
    line-height: 22px; }
  .other-settings-container .rto-address .ant-form-item-control .ant-select-selection {
    border-radius: 0px; }
  .other-settings-container .rto-address .ant-form-item-control .ant-input {
    margin-top: 7px;
    border-radius: 0px !important;
    font-size: 12px; }
  .other-settings-container .rto-address .ant-form-item-control .ant-input::placeholder {
    color: #707070; }

.other-settings-container .rto-address .ant-btn-primary {
  margin-top: 24px; }

.other-settings-container .has-error label {
  color: #f5222d; }

.other-settings-container .options-container p {
  margin-bottom: 0px;
  line-height: 22px;
  font-weight: 500;
  color: #898989; }

.other-settings-container .options-container .ant-btn.ant-btn-link {
  padding: 0px; }

.other-settings-container .options-container .ant-radio-group {
  display: block;
  margin: auto;
  margin-right: 31%;
  width: 300px; }
  .other-settings-container .options-container .ant-radio-group .ant-radio-button-wrapper {
    width: 108px;
    margin-right: 5px;
    border-radius: 2px;
    text-align: center;
    background: #bbbbbb;
    color: white;
    font-size: 12px;
    border: none;
    box-shadow: unset; }

.other-settings-container .options-container .description {
  padding: 12px 35px;
  background: #F5FAFF; }
  .other-settings-container .options-container .description p {
    color: #2b2a2a;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 0px; }

.other-settings-container .options-container .select-location {
  display: flex;
  margin-top: 12px;
  margin-bottom: 24px; }
  .other-settings-container .options-container .select-location .select-location-card.selected {
    border: 2px solid #2ecc39; }
    .other-settings-container .options-container .select-location .select-location-card.selected .select-header span {
      background: #2ecc39; }
    .other-settings-container .options-container .select-location .select-location-card.selected .select-header p {
      color: #2ecc39;
      font-size: 8px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.5px; }
    .other-settings-container .options-container .select-location .select-location-card.selected p {
      color: #2BC822; }
    .other-settings-container .options-container .select-location .select-location-card.selected .anticon path {
      fill: #2BC822; }
  .other-settings-container .options-container .select-location .select-location-card {
    cursor: pointer;
    border: 1px solid #707070;
    text-align: center;
    padding: 10px 8px;
    border-radius: 4px;
    margin-right: 8px;
    width: 120px; }
    .other-settings-container .options-container .select-location .select-location-card .select-header {
      display: flex;
      align-items: center;
      height: 22px; }
      .other-settings-container .options-container .select-location .select-location-card .select-header span {
        width: 10px;
        height: 10px;
        background: #efefef;
        border-radius: 20px;
        margin-right: 5px; }
    .other-settings-container .options-container .select-location .select-location-card p {
      font-size: 12px;
      color: #707070;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 0px; }
    .other-settings-container .options-container .select-location .select-location-card .anticon {
      font-size: 48px; }
      .other-settings-container .options-container .select-location .select-location-card .anticon path {
        fill: #707070; }

.preferences_seetting_data .settingnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .preferences_seetting_data .settingnav .settingwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .preferences_seetting_data .settingnav .settingwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .preferences_seetting_data .settingnav .settingwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
  .preferences_seetting_data .settingnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .preferences_seetting_data .settingnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .preferences_seetting_data .settingnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .preferences_seetting_data .settingnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .preferences_seetting_data .settingnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation {
        position: relative; }
      .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:hover {
        color: #4398ed !important; }
        .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation-click {
        position: relative; }
      .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }

.preferences_seetting_data .bottom_data {
  display: flex;
  justify-content: space-between;
  padding-top: 90px;
  margin-top: 32px;
  padding-top: 60px;
  margin-bottom: 8px; }

.preferences_seetting_data .ant-select-selection__placeholder {
  color: #1268fb; }

@media screen and (min-width: 1440px) {
  .settingnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .settingnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.non-cursor-pointer {
  pointer-events: none;
  border-bottom: none !important; }

.channel_branding_card .brand-setting-txt {
  border-bottom: 1px solid #cecece;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.032em;
  font-weight: 500;
  color: #212121; }

.channel_branding_card .brand_input {
  border-radius: 2px;
  width: 44%;
  background: #f5f5f5;
  border: none; }

.channel_branding_card .brand_input2 {
  border-radius: 2px;
  width: 44%;
  background: #f5f5f5;
  border: 1px solid #212121; }

.channel_branding_card .brand_input_name {
  border-radius: 2px;
  width: 44%;
  background: #f5f5f5;
  border: none;
  padding: 8px;
  color: #212121;
  letter-spacing: 0.032em;
  font-size: 12px; }

.channel_branding_card .brand_name_label {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  margin-bottom: 4px; }

.channel_branding_card .brand_logo {
  margin-top: 32px; }
  .channel_branding_card .brand_logo .drage-image {
    padding: 24px 16px;
    background-color: #f5f5f5;
    margin-right: 8px; }
  .channel_branding_card .brand_logo .drage-image:hover {
    background-color: #1370fc;
    color: #fff; }
  .channel_branding_card .brand_logo .drage-image:hover svg g path {
    fill: #fff; }
  .channel_branding_card .brand_logo .drage-image svg {
    position: relative;
    left: 33%; }
  .channel_branding_card .brand_logo .brand_logo_uploder {
    display: flex;
    justify-content: space-between;
    width: 48%; }
    .channel_branding_card .brand_logo .brand_logo_uploder .brand_logo_ican {
      width: 32%;
      height: 120px; }
    .channel_branding_card .brand_logo .brand_logo_uploder .images_show {
      width: 65%; }

.channel_branding_card .bottom_data {
  margin-top: 32px; }
  .channel_branding_card .bottom_data .footer_data {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: -56px; }
    .channel_branding_card .bottom_data .footer_data .change_email {
      width: 48%; }
      .channel_branding_card .bottom_data .footer_data .change_email .save_chnage {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
        font-size: 12px;
        letter-spacing: 0.032em;
        color: #898989;
        margin-top: 56px; }
        .channel_branding_card .bottom_data .footer_data .change_email .save_chnage .change-number-txt {
          color: #fc125e;
          border: none;
          background: none;
          text-decoration: underline !important;
          cursor: pointer; }
        .channel_branding_card .bottom_data .footer_data .change_email .save_chnage .save-number-txt {
          color: #28af21;
          border: none;
          background: none;
          text-decoration: underline !important;
          cursor: pointer; }
    .channel_branding_card .bottom_data .footer_data .change_phone_number {
      width: 48%; }
      .channel_branding_card .bottom_data .footer_data .change_phone_number .save_chnage {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3px;
        font-size: 12px;
        letter-spacing: 0.032em;
        color: #898989;
        margin-top: 56px; }
        .channel_branding_card .bottom_data .footer_data .change_phone_number .save_chnage .change-number-txt {
          color: #fc125e;
          border: none;
          background: none;
          text-decoration: underline !important;
          cursor: pointer; }
        .channel_branding_card .bottom_data .footer_data .change_phone_number .save_chnage .save-number-txt {
          color: #28af21;
          border: none;
          background: none;
          text-decoration: underline !important;
          cursor: pointer; }
    .channel_branding_card .bottom_data .footer_data .input-number-data {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      .channel_branding_card .bottom_data .footer_data .input-number-data .ind-91 {
        width: 20%;
        background: #f5f5f5;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        font-size: 12px; }
        .channel_branding_card .bottom_data .footer_data .input-number-data .ind-91 .ant-select-selection {
          background: none;
          border: none;
          font-size: 12px;
          display: flex;
          justify-content: center;
          border-radius: 2px;
          color: #212121; }
        .channel_branding_card .bottom_data .footer_data .input-number-data .ind-91 .ant-select-selection__rendered {
          line-height: 23px !important; }
        .channel_branding_card .bottom_data .footer_data .input-number-data .ind-91 .ant-select-arrow {
          margin-top: -7px !important; }
      .channel_branding_card .bottom_data .footer_data .input-number-data .input-email-txt {
        width: 100%; }
        .channel_branding_card .bottom_data .footer_data .input-number-data .input-email-txt input {
          background: #f5f5f5;
          border: none;
          border-radius: 0px;
          color: #212121;
          letter-spacing: 0.032em;
          line-height: 20px;
          font-size: 12px; }
      .channel_branding_card .bottom_data .footer_data .input-number-data .input-email-txt2 {
        width: 100%; }
        .channel_branding_card .bottom_data .footer_data .input-number-data .input-email-txt2 input {
          background: #f5f5f5;
          border: 1px solid #212121;
          border-radius: 0px;
          color: #212121;
          letter-spacing: 0.032em;
          line-height: 20px;
          font-size: 12px;
          height: 37px; }
      .channel_branding_card .bottom_data .footer_data .input-number-data .input-number-txt {
        width: 78%; }
        .channel_branding_card .bottom_data .footer_data .input-number-data .input-number-txt input {
          background: #f5f5f5;
          border: none;
          border-radius: 0px;
          color: #212121;
          letter-spacing: 0.032em;
          line-height: 20px;
          font-size: 12px; }
      .channel_branding_card .bottom_data .footer_data .input-number-data .input-number-txt2 {
        width: 100%; }
        .channel_branding_card .bottom_data .footer_data .input-number-data .input-number-txt2 input {
          background: #f5f5f5;
          border: 1px solid #212121;
          border-radius: 0px;
          color: #212121;
          letter-spacing: 0.032em;
          line-height: 20px;
          font-size: 12px;
          margin-left: 8px; }
    .channel_branding_card .bottom_data .footer_data .disable-phonenumber {
      background: #f5f5f5;
      font-size: 12px;
      height: 35px;
      display: flex;
      align-items: center;
      letter-spacing: 0.032em;
      color: #212121;
      padding-left: 16px; }
    .channel_branding_card .bottom_data .footer_data .verify-email {
      background: transparent;
      position: absolute;
      right: 58%;
      border: none;
      font-size: 12px;
      box-shadow: none;
      letter-spacing: 0.032em;
      cursor: pointer;
      margin-top: 0px;
      z-index: 99;
      color: #92bcf9;
      text-decoration: underline !important;
      pointer-events: none; }
    .channel_branding_card .bottom_data .footer_data .verify-email2 {
      background: transparent;
      position: absolute;
      right: 58%;
      border: none;
      font-size: 12px;
      box-shadow: none;
      letter-spacing: 0.032em;
      cursor: pointer;
      margin-top: 0px;
      z-index: 99;
      color: #0063f2;
      text-decoration: underline !important; }
    .channel_branding_card .bottom_data .footer_data .verify-phone {
      background: transparent;
      position: absolute;
      right: 10%;
      border: none;
      font-size: 12px;
      box-shadow: none;
      letter-spacing: 0.032em;
      cursor: pointer;
      margin-top: 0px;
      z-index: 99;
      color: #92bcf9;
      text-decoration: underline !important;
      pointer-events: none; }
    .channel_branding_card .bottom_data .footer_data .verify-phone2 {
      background: transparent;
      position: absolute;
      right: 10%;
      border: none;
      font-size: 12px;
      box-shadow: none;
      letter-spacing: 0.032em;
      cursor: pointer;
      margin-top: 0px;
      z-index: 99;
      color: #0063f2;
      text-decoration: underline !important; }
    .channel_branding_card .bottom_data .footer_data .cancel-verify-email {
      background: transparent;
      position: absolute;
      right: 52%;
      border: none;
      font-size: 12px;
      box-shadow: none;
      letter-spacing: 0.032em;
      cursor: pointer;
      margin-top: 0px;
      z-index: 99;
      color: #898989;
      text-decoration: underline !important; }
    .channel_branding_card .bottom_data .footer_data .cancel-verify-Phone {
      background: transparent;
      position: absolute;
      right: 4%;
      border: none;
      font-size: 12px;
      box-shadow: none;
      letter-spacing: 0.032em;
      cursor: pointer;
      margin-top: 0px;
      z-index: 99;
      color: #898989;
      text-decoration: underline !important; }
  .channel_branding_card .bottom_data .support_url {
    margin-top: -40px; }
  .channel_branding_card .bottom_data .already-verify-email {
    background: transparent;
    position: absolute;
    right: 52%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 0px;
    z-index: 99;
    color: #28af21;
    text-decoration: underline !important;
    pointer-events: none; }
    .channel_branding_card .bottom_data .already-verify-email .save-ican {
      margin-right: 3px; }
      .channel_branding_card .bottom_data .already-verify-email .save-ican svg path {
        fill: #28af21; }
  .channel_branding_card .bottom_data .already-verify-phone {
    background: transparent;
    position: absolute;
    right: 5%;
    border: none;
    font-size: 12px;
    box-shadow: none;
    letter-spacing: 0.032em;
    cursor: pointer;
    margin-top: 0px;
    z-index: 99;
    color: #28af21;
    text-decoration: underline !important;
    pointer-events: none; }
    .channel_branding_card .bottom_data .already-verify-phone .save-ican {
      margin-right: 3px; }
      .channel_branding_card .bottom_data .already-verify-phone .save-ican svg path {
        fill: #28af21; }

.channel_branding_card .brand_Edit {
  width: 168px;
  margin-top: 16px; }

.channel_branding_card .cancel_btn {
  width: 168px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #212121; }

.channel_branding_card .brand_save {
  background: #2bc822;
  width: 168px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em; }

.edit-ican {
  margin-right: 3px !important; }
  .edit-ican .anticon {
    position: relative;
    top: 1px; }

.cancel-url {
  color: #898989;
  border: none;
  background: none;
  text-decoration: underline !important;
  cursor: pointer; }

.support-email-phone-modal .ant-modal-content .ant-modal-close {
  display: none !important; }

.support-email-phone-modal .ant-modal-content .support-phone-number-txt {
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.032em;
  line-height: 22px;
  margin-bottom: 0px;
  color: #fc125e; }

.support-email-phone-modal .ant-modal-content .number-verify-txt {
  font-size: 12px;
  color: #4b4b4b;
  line-height: 22px;
  letter-spacing: 0.032em;
  text-align: center; }

.support-email-phone-modal .ant-modal-content .ant-modal-header {
  border-bottom: none !important;
  padding: 32px 24px !important; }

.support-email-phone-modal .ant-modal-content .ant-modal-footer {
  border-top: none !important; }

.support-email-phone-modal .otp-verify {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 24px; }
  .support-email-phone-modal .otp-verify .otp-number {
    width: 15%;
    border: none;
    background: #f5f5f5;
    height: 32px;
    text-align: center; }

.support-email-phone-modal .otp-txt {
  font-size: 12px;
  margin-top: 24px;
  margin-bottom: 5px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #898989; }

.support-email-phone-modal .resend-code {
  float: right;
  margin-top: -16px;
  font-size: 12px;
  letter-spacing: 0.032em; }
  .support-email-phone-modal .resend-code .resend-code-txt {
    color: #3273cc;
    text-decoration: underline !important;
    margin-right: 4px;
    background: transparent;
    border: none;
    cursor: pointer; }
  .support-email-phone-modal .resend-code .resend-code-txt2 {
    color: #98b9e5;
    text-decoration: underline !important;
    margin-right: -3px;
    background: transparent;
    border: none;
    pointer-events: none; }

.support-email-phone-modal .header-support-txt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3px;
  font-size: 12px;
  letter-spacing: 0.032em;
  margin-top: -24px;
  color: #898989; }
  .support-email-phone-modal .header-support-txt .change-number-txt {
    color: #fc125e;
    border: none;
    background: none;
    text-decoration: underline !important;
    cursor: pointer; }
  .support-email-phone-modal .header-support-txt .save-number-txt {
    color: #28af21;
    border: none;
    background: none;
    text-decoration: underline !important;
    cursor: pointer; }

.support-email-phone-modal .save-ican {
  margin-right: 3px; }
  .support-email-phone-modal .save-ican svg path {
    fill: #28af21; }

.support-email-phone-modal .input-number-data {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .support-email-phone-modal .input-number-data .ind-91 {
    width: 20%;
    background: #f5f5f5;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 12px; }
    .support-email-phone-modal .input-number-data .ind-91 .ant-select-selection {
      background: none;
      border: none;
      font-size: 12px;
      display: flex;
      justify-content: center;
      border-radius: 2px;
      color: #212121; }
    .support-email-phone-modal .input-number-data .ind-91 .ant-select-selection__rendered {
      line-height: 23px !important; }
    .support-email-phone-modal .input-number-data .ind-91 .ant-select-arrow {
      margin-top: -7px !important; }
  .support-email-phone-modal .input-number-data .input-email-txt {
    width: 100%; }
    .support-email-phone-modal .input-number-data .input-email-txt input {
      background: #f5f5f5;
      border: none;
      border-radius: 0px; }
  .support-email-phone-modal .input-number-data .input-email-txt2 {
    width: 100%; }
    .support-email-phone-modal .input-number-data .input-email-txt2 input {
      background: #f5f5f5;
      border: 1px solid #212121;
      border-radius: 0px;
      height: 37px; }
  .support-email-phone-modal .input-number-data .input-number-txt {
    width: 78%; }
    .support-email-phone-modal .input-number-data .input-number-txt input {
      background: #f5f5f5;
      border: none;
      border-radius: 0px; }
  .support-email-phone-modal .input-number-data .input-number-txt2 {
    width: 100%; }
    .support-email-phone-modal .input-number-data .input-number-txt2 input {
      background: #f5f5f5;
      border: 1px solid #212121;
      border-radius: 0px;
      margin-left: 8px; }

.support-email-phone-modal .verify-continue-btn {
  width: 408px;
  margin-top: 8px;
  border: none;
  box-shadow: none;
  background: #2bc822 !important;
  border-radius: 2px;
  color: #fff;
  letter-spacing: 0.032em;
  height: 40px;
  line-height: 16px;
  margin-top: 16px; }

.support-email-phone-modal .verify-continue-btn2 {
  width: 408px;
  margin-top: 8px;
  border: none;
  box-shadow: none;
  background: #94e38f !important;
  border-radius: 2px;
  color: #fff;
  letter-spacing: 0.032em;
  height: 40px;
  line-height: 16px;
  pointer-events: none; }

.support-email-phone-modal .cancel-btn {
  width: 408px;
  margin-top: 8px;
  border: 1px solid #898989;
  box-shadow: none;
  border-radius: 2px;
  color: #898989;
  letter-spacing: 0.032em;
  height: 40px;
  line-height: 16px; }

.support-email-phone-modal .otp-number-txt {
  font-size: 12px;
  font-style: italic;
  color: #898989;
  margin-top: 4px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.support-email-phone-modal .ant-input {
  font-size: 12px !important;
  color: #212121;
  letter-spacing: 0.032em;
  line-height: 16px; }

.support-email-phone-modal .disable-phonenumber {
  background: #f5f5f5;
  font-size: 12px;
  height: 32px;
  display: flex;
  align-items: center;
  letter-spacing: 0.032em;
  color: #212121;
  padding-left: 16px; }

.settings-page {
  font-family: "Poppins", sans-serif; }
  .settings-page .abc .ant-card-body {
    padding-bottom: 2rem !important; }
  .settings-page .ant-card-bordered {
    padding: 0.1rem 1rem; }
    .settings-page .ant-card-bordered h3 {
      color: #212121;
      font-size: 1rem;
      font-weight: 500; }
    .settings-page .ant-card-bordered .ant-divider-horizontal {
      margin-top: 5px;
      margin-bottom: 25px; }
  .settings-page .tabs-card .ant-card-body {
    padding: 24px 32px 24px 0; }
  .settings-page .tabs-card .ant-tabs-left-bar {
    width: 256px; }
    .settings-page .tabs-card .ant-tabs-left-bar .ant-tabs-tab {
      padding: 20px 0 20px 60px;
      text-align: left; }
    .settings-page .tabs-card .ant-tabs-left-bar .ant-tabs-tab-active {
      background: #efefef; }
  .settings-page .tabs-card .ant-tabs-ink-bar {
    width: 6px;
    background: #050c15; }
  .settings-page .tabs-card .ant-tabs-nav .ant-tabs-tab:hover {
    color: black; }
  .settings-page .tabs-card .ant-tabs-tab-active {
    color: black; }
  .settings-page .title {
    padding-left: 1rem;
    font-weight: 400;
    color: #6c6c6c;
    margin-bottom: 0;
    margin-top: 1rem; }
  .settings-page .ant-checkbox-wrapper {
    font-size: 0.9rem;
    font-weight: 500; }
  .settings-page .sub-text {
    font-size: 0.85rem;
    margin-top: 0.3rem;
    color: #9a9a9a; }
  .settings-page .options-container {
    padding: 0 1rem;
    margin-bottom: 1rem; }
  .settings-page .ant-switch {
    margin-right: 10px; }
  .settings-page .normal-text {
    font-size: 0.9rem;
    font-weight: 500; }
  .settings-page .auto-update-options .normal-text {
    margin-top: 1.25rem;
    margin-bottom: 0.5rem; }
  .settings-page .inline-input {
    display: inline-flex;
    max-width: 25%;
    margin-left: 10px;
    flex-direction: column; }

.settings-sidebar {
  list-style: none;
  padding: 0;
  font-size: 1rem;
  margin-top: 16px; }
  .settings-sidebar li a {
    color: rgba(0, 0, 0, 0.65);
    padding: 1rem;
    display: block;
    text-decoration: none; }
  .settings-sidebar li a:hover,
  .settings-sidebar li a.active {
    color: #5e73ff; }

@media screen and (max-width: 1280px) {
  .settings-page .tabs-card .ant-card-body {
    padding: 24px 32px; } }

.content-page-title {
  font-size: 1rem; }

.user-firstname {
  text-transform: capitalize;
  letter-spacing: 0.032em;
  font-size: 12px; }

.options-container34 {
  display: flex;
  justify-content: space-between;
  padding-left: 14px; }
  .options-container34 .option a {
    color: #0068ff; }
    .options-container34 .option a .d-container {
      position: relative;
      top: 2px; }
  .options-container34 .option .bottom-line {
    font-size: 10px;
    margin-top: 2px; }
    .options-container34 .option .bottom-line .date {
      color: #212121;
      font-weight: 500; }

.options-container35 {
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  grid-gap: 26px;
  gap: 26px; }
  .options-container35 .ant-switch-disabled {
    cursor: unset;
    opacity: 1; }
  .options-container35 .ant-switch-disabled::after {
    cursor: unset; }
  .options-container35 .subheading {
    font-size: 14px;
    padding-bottom: 5px;
    padding-top: 10px; }
  .options-container35 .order-switch-container {
    display: flex; }
    .options-container35 .order-switch-container .label {
      margin-right: 12px; }
    .options-container35 .order-switch-container .ant-switch-checked {
      background-color: #36c626; }
  .options-container35 .otp-switch-container .label {
    margin-right: 12px; }
  .options-container35 .otp-switch-container .ant-switch-checked {
    background-color: #36c626; }
  .options-container35 .type-container .ant-radio-checked .ant-radio-inner {
    border-color: #36c626; }
  .options-container35 .type-container .ant-radio-wrapper-checked span {
    color: #212121;
    font-weight: 500; }
  .options-container35 .type-container .ant-radio-checked .ant-radio-inner::after {
    background-color: #36c626; }
  .options-container35 .input-container .input-tags {
    width: 360px;
    background: #f5f5f5;
    border: 1px solid #212121;
    border-radius: 0px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 20px;
    font-size: 12px;
    height: 37px;
    padding: 4px 11px; }
  .options-container35 input:focus-visible,
  .options-container35 textarea {
    outline: none !important; }
  .options-container35 input:disabled {
    cursor: not-allowed;
    border: 0px solid black !important; }
  .options-container35 .time-zone-tooltip-custom {
    position: relative;
    top: 2px; }

.options-container35 {
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  grid-gap: 26px;
  gap: 26px; }
  .options-container35 .subheading {
    font-size: 14px;
    padding-bottom: 5px;
    padding-top: 10px; }
  .options-container35 .order-switch-container {
    display: flex; }
    .options-container35 .order-switch-container .label {
      margin-right: 12px; }
    .options-container35 .order-switch-container .ant-switch-checked {
      background-color: #36c626; }
  .options-container35 .otp-switch-container .label {
    margin-right: 12px; }
  .options-container35 .otp-switch-container .ant-switch-checked {
    background-color: #36c626; }
  .options-container35 .type-container .ant-radio-checked .ant-radio-inner {
    border-color: #36c626; }
  .options-container35 .type-container .ant-radio-wrapper-checked span {
    color: #212121;
    font-weight: 500; }
  .options-container35 .type-container .ant-radio-checked .ant-radio-inner::after {
    background-color: #36c626; }
  .options-container35 .input-container .input-tags {
    width: 360px;
    background: #f5f5f5;
    border: 1px solid #212121;
    border-radius: 0px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 20px;
    font-size: 12px;
    height: 37px;
    padding: 4px 11px; }
  .options-container35 input:focus-visible,
  .options-container35 textarea {
    outline: none !important; }
  .options-container35 input:disabled {
    cursor: not-allowed;
    border: 0px solid black !important; }
  .options-container35 .change-number-txt {
    color: #fc125e;
    border: none;
    background: none;
    text-decoration: underline !important;
    cursor: pointer; }
  .options-container35 .edit-ican {
    margin-right: 3px !important; }
    .options-container35 .edit-ican .anticon {
      position: relative;
      top: 1px; }

.image-modal .ant-modal-body {
  min-height: 400px; }

.image-modal .ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 29px; }

.image-modal .image-arrow {
  position: absolute;
  top: 50%;
  z-index: 99;
  background: transparent;
  padding: 0px;
  height: 40px;
  width: 40px;
  border: none;
  box-shadow: none; }
  .image-modal .image-arrow .anticon {
    font-size: 40px; }

.search-products-container .ant-input-search {
  height: 1.75rem; }
  .search-products-container .ant-input-search .ant-input {
    font-size: 0.75rem;
    height: 1.75rem; }
  .search-products-container .ant-input-search .ant-input::placeholder {
    font-size: 0.75rem; }

.search-products-container img {
  max-width: 100%; }

.search-products-container .search-button {
  text-align: end; }

.search-products-container .search-button button {
  width: 275px;
  border: none;
  border-radius: 0px;
  box-shadow: none;
  height: 36px; }

.search-products-container .import-product-btn {
  background: #0068ff;
  color: white;
  border-color: #0068ff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045); }

.search-products-container .product-category-row .selected-category {
  border-color: #36c626; }
  .search-products-container .product-category-row .selected-category svg * {
    stroke: #36c626;
    fill: #36c626; }
  .search-products-container .product-category-row .selected-category p {
    color: #36c626; }

.search-products-container .product-category-row .product-category-card {
  cursor: pointer;
  display: flex;
  background: white;
  border: 1px solid #d6d6d6;
  align-items: center;
  height: 70px;
  padding: 15px 20px; }
  .search-products-container .product-category-row .product-category-card .product-category-img {
    width: 20px; }
    .search-products-container .product-category-row .product-category-card .product-category-img svg {
      width: 20px; }
  .search-products-container .product-category-row .product-category-card .product-category-name {
    flex: 1 1;
    flex-direction: column;
    font-size: 14px;
    line-height: 30px;
    align-items: center;
    overflow: hidden; }
    .search-products-container .product-category-row .product-category-card .product-category-name p {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }

.search-products-container .product-category-row .product-category-card:hover,
.search-products-container .product-category-row .product-category-card.selected {
  background: #e6e5e5;
  border: 1px solid black;
  color: black; }

.search-products-container .list-category-container {
  padding-top: 19px; }
  .search-products-container .list-category-container .list-category-card .ant-card-body {
    padding: 0; }
  .search-products-container .list-category-container .list-category-card .watch-product {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 24px; }
  .search-products-container .list-category-container .list-category-card .slider-image {
    overflow: scroll;
    margin: 10px;
    width: 118px;
    display: flex;
    margin-bottom: 0px; }
    .search-products-container .list-category-container .list-category-card .slider-image img {
      width: 32px;
      height: 32px;
      border: 1px solid black;
      margin-right: 10px;
      cursor: pointer; }
  .search-products-container .list-category-container .title {
    font-weight: 500; }
  .search-products-container .list-category-container .blue-text {
    color: #0068ff; }
  .search-products-container .list-category-container .pink-text {
    color: #ff005e; }
  .search-products-container .list-category-container .list-category-column {
    padding: 0.5rem;
    margin: 0 0 15px 0; }
    .search-products-container .list-category-container .list-category-column .image-container {
      border: 1px solid black;
      width: 144px;
      max-height: 138px; }
    .search-products-container .list-category-container .list-category-column .list-category-card-content {
      width: 98%; }
    .search-products-container .list-category-container .list-category-column .landing-cost {
      margin-bottom: 0px;
      padding: 7px;
      background: #f2f7fe;
      text-align: center;
      font-weight: 500;
      color: #383838;
      line-height: 22px;
      margin-top: 3px; }
    .search-products-container .list-category-container .list-category-column .product-name {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      display: block;
      color: #1268fb;
      line-height: 21px;
      margin-bottom: 8px;
      min-height: 42px; }
    .search-products-container .list-category-container .list-category-column .product-field {
      margin-bottom: 0.2rem;
      font-size: 12px;
      line-height: 22px;
      color: #696969;
      margin: 0; }
      .search-products-container .list-category-container .list-category-column .product-field b {
        font-weight: 500; }
    .search-products-container .list-category-container .list-category-column .anticon-star svg {
      width: 1.5rem;
      height: 1.5rem;
      margin-bottom: 0.7rem; }
      .search-products-container .list-category-container .list-category-column .anticon-star svg path {
        fill: #f2e227; }
    .search-products-container .list-category-container .list-category-column .footer {
      color: #fff;
      background: #36c626;
      border: none;
      border-radius: 0px;
      box-shadow: unset;
      font-size: 14px;
      text-align: center;
      padding: 12px 24px; }
    .search-products-container .list-category-container .list-category-column .list-category-card {
      border: 2px solid transparent;
      position: relative;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.16); }

.search-products-container .filter-container p {
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-bottom: 10px; }

.search-products-container .filter .ant-select-selection {
  border-radius: 0; }

.search-products-container .filter.first .ant-select-selection {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px; }

.search-products-container .filter.last .ant-select-selection {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px; }

.search-products-container .product-sorting {
  display: flex;
  align-items: center;
  margin-bottom: 30px; }

.search-products-container .other-attributes {
  margin-top: 6px;
  display: flex; }
  .search-products-container .other-attributes .anticon {
    font-size: 24px;
    margin-right: 8px; }

.search-products-container .viewAll {
  display: flex; }
  .search-products-container .viewAll .ant-btn {
    background: #f2f7ff;
    color: #0068ff;
    border: none;
    height: 48px;
    flex: 1 1; }
  .search-products-container .viewAll a {
    display: inline-block;
    background: #f2f7ff;
    color: #0068ff;
    border: none;
    height: 48px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex: 1 1; }

.search-products-container .product-and-sort p {
  font-size: 16px;
  font-weight: 500;
  color: black;
  margin-bottom: 0px; }

.productOverviewDrawer .go-back {
  background-color: #000000;
  font-weight: 500;
  padding: 14px 24px;
  font-size: 1rem;
  margin-bottom: 15px; }
  .productOverviewDrawer .go-back a {
    color: white; }

.productOverviewDrawer .ant-drawer-body {
  padding: 0px; }
  .productOverviewDrawer .ant-drawer-body hr {
    margin-bottom: 24px;
    margin-top: 24px; }
  .productOverviewDrawer .ant-drawer-body .drawer-main-section {
    display: flex; }
    .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-sidebar {
      width: 255px;
      background: #0e1d34;
      padding: 0 0 24px 0px;
      height: 100vh;
      position: fixed; }
      .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-sidebar h1 {
        font-size: 21px;
        font-weight: 500;
        color: white;
        padding: 0px 34px; }
      .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-sidebar .ant-menu {
        background: #0e1d34; }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-sidebar .ant-menu .ant-menu-item {
          padding: 0 34px;
          font-size: 16px;
          font-weight: 400;
          color: white;
          margin-top: 0px;
          margin-bottom: 0px;
          height: 48px; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-sidebar .ant-menu .ant-menu-item a {
            color: white; }
      .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: #000000;
        border-right: 5px solid #0068ff;
        font-weight: 500; }
      .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-sidebar .ant-menu-item:active {
        background-color: #000000; }
    .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body {
      margin-left: 18.2em;
      width: calc(100% - 18.2em);
      height: 100vh;
      overflow: scroll;
      scroll-behavior: smooth; }
      .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 9px 43px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info {
          width: 100%;
          display: flex; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .product-image {
            margin-right: 30px;
            width: 240px; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .product-image img {
              border: 1px solid black; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .product-image .blackbox {
              display: flex;
              overflow: scroll;
              margin-bottom: 15px; }
              .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .product-image .blackbox img {
                width: 40px;
                height: 40px;
                margin-top: 10px;
                margin-right: 10px;
                cursor: pointer; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .product-image .overview-dates {
              font-size: 12px;
              text-decoration: underline;
              color: #838383;
              font-weight: 500;
              text-align: center;
              margin: 0 0 5px 0; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .product-details {
            display: flex;
            flex: 1 1;
            justify-content: space-between; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .product-details .product-internal-info {
              flex: 1 1; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .ant-tag,
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .ant-tag a,
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .ant-tag a:hover {
            color: #585858 !important;
            font-weight: 400;
            font-size: 12px;
            padding: 7px 10px; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .bulk-price {
            margin-top: 10px; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .bulk-price p {
              color: #585858 !important;
              font-weight: 400;
              font-size: 14px;
              margin-bottom: 0px;
              text-align: center;
              padding: 0px 25px; }
              .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .bulk-price p span {
                color: #2b2a2a !important;
                font-weight: 500;
                font-size: 12px; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .explore-pricing {
            margin-top: 24px;
            margin-bottom: 16px; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .explore-pricing .ant-btn {
              width: 260px;
              height: 36px;
              margin-right: 24px;
              border-radius: 2px; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .product-info .explore-pricing .add-product {
              background: #36c626;
              color: white; }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle .anticon {
          color: #444242; }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .modalTitle p {
          font-size: 16px;
          font-weight: 400;
          color: #585858; }
      .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body h4 {
        font-size: 14px;
        font-weight: 500;
        color: black;
        margin-bottom: 10px; }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body h4 span {
          font-weight: 500;
          text-decoration: underline;
          margin-left: 10px; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body h4 span .anticon {
            margin-left: 10px; }
      .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section {
        margin: 43px 60px; }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section h1 {
          font-size: 24px;
          color: black;
          font-weight: bold;
          border-bottom: 2px solid black;
          line-height: 20px;
          margin-bottom: 24px; }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .media-container {
          padding-top: 20px; }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .data-container {
          padding-top: 20px;
          margin-bottom: 60px; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .data-container .advertising-data-not-available {
            background-image: url(/static/media/advertising-disable.6eaf224c.svg);
            padding: 36px 43px;
            text-align: center; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .data-container .advertising-data-not-available h2 {
              color: #040404;
              font-size: 24px;
              font-weight: 600;
              margin-bottom: 0px; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .data-container .advertising-data-not-available p {
              color: #040404;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 0px; }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .calculate-est-vf {
          padding: 24px;
          background: #f0f0f0; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .calculate-est-vf .calculation {
            display: flex;
            align-items: center;
            margin-bottom: 10px; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .calculate-est-vf .calculation p {
              margin-bottom: 0px; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .calculate-est-vf .calculation .ant-input {
              width: 104px;
              margin-left: 20px; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .calculate-est-vf .cal-button {
            margin-top: 24px;
            text-align: center; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .calculate-est-vf .cal-button .ant-btn {
              width: 250px; }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .shopify-metrics {
          display: flex;
          margin-bottom: 24px; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .shopify-metrics p {
            background: #eeeeee;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 0px;
            color: #040404;
            font-weight: 400;
            margin-right: 15px;
            width: 204px;
            text-align: center; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .shopify-metrics p span {
              font-size: 12px;
              margin-bottom: 0px;
              font-weight: 500; }
        .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .report-graph {
          margin-top: 24px; }
          .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .report-graph .graph-stat {
            display: flex;
            align-items: center; }
            .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .report-graph .graph-stat .stat-name {
              margin-right: 20px; }
              .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .report-graph .graph-stat .stat-name p {
                font-size: 12px;
                color: #000000d9;
                font-weight: 500; }
                .productOverviewDrawer .ant-drawer-body .drawer-main-section .drawer-main-body .overview-section .pricing-data .report-graph .graph-stat .stat-name p .anticon {
                  font-size: 10px;
                  margin-right: 8px; }

.bulk-pricing-modal {
  width: 500px; }
  .bulk-pricing-modal .ant-modal-body {
    padding: 0;
    overflow: hidden; }
    .bulk-pricing-modal .ant-modal-body .ant-modal-confirm-content {
      margin: 0; }
      .bulk-pricing-modal .ant-modal-body .ant-modal-confirm-content .title {
        background: #1268fb;
        color: white;
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        padding: 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px; }
      .bulk-pricing-modal .ant-modal-body .ant-modal-confirm-content .body {
        padding: 10px 16px 0 16px;
        color: black;
        font-size: 12px; }
    .bulk-pricing-modal .ant-modal-body .ant-modal-confirm-btns {
      float: unset;
      padding: 10px 0 16px 0;
      text-align: center; }
      .bulk-pricing-modal .ant-modal-body .ant-modal-confirm-btns .ant-btn {
        border: 2px solid #707070;
        border-radius: 0; }

.media .no-media {
  background-image: url(/static/media/no-media.0ac79871.svg);
  padding: 24px 20px;
  background-repeat: no-repeat;
  width: 120px; }
  .media .no-media p {
    margin-bottom: 0px;
    text-align: center;
    color: black;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; }

.media .product-images img {
  width: 120px;
  height: 120px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid black; }

.media .product-images .anticon {
  bottom: 24px;
  position: absolute;
  right: 22px;
  background: #0068ff;
  color: white;
  padding: 5px;
  border-radius: 18px;
  font-size: 15px; }

.media .product-video video {
  width: 120px;
  height: 120px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: 1px solid black; }

.media .product-video .anticon {
  bottom: 24px;
  position: absolute;
  right: 22px;
  background: #0068ff;
  color: white;
  padding: 5px;
  border-radius: 18px;
  font-size: 15px; }

.social-data .popular-link a {
  font-size: 14px; }

.social-data .social_stats .facebook-stats {
  padding: 10px;
  background: #eeeeee; }
  .social-data .social_stats .facebook-stats .stats-info {
    display: flex; }
  .social-data .social_stats .facebook-stats p {
    font-size: 0.9rem;
    font-weight: 400;
    color: #040404; }
    .social-data .social_stats .facebook-stats p span {
      font-weight: 500; }

.social-data .social_stats .engagement-stats {
  padding: 22px;
  background: #eeeeee;
  display: flex;
  justify-content: space-between; }
  .social-data .social_stats .engagement-stats .ant-row {
    width: 100%; }
  .social-data .social_stats .engagement-stats .ant-col {
    text-align: center;
    font-size: 10px;
    color: #040404; }
    .social-data .social_stats .engagement-stats .ant-col span {
      font-weight: 600;
      font-size: 20px; }

.social-data .internal-notes {
  margin-top: 20px;
  display: flex; }
  .social-data .internal-notes p {
    font-size: 12px;
    margin-bottom: 24px;
    color: #595650;
    font-weight: 400; }
  .social-data .internal-notes .ant-btn {
    border: none;
    background: #ff005e;
    color: white;
    box-shadow: none;
    height: 20px;
    font-size: 10px;
    font-weight: 500; }

.social-data .shopify-metrics {
  display: flex;
  margin-bottom: 24px; }
  .social-data .shopify-metrics p {
    background: #eeeeee;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 0px;
    color: #040404;
    font-weight: 400;
    margin-right: 15px; }
    .social-data .shopify-metrics p span {
      font-size: 12px;
      margin-bottom: 0px;
      font-weight: 500; }

.social-data .store-list {
  padding-left: 15px;
  margin-bottom: 0px; }
  .social-data .store-list li a {
    color: #ff005e;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; }

.social-data .country-list {
  margin-bottom: 0px;
  padding-left: 15px; }
  .social-data .country-list li {
    line-height: 22px;
    color: #838383;
    font-size: 14px;
    font-weight: 400; }

.social-data hr {
  margin-top: 30px;
  margin-bottom: 30px; }

.social-data .instagram-influencers {
  display: flex;
  overflow: auto; }
  .social-data .instagram-influencers .insta-influence {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap; }
    .social-data .instagram-influencers .insta-influence .insta-img {
      padding: 10px;
      background: #eeeeee;
      margin-right: 10px;
      width: 200px; }
      .social-data .instagram-influencers .insta-influence .insta-img img {
        width: 100%;
        height: 200px; }
      .social-data .instagram-influencers .insta-influence .insta-img p {
        font-size: 12px;
        font-weight: 500;
        color: #0068ff;
        margin-bottom: 0px; }
      .social-data .instagram-influencers .insta-influence .insta-img .ant-btn {
        border: none;
        background: #ffba13;
        font-size: 8px;
        color: white;
        box-shadow: none;
        width: 48px;
        height: 16px;
        padding: 0px;
        margin-bottom: 8px; }

.social-data .facebook-images {
  display: flex;
  margin-top: 24px;
  overflow: auto; }
  .social-data .facebook-images img {
    width: 108px;
    height: 108px;
    margin-right: 10px;
    border: 1px solid black; }
  .social-data .facebook-images .anticon {
    bottom: 10px;
    position: absolute;
    right: 22px;
    background: #0068ff;
    color: white;
    padding: 5px;
    border-radius: 18px;
    font-size: 15px; }
  .social-data .facebook-images video {
    width: 120px;
    height: 120px;
    margin-right: 15px;
    margin-bottom: 15px;
    border: 1px solid black; }

.social-data .report-graph {
  margin-top: 24px; }
  .social-data .report-graph .graph-stat {
    display: flex;
    align-items: center; }
    .social-data .report-graph .graph-stat .stat-name {
      margin-right: 20px; }
      .social-data .report-graph .graph-stat .stat-name p {
        font-size: 12px;
        color: #000000d9;
        font-weight: 500; }
        .social-data .report-graph .graph-stat .stat-name p .anticon {
          font-size: 10px;
          margin-right: 8px; }

.variantData .ant-table {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.variantData .ant-table-thead > tr > th {
  color: white;
  background: #16283b; }

.variantData .ant-table-thead > tr > th,
.variantData .ant-table-thead > tr > td {
  text-align: left; }

.variantData tr.ant-table-row {
  background: white; }

.variantData .variant-image {
  display: flex;
  align-items: center; }
  .variantData .variant-image img {
    margin-right: 3px; }

.bulk-pricing-not-available {
  background-image: url(/static/media/bulk-pricing-empty-state.c4408b91.svg);
  padding: 24px 43px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .bulk-pricing-not-available h2 {
    color: #040404;
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 0px; }
  .bulk-pricing-not-available p {
    color: #040404;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0px; }

.product-summary .ant-tag,
.product-summary .ant-tag a,
.product-summary .ant-tag a:hover {
  color: #585858 !important;
  font-weight: 400;
  font-size: 12px; }

.product-summary .descri p {
  font-size: 16px;
  font-weight: 400;
  color: #585858;
  margin-bottom: 8px; }

.product-summary .descri .ant-btn {
  font-size: 10px;
  color: white;
  background: #ff005e;
  height: 22px;
  width: 125px;
  font-weight: 500;
  border: none; }

.product-summary .category .ant-tag,
.product-summary .category .ant-tag a,
.product-summary .category .ant-tag a:hover {
  color: #585858 !important;
  font-weight: 400;
  font-size: 12px; }

.product-summary .feature ul {
  padding-left: 17px; }
  .product-summary .feature ul li {
    font-size: 16px;
    width: 50%;
    float: left;
    margin-bottom: 8px; }

.product-summary .attri .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #36c626;
  border-color: #36c626; }

.product-summary .attri .ant-tag,
.product-summary .attri .ant-tag a,
.product-summary .attri .ant-tag a:hover {
  color: #585858 !important;
  font-weight: 400;
  font-size: 14px;
  padding: 7px 10px;
  text-align: center;
  height: 48px; }
  .product-summary .attri .ant-tag span,
  .product-summary .attri .ant-tag a span,
  .product-summary .attri .ant-tag a:hover span {
    font-size: 10px; }
  .product-summary .attri .ant-tag .anticon,
  .product-summary .attri .ant-tag a .anticon,
  .product-summary .attri .ant-tag a:hover .anticon {
    font-size: 20px; }

.product-summary .attri p {
  width: 33%;
  margin-bottom: 10px; }
  .product-summary .attri p .ant-checkbox {
    margin-left: 20px; }

.product-attributes .attri {
  display: flex; }
  .product-attributes .attri .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #36c626;
    border-color: #36c626; }
  .product-attributes .attri .ant-tag,
  .product-attributes .attri .ant-tag a,
  .product-attributes .attri .ant-tag a:hover {
    color: #585858 !important;
    font-weight: 400;
    font-size: 14px;
    padding: 7px 10px;
    text-align: center;
    height: 48px;
    display: flex;
    align-items: center; }
    .product-attributes .attri .ant-tag span,
    .product-attributes .attri .ant-tag a span,
    .product-attributes .attri .ant-tag a:hover span {
      font-size: 10px; }
    .product-attributes .attri .ant-tag .anticon,
    .product-attributes .attri .ant-tag a .anticon,
    .product-attributes .attri .ant-tag a:hover .anticon {
      font-size: 20px; }
  .product-attributes .attri .tag-class {
    display: flex;
    align-items: center;
    justify-content: center; }
    .product-attributes .attri .tag-class p {
      font-size: 10px !important;
      margin-bottom: 0px;
      margin-left: 8px;
      width: 100%; }
  .product-attributes .attri p {
    width: 33%;
    margin-bottom: 10px; }
    .product-attributes .attri p .ant-checkbox {
      margin-left: 20px; }

.view-content .ant-modal-content .ant-modal-header {
  border-bottom: none;
  padding: 16px 60px; }
  .view-content .ant-modal-content .ant-modal-header h1 {
    text-align: center;
    font-size: 28px;
    color: #01002b;
    font-weight: 500;
    margin-bottom: 0px;
    line-height: 42px; }

.view-content .ant-modal-content .ant-modal-body {
  padding: 0px 24px 14px 24px;
  height: auto; }
  .view-content .ant-modal-content .ant-modal-body p {
    font-size: 14px;
    color: #484848;
    margin-bottom: 0px;
    border: 1px solid #eeeeee;
    padding: 10px;
    background: #f9f9f9;
    border-radius: 6px; }

.view-content .ant-modal-content .ant-btn {
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px; }

.inventories-modal .ant-modal-header {
  background: #0e1d34; }
  .inventories-modal .ant-modal-header .inventories-header {
    display: flex;
    justify-content: space-between; }
    .inventories-modal .ant-modal-header .inventories-header img {
      width: 105px; }
    .inventories-modal .ant-modal-header .inventories-header p {
      margin-bottom: 0px;
      margin-left: 10px;
      color: white;
      font-weight: 400; }

.inventories-modal .ant-modal-body .main-inventory-container .product-card {
  margin-right: 24px; }
  .inventories-modal .ant-modal-body .main-inventory-container .product-card h4 {
    color: black;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0px;
    line-height: 22px; }
  .inventories-modal .ant-modal-body .main-inventory-container .product-card .product-info {
    display: flex;
    margin-bottom: 16px; }
    .inventories-modal .ant-modal-body .main-inventory-container .product-card .product-info img {
      width: 100px;
      border: 1px solid black; }
  .inventories-modal .ant-modal-body .main-inventory-container .product-card .bulk-price {
    margin-top: 10px;
    margin-bottom: 24px; }
    .inventories-modal .ant-modal-body .main-inventory-container .product-card .bulk-price p {
      color: #585858 !important;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 0px;
      text-align: center;
      padding: 0px 13px; }
      .inventories-modal .ant-modal-body .main-inventory-container .product-card .bulk-price p span {
        color: #2b2a2a !important;
        font-weight: 500;
        font-size: 12px; }
  .inventories-modal .ant-modal-body .main-inventory-container .product-card .stock-location.selected-location {
    background: #f7fff6 !important;
    border: 2px solid #67c72b !important; }
    .inventories-modal .ant-modal-body .main-inventory-container .product-card .stock-location.selected-location span {
      border: 2px solid #67c72b; }
  .inventories-modal .ant-modal-body .main-inventory-container .product-card .disabled-location {
    margin-bottom: 10px;
    background: #eeeeee; }
    .inventories-modal .ant-modal-body .main-inventory-container .product-card .disabled-location .location-error {
      color: white;
      font-weight: 500;
      font-size: 10px;
      padding: 8px;
      background: #eb2d2d;
      margin-bottom: 0px; }
    .inventories-modal .ant-modal-body .main-inventory-container .product-card .disabled-location .location-name {
      margin-left: 10px; }
    .inventories-modal .ant-modal-body .main-inventory-container .product-card .disabled-location .stock-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 10px 0px 28px; }
      .inventories-modal .ant-modal-body .main-inventory-container .product-card .disabled-location .stock-info p {
        color: #acacac;
        font-weight: 400;
        font-size: 10px;
        margin-bottom: 6px;
        background: none;
        padding: 0px; }
  .inventories-modal .ant-modal-body .main-inventory-container .product-card .stock-location {
    padding: 10px;
    background: #eeeeee;
    display: flex;
    margin-bottom: 8px;
    border: none; }
    .inventories-modal .ant-modal-body .main-inventory-container .product-card .stock-location .location-name {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .inventories-modal .ant-modal-body .main-inventory-container .product-card .stock-location .location-name p {
        color: black;
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 8px; }
    .inventories-modal .ant-modal-body .main-inventory-container .product-card .stock-location .stock-info {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .inventories-modal .ant-modal-body .main-inventory-container .product-card .stock-location .stock-info p {
        color: #585858;
        font-weight: 400;
        font-size: 10px;
        margin-bottom: 6px; }
    .inventories-modal .ant-modal-body .main-inventory-container .product-card .stock-location span {
      width: 12px;
      height: 12px;
      border-radius: 20px;
      border: 1px solid #707070;
      margin-right: 5px;
      margin-top: 4px;
      background: white; }

.inventories-modal .ant-modal-body .main-inventory-container .order-from-card hr {
  margin-top: 24px; }

.inventories-modal .ant-modal-body .main-inventory-container .order-from-card .order-heading {
  font-size: 14px;
  color: #989898;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 0px; }

.inventories-modal .ant-modal-body .main-inventory-container .order-from-card .order-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px; }
  .inventories-modal .ant-modal-body .main-inventory-container .order-from-card .order-info p {
    font-size: 12px;
    color: black;
    margin-bottom: 0px;
    flex: 1 1; }
  .inventories-modal .ant-modal-body .main-inventory-container .order-from-card .order-info .ant-input {
    width: 95px;
    border-color: black; }

.inventories-modal .ant-modal-body .main-inventory-container .inventory-btn {
  border: none;
  border-radius: 0px;
  background: #36c626;
  color: white;
  height: 36px; }

.inventories-modal .ant-modal-body .main-inventory-container .inventory-btn:disabled {
  background: rgba(54, 198, 38, 0.8); }

.modal-note {
  border: 1px solid #0068ff;
  background: #eaf3ff;
  text-align: center;
  padding: 12px 24px;
  font-size: 10px;
  color: #141414;
  margin-bottom: 12px;
  line-height: 18px;
  margin-top: 18px; }

.variants-modal .variant-container .variants-data .variant-header {
  background: black;
  display: flex;
  justify-content: space-between; }
  .variants-modal .variant-container .variants-data .variant-header p {
    color: white;
    margin-bottom: 0px;
    padding: 12px 24px; }

.variants-modal .variant-container .variants-data .variant-body {
  display: flex;
  margin-bottom: 10px;
  position: relative; }
  .variants-modal .variant-container .variants-data .variant-body .ant-select {
    flex: 1 1; }
    .variants-modal .variant-container .variants-data .variant-body .ant-select .ant-select-selection {
      background: #ececec;
      border-radius: 0px;
      height: 48px; }
    .variants-modal .variant-container .variants-data .variant-body .ant-select .ant-select-selection__rendered {
      line-height: 48px; }
  .variants-modal .variant-container .variants-data .variant-body .anticon.anticon-close-circle {
    position: absolute;
    z-index: 999;
    left: -8px;
    top: 15px;
    font-size: 19px;
    color: #707070; }
  .variants-modal .variant-container .variants-data .variant-body .ant-input-number {
    width: 100px;
    border: none;
    background: #ececec;
    border-radius: 0px;
    margin-left: 15px;
    height: 48px; }
    .variants-modal .variant-container .variants-data .variant-body .ant-input-number .ant-input-number-input {
      height: 48px; }

.variants-modal .variant-container .variants-data .total-quantity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px; }
  .variants-modal .variant-container .variants-data .total-quantity p {
    background: #eaf3ff;
    padding: 10px 24px;
    text-align: center;
    font-weight: 500;
    color: black;
    margin-bottom: 0px; }

.variants-modal .variant-container .variants-data .add-variant-btn {
  text-align: end; }
  .variants-modal .variant-container .variants-data .add-variant-btn .ant-btn-link {
    color: #ff0064;
    margin-bottom: 12px; }

.variants-modal .variant-container .variants-data .variant-footer {
  display: flex; }
  .variants-modal .variant-container .variants-data .variant-footer .ant-btn-primary {
    border: none;
    border-radius: 0px; }
  .variants-modal .variant-container .variants-data .variant-footer .save-btn {
    border: none;
    background: #36c626;
    border-radius: 0px;
    color: white;
    flex: 1 1;
    margin-left: 10px; }

.product_niche {
  margin-top: 26px; }
  .product_niche .ant-card-body {
    padding: 0px; }
    .product_niche .ant-card-body .niche-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      .product_niche .ant-card-body .niche-container .border-container {
        height: 5rem;
        border: 1px solid #d6d6d6; }
      .product_niche .ant-card-body .niche-container .border-container:hover {
        border: 1px solid #0068ff; }
      .product_niche .ant-card-body .niche-container .niche-column-selected {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: #d7f3d4;
        padding: 0.5rem 1.5rem;
        height: 3rem;
        position: relative;
        z-index: 1; }
        .product_niche .ant-card-body .niche-container .niche-column-selected .niche-name-selected {
          display: flex;
          align-items: center; }
          .product_niche .ant-card-body .niche-container .niche-column-selected .niche-name-selected .ant-col-md-8 {
            text-align: right; }
          .product_niche .ant-card-body .niche-container .niche-column-selected .niche-name-selected .anticon {
            font-size: 1.75rem; }
          .product_niche .ant-card-body .niche-container .niche-column-selected .niche-name-selected p {
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 0.625rem;
            margin-left: 0.5rem;
            color: black; }
      .product_niche .ant-card-body .niche-container .niche-column {
        display: flex;
        justify-content: center;
        flex-direction: column;
        background: unset;
        padding: 1rem 1.5rem;
        height: 5rem;
        position: relative;
        z-index: 1;
        cursor: pointer; }
        .product_niche .ant-card-body .niche-container .niche-column .niche-name {
          display: flex;
          align-items: center; }
          .product_niche .ant-card-body .niche-container .niche-column .niche-name .ant-col-md-8 {
            text-align: right; }
          .product_niche .ant-card-body .niche-container .niche-column .niche-name .anticon {
            font-size: 2rem; }
          .product_niche .ant-card-body .niche-container .niche-column .niche-name p {
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 0.75rem;
            margin-left: 0.5rem;
            color: black;
            letter-spacing: 0.02125rem; }
      .product_niche .ant-card-body .niche-container .niche-check {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.125rem 0.5rem 0.5rem 0.125rem;
        background: #2ee31b;
        color: white;
        border-radius: 0px 0px 1.5rem 0px;
        font-size: 0.625rem;
        z-index: 99; }
      .product_niche .ant-card-body .niche-container .ant-btn {
        font-size: 0.75rem;
        color: white;
        font-weight: 500;
        background: #24b914;
        border: unset;
        border-radius: 0px;
        height: 1.9375rem; }

.product-categories-card {
  margin-top: 26px; }
  .product-categories-card .ant-card-body {
    padding: 0px; }
    .product-categories-card .ant-card-body .product-categories {
      display: flex;
      max-height: 16rem; }
      .product-categories-card .ant-card-body .product-categories .selected-niche {
        padding: 1.5rem;
        background: #eef4fb;
        color: black;
        width: 15rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .product-categories-card .ant-card-body .product-categories .selected-niche .ant-btn.ant-btn-link span + .anticon {
          margin-left: 0.25rem; }
        .product-categories-card .ant-card-body .product-categories .selected-niche .niche-info {
          color: #0068ff;
          margin-bottom: 0.5rem; }
          .product-categories-card .ant-card-body .product-categories .selected-niche .niche-info p {
            font-size: 0.875rem;
            color: #0068ff;
            font-weight: 500;
            margin: 0.25rem 0 0 0; }
        .product-categories-card .ant-card-body .product-categories .selected-niche p {
          font-size: 0.75rem;
          font-weight: 400;
          color: black;
          margin-bottom: 0px; }
        .product-categories-card .ant-card-body .product-categories .selected-niche label.ant-checkbox-wrapper {
          margin-top: 13px;
          margin-bottom: 13px;
          font-size: 0.75rem;
          color: black; }
    .product-categories-card .ant-card-body .niche-category {
      flex: 1 1;
      display: flex;
      flex-wrap: wrap;
      padding: 1.5rem;
      overflow: auto;
      white-space: nowrap; }
      .product-categories-card .ant-card-body .niche-category .category-name {
        display: flex;
        width: 16rem;
        margin-left: 0px;
        margin-bottom: 0.625rem;
        font-size: 0.75rem; }
        .product-categories-card .ant-card-body .niche-category .category-name span.ant-checkbox {
          margin-right: 0; }
  .product-categories-card .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #2ee31b;
    border-color: #2ee31b; }
  .product-categories-card .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .product-categories-card .ant-checkbox:hover .ant-checkbox-inner,
  .product-categories-card .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #2ee31b; }

.catalog-product {
  letter-spacing: 0.032em !important;
  text-rendering: optimizelegibility;
  background: #f0f2f5;
  /* Track */
  /* Handle */
  /* Handle on hover */ }
  .catalog-product .loader {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(141, 141, 141, 0.3);
    border-radius: 50%;
    border-top-color: #212121;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }
  .catalog-product .center-details .product-title-wraper {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px; }
    .catalog-product .center-details .product-title-wraper .product-name {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      max-width: 445px;
      color: #212121;
      font-weight: 600;
      font-size: 20px;
      text-overflow: ellipsis;
      /* Needed to make it work */
      white-space: wrap; }
    .catalog-product .center-details .product-title-wraper .product-id-wraper {
      width: 190px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 8px;
      font-size: 12px;
      padding-left: 8px;
      color: #898989; }
      .catalog-product .center-details .product-title-wraper .product-id-wraper .product-id > p {
        margin-right: 4px; }
    .catalog-product .center-details .product-title-wraper .line {
      margin-right: 8px;
      margin-top: 8px; }
    .catalog-product .center-details .product-title-wraper .product-id {
      display: flex;
      margin-top: 8px; }
  .catalog-product .center-details .product-sub-heading {
    margin-bottom: 10px;
    font-size: 14px;
    color: #898989;
    margin-bottom: 20px; }
  .catalog-product .center-details .star-wraper {
    display: flex;
    align-items: center; }
    .catalog-product .center-details .star-wraper .stars {
      display: flex;
      align-items: center; }
  .catalog-product button {
    cursor: pointer; }
  .catalog-product .f8 {
    font-size: 8px; }
  .catalog-product .f12 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    word-wrap: break-word; }
  .catalog-product .f16 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.032em;
    word-wrap: break-word; }
  .catalog-product .f20 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.032em;
    word-wrap: break-word; }
  .catalog-product .mr0 {
    margin-right: 0px; }
  .catalog-product .ml0 {
    margin-left: 0px; }
  .catalog-product .mt0 {
    margin-top: 0px; }
  .catalog-product .mb0 {
    margin-bottom: 0px; }
  .catalog-product .mr8 {
    margin-right: 8px; }
  .catalog-product .ml8 {
    margin-left: 8px; }
  .catalog-product .mt8 {
    margin-top: 8px; }
  .catalog-product .mb8 {
    margin-bottom: 8px; }
  .catalog-product .mr16 {
    margin-right: 16px; }
  .catalog-product .ml16 {
    margin-left: 16px; }
  .catalog-product .mt16 {
    margin-top: 10px; }
  .catalog-product .mb16 {
    margin-bottom: 12px; }
  .catalog-product .w100 {
    width: 100%; }
  .catalog-product .py8 {
    padding-top: 8px;
    padding-bottom: 8px; }
  .catalog-product .px8 {
    padding-left: 8px;
    padding-right: 8px; }
  .catalog-product .py2 {
    padding-top: 2px;
    padding-bottom: 2px; }
  .catalog-product .px2 {
    padding-left: 2px;
    padding-right: 2px; }
  .catalog-product .semibold {
    font-weight: 500; }
  .catalog-product .bold {
    font-weight: 600; }
  .catalog-product .italic {
    font-style: italic; }
  .catalog-product .underline {
    text-decoration: underline !important; }
  .catalog-product .inline-list {
    list-style-type: none;
    margin-bottom: 0px !important;
    padding: 0; }
    .catalog-product .inline-list li {
      display: inline-block; }
  .catalog-product .blue-text {
    color: #1370fc; }
  .catalog-product .btn-green {
    background: #2bc822;
    border-color: #2bc822;
    height: auto;
    border-radius: 2px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); }
  .catalog-product .btn-blue {
    background: #1370fc;
    border-color: #1370fc;
    height: auto;
    border-radius: 2px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); }
  .catalog-product .top-section-container {
    background: white;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05); }
  .catalog-product .top-section {
    width: 1200px;
    margin: auto;
    padding-top: 35px;
    padding-bottom: 35px; }
  .catalog-product .bottom-section-container {
    width: 1200px;
    margin: auto;
    margin-top: 35px; }
  .catalog-product .bottom-section {
    width: calc(100% - 320px); }
  .catalog-product .basic-tags {
    background: #fff0c4;
    color: #707070;
    cursor: pointer; }
  .catalog-product .basic-tags:hover {
    background: #f3705a;
    color: white; }
  .catalog-product .qty-increment-btn {
    background: #1370fc;
    color: white;
    width: 24px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border: none; }
  .catalog-product .qty-decrement-btn {
    background: #1370fc;
    color: white;
    width: 24px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border: none; }
  .catalog-product .bottom-section {
    position: relative; }
  .catalog-product .bottom-section ul.tabs {
    position: -webkit-sticky;
    position: sticky;
    padding: 8px 0px 0px;
    top: 0;
    z-index: 9;
    background: #f0f2f5; }
  .catalog-product ul.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    .catalog-product ul.tabs li:first-of-type button {
      border-top-left-radius: 2px; }
    .catalog-product ul.tabs li:last-of-type button {
      border-top-right-radius: 2px; }
    .catalog-product ul.tabs li {
      display: inline-block;
      margin-right: 8px; }
      .catalog-product ul.tabs li button {
        min-width: -webkit-fit-content !important;
        min-width: -moz-fit-content !important;
        min-width: fit-content !important;
        max-width: 300px;
        padding-top: 8px;
        padding-left: 23px !important;
        padding-right: 23px !important;
        padding-bottom: 8px;
        border: none;
        background: #fcfcfc; }
    .catalog-product ul.tabs li.active button {
      background: #1370fc;
      color: #fff; }
  .catalog-product table.shipping-zones {
    width: 550px;
    border: 2px solid #e3e3e3;
    font-size: 12px;
    border-radius: 2px; }
  .catalog-product table.shipping-zones th {
    background: #f5f5f5;
    color: #898989;
    font-size: 12px;
    font-weight: 400;
    border-right: 2px solid #e3e3e3;
    text-align: center;
    padding: 8px 0px; }
  .catalog-product table.shipping-zones thead {
    border-bottom: 2px solid #e3e3e3; }
  .catalog-product table.shipping-zones th:last-child {
    background: #f5f5f5;
    color: #898989;
    border-right: 0px; }
  .catalog-product table.shipping-zones td:nth-child(1),
  .catalog-product table.shipping-zones td:nth-child(2) {
    background: #f5f5f5;
    color: #898989; }
  .catalog-product table.shipping-zones td:nth-child(1) {
    width: 72px;
    text-align: center;
    padding: 12px;
    border: 2px solid #e3e3e3;
    border-left: none;
    border-top: none; }
  .catalog-product table.shipping-zones td:nth-child(2) {
    width: 224px;
    padding: 12px;
    border: 2px solid #e3e3e3;
    border-top: none; }
  .catalog-product table.shipping-zones td:nth-child(3) {
    width: 96px;
    text-align: center;
    padding: 12px;
    border: 2px solid #e3e3e3;
    border-right: none;
    border-top: none; }
  .catalog-product .tabs-content {
    background: white;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
    padding: 24px 24px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px; }
    .catalog-product .tabs-content .tabs-content-container {
      max-width: 100%; }
  .catalog-product table.variants-table {
    min-width: 665px;
    font-size: 12px; }
    .catalog-product table.variants-table th {
      color: #898989;
      font-weight: 400;
      padding-bottom: 4px; }
    .catalog-product table.variants-table td {
      padding: 24px 16px;
      border: 2px solid #e3e3e3; }
      .catalog-product table.variants-table td img {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        object-fit: cover;
        margin-right: 8px; }
    .catalog-product table.variants-table td:nth-child(1) {
      background: #f5f5f5;
      color: #898989; }
  .catalog-product .description-tag {
    position: relative;
    font-size: 12px;
    padding: 4px 8px;
    background: #fff0c4;
    color: #707070;
    margin-right: 22px;
    margin-bottom: 8px; }
  .catalog-product .description-tag::after {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 14px solid #fff0c4; }
  .catalog-product .description-table {
    width: 100%;
    font-size: 12px;
    border-radius: 2px;
    overflow: hidden; }
    .catalog-product .description-table td {
      width: 50%;
      padding: 12px 16px;
      border: 2px solid #e3e3e3; }
    .catalog-product .description-table td:nth-child(1) {
      background: #f5f5f5;
      color: #898989; }
  .catalog-product .description-html-iframe {
    border: none;
    width: 100%; }
  .catalog-product .variants-selector::-webkit-scrollbar {
    width: 5px; }
  .catalog-product .variants-selector::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .catalog-product .variants-selector::-webkit-scrollbar-thumb {
    background: #1370fc; }
  .catalog-product .variants-selector::-webkit-scrollbar-thumb:hover {
    background: #555; }
  .catalog-product .catalog-name-tooltip {
    font-size: 12px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.032em;
    margin: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0px;
    overflow: hidden;
    cursor: pointer; }
  .catalog-product .catalog-name-tooltip .catalog-tooltip {
    display: none;
    background-color: #212121;
    color: #fff;
    font-weight: 400;
    text-align: center;
    border-radius: 2px;
    padding: 8px;
    position: absolute;
    z-index: 9;
    top: 40%;
    left: 0%;
    min-width: 160px;
    max-width: 240px;
    font-size: 12px;
    white-space: normal; }
  .catalog-product .catalog-name-tooltip .catalog-tooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #212121 transparent; }
  .catalog-product .catalog-name-tooltip:hover .catalog-tooltip {
    display: block; }

@keyframes animationdown {
  from {
    top: -100%; }
  to {
    top: 120px; } }
  .catalog-product .retail-price-checkbox .ant-checkbox-inner {
    width: 24px;
    height: 26px;
    margin-bottom: -2px;
    border-radius: 0px;
    border: 2px solid #b5dafe; }
  .catalog-product .retail-price-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1370fc !important;
    border-color: #1370fc !important;
    border-top-left-radius: 2px !important;
    border-right: 1px solid #fff !important; }
  .catalog-product .bulk-price-checkbox .ant-checkbox-inner {
    width: 24px;
    height: 26px;
    margin-bottom: -2px;
    border-radius: 0px;
    border: 2px solid #b5dafe; }
  .catalog-product .bulk-price-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1370fc !important;
    border-color: #1370fc !important;
    border-top-left-radius: 2px !important;
    border-right: 1px solid #fff !important; }
  .catalog-product .sea-freight-checkbox .ant-checkbox-inner {
    width: 24px;
    height: 26px;
    margin-bottom: -2px;
    border-radius: 0px;
    border: 2px solid #b5dafe; }
  .catalog-product .sea-freight-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1370fc !important;
    border-color: #1370fc !important;
    border-top-left-radius: 2px !important;
    border-right: 1px solid #fff !important; }
  .catalog-product .copied-id svg g path {
    padding-right: 4px; }
  .catalog-product .copied-id {
    padding-right: 8px; }
  .catalog-product .customize-data {
    display: flex;
    justify-content: flex-start; }
  .catalog-product .bulet-no {
    background: #f5f5f5;
    height: 8px;
    width: 8px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    border-radius: 50%; }
  .catalog-product .product-tooltip:hover svg.product-tooltip-icon {
    background: #1268fb;
    border-radius: 50%;
    cursor: pointer; }
  .catalog-product .product-tooltip:hover svg.product-tooltip-icon g text {
    fill: #fff; }
  .catalog-product .product-tooltip:hover svg.product-tooltip-icon g > g {
    stroke: #1268fb; }
  .catalog-product .add-to-store {
    margin-top: 16px !important;
    margin-bottom: 16px !important; }
    .catalog-product .add-to-store span {
      text-shadow: none;
      font-weight: 500; }
  .catalog-product .cost-updated {
    color: #898989;
    font-style: italic;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px; }
    .catalog-product .cost-updated span {
      color: #212121;
      margin-left: 4px; }
  .catalog-product .request-latest-price.disabled {
    color: #898989; }
    .catalog-product .request-latest-price.disabled svg {
      font-size: 16px;
      margin-right: 8px; }
  .catalog-product .request-latest-price {
    display: flex;
    color: #212121 !important;
    font-size: 10px !important; }
    .catalog-product .request-latest-price svg {
      color: #212121;
      font-size: 14px;
      margin-right: 5px;
      transition: all 0.3s;
      transform: rotate(0deg); }
  .catalog-product .request-latest-price:hover {
    display: flex;
    color: #212121 !important;
    transition: all 0.3s; }
    .catalog-product .request-latest-price:hover svg {
      transform: rotate(220deg) !important; }
      .catalog-product .request-latest-price:hover svg path {
        fill: #0058d6 !important; }
  .catalog-product .request-latest-price.disabled {
    color: #898989 !important;
    font-size: 10px !important;
    transition: none; }
    .catalog-product .request-latest-price.disabled svg {
      font-size: 14px !important;
      margin-right: 5px !important; }
      .catalog-product .request-latest-price.disabled svg path {
        fill: #898989 !important; }
  .catalog-product .request-latest-price.disabled:hover {
    color: #898989;
    transform: none; }
    .catalog-product .request-latest-price.disabled:hover svg {
      transform: rotate(0deg) !important; }
      .catalog-product .request-latest-price.disabled:hover svg path {
        fill: #898989 !important; }
  .catalog-product .last-updated-pricing {
    font-size: 10px !important;
    color: #898989;
    font-style: italic;
    margin-bottom: 12px !important; }
    .catalog-product .last-updated-pricing span {
      font-size: 10px !important;
      color: #212121;
      font-style: italic; }
  .catalog-product .bottom-pointer {
    display: flex;
    align-items: center;
    justify-content: center; }
    .catalog-product .bottom-pointer .f12 {
      font-weight: 300 !important;
      font-size: 12px !important;
      margin-right: 10px !important; }

.suggested-selling-price {
  margin-right: 8px; }

.star-parent {
  cursor: pointer; }

.star-parent:hover span .under {
  color: #e61047; }
  .star-parent:hover span .under span {
    text-decoration: underline; }

.ant-message {
  z-index: 99999 !important; }

.basic-tags-tooltip .ant-tooltip-arrow {
  border-top-color: #f3705a; }

.basic-tags-tooltip .ant-tooltip-inner {
  background: #f3705a;
  font-size: 12px;
  color: white; }

.basic-tags-tooltip .shipping-zones thead th {
  text-align: center;
  color: #898989;
  border-right: 1px solid #898989; }

@media screen and (min-width: 1360px) {
  .catalog-product .top-section {
    width: 90%;
    margin: auto; }
  .catalog-product .bottom-section-container {
    width: 1240px;
    margin: auto;
    margin-top: 35px; } }

.bulk-inventory-booking-confirmation-modal .ant-modal-close-x {
  display: none; }

.bulk-inventory-booking-confirmation-modal .f12 {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em; }

.bulk-inventory-booking-confirmation-modal .f16 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.032em; }

.bulk-inventory-booking-confirmation-modal .italic {
  font-style: italic; }

.bulk-inventory-booking-confirmation-modal .underline {
  text-decoration: underline !important; }

.bulk-inventory-booking-confirmation-modal .py8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.bulk-inventory-booking-confirmation-modal .w100 {
  width: 100%; }

.bulk-inventory-booking-confirmation-modal .py8 {
  padding-top: 8px;
  padding-bottom: 8px; }

.bulk-inventory-booking-confirmation-modal .px8 {
  padding-left: 8px;
  padding-right: 8px; }

.bulk-inventory-booking-confirmation-modal .btn-green {
  background: #2bc822;
  border-color: #2bc822;
  height: auto;
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2); }

.bulk-inventory-booking-confirmation-modal .ant-modal-content {
  border-radius: 2px;
  padding: 24px; }
  .bulk-inventory-booking-confirmation-modal .ant-modal-content .ant-modal-header {
    border-bottom: none;
    padding: 0; }
  .bulk-inventory-booking-confirmation-modal .ant-modal-content .ant-modal-body {
    padding: 0; }

.bulk-inventory-booking-confirmation-modal .btn-wraper {
  margin-top: 24px;
  margin-bottom: 0px; }
  .bulk-inventory-booking-confirmation-modal .btn-wraper .track-sourcing {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .bulk-inventory-booking-confirmation-modal .btn-wraper .track-sourcing a {
      margin-right: 6px;
      line-height: 10px; }
    .bulk-inventory-booking-confirmation-modal .btn-wraper .track-sourcing svg {
      font-size: 14px; }
      .bulk-inventory-booking-confirmation-modal .btn-wraper .track-sourcing svg path {
        stroke: #1268fb; }

.variant-vfsku {
  color: #212121;
  font-weight: 500;
  margin-left: 16px; }

.variant-vfsku-allspecs {
  margin-top: 5px;
  margin-left: 8px; }

.variant-allspecs-name-small {
  width: 80px;
  display: inline-flex; }

.variant-allspecs-name-larg {
  width: 120px;
  display: inline-flex; }

.line-global {
  font-size: 13px;
  margin-left: 8px;
  margin-right: 8px;
  color: #b2b2b2; }

.catalog-product .center-details .product-title-wraper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px; }
  .catalog-product .center-details .product-title-wraper .product-name {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 445px;
    color: #212121;
    font-weight: 600;
    font-size: 20px;
    text-overflow: ellipsis;
    /* Needed to make it work */
    white-space: wrap; }
  .catalog-product .center-details .product-title-wraper .product-id-wraper {
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 8px;
    font-size: 12px;
    padding-left: 8px;
    color: #898989; }
    .catalog-product .center-details .product-title-wraper .product-id-wraper .product-id > p {
      margin-right: 4px; }
  .catalog-product .center-details .product-title-wraper .line {
    margin-right: 8px;
    color: #b2b2b2;
    font-size: 12px;
    margin-top: 8px; }
  .catalog-product .center-details .product-title-wraper .product-id {
    display: flex;
    margin-top: 8px; }

.catalog-product .center-details .product-sub-heading {
  margin-bottom: 10px;
  font-size: 14px;
  color: #898989;
  margin-bottom: 20px; }

.catalog-product .center-details .star-wraper {
  display: flex;
  align-items: center; }
  .catalog-product .center-details .star-wraper .stars {
    display: flex;
    align-items: center; }
  .catalog-product .center-details .star-wraper .inventory-status {
    display: flex;
    align-items: center; }
    .catalog-product .center-details .star-wraper .inventory-status i {
      margin-right: 8px; }
    .catalog-product .center-details .star-wraper .inventory-status p {
      font-size: 12px;
      color: #898989; }
  .catalog-product .center-details .star-wraper .inventory-restocking-status {
    display: flex;
    align-items: center; }
    .catalog-product .center-details .star-wraper .inventory-restocking-status i {
      margin-right: 8px; }
    .catalog-product .center-details .star-wraper .inventory-restocking-status p {
      font-size: 12px;
      color: #898989; }
      .catalog-product .center-details .star-wraper .inventory-restocking-status p span {
        color: #212121; }

.catalog-product .center-details .bulk-pricing-wraper .heading {
  height: 32px;
  background-color: #26b99a;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 0px 16px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px; }

.catalog-product .center-details .bulk-pricing-wraper .retail-order-pricing-wraper {
  margin-bottom: 28px; }
  .catalog-product .center-details .bulk-pricing-wraper .retail-order-pricing-wraper .pricing-wraper {
    border: 2px solid #26b99a;
    display: flex;
    align-items: center;
    height: 64px; }
    .catalog-product .center-details .bulk-pricing-wraper .retail-order-pricing-wraper .pricing-wraper .price {
      width: 420px;
      display: flex;
      align-items: center;
      margin-left: 24px; }
      .catalog-product .center-details .bulk-pricing-wraper .retail-order-pricing-wraper .pricing-wraper .price p {
        color: #212121;
        font-size: 16px;
        font-weight: 500; }
      .catalog-product .center-details .bulk-pricing-wraper .retail-order-pricing-wraper .pricing-wraper .price span {
        color: #212121;
        font-size: 12px;
        font-weight: 400;
        margin-left: 8px; }
    .catalog-product .center-details .bulk-pricing-wraper .retail-order-pricing-wraper .pricing-wraper .description {
      font-size: 10px;
      color: #212121;
      font-style: italic;
      line-height: 16px; }

.catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper {
  margin-bottom: 5px; }
  .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .heading {
    height: 32px;
    background-color: #26b99a;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0px 16px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between; }
    .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .heading-wraper button {
      font-size: 12px;
      padding: 0px 16px;
      color: #898989;
      height: 24px;
      border: none;
      background-color: #f5f5f5;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px; }
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .heading-wraper button i {
        margin-left: 8px; }
  .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper {
    border: 2px solid #26b99a;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-headings,
    .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings,
    .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-row-data {
      background-color: #26b99a;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between; }
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-headings div,
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings div,
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-row-data div {
        display: flex;
        align-items: center;
        justify-content: center; }
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-headings .transit-time,
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .transit-time,
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .transit-time {
        width: 110px; }
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-headings .vf-days,
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .vf-days,
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .vf-days {
        width: 111px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-headings .vf-days i,
        .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .vf-days i,
        .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .vf-days i {
          margin: 0px 4px; }
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-headings :nth-child(3),
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(3),
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(3) {
        width: 110px; }
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-headings :nth-child(4),
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(4),
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(4) {
        width: 130px; }
    .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5; }
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings div {
        color: #898989; }
    .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-row-data {
      height: 48px;
      background-color: #fff; }
      .catalog-product .center-details .bulk-pricing-wraper .bulk-order-pricing-table-wraper .pricing-wraper .table-row-data div {
        color: #212121; }

.catalog-product .center-details .bulk-pricing-wraper .table-row-data:nth-child(even) {
  background-color: #f5f5f5 !important; }

.product-tooltip {
  display: flex;
  align-items: center;
  justify-content: center; }

.product-tooltip-icon {
  font-size: 16px; }

.product-tooltip:hover .product-tooltip-icon {
  background: #0058d6;
  border-radius: 50%;
  cursor: pointer; }
  .product-tooltip:hover .product-tooltip-icon svg g {
    stroke: #0058d6; }
  .product-tooltip:hover .product-tooltip-icon svg :last-child g {
    stroke: #fff; }

.product-tooltip:hover .product-tooltip-icon g text {
  fill: #fff; }

.product-tooltip:hover .product-tooltip-icon g > g {
  stroke: #0058d6; }

.raise-bulk-order-pricing-table-wraper {
  margin-bottom: 24px;
  margin-top: 10px; }
  .raise-bulk-order-pricing-table-wraper .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between; }
    .raise-bulk-order-pricing-table-wraper .heading-wraper .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #0058d6 !important;
      height: 32px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 0px 16px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px; }
      .raise-bulk-order-pricing-table-wraper .heading-wraper .heading svg {
        margin-right: 4px; }
  .raise-bulk-order-pricing-table-wraper .pricing-wraper {
    border: 2px solid #0058d6;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings {
      height: 30px;
      background-color: #0058d6;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .inventory-freight-type {
        display: flex;
        align-items: center; }
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .inventory-freight-type p {
          font-size: 12px;
          color: #fff; }
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .inventory-freight-type .golden-star {
          margin-right: 4px; }
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .inventory-freight-type svg {
          font-size: 12px; }
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .inventory-freight-type .type-select .ant-select-selection.ant-select-selection--single {
          border: none;
          background-color: transparent; }
          .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .inventory-freight-type .type-select .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
            line-height: 32px; }
          .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .inventory-freight-type .type-select .ant-select-selection.ant-select-selection--single .ant-select-selection-selected-value {
            font-size: 12px;
            display: block;
            color: #fff;
            opacity: 1;
            font-weight: 500;
            margin-right: 6px; }
          .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .inventory-freight-type .type-select .ant-select-selection.ant-select-selection--single .ant-select-arrow {
            right: 8px; }
          .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .inventory-freight-type .type-select .ant-select-selection.ant-select-selection--single svg {
            font-size: 16px;
            margin-top: -3px; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .transit-time {
        font-size: 12px;
        color: #fff;
        display: flex;
        align-items: center; }
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-headings .transit-time span {
          margin-left: 4px; }
    .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings,
    .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data {
      background-color: #0058d6;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings div,
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data div {
        display: flex;
        align-items: center;
        justify-content: center; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .transit-time,
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .transit-time {
        width: 110px; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .vf-days,
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .vf-days {
        width: 105px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .vf-days i,
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .vf-days i {
          margin: 0px 4px; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .vf-inventory,
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .vf-inventory {
        width: 105px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .vf-inventory i,
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .vf-inventory i {
          margin: 0px 4px; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .air-days,
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .air-days {
        width: 112px; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .booking-qty,
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .booking-qty {
        width: 115px; }
    .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings .variants {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 14px;
        min-width: 210px !important; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-sub-headings div {
        color: #898989; }
    .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data {
      height: 64px;
      background-color: #fff; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data div {
        color: #212121; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .variants-details {
        padding-left: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 210px !important; }
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .variants-details .img {
          height: 32px;
          width: 32px;
          border-radius: 2px;
          margin-right: 8px; }
          .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .variants-details .img img {
            height: 100%;
            object-fit: cover;
            width: inherit;
            border-radius: 2px; }
        .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .variants-details .sku-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
          .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .variants-details .sku-details p {
            color: #898989;
            font-size: 12px;
            margin-right: 4px; }
          .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .variants-details .sku-details span {
            color: #212121;
            font-size: 12px; }
          .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .variants-details .sku-details .sku {
            display: flex;
            align-items: center;
            justify-content: flex-start; }
          .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .variants-details .sku-details .specs {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .vf-inventory {
        font-size: 16px;
        font-weight: 600;
        color: #8b8b8b; }
      .raise-bulk-order-pricing-table-wraper .pricing-wraper .table-row-data .air-days {
        font-size: 16px;
        font-weight: 600;
        color: #212121; }
  .raise-bulk-order-pricing-table-wraper .booking-qty button:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
  .raise-bulk-order-pricing-table-wraper .booking-qty button:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px; }
  .raise-bulk-order-pricing-table-wraper .booking-qty button {
    font-size: 16px;
    border: none;
    background-color: #1370fc;
    height: 32px;
    width: 24px;
    color: #fff; }
  .raise-bulk-order-pricing-table-wraper .booking-qty input {
    width: 48px;
    height: 32px;
    font-size: 16px;
    color: #212121;
    outline: none;
    border: none;
    background: #f5f5f5;
    text-align: center; }
  .raise-bulk-order-pricing-table-wraper .booking-qty.disabled {
    pointer-events: none;
    opacity: 0.5; }
    .raise-bulk-order-pricing-table-wraper .booking-qty.disabled button {
      font-size: 16px;
      border: none;
      background-color: #1370fc;
      height: 32px;
      width: 24px;
      color: #fff; }

.raise-bulk-order-pricing-table-wraper .table-row-data:nth-child(even) {
  background-color: #f5f5f5 !important; }
  .raise-bulk-order-pricing-table-wraper .table-row-data:nth-child(even) input {
    background-color: #fff; }

@media screen and (max-width: 1280px) {
  .center-details {
    width: 504px; }
  .product-name {
    width: 342px !important;
    max-width: 342px !important; }
  ul.tabs {
    list-style-type: none;
    padding: 0;
    margin: 0; }
    ul.tabs li:first-of-type button {
      border-top-left-radius: 2px; }
    ul.tabs li:last-of-type button {
      border-top-right-radius: 2px; }
    ul.tabs li {
      display: inline-block;
      margin-right: 8px; }
      ul.tabs li button {
        min-width: 136px;
        max-width: 300px;
        padding-top: 8px;
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 8px;
        border: none;
        background: #fcfcfc; }
    ul.tabs li.active button {
      background: #1370fc;
      color: #fff; }
  .line-global {
    font-size: 13px;
    margin-left: 4px;
    margin-right: 4px;
    color: #b2b2b2; } }

.recommended-packaging-table-wraper {
  margin-bottom: 24px;
  margin-top: 32px; }
  .recommended-packaging-table-wraper .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between; }
    .recommended-packaging-table-wraper .heading-wraper .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f6222c;
      height: 32px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 0px 16px !important;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px; }
      .recommended-packaging-table-wraper .heading-wraper .heading svg {
        margin-right: 4px; }
  .recommended-packaging-table-wraper .pricing-wraper {
    border: 2px solid #f6222c;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .recommended-packaging-table-wraper .pricing-wraper .table-headings {
      height: 30px;
      background-color: #f6222c;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .recommended-packaging-table-wraper .pricing-wraper .table-headings .inventory-freight-type {
        display: flex;
        align-items: center; }
        .recommended-packaging-table-wraper .pricing-wraper .table-headings .inventory-freight-type p {
          font-size: 12px;
          color: #fff; }
        .recommended-packaging-table-wraper .pricing-wraper .table-headings .inventory-freight-type .golden-star {
          margin-right: 4px; }
        .recommended-packaging-table-wraper .pricing-wraper .table-headings .inventory-freight-type svg {
          font-size: 12px; }
        .recommended-packaging-table-wraper .pricing-wraper .table-headings .inventory-freight-type .type-select .ant-select-selection.ant-select-selection--single {
          border: none;
          background-color: transparent; }
          .recommended-packaging-table-wraper .pricing-wraper .table-headings .inventory-freight-type .type-select .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
            line-height: 32px; }
          .recommended-packaging-table-wraper .pricing-wraper .table-headings .inventory-freight-type .type-select .ant-select-selection.ant-select-selection--single .ant-select-selection-selected-value {
            font-size: 12px;
            display: block;
            color: #fff;
            opacity: 1;
            font-weight: 500;
            margin-right: 6px; }
          .recommended-packaging-table-wraper .pricing-wraper .table-headings .inventory-freight-type .type-select .ant-select-selection.ant-select-selection--single .ant-select-arrow {
            right: 8px; }
          .recommended-packaging-table-wraper .pricing-wraper .table-headings .inventory-freight-type .type-select .ant-select-selection.ant-select-selection--single svg {
            font-size: 16px;
            margin-top: -3px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-headings .transit-time {
        font-size: 12px;
        color: #fff;
        display: flex;
        align-items: center; }
        .recommended-packaging-table-wraper .pricing-wraper .table-headings .transit-time span {
          margin-left: 4px; }
    .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings,
    .recommended-packaging-table-wraper .pricing-wraper .table-row-data {
      background-color: #0058d6;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between; }
      .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings div,
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data div {
        display: flex;
        align-items: center;
        justify-content: center; }
      .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings .transit-time,
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .transit-time {
        width: 110px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings .vf-days,
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .vf-days {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings .vf-days i,
        .recommended-packaging-table-wraper .pricing-wraper .table-row-data .vf-days i {
          margin: 0px 4px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings .vf-inventory,
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .vf-inventory {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings .vf-inventory i,
        .recommended-packaging-table-wraper .pricing-wraper .table-row-data .vf-inventory i {
          margin: 0px 4px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings :nth-child(3),
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data :nth-child(3) {
        width: 110px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings :nth-child(4),
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data :nth-child(4) {
        width: 130px; }
    .recommended-packaging-table-wraper .pricing-wraper .table-row-data {
      cursor: pointer; }
    .recommended-packaging-table-wraper .pricing-wraper .table-row-data.inactive {
      pointer-events: none; }
    .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5; }
      .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings .MOQ {
        width: 160px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings .variants {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 14px;
        width: 210px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings div {
        color: #898989; }
      .recommended-packaging-table-wraper .pricing-wraper .table-sub-headings .blank {
        width: 120px; }
    .recommended-packaging-table-wraper .pricing-wraper .table-row-data {
      height: 64px;
      background-color: #fff; }
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data div {
        color: #212121; }
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .variants-details {
        padding-left: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 210px; }
        .recommended-packaging-table-wraper .pricing-wraper .table-row-data .variants-details .img {
          height: 32px;
          width: 32px;
          border-radius: 2px;
          margin-right: 8px; }
          .recommended-packaging-table-wraper .pricing-wraper .table-row-data .variants-details .img img {
            height: 100%;
            object-fit: cover;
            width: inherit;
            border-radius: 2px; }
        .recommended-packaging-table-wraper .pricing-wraper .table-row-data .variants-details .sku-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start; }
          .recommended-packaging-table-wraper .pricing-wraper .table-row-data .variants-details .sku-details p {
            color: #898989;
            font-size: 12px;
            margin-right: 4px;
            min-width: 70px; }
          .recommended-packaging-table-wraper .pricing-wraper .table-row-data .variants-details .sku-details span {
            color: #212121;
            font-size: 12px; }
          .recommended-packaging-table-wraper .pricing-wraper .table-row-data .variants-details .sku-details .sku {
            display: flex;
            align-items: center;
            justify-content: flex-start; }
          .recommended-packaging-table-wraper .pricing-wraper .table-row-data .variants-details .sku-details .specs {
            display: flex;
            align-items: center;
            justify-content: flex-start; }
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .vf-inventory {
        width: 120px; }
        .recommended-packaging-table-wraper .pricing-wraper .table-row-data .vf-inventory .Complimentary {
          font-size: 12px;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #9d24d6;
          width: 120px;
          height: 24px;
          border-radius: 2px;
          border: 1px solid #9d24d6; }
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .MOQ {
        width: 160px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .air-days {
        font-size: 16px;
        font-weight: 600;
        color: #212121; }
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .air-days.comp {
        font-size: 16px;
        font-weight: 600;
        color: #909090;
        text-decoration: line-through;
        -webkit-text-decoration-color: #212121;
                text-decoration-color: #212121;
        text-decoration-thickness: 2px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .booking-qty button:first-of-type {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .booking-qty button:last-of-type {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px; }
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .booking-qty button {
        font-size: 16px;
        border: none;
        background-color: #1370fc;
        height: 32px;
        width: 24px;
        color: #fff; }
      .recommended-packaging-table-wraper .pricing-wraper .table-row-data .booking-qty input {
        width: 48px;
        height: 32px;
        font-size: 16px;
        color: #212121;
        outline: none;
        border: none;
        text-align: center; }
    .recommended-packaging-table-wraper .pricing-wraper .footer-data {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 8px; }
      .recommended-packaging-table-wraper .pricing-wraper .footer-data a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 208px;
        border-radius: 2px;
        border: none;
        background: #f6222c;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 16px;
        height: 32px; }

.recommended-packaging-table-wraper .table-row-data:nth-child(even) {
  background-color: #f5f5f5 !important; }

.restocking-btn {
  transition: 0.3s all; }

.restocking-btn:hover {
  transition: 0.3s all;
  background: #1370fc !important;
  color: #fff !important; }
  .restocking-btn:hover svg {
    position: relative !important;
    animation: glide 0.3s linear alternate infinite !important; }
    .restocking-btn:hover svg path {
      fill: #fff; }

.rotationClass {
  border-radius: 50%;
  font-size: 16px;
  animation: pulse-gold 2.4s infinite, rotation 2.4s infinite; }

@keyframes pulse-gold {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #fbc52d; }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 8px rgba(12, 12, 12, 0); }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

@keyframes rotation {
  50% {
    transform: rotate(180deg); } }

@keyframes flip {
  50% {
    transform: rotateY(180deg); } }

.error-wraper-catalog {
  position: relative; }
  .error-wraper-catalog .ant-alert-error {
    height: 150px; }
  .error-wraper-catalog a {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 208px;
    border-radius: 2px;
    border: none;
    background: #fd4726;
    font-weight: 500;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 16px;
    height: 32px;
    left: 50%;
    transform: translateX(-50%);
    top: 50%; }

.floating-widget-wraper {
  width: 286px !important; }
  .floating-widget-wraper .pricing-container {
    padding: 12px 12px;
    background-color: #f5f5f5; }
    .floating-widget-wraper .pricing-container .horizental-line {
      height: 1px;
      background-color: #b2b2b2;
      margin-top: 12px;
      margin-bottom: 12px; }
    .floating-widget-wraper .pricing-container i {
      margin: 0 8px; }
    .floating-widget-wraper .pricing-container p {
      font-size: 12px;
      color: #898989; }
    .floating-widget-wraper .pricing-container span {
      font-size: 12px;
      color: #898989;
      font-weight: 500; }
    .floating-widget-wraper .pricing-container .float-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 8px; }
    .floating-widget-wraper .pricing-container .float-heading:nth-of-type(1) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 8px; }
      .floating-widget-wraper .pricing-container .float-heading:nth-of-type(1) p {
        font-size: 12px;
        margin-right: 4px; }
      .floating-widget-wraper .pricing-container .float-heading:nth-of-type(1) span {
        font-size: 12px;
        color: #0068ff;
        margin-left: 0px !important; }
    .floating-widget-wraper .pricing-container .float-heading:nth-of-type(2) i {
      margin: 0px !important;
      margin-left: 4px !important; }
    .floating-widget-wraper .pricing-container .price-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 8px; }
      .floating-widget-wraper .pricing-container .price-heading span {
        font-size: 16px;
        font-weight: 600;
        color: #212121; }
    .floating-widget-wraper .pricing-container .gst-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px; }
      .floating-widget-wraper .pricing-container .gst-heading p {
        font-style: italic; }
      .floating-widget-wraper .pricing-container .gst-heading span {
        font-size: 12px;
        font-weight: 400;
        color: #898989; }
      .floating-widget-wraper .pricing-container .gst-heading i {
        font-size: 16px; }
    .floating-widget-wraper .pricing-container .info-container {
      padding-top: 4px;
      padding-bottom: 6px; }
      .floating-widget-wraper .pricing-container .info-container p {
        font-style: italic; }
      .floating-widget-wraper .pricing-container .info-container i {
        font-size: 16px; }
      .floating-widget-wraper .pricing-container .info-container div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 6px; }
    .floating-widget-wraper .pricing-container .total-cost-container {
      border-top: 1px solid #b2b2b2;
      padding-top: 12px; }
      .floating-widget-wraper .pricing-container .total-cost-container .cost-wraper {
        display: flex;
        align-items: center; }
      .floating-widget-wraper .pricing-container .total-cost-container .total-cost {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 12px; }
        .floating-widget-wraper .pricing-container .total-cost-container .total-cost > p:first-of-type {
          text-decoration: underline; }
        .floating-widget-wraper .pricing-container .total-cost-container .total-cost .f16 {
          font-size: 16px;
          color: #212121;
          font-weight: 600;
          text-decoration: none !important; }
      .floating-widget-wraper .pricing-container .total-cost-container .gst-desc {
        font-size: 12px;
        color: #898989;
        text-align: center;
        font-size: 10px;
        font-style: italic; }
    .floating-widget-wraper .pricing-container .raise_booking_request button {
      border-radius: 2px;
      width: 100%;
      margin: 12px 0;
      font-size: 16px;
      color: #fff;
      height: 40px;
      background-color: #1370fc;
      box-shadow: #00000033 0px 2px 3px; }
      .floating-widget-wraper .pricing-container .raise_booking_request button span {
        color: #fff;
        font-size: 16px; }
    .floating-widget-wraper .pricing-container .raise_booking_request p {
      font-size: 12px;
      color: #898989;
      text-align: center;
      font-size: 10px;
      font-style: italic; }
    .floating-widget-wraper .pricing-container .raise_booking_request .ant-btn.ant-btn-cancel {
      background-color: #f5f5f5 !important; }
      .floating-widget-wraper .pricing-container .raise_booking_request .ant-btn.ant-btn-cancel span {
        color: #898989 !important; }
  .floating-widget-wraper .gst-heading-bottom {
    display: flex;
    align-items: center;
    margin-bottom: 12px; }
    .floating-widget-wraper .gst-heading-bottom i {
      margin-left: 4px;
      margin-right: 0px !important; }

.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(141, 141, 141, 0.3);
  border-radius: 50%;
  border-top-color: #212121;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

.rasing-bulk-order-modal * {
  box-sizing: border-box;
  letter-spacing: 0.032em;
  margin: 0;
  padding: 0; }

.rasing-bulk-order-modal .ant-modal-close-x {
  display: none; }

.rasing-bulk-order-modal p {
  font-size: 12px;
  color: #898989; }

.rasing-bulk-order-modal h1 {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 5px; }

.rasing-bulk-order-modal .ant-modal-header {
  padding-top: 24px;
  margin: 0 24px; }
  .rasing-bulk-order-modal .ant-modal-header p {
    margin-bottom: 12px; }

.rasing-bulk-order-modal .ant-modal-footer {
  margin: 0 24px;
  padding-bottom: 26px;
  padding-top: 16px;
  display: flex; }
  .rasing-bulk-order-modal .ant-modal-footer .note {
    width: 60px; }
    .rasing-bulk-order-modal .ant-modal-footer .note p:first-of-type {
      margin-bottom: 5px; }
    .rasing-bulk-order-modal .ant-modal-footer .note p {
      text-align: left;
      font-size: 10px;
      font-style: italic; }
  .rasing-bulk-order-modal .ant-modal-footer .note-desc p:first-of-type {
    margin-bottom: 5px; }
  .rasing-bulk-order-modal .ant-modal-footer .note-desc p {
    text-align: left;
    font-size: 10px;
    font-style: italic; }
  .rasing-bulk-order-modal .ant-modal-footer .btns {
    width: 700px;
    display: flex;
    justify-content: end;
    align-items: center;
    border-radius: 2px; }
    .rasing-bulk-order-modal .ant-modal-footer .btns button:first-of-type {
      width: 160px; }
    .rasing-bulk-order-modal .ant-modal-footer .btns button {
      font-size: 12px;
      font-weight: 500;
      width: 208px;
      height: 40px;
      border-radius: 2px !important; }

.rasing-bulk-order-modal .ant-modal-body {
  padding: 24px;
  padding-top: 16px;
  font-size: 12px; }
  .rasing-bulk-order-modal .ant-modal-body .content-headings {
    padding: 12px 16px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings div {
      display: flex;
      align-items: center;
      font-style: italic; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-1 {
      font-style: normal; }
      .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-1 span {
        margin-left: 4px;
        color: #0068ff; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-2 p {
      margin-right: 4px; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-2 i {
      font-size: 16px;
      margin-left: 8px; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-3 i {
      font-size: 16px;
      margin-left: 4px; }
  .rasing-bulk-order-modal .ant-modal-body .variants-headings {
    padding: 0px 16px 4px 16px;
    display: flex;
    justify-content: space-between; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-1 {
      width: 380px !important;
      justify-content: flex-start; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-2 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-3 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-4 {
      width: 100px; }
  .rasing-bulk-order-modal .ant-modal-body .variants-data-row {
    padding: 8px 16px;
    display: flex;
    height: 72px;
    background-color: #f5f5f5;
    margin-bottom: 8px;
    justify-content: space-between; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 {
      width: 380px !important;
      justify-content: flex-start; }
      .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .details {
        flex-direction: column;
        align-items: flex-start; }
        .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .details p {
          color: #898989;
          margin-right: 4px; }
        .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .details span {
          color: #212121; }
      .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .img {
        height: 56px;
        width: 56px;
        margin-right: 16px; }
        .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .img img {
          object-fit: cover;
          height: 100%;
          width: inherit; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-2 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-3 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-4 {
      width: 100px; }
  .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    height: 48px;
    background-color: #f5f5f5; }
    .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .included {
      font-style: italic;
      color: #898989;
      font-size: 12px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .total-price {
      display: flex;
      justify-content: end;
      align-items: center; }
      .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .total-price .gst {
        font-size: 12px;
        color: #898989; }
      .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .total-price .price {
        width: 100px;
        margin-left: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #212121; }

.rasing-bulk-order-modal .product-tooltip:hover svg.product-tooltip-icon {
  background: #1268fb;
  border-radius: 50%;
  cursor: pointer; }

.rasing-bulk-order-modal .product-tooltip:hover svg.product-tooltip-icon g text {
  fill: #fff; }

.rasing-bulk-order-modal .product-tooltip:hover svg.product-tooltip-icon g > g {
  stroke: #1268fb; }

.opopp {
  margin-left: 16px; }
  .opopp .dragger-packing {
    border-radius: 0px; }
  .opopp .io-container {
    width: 110px;
    margin-right: 10px;
    height: 110px;
    margin-bottom: 12px;
    display: inline-block;
    background-color: lavender; }
    .opopp .io-container svg {
      display: flex;
      margin: auto;
      height: 110px; }
  .opopp .cointainer {
    display: flex;
    margin-top: 12px; }
  .opopp .heading {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 12px;
    font-weight: 400; }
  .opopp .drage-image {
    padding: 24px 16px;
    background-color: #f5f5f5;
    margin-right: 8px;
    width: 110px;
    height: 110px; }
  .opopp .drage-image:hover {
    background-color: #1370fc;
    color: #fff; }
    .opopp .drage-image:hover p {
      color: #fff; }
  .opopp .drage-image:hover svg g path {
    fill: #fff; }
  .opopp .drage-image svg {
    position: relative;
    left: 33%; }
  .opopp .image-cointainer {
    position: relative; }
    .opopp .image-cointainer .icon-cointainer-pdf {
      position: absolute;
      height: 16px;
      width: 16px;
      display: inline-block;
      top: 2px;
      right: 12px;
      display: none; }
      .opopp .image-cointainer .icon-cointainer-pdf svg {
        height: 100%;
        width: 100%; }
    .opopp .image-cointainer .icon-cointainer {
      position: absolute;
      height: 16px;
      width: 16px;
      display: inline-block;
      top: -1px;
      right: 10px;
      display: none; }
      .opopp .image-cointainer .icon-cointainer svg {
        height: 100%;
        width: 100%; }
  .opopp .image-cointainer:hover .icon-cointainer {
    display: block; }
  .opopp .image-cointainer:hover .icon-cointainer-pdf {
    display: block; }

.recomended-packaging-modal * {
  box-sizing: border-box;
  letter-spacing: 0.032em !important;
  margin: 0;
  padding: 0; }

.recomended-packaging-modal .ant-modal-close-x {
  display: none; }

.recomended-packaging-modal p {
  font-size: 12px;
  color: #898989; }

.recomended-packaging-modal h1 {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 5px; }

.recomended-packaging-modal .ant-modal-header {
  padding-top: 30px;
  margin: 0 24px;
  padding-bottom: 24px; }
  .recomended-packaging-modal .ant-modal-header p {
    margin-bottom: 12px;
    text-align: center; }
  .recomended-packaging-modal .ant-modal-header .ant-modal-title > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px; }
    .recomended-packaging-modal .ant-modal-header .ant-modal-title > div svg {
      margin-right: 10px;
      font-size: 60px;
      margin-bottom: 14px; }
    .recomended-packaging-modal .ant-modal-header .ant-modal-title > div h1 {
      line-height: 16px;
      margin-bottom: 4px; }

.recomended-packaging-modal .ant-modal-footer {
  margin: 0 24px;
  padding-bottom: 26px;
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .recomended-packaging-modal .ant-modal-footer .btns {
    display: flex;
    justify-content: end;
    align-items: center; }
    .recomended-packaging-modal .ant-modal-footer .btns button:first-of-type {
      width: 160px;
      margin-right: 8px;
      border-radius: 2px !important; }
    .recomended-packaging-modal .ant-modal-footer .btns button {
      font-size: 12px;
      font-weight: 500;
      width: 208px;
      height: 40px; }
    .recomended-packaging-modal .ant-modal-footer .btns a {
      height: 40px;
      width: 208px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px !important; }
  .recomended-packaging-modal .ant-modal-footer .green-outline {
    background: #fff !important;
    margin-right: 8px;
    width: 100px;
    font-size: 12px;
    color: #2a9c27 !important;
    border: 1px solid #2a9c27; }
  .recomended-packaging-modal .ant-modal-footer .clickable-dropdown.custom-width {
    margin-top: 12px;
    width: 376px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px; }

.recomended-packaging-modal .ant-modal-body {
  padding: 24px;
  padding-top: 20px;
  font-size: 12px; }
  .recomended-packaging-modal .ant-modal-body .head-1 {
    width: 480px;
    justify-content: flex-start !important; }
  .recomended-packaging-modal .ant-modal-body .head-2 {
    width: 100px; }
    .recomended-packaging-modal .ant-modal-body .head-2 .Complimentary {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 120px;
      color: #9d24d6;
      border-radius: 2px;
      border: 1px solid #9d24d6;
      font-weight: 500;
      padding: 0 8px; }
  .recomended-packaging-modal .ant-modal-body .head-3 {
    width: 100px; }
  .recomended-packaging-modal .ant-modal-body .head-4 {
    width: 100px; }
  .recomended-packaging-modal .ant-modal-body .variants-headings {
    padding: 0px 16px 4px 16px;
    display: flex;
    justify-content: space-between; }
    .recomended-packaging-modal .ant-modal-body .variants-headings div {
      display: flex;
      align-items: center;
      justify-content: center; }
  .recomended-packaging-modal .ant-modal-body .variants-data-row {
    padding: 8px 16px;
    display: flex;
    height: 72px;
    background-color: #f5f5f5;
    margin-bottom: 8px;
    justify-content: space-between; }
    .recomended-packaging-modal .ant-modal-body .variants-data-row div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 {
      justify-content: flex-start; }
      .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details {
        flex-direction: column;
        align-items: flex-start; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details p {
          color: #898989;
          margin-right: 4px; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details span {
          color: #212121; }
      .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .img {
        height: 56px;
        width: 56px;
        margin-right: 16px; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .img img {
          object-fit: cover;
          height: 100%;
          width: inherit; }

.recomended-packaging-modal.hide-border .ant-modal-header {
  border-bottom: none; }

.recomended-packaging-modal.hide-border .ant-modal-body {
  display: none; }

.recomended-packaging-modal .btns {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px; }
  .recomended-packaging-modal .btns button {
    width: 100% !important;
    margin: 0px !important; }

.recomended-packaging-modal * {
  box-sizing: border-box;
  letter-spacing: 0.032em !important;
  margin: 0;
  padding: 0; }

.recomended-packaging-modal .ant-modal-close-x {
  display: none; }

.recomended-packaging-modal p {
  font-size: 12px;
  color: #898989; }

.recomended-packaging-modal h1 {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 5px; }

.recomended-packaging-modal .ant-modal-header {
  padding-top: 30px;
  margin: 0 24px;
  padding-bottom: 24px; }
  .recomended-packaging-modal .ant-modal-header p {
    margin-bottom: 12px;
    text-align: center; }
  .recomended-packaging-modal .ant-modal-header .ant-modal-title > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px; }
    .recomended-packaging-modal .ant-modal-header .ant-modal-title > div svg {
      margin-right: 10px;
      font-size: 60px;
      margin-bottom: 14px; }
    .recomended-packaging-modal .ant-modal-header .ant-modal-title > div h1 {
      line-height: 16px;
      margin-bottom: 4px; }

.recomended-packaging-modal .ant-modal-footer {
  margin: 0 24px;
  padding-bottom: 26px;
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .recomended-packaging-modal .ant-modal-footer .btns {
    display: flex;
    justify-content: end;
    align-items: center; }
    .recomended-packaging-modal .ant-modal-footer .btns button:first-of-type {
      width: 160px;
      margin-right: 8px;
      border-radius: 2px !important; }
    .recomended-packaging-modal .ant-modal-footer .btns button {
      font-size: 12px;
      font-weight: 500;
      width: 208px;
      height: 40px; }
    .recomended-packaging-modal .ant-modal-footer .btns a {
      height: 40px;
      width: 208px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px !important; }
  .recomended-packaging-modal .ant-modal-footer .green-outline {
    background: #fff !important;
    margin-right: 8px;
    width: 100px;
    font-size: 12px;
    color: #2a9c27 !important;
    border: 1px solid #2a9c27; }
  .recomended-packaging-modal .ant-modal-footer .clickable-dropdown.custom-width {
    margin-top: 12px;
    width: 376px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px; }

.recomended-packaging-modal .ant-modal-body {
  padding: 24px;
  padding-top: 20px;
  font-size: 12px; }
  .recomended-packaging-modal .ant-modal-body .head-1 {
    width: 480px;
    justify-content: flex-start !important; }
  .recomended-packaging-modal .ant-modal-body .head-2 {
    width: 100px; }
    .recomended-packaging-modal .ant-modal-body .head-2 .Complimentary {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 120px;
      color: #9d24d6;
      border-radius: 2px;
      border: 1px solid #9d24d6;
      font-weight: 500;
      padding: 0 8px; }
  .recomended-packaging-modal .ant-modal-body .head-3 {
    width: 100px; }
  .recomended-packaging-modal .ant-modal-body .head-4 {
    width: 100px; }
  .recomended-packaging-modal .ant-modal-body .variants-headings {
    padding: 0px 16px 4px 16px;
    display: flex;
    justify-content: space-between; }
    .recomended-packaging-modal .ant-modal-body .variants-headings div {
      display: flex;
      align-items: center;
      justify-content: center; }
  .recomended-packaging-modal .ant-modal-body .variants-data-row {
    padding: 8px 16px;
    display: flex;
    height: 72px;
    background-color: #f5f5f5;
    margin-bottom: 8px;
    justify-content: space-between; }
    .recomended-packaging-modal .ant-modal-body .variants-data-row div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 {
      justify-content: flex-start; }
      .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details {
        flex-direction: column;
        align-items: flex-start; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details p {
          color: #898989;
          margin-right: 4px; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details span {
          color: #212121; }
      .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .img {
        height: 56px;
        width: 56px;
        margin-right: 16px; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .img img {
          object-fit: cover;
          height: 100%;
          width: inherit; }

.recomended-packaging-modal.hide-border .ant-modal-header {
  border-bottom: none; }

.recomended-packaging-modal.hide-border .ant-modal-body {
  display: none; }

.addfundsmodal {
  top: 32px; }
  .addfundsmodal .addfunds-tabs .ant-tabs-nav-animated {
    width: 100%; }
    .addfundsmodal .addfunds-tabs .ant-tabs-nav-animated > div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .addfundsmodal .addfunds-tabs .ant-tabs-nav-animated > div .ant-tabs-tab {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center; }
  .addfundsmodal .fund-err {
    color: crimson; }
  .addfundsmodal .ant-modal-header {
    padding: 0px 0px; }
    .addfundsmodal .ant-modal-header .head-background {
      background: url(/static/media/add-funds-background.622089dc.svg);
      padding: 30px 24px;
      background-repeat: no-repeat;
      background-size: cover;
      text-align: center; }
      .addfundsmodal .ant-modal-header .head-background h1 {
        font-size: 32px;
        color: white;
        font-weight: bold;
        margin-bottom: 8px; }
      .addfundsmodal .ant-modal-header .head-background p {
        font-size: 16px;
        color: white;
        font-weight: 600;
        margin-bottom: 0px;
        letter-spacing: 1px; }
  .addfundsmodal .ant-modal-body .funds-input {
    display: flex;
    align-items: center;
    justify-content: center; }
    .addfundsmodal .ant-modal-body .funds-input p {
      margin-bottom: 0px;
      font-size: 32px;
      color: black; }
    .addfundsmodal .ant-modal-body .funds-input .ant-input {
      border: none;
      border-bottom: 1px solid black;
      border-radius: 0px;
      width: 200px;
      margin-left: 10px;
      margin-right: 10px;
      height: 41px;
      color: black;
      font-size: 16px; }
    .addfundsmodal .ant-modal-body .funds-input .ant-input:focus {
      box-shadow: unset; }
    .addfundsmodal .ant-modal-body .funds-input span {
      padding: 9px 24px;
      background: #d6d6d6;
      font-size: 16px;
      font-weight: 500;
      color: black; }
  .addfundsmodal .ant-modal-body .funds-amount {
    margin-top: 30px;
    display: flex;
    justify-content: space-between; }
    .addfundsmodal .ant-modal-body .funds-amount span {
      padding: 9px 24px;
      background: #e3eeff;
      font-size: 16px;
      font-weight: 500;
      color: black;
      cursor: pointer; }
  .addfundsmodal .ant-modal-body .total-fee,
  .addfundsmodal .ant-modal-body .total-amount {
    padding: 9px 24px;
    background: #d6d6d6;
    font-size: 16px;
    font-weight: 500;
    color: black;
    margin-top: 15px; }
    .addfundsmodal .ant-modal-body .total-fee p,
    .addfundsmodal .ant-modal-body .total-amount p {
      margin: 0;
      font-weight: normal; }
  .addfundsmodal .ant-modal-body .total-name p {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #00b700; }
  .addfundsmodal .ant-modal-body .select-payment {
    background: #f7f7f7; }
    .addfundsmodal .ant-modal-body .select-payment .wire-div,
    .addfundsmodal .ant-modal-body .select-payment .cc-div {
      position: relative; }
    .addfundsmodal .ant-modal-body .select-payment .abs-text {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 10px;
      font-style: italic; }
    .addfundsmodal .ant-modal-body .select-payment p {
      font-size: 16px;
      color: white;
      font-weight: 600;
      margin-bottom: 0px;
      padding: 10px;
      background: #72a0ff;
      letter-spacing: 1px; }
    .addfundsmodal .ant-modal-body .select-payment .ant-radio-group {
      width: 100%; }
    .addfundsmodal .ant-modal-body .select-payment .ant-radio-button-wrapper-checked {
      color: #fff;
      background: #e2ffe4;
      border: 2px solid #00b50e !important;
      border-radius: 0px;
      width: 100%;
      height: 48px; }
    .addfundsmodal .ant-modal-body .select-payment .ant-radio-button-wrapper {
      color: #fff;
      background: #f7f7f7;
      border: 2px solid #f7f7f7;
      border-radius: 0px;
      width: 100%;
      height: 48px;
      font-size: 14px;
      color: black;
      padding: 0 30px;
      background: unset;
      margin-bottom: 0px;
      box-shadow: unset; }
      .addfundsmodal .ant-modal-body .select-payment .ant-radio-button-wrapper span {
        display: flex;
        align-items: center; }
      .addfundsmodal .ant-modal-body .select-payment .ant-radio-button-wrapper .anticon {
        font-size: 40px;
        margin-right: 20px; }
      .addfundsmodal .ant-modal-body .select-payment .ant-radio-button-wrapper .feeCharge {
        font-size: 10px;
        color: black;
        font-style: italic; }
  .addfundsmodal .ant-modal-footer {
    padding: 0px 0px; }
    .addfundsmodal .ant-modal-footer .ant-btn {
      text-align: center;
      background: #336ae2;
      font-size: 16px;
      color: white;
      font-weight: bold;
      letter-spacing: 1px;
      height: 46px;
      border-radius: 0px;
      border: none; }
      .addfundsmodal .ant-modal-footer .ant-btn .anticon {
        transform: rotate(180deg);
        margin-left: 10px; }
  .addfundsmodal button.ant-modal-close {
    color: white; }

.transactionSuccessModal .transaction-sucess-content h1 {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  margin-top: 10px;
  color: #242424; }

.transactionSuccessModal .transaction-sucess-content p {
  font-size: 16px;
  color: #242424;
  font-weight: 500; }

.transactionSuccessModal .transaction-sucess-content .anticon {
  font-size: 55px; }

.transactionSuccessModal .footer-button {
  margin-top: 30px; }
  .transactionSuccessModal .footer-button .ant-btn {
    padding: 0px 16px;
    text-align: center;
    background: #00b50e;
    font-size: 18px;
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
    height: 48px;
    border-radius: 0px;
    border: none; }
    .transactionSuccessModal .footer-button .ant-btn .anticon {
      transform: rotate(180deg);
      margin-left: 10px; }

.transactionFailedModal .transaction-failed-content h1 {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  margin-top: 10px;
  color: #242424; }

.transactionFailedModal .transaction-failed-content p {
  font-size: 16px;
  color: #242424;
  font-weight: 500; }

.transactionFailedModal .transaction-failed-content .anticon {
  font-size: 55px; }

.transactionFailedModal .footer-button {
  margin-top: 30px; }
  .transactionFailedModal .footer-button span {
    display: inline-block;
    vertical-align: middle;
    color: #a3a3a3;
    font-size: 13px;
    margin-right: 15px;
    cursor: pointer; }
  .transactionFailedModal .footer-button .ant-btn {
    padding: 0px 16px;
    text-align: center;
    background: #d75a4a;
    font-size: 18px;
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
    height: 40px;
    border-radius: 0px;
    border: none;
    width: 125px; }
    .transactionFailedModal .footer-button .ant-btn span {
      color: white;
      margin: 0; }

.stripe-screen-wraper2 .stripe-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 36px;
  padding-bottom: 44px; }
  .stripe-screen-wraper2 .stripe-header h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px; }

.stripe-screen-wraper2 .stripe-card-wraper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 32px;
  gap: 32px; }
  .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper .total-name-2 p {
    margin-bottom: 20px;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    color: #00b700; }
  .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper .total-name-2 .urd-amount {
    font-weight: 500;
    color: #1d4294; }
  .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper .hed {
    font-size: 16px;
    font-weight: 600; }
  .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper h1 {
    display: flex;
    justify-content: space-between; }
  .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper h2 {
    font-size: 12px;
    font-weight: 400;
    color: #536be8;
    cursor: pointer;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 4px;
    gap: 4px; }
    .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper h2 svg {
      fill: #536be8; }
  .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper .payment-element {
    width: 500px;
    min-height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 12px;
    background-color: #f7fafc !important; }
    .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper {
      display: flex;
      align-items: center;
      justify-content: center; }
      .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper button {
        height: 40px;
        border: 1px solid #536be8;
        margin-bottom: 8px;
        margin-top: 20px;
        padding-left: 40px;
        padding-right: 40px;
        background-color: #536be8; }
        .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper button i svg {
          fill: #fff !important; }
        .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper .payment-element .btn-wraper button span {
          color: #fff;
          font-size: 14px;
          font-weight: 600; }
  .stripe-screen-wraper2 .stripe-card-wraper .payment-element-wraper .secure {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 6px;
    gap: 6px;
    color: #898989;
    font-size: 14px;
    margin-top: 20px; }
  .stripe-screen-wraper2 .stripe-card-wraper .preview-card-wraper .plan-card {
    padding-bottom: 14px !important;
    grid-gap: 8px !important;
    gap: 8px !important;
    padding-bottom: 20px; }
    .stripe-screen-wraper2 .stripe-card-wraper .preview-card-wraper .plan-card .price-card {
      grid-gap: 0px !important;
      gap: 0px !important; }
    .stripe-screen-wraper2 .stripe-card-wraper .preview-card-wraper .plan-card .bottom-bar {
      display: none; }
  .stripe-screen-wraper2 .stripe-card-wraper .preview-card-wraper .shaker-card.plan-card {
    margin-top: 0px !important; }
  .stripe-screen-wraper2 .stripe-card-wraper .preview-card-wraper .shaker-cards-wraper {
    grid-gap: 8px !important;
    gap: 8px !important; }
  .stripe-screen-wraper2 .stripe-card-wraper .preview-card-wraper .change-plan {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px; }
    .stripe-screen-wraper2 .stripe-card-wraper .preview-card-wraper .change-plan h1 {
      font-size: 16px;
      font-weight: 600; }

@media screen and (max-width: 800px) {
  .stripe-screen-wraper {
    padding: 12px; }
    .stripe-screen-wraper .stripe-header h1 {
      text-align: center; }
    .stripe-screen-wraper .payment-element {
      width: 450px !important; } }

@media screen and (max-width: 500px) {
  .ant-modal.app-subscription-modal.screen-2 {
    width: calc(100vw - 16px) !important; }
  .stripe-screen-wraper {
    padding: 12px; }
    .stripe-screen-wraper .stripe-header h1 {
      text-align: center; }
    .stripe-screen-wraper .payment-element {
      width: 300px !important; } }

.wireSuccessModal .copy-all {
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 6px; }

.wireSuccessModal .transaction-sucess-content h1 {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  margin-top: 10px;
  color: #242424; }

.wireSuccessModal .transaction-sucess-content p {
  font-size: 16px;
  color: #242424;
  font-weight: 500; }

.wireSuccessModal .transaction-sucess-content .anticon {
  font-size: 55px; }

.wireSuccessModal .account-details p {
  color: #242424;
  font-size: 14px; }
  .wireSuccessModal .account-details p span {
    font-weight: 600 !important; }

.wireSuccessModal .copy-message {
  background: #f6f6f6;
  padding: 20px;
  margin-top: 24px; }
  .wireSuccessModal .copy-message p {
    color: #242424;
    font-size: 14px; }
    .wireSuccessModal .copy-message p span {
      font-weight: 600; }
  .wireSuccessModal .copy-message .ant-btn {
    padding: 0px 16px;
    text-align: center;
    background: #00b50e;
    font-size: 18px;
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
    height: 48px;
    width: 320px;
    border-radius: 2px;
    border: none; }

.wireSuccessModal .footer-button {
  margin-top: 30px;
  margin-top: 12px; }
  .wireSuccessModal .footer-button .ant-btn {
    font-size: 18px;
    font-weight: 600;
    color: #00b50e; }
    .wireSuccessModal .footer-button .ant-btn .anticon {
      transform: rotate(180deg);
      margin-left: 10px;
      color: #00b50e; }
      .wireSuccessModal .footer-button .ant-btn .anticon path {
        fill: #00b50e; }

.wireSuccessModal1 .copy-all {
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  text-decoration: underline;
  margin-top: 6px; }

.wireSuccessModal1 .ant-modal-body {
  min-height: 631px; }

.wireSuccessModal1 .transaction-sucess-content h1 {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  margin-top: 10px;
  color: #242424; }

.wireSuccessModal1 .transaction-sucess-content p {
  font-size: 16px;
  color: #242424;
  font-weight: 500; }

.wireSuccessModal1 .transaction-sucess-content .anticon {
  font-size: 55px; }

.wireSuccessModal1 .account-details p {
  color: #242424;
  font-size: 14px; }
  .wireSuccessModal1 .account-details p span {
    font-weight: 600 !important; }

.wireSuccessModal1 .copy-message {
  background: #f6f6f6;
  padding: 20px;
  margin-top: 34px; }
  .wireSuccessModal1 .copy-message p {
    color: #242424;
    font-size: 14px; }
    .wireSuccessModal1 .copy-message p span {
      font-weight: 600; }
  .wireSuccessModal1 .copy-message .ant-btn {
    padding: 0px 16px;
    text-align: center;
    background: #00b50e;
    font-size: 18px;
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
    height: 48px;
    width: 320px;
    border-radius: 2px;
    border: none; }

.wireSuccessModal1 .footer-button {
  margin-top: 30px;
  margin-top: 36px; }
  .wireSuccessModal1 .footer-button .ant-btn {
    font-size: 18px;
    font-weight: 600;
    color: #00b50e; }
    .wireSuccessModal1 .footer-button .ant-btn .anticon {
      transform: rotate(180deg);
      margin-left: 10px;
      color: #00b50e; }
      .wireSuccessModal1 .footer-button .ant-btn .anticon path {
        fill: #00b50e; }

.global-tooltip-w {
  display: flex;
  align-items: center;
  justify-content: center; }

.global-tooltip-icon-w {
  font-size: 16px;
  margin: 0px 8px !important; }

.global-tooltip-w:hover .global-tooltip-icon-w {
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer; }
  .global-tooltip-w:hover .global-tooltip-icon-w svg g {
    stroke: #ffffff; }
  .global-tooltip-w:hover .global-tooltip-icon-w svg :last-child g {
    stroke: #26b99a; }

.global-tooltip-w:hover .global-tooltip-icon-w g text {
  fill: #fff; }

.global-tooltip-w:hover .global-tooltip-icon-w g > g {
  stroke: #ffffff; }

.field-value {
  font-size: 12px;
  position: relative;
  top: 5px; }
  .field-value .iconCantainer {
    margin-left: 2px;
    position: relative; }

.field-value:hover svg #Ellipse_44 {
  stroke: #1268fb;
  fill: #1268fb; }

.field-value:hover svg #Ellipse_47 {
  stroke: #fff;
  fill: #ffff; }

.field-value:hover svg #Rectangle_2462 {
  stroke: #fff;
  fill: #ffff; }

.field-value1 {
  font-size: 12px;
  position: relative;
  top: 9px; }

.edd-test.custom .edd-log-text {
  display: flex !important;
  flex-direction: column !important; }

.variants-table-pricing-table-wraper {
  margin-bottom: 24px;
  margin-top: 20px; }
  .variants-table-pricing-table-wraper .heading {
    height: 32px;
    background-color: #26b99a;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0px 16px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .variants-table-pricing-table-wraper .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between; }
  .variants-table-pricing-table-wraper .pricing-wraper {
    border: 2px solid #26b99a;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .variants-table-pricing-table-wraper .pricing-wraper .table-headings,
    .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings,
    .variants-table-pricing-table-wraper .pricing-wraper .table-row-data {
      background-color: #26b99a;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings div,
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings div,
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data div {
        display: flex;
        align-items: center;
        justify-content: center; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings .transit-time,
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings .transit-time,
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data .transit-time {
        width: 160px;
        padding-left: 16px;
        justify-content: flex-start; }
        .variants-table-pricing-table-wraper .pricing-wraper .table-headings .transit-time .img,
        .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings .transit-time .img,
        .variants-table-pricing-table-wraper .pricing-wraper .table-row-data .transit-time .img {
          height: 24px;
          width: 24px;
          border-radius: 2px;
          margin-right: 8px; }
          .variants-table-pricing-table-wraper .pricing-wraper .table-headings .transit-time .img img,
          .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings .transit-time .img img,
          .variants-table-pricing-table-wraper .pricing-wraper .table-row-data .transit-time .img img {
            height: 100%;
            object-fit: cover;
            width: inherit;
            border-radius: 2px; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings .vf-days,
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings .vf-days,
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data .vf-days {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(3),
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(3),
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(3) {
        width: 120px;
        justify-content: flex-start; }
        .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(3) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(3) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(3) p {
          margin-right: 6px; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(4),
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(4),
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(4) {
        width: 110px;
        justify-content: flex-start; }
        .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(4) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(4) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(4) p {
          margin-right: 6px; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(5),
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(5),
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(5) {
        width: 153px !important;
        align-items: center;
        justify-content: flex-start; }
        .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(5) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(5) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(5) p {
          margin-right: 6px; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(6),
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(6),
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(6) {
        width: 90px; }
    .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings div {
        color: #898989; }
    .variants-table-pricing-table-wraper .pricing-wraper .table-row-data {
      height: 48px;
      background-color: #fff; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data div {
        color: #212121; }
    .variants-table-pricing-table-wraper .pricing-wraper .table-row-data:nth-child(odd) {
      background-color: #f5f5f5 !important; }

.inventory-restocking-table-wraper {
  margin-bottom: 24px; }
  .inventory-restocking-table-wraper .heading {
    height: 32px;
    background-color: #26b99a;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0px 16px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .inventory-restocking-table-wraper .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between; }
    .inventory-restocking-table-wraper .heading-wraper button {
      font-size: 12px;
      padding: 0px 16px;
      color: #898989;
      height: 24px;
      border: none;
      background-color: #f5f5f5;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px; }
      .inventory-restocking-table-wraper .heading-wraper button i {
        margin-left: 8px; }
  .inventory-restocking-table-wraper .pricing-wraper {
    border: 2px solid #26b99a;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .inventory-restocking-table-wraper .pricing-wraper .table-headings,
    .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings,
    .inventory-restocking-table-wraper .pricing-wraper .table-row-data {
      background-color: #26b99a;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between; }
      .inventory-restocking-table-wraper .pricing-wraper .table-headings div,
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings div,
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data div {
        display: flex;
        align-items: center;
        justify-content: center; }
      .inventory-restocking-table-wraper .pricing-wraper .table-headings .transit-time,
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings .transit-time,
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data .transit-time {
        width: 340px;
        justify-content: flex-start;
        padding-left: 16px; }
      .inventory-restocking-table-wraper .pricing-wraper .table-headings .vf-days,
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings .vf-days,
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data .vf-days {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .inventory-restocking-table-wraper .pricing-wraper .table-headings .vf-days i,
        .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings .vf-days i,
        .inventory-restocking-table-wraper .pricing-wraper .table-row-data .vf-days i {
          margin: 0px 4px; }
      .inventory-restocking-table-wraper .pricing-wraper .table-headings :nth-child(3),
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings :nth-child(3),
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data :nth-child(3) {
        width: 180px;
        margin-right: 40px; }
      .inventory-restocking-table-wraper .pricing-wraper .table-headings :nth-child(4),
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings :nth-child(4),
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data :nth-child(4) {
        width: 130px; }
    .inventory-restocking-table-wraper .pricing-wraper .table-headings .sea-days {
      width: 58%; }
    .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5; }
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings div {
        color: #898989; }
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings .air-days {
        width: 160px; }
    .inventory-restocking-table-wraper .pricing-wraper .table-row-data {
      height: 48px;
      background-color: #fff; }
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data div {
        color: #212121; }
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data .air-days {
        width: 160px; }
    .inventory-restocking-table-wraper .pricing-wraper .table-row-data:nth-child(even) {
      background-color: #f5f5f5 !important; }

.categories-bar {
  background: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05); }
  .categories-bar .categories-bar-content {
    width: 1200px;
    margin: auto;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.032em;
    color: #212121; }
    .categories-bar .categories-bar-content a {
      color: #212121; }
    .categories-bar .categories-bar-content .filters-list {
      list-style-type: none;
      padding: 0;
      height: 56px;
      margin: 0; }
      .categories-bar .categories-bar-content .filters-list li:first-child {
        width: 240px; }
      .categories-bar .categories-bar-content .filters-list li {
        display: inline-block;
        position: relative;
        margin-right: 16px;
        padding: 16px 8px;
        display: inline-block;
        cursor: pointer; }
        .categories-bar .categories-bar-content .filters-list li span {
          color: #212121 !important; }
        .categories-bar .categories-bar-content .filters-list li ul.dropdown,
        .categories-bar .categories-bar-content .filters-list li ul.dropdown-right {
          background: white;
          visibility: hidden;
          opacity: 0;
          position: absolute;
          transition: all 0.5s ease;
          left: 0;
          display: none;
          z-index: 2;
          padding: 0px 0;
          min-width: 300px;
          border: 1px solid #f1f1f2;
          z-index: 999; }
          .categories-bar .categories-bar-content .filters-list li ul.dropdown li,
          .categories-bar .categories-bar-content .filters-list li ul.dropdown-right li {
            width: 100%;
            padding: 11px 24px;
            line-height: 16px;
            margin: 0;
            letter-spacing: 0.032em;
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent; }
            .categories-bar .categories-bar-content .filters-list li ul.dropdown li :hover,
            .categories-bar .categories-bar-content .filters-list li ul.dropdown-right li :hover {
              border-bottom: 1px solid #f1f1f2;
              background-color: #f5faff; }
        .categories-bar .categories-bar-content .filters-list li ul.dropdown-right {
          left: 100%;
          top: 0; }
      .categories-bar .categories-bar-content .filters-list li:hover > ul.dropdown,
      .categories-bar .categories-bar-content .filters-list li ul.dropdown:hover {
        visibility: visible;
        opacity: 1;
        display: block; }
  .categories-bar .hov {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
    .categories-bar .hov i {
      vertical-align: middle;
      margin-right: 8px; }
      .categories-bar .hov i svg {
        font-size: 16px; }
    .categories-bar .hov .beta {
      text-transform: uppercase;
      display: inline-block;
      font-family: "Archivo Black", sans-serif !important;
      position: relative;
      right: 0px;
      top: -9px;
      font-size: 10px; }
  .categories-bar .hov:hover {
    background-color: #f9f9f9; }
  .categories-bar .hovercursor-pointer {
    pointer-events: none; }
  .categories-bar .filled-heart-ican {
    margin-right: 6px; }
  .categories-bar #active {
    background-color: #f5f5f5;
    box-shadow: inset 0 -1px 0 #0068ff; }
    .categories-bar #active span {
      color: #212121 !important;
      font-weight: 600; }

.niche-hov.ant-dropdown-open svg {
  transform: rotateX(180deg);
  transition: all 0.2s;
  color: black; }
  .niche-hov.ant-dropdown-open svg g {
    fill: #212121; }

.niche-overlay {
  width: 220px !important; }
  .niche-overlay ul {
    padding-top: 12px !important;
    padding-bottom: 12px !important; }
  .niche-overlay li {
    padding: 0 !important; }
    .niche-overlay li .ant-checkbox-wrapper-checked span {
      font-weight: 500; }
    .niche-overlay li .ant-checkbox {
      display: none; }
  .niche-overlay label {
    width: 100% !important;
    padding: 5px 12px !important; }

@media screen and (min-width: 1360px) {
  .categories-bar .categories-bar-content {
    width: 1277px;
    margin: auto; } }

li:first-of-type svg {
  transition: all 0.4s; }

.dd {
  cursor: pointer; }

.dd:hover svg {
  transform: rotateX(180deg);
  transition: all 0.2s;
  color: black; }
  .dd:hover svg g {
    fill: #212121; }

.catalog-product-new {
  background-color: #fff;
  padding: 38px 48px;
  display: flex;
  padding-bottom: 0px !important;
  height: 100%;
  grid-gap: 40px;
  gap: 40px; }
  .catalog-product-new .gallery {
    flex: 1 1;
    width: 282px; }
    .catalog-product-new .gallery .inline-list {
      list-style-type: none;
      margin-bottom: 0px !important;
      padding: 0; }
      .catalog-product-new .gallery .inline-list li {
        display: inline-block; }
    .catalog-product-new .gallery .cost-updated {
      color: #898989;
      font-style: italic;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px; }
      .catalog-product-new .gallery .cost-updated span {
        color: #212121;
        margin-left: 4px; }
  .catalog-product-new .mt0 {
    margin-top: 0px; }
  .catalog-product-new .mt8 {
    margin-top: 8px; }
  .catalog-product-new .center-details {
    flex: 3 1; }
    .catalog-product-new .center-details .product-title-wraper {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px; }
      .catalog-product-new .center-details .product-title-wraper .product-name {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 445px;
        color: #212121;
        font-weight: 600;
        font-size: 20px;
        text-overflow: ellipsis;
        /* Needed to make it work */
        white-space: wrap; }
      .catalog-product-new .center-details .product-title-wraper .product-id-wraper {
        width: 190px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 8px;
        font-size: 12px;
        padding-left: 8px;
        color: #898989; }
        .catalog-product-new .center-details .product-title-wraper .product-id-wraper .product-id > p {
          margin-right: 4px; }
      .catalog-product-new .center-details .product-title-wraper .line {
        margin-right: 8px;
        margin-top: 8px; }
      .catalog-product-new .center-details .product-title-wraper .product-id {
        display: flex;
        margin-top: 8px; }
    .catalog-product-new .center-details .product-sub-heading {
      margin-bottom: 10px;
      font-size: 14px;
      color: #898989;
      margin-bottom: 20px; }
    .catalog-product-new .center-details .star-wraper {
      display: flex;
      align-items: center; }
      .catalog-product-new .center-details .star-wraper .stars {
        display: flex;
        align-items: center; }
    .catalog-product-new .center-details .inventory-status {
      display: flex;
      align-items: center; }
      .catalog-product-new .center-details .inventory-status i {
        margin-right: 8px; }
      .catalog-product-new .center-details .inventory-status p {
        font-size: 12px;
        color: #898989; }
    .catalog-product-new .center-details .new-moq-table {
      border: 2px solid #26b99a; }
      .catalog-product-new .center-details .new-moq-table .main-heading {
        background-color: #26b99a;
        height: 24px; }
        .catalog-product-new .center-details .new-moq-table .main-heading > div {
          color: #fff !important;
          font-size: 12px; }
      .catalog-product-new .center-details .new-moq-table .main-heading,
      .catalog-product-new .center-details .new-moq-table .new-moq-table-row {
        display: flex;
        padding: 12px 0;
        grid-gap: 12px;
        gap: 12px; }
        .catalog-product-new .center-details .new-moq-table .main-heading > div,
        .catalog-product-new .center-details .new-moq-table .new-moq-table-row > div {
          flex: 1 1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #212121; }
        .catalog-product-new .center-details .new-moq-table .main-heading .type,
        .catalog-product-new .center-details .new-moq-table .new-moq-table-row .type {
          justify-content: flex-start;
          padding-left: 24px; }
      .catalog-product-new .center-details .new-moq-table .new-moq-table-row:nth-child(even) {
        background-color: #f5f5f5; }
    .catalog-product-new .center-details .action-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      grid-gap: 8px;
      gap: 8px;
      margin-top: 12px; }
      .catalog-product-new .center-details .action-btns .ant-btn {
        height: 40px; }
      .catalog-product-new .center-details .action-btns .ant-btn-primary {
        background-color: #21a819;
        border: 1px solid #21a819;
        font-size: 16px;
        padding: 0px 38px; }
      .catalog-product-new .center-details .action-btns .ant-btn-primary:hover {
        background-color: #0c7c06 !important;
        border: 1px solid #076f02 !important;
        font-size: 16px;
        padding: 0px 38px; }
  .catalog-product-new .right {
    flex: 1 1;
    background-color: #f4f4f4;
    border-radius: 8px;
    height: 352px;
    padding: 16px 24px; }
    .catalog-product-new .right h1 {
      font-size: 12px;
      margin-bottom: 16px;
      color: #898989; }
    .catalog-product-new .right .bar-content {
      display: flex;
      grid-gap: 12px;
      gap: 12px; }
      .catalog-product-new .right .bar-content .bar {
        width: 4px;
        height: 280px;
        background-color: #35446e;
        position: relative; }
        .catalog-product-new .right .bar-content .bar .payment,
        .catalog-product-new .right .bar-content .bar .confirmation,
        .catalog-product-new .right .bar-content .bar .dispatch,
        .catalog-product-new .right .bar-content .bar .received {
          position: absolute;
          left: 50%;
          height: 16px;
          min-width: 16px;
          border-radius: 50%;
          transform: translateX(-50%);
          background-color: #21a819; }
        .catalog-product-new .right .bar-content .bar .payment {
          border: 2px solid #35446e;
          background-color: #cecece; }
        .catalog-product-new .right .bar-content .bar .confirmation {
          top: 50px;
          background-color: #35446e; }
        .catalog-product-new .right .bar-content .bar .dispatch {
          top: 170px; }
          .catalog-product-new .right .bar-content .bar .dispatch .dispatch-bar {
            position: relative; }
        .catalog-product-new .right .bar-content .bar .dispatch-bar::before {
          content: "";
          height: 90px;
          width: 4px;
          background-color: #20a819;
          position: absolute;
          top: 5px;
          left: 50%;
          transform: translateX(-50%); }
        .catalog-product-new .right .bar-content .bar .received {
          bottom: 0px;
          border: 2px solid #20a819;
          background-color: #cecece; }
      .catalog-product-new .right .bar-content .text > div {
        position: relative;
        font-size: 12px;
        color: #212121; }
      .catalog-product-new .right .bar-content .text .confirmation-text {
        top: 30px; }
      .catalog-product-new .right .bar-content .text .avg-wraper {
        top: 64px;
        left: 24px; }
        .catalog-product-new .right .bar-content .text .avg-wraper .avg {
          font-weight: 600; }
      .catalog-product-new .right .bar-content .text .dispatch-text {
        top: 96px; }
      .catalog-product-new .right .bar-content .text .avg-wraper-green {
        top: 116px;
        left: 24px; }
        .catalog-product-new .right .bar-content .text .avg-wraper-green div {
          color: #20a819 !important; }
        .catalog-product-new .right .bar-content .text .avg-wraper-green .avg {
          font-weight: 600; }
      .catalog-product-new .right .bar-content .text .received-text {
        top: 136px; }

.floating-widget-wraper-new .pricing-container {
  padding: 12px 12px;
  background-color: #f5f5f5; }
  .floating-widget-wraper-new .pricing-container .horizental-line {
    height: 1px;
    background-color: #b2b2b2;
    margin-top: 12px;
    margin-bottom: 12px; }
  .floating-widget-wraper-new .pricing-container i {
    margin: 0 8px; }
  .floating-widget-wraper-new .pricing-container p {
    font-size: 12px;
    color: #898989; }
  .floating-widget-wraper-new .pricing-container span {
    font-size: 12px;
    color: #898989;
    font-weight: 500; }
  .floating-widget-wraper-new .pricing-container .float-heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 8px; }
  .floating-widget-wraper-new .pricing-container .float-heading:nth-of-type(1) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 8px; }
    .floating-widget-wraper-new .pricing-container .float-heading:nth-of-type(1) p {
      font-size: 12px;
      margin-right: 4px; }
    .floating-widget-wraper-new .pricing-container .float-heading:nth-of-type(1) span {
      font-size: 12px;
      color: #0068ff;
      margin-left: 0px !important; }
  .floating-widget-wraper-new .pricing-container .float-heading:nth-of-type(2) i {
    margin: 0px !important;
    margin-left: 4px !important; }
  .floating-widget-wraper-new .pricing-container .price-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 8px; }
    .floating-widget-wraper-new .pricing-container .price-heading span {
      font-size: 16px;
      font-weight: 600;
      color: #212121; }
  .floating-widget-wraper-new .pricing-container .gst-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px; }
    .floating-widget-wraper-new .pricing-container .gst-heading p {
      font-style: italic; }
    .floating-widget-wraper-new .pricing-container .gst-heading span {
      font-size: 12px;
      font-weight: 400;
      color: #898989; }
    .floating-widget-wraper-new .pricing-container .gst-heading i {
      font-size: 16px; }
  .floating-widget-wraper-new .pricing-container .info-container {
    padding-top: 4px;
    padding-bottom: 6px; }
    .floating-widget-wraper-new .pricing-container .info-container p {
      font-style: italic; }
    .floating-widget-wraper-new .pricing-container .info-container i {
      font-size: 16px; }
    .floating-widget-wraper-new .pricing-container .info-container div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 6px; }
  .floating-widget-wraper-new .pricing-container .total-cost-container {
    border-top: 1px solid #b2b2b2;
    padding-top: 12px; }
    .floating-widget-wraper-new .pricing-container .total-cost-container .cost-wraper {
      display: flex;
      align-items: center; }
    .floating-widget-wraper-new .pricing-container .total-cost-container .total-cost {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 12px; }
      .floating-widget-wraper-new .pricing-container .total-cost-container .total-cost > p:first-of-type {
        text-decoration: underline; }
      .floating-widget-wraper-new .pricing-container .total-cost-container .total-cost .f16 {
        font-size: 16px;
        color: #212121;
        font-weight: 600;
        text-decoration: none !important; }
    .floating-widget-wraper-new .pricing-container .total-cost-container .gst-desc {
      font-size: 12px;
      color: #898989;
      text-align: center;
      font-size: 10px;
      font-style: italic; }
  .floating-widget-wraper-new .pricing-container .raise_booking_request button {
    border-radius: 2px;
    width: 100%;
    margin: 12px 0;
    font-size: 16px;
    color: #fff;
    height: 40px;
    background-color: #1370fc;
    box-shadow: #00000033 0px 2px 3px; }
    .floating-widget-wraper-new .pricing-container .raise_booking_request button span {
      color: #fff;
      font-size: 16px; }
  .floating-widget-wraper-new .pricing-container .raise_booking_request p {
    font-size: 12px;
    color: #898989;
    text-align: center;
    font-size: 10px;
    font-style: italic; }
  .floating-widget-wraper-new .pricing-container .raise_booking_request .ant-btn.ant-btn-cancel {
    background-color: #f5f5f5 !important; }
    .floating-widget-wraper-new .pricing-container .raise_booking_request .ant-btn.ant-btn-cancel span {
      color: #898989 !important; }

.floating-widget-wraper-new .gst-heading-bottom {
  display: flex;
  align-items: center;
  margin-bottom: 12px; }
  .floating-widget-wraper-new .gst-heading-bottom i {
    margin-left: 4px;
    margin-right: 0px !important; }

.ant-message {
  z-index: 9999999999999 !important; }

.rasing-bulk-order-modal * {
  box-sizing: border-box;
  letter-spacing: 0.032em;
  margin: 0;
  padding: 0; }

.rasing-bulk-order-modal .ant-modal-close-x {
  display: none; }

.rasing-bulk-order-modal p {
  font-size: 12px;
  color: #898989; }

.rasing-bulk-order-modal h1 {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 5px; }

.rasing-bulk-order-modal .ant-modal-header {
  padding-top: 24px;
  margin: 0 24px; }
  .rasing-bulk-order-modal .ant-modal-header p {
    margin-bottom: 12px; }

.rasing-bulk-order-modal .ant-modal-footer {
  margin: 0 24px;
  padding-bottom: 26px;
  padding-top: 16px;
  display: flex; }
  .rasing-bulk-order-modal .ant-modal-footer .note {
    width: 60px; }
    .rasing-bulk-order-modal .ant-modal-footer .note p:first-of-type {
      margin-bottom: 5px; }
    .rasing-bulk-order-modal .ant-modal-footer .note p {
      text-align: left;
      font-size: 10px;
      font-style: italic; }
  .rasing-bulk-order-modal .ant-modal-footer .note-desc p:first-of-type {
    margin-bottom: 5px; }
  .rasing-bulk-order-modal .ant-modal-footer .note-desc p {
    text-align: left;
    font-size: 10px;
    font-style: italic; }
  .rasing-bulk-order-modal .ant-modal-footer .btns {
    width: 700px;
    display: flex;
    justify-content: end;
    align-items: center;
    border-radius: 2px; }
    .rasing-bulk-order-modal .ant-modal-footer .btns button:first-of-type {
      width: 160px; }
    .rasing-bulk-order-modal .ant-modal-footer .btns button {
      font-size: 12px;
      font-weight: 500;
      width: 208px;
      height: 40px;
      border-radius: 2px !important; }

.rasing-bulk-order-modal .ant-modal-body {
  padding: 24px;
  padding-top: 16px;
  font-size: 12px; }
  .rasing-bulk-order-modal .ant-modal-body .content-headings {
    padding: 12px 16px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings div {
      display: flex;
      align-items: center;
      font-style: italic; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-1 {
      font-style: normal; }
      .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-1 span {
        margin-left: 4px;
        color: #0068ff; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-2 p {
      margin-right: 4px; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-2 i {
      font-size: 16px;
      margin-left: 8px; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-3 i {
      font-size: 16px;
      margin-left: 4px; }
  .rasing-bulk-order-modal .ant-modal-body .variants-headings {
    padding: 0px 16px 4px 16px;
    display: flex;
    justify-content: space-between; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-1 {
      width: 380px !important;
      justify-content: flex-start; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-2 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-3 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-4 {
      width: 100px; }
  .rasing-bulk-order-modal .ant-modal-body .variants-data-row {
    padding: 8px 16px;
    display: flex;
    height: 72px;
    background-color: #f5f5f5;
    margin-bottom: 8px;
    justify-content: space-between; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 {
      width: 380px !important;
      justify-content: flex-start; }
      .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .details {
        flex-direction: column;
        align-items: flex-start; }
        .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .details p {
          color: #898989;
          margin-right: 4px; }
        .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .details span {
          color: #212121; }
      .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .img {
        height: 56px;
        width: 56px;
        margin-right: 16px; }
        .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .img img {
          object-fit: cover;
          height: 100%;
          width: inherit; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-2 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-3 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-4 {
      width: 100px; }
  .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    height: 48px;
    background-color: #f5f5f5; }
    .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .included {
      font-style: italic;
      color: #898989;
      font-size: 12px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .total-price {
      display: flex;
      justify-content: end;
      align-items: center; }
      .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .total-price .gst {
        font-size: 12px;
        color: #898989; }
      .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .total-price .price {
        width: 100px;
        margin-left: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #212121; }

.rasing-bulk-order-modal .product-tooltip:hover svg.product-tooltip-icon {
  background: #1268fb;
  border-radius: 50%;
  cursor: pointer; }

.rasing-bulk-order-modal .product-tooltip:hover svg.product-tooltip-icon g text {
  fill: #fff; }

.rasing-bulk-order-modal .product-tooltip:hover svg.product-tooltip-icon g > g {
  stroke: #1268fb; }

.opopp {
  margin-left: 16px; }
  .opopp .dragger-packing {
    border-radius: 0px; }
  .opopp .io-container {
    width: 110px;
    margin-right: 10px;
    height: 110px;
    margin-bottom: 12px;
    display: inline-block;
    background-color: lavender; }
    .opopp .io-container svg {
      display: flex;
      margin: auto;
      height: 110px; }
  .opopp .cointainer {
    display: flex;
    margin-top: 12px; }
  .opopp .heading {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 12px;
    font-weight: 400; }
  .opopp .drage-image {
    padding: 24px 16px;
    background-color: #f5f5f5;
    margin-right: 8px;
    width: 110px;
    height: 110px; }
  .opopp .drage-image:hover {
    background-color: #1370fc;
    color: #fff; }
    .opopp .drage-image:hover p {
      color: #fff; }
  .opopp .drage-image:hover svg g path {
    fill: #fff; }
  .opopp .drage-image svg {
    position: relative;
    left: 33%; }
  .opopp .image-cointainer {
    position: relative; }
    .opopp .image-cointainer .icon-cointainer-pdf {
      position: absolute;
      height: 16px;
      width: 16px;
      display: inline-block;
      top: 2px;
      right: 12px;
      display: none; }
      .opopp .image-cointainer .icon-cointainer-pdf svg {
        height: 100%;
        width: 100%; }
    .opopp .image-cointainer .icon-cointainer {
      position: absolute;
      height: 16px;
      width: 16px;
      display: inline-block;
      top: -1px;
      right: 10px;
      display: none; }
      .opopp .image-cointainer .icon-cointainer svg {
        height: 100%;
        width: 100%; }
  .opopp .image-cointainer:hover .icon-cointainer {
    display: block; }
  .opopp .image-cointainer:hover .icon-cointainer-pdf {
    display: block; }

.recomended-packaging-modal * {
  box-sizing: border-box;
  letter-spacing: 0.032em !important;
  margin: 0;
  padding: 0; }

.recomended-packaging-modal .p-cancel {
  margin-top: 12px;
  height: 40px;
  width: 100%; }

.recomended-packaging-modal .ant-modal-close-x {
  display: none; }

.recomended-packaging-modal p {
  font-size: 12px;
  color: #898989; }

.recomended-packaging-modal h1 {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 5px; }

.recomended-packaging-modal .ant-modal-header {
  padding-top: 30px;
  margin: 0 24px;
  padding-bottom: 24px; }
  .recomended-packaging-modal .ant-modal-header p {
    margin-bottom: 12px;
    text-align: center; }
  .recomended-packaging-modal .ant-modal-header .ant-modal-title > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px; }
    .recomended-packaging-modal .ant-modal-header .ant-modal-title > div svg {
      margin-right: 10px;
      font-size: 60px;
      margin-bottom: 14px; }
    .recomended-packaging-modal .ant-modal-header .ant-modal-title > div h1 {
      line-height: 16px;
      margin-bottom: 4px; }

.recomended-packaging-modal .ant-modal-footer {
  margin: 0 24px;
  padding-bottom: 26px;
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .recomended-packaging-modal .ant-modal-footer .btns {
    display: flex;
    justify-content: end;
    align-items: center; }
    .recomended-packaging-modal .ant-modal-footer .btns button:first-of-type {
      width: 160px;
      margin-right: 8px;
      border-radius: 2px !important; }
    .recomended-packaging-modal .ant-modal-footer .btns button {
      font-size: 12px;
      font-weight: 500;
      width: 208px;
      height: 40px; }
    .recomended-packaging-modal .ant-modal-footer .btns a {
      height: 40px;
      width: 208px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px !important; }
  .recomended-packaging-modal .ant-modal-footer .green-outline {
    background: #fff !important;
    margin-right: 8px;
    width: 100px;
    font-size: 12px;
    color: #2a9c27 !important;
    border: 1px solid #2a9c27; }
  .recomended-packaging-modal .ant-modal-footer .clickable-dropdown.custom-width {
    margin-top: 12px;
    width: 376px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px; }

.recomended-packaging-modal .ant-modal-body {
  padding: 24px;
  padding-top: 20px;
  font-size: 12px; }
  .recomended-packaging-modal .ant-modal-body .head-1 {
    width: 480px;
    justify-content: flex-start !important; }
  .recomended-packaging-modal .ant-modal-body .head-2 {
    width: 100px; }
    .recomended-packaging-modal .ant-modal-body .head-2 .Complimentary {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 120px;
      color: #9d24d6;
      border-radius: 2px;
      border: 1px solid #9d24d6;
      font-weight: 500;
      padding: 0 8px; }
  .recomended-packaging-modal .ant-modal-body .head-3 {
    width: 100px; }
  .recomended-packaging-modal .ant-modal-body .head-4 {
    width: 100px; }
  .recomended-packaging-modal .ant-modal-body .variants-headings {
    padding: 0px 16px 4px 16px;
    display: flex;
    justify-content: space-between; }
    .recomended-packaging-modal .ant-modal-body .variants-headings div {
      display: flex;
      align-items: center;
      justify-content: center; }
  .recomended-packaging-modal .ant-modal-body .variants-data-row {
    padding: 8px 16px;
    display: flex;
    height: 72px;
    background-color: #f5f5f5;
    margin-bottom: 8px;
    justify-content: space-between; }
    .recomended-packaging-modal .ant-modal-body .variants-data-row div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 {
      justify-content: flex-start; }
      .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details {
        flex-direction: column;
        align-items: flex-start; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details p {
          color: #898989;
          margin-right: 4px; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details span {
          color: #212121; }
      .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .img {
        height: 56px;
        width: 56px;
        margin-right: 16px; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .img img {
          object-fit: cover;
          height: 100%;
          width: inherit; }

.recomended-packaging-modal.hide-border .ant-modal-header {
  border-bottom: none; }

.recomended-packaging-modal.hide-border .ant-modal-body {
  display: none; }

.recomended-packaging-modal .btns {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  grid-gap: 12px;
  gap: 12px; }
  .recomended-packaging-modal .btns button {
    width: 100% !important;
    margin: 0px !important; }

.recomended-packaging-modal * {
  box-sizing: border-box;
  letter-spacing: 0.032em !important;
  margin: 0;
  padding: 0; }

.recomended-packaging-modal .ant-modal-close-x {
  display: none; }

.recomended-packaging-modal p {
  font-size: 12px;
  color: #898989; }

.recomended-packaging-modal h1 {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 5px; }

.recomended-packaging-modal .ant-modal-header {
  padding-top: 30px;
  margin: 0 24px;
  padding-bottom: 24px; }
  .recomended-packaging-modal .ant-modal-header p {
    margin-bottom: 12px;
    text-align: center; }
  .recomended-packaging-modal .ant-modal-header .ant-modal-title > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px; }
    .recomended-packaging-modal .ant-modal-header .ant-modal-title > div svg {
      margin-right: 10px;
      font-size: 60px;
      margin-bottom: 14px; }
    .recomended-packaging-modal .ant-modal-header .ant-modal-title > div h1 {
      line-height: 16px;
      margin-bottom: 4px; }

.recomended-packaging-modal .ant-modal-footer {
  margin: 0 24px;
  padding-bottom: 26px;
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .recomended-packaging-modal .ant-modal-footer .btns {
    display: flex;
    justify-content: end;
    align-items: center; }
    .recomended-packaging-modal .ant-modal-footer .btns button:first-of-type {
      width: 160px;
      margin-right: 8px;
      border-radius: 2px !important; }
    .recomended-packaging-modal .ant-modal-footer .btns button {
      font-size: 12px;
      font-weight: 500;
      width: 208px;
      height: 40px; }
    .recomended-packaging-modal .ant-modal-footer .btns a {
      height: 40px;
      width: 208px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px !important; }
  .recomended-packaging-modal .ant-modal-footer .green-outline {
    background: #fff !important;
    margin-right: 8px;
    width: 100px;
    font-size: 12px;
    color: #2a9c27 !important;
    border: 1px solid #2a9c27; }
  .recomended-packaging-modal .ant-modal-footer .clickable-dropdown.custom-width {
    margin-top: 12px;
    width: 376px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px; }

.recomended-packaging-modal .ant-modal-body {
  padding: 24px;
  padding-top: 20px;
  font-size: 12px; }
  .recomended-packaging-modal .ant-modal-body .head-1 {
    width: 480px;
    justify-content: flex-start !important; }
  .recomended-packaging-modal .ant-modal-body .head-2 {
    width: 100px; }
    .recomended-packaging-modal .ant-modal-body .head-2 .Complimentary {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 120px;
      color: #9d24d6;
      border-radius: 2px;
      border: 1px solid #9d24d6;
      font-weight: 500;
      padding: 0 8px; }
  .recomended-packaging-modal .ant-modal-body .head-3 {
    width: 100px; }
  .recomended-packaging-modal .ant-modal-body .head-4 {
    width: 100px; }
  .recomended-packaging-modal .ant-modal-body .variants-headings {
    padding: 0px 16px 4px 16px;
    display: flex;
    justify-content: space-between; }
    .recomended-packaging-modal .ant-modal-body .variants-headings div {
      display: flex;
      align-items: center;
      justify-content: center; }
  .recomended-packaging-modal .ant-modal-body .variants-data-row {
    padding: 8px 16px;
    display: flex;
    height: 72px;
    background-color: #f5f5f5;
    margin-bottom: 8px;
    justify-content: space-between; }
    .recomended-packaging-modal .ant-modal-body .variants-data-row div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 {
      justify-content: flex-start; }
      .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details {
        flex-direction: column;
        align-items: flex-start; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details p {
          color: #898989;
          margin-right: 4px; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details span {
          color: #212121; }
      .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .img {
        height: 56px;
        width: 56px;
        margin-right: 16px; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .img img {
          object-fit: cover;
          height: 100%;
          width: inherit; }

.recomended-packaging-modal.hide-border .ant-modal-header {
  border-bottom: none; }

.recomended-packaging-modal.hide-border .ant-modal-body {
  display: none; }

.ant-drawer {
  z-index: 99999; }
  .ant-drawer .ant-drawer-header {
    padding-left: 0px !important;
    margin-left: 24px !important; }
    .ant-drawer .ant-drawer-header .drawer-title {
      font-size: 16px !important;
      font-weight: 500 !important; }
    .ant-drawer .ant-drawer-header p {
      font-size: 12px !important;
      color: #898989 !important;
      font-weight: 400 !important; }
  .ant-drawer .ant-drawer-body {
    padding-top: 14px !important;
    margin-bottom: 70px; }
  .ant-drawer .action-btns-drawer {
    position: absolute;
    border-top: 1px solid #e8e8e8;
    width: 100%;
    background-color: #fff;
    padding: 16px 22px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 8px;
    gap: 8px; }
    .ant-drawer .action-btns-drawer .ant-btn-primary {
      background-color: #21a819;
      border: 1px solid #21a819;
      font-size: 16px; }

.place-order-drawer .step-head {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 24px;
  gap: 24px;
  margin-bottom: 8px; }
  .place-order-drawer .step-head span {
    font-size: 16px;
    font-weight: 600;
    color: #1370fc;
    position: relative; }
  .place-order-drawer .step-head span::before {
    content: "";
    height: 16px;
    width: 2px;
    background-color: #1370fc;
    position: absolute;
    left: -13px;
    transform: translateY(-50%);
    top: 50%; }

.place-order-drawer .booking-qty button {
  cursor: pointer; }

.place-order-drawer .new-moq-table {
  border-bottom: 2px solid #f5f5f5; }
  .place-order-drawer .new-moq-table .main-heading {
    background-color: #1a67d9;
    height: 24px; }
    .place-order-drawer .new-moq-table .main-heading > div {
      color: #fff !important;
      font-size: 12px; }
  .place-order-drawer .new-moq-table .main-heading,
  .place-order-drawer .new-moq-table .new-moq-table-row {
    cursor: pointer;
    display: flex;
    padding: 12px 0;
    grid-gap: 12px;
    gap: 12px; }
    .place-order-drawer .new-moq-table .main-heading > div,
    .place-order-drawer .new-moq-table .new-moq-table-row > div {
      flex: 1 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-weight: 500;
      color: #212121; }
    .place-order-drawer .new-moq-table .main-heading .select,
    .place-order-drawer .new-moq-table .new-moq-table-row .select {
      flex: 0.7 1; }
    .place-order-drawer .new-moq-table .main-heading .type,
    .place-order-drawer .new-moq-table .new-moq-table-row .type {
      justify-content: flex-start; }
  .place-order-drawer .new-moq-table .new-moq-table-row {
    border: 2px solid #fff;
    border-left-color: #f5f5f5;
    border-right-color: #f5f5f5; }
  .place-order-drawer .new-moq-table .new-moq-table-row:nth-child(even) {
    background-color: #f5f5f5 !important;
    border: 2px solid #f5f5f5 !important; }
  .place-order-drawer .new-moq-table .new-moq-table-row.selected {
    border: 2px solid #20a819 !important;
    background-color: #f4fff4 !important; }
    .place-order-drawer .new-moq-table .new-moq-table-row.selected .ant-radio-inner {
      border-color: #20a819; }
    .place-order-drawer .new-moq-table .new-moq-table-row.selected .ant-radio-inner::after {
      background-color: #20a819 !important; }
    .place-order-drawer .new-moq-table .new-moq-table-row.selected .type {
      color: #20a819; }

.place-order-drawer .pricing-wraper {
  border: none !important; }
  .place-order-drawer .pricing-wraper .table-sub-headings {
    background-color: #fff;
    padding: 8px 12px !important;
    grid-gap: 8px;
    gap: 8px; }
    .place-order-drawer .pricing-wraper .table-sub-headings .variants {
      flex: 2 1 !important;
      width: auto !important;
      padding-left: 0px; }
    .place-order-drawer .pricing-wraper .table-sub-headings .air-days,
    .place-order-drawer .pricing-wraper .table-sub-headings .booking-qty {
      flex: 1 1 !important;
      width: auto !important; }
  .place-order-drawer .pricing-wraper .table-row-data {
    background-color: #fff !important;
    height: auto !important;
    padding: 8px 12px !important;
    grid-gap: 8px;
    gap: 8px; }
    .place-order-drawer .pricing-wraper .table-row-data .variants-details {
      flex: 2 1 !important;
      width: auto !important;
      padding-left: 0px; }
      .place-order-drawer .pricing-wraper .table-row-data .variants-details .img {
        height: 50px;
        width: 50px; }
    .place-order-drawer .pricing-wraper .table-row-data .air-days {
      font-size: 12px;
      color: #212121;
      font-weight: 400; }
    .place-order-drawer .pricing-wraper .table-row-data .air-days,
    .place-order-drawer .pricing-wraper .table-row-data .booking-qty-parent-wraper {
      flex: 1 1 !important;
      width: auto !important; }
  .place-order-drawer .pricing-wraper .table-row-data:nth-child(even) {
    background-color: #f5f5f5 !important; }

.place-order-drawer .price-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 10px 25px 10px 12px;
  margin-bottom: 4px; }
  .place-order-drawer .price-div p {
    font-size: 14px;
    color: #898989; }
  .place-order-drawer .price-div span {
    font-size: 16px;
    font-weight: 600;
    color: #212121; }

.rfq-modal {
  top: 32px !important; }
  .rfq-modal * {
    letter-spacing: 0.032em;
    margin: 0; }
  .rfq-modal .heading-varient {
    font-size: 12px !important;
    color: #898989 !important;
    line-height: 24px !important; }
  .rfq-modal .product-name-wraper {
    position: relative; }
    .rfq-modal .product-name-wraper .external-product-wraper {
      position: absolute;
      background-color: #fff;
      z-index: 99;
      max-height: 200px;
      overflow-y: hidden;
      top: 65px;
      overflow: scroll;
      width: 100%;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }
      .rfq-modal .product-name-wraper .external-product-wraper .img-details.external {
        background-color: #f5f5f5;
        border-bottom: 1px solid #fff !important; }
  .rfq-modal .scroller {
    max-height: 469px !important; }
  .rfq-modal .ant-form-explain {
    text-transform: capitalize !important; }
  .rfq-modal .ant-modal-header {
    padding: 24px 0px 8px 0px !important;
    margin: 0px 24px; }
    .rfq-modal .ant-modal-header .ant-modal-title h1 {
      font-size: 16px;
      font-weight: 600;
      color: #212121;
      margin-bottom: 6px; }
    .rfq-modal .ant-modal-header .ant-modal-title h2 {
      font-size: 12px;
      font-weight: 400;
      color: #898989; }
  .rfq-modal .qty-error {
    color: #f5222d;
    font-size: 11px; }
  .rfq-modal .ant-modal-body {
    padding-top: 16px !important;
    padding-bottom: 0px !important; }
    .rfq-modal .ant-modal-body .img-details-wraper {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .rfq-modal .ant-modal-body .img-details-wraper .right_svg {
        display: none; }
    .rfq-modal .ant-modal-body .varient-qty-container {
      margin-bottom: 16px !important; }
    .rfq-modal .ant-modal-body .red-dot {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 12px;
      font-family: SimSun, sans-serif;
      line-height: 1; }
    .rfq-modal .ant-modal-body .img-details-wraper-custom .right_svg {
      display: none; }
    .rfq-modal .ant-modal-body .img-details {
      display: flex;
      grid-gap: 5px;
      gap: 5px;
      align-items: center;
      justify-content: flex-start; }
      .rfq-modal .ant-modal-body .img-details img {
        height: 32px !important;
        width: 32px;
        object-fit: contain;
        border: 1px solid #e8e8e8;
        margin-right: 6px; }
      .rfq-modal .ant-modal-body .img-details .text-wraper {
        font-size: 12px;
        line-height: 16px;
        flex: 1 1; }
        .rfq-modal .ant-modal-body .img-details .text-wraper .allsep {
          font-size: 10px; }
    .rfq-modal .ant-modal-body .external-product-wraper .img-details.external {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px;
      padding-left: 0px;
      border-bottom: 1px solid #e9e9e9;
      cursor: pointer;
      transition: 0.4s all; }
    .rfq-modal .ant-modal-body .external-product-wraper .img-details.external svg {
      fill: #5c88ff; }
    .rfq-modal .ant-modal-body .external-product-wraper .img-details.external:hover {
      background-color: #f5f5f5; }
      .rfq-modal .ant-modal-body .external-product-wraper .img-details.external:hover * {
        font-weight: 500;
        transition: 0.4s all; }
    .rfq-modal .ant-modal-body .ant-upload.ant-upload-btn {
      padding: 0px !important; }
    .rfq-modal .ant-modal-body .preview-image-wraper {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: scroll; }
      .rfq-modal .ant-modal-body .preview-image-wraper img {
        height: 80px; }
      .rfq-modal .ant-modal-body .preview-image-wraper embed {
        height: 80px; }
      .rfq-modal .ant-modal-body .preview-image-wraper video {
        height: 80px; }
      .rfq-modal .ant-modal-body .preview-image-wraper iframe {
        height: 80px; }
    .rfq-modal .ant-modal-body .ant-upload.ant-upload-drag {
      border-radius: 0px;
      border: none;
      background-color: #f5f5f5;
      padding: 0px !important;
      height: 64px; }
      .rfq-modal .ant-modal-body .ant-upload.ant-upload-drag .ant-upload-drag-container {
        color: #898989;
        font-size: 12px;
        background-color: #f5f5f5; }
    .rfq-modal .ant-modal-body .ant-tabs-bar {
      margin-bottom: 16px !important;
      border-bottom: 0px; }
      .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll {
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll > div {
          width: 100%; }
        .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-nav.ant-tabs-nav-animated > div {
          width: 100%; }
        .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab {
          padding: 8px 16px !important;
          width: 50%;
          margin: 0 !important;
          text-align: center;
          background-color: #f5f5f5 !important;
          font-size: 12px;
          color: #212121 !important; }
        .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab-active.ant-tabs-tab {
          background-color: #5c88ff !important;
          color: #fff !important; }
        .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-ink-bar {
          background-color: #5c88ff !important;
          transition: 0.3s all linear !important;
          display: none !important; }
    .rfq-modal .ant-modal-body .ant-select-selection__choice {
      height: 32px;
      margin-top: 4px; }
    .rfq-modal .ant-modal-body .product-select .ant-select-selection.ant-select-selection--single {
      border-radius: 0px !important;
      background-color: #f5f5f5 !important;
      height: 40px; }
    .rfq-modal .ant-modal-body .product-select .ant-select-selection__placeholder {
      padding-left: 12px !important; }
    .rfq-modal .ant-modal-body .product-select input {
      padding-left: 12px !important; }
    .rfq-modal .ant-modal-body .product-variants .ant-select-selection__placeholder {
      margin-left: 0px;
      padding-left: 12px; }
    .rfq-modal .ant-modal-body .product-variants input {
      margin-left: 6px !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-select-selection__clear {
      top: 35% !important;
      background: transparent !important; }
    .rfq-modal .ant-modal-body .ant-form #expected_landing_date {
      width: 100% !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-calendar-picker-clear {
      top: 25% !important;
      background: #f5f5f5 !important;
      border-radius: 50%; }
    .rfq-modal .ant-modal-body .ant-form .ant-calendar-picker-icon {
      top: 25% !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-form-explain {
      font-size: 11px !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-row.ant-form-item {
      margin-bottom: 16px !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-row.varient-item {
      margin-bottom: 0px !important;
      margin-left: 20px;
      width: 50%; }
    .rfq-modal .ant-modal-body .ant-form .ant-input-group-addon {
      height: 32px !important;
      border-radius: 0px !important;
      background-color: #ededed !important;
      border: none !important;
      font-size: 12px !important; }
    .rfq-modal .ant-modal-body .ant-form input {
      border-radius: 0px !important;
      background-color: #f5f5f5 !important;
      border: none !important;
      font-size: 12px !important; }
    .rfq-modal .ant-modal-body .ant-form input::placeholder {
      color: #898989 !important; }
    .rfq-modal .ant-modal-body .ant-form textarea::placeholder {
      color: #898989 !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-input:placeholder-shown {
      font-size: 12px !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-select-selection-selected-value {
      padding-left: 12px !important;
      margin-top: 4px;
      font-size: 12px !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-select-selection {
      border: none !important; }
      .rfq-modal .ant-modal-body .ant-form .ant-select-selection .ant-select-selection.ant-select-selection--single {
        height: 40px !important; }
      .rfq-modal .ant-modal-body .ant-form .ant-select-selection .ant-select-selection__rendered {
        height: 40px !important;
        border-radius: 0px !important;
        background-color: #f5f5f5 !important;
        border: none !important;
        margin-left: 0px !important; }
        .rfq-modal .ant-modal-body .ant-form .ant-select-selection .ant-select-selection__rendered svg {
          fill: #5c88ff; }
        .rfq-modal .ant-modal-body .ant-form .ant-select-selection .ant-select-selection__rendered input {
          background-color: transparent !important; }
      .rfq-modal .ant-modal-body .ant-form .ant-select-selection .ant-select-selection__placeholder {
        top: 22% !important;
        font-size: 12px !important;
        color: #898989 !important;
        padding-left: 9px; }
    .rfq-modal .ant-modal-body .ant-form textarea {
      border-radius: 0px !important;
      background-color: #f5f5f5 !important;
      border: none !important;
      font-size: 12px; }
    .rfq-modal .ant-modal-body .ant-form .private-label-radio-group {
      display: flex;
      align-items: center; }
      .rfq-modal .ant-modal-body .ant-form .private-label-radio-group label span:nth-of-type(2) {
        font-size: 12px;
        color: #898989; }
      .rfq-modal .ant-modal-body .ant-form .private-label-radio-group .ant-radio-wrapper-checked .ant-radio-inner {
        border-color: #2bc822 !important; }
      .rfq-modal .ant-modal-body .ant-form .private-label-radio-group .ant-radio-wrapper-checked .ant-radio-inner::after {
        background: #2bc822 !important; }
    .rfq-modal .ant-modal-body .ant-form .footer-button {
      border-top: 1px solid #e8e8e8;
      padding-top: 6px; }
      .rfq-modal .ant-modal-body .ant-form .footer-button .ant-form-item-children {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 12px; }
        .rfq-modal .ant-modal-body .ant-form .footer-button .ant-form-item-children button {
          padding: 0px 50px;
          box-shadow: 0px 2px 3px #00000016; }
        .rfq-modal .ant-modal-body .ant-form .footer-button .ant-form-item-children .cancelBtn {
          box-shadow: none; }
        .rfq-modal .ant-modal-body .ant-form .footer-button .ant-form-item-children button:last-of-type {
          background-color: #2bc822;
          border-color: #2bc822; }
    .rfq-modal .ant-modal-body .ant-form .ant-form-item-label {
      line-height: 24px !important; }
      .rfq-modal .ant-modal-body .ant-form .ant-form-item-label label {
        font-size: 12px !important;
        color: #898989 !important; }

.product-overlay-wraper li {
  border-bottom: 1px solid #fff; }

.product-overlay-wraper .img-details-wraper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .product-overlay-wraper .img-details-wraper .right_svg {
    fill: #5c88ff; }

.product-overlay-wraper .img-details {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  justify-content: flex-start; }
  .product-overlay-wraper .img-details img {
    height: 32px !important;
    width: 32px;
    object-fit: contain;
    border: 1px solid #e8e8e8;
    margin-right: 6px; }
  .product-overlay-wraper .img-details .text-wraper {
    font-size: 12px !important;
    line-height: 14px;
    flex: 1 1; }

.rfq-modal
.ant-modal-body
.ant-form
.landing-select
.ant-select-selection
.ant-select-selection__rendered {
  height: 32px !important;
  margin-right: 0px !important; }

.rfq-modal
.ant-modal-body
.ant-form
.landing-select
.ant-select-selection
.ant-select-arrow {
  top: 35%; }


.floating-widget-wraper {
  width: 286px !important; }
  .floating-widget-wraper .pricing-container {
    padding: 12px 12px;
    background-color: #f5f5f5; }
    .floating-widget-wraper .pricing-container .horizental-line {
      height: 1px;
      background-color: #b2b2b2;
      margin-top: 12px;
      margin-bottom: 12px; }
    .floating-widget-wraper .pricing-container i {
      margin: 0 8px; }
    .floating-widget-wraper .pricing-container p {
      font-size: 12px;
      color: #898989; }
    .floating-widget-wraper .pricing-container span {
      font-size: 12px;
      color: #898989;
      font-weight: 500; }
    .floating-widget-wraper .pricing-container .float-heading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 8px; }
    .floating-widget-wraper .pricing-container .float-heading:nth-of-type(1) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 8px; }
      .floating-widget-wraper .pricing-container .float-heading:nth-of-type(1) p {
        font-size: 12px;
        margin-right: 4px; }
      .floating-widget-wraper .pricing-container .float-heading:nth-of-type(1) span {
        font-size: 12px;
        color: #0068ff;
        margin-left: 0px !important; }
    .floating-widget-wraper .pricing-container .float-heading:nth-of-type(2) i {
      margin: 0px !important;
      margin-left: 4px !important; }
    .floating-widget-wraper .pricing-container .price-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 8px; }
      .floating-widget-wraper .pricing-container .price-heading span {
        font-size: 16px;
        font-weight: 600;
        color: #212121; }
    .floating-widget-wraper .pricing-container .gst-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px; }
      .floating-widget-wraper .pricing-container .gst-heading p {
        font-style: italic; }
      .floating-widget-wraper .pricing-container .gst-heading span {
        font-size: 12px;
        font-weight: 400;
        color: #898989; }
      .floating-widget-wraper .pricing-container .gst-heading i {
        font-size: 16px; }
    .floating-widget-wraper .pricing-container .info-container {
      padding-top: 4px;
      padding-bottom: 6px; }
      .floating-widget-wraper .pricing-container .info-container p {
        font-style: italic; }
      .floating-widget-wraper .pricing-container .info-container i {
        font-size: 16px; }
      .floating-widget-wraper .pricing-container .info-container div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 6px; }
    .floating-widget-wraper .pricing-container .total-cost-container {
      border-top: 1px solid #b2b2b2;
      padding-top: 12px; }
      .floating-widget-wraper .pricing-container .total-cost-container .cost-wraper {
        display: flex;
        align-items: center; }
      .floating-widget-wraper .pricing-container .total-cost-container .total-cost {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 12px; }
        .floating-widget-wraper .pricing-container .total-cost-container .total-cost > p:first-of-type {
          text-decoration: underline; }
        .floating-widget-wraper .pricing-container .total-cost-container .total-cost .f16 {
          font-size: 16px;
          color: #212121;
          font-weight: 600;
          text-decoration: none !important; }
      .floating-widget-wraper .pricing-container .total-cost-container .gst-desc {
        font-size: 12px;
        color: #898989;
        text-align: center;
        font-size: 10px;
        font-style: italic; }
    .floating-widget-wraper .pricing-container .raise_booking_request button {
      border-radius: 2px;
      width: 100%;
      margin: 12px 0;
      font-size: 16px;
      color: #fff;
      height: 40px;
      background-color: #1370fc;
      box-shadow: #00000033 0px 2px 3px; }
      .floating-widget-wraper .pricing-container .raise_booking_request button span {
        color: #fff;
        font-size: 16px; }
    .floating-widget-wraper .pricing-container .raise_booking_request p {
      font-size: 12px;
      color: #898989;
      text-align: center;
      font-size: 10px;
      font-style: italic; }
    .floating-widget-wraper .pricing-container .raise_booking_request .ant-btn.ant-btn-cancel {
      background-color: #f5f5f5 !important; }
      .floating-widget-wraper .pricing-container .raise_booking_request .ant-btn.ant-btn-cancel span {
        color: #898989 !important; }
  .floating-widget-wraper .gst-heading-bottom {
    display: flex;
    align-items: center;
    margin-bottom: 12px; }
    .floating-widget-wraper .gst-heading-bottom i {
      margin-left: 4px;
      margin-right: 0px !important; }

.rasing-bulk-order-modal * {
  box-sizing: border-box;
  letter-spacing: 0.032em;
  margin: 0;
  padding: 0; }

.rasing-bulk-order-modal .ant-modal-close-x {
  display: none; }

.rasing-bulk-order-modal p {
  font-size: 12px;
  color: #898989; }

.rasing-bulk-order-modal h1 {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 5px; }

.rasing-bulk-order-modal .ant-modal-header {
  padding-top: 24px;
  margin: 0 24px; }
  .rasing-bulk-order-modal .ant-modal-header p {
    margin-bottom: 12px; }

.rasing-bulk-order-modal .ant-modal-footer {
  margin: 0 24px;
  padding-bottom: 26px;
  padding-top: 16px;
  display: flex; }
  .rasing-bulk-order-modal .ant-modal-footer .note {
    width: 60px; }
    .rasing-bulk-order-modal .ant-modal-footer .note p:first-of-type {
      margin-bottom: 5px; }
    .rasing-bulk-order-modal .ant-modal-footer .note p {
      text-align: left;
      font-size: 10px;
      font-style: italic; }
  .rasing-bulk-order-modal .ant-modal-footer .note-desc p:first-of-type {
    margin-bottom: 5px; }
  .rasing-bulk-order-modal .ant-modal-footer .note-desc p {
    text-align: left;
    font-size: 10px;
    font-style: italic; }
  .rasing-bulk-order-modal .ant-modal-footer .btns {
    width: 700px;
    display: flex;
    justify-content: end;
    align-items: center;
    border-radius: 2px; }
    .rasing-bulk-order-modal .ant-modal-footer .btns button:first-of-type {
      width: 160px; }
    .rasing-bulk-order-modal .ant-modal-footer .btns button {
      font-size: 12px;
      font-weight: 500;
      width: 208px;
      height: 40px;
      border-radius: 2px !important; }

.rasing-bulk-order-modal .ant-modal-body {
  padding: 24px;
  padding-top: 16px;
  font-size: 12px; }
  .rasing-bulk-order-modal .ant-modal-body .content-headings {
    padding: 12px 16px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings div {
      display: flex;
      align-items: center;
      font-style: italic; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-1 {
      font-style: normal; }
      .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-1 span {
        margin-left: 4px;
        color: #0068ff; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-2 p {
      margin-right: 4px; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-2 i {
      font-size: 16px;
      margin-left: 8px; }
    .rasing-bulk-order-modal .ant-modal-body .content-headings .content-head-3 i {
      font-size: 16px;
      margin-left: 4px; }
  .rasing-bulk-order-modal .ant-modal-body .variants-headings {
    padding: 0px 16px 4px 16px;
    display: flex;
    justify-content: space-between; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-1 {
      width: 380px !important;
      justify-content: flex-start; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-2 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-3 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-headings .head-4 {
      width: 100px; }
  .rasing-bulk-order-modal .ant-modal-body .variants-data-row {
    padding: 8px 16px;
    display: flex;
    height: 72px;
    background-color: #f5f5f5;
    margin-bottom: 8px;
    justify-content: space-between; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 {
      width: 380px !important;
      justify-content: flex-start; }
      .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .details {
        flex-direction: column;
        align-items: flex-start; }
        .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .details p {
          color: #898989;
          margin-right: 4px; }
        .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .details span {
          color: #212121; }
      .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .img {
        height: 56px;
        width: 56px;
        margin-right: 16px; }
        .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-1 .img img {
          object-fit: cover;
          height: 100%;
          width: inherit; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-2 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-3 {
      width: 100px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-data-row .head-4 {
      width: 100px; }
  .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    height: 48px;
    background-color: #f5f5f5; }
    .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .included {
      font-style: italic;
      color: #898989;
      font-size: 12px; }
    .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .total-price {
      display: flex;
      justify-content: end;
      align-items: center; }
      .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .total-price .gst {
        font-size: 12px;
        color: #898989; }
      .rasing-bulk-order-modal .ant-modal-body .variants-total-pricing .total-price .price {
        width: 100px;
        margin-left: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #212121; }

.rasing-bulk-order-modal .product-tooltip:hover svg.product-tooltip-icon {
  background: #1268fb;
  border-radius: 50%;
  cursor: pointer; }

.rasing-bulk-order-modal .product-tooltip:hover svg.product-tooltip-icon g text {
  fill: #fff; }

.rasing-bulk-order-modal .product-tooltip:hover svg.product-tooltip-icon g > g {
  stroke: #1268fb; }

.opopp {
  margin-left: 16px; }
  .opopp .dragger-packing {
    border-radius: 0px; }
  .opopp .io-container {
    width: 110px;
    margin-right: 10px;
    height: 110px;
    margin-bottom: 12px;
    display: inline-block;
    background-color: lavender; }
    .opopp .io-container svg {
      display: flex;
      margin: auto;
      height: 110px; }
  .opopp .cointainer {
    display: flex;
    margin-top: 12px; }
  .opopp .heading {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 12px;
    font-weight: 400; }
  .opopp .drage-image {
    padding: 24px 16px;
    background-color: #f5f5f5;
    margin-right: 8px;
    width: 110px;
    height: 110px; }
  .opopp .drage-image:hover {
    background-color: #1370fc;
    color: #fff; }
    .opopp .drage-image:hover p {
      color: #fff; }
  .opopp .drage-image:hover svg g path {
    fill: #fff; }
  .opopp .drage-image svg {
    position: relative;
    left: 33%; }
  .opopp .image-cointainer {
    position: relative; }
    .opopp .image-cointainer .icon-cointainer-pdf {
      position: absolute;
      height: 16px;
      width: 16px;
      display: inline-block;
      top: 2px;
      right: 12px;
      display: none; }
      .opopp .image-cointainer .icon-cointainer-pdf svg {
        height: 100%;
        width: 100%; }
    .opopp .image-cointainer .icon-cointainer {
      position: absolute;
      height: 16px;
      width: 16px;
      display: inline-block;
      top: -1px;
      right: 10px;
      display: none; }
      .opopp .image-cointainer .icon-cointainer svg {
        height: 100%;
        width: 100%; }
  .opopp .image-cointainer:hover .icon-cointainer {
    display: block; }
  .opopp .image-cointainer:hover .icon-cointainer-pdf {
    display: block; }

.recomended-packaging-modal * {
  box-sizing: border-box;
  letter-spacing: 0.032em !important;
  margin: 0;
  padding: 0; }

.recomended-packaging-modal .ant-modal-close-x {
  display: none; }

.recomended-packaging-modal p {
  font-size: 12px;
  color: #898989; }

.recomended-packaging-modal h1 {
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  margin-bottom: 5px; }

.recomended-packaging-modal .ant-modal-header {
  padding-top: 30px;
  margin: 0 24px;
  padding-bottom: 24px; }
  .recomended-packaging-modal .ant-modal-header p {
    margin-bottom: 12px;
    text-align: center; }
  .recomended-packaging-modal .ant-modal-header .ant-modal-title > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 5px; }
    .recomended-packaging-modal .ant-modal-header .ant-modal-title > div svg {
      margin-right: 10px;
      font-size: 60px;
      margin-bottom: 14px; }
    .recomended-packaging-modal .ant-modal-header .ant-modal-title > div h1 {
      line-height: 16px;
      margin-bottom: 4px; }

.recomended-packaging-modal .ant-modal-footer {
  margin: 0 24px;
  padding-bottom: 26px;
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .recomended-packaging-modal .ant-modal-footer .btns {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center; }
    .recomended-packaging-modal .ant-modal-footer .btns button:first-of-type {
      width: 160px;
      margin-right: 8px;
      border-radius: 2px !important; }
    .recomended-packaging-modal .ant-modal-footer .btns button {
      font-size: 12px;
      font-weight: 500;
      width: 208px;
      height: 40px; }
    .recomended-packaging-modal .ant-modal-footer .btns a {
      height: 40px;
      width: 208px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px !important; }
  .recomended-packaging-modal .ant-modal-footer .green-outline {
    background: #fff !important;
    margin-right: 8px;
    width: 100px;
    font-size: 12px;
    color: #2a9c27 !important;
    border: 1px solid #2a9c27; }
  .recomended-packaging-modal .ant-modal-footer .clickable-dropdown.custom-width {
    margin-top: 12px;
    width: 376px !important;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 9px; }

.recomended-packaging-modal .ant-modal-body {
  padding: 24px;
  padding-top: 20px;
  font-size: 12px; }
  .recomended-packaging-modal .ant-modal-body .head-1 {
    width: 480px;
    justify-content: flex-start !important; }
  .recomended-packaging-modal .ant-modal-body .head-2 {
    width: 100px; }
    .recomended-packaging-modal .ant-modal-body .head-2 .Complimentary {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      width: 120px;
      color: #9d24d6;
      border-radius: 2px;
      border: 1px solid #9d24d6;
      font-weight: 500;
      padding: 0 8px; }
  .recomended-packaging-modal .ant-modal-body .head-3 {
    width: 100px; }
  .recomended-packaging-modal .ant-modal-body .head-4 {
    width: 100px; }
  .recomended-packaging-modal .ant-modal-body .variants-headings {
    padding: 0px 16px 4px 16px;
    display: flex;
    justify-content: space-between; }
    .recomended-packaging-modal .ant-modal-body .variants-headings div {
      display: flex;
      align-items: center;
      justify-content: center; }
  .recomended-packaging-modal .ant-modal-body .variants-data-row {
    padding: 8px 16px;
    display: flex;
    height: 72px;
    background-color: #f5f5f5;
    margin-bottom: 8px;
    justify-content: space-between; }
    .recomended-packaging-modal .ant-modal-body .variants-data-row div {
      display: flex;
      align-items: center;
      justify-content: center; }
    .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 {
      justify-content: flex-start; }
      .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details {
        flex-direction: column;
        align-items: flex-start; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details p {
          color: #898989;
          margin-right: 4px; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .details span {
          color: #212121; }
      .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .img {
        height: 56px;
        width: 56px;
        margin-right: 16px; }
        .recomended-packaging-modal .ant-modal-body .variants-data-row .head-1 .img img {
          object-fit: cover;
          height: 100%;
          width: inherit; }

.recomended-packaging-modal.hide-border .ant-modal-header {
  border-bottom: none; }

.recomended-packaging-modal.hide-border .ant-modal-body {
  display: none; }

.variants-table-pricing-table-wraper {
  margin-bottom: 24px;
  margin-top: 20px; }
  .variants-table-pricing-table-wraper .heading {
    height: 32px;
    background-color: #26b99a;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0px 16px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .variants-table-pricing-table-wraper .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between; }
  .variants-table-pricing-table-wraper .pricing-wraper {
    border: 2px solid #26b99a;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .variants-table-pricing-table-wraper .pricing-wraper .table-headings,
    .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings,
    .variants-table-pricing-table-wraper .pricing-wraper .table-row-data {
      background-color: #26b99a;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings div,
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings div,
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data div {
        display: flex;
        align-items: center;
        justify-content: center; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings .transit-time,
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings .transit-time,
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data .transit-time {
        width: 160px;
        padding-left: 16px;
        justify-content: flex-start; }
        .variants-table-pricing-table-wraper .pricing-wraper .table-headings .transit-time .img,
        .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings .transit-time .img,
        .variants-table-pricing-table-wraper .pricing-wraper .table-row-data .transit-time .img {
          height: 24px;
          width: 24px;
          border-radius: 2px;
          margin-right: 8px; }
          .variants-table-pricing-table-wraper .pricing-wraper .table-headings .transit-time .img img,
          .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings .transit-time .img img,
          .variants-table-pricing-table-wraper .pricing-wraper .table-row-data .transit-time .img img {
            height: 100%;
            object-fit: cover;
            width: inherit;
            border-radius: 2px; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings .vf-days,
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings .vf-days,
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data .vf-days {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(3),
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(3),
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(3) {
        width: 120px;
        justify-content: flex-start; }
        .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(3) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(3) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(3) p {
          margin-right: 6px; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(4),
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(4),
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(4) {
        width: 110px;
        justify-content: flex-start; }
        .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(4) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(4) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(4) p {
          margin-right: 6px; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(5),
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(5),
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(5) {
        width: 130px;
        justify-content: flex-start; }
        .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(5) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(5) p,
        .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(5) p {
          margin-right: 6px; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-headings :nth-child(6),
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings :nth-child(6),
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data :nth-child(6) {
        width: 90px; }
    .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-sub-headings div {
        color: #898989; }
    .variants-table-pricing-table-wraper .pricing-wraper .table-row-data {
      height: 48px;
      background-color: #fff; }
      .variants-table-pricing-table-wraper .pricing-wraper .table-row-data div {
        color: #212121; }
    .variants-table-pricing-table-wraper .pricing-wraper .table-row-data:nth-child(odd) {
      background-color: #f5f5f5 !important; }

.inventory-restocking-table-wraper {
  margin-bottom: 24px; }
  .inventory-restocking-table-wraper .heading {
    height: 32px;
    background-color: #26b99a;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 0px 16px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px; }
  .inventory-restocking-table-wraper .heading-wraper {
    display: flex;
    align-items: end;
    justify-content: space-between; }
    .inventory-restocking-table-wraper .heading-wraper button {
      font-size: 12px;
      padding: 0px 16px;
      color: #898989;
      height: 24px;
      border: none;
      background-color: #f5f5f5;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px; }
      .inventory-restocking-table-wraper .heading-wraper button i {
        margin-left: 8px; }
  .inventory-restocking-table-wraper .pricing-wraper {
    border: 2px solid #26b99a;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .inventory-restocking-table-wraper .pricing-wraper .table-headings,
    .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings,
    .inventory-restocking-table-wraper .pricing-wraper .table-row-data {
      background-color: #26b99a;
      width: 100%;
      height: 22px;
      display: flex;
      font-size: 12px;
      color: #fff;
      align-items: center;
      justify-content: space-between; }
      .inventory-restocking-table-wraper .pricing-wraper .table-headings div,
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings div,
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data div {
        display: flex;
        align-items: center;
        justify-content: center; }
      .inventory-restocking-table-wraper .pricing-wraper .table-headings .transit-time,
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings .transit-time,
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data .transit-time {
        width: 340px;
        justify-content: flex-start;
        padding-left: 16px; }
      .inventory-restocking-table-wraper .pricing-wraper .table-headings .vf-days,
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings .vf-days,
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data .vf-days {
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .inventory-restocking-table-wraper .pricing-wraper .table-headings .vf-days i,
        .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings .vf-days i,
        .inventory-restocking-table-wraper .pricing-wraper .table-row-data .vf-days i {
          margin: 0px 4px; }
      .inventory-restocking-table-wraper .pricing-wraper .table-headings :nth-child(3),
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings :nth-child(3),
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data :nth-child(3) {
        width: 180px;
        margin-right: 40px; }
      .inventory-restocking-table-wraper .pricing-wraper .table-headings :nth-child(4),
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings :nth-child(4),
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data :nth-child(4) {
        width: 130px; }
    .inventory-restocking-table-wraper .pricing-wraper .table-headings .sea-days {
      width: 58%; }
    .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings {
      height: 32px;
      background-color: #f5f5f5; }
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings div {
        color: #898989; }
      .inventory-restocking-table-wraper .pricing-wraper .table-sub-headings .air-days {
        width: 160px; }
    .inventory-restocking-table-wraper .pricing-wraper .table-row-data {
      height: 48px;
      background-color: #fff; }
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data div {
        color: #212121; }
      .inventory-restocking-table-wraper .pricing-wraper .table-row-data .air-days {
        width: 160px; }
    .inventory-restocking-table-wraper .pricing-wraper .table-row-data:nth-child(even) {
      background-color: #f5f5f5 !important; }

.packaging-card {
  background: white;
  margin-bottom: 24px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  flex-direction: column; }
  .packaging-card * {
    letter-spacing: 0.032em; }
  .packaging-card .content {
    padding: 16px;
    padding-right: 24px;
    padding-bottom: 0px;
    display: flex; }
    .packaging-card .content .product-img {
      height: 164px;
      position: relative;
      overflow: hidden;
      border-radius: 2px;
      margin-right: 24px;
      margin-bottom: 0px; }
      .packaging-card .content .product-img img {
        width: 164px;
        height: 164px;
        object-fit: cover;
        border-radius: 2px;
        border: 2px solid #f5f5f5;
        transition: all 0.5s ease; }
      .packaging-card .content .product-img .prev-img,
      .packaging-card .content .product-img .next-img {
        position: absolute;
        transition: all 0.5s ease;
        height: 100%;
        width: 32px;
        background: rgba(33, 33, 33, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        display: none; }
      .packaging-card .content .product-img .prev-img {
        top: 0;
        left: 0; }
      .packaging-card .content .product-img .next-img {
        top: 0;
        right: 0; }
    .packaging-card .content .product-img:hover img {
      transition: all 0.5s ease;
      transform: scale(1.05); }
    .packaging-card .content .product-img:hover .prev-img,
    .packaging-card .content .product-img:hover .next-img {
      transition: all 0.5s ease;
      opacity: 1;
      transform: scale(1.05); }
    .packaging-card .content .product-img:hover .prev-img,
    .packaging-card .content .product-img:hover .next-img {
      display: block; }
    .packaging-card .content .details {
      flex: 1 1; }
      .packaging-card .content .details .packaging-name {
        color: #1268fb;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 6px; }
      .packaging-card .content .details .price-title .packaging-with-tooltip {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4px; }
        .packaging-card .content .details .price-title .packaging-with-tooltip > div {
          display: flex;
          align-items: center;
          justify-content: flex-start; }
          .packaging-card .content .details .price-title .packaging-with-tooltip > div p {
            color: #898989;
            font-size: 12px; }
      .packaging-card .content .details .cost-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        background-color: #f5f5f5;
        height: 32px;
        margin-bottom: 6px; }
        .packaging-card .content .details .cost-field .price-range {
          color: #1c6513;
          font-weight: 500;
          font-size: 12px; }
        .packaging-card .content .details .cost-field .gst {
          color: #212121;
          font-style: italic;
          font-size: 12px; }
      .packaging-card .content .details .last-updated-pricing {
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        .packaging-card .content .details .last-updated-pricing p {
          font-size: 10px;
          color: #898989;
          margin-right: 4px; }
        .packaging-card .content .details .last-updated-pricing span {
          font-size: 10px;
          color: #212121; }
  .packaging-card .view-packaging-details {
    height: 48px;
    background-color: #f5faff;
    display: flex;
    margin-top: 16px;
    align-items: center;
    justify-content: center; }
    .packaging-card .view-packaging-details p {
      color: #0068ff;
      font-weight: 600;
      font-size: 12px;
      margin-right: 8px; }
  .packaging-card .request-latest-price.disabled {
    color: #898989; }
    .packaging-card .request-latest-price.disabled svg {
      font-size: 16px;
      margin-right: 8px; }
  .packaging-card .request-latest-price {
    display: flex;
    color: #212121 !important;
    font-size: 10px !important; }
    .packaging-card .request-latest-price svg {
      color: #212121;
      font-size: 14px;
      margin-right: 5px;
      transition: all 0.3s;
      transform: rotate(0deg); }
  .packaging-card .request-latest-price:hover {
    display: flex;
    color: #212121 !important;
    transition: all 0.3s; }
    .packaging-card .request-latest-price:hover svg {
      transform: rotate(220deg) !important; }
      .packaging-card .request-latest-price:hover svg path {
        fill: #0058d6 !important; }
  .packaging-card .request-latest-price.disabled {
    color: #898989 !important;
    font-size: 10px !important;
    transition: none; }
    .packaging-card .request-latest-price.disabled svg {
      font-size: 14px !important;
      margin-right: 5px !important; }
      .packaging-card .request-latest-price.disabled svg path {
        fill: #898989 !important; }
  .packaging-card .request-latest-price.disabled:hover {
    color: #898989;
    transform: none; }
    .packaging-card .request-latest-price.disabled:hover svg {
      transform: rotate(0deg) !important; }
      .packaging-card .request-latest-price.disabled:hover svg path {
        fill: #898989 !important; }
  .packaging-card .hover-underline-animation {
    position: relative;
    color: #0087ca; }
    .packaging-card .hover-underline-animation i {
      transition: all 0.5s; }
  .packaging-card .hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 25.9%;
    transform: scaleX(0);
    height: 1px;
    left: 7px;
    top: 32px;
    margin-left: 33.2%;
    background-color: #2986ff;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .packaging-card .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: top left; }
  .packaging-card .hover-underline-animation:hover i {
    transition: all 0.5s;
    transform: translateX(8px); }
  .packaging-card .parent {
    height: 32px;
    width: 100%;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center; }
    .packaging-card .parent h1 {
      font-size: 16px;
      color: #1c6513;
      font-weight: medium;
      margin: 0;
      padding: 0;
      line-height: 0;
      margin-right: 16px; }

.catalog-listing {
  background: #f0f2f5; }
  .catalog-listing .div-sidebar {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .catalog-listing .div-sidebar h1 {
      margin-right: 8px; }
  .catalog-listing .f8 {
    font-size: 8px; }
  .catalog-listing .f10 {
    font-size: 10px; }
  .catalog-listing .f12 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    margin-top: 0.4rem; }
    .catalog-listing .f12 .special-cost-txt {
      color: #898989;
      margin-right: 8px; }
  .catalog-listing .product-card-cost {
    margin-bottom: 4px;
    align-items: center;
    display: flex; }
  .catalog-listing .f16 {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.032em; }
  .catalog-listing .f20 {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.032em; }
  .catalog-listing .mr0 {
    margin-right: 0px; }
  .catalog-listing .ml0 {
    margin-left: 0px; }
  .catalog-listing .mt0 {
    margin-top: 0px; }
  .catalog-listing .mb0 {
    margin-bottom: 0px; }
  .catalog-listing .mb4 {
    margin-bottom: 4px; }
  .catalog-listing .mr8 {
    margin-right: 8px; }
  .catalog-listing .ml8 {
    margin-left: 8px; }
  .catalog-listing .mt8 {
    margin-top: 8px; }
  .catalog-listing .mb8 {
    margin-bottom: 8px; }
  .catalog-listing .mr16 {
    margin-right: 16px; }
  .catalog-listing .ml16 {
    margin-left: 16px; }
  .catalog-listing .mt16 {
    margin-top: 10px; }
  .catalog-listing .mb16 {
    margin-bottom: 10px; }
  .catalog-listing .w100 {
    width: 100%; }
  .catalog-listing .py8 {
    padding-top: 8px;
    padding-bottom: 8px; }
  .catalog-listing .px8 {
    padding-left: 8px;
    padding-right: 8px; }
  .catalog-listing .py2 {
    padding-top: 2px;
    padding-bottom: 2px; }
  .catalog-listing .px2 {
    padding-left: 2px;
    padding-right: 2px; }
  .catalog-listing .semibold {
    font-weight: 500; }
  .catalog-listing .bold {
    font-weight: 600; }
    .catalog-listing .bold .default_retail_price {
      font-size: 12px;
      margin-left: -10px;
      margin-right: 8px;
      font-style: italic;
      letter-spacing: 0.032em;
      text-decoration: line-through;
      -webkit-text-decoration-color: #898989;
              text-decoration-color: #898989; }
  .catalog-listing .italic {
    font-style: italic;
    margin-top: 8px; }
  .catalog-listing .underline {
    text-decoration: underline !important; }
  .catalog-listing button {
    cursor: pointer; }
  .catalog-listing .clamp1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .catalog-listing .clamp2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .catalog-listing .niche-item {
    font-size: 12px;
    color: #212121;
    text-transform: capitalize; }
  .catalog-listing .catalog-listing-content {
    width: 90% !important;
    margin: auto;
    display: flex;
    padding-top: 24px; }
    .catalog-listing .catalog-listing-content .content-sidebar {
      width: 248px;
      margin-right: 24px; }
      .catalog-listing .catalog-listing-content .content-sidebar .filters {
        background: white;
        padding: 16px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 0px; }
        .catalog-listing .catalog-listing-content .content-sidebar .filters .no-filters {
          height: 24px;
          background-color: #eeeeee;
          color: #898989;
          font-size: 12px;
          text-align: left;
          padding-left: 8px;
          display: flex;
          align-items: center;
          justify-content: flex-start; }
        .catalog-listing .catalog-listing-content .content-sidebar .filters .filter-tag {
          display: block;
          color: #acacac;
          background: #eeeeee;
          padding: 8px 16px;
          margin-bottom: 16px;
          letter-spacing: 0.025em;
          display: flex;
          align-items: center; }
          .catalog-listing .catalog-listing-content .content-sidebar .filters .filter-tag .filter-available {
            height: 16px;
            margin-right: 8px; }
          .catalog-listing .catalog-listing-content .content-sidebar .filters .filter-tag .buyback {
            height: 16px;
            margin-right: 8px; }
        .catalog-listing .catalog-listing-content .content-sidebar .filters .filter-tag.active {
          color: #212121; }
        .catalog-listing .catalog-listing-content .content-sidebar .filters .location-tag.active {
          background: #2bc822;
          color: white; }
        .catalog-listing .catalog-listing-content .content-sidebar .filters .ican-image {
          height: 16px;
          margin-right: 8px; }
          .catalog-listing .catalog-listing-content .content-sidebar .filters .ican-image .anticon svg {
            width: 16px;
            height: 16px; }
        .catalog-listing .catalog-listing-content .content-sidebar .filters .filter-text {
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px; }
        .catalog-listing .catalog-listing-content .content-sidebar .filters .ant-checkbox-inner {
          border: none; }
      .catalog-listing .catalog-listing-content .content-sidebar .filter-product-data {
        margin-top: 27px; }
    .catalog-listing .catalog-listing-content .content {
      flex: 1 1; }
    .catalog-listing .catalog-listing-content .catalog-listing-labelsection {
      margin-bottom: 30px; }
      .catalog-listing .catalog-listing-content .catalog-listing-labelsection .catalog-listing-labelhead {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0px;
        border-bottom: 2px solid #000;
        padding-bottom: 0px;
        font-size: 18px; }
  .catalog-listing .product-Tooltipican:hover svg.product-tooltip-icon {
    background: #1268fb;
    border-radius: 50%;
    cursor: pointer; }
  .catalog-listing .product-Tooltipican:hover svg.product-tooltip-icon g text {
    fill: #fff; }
  .catalog-listing .product-Tooltipican:hover svg.product-tooltip-icon g > g {
    stroke: #1268fb; }
  .catalog-listing .top-import-button {
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    background: #2bc822;
    padding: 8px 16px;
    color: #fff;
    max-height: 40px;
    border-radius: 2px;
    border: none; }
  .catalog-listing .product-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px; }
    .catalog-listing .product-grid .product-card {
      background: white;
      margin-bottom: 24px;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
      position: relative;
      display: flex;
      flex-direction: column; }
      .catalog-listing .product-grid .product-card .potd-label {
        position: absolute;
        background: linear-gradient(150deg, #fd104d, #f0320f);
        padding: 4px 8px;
        color: white;
        top: 8px;
        z-index: 2; }
      .catalog-listing .product-grid .product-card .product-watchlist-btn {
        position: absolute;
        top: 0;
        right: 16px;
        padding: 0;
        border: none;
        background: transparent; }
        .catalog-listing .product-grid .product-card .product-watchlist-btn .anticon {
          position: relative;
          left: 16px; }
          .catalog-listing .product-grid .product-card .product-watchlist-btn .anticon svg {
            width: 48px;
            height: 48px; }
      .catalog-listing .product-grid .product-card .content {
        padding: 16px;
        padding-bottom: 0px;
        display: flex; }
        .catalog-listing .product-grid .product-card .content .sorter .ant-dropdown-link.ant-dropdown-trigger {
          color: #0068ff !important; }
        .catalog-listing .product-grid .product-card .content .product-img {
          height: 168px;
          position: relative;
          overflow: hidden;
          border-radius: 2px;
          margin-right: 16px;
          margin-bottom: 4px; }
          .catalog-listing .product-grid .product-card .content .product-img img {
            width: 168px;
            height: 168px;
            object-fit: cover;
            border-radius: 2px;
            border: 2px solid #f5f5f5;
            transition: all 0.5s ease; }
          .catalog-listing .product-grid .product-card .content .product-img .prev-img,
          .catalog-listing .product-grid .product-card .content .product-img .next-img {
            position: absolute;
            transition: all 0.5s ease;
            height: 100%;
            width: 32px;
            background: rgba(33, 33, 33, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            display: none; }
          .catalog-listing .product-grid .product-card .content .product-img .prev-img {
            top: 0;
            left: 0; }
          .catalog-listing .product-grid .product-card .content .product-img .next-img {
            top: 0;
            right: 0; }
        .catalog-listing .product-grid .product-card .content .product-img:hover img {
          transition: all 0.5s ease;
          transform: scale(1.05); }
        .catalog-listing .product-grid .product-card .content .product-img:hover .prev-img,
        .catalog-listing .product-grid .product-card .content .product-img:hover .next-img {
          transition: all 0.5s ease;
          opacity: 1;
          transform: scale(1.05); }
        .catalog-listing .product-grid .product-card .content .product-img:hover .prev-img,
        .catalog-listing .product-grid .product-card .content .product-img:hover .next-img {
          display: block; }
        .catalog-listing .product-grid .product-card .content .details {
          flex: 1 1; }
  .catalog-listing .product-stock-count {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    margin-bottom: 6px; }
  .catalog-listing .stock-processing-time {
    font-size: 12px;
    letter-spacing: 0.032em;
    color: #898989;
    margin-bottom: 6px; }
  .catalog-listing .product-research-txt {
    margin-top: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: start;
    align-items: center; }
  .catalog-listing .product-tooltip-icon {
    margin-top: -2px; }
  .catalog-listing .sv:hover {
    fill: #0068ff; }
  .catalog-listing .anticon:hover svg #Ellipse_44 {
    stroke: #1268fb;
    fill: #1268fb; }
  .catalog-listing .anticon:hover svg #Ellipse_47 {
    stroke: #fff;
    fill: #ffff; }
  .catalog-listing .anticon:hover svg #Rectangle_2462 {
    stroke: #fff;
    fill: #ffff; }
  .catalog-listing .product-Tooltipican svg {
    width: 16px;
    height: 16px; }
  .catalog-listing .prev-img svg {
    width: 18px;
    height: 41px; }
  .catalog-listing .next-img svg {
    width: 18px;
    height: 41px; }
  .catalog-listing .cost-update-date {
    color: #212121; }
  .catalog-listing .filter-range {
    color: #acacac;
    background: #eeeeee;
    padding: 16px;
    margin-bottom: 16px;
    letter-spacing: 0.025em; }
    .catalog-listing .filter-range .inventory-restocking-time-txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      font-weight: 400;
      margin-right: 8px; }
    .catalog-listing .filter-range .refresh-svg .anticon svg {
      width: 8px;
      height: 8px;
      margin-bottom: 2px; }
    .catalog-listing .filter-range .ant-slider-rail {
      height: 4px;
      background-color: #cecece;
      border-radius: 0px; }
    .catalog-listing .filter-range .ant-slider:hover .ant-slider-rail {
      background-color: #cecece; }
    .catalog-listing .filter-range .ant-slider-handle {
      background-color: #2bc822;
      border: solid 2px #2bc822; }
    .catalog-listing .filter-range .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: #2bc822; }
    .catalog-listing .filter-range .type-range {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .catalog-listing .filter-range .type-range .start-range {
        width: 56px;
        height: 24px;
        border: none;
        color: #212121;
        font-size: 12px;
        text-align: center; }
      .catalog-listing .filter-range .type-range .end-range {
        width: 56px;
        height: 24px;
        border: none;
        color: #212121;
        font-size: 12px;
        text-align: center; }
      .catalog-listing .filter-range .type-range .days {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .catalog-listing .filter-range .ant-slider {
      margin: 8px 0px 16px; }
    .catalog-listing .filter-range .ant-slider-track {
      background-color: #36c626; }
  .catalog-listing .set-range-btn {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 188px;
    margin-left: -2px; }
  .catalog-listing .request_lattest_ican {
    display: flex;
    justify-content: space-between;
    margin-bottom: -19px; }
  .catalog-listing .request_lattest_ican2 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px; }
  .catalog-listing .sorter-link .arrow {
    transition: all 0.3s linear;
    transform: rotateX(0deg) !important;
    margin-left: 4px;
    vertical-align: middle; }
    .catalog-listing .sorter-link .arrow svg {
      font-size: 16px; }
  .catalog-listing .sorter-link .arrow-up {
    transform: rotateX(180deg) !important;
    transition: all 0.3s linear; }
  .catalog-listing .catalog-listing-labelhead {
    display: flex;
    align-items: center;
    border-bottom: none !important; }
  .catalog-listing .catalog-listing-labelviewall {
    padding: 0 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid #212121;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    position: relative;
    z-index: 999; }
    .catalog-listing .catalog-listing-labelviewall p {
      color: #212121 !important; }
    .catalog-listing .catalog-listing-labelviewall .white-underline-animation {
      display: inline-block;
      position: relative;
      color: #ffffff; }
    .catalog-listing .catalog-listing-labelviewall .white-underline-animation:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      color: #fff;
      background-color: #fff;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out; }
    .catalog-listing .catalog-listing-labelviewall .forward svg {
      transition: all 0.4s;
      transform: rotate(-90deg); }
  .catalog-listing .catalog-listing-labelviewall:hover {
    background-color: #212121 !important; }
    .catalog-listing .catalog-listing-labelviewall:hover p {
      color: #fff !important; }
    .catalog-listing .catalog-listing-labelviewall:hover .white-underline-animation:after {
      transform: scaleX(1);
      transform-origin: bottom left; }
    .catalog-listing .catalog-listing-labelviewall:hover svg g path:nth-of-type(2) {
      fill: #fff; }
    .catalog-listing .catalog-listing-labelviewall:hover svg path {
      fill: #fff; }
    .catalog-listing .catalog-listing-labelviewall:hover .backward svg {
      transition: all 0.4s;
      transform: rotate(90deg) translateY(6px) !important; }
    .catalog-listing .catalog-listing-labelviewall:hover .forward svg {
      transition: all 0.4s;
      transform: rotate(-90deg) translateY(6px) !important; }
  .catalog-listing .ant-divider-horizontal {
    margin: -1px 0 32px 0 !important;
    height: 2px;
    background-color: #21d4fd;
    background-image: linear-gradient(19deg, #35343a 0%, #f0f2f6 90%); }
  .catalog-listing .catalog-listing-labelname {
    font-size: 14px;
    letter-spacing: 0.032em;
    color: #fff;
    font-weight: 500;
    margin: 0 !important;
    margin-bottom: 0px !important;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    padding: 0 !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    height: 36px;
    display: flex;
    align-items: center;
    background: linear-gradient(28deg, #333239, #3b393f); }
  .catalog-listing .viewmore-label-products {
    cursor: pointer;
    font-size: 12px !important;
    letter-spacing: 0.032em;
    font-weight: 500;
    color: #fff; }
    .catalog-listing .viewmore-label-products svg {
      transition: all 0.4s; }
      .catalog-listing .viewmore-label-products svg g path:nth-of-type(2) {
        fill: #212121; }
  .catalog-listing .viewmore-label-products.backward svg {
    transform: rotate(90deg); }

.content .sorter .ant-dropdown-link.ant-dropdown-trigger {
  color: #0068ff !important; }

@media screen and (min-width: 1360px) {
  .catalog-listing .catalog-listing-content {
    width: 90%;
    margin: auto; } }

.wallet-tabs .ant-pagination {
  margin-top: 20px; }

.wallet-tabs thead tr th {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.032em;
  background: #16273b !important;
  border-radius: 0 !important;
  color: white !important; }

.wallet-tabs tbody tr {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.032em; }

.wallet-tabs .ant-tabs-bar {
  border-bottom: unset;
  background: white; }

.wallet-tabs .ant-tabs-tab {
  font-size: 18px;
  padding-bottom: 8px;
  color: #262626;
  font-weight: 500; }

.wallet-tabs .ant-tabs-tab-active {
  color: #007bff; }

.wallet-tabs .ant-tabs-ink-bar {
  background-color: #007bff; }

.wallet-tabs .ant-table-thead > tr > th .ant-table-header-column {
  display: inline-block;
  vertical-align: top;
  width: 110px; }

.wallet-tabs .ant-table-thead > tr > th:nth-child(4) .ant-table-header-column {
  width: 175px !important; }

.wallet-tabs .ant-table-thead > tr > th:nth-child(7) .ant-table-header-column {
  width: 70px !important; }

.wallet-tabs .ant-table-thead > tr > th:nth-child(6) .ant-table-header-column {
  width: 70px !important; }

.wallet-tabs .ant-table-thead > tr > th:nth-child(8) .ant-table-header-column {
  width: 70px !important; }

.wallet-tabs .ant-table-thead > tr > th:nth-child(9) .ant-table-header-column {
  width: 70px !important; }

.wallet-tabs .ant-table-layout-fixed table {
  table-layout: unset !important; }

.wallet-tabs .ant-table-thead {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0px !important;
  z-index: 99 !important;
  white-space: nowrap; }

.wallet-tabs .ant-table-tbody > tr > td {
  border: none !important;
  color: #595959;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 16px; }

.wallet-tabs .ant-table-tbody > tr:nth-child(even) {
  background-color: #f5f5f5; }

.wallet-tabs .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.wallet-tabs .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.wallet-tabs .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.wallet-tabs .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: none !important; }

.previous-page-link {
  padding: 15px;
  background: #192f4a;
  padding-bottom: 15px !important; }
  .previous-page-link a {
    color: white;
    font-size: 16px;
    font-weight: 500; }
    .previous-page-link a .anticon {
      margin-right: 10px; }

.chips-wraper {
  position: relative;
  bottom: 30px; }
  .chips-wraper .selectedValueChips {
    font-size: 12px;
    padding: 1px 6px;
    border-radius: 2px;
    background: #e6f4fe;
    display: inline-block;
    margin: 6px 8px;
    border: 2px solid #a6e6ff;
    position: relative; }
    .chips-wraper .selectedValueChips i.anticon.anticon-close {
      position: absolute;
      top: -7px;
      right: -6px;
      font-size: 10px;
      color: white;
      background: #a6e6ff;
      border-radius: 15px;
      padding: 2px; }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.my-wallet {
  margin-top: 48px; }
  .my-wallet .ant-alert-with-description * {
    letter-spacing: 0.032em; }
  .my-wallet .ant-alert-with-description .ant-alert-message {
    font-size: 12px;
    font-weight: 600;
    color: #212121; }
  .my-wallet .ant-alert-with-description .ant-alert-description {
    font-size: 12px;
    color: #212121; }
  .my-wallet .wallet-title {
    margin-top: 30px;
    font-size: 21px;
    font-weight: 500;
    margin-bottom: 0px; }
  .my-wallet .wallet-sub-title {
    font-size: 18px;
    font-weight: 500;
    color: #262626;
    margin-bottom: 30px; }
  .my-wallet .wallet-balance {
    display: flex; }
    .my-wallet .wallet-balance .wallet-balance-title {
      flex: 1.5 1;
      background: #0c182a;
      display: flex;
      margin: -24px;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      height: 176px; }
      .my-wallet .wallet-balance .wallet-balance-title p {
        color: white;
        font-size: 20px;
        letter-spacing: 0.032em;
        font-weight: 500;
        line-height: 20px; }
    .my-wallet .wallet-balance .wallet-balance-number {
      flex: 3 1;
      display: flex;
      align-items: center; }
      .my-wallet .wallet-balance .wallet-balance-number .balance-amount {
        flex: 1 1;
        text-align: center; }
        .my-wallet .wallet-balance .wallet-balance-number .balance-amount p {
          margin-bottom: 0px;
          color: #000;
          font-size: 24px;
          letter-spacing: 0.038em;
          font-weight: 500;
          line-height: 20px; }
      .my-wallet .wallet-balance .wallet-balance-number .balance-button {
        display: flex;
        flex-direction: column; }
        .my-wallet .wallet-balance .wallet-balance-number .balance-button .ant-btn {
          margin-bottom: 16px; }
  .my-wallet .add-balance-btn {
    background-color: #2bc822 !important;
    box-shadow: 0px 2px 8px #002f7225 !important;
    border: 2px solid #2bc822 !important;
    border-radius: 2px !important;
    line-height: none !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #fff !important;
    text-shadow: none !important;
    cursor: pointer;
    letter-spacing: 0.032em; }
  .my-wallet .ant-row {
    margin-bottom: 2rem; }
    .my-wallet .ant-row .ant-card {
      border: 1px solid #979797;
      border-radius: 4px; }
      .my-wallet .ant-row .ant-card .sub-text {
        color: #908f8f;
        font-size: 0.75rem;
        margin-bottom: 0;
        margin-top: 1rem; }
      .my-wallet .ant-row .ant-card .gap {
        flex: 1 1; }
      .my-wallet .ant-row .ant-card .transaction-details .review-button {
        font-weight: normal;
        font-size: 0.7rem;
        margin-left: 0.5rem;
        background: transparent;
        color: #fff;
        background-color: #0068ff;
        border-color: #0068ff; }
      .my-wallet .ant-row .ant-card .blue-text {
        color: #208aed; }
      .my-wallet .ant-row .ant-card .green-text {
        color: #229d1b;
        font-weight: 500; }
      .my-wallet .ant-row .ant-card .red-text {
        color: #c70c3c;
        font-weight: 500; }
      .my-wallet .ant-row .ant-card .orange-text {
        color: #ff9800;
        font-weight: 500; }
    .my-wallet .ant-row .table-card .ant-card-body,
    .my-wallet .ant-row .stats-card .ant-card-body {
      padding: 0px !important; }
    .my-wallet .ant-row .table-card .yaxis,
    .my-wallet .ant-row .stats-card .yaxis {
      display: flex;
      justify-content: end;
      font-weight: 500;
      font-size: 12px;
      color: #000;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .my-wallet .ant-row .table-card .yaxis p,
      .my-wallet .ant-row .stats-card .yaxis p {
        margin-right: 8px; }
    .my-wallet .ant-row .table-card .debit_credit,
    .my-wallet .ant-row .stats-card .debit_credit {
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      font-size: 12px;
      color: #000;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-bottom: 24px; }
  .my-wallet .ant-row:last-of-type {
    margin-bottom: 0.5rem; }
  .my-wallet .antd-pro-charts-chart-card-chartTop {
    display: none; }
  .my-wallet .antd-pro-charts-chart-card-content {
    overflow-x: scroll; }
  .my-wallet .withdrawBank {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px; }
    .my-wallet .withdrawBank li {
      line-height: 29px; }
  .my-wallet .ant-table-body {
    height: 368px !important;
    border-radius: 4px !important; }
  .my-wallet .antd-pro-charts-chart-card-chartCard {
    position: relative;
    padding: 24px !important; }
  .my-wallet .Wallet_Stats_txt {
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 22px;
    color: #000;
    margin-bottom: 8px; }
  .my-wallet .Transaction_History {
    position: relative; }
    .my-wallet .Transaction_History .ant-table-body {
      height: 368px !important;
      border-radius: 4px;
      overflow: visible; }
    .my-wallet .Transaction_History .line {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      width: 10px;
      background: #16273b;
      border-top-right-radius: 4px; }
  .my-wallet .ant-btn-primary {
    color: #fff;
    background-color: #0068ff;
    font-size: 12px;
    margin-left: 8px !important; }
  .my-wallet .Wallet_stats .ant-card {
    height: 176px; }
  .my-wallet .ant-tooltip-inner {
    text-align: center !important; }

.my-wallet .add-balance-btn:hover {
  background-color: #14b50a !important;
  border: 2px solid #229d1b !important;
  color: #fff !important; }

.my-wallet .add-balance-btn:active {
  background-color: #229d1b !important;
  color: #fff !important; }

.tooltip {
  position: relative;
  display: inline-block; }

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000000bf;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 99;
  bottom: 146%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 1s;
  top: -43px;
  display: flex;
  justify-content: center;
  align-items: center; }

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent; }

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; }

.my-wallet-review-modal {
  max-width: 100%; }
  .my-wallet-review-modal .ant-modal-header {
    border-bottom: none;
    padding: 24px 24px 12px 24px; }
  .my-wallet-review-modal .ant-modal-body {
    padding: 0; }
    .my-wallet-review-modal .ant-modal-body table thead th:nth-child(3) {
      text-align: left; }
    .my-wallet-review-modal .ant-modal-body .ant-table-body {
      background: white; }
      .my-wallet-review-modal .ant-modal-body .ant-table-body .products-list img {
        max-width: 50px;
        padding: 0.5rem;
        border: 1px solid #707070; }
      .my-wallet-review-modal .ant-modal-body .ant-table-body .blue-text {
        color: #5e73ff; }

:root {
  --normal-letter-spacing: 0.032em;
  --small-font-size: 12px;
  --medium-font-size: 16px;
  --max-font-size: 24px;
  --small-line-height: 16px;
  --max-line-height: 24px;
  --medium-line-height: 20px; }

.Transactions_head_fs .Transactions-order_place_fs {
  min-height: 55px;
  max-height: 214px;
  overflow: scroll; }

.Transactions_head_fs .modal_headerdata {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 24px;
  align-items: center; }

.Transactions_head_fs .Vforderid_txt {
  padding: 0px 24px; }
  .Transactions_head_fs .Vforderid_txt span {
    padding: 5px;
    padding-left: 8px;
    background: #3385ff;
    color: white;
    border-radius: 2px;
    border-bottom-left-radius: 0;
    font-size: var(--small-font-size);
    font-weight: 500;
    letter-spacing: 0.032em; }

.Transactions_head_fs .Header_name {
  font-size: var(--small-font-size);
  padding: 0px 24px; }

.Transactions_head_fs .Transactions-table_fs {
  width: 100%;
  font-size: 12px;
  transition-duration: 1s;
  overflow-x: scroll;
  padding: 16px 24px;
  display: inline-block; }
  .Transactions_head_fs .Transactions-table_fs th {
    color: #898989;
    font-weight: 400;
    padding: 16px;
    white-space: normal;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .Transactions_head_fs .Transactions-table_fs td {
    padding: 16px 16px 16px 16px;
    color: #212121;
    text-transform: capitalize;
    border: 2px solid #e3e3e3;
    font-size: 16px; }
  .Transactions_head_fs .Transactions-table_fs .table_parrent {
    transform: translate(4%, 0px) !important; }
  .Transactions_head_fs .Transactions-table_fs .table_parrent.tr {
    transform: translate(0%, 0px) !important; }
  .Transactions_head_fs .Transactions-table_fs .pr-details {
    position: relative; }
    .Transactions_head_fs .Transactions-table_fs .pr-details .qty-header {
      position: absolute;
      z-index: 97;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 100%; }

.Transactions_head_fs .transaction_images {
  padding: 8px 8px 8px 16px;
  width: 235px; }
  .Transactions_head_fs .transaction_images span {
    white-space: nowrap;
    display: inline-block; }
  .Transactions_head_fs .transaction_images img {
    width: 24px;
    height: 24px;
    margin-right: 8px; }

.Transactions_head_fs .transaction_images.gb {
  padding: 8px 8px 8px 16px;
  width: 350px; }
  .Transactions_head_fs .transaction_images.gb span {
    white-space: nowrap;
    display: inline-block; }
  .Transactions_head_fs .transaction_images.gb img {
    width: 32px;
    height: 32px;
    margin-right: 8px; }

.Transactions_head_fs .transaction_images {
  position: relative; }
  .Transactions_head_fs .transaction_images .wraper {
    display: flex;
    align-items: center; }
    .Transactions_head_fs .transaction_images .wraper img {
      height: 32px !important;
      width: 32px !important; }
  .Transactions_head_fs .transaction_images .qty {
    position: absolute;
    z-index: 97;
    top: 50%;
    font-size: 16px;
    transform: translateY(-50%);
    right: 0px;
    border-left: 1px solid #b2b2b2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 107%; }

.Transactions_head_fs .transaction_images {
  border-bottom: 1px #b2b2b2 solid; }

.Transactions_head_fs .ant-modal-body table thead th:nth-child(3) {
  text-align: none !important; }

.Transactions_head_fs .Transactions-table_Total {
  width: 100%;
  transition-duration: 1s;
  margin-bottom: 20px;
  overflow-x: scroll;
  padding: 16px 24px;
  display: inline-block;
  margin: -56px 0 0 38px; }
  .Transactions_head_fs .Transactions-table_Total td {
    padding: 16px 16px;
    color: #212121;
    text-transform: capitalize;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.032em;
    line-height: 16px; }

.Transactions_head_fs tr.no-border {
  border: none !important;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.032em;
  line-height: 16px; }

.Transactions_head_fs .no-border td {
  border: none !important;
  background: white !important; }

.Transactions_head_fs .red_text {
  color: #e14643;
  font-weight: 600;
  letter-spacing: var(--normal-letter-spacing);
  line-height: var(--small-line-height); }

.Transactions_head_fs .green_text {
  color: #36c626;
  font-weight: 600;
  letter-spacing: var(--normal-letter-spacing);
  line-height: var(--small-line-height); }

.Transactions_head_fs .shipping-qty {
  display: none; }

.Transactions_head .Transactions-table th:first-child {
  padding-left: 0; }

.Transactions_head .Transactions-table td:first-child {
  background: #f5f5f5; }

.Transactions-order_place_fs tr:last-child .transaction_images {
  border-bottom: none !important; }

.fs-16-m {
  font-size: 16px;
  font-weight: 500; }

.Transactions_head .Vforderid_txt {
  padding: 0px 24px; }
  .Transactions_head .Vforderid_txt span {
    padding: 5px;
    padding-left: 8px;
    background: #3385ff;
    color: white;
    border-radius: 2px;
    border-bottom-left-radius: 0;
    font-size: var(--small-font-size);
    font-weight: 500;
    letter-spacing: var(--normal-letter-spacing);
    display: inline-block;
    margin-right: 8px; }

.Transactions_head .modal_headerdata {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 24px;
  align-items: center; }

.Transactions_head .Header_name {
  font-size: var(--small-font-size);
  padding: 0px 24px; }

.Transactions_head .Transactions-table {
  width: 100%;
  font-size: var(--small-font-size);
  transition-duration: 1s;
  overflow-x: scroll;
  padding: 16px 24px;
  display: inline-block; }
  .Transactions_head .Transactions-table th {
    color: #898989;
    font-weight: 400;
    padding: 16px;
    white-space: normal;
    letter-spacing: 0.032em;
    line-height: var(--small-line-height); }
  .Transactions_head .Transactions-table td {
    padding: 16px 16px;
    color: #212121;
    text-transform: capitalize;
    border: 2px solid #e3e3e3;
    text-align: center;
    font-size: 16px; }
    .Transactions_head .Transactions-table td .wraper {
      font-size: 12px; }

.Transactions_head .transaction_images span {
  white-space: nowrap;
  display: inline-block; }

.Transactions_head .transaction_images.gb {
  width: 318px;
  text-align: start; }

.Transactions_head .transaction_images .wraper {
  display: flex;
  align-items: center; }

.Transactions_head img {
  width: 32px;
  height: 32px;
  margin-right: 6px; }

.Transactions_head .ant-modal-body table thead th:nth-child(3) {
  text-align: none !important; }

.Transactions_head .Transactions-table_Total {
  width: 100%;
  transition-duration: 1s;
  margin-bottom: 20px;
  overflow-x: scroll;
  padding: 16px 24px;
  display: inline-block;
  margin: -56px 0 0 38px; }
  .Transactions_head .Transactions-table_Total td {
    padding: 16px 16px;
    color: #212121;
    text-transform: capitalize;
    width: 200px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.032em;
    line-height: 16px; }

.Transactions_head tr.no-border {
  border: none !important;
  font-size: var(--medium-font-size);
  font-weight: 600;
  letter-spacing: var(--normal-letter-spacing);
  line-height: var(--small-line-height); }

.Transactions_head .no-border td {
  border: none !important;
  background: white !important; }

.Transactions_head .red_text {
  color: #e14643;
  font-weight: 600;
  letter-spacing: var(--normal-letter-spacing);
  line-height: var(--small-line-height); }

.Transactions_head .green_text {
  color: #36c626;
  font-weight: 600;
  letter-spacing: var(--normal-letter-spacing);
  line-height: var(--small-line-height); }

.Transactions_head .Transactions-table th:first-child {
  padding-left: 0; }

.Transactions_head .Transactions-table td:first-child {
  background: #f5f5f5; }

.Transactions_head.gb .Transactions-table td {
  padding: 16px 30px !important; }

.withdrawalModal .ant-modal-header {
  padding: 0px 0px; }
  .withdrawalModal .ant-modal-header .head-background {
    background: url(/static/media/withdrawal-background.144e5ee9.svg);
    padding: 30px 24px;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center; }
    .withdrawalModal .ant-modal-header .head-background h1 {
      font-size: 32px;
      color: white;
      font-weight: bold;
      margin-bottom: 8px; }
    .withdrawalModal .ant-modal-header .head-background p {
      font-size: 16px;
      color: white;
      font-weight: 600;
      margin-bottom: 0px;
      letter-spacing: 1px; }

.withdrawalModal .ant-modal-body {
  padding: 0; }
  .withdrawalModal .ant-modal-body .withdrawal-request p {
    font-size: 20px;
    font-weight: 500;
    color: black; }
  .withdrawalModal .ant-modal-body .balance-exceeded-warning {
    background: #ffeded;
    color: #f40505;
    padding: 20px 70px; }
  .withdrawalModal .ant-modal-body .funds-input {
    display: flex;
    align-items: center;
    justify-content: center; }
    .withdrawalModal .ant-modal-body .funds-input p {
      margin-bottom: 0px;
      font-size: 32px;
      color: black; }
    .withdrawalModal .ant-modal-body .funds-input .ant-input {
      border: none;
      border-bottom: 1px solid black;
      border-radius: 0px;
      width: 200px;
      margin-left: 10px;
      margin-right: 10px;
      height: 41px;
      color: black;
      font-size: 16px; }
    .withdrawalModal .ant-modal-body .funds-input .ant-input:focus {
      box-shadow: unset; }
    .withdrawalModal .ant-modal-body .funds-input span {
      padding: 9px 24px;
      background: #d6d6d6;
      font-size: 16px;
      font-weight: 500;
      color: black; }
  .withdrawalModal .ant-modal-body .funds-amount {
    margin-top: 30px;
    display: flex;
    justify-content: space-between; }
    .withdrawalModal .ant-modal-body .funds-amount span {
      padding: 9px 24px;
      background: #e3eeff;
      font-size: 16px;
      font-weight: 500;
      color: black; }
  .withdrawalModal .ant-modal-body .total-name p {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #00b700; }

.withdrawalModal .ant-modal-footer {
  padding: 0px 0px; }
  .withdrawalModal .ant-modal-footer .ant-btn {
    text-align: center;
    background: #feaa74;
    font-size: 16px;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    height: 46px;
    border-radius: 0px;
    border: none; }

.withdrawalModal button.ant-modal-close {
  color: white; }

.withdrawalSuccessModal .withdrawal-sucess-content h1 {
  font-size: 20px;
  margin-bottom: 0px;
  font-weight: bold;
  margin-top: 10px;
  color: #242424; }

.withdrawalSuccessModal .withdrawal-sucess-content p {
  font-size: 14px;
  color: #242424;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 0px; }

.withdrawalSuccessModal .withdrawal-sucess-content .anticon {
  font-size: 55px; }

.withdrawalSuccessModal .withdrawal-time {
  margin-top: 25px;
  font-size: 14px;
  color: #242424;
  font-style: italic; }

.withdrawalSuccessModal .footer-button {
  margin-top: 25px; }
  .withdrawalSuccessModal .footer-button .ant-btn {
    padding: 0px 16px;
    text-align: center;
    background: #00b50e;
    font-size: 18px;
    color: white;
    font-weight: 600;
    letter-spacing: 1px;
    height: 48px;
    border-radius: 0px;
    border: none; }
    .withdrawalSuccessModal .footer-button .ant-btn .anticon {
      transform: rotate(180deg);
      margin-left: 10px; }

.withdrawalModal .ant-modal-header {
  padding: 0px 0px; }
  .withdrawalModal .ant-modal-header .head-background {
    background: url(/static/media/withdrawal-background.144e5ee9.svg);
    padding: 30px 24px;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center; }
    .withdrawalModal .ant-modal-header .head-background h1 {
      font-size: 32px;
      color: white;
      font-weight: bold;
      margin-bottom: 8px; }
    .withdrawalModal .ant-modal-header .head-background p {
      font-size: 16px;
      color: white;
      font-weight: 600;
      margin-bottom: 0px;
      letter-spacing: 1px; }

.withdrawalModal .ant-modal-body {
  padding: 0; }
  .withdrawalModal .ant-modal-body .withdrawal-request p {
    font-size: 20px;
    font-weight: 500;
    color: black; }
  .withdrawalModal .ant-modal-body .balance-exceeded-warning {
    background: #ffeded;
    color: #f40505;
    padding: 20px 70px; }
  .withdrawalModal .ant-modal-body .funds-input {
    display: flex;
    align-items: center;
    justify-content: center; }
    .withdrawalModal .ant-modal-body .funds-input p {
      margin-bottom: 0px;
      font-size: 32px;
      color: black; }
    .withdrawalModal .ant-modal-body .funds-input .ant-input {
      border: none;
      border-bottom: 1px solid black;
      border-radius: 0px;
      width: 200px;
      margin-left: 10px;
      margin-right: 10px;
      height: 41px;
      color: black;
      font-size: 16px; }
    .withdrawalModal .ant-modal-body .funds-input .ant-input:focus {
      box-shadow: unset; }
    .withdrawalModal .ant-modal-body .funds-input span {
      padding: 9px 24px;
      background: #d6d6d6;
      font-size: 16px;
      font-weight: 500;
      color: black; }
  .withdrawalModal .ant-modal-body .funds-amount {
    margin-top: 30px;
    display: flex;
    justify-content: space-between; }
    .withdrawalModal .ant-modal-body .funds-amount span {
      padding: 9px 24px;
      background: #e3eeff;
      font-size: 16px;
      font-weight: 500;
      color: black; }
  .withdrawalModal .ant-modal-body .total-name p {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #00b700; }

.withdrawalModal .ant-modal-footer {
  padding: 0px 0px; }
  .withdrawalModal .ant-modal-footer .ant-btn {
    text-align: center;
    background: #feaa74;
    font-size: 16px;
    color: white;
    font-weight: bold;
    letter-spacing: 1px;
    height: 46px;
    border-radius: 0px;
    border: none; }

.withdrawalModal button.ant-modal-close {
  color: white; }

.deductedFromWallet-summary {
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;
  background-color: #fff;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .deductedFromWallet-summary .not-found-wraper {
    margin-bottom: 0px !important; }
  .deductedFromWallet-summary .topPerformingProductHead {
    height: 48px;
    background-color: #f0f2f659;
    padding: 16px 24px;
    font-size: 12px;
    color: #898989;
    position: relative; }
    .deductedFromWallet-summary .topPerformingProductHead .name {
      margin-left: 0px !important; }
    .deductedFromWallet-summary .topPerformingProductHead span {
      font-size: 12px;
      color: #898989;
      margin-left: 4px; }
    .deductedFromWallet-summary .topPerformingProductHead span:first-of-type {
      color: #212121;
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px; }
  .deductedFromWallet-summary .product-heads {
    height: 24px;
    padding: 0 24px;
    background-color: #f0f2f659;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .deductedFromWallet-summary .product-heads div {
      font-size: 12px;
      font-weight: 500;
      color: #898989;
      display: flex;
      align-items: center;
      justify-content: center; }
    .deductedFromWallet-summary .product-heads .name-head {
      justify-content: flex-start;
      width: 260px; }
    .deductedFromWallet-summary .product-heads .delivered-order-head {
      width: 180px;
      justify-content: flex-end;
      padding-right: 8px; }
  .deductedFromWallet-summary .product-row-wraper {
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px; }
    .deductedFromWallet-summary .product-row-wraper .product-row {
      height: 40px;
      padding-left: 8px;
      padding-right: 8px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .deductedFromWallet-summary .product-row-wraper .product-row > div {
        font-size: 12px;
        letter-spacing: 0.032em;
        color: #898989;
        display: flex;
        align-items: center;
        justify-content: center; }
      .deductedFromWallet-summary .product-row-wraper .product-row .name-head {
        justify-content: flex-start;
        width: 256px;
        color: #212121; }
      .deductedFromWallet-summary .product-row-wraper .product-row .name-head.total {
        font-weight: 500; }
      .deductedFromWallet-summary .product-row-wraper .product-row .delivered-order-head {
        width: 180px;
        justify-content: flex-end;
        font-weight: 500; }
      .deductedFromWallet-summary .product-row-wraper .product-row .delivered-order-head.debit {
        color: #c70c3c; }
      .deductedFromWallet-summary .product-row-wraper .product-row .delivered-order-head.credit {
        color: #229d1b; }
      .deductedFromWallet-summary .product-row-wraper .product-row .delivered-order-head.yellow {
        color: #ff9800; }
    .deductedFromWallet-summary .product-row-wraper .product-row:nth-child(2n) {
      background-color: #f5f5f5; }

.wallet-container {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  margin-top: 60px; }
  .wallet-container > div {
    width: 48.8%; }

.redirect-kyc .ant-modal-content {
  border-radius: 2px;
  padding: 24px 24px 0 24px; }
  .redirect-kyc .ant-modal-content .ant-modal-header {
    padding: 0; }
    .redirect-kyc .ant-modal-content .ant-modal-header .icon-container {
      position: relative;
      top: 7px;
      margin-right: 6px; }
    .redirect-kyc .ant-modal-content .ant-modal-header .ant-modal-title {
      padding: 0 0 8px; }
      .redirect-kyc .ant-modal-content .ant-modal-header .ant-modal-title .heading-main {
        font-size: 16px;
        font-weight: 500; }
  .redirect-kyc .ant-modal-content .ant-modal-body {
    padding: 16px 0; }
    .redirect-kyc .ant-modal-content .ant-modal-body .steps-kyc-modal {
      font-size: 12px;
      color: #898989; }
      .redirect-kyc .ant-modal-content .ant-modal-body .steps-kyc-modal .upperheading {
        color: #898989;
        margin-bottom: 24px; }
      .redirect-kyc .ant-modal-content .ant-modal-body .steps-kyc-modal .sub-heading {
        margin-bottom: 5px; }
      .redirect-kyc .ant-modal-content .ant-modal-body .steps-kyc-modal .step {
        font-weight: 600; }
      .redirect-kyc .ant-modal-content .ant-modal-body .steps-kyc-modal .footer-info {
        margin-top: 5px; }
  .redirect-kyc .ant-modal-content .ant-modal-footer {
    padding: 16px 0; }
    .redirect-kyc .ant-modal-content .ant-modal-footer .clickable-green {
      background-color: #2bc822;
      color: #ffffff;
      border-radius: 2px !important; }
    .redirect-kyc .ant-modal-content .ant-modal-footer .clickable-green:hover {
      border: 1px solid #2bc822; }

.wallet-transactions {
  margin-top: -32px; }
  .wallet-transactions .ant-cascader-picker {
    width: 300px; }
  .wallet-transactions .ant-cascader-picker-label {
    text-align: left; }
  .wallet-transactions button.ant-btn.download-transaction-btn {
    background: #36c626;
    border-color: #36c626;
    color: white; }
  .wallet-transactions .transaction-rangepicker {
    display: flex;
    align-items: center; }
    .wallet-transactions .transaction-rangepicker p {
      margin-bottom: 0px;
      margin-right: 10px; }
  .wallet-transactions .transaction-filter {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .wallet-transactions .transaction-filter .ant-select {
      width: 178px; }
    .wallet-transactions .transaction-filter p {
      margin-bottom: 0px;
      margin-right: 10px; }
  .wallet-transactions .green-text {
    color: #229d1b;
    font-weight: 500; }
  .wallet-transactions .red-text {
    color: #c70c3c;
    font-weight: 500; }
  .wallet-transactions .orange-text {
    color: #ff9800;
    font-weight: 500; }
  .wallet-transactions .blue-text {
    color: #007fff; }
  .wallet-transactions .ant-btn-primary {
    color: #fff;
    background-color: #0068ff;
    font-size: 12px;
    margin-left: 8px !important; }
  .wallet-transactions .ant-table-thead > tr > th .ant-table-header-column {
    display: inline-block;
    vertical-align: top;
    width: 109px; }
  .wallet-transactions .ant-table-thead > tr > th:nth-child(4) .ant-table-header-column {
    width: 175px !important; }
  .wallet-transactions .ant-table-thead > tr > th:nth-child(7) .ant-table-header-column {
    width: 70px !important; }
  .wallet-transactions .ant-table-thead > tr > th:nth-child(6) .ant-table-header-column {
    width: 70px !important; }
  .wallet-transactions .ant-table-thead > tr > th:nth-child(8) .ant-table-header-column {
    width: 70px !important; }
  .wallet-transactions .ant-table-thead > tr > th:nth-child(9) .ant-table-header-column {
    width: 70px !important; }
  .wallet-transactions .ant-table-layout-fixed table {
    table-layout: unset !important; }
  .wallet-transactions .content-page-text {
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 32px;
    margin-bottom: -32px;
    margin-top: 24px;
    color: #000000; }
  .wallet-transactions .Transaction_History {
    position: relative; }
    .wallet-transactions .Transaction_History .ant-table-body {
      max-height: 368px !important;
      border-radius: 4px;
      overflow: visible; }
    .wallet-transactions .Transaction_History .line {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      width: 10px;
      background: #16273b;
      border-top-right-radius: 4px; }
  .wallet-transactions .transaction-history-length {
    position: relative; }
    .wallet-transactions .transaction-history-length .ant-table-body {
      height: 368px !important;
      border-radius: 4px;
      overflow: visible; }
  .wallet-transactions .ant-card-body {
    padding: 0px !important; }
  .wallet-transactions .ant-table-pagination.ant-pagination {
    float: right;
    margin: 16px 16px !important; }
  .wallet-transactions .ant-table-tbody > tr > td {
    border: none !important;
    color: #595959;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .wallet-transactions .ant-empty-normal {
    margin: 32px 0;
    color: rgba(0, 0, 0, 0.25);
    position: absolute !important;
    top: -205px !important;
    left: 44% !important; }
  .wallet-transactions .gst-deductions-on-deliver .ant-tooltip-inner {
    text-align: center !important;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-size: 12px; }

.tooltip {
  position: relative;
  display: inline-block; }

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000000bf;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 99;
  bottom: 146%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 1s;
  top: -43px;
  display: flex;
  justify-content: center;
  align-items: center; }

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000bf transparent transparent transparent; }

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; }

.wallet-tabs .ant-pagination .ant-pagination-item {
  min-width: -webkit-min-content !important;
  min-width: min-content !important; }

.new-storage-charges .heads {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .new-storage-charges .heads > div {
    flex: 1 1;
    color: #898989;
    font-weight: 400;
    font-size: 12px;
    padding: 16px;
    white-space: normal;
    letter-spacing: 0.032em;
    line-height: 16px; }

.new-storage-charges .heads.values {
  border: 1px solid #c0c0c0;
  border-right: 0px; }
  .new-storage-charges .heads.values > div {
    border-right: 2px solid #c0c0c0;
    padding: 16px 16px 16px 16px;
    color: #212121;
    text-transform: capitalize;
    font-size: 16px; }

.new-ref-cell {
  margin-top: -15px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.bn {
  justify-content: end !important; }
  .bn .mg {
    margin-right: 30px !important; }

.modal-data-transtion .delail-CONTAINER {
  font-size: 12px;
  color: #212121;
  text-transform: capitalize; }
  .modal-data-transtion .delail-CONTAINER span {
    display: inline-block;
    margin-left: 14px; }

.modal-data-transtion .id-cointainer {
  display: flex;
  justify-content: space-between; }
  .modal-data-transtion .id-cointainer .transtionid {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    margin-right: 5px; }

.modal-data-transtion .table-heading {
  color: #898989;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-size: 12px; }

.modal-data-transtion .footer-table {
  display: flex;
  justify-content: space-between; }
  .modal-data-transtion .footer-table .item {
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #212121;
    text-transform: capitalize; }
  .modal-data-transtion .footer-table .total {
    font-weight: 600;
    margin-right: 30px;
    color: #e14643;
    font-weight: 600; }

.modal-data-transtion .data {
  color: #212121;
  font-size: 16px;
  font-weight: 500; }

.modal-data-transtion .divider {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 10px; }

.modal-data-transtion .top-divider {
  border-top: 10px; }

.modal-data-transtion .amount {
  font-weight: 600;
  color: #e14643; }

.modal-data-transtion .ant-table-thead > tr > th {
  background: white;
  border: none !important; }

.modal-data-transtion .ant-table-thead > tr > th:first-of-type {
  background: white;
  border: none !important;
  padding-left: 0px; }

.modal-data-transtion .ant-table-thead > tr > th, .modal-data-transtion .ant-table-tbody > tr > td {
  padding: 8px 8px;
  overflow-wrap: break-word;
  border: 2px solid #e3e3e3; }

.modal-data-transtion .ant-table table {
  border-collapse: collapse; }

.modal-data-transtion .ant-table-tbody > tr > td:first-of-type {
  background-color: #f5f5f5; }

.modal-data-transtion .heading-modal {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  padding: 5px;
  padding-left: 0px; }

.modal-data-transtion .heading-vfd {
  padding: 5px;
  padding-left: 8px;
  background: #3385ff;
  color: white;
  border-radius: 2px;
  font-size: var(--small-font-size);
  font-weight: 500;
  letter-spacing: 0.032em;
  position: relative;
  top: -7px; }

.modal-data-transtion .footer-cointainer {
  margin-top: 10px;
  text-align: right; }

.modal-data-transtion .sku-img {
  height: 32px !important;
  width: 32px !important; }

.walletsummarynav .walletnav {
  padding-left: 59px;
  padding-top: 104px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 121;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .walletsummarynav .walletnav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .walletsummarynav .walletnav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .walletsummarynav .walletnav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .walletsummarynav .walletnav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .walletsummarynav .walletnav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .walletsummarynav .walletnav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .walletsummarynav .walletnav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .walletsummarynav .walletnav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .walletsummarynav .walletnav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .walletsummarynav .walletnav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .walletsummarynav .walletnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .walletsummarynav .walletnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .walletsummarynav .walletnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .walletsummarynav .walletnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .walletsummarynav .walletnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .walletsummarynav .walletnav .secondwrap .filtertab .hover-transactions-underline-animation {
        position: relative; }
      .walletsummarynav .walletnav .secondwrap .filtertab .hover-transactions-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .walletsummarynav .walletnav .secondwrap .filtertab .hover-transactions-underline-animation:hover {
        color: #4398ed !important; }
        .walletsummarynav .walletnav .secondwrap .filtertab .hover-transactions-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .walletsummarynav .walletnav .secondwrap .filtertab .hover-transactions-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .walletsummarynav .walletnav .secondwrap .filtertab .hover-transactions-underline-animation-click {
        position: relative; }
      .walletsummarynav .walletnav .secondwrap .filtertab .hover-transactions-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .walletsummarynav .walletnav .secondwrap .download_csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .walletsummarynav .walletnav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .walletsummarynav .walletnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .walletsummarynav .walletnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .walletsummarynav .walletnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .walletsummarynav .walletnav .ant-calendar-range-picker-input {
    width: 36% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .walletsummarynav .walletnav .ant-calendar-picker-clear,
  .walletsummarynav .walletnav .ant-calendar-picker-icon {
    top: 50%;
    right: 46px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.walletsummarynav .bottom_data {
  display: flex;
  justify-content: space-between;
  padding-top: 104px; }

.walletsummarynav .ant-select-selection__placeholder {
  color: #1268fb; }

.walletsummarynav .ant-cascader-picker {
  width: 368px !important;
  border-radius: 2px !important; }

.walletsummarynav .ant-input {
  border-radius: 0px !important;
  font-size: 12px !important;
  border: none; }

.walletsummarynav .ant-cascader-menu-item {
  padding: 8px 16px !important;
  color: #898989; }

.walletsummarynav .ant-cascader-menu-item {
  padding: 8px 16px !important;
  border-bottom: 1px solid #e6f4ff !important;
  color: #898989; }

.walletsummarynav .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.walletsummarynav .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background: #e6f4ff !important;
  background: #e6f4ff;
  color: #000;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 24px;
  padding: 8px 16px !important; }

.walletsummarynav .ant-cascader-menu-item:hover {
  background: #e6f4ff;
  background: #e6f4ff;
  color: #000;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 24px;
  padding: 8px 16px !important; }

.walletsummarynav .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.walletsummarynav .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
  right: 2px !important; }

.walletsummarynav .ant-cascader-menu {
  width: 155px; }

.walletsummarynav .transaction-head-btn-wraper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 12px; }

.ant-cascader-menu:nth-of-type(2) {
  width: 266px; }

@media screen and (min-width: 1440px) {
  .walletnav {
    padding-left: 104px !important;
    padding-top: 104px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; }
  .ant-cascader-menu {
    width: 155px; }
  .ant-cascader-menu-item {
    padding: 8px 16px !important;
    border-bottom: 1px solid #e6f4ff !important;
    font-size: 12px;
    color: #898989; }
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
  .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
    font-weight: 600;
    background: #e6f4ff !important;
    background: #e6f4ff;
    color: #000;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding: 8px 16px !important; }
  .ant-cascader-menu-item:hover {
    background: #e6f4ff;
    background: #e6f4ff;
    color: #000;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding: 8px 16px !important; }
  .ant-select-selection__placeholder {
    color: #898989; }
  .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
  .ant-cascader-menu-item-expand .ant-cascader-menu-item-loading-icon {
    right: 2px !important; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .walletnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.walletsummarynav .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.walletsummarynav .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.multi-transaction-head-filter {
  letter-spacing: 0.032em !important;
  margin-top: 12px; }
  .multi-transaction-head-filter > p {
    margin-bottom: 0.5em;
    font-size: 16px;
    color: #212121;
    font-weight: 500; }
  .multi-transaction-head-filter .wraper {
    display: flex;
    align-items: start;
    justify-content: space-between; }
  .multi-transaction-head-filter .multiple-search {
    width: 55.6%;
    position: relative; }
    .multi-transaction-head-filter .multiple-search .transactions-select {
      width: 100%;
      height: 32px;
      overflow: hidden;
      background-color: #fff;
      border: none;
      box-shadow: 0px 2px 8px #00000014; }
      .multi-transaction-head-filter .multiple-search .transactions-select .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-checked {
        margin-right: 0px;
        opacity: 0;
        font-size: 0px; }
        .multi-transaction-head-filter .multiple-search .transactions-select .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-checked input {
          font-size: 0px; }
        .multi-transaction-head-filter .multiple-search .transactions-select .ant-checkbox-wrapper.ant-checkbox-wrapper-checked .ant-checkbox-checked span {
          height: 0px;
          width: 0px; }
      .multi-transaction-head-filter .multiple-search .transactions-select .ant-select-selection__rendered {
        margin-right: 10px; }
        .multi-transaction-head-filter .multiple-search .transactions-select .ant-select-selection__rendered .ant-select-selection__choice__content {
          width: 122px;
          font-size: 12px !important; }
        .multi-transaction-head-filter .multiple-search .transactions-select .ant-select-selection__rendered .ant-select-selection__choice__disabled {
          width: 55px; }
        .multi-transaction-head-filter .multiple-search .transactions-select .ant-select-selection__rendered .ant-select-selection__placeholder {
          color: #00000040 !important; }
      .multi-transaction-head-filter .multiple-search .transactions-select .ant-select-selection__choice {
        padding-left: 6px; }
      .multi-transaction-head-filter .multiple-search .transactions-select .ant-select-selection {
        border: none;
        border-radius: 2px;
        background-color: #fff; }
    .multi-transaction-head-filter .multiple-search .arrow-icon {
      font-size: 16px;
      position: absolute;
      top: 20%;
      right: 8px;
      z-index: 990;
      transform: rotate(0deg);
      transition: 0.5s all; }
    .multi-transaction-head-filter .multiple-search .rotate {
      transform: rotate(180deg);
      transition: 0.5s all; }
      .multi-transaction-head-filter .multiple-search .rotate g {
        fill: gray; }

.transaction-dropdown {
  letter-spacing: 0.032em !important;
  background-color: #fff !important;
  padding: 16px 12px;
  border-radius: 2px;
  width: 992px !important; }
  .transaction-dropdown .ant-checkbox {
    margin-right: 8px; }
  .transaction-dropdown > div ul ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px; }
  .transaction-dropdown ul {
    display: flex;
    flex-wrap: wrap;
    max-height: 232px !important; }
    .transaction-dropdown ul li {
      width: 236px;
      padding-right: 6px !important;
      font-size: 12px !important;
      color: #898989;
      border: 1px solid #f5f5f5;
      border-radius: 0px !important; }
      .transaction-dropdown ul li .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #36c626 !important;
        border: 1px solid #36c626; }
      .transaction-dropdown ul li .anticon.anticon-check.ant-select-selected-icon {
        display: none; }
    .transaction-dropdown ul li:first-of-type {
      width: 100%;
      border: none;
      color: #0062ff;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      cursor: pointer;
      font-weight: 500; }
      .transaction-dropdown ul li:first-of-type span {
        cursor: pointer; }
      .transaction-dropdown ul li:first-of-type span:hover {
        text-decoration: underline; }
      .transaction-dropdown ul li:first-of-type .active.red-e {
        color: #ff005e !important; }
    .transaction-dropdown ul li:first-of-type:hover {
      background-color: #fff;
      cursor: auto;
      box-shadow: none; }
    .transaction-dropdown ul li:first-of-type:focus {
      background-color: #fff;
      cursor: auto;
      box-shadow: none; }
    .transaction-dropdown ul li:first-of-type:active {
      background-color: #fff;
      cursor: auto;
      box-shadow: none; }
    .transaction-dropdown ul li.ant-select-dropdown-menu-item-selected {
      color: #212121;
      font-weight: 400; }
    .transaction-dropdown ul li {
      border: none;
      border-top: 1px solid #cecece;
      border-left: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(5) {
      border-right: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(9) {
      border-right: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(13) {
      border-right: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(17) {
      border-right: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(21) {
      border-right: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(25) {
      border-right: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(29) {
      border-right: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(33) {
      border-right: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(37) {
      border-right: 1px solid #cecece;
      border-bottom: 0px solid #cecece !important; }
    .transaction-dropdown ul li:nth-of-type(38) {
      border-bottom: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(39) {
      border-bottom: 1px solid #cecece; }
    .transaction-dropdown ul li:nth-of-type(40) {
      border-bottom: 1px solid #cecece !important; }
    .transaction-dropdown ul li:nth-of-type(41) {
      border-bottom: 1px solid #cecece !important;
      border-right: 1px solid #cecece; }
    .transaction-dropdown ul .ant-select-dropdown-menu-item-active:first-of-type {
      background-color: #fff; }
    .transaction-dropdown ul li:hover {
      border-bottom-color: #b5dafe; }
      .transaction-dropdown ul li:hover .anticon.anticon-check.ant-select-selected-icon {
        display: none; }
      .transaction-dropdown ul li:hover .ant-checkbox-inner {
        border-color: #36c626 !important; }
    .transaction-dropdown ul ::-webkit-scrollbar {
      width: 8px;
      border: 1px solid #898989; }
    .transaction-dropdown ul ::\--webkit-scrollbar-thumb {
      background-color: #898989; }
  .transaction-dropdown ul::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px; }
  .transaction-dropdown ul::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
  .transaction-dropdown ul::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #898989; }

.Transaction-heads-modal {
  width: 644px !important;
  letter-spacing: 0.01em;
  top: 70px; }
  .Transaction-heads-modal .search-input-transactions {
    height: 40px !important;
    box-shadow: 0 3px 10px #0000000d;
    border-radius: 0px;
    background-color: #fff;
    border: 1px solid #e3e3e3 !important;
    position: relative;
    top: -6px;
    width: 532px;
    z-index: 99999;
    transition: all 0.2s ease-in-out !important; }
  .Transaction-heads-modal .ant-modal-content {
    border-radius: 2px; }
  .Transaction-heads-modal .ant-modal-close-x svg {
    fill: #fff; }
  .Transaction-heads-modal .ant-modal-header {
    background-color: #192f4a; }
    .Transaction-heads-modal .ant-modal-header > div {
      color: #fff; }
  .Transaction-heads-modal .ant-modal-header {
    background-color: #fff;
    padding: 0px;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 24px;
    padding-bottom: 8px;
    border-color: #cecece;
    margin-bottom: 12px; }
    .Transaction-heads-modal .ant-modal-header h2 {
      margin: 0px;
      font-size: 16px;
      color: #000000d9;
      font-weight: 500; }
    .Transaction-heads-modal .ant-modal-header p {
      margin: 0px;
      font-size: 12px;
      color: #898989;
      padding-bottom: 16px; }
  .Transaction-heads-modal .ant-modal-body {
    height: 500px;
    padding-top: 0px;
    overflow: scroll;
    padding-bottom: 12px;
    overflow-x: hidden !important;
    margin-right: 1px; }
    .Transaction-heads-modal .ant-modal-body .wraper {
      border-bottom: 1px solid #b2b2b2; }
      .Transaction-heads-modal .ant-modal-body .wraper .select-all-wraper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        margin-top: 24px; }
        .Transaction-heads-modal .ant-modal-body .wraper .select-all-wraper h1 {
          font-size: 14px;
          margin: 0;
          padding: 0; }
        .Transaction-heads-modal .ant-modal-body .wraper .select-all-wraper .ant-checkbox {
          display: none; }
        .Transaction-heads-modal .ant-modal-body .wraper .select-all-wraper span {
          color: #0062ff;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          cursor: pointer;
          font-weight: 500; }
        .Transaction-heads-modal .ant-modal-body .wraper .select-all-wraper .ant-checkbox-wrapper-checked span {
          color: red !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          cursor: pointer;
          font-weight: 500; }
        .Transaction-heads-modal .ant-modal-body .wraper .select-all-wraper span:hover {
          text-decoration: underline; }
      .Transaction-heads-modal .ant-modal-body .wraper .select-all-wraper:first-of-type {
        margin-top: 24px; }
      .Transaction-heads-modal .ant-modal-body .wraper .ant-checkbox-wrapper-checked .ant-checkbox-inner {
        background-color: #36c626;
        border-color: #36c626; }
      .Transaction-heads-modal .ant-modal-body .wraper .single-select-wraper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 24px; }
        .Transaction-heads-modal .ant-modal-body .wraper .single-select-wraper .ant-checkbox-wrapper {
          margin-left: 0px;
          border: none;
          width: 48%;
          padding: 8px;
          margin-bottom: 12px;
          font-size: 12px;
          background-color: #f5f5f5;
          color: #212121; }
        .Transaction-heads-modal .ant-modal-body .wraper .single-select-wraper .ant-checkbox-wrapper:hover .ant-checkbox-inner {
          border-color: #36c626; }
    .Transaction-heads-modal .ant-modal-body .wraper:first-of-type .select-all-wraper {
      margin-top: 12px; }
    .Transaction-heads-modal .ant-modal-body .wraper:last-of-type {
      border-bottom: none; }
      .Transaction-heads-modal .ant-modal-body .wraper:last-of-type .single-select-wraper {
        margin-bottom: 0px; }
  .Transaction-heads-modal .not-found {
    display: flex;
    align-items: center;
    justify-content: center; }
  .Transaction-heads-modal .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fff;
    padding: 0px;
    margin-left: 24px;
    margin-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-color: #cecece; }
    .Transaction-heads-modal .ant-modal-footer .ant-btn.ant-btn-cancel {
      border-radius: 2px; }
  .Transaction-heads-modal .ant-modal-body::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px; }
  .Transaction-heads-modal .ant-modal-body::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
  .Transaction-heads-modal .ant-modal-body::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #898989; }

.user-card .ant-tabs .ant-tabs-ink-bar {
  background-color: black; }

.user-card .ant-tabs .ant-tabs-nav .ant-tabs-tab-active {
  color: black; }

.user-card .ant-tabs .ant-tabs-nav .ant-tabs-tab:hover {
  color: black; }

.user-card .user-settings .heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
  .user-card .user-settings .heading .text {
    font-size: 1.43rem;
    font-weight: 400;
    color: #151515; }
  .user-card .user-settings .heading .add-user-btn {
    background: #ff5858;
    border-color: #ff5858;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 3px;
    height: 2.5rem; }
    .user-card .user-settings .heading .add-user-btn .anticon + span {
      margin-left: 0.25rem; }
  .user-card .user-settings .heading .add-user-btn:hover,
  .user-card .user-settings .heading .add-user-btn:active,
  .user-card .user-settings .heading .add-user-btn:focus,
  .user-card .user-settings .heading .add-user-btn:focus-within {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25); }

.user-card .user-settings .ant-divider {
  background: #979797;
  height: 2px; }

.user-card .user-settings .disclaimer {
  font-size: 16px;
  color: #979797; }

.user-card .user-settings .ant-table {
  border-radius: 4px; }
  .user-card .user-settings .ant-table .user-name {
    text-transform: capitalize;
    margin-bottom: 0;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 24px;
    font-weight: 400; }
  .user-card .user-settings .ant-table .role-tag {
    border-radius: 9px;
    font-size: 0.86rem;
    font-weight: 500;
    padding: 0 1rem; }
  .user-card .user-settings .ant-table .ant-table-thead > tr > th {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .user-card .user-settings .ant-table .ant-table-tbody > tr > td {
    color: #212121;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px; }
    .user-card .user-settings .ant-table .ant-table-tbody > tr > td p {
      margin-bottom: 0px; }
    .user-card .user-settings .ant-table .ant-table-tbody > tr > td .actions-div {
      font-size: 12px; }
      .user-card .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-btn {
        height: 28px;
        width: 28px;
        font-size: 12px;
        padding: 0px;
        margin-right: 16px;
        font-weight: 500;
        border: none;
        box-shadow: none;
        background: none; }
      .user-card .user-settings .ant-table .ant-table-tbody > tr > td .actions-div .ant-switch-checked {
        background-color: #229d1b; }

.user-card .ant-card-body {
  padding: 0px;
  zoom: 1;
  margin-bottom: 32px; }

.user-card .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px !important; }

.user-card .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 4px !important; }

.user-card .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 4px !important; }

.user-card .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 4px !important; }

.user-card .ant-table-tbody > tr > td :last-child {
  border-bottom: 0px !important; }

.user-card .ant-table-tbody > tr > td {
  border-bottom: 0px !important; }

.user-card .ant-table-tbody > tr {
  border-bottom: 1px solid #cecece !important; }

.user-card .ant-table-tbody > tr:last-child {
  border-bottom: 0px !important; }

.user-card .review-role {
  background: none;
  border: none;
  cursor: pointer; }

.ant-card {
  border-radius: 2px !important; }

.user-modal {
  z-index: 99; }
  .user-modal .ant-modal-content {
    border-radius: 4px;
    border: 1px solid #707070;
    box-shadow: none;
    padding: 1rem; }
    .user-modal .ant-modal-content .ant-modal-close {
      display: none; }
    .user-modal .ant-modal-content .ant-modal-header {
      border: none;
      padding: 8px 16px; }
      .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom {
        font-size: 16px;
        color: #020429;
        display: flex;
        justify-content: space-between;
        align-items: center;
        letter-spacing: 0.032em;
        line-height: 16px; }
        .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg {
          width: 1.71rem;
          height: 1.71rem;
          cursor: pointer; }
          .user-modal .ant-modal-content .ant-modal-header .ant-modal-title.custom svg * {
            color: rgba(0, 0, 0, 0.65); }
    .user-modal .ant-modal-content .ant-modal-body {
      padding: 16px; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form label {
        font-size: 12px; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item-label > label {
        color: #464646;
        line-height: 16px !important; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .label {
        font-size: 12px;
        color: #464646;
        font-weight: 400;
        margin-bottom: 2px;
        letter-spacing: 0.032em; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-input {
        border-radius: 0px;
        border: 0px solid rgba(0, 0, 0, 0.15);
        background: #f5f5f5; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select {
        width: 100%; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form .ant-input-group .ant-select-dropdown-menu-item {
        border-bottom: 2px solid; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form .form-footer {
        text-align: center; }
        .user-modal .ant-modal-content .ant-modal-body .ant-form .form-footer a {
          color: #010101;
          text-decoration: underline !important; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button {
        width: 100%;
        background: #1bca00;
        color: #fff !important;
        border: none !important;
        outline: none !important;
        font-size: 16px;
        font-weight: 500;
        border-radius: 2px;
        margin-top: 16px; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:hover,
      .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:active,
      .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus,
      .user-modal .ant-modal-content .ant-modal-body .ant-form .submit-button:focus-within {
        background: #1bca00; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form .anticon {
        vertical-align: -0.2em; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form-item {
        margin-bottom: 8px !important; }
      .user-modal .ant-modal-content .ant-modal-body .ant-input::placeholder {
        font-size: 12px !important; }
      .user-modal .ant-modal-content .ant-modal-body .ant-form-item-label {
        color: #464646;
        line-height: 16px !important;
        letter-spacing: 0.032em; }
      .user-modal .ant-modal-content .ant-modal-body .ant-select-selection {
        background-color: #f5f5f5;
        border: 0px solid #d9d9d9;
        border-top-width: 0px;
        border-radius: 0px; }

.option-span {
  margin-bottom: 0; }

.role-select {
  font-size: 10px !important;
  font-style: italic !important;
  color: #d6d6d6 !important;
  margin-left: 20px !important; }

.role-permissions-data .title {
  font-size: 12px;
  color: #595959;
  font-weight: 500;
  margin-bottom: 0.5rem; }

.role-permissions-data p {
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center; }
  .role-permissions-data p svg {
    width: 0.93rem;
    height: 0.93rem;
    margin-right: 0.5rem; }

.permissionDrawer {
  z-index: 99999; }
  .permissionDrawer .ant-drawer-header {
    background: #192f4a;
    border-radius: 0px;
    width: 1000px;
    padding: 16px 40px;
    position: fixed;
    z-index: 99;
    height: 56px; }
    .permissionDrawer .ant-drawer-header .modalTitle {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .permissionDrawer .ant-drawer-header .modalTitle .add-new-role-txt {
        margin-bottom: 0px;
        color: white;
        font-weight: 500;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.032em; }
      .permissionDrawer .ant-drawer-header .modalTitle p {
        margin-bottom: 0px;
        color: white;
        font-size: 16px; }
      .permissionDrawer .ant-drawer-header .modalTitle .anticon {
        color: white; }
  .permissionDrawer .ant-drawer-body {
    margin-top: 56px;
    padding: 0px 40px;
    background: #fcfcfc; }
    .permissionDrawer .ant-drawer-body .main-container .permission-container {
      padding-top: 8px;
      border-bottom: 1px solid #b2b2b2; }
      .permissionDrawer .ant-drawer-body .main-container .permission-container .checkbox-title {
        font-size: 12px;
        font-weight: 500;
        color: #212121;
        margin-bottom: 5px; }
        .permissionDrawer .ant-drawer-body .main-container .permission-container .checkbox-title span {
          font-size: 10px;
          font-weight: 400;
          float: right;
          cursor: pointer; }
      .permissionDrawer .ant-drawer-body .main-container .permission-container .permission-option {
        display: flex;
        flex-wrap: wrap; }
        .permissionDrawer .ant-drawer-body .main-container .permission-container .permission-option p {
          font-size: 16px;
          color: #585858;
          font-weight: 400;
          margin-right: 20px; }
        .permissionDrawer .ant-drawer-body .main-container .permission-container .permission-option .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0px; }
        .permissionDrawer .ant-drawer-body .main-container .permission-container .permission-option .ant-checkbox-wrapper {
          margin-bottom: 16px;
          margin-right: 16px; }
      .permissionDrawer .ant-drawer-body .main-container .permission-container .snapshot-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        margin-top: 4px; }
        .permissionDrawer .ant-drawer-body .main-container .permission-container .snapshot-select .select {
          color: #0062ff;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          cursor: pointer;
          font-weight: 500; }
        .permissionDrawer .ant-drawer-body .main-container .permission-container .snapshot-select .deselect {
          color: #ff005e;
          font-size: 12px;
          letter-spacing: 0.032em;
          line-height: 16px;
          cursor: pointer;
          font-weight: 500; }
  .permissionDrawer .drawer-footer {
    margin: 0px -40px;
    padding: 20px 40px;
    background: white; }
    .permissionDrawer .drawer-footer .ant-btn {
      background: #1bca00;
      color: #fff !important;
      border: none !important;
      outline: none !important;
      font-size: 14px;
      font-weight: 500;
      border-radius: 2px;
      height: 40px;
      width: 140px;
      letter-spacing: 0.032em; }
    .permissionDrawer .drawer-footer a {
      color: #212121;
      font-size: 16px;
      font-weight: 400;
      margin-left: 24px;
      letter-spacing: 0.032em;
      line-height: 20px; }
  .permissionDrawer .ant-checkbox-wrapper + span,
  .permissionDrawer .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    font-weight: 400; }
  .permissionDrawer .all-permissions-continue {
    margin-left: 16px;
    background: none;
    border: none;
    cursor: pointer;
    color: #0068ff;
    letter-spacing: 0.032em;
    font-size: 14px;
    font-weight: 500; }
  .permissionDrawer .all-permissions-continue:hover {
    margin-left: 16px;
    background: none;
    border: none;
    cursor: pointer;
    color: #0068ff;
    letter-spacing: 0.032em;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline; }
  .permissionDrawer .select:hover {
    text-decoration: underline; }
  .permissionDrawer .deselect:hover {
    text-decoration: underline; }
  .permissionDrawer .drawer-footer .ant-btn:hover {
    background: #28af21;
    border: 2px solid #229d1b !important; }
  .permissionDrawer .arrow-right {
    transform: rotate(270deg); }
    .permissionDrawer .arrow-right g {
      stroke: white;
      fill: white; }
      .permissionDrawer .arrow-right g path {
        fill: white; }

.permission-modal-checkbox {
  z-index: 99999; }
  .permission-modal-checkbox .modal-content {
    display: flex;
    align-items: center;
    justify-content: center; }
    .permission-modal-checkbox .modal-content img {
      height: 48px;
      margin-right: 12px; }

.role-modal .ant-modal-body {
  padding: 24px; }

.role-modal .ant-modal-close {
  display: none; }

.role-modal .ant-checkbox {
  border: 2px solid #e3e3e3 !important;
  border-radius: 2px !important; }

.role-modal .role-container .role-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #b2b2b2;
  margin-bottom: 16px; }
  .role-modal .role-container .role-header .anticon-close {
    margin-bottom: 16px; }

.role-modal .role-container .role-body {
  border-bottom: 1px solid #b2b2b2;
  margin-bottom: 40px; }
  .role-modal .role-container .role-body p {
    font-size: 12px;
    color: #212121;
    margin-bottom: 4px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .role-modal .role-container .role-body .ant-btn {
    background: #1bca00;
    color: #fff !important;
    border: none !important;
    outline: none !important;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    height: 50px;
    width: 100%;
    letter-spacing: 0.5px;
    margin-top: 16px; }

.role-modal .save-role {
  font-size: 16px;
  letter-spacing: 0.032em;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 16px;
  color: #212121; }

.role-modal .role-input {
  background: #f5f5f5 !important;
  border-radius: 2px !important;
  height: 40px !important;
  border: none !important;
  margin-bottom: 16px; }

.role-modal .ant-modal-content {
  border-radius: 2px !important; }

.role-modal .drawer-footer {
  background: #1bca00;
  color: #fff !important;
  border: none !important;
  outline: none !important;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
  height: 40px;
  letter-spacing: 0.032em;
  margin-top: -26px;
  float: right;
  width: 140px; }

.role-modal .ant-input::placeholder {
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.role-modal .role-input:hover {
  border: 1px solid #898989 !important; }

.role-modal .ant-btn:not([disabled]):hover {
  background: #28af21;
  border: 2px solid #229d1b !important; }

.role-modal .arrow-right {
  transform: rotate(270deg); }
  .role-modal .arrow-right g {
    stroke: white;
    fill: white; }
    .role-modal .arrow-right g path {
      fill: white; }

.usersettingnav .settingnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .usersettingnav .settingnav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .usersettingnav .settingnav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .usersettingnav .settingnav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .usersettingnav .settingnav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .usersettingnav .settingnav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .usersettingnav .settingnav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .usersettingnav .settingnav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .usersettingnav .settingnav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .usersettingnav .settingnav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .usersettingnav .settingnav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .usersettingnav .settingnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .usersettingnav .settingnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .usersettingnav .settingnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .usersettingnav .settingnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .usersettingnav .settingnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .usersettingnav .settingnav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .usersettingnav .settingnav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1161fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1161fb; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-user-underline-animation {
        position: relative; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-user-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-user-underline-animation:hover {
        color: #4398ed !important; }
        .usersettingnav .settingnav .secondwrap .filtertab .hover-user-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-user-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-user-underline-animation-click {
        position: relative; }
      .usersettingnav .settingnav .secondwrap .filtertab .hover-user-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .usersettingnav .settingnav .secondwrap .download_csv {
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px;
      margin-right: 100px; }
  .usersettingnav .settingnav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .usersettingnav .settingnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .usersettingnav .settingnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .usersettingnav .settingnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .usersettingnav .settingnav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .usersettingnav .settingnav .ant-calendar-picker-clear,
  .usersettingnav .settingnav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.usersettingnav .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 76px;
  margin-bottom: 32px; }

.usersettingnav .ant-select-selection__placeholder {
  color: #1268fb; }

@media screen and (min-width: 1440px) {
  .settingnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .settingnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.reporting .ant-table-wrapper {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.reporting .vf-cost-container {
  text-align: center; }

.reporting .selected-column {
  display: flex;
  margin-bottom: 30px;
  align-items: center; }
  .reporting .selected-column .display-column-select {
    width: 20%; }
    .reporting .selected-column .display-column-select .ant-select-selection__placeholder {
      display: block !important; }
    .reporting .selected-column .display-column-select .ant-select-selection__choice {
      display: none; }
  .reporting .selected-column h3 {
    margin-right: 10px;
    margin-bottom: 0px; }

.reporting .total-stat-card {
  text-align: center; }

.reporting .ant-calendar-picker {
  margin-left: 5px; }

.reporting .reporting-card {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border: 1px solid #d6d6d6 !important; }
  .reporting .reporting-card .ant-card-body {
    padding: 0; }
    .reporting .reporting-card .ant-card-body .header {
      color: white;
      background: linear-gradient(90deg, #16273b, #192f4a);
      padding: 12px 24px;
      font-size: 16px; }
      .reporting .reporting-card .ant-card-body .header .header-tooltip {
        float: right; }
    .reporting .reporting-card .ant-card-body .body {
      padding: 12px 24px;
      font-size: 22px;
      letter-spacing: 0.3px; }
    .reporting .reporting-card .ant-card-body .divider {
      margin: 0 20px;
      border-top: 1px solid #e8e8e8; }
    .reporting .reporting-card .ant-card-body .footer {
      padding: 10px 24px;
      font-size: 13px; }
      .reporting .reporting-card .ant-card-body .footer hr {
        border: 0;
        border-top: 1px solid #e8e8e8; }

.reporting .selectedValue {
  padding: 1px 6px;
  background: #e6f4fe;
  display: inline-block;
  margin: 6px 8px;
  border-radius: 5px;
  border: 2px solid #a6e6ff;
  position: relative; }
  .reporting .selectedValue i.anticon.anticon-close {
    position: absolute;
    top: -7px;
    right: -6px;
    font-size: 10px;
    color: white;
    background: #a6e6ff;
    border-radius: 15px;
    padding: 2px; }

.reporting table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .reporting table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .reporting table tbody {
    background: white; }
    .reporting table tbody tr:nth-child(2n) {
      background: #f2f2f2; }
    .reporting table tbody tr td {
      font-size: 16px;
      border-bottom-color: #f2f2f2 !important;
      color: #676666;
      font-weight: 400; }
      .reporting table tbody tr td p {
        margin-bottom: 0px; }
    .reporting table tbody tr .order-content p {
      width: 150px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      color: #53a2db; }

.remittance .ant-table-wrapper {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.remittance .vf-cost-container {
  text-align: center; }

.remittance .selected-column {
  display: flex;
  margin-bottom: 30px;
  align-items: center; }
  .remittance .selected-column .display-column-select {
    width: 20%; }
    .remittance .selected-column .display-column-select .ant-select-selection__placeholder {
      display: block !important; }
    .remittance .selected-column .display-column-select .ant-select-selection__choice {
      display: none; }
  .remittance .selected-column h3 {
    margin-right: 10px;
    margin-bottom: 0px; }

.remittance .total-stat-card {
  text-align: center; }

.remittance .ant-calendar-picker {
  margin-left: 5px; }

.remittance .reporting-card {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border: 1px solid #d6d6d6 !important; }
  .remittance .reporting-card .ant-card-body {
    padding: 0; }
    .remittance .reporting-card .ant-card-body .header {
      color: white;
      background: linear-gradient(90deg, #16273b, #192f4a);
      padding: 12px 24px;
      font-size: 16px; }
      .remittance .reporting-card .ant-card-body .header .header-tooltip {
        float: right; }
    .remittance .reporting-card .ant-card-body .body {
      padding: 12px 24px;
      font-size: 22px;
      letter-spacing: 0.3px; }
    .remittance .reporting-card .ant-card-body .divider {
      margin: 0 20px;
      border-top: 1px solid #e8e8e8; }
    .remittance .reporting-card .ant-card-body .footer {
      padding: 10px 24px;
      font-size: 13px; }
      .remittance .reporting-card .ant-card-body .footer hr {
        border: 0;
        border-top: 1px solid #e8e8e8; }

.remittance .selectedValue {
  padding: 1px 6px;
  background: #e6f4fe;
  border-radius: 5px;
  border: 2px solid #a6e6ff;
  position: relative;
  display: inline-block;
  margin: 6px 8px; }
  .remittance .selectedValue i.anticon.anticon-close {
    position: absolute;
    top: -7px;
    right: -6px;
    font-size: 10px;
    color: white;
    background: #a6e6ff;
    border-radius: 15px;
    padding: 2px; }

.remittance table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .remittance table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .remittance table tbody {
    background: white; }
    .remittance table tbody tr:nth-child(2n) {
      background: #f2f2f2; }
    .remittance table tbody tr td {
      font-size: 16px;
      border-bottom-color: #f2f2f2 !important;
      color: #676666;
      font-weight: 400; }
      .remittance table tbody tr td p {
        margin-bottom: 0px; }

.remittance .ant-table-wrapper {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.remittance .vf-cost-container {
  text-align: center; }

.remittance .selected-column {
  display: flex;
  margin-bottom: 30px;
  align-items: center; }
  .remittance .selected-column .display-column-select {
    width: 20%; }
    .remittance .selected-column .display-column-select .ant-select-selection__placeholder {
      display: block !important; }
    .remittance .selected-column .display-column-select .ant-select-selection__choice {
      display: none; }
  .remittance .selected-column h3 {
    margin-right: 10px;
    margin-bottom: 0px; }

.remittance .total-stat-card {
  text-align: center; }

.remittance .ant-calendar-picker {
  margin-left: 5px; }

.remittance .reporting-card {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border: 1px solid #d6d6d6 !important; }
  .remittance .reporting-card .ant-card-body {
    padding: 0; }
    .remittance .reporting-card .ant-card-body .header {
      color: white;
      background: linear-gradient(90deg, #16273b, #192f4a);
      padding: 12px 24px;
      font-size: 16px; }
      .remittance .reporting-card .ant-card-body .header .header-tooltip {
        float: right; }
    .remittance .reporting-card .ant-card-body .body {
      padding: 12px 24px;
      font-size: 22px;
      letter-spacing: 0.3px; }
    .remittance .reporting-card .ant-card-body .divider {
      margin: 0 20px;
      border-top: 1px solid #e8e8e8; }
    .remittance .reporting-card .ant-card-body .footer {
      padding: 10px 24px;
      font-size: 13px; }
      .remittance .reporting-card .ant-card-body .footer hr {
        border: 0;
        border-top: 1px solid #e8e8e8; }

.remittance .selectedValue {
  padding: 1px 6px;
  background: #e6f4fe;
  border-radius: 5px;
  border: 2px solid #a6e6ff;
  position: relative;
  display: inline-block;
  margin: 6px 8px; }
  .remittance .selectedValue i.anticon.anticon-close {
    position: absolute;
    top: -7px;
    right: -6px;
    font-size: 10px;
    color: white;
    background: #a6e6ff;
    border-radius: 15px;
    padding: 2px; }

.remittance table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .remittance table thead tr th {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .remittance table tbody {
    background: white; }
    .remittance table tbody tr:nth-child(2n) {
      background: #f2f2f2; }
    .remittance table tbody tr td {
      font-size: 16px;
      border-bottom-color: #f2f2f2 !important;
      color: #676666;
      font-weight: 400; }
      .remittance table tbody tr td p {
        margin-bottom: 0px; }
    .remittance table tbody tr .order-content p {
      width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      color: #53a2db; }

.remittance .ant-table-wrapper {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.remittance .vf-cost-container {
  text-align: center; }

.remittance .selected-column {
  display: flex;
  margin-bottom: 30px;
  align-items: center; }
  .remittance .selected-column .display-column-select {
    width: 20%; }
    .remittance .selected-column .display-column-select .ant-select-selection__placeholder {
      display: block !important; }
    .remittance .selected-column .display-column-select .ant-select-selection__choice {
      display: none; }
  .remittance .selected-column h3 {
    margin-right: 10px;
    margin-bottom: 0px; }

.remittance .total-stat-card {
  text-align: center; }

.remittance .ant-calendar-picker {
  margin-left: 5px; }

.remittance .reporting-card {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border: 1px solid #d6d6d6 !important; }
  .remittance .reporting-card .ant-card-body {
    padding: 0; }
    .remittance .reporting-card .ant-card-body .header {
      color: white;
      background: linear-gradient(90deg, #16273b, #192f4a);
      padding: 12px 24px;
      font-size: 16px; }
      .remittance .reporting-card .ant-card-body .header .header-tooltip {
        float: right; }
    .remittance .reporting-card .ant-card-body .body {
      padding: 12px 24px;
      font-size: 22px;
      letter-spacing: 0.3px; }
    .remittance .reporting-card .ant-card-body .divider {
      margin: 0 20px;
      border-top: 1px solid #e8e8e8; }
    .remittance .reporting-card .ant-card-body .footer {
      padding: 10px 24px;
      font-size: 13px; }
      .remittance .reporting-card .ant-card-body .footer hr {
        border: 0;
        border-top: 1px solid #e8e8e8; }

.remittance .selectedValue {
  padding: 1px 6px;
  background: #e6f4fe;
  border-radius: 5px;
  border: 2px solid #a6e6ff;
  position: relative;
  display: inline-block;
  margin: 6px 8px; }
  .remittance .selectedValue i.anticon.anticon-close {
    position: absolute;
    top: -7px;
    right: -6px;
    font-size: 10px;
    color: white;
    background: #a6e6ff;
    border-radius: 15px;
    padding: 2px; }

.remittance table thead tr th {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  background: #16273b !important;
  border-radius: 0 !important;
  color: white !important; }

.remittance table tbody {
  background: white; }
  .remittance table tbody tr:nth-child(2n) {
    background: #f2f2f2; }
  .remittance table tbody tr td {
    font-size: 16px;
    border-bottom-color: #f2f2f2 !important;
    color: #676666;
    font-weight: 400; }
    .remittance table tbody tr td p {
      margin-bottom: 0px; }
  .remittance table tbody tr .order-content p {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    color: #53a2db; }

.overallReport .ant-table-wrapper {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.overallReport .vf-cost-container {
  text-align: center; }

.overallReport .selected-column {
  display: flex;
  margin-bottom: 30px;
  align-items: center; }
  .overallReport .selected-column .display-column-select {
    width: 20%; }
    .overallReport .selected-column .display-column-select .ant-select-selection__placeholder {
      display: block !important; }
    .overallReport .selected-column .display-column-select .ant-select-selection__choice {
      display: none; }
  .overallReport .selected-column h3 {
    margin-right: 10px;
    margin-bottom: 0px; }

.overallReport .total-stat-card {
  text-align: center; }

.overallReport .ant-calendar-picker {
  margin-left: 5px; }

.overallReport .reporting-card {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border: 1px solid #d6d6d6 !important; }
  .overallReport .reporting-card .ant-card-body {
    padding: 0; }
    .overallReport .reporting-card .ant-card-body .header {
      color: white;
      background: linear-gradient(90deg, #16273b, #192f4a);
      padding: 12px 12px;
      font-size: 15px; }
      .overallReport .reporting-card .ant-card-body .header .header-tooltip {
        float: right; }
    .overallReport .reporting-card .ant-card-body .body {
      padding: 12px 24px;
      font-size: 22px;
      letter-spacing: 0.3px; }
    .overallReport .reporting-card .ant-card-body .divider {
      margin: 0 20px;
      border-top: 1px solid #e8e8e8; }
    .overallReport .reporting-card .ant-card-body .footer {
      padding: 10px 24px;
      font-size: 13px; }
      .overallReport .reporting-card .ant-card-body .footer hr {
        border: 0;
        border-top: 1px solid #e8e8e8; }

.overallReport .selectedValue {
  padding: 1px 6px;
  background: #e6f4fe;
  border-radius: 5px;
  border: 2px solid #a6e6ff;
  position: relative;
  display: inline-block;
  margin: 6px 8px; }
  .overallReport .selectedValue i.anticon.anticon-close {
    position: absolute;
    top: -7px;
    right: -6px;
    font-size: 10px;
    color: white;
    background: #a6e6ff;
    border-radius: 15px;
    padding: 2px; }

.overallReport table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
  .overallReport table thead tr th {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important; }
  .overallReport table tbody {
    background: white; }
    .overallReport table tbody tr:nth-child(2n) {
      background: #f2f2f2; }
    .overallReport table tbody tr td {
      font-size: 16px;
      border-bottom-color: #f2f2f2 !important;
      color: #676666;
      font-weight: 400; }
      .overallReport table tbody tr td p {
        margin-bottom: 0px; }
    .overallReport table tbody tr .order-content p {
      width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      color: #53a2db; }

.fill3 {
  margin-bottom: -20px; }
  .fill3 .active {
    color: #1268fb;
    border-bottom: 2px #1268fb solid; }

.pick {
  color: #1268fb !important;
  background-color: white !important;
  margin-left: 8px;
  font-size: 12px !important;
  box-shadow: 0px 2px 8px #00000014; }

.content-page-subtitle {
  font-size: 12px;
  color: #212121;
  letter-spacing: 0.032em; }

.custom_dropdown3 .review_line_btn {
  border: none;
  box-shadow: none;
  background: transparent;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px; }

.custom_dropdown3 .ant-btn-disabled,
.custom_dropdown3 .ant-btn.disabled,
.custom_dropdown3 .ant-btn[disabled],
.custom_dropdown3 .ant-btn-disabled:hover,
.custom_dropdown3 .ant-btn.disabled:hover,
.custom_dropdown3 .ant-btn[disabled]:hover,
.custom_dropdown3 .ant-btn-disabled:focus,
.custom_dropdown3 .ant-btn.disabled:focus,
.custom_dropdown3 .ant-btn[disabled]:focus,
.custom_dropdown3 .ant-btn-disabled:active,
.custom_dropdown3 .ant-btn.disabled:active,
.custom_dropdown3 .ant-btn[disabled]:active,
.custom_dropdown3 .ant-btn-disabled.active,
.custom_dropdown3 .ant-btn.disabled.active,
.custom_dropdown3 .ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.25);
  background-color: transparent !important;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none; }

.custom_dropdown3 .reverse_pickup_status {
  display: flex;
  justify-content: center;
  align-items: center; }

.custom_dropdown3 .Raise_Escalation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px; }

.custom_dropdown3 .pointer_escalations {
  color: #00000040; }

.custom_dropdown3 .raise_escalation_btn {
  margin-left: -11px; }

.custom_dropdown3 .ant-dropdown-menu-item {
  border-bottom: 1px solid #cecece !important;
  padding: 4px 0px 0px 0px !important; }

.bulk-list-wraper .detailswrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  margin-top: 8px; }
  .bulk-list-wraper .detailswrp > p {
    font-size: 12px;
    display: flex;
    align-items: center; }
    .bulk-list-wraper .detailswrp > p span {
      font-size: 14px;
      font-weight: 500;
      margin: 0px 6px; }
  .bulk-list-wraper .detailswrp .right {
    margin-left: 16px; }
    .bulk-list-wraper .detailswrp .right .err {
      border-color: red !important;
      background-color: transparent !important;
      color: red !important; }

.bulk-list-wraper .lst-wraper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 12px; }
  .bulk-list-wraper .lst-wraper .left {
    transition: all 1s linear;
    background: #e6eaee;
    width: 100%;
    padding: 8px 12px 0px 12px;
    border-radius: 4px;
    border: 1px solid #b2b2b2; }
  .bulk-list-wraper .lst-wraper .ant-tag {
    margin-bottom: 8px;
    border-radius: 2px;
    padding: 2px 6px;
    border-color: #b5daff;
    background-color: #f5faff; }
  .bulk-list-wraper .lst-wraper .left::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px; }
  .bulk-list-wraper .lst-wraper .left::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
  .bulk-list-wraper .lst-wraper .left::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #898989; }
  .bulk-list-wraper .lst-wraper .left {
    display: flex;
    align-items: center;
    min-height: 20px;
    max-height: 80px;
    overflow-y: scroll;
    flex-wrap: wrap; }
    .bulk-list-wraper .lst-wraper .left p {
      margin-right: 8px; }
  .bulk-list-wraper .lst-wraper .right {
    margin-bottom: 12px; }

.shipment-card {
  margin-bottom: 3%;
  letter-spacing: 0.032em;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .shipment-card .vforderid_tag {
    position: absolute;
    left: 0px;
    top: 24px; }
  .shipment-card .ant-dropdown-button.ant-btn-group
> .ant-btn:last-child:not(:first-child):not(:disabled) {
    background: #3587ff; }
  .shipment-card .ant-dropdown-menu-item-disabled,
  .shipment-card .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed !important;
    background: rgba(0, 0, 0, 0.15) !important; }
  .shipment-card .ant-btn-group .ant-btn {
    vertical-align: middle; }
  .shipment-card .hidden {
    display: none; }
  .shipment-card .ant-card-body {
    padding: 0; }
  .shipment-card .blue-text {
    color: #1161fb; }
  .shipment-card .mr-3 {
    margin-right: 10px; }
  .shipment-card span {
    vertical-align: middle; }
    .shipment-card span i {
      vertical-align: middle; }
  .shipment-card .header-row {
    display: flex;
    align-items: center;
    padding: 64px 32px 16px 32px;
    justify-content: space-between; }
    .shipment-card .header-row h3 {
      margin-bottom: 0px;
      font-size: 12px;
      font-weight: 400;
      color: #898989;
      line-height: 16px;
      letter-spacing: 0.032em; }
    .shipment-card .header-row .shipment-date {
      display: flex;
      justify-content: center;
      align-items: center; }
      .shipment-card .header-row .shipment-date .anticon {
        font-size: 17px; }
    .shipment-card .header-row .shipment-action {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 16px;
      justify-content: space-around; }
      .shipment-card .header-row .shipment-action .icansvg {
        margin-top: -2px; }
    .shipment-card .header-row .shipment-action_bg {
      width: 168px;
      height: 32px;
      background: #1161fb;
      color: #fff;
      position: relative;
      border-radius: 2px; }
    .shipment-card .header-row .middledata {
      display: flex;
      justify-content: center; }
  .shipment-card .tabs-row {
    background: #16273b;
    padding: 0 20px 0 28px;
    display: flex;
    align-items: center;
    height: 40px;
    overflow: hidden; }
    .shipment-card .tabs-row ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      .shipment-card .tabs-row ul li {
        display: inline-block;
        padding: 11px 32px;
        color: white;
        cursor: pointer;
        font-size: 12px;
        height: 42px; }
      .shipment-card .tabs-row ul li.active,
      .shipment-card .tabs-row ul li:hover {
        background: #244060; }
    .shipment-card .tabs-row .bulk-action-btn {
      background: transparent;
      color: white;
      border: 2px solid #fff;
      border-radius: 4px; }
    .shipment-card .tabs-row .ant-avatar {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 12px; }
  .shipment-card .tab-container {
    background: #f2f2f2;
    padding: 0 28px 16px 28px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .shipment-card .end_data svg {
    width: 16px !important;
    height: 16px !important; }
  .shipment-card .icansvg svg {
    width: 16px;
    height: 16px;
    margin-right: 8px; }
  .shipment-card .delivered-date {
    margin-bottom: 0px;
    font-size: 12px;
    text-decoration: underline;
    text-align: center;
    line-height: 16px; }
  .shipment-card .middledata_shipment {
    display: flex;
    justify-content: center;
    align-items: center; }

.review_pickup_flex {
  display: flex;
  justify-content: start;
  align-items: center; }
  .review_pickup_flex .antCircleCheck.anticon {
    font-size: 16px;
    color: #d9d9d9; }

.review_pickup_flex .ant-tooltip-open:hover svg #Ellipse_44 {
  stroke: #1268fb;
  fill: #1268fb; }

.review_pickup_flex .ant-tooltip-open:hover svg #Ellipse_47 {
  stroke: #fff;
  fill: #ffff; }

.review_pickup_flex .ant-tooltip-open:hover svg #Rectangle_2462 {
  stroke: #fff;
  fill: #ffff; }

.review_pickup_flex .antCircleCheck.anticon:hover {
  font-size: 16px;
  color: #1268fb !important; }

.review_pickup_flex button:hover svg path {
  fill: #0058d6 !important;
  stroke: #fff; }

.review_pickup_flex button:hover svg #Ellipse_44 {
  stroke: #1268fb;
  fill: #1268fb; }

.review_pickup_flex button:hover svg #Ellipse_47 {
  stroke: #fff;
  fill: #ffff; }

.review_pickup_flex button:hover svg #Rectangle_2462 {
  stroke: #fff;
  fill: #ffff; }

.customer-info-tab {
  background: #fff;
  padding: 8px 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .customer-info-tab .header_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%; }
    .customer-info-tab .header_customer_info .full_name {
      width: 32%; }
      .customer-info-tab .header_customer_info .full_name .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .header_customer_info .full_name .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .customer-info-tab .header_customer_info .address_line1 {
      width: 32%; }
      .customer-info-tab .header_customer_info .address_line1 .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .header_customer_info .address_line1 .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px; }
    .customer-info-tab .header_customer_info .Pin_code {
      width: 32%; }
      .customer-info-tab .header_customer_info .Pin_code .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .header_customer_info .Pin_code .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
  .customer-info-tab .middle_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%; }
    .customer-info-tab .middle_customer_info .email_address {
      width: 32%; }
      .customer-info-tab .middle_customer_info .email_address .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .middle_customer_info .email_address .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .customer-info-tab .middle_customer_info .address_line2 {
      width: 32%; }
      .customer-info-tab .middle_customer_info .address_line2 .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .middle_customer_info .address_line2 .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .customer-info-tab .middle_customer_info .ship_country {
      width: 32%; }
      .customer-info-tab .middle_customer_info .ship_country .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898898;
        cursor: no-drop; }
      .customer-info-tab .middle_customer_info .ship_country .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px; }
  .customer-info-tab .footer_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px; }
    .customer-info-tab .footer_customer_info .mobile_number {
      width: 32%; }
      .customer-info-tab .footer_customer_info .mobile_number .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .footer_customer_info .mobile_number .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .customer-info-tab .footer_customer_info .citytown {
      width: 32%; }
      .customer-info-tab .footer_customer_info .citytown .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898898;
        cursor: no-drop; }
      .customer-info-tab .footer_customer_info .citytown .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .customer-info-tab .footer_customer_info .State {
      width: 32%; }
      .customer-info-tab .footer_customer_info .State .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .footer_customer_info .State .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }

.line-items-tab {
  padding: 16px 16px 16px 16px;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .line-items-tab .line-item {
    background: #f7f7f7;
    margin-bottom: 10px;
    padding: 14px 12px;
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent; }
    .line-items-tab .line-item .line-item-product-detail {
      display: flex;
      align-items: center;
      width: 250px;
      margin-left: 0px; }
    .line-items-tab .line-item .line-item-img {
      width: 56px;
      height: 56px;
      margin-right: 10px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important; }
    .line-items-tab .line-item .line-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      width: 150px; }
    .line-items-tab .line-item .line-item-sku {
      font-size: 12px;
      color: #000;
      font-weight: 400;
      margin: 0;
      overflow: inherit !important; }
      .line-items-tab .line-item .line-item-sku a {
        color: #1161fb; }
      .line-items-tab .line-item .line-item-sku a:hover {
        text-decoration: underline !important; }
    .line-items-tab .line-item .line-item-sku svg g path {
      stroke: #1161fb;
      text-decoration: underline; }
    .line-items-tab .line-item .line-item-sku:hover svg g path {
      stroke: #1161fb;
      text-decoration: underline; }
    .line-items-tab .line-item .line-item-name-sku {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      width: 150px; }
  .line-items-tab .delivered-date {
    margin-bottom: 0px;
    font-size: 12px;
    text-decoration: underline;
    text-align: center; }
  .line-items-tab .line-items-tab_header_upper_data {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 16px 16px 8px;
    margin: -16px 0 -2px 0;
    color: #898989; }
    .line-items-tab .line-items-tab_header_upper_data .flex33 {
      width: 250px; }
    .line-items-tab .line-items-tab_header_upper_data .flex44 {
      width: 85px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .line-items-tab .line-items-tab_header_upper_data .flex55 {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-evenly; }
      .line-items-tab .line-items-tab_header_upper_data .flex55 span {
        margin-right: 20px; }
      .line-items-tab .line-items-tab_header_upper_data .flex55 .Quantity {
        width: 70px; }
      .line-items-tab .line-items-tab_header_upper_data .flex55 .star {
        width: 30px; }
      .line-items-tab .line-items-tab_header_upper_data .flex55 .Shopify_Selling_Price {
        width: 150px; }
    .line-items-tab .line-items-tab_header_upper_data .flex66 {
      width: 115px;
      display: flex;
      padding-left: 20px; }
  .line-items-tab .openordersvg {
    width: 85px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .line-items-tab .quntyandpices {
    display: flex;
    width: 250px;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    color: #000; }
    .line-items-tab .quntyandpices .Quantity {
      width: 70px; }
    .line-items-tab .quntyandpices .star {
      width: 30px; }
    .line-items-tab .quntyandpices .Shopify_Selling_Price {
      width: 150px; }
  .line-items-tab .end_data {
    width: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    margin-right: 3px; }
  .line-items-tab .lost-data {
    width: 115px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    margin-right: 25px; }
  .line-items-tab .other-status {
    white-space: nowrap;
    letter-spacing: 0.032em;
    font-weight: 500;
    line-height: 16px; }
  .line-items-tab .rto-in-transit-status {
    margin-left: 10px; }

.shipmentprogressbar_data {
  background: #ffff;
  padding: 8px 16px; }
  .shipmentprogressbar_data .shipmentprogressbar_data2 {
    background-color: #f7f7f7;
    height: 112px;
    margin-bottom: 12px;
    display: flex; }
    .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container {
      margin-top: 24px;
      display: flex;
      width: 100%;
      margin-bottom: 16px; }
      .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .progress-line {
        position: relative;
        margin: 0 auto;
        border-radius: 10px;
        width: 60%;
        background: #cecece;
        height: 8px;
        z-index: 90;
        display: flex;
        justify-content: space-between;
        margin-left: 40px; }
        .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .progress-line .progress {
          position: absolute;
          width: 75%;
          height: 100%;
          background: #2bc822;
          transition: width 1s ease-in-out; }
      .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .status {
        position: relative;
        top: -4px;
        left: -0.7px; }
        .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .status .dot {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #cecece;
          cursor: pointer; }
          .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .status .dot .current {
            background: white;
            border: 5px solid orange; }
          .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .status .dot.completed {
            position: relative;
            background: #2bc822;
            border: 5px solid #2bc822; }
        .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .status .dot.dot-active {
          outline: 4px solid #b8f0b3;
          animation: pulse-black 2s infinite; }
        .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .status .time-and-status-wraper {
          position: relative;
          display: flex;
          min-width: 75px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          right: 28px; }
          .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .status .time-and-status-wraper .date {
            margin-top: 2px;
            color: #212121;
            font-size: 12px;
            letter-spacing: 0, 032em;
            font-weight: 400; }
        .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .status .shipment_step_color {
          color: #2bc822;
          font-weight: 500;
          font-size: 12px;
          margin-top: 7px; }
        .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .status .original_color {
          color: #cecece;
          font-size: 12px;
          margin-top: 7px; }
      .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .status:nth-of-type(4) .time-and-status-wraper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
      .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .custom-rto-in-transit {
        left: 56px !important; }
      .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .return-time-and-status-wraper {
        margin-left: -64px;
        position: relative;
        color: #2bc822;
        left: 35px;
        text-align: center; }
        .shipmentprogressbar_data .shipmentprogressbar_data2 .line-container .return-time-and-status-wraper .date {
          margin-top: 2px;
          color: #212121;
          font-size: 12px;
          letter-spacing: 0, 032em; }
    .shipmentprogressbar_data .shipmentprogressbar_data2 .rto-dot-active {
      left: 5px !important; }
    .shipmentprogressbar_data .shipmentprogressbar_data2 .Order_tracking_status {
      margin-right: 28px; }
      .shipmentprogressbar_data .shipmentprogressbar_data2 .Order_tracking_status p {
        letter-spacing: 0.032em;
        font-size: 12px;
        line-height: 16px; }
    .shipmentprogressbar_data .shipmentprogressbar_data2 .lost-shipment {
      color: #f12f1a;
      font-weight: 500; }
  .shipmentprogressbar_data .shipment_track_status {
    max-height: 187px;
    overflow-x: auto; }
    .shipmentprogressbar_data .shipment_track_status .footer_data {
      font-size: 12px;
      background: #f5fff4;
      padding: 8px;
      margin-bottom: 8px; }
      .shipmentprogressbar_data .shipment_track_status .footer_data .footer_data_text {
        margin-bottom: 4px;
        position: relative;
        margin-top: 10px;
        display: flex;
        justify-content: space-between; }
        .shipmentprogressbar_data .shipment_track_status .footer_data .footer_data_text span {
          margin-right: 8px; }
        .shipmentprogressbar_data .shipment_track_status .footer_data .footer_data_text .circle {
          position: absolute;
          transform: translateY(-60%);
          height: 16px;
          width: 16px;
          top: 50%;
          left: 8px;
          border-radius: 50%;
          background: #252625;
          transition: all 1s; }
        .shipmentprogressbar_data .shipment_track_status .footer_data .footer_data_text .circle::after {
          content: "";
          position: absolute;
          transform: translate(-50%, 0);
          height: 56px;
          width: 1px;
          top: 100%;
          left: 50%;
          background: #252625;
          transition: all 1s; }
    .shipmentprogressbar_data .shipment_track_status .shipmen-remark {
      width: 400px; }
    .shipmentprogressbar_data .shipment_track_status .shipment-date {
      width: 240px;
      padding-left: 40px; }
      .shipmentprogressbar_data .shipment_track_status .shipment-date .date-moment {
        font-weight: 600;
        letter-spacing: 0.032em;
        font-size: 12px;
        color: #000; }
    .shipmentprogressbar_data .shipment_track_status .Shipment-location {
      width: 190px;
      text-transform: uppercase; }
  .shipmentprogressbar_data .shipment_track .footer_data {
    font-size: 12px;
    background: #f5fff4;
    padding: 8px;
    margin-bottom: 8px; }
    .shipmentprogressbar_data .shipment_track .footer_data .footer_data_text {
      margin-bottom: 4px;
      position: relative;
      margin-top: 10px;
      display: flex;
      justify-content: space-between; }
      .shipmentprogressbar_data .shipment_track .footer_data .footer_data_text span {
        margin-right: 8px; }
      .shipmentprogressbar_data .shipment_track .footer_data .footer_data_text .circle {
        position: absolute;
        transform: translateY(-60%);
        height: 16px;
        width: 16px;
        top: 50%;
        left: 8px;
        border-radius: 50%;
        background: #252625;
        transition: all 1s; }
      .shipmentprogressbar_data .shipment_track .footer_data .footer_data_text .circle::after {
        content: "";
        position: absolute;
        transform: translate(-50%, 0);
        height: 56px;
        width: 1px;
        top: 100%;
        left: 50%;
        background: #252625;
        transition: all 1s; }
  .shipmentprogressbar_data .shipment_track .shipmen-remark {
    width: 405px;
    text-transform: uppercase; }
  .shipmentprogressbar_data .shipment_track .shipment-date {
    width: 215px;
    padding-left: 40px; }
  .shipmentprogressbar_data .shipment_track .Shipment-location {
    width: 170px;
    text-transform: uppercase; }
  .shipmentprogressbar_data .detail_not_available {
    font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
    background: #f5fff4;
    padding: 8px; }
  .shipmentprogressbar_data .shipment_track_status::-webkit-scrollbar {
    width: 5px; }
  .shipmentprogressbar_data .shipment_track_status::-webkit-scrollbar-thumb {
    background: #888; }
  .shipmentprogressbar_data .shipment_track_status::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .shipmentprogressbar_data .lessDetails {
    margin-left: 8px; }
  .shipmentprogressbar_data .moredeatil {
    margin-left: 8px; }
  .shipmentprogressbar_data .ist-timezone-txt p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 8px;
    color: #000;
    font-family: ui-monospace;
    font-style: italic;
    letter-spacing: 0.032em;
    line-height: 16px; }

.footer_data:last-of-type {
  overflow: hidden; }
  .footer_data:last-of-type .circle {
    visibility: hidden; }
  .footer_data:last-of-type .line {
    height: 16px;
    background: #252625;
    width: 16px;
    position: absolute;
    visibility: visible;
    border-radius: 50%; }
  .footer_data:last-of-type .footer_data {
    overflow: hidden; }

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #2ac82260; }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

@keyframes load-34 {
  0% {
    width: 0; }
  100% {
    width: 34%; } }

@keyframes load-48 {
  0% {
    width: 0; }
  100% {
    width: 48%; } }

@keyframes load-66 {
  0% {
    width: 0; }
  100% {
    width: 66%; } }

@keyframes load-79 {
  0% {
    width: 0; }
  100% {
    width: 79%; } }

@keyframes load-100 {
  0% {
    width: 0; }
  100% {
    width: 99%; } }

.shipment_request_review-line-item .ant-modal-content {
  padding: 8px 0px;
  border-radius: 0px !important; }
  .shipment_request_review-line-item .ant-modal-content .ant-modal-header {
    padding: 16px 8px;
    width: 856px;
    margin: auto; }
  .shipment_request_review-line-item .ant-modal-content .flex-wraper {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .shipment_request_review-line-item .ant-modal-content .flex-wraper > div {
      margin-right: 3px; }
  .shipment_request_review-line-item .ant-modal-content .ant-modal-footer {
    padding: 16px 0px;
    text-align: left !important;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    width: 856px;
    margin: auto; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail {
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 16px; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .title_text {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121;
      margin-bottom: 12px;
      font-weight: 500; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .replacement_text {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      margin-bottom: 16px; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .note_txt {
      font-size: 14px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #000;
      margin-bottom: 16px;
      font-weight: 500;
      font-style: italic;
      margin-right: 6px; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason {
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 16px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason .pickup_reason {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
        margin-bottom: 8px;
        font-weight: 500; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason .reverse_pickup_reason_data {
        font-size: 12px;
        width: 100%;
        margin-bottom: 16px; }
        .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason .reverse_pickup_reason_data .ant-select-selection {
          display: block;
          box-sizing: border-box;
          background-color: #f5f5f5;
          border: none;
          border-radius: 0;
          outline: none; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason .other-textarea textarea {
        width: 100%;
        border: none;
        outline: none;
        background-color: #f5f5f5;
        height: 100px;
        padding: 8px;
        font-size: 12px;
        letter-spacing: 0.032em;
        margin-bottom: 16px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason .other_label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        margin-bottom: 4px; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 32px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .common_label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        width: 208px;
        margin-bottom: 4px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .upeer_label_margin {
        margin-right: 6px;
        margin-bottom: 16px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .customer_detail_text {
        width: 208px;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .account_number_text {
        width: 208px;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .confirm_account_number_text {
        width: 208px;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .ifsc {
        width: 208px;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .amount_data {
      margin-top: -22px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .amount_data .amount_value {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        margin-bottom: 4px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .amount_data .end_customer_amount_input {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .amount_data .customer_amount_text {
        width: 208px;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
  .shipment_request_review-line-item .ant-modal-content .bottom_button {
    text-align: right; }
    .shipment_request_review-line-item .ant-modal-content .bottom_button .not_clickablebtn {
      width: 176px;
      background: #88b6fd;
      height: 40px;
      border: none;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      pointer-events: none; }
    .shipment_request_review-line-item .ant-modal-content .bottom_button .clickable_btn {
      width: 176px;
      background: #1370fc;
      height: 40px;
      border: none;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .shipment_request_review-line-item .ant-modal-content .bottom_button .cancel_close_btn {
      width: 176px;
      background: #f5f5f5;
      height: 40px;
      border: none;
      color: #898989;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }

.shipment_request_review-line-item .order_vfid span {
  color: #898989; }

.shipment_request_review-line-item .modal-title {
  display: flex;
  justify-content: space-between; }
  .shipment_request_review-line-item .modal-title p {
    color: #898989 !important;
    margin-bottom: 0px;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 22px; }
    .shipment_request_review-line-item .modal-title p > span {
      color: #212121 !important; }
  .shipment_request_review-line-item .modal-title .imp {
    font-size: 12px !important;
    color: #898989; }
    .shipment_request_review-line-item .modal-title .imp > span {
      color: #ce1543; }

.shipment_request_review-line-item .blue-text {
  color: #1268fb; }

.shipment_request_review-line-item .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 16px;
  font-size: 12px; }
  .shipment_request_review-line-item .modal-header p {
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin-bottom: 0px;
    letter-spacing: 0.032em;
    line-height: 16px; }

.shipment_request_review-line-item .line-items-tab {
  background: #fff;
  margin-bottom: -16px;
  padding: 8px; }
  .shipment_request_review-line-item .line-items-tab .line-item {
    background: #f2f2f2;
    margin-bottom: 10px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent; }
    .shipment_request_review-line-item .line-items-tab .line-item .ant-checkbox-wrapper {
      transform: scale(1.15); }
    .shipment_request_review-line-item .line-items-tab .line-item .ant-input {
      width: 80px; }
    .shipment_request_review-line-item .line-items-tab .line-item .ant-checkbox .ant-checkbox-inner {
      border: none;
      width: 14px;
      height: 14px; }
    .shipment_request_review-line-item .line-items-tab .line-item .quantity-field {
      max-width: 65px;
      margin-right: 5px; }
    .shipment_request_review-line-item .line-items-tab .line-item .anticon {
      font-size: 22px; }
    .shipment_request_review-line-item .line-items-tab .line-item .line-item-product-detail {
      display: flex;
      align-items: center;
      width: 61%;
      margin-left: 0px; }
      .shipment_request_review-line-item .line-items-tab .line-item .line-item-product-detail .mr {
        margin-right: 8px; }
    .shipment_request_review-line-item .line-items-tab .line-item .line-item-img {
      width: 56px;
      height: 56px;
      margin-right: 0px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important; }
    .shipment_request_review-line-item .line-items-tab .line-item .line-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      margin-top: -12px; }
    .shipment_request_review-line-item .line-items-tab .line-item .line-item-sku {
      font-size: 12px;
      color: #000;
      font-weight: 400;
      margin: 0; }
  .shipment_request_review-line-item .line-items-tab .line-item.selected {
    background: #eeffe5;
    border: 1px solid #73d13d; }
  .shipment_request_review-line-item .line-items-tab .edit-quantity-btn {
    background: transparent;
    border: 1px solid #ff005e;
    border-radius: 4px;
    color: #ff005e; }
  .shipment_request_review-line-item .line-items-tab .edit-quantity-btn:hover {
    background: #ff005e;
    border: 1px solid #ff005e;
    box-shadow: 0 1px 2px rgba(255, 0, 94, 0.4);
    color: white; }

.shipment_request_review-line-item .shipments-tab {
  padding: 12px 13px;
  background: #fff; }
  .shipment_request_review-line-item .shipments-tab .no-shipment {
    text-align: center; }
  .shipment_request_review-line-item .shipments-tab .shipment-header {
    background: #f2f2f2;
    padding: 28px 20px;
    margin-bottom: 5px;
    color: black; }
  .shipment_request_review-line-item .shipments-tab .shipment-items {
    background: #eff5ff;
    padding: 5px 10px; }
  .shipment_request_review-line-item .shipments-tab .shipment-item {
    background: transparent;
    padding: 16px 8px;
    display: flex;
    align-items: center; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .ant-checkbox-wrapper {
      transform: scale(1.15); }
    .shipment_request_review-line-item .shipments-tab .shipment-item .ant-checkbox .ant-checkbox-inner {
      border: none; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .anticon {
      font-size: 22px; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .shipment-item-product-detail {
      display: flex;
      align-items: center; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .shipment-item-img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .shipment-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 15px; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .shipment-item-sku {
      font-size: 14px;
      color: #676666;
      font-weight: 400;
      margin: 0;
      margin-top: -2px; }
  .shipment_request_review-line-item .shipments-tab .shipment-item:nth-of-type(2n) {
    background: white; }

.shipment_request_review-line-item .quantity {
  width: 20%; }

.shipment_request_review-line-item .action {
  width: 15%; }
  .shipment_request_review-line-item .action .action_button {
    background: transparent;
    border: none;
    box-shadow: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #fd115e;
    padding: 0; }

.shipment_request_review-line-item .variants_detail_header {
  margin-top: -8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 8px; }
  .shipment_request_review-line-item .variants_detail_header .varaint_text {
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin-bottom: 0px;
    letter-spacing: 0.032em;
    line-height: 16px;
    width: 55%; }
  .shipment_request_review-line-item .variants_detail_header .quantity_txt {
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin-bottom: 0px;
    letter-spacing: 0.032em;
    line-height: 16px;
    width: 20%; }
  .shipment_request_review-line-item .variants_detail_header .action_txt {
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin-bottom: 0px;
    letter-spacing: 0.032em;
    line-height: 16px;
    width: 15%; }

.shipment_request_review-line-item .bottom_check-box {
  display: flex;
  justify-content: start;
  padding: 16px 8px 0px 16px; }
  .shipment_request_review-line-item .bottom_check-box .following_action {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    margin-right: 16px; }
  .shipment_request_review-line-item .bottom_check-box .reverse_pickup {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    margin-right: 16px; }
    .shipment_request_review-line-item .bottom_check-box .reverse_pickup .reverse_pickup_checkbox {
      margin-left: 8px; }
  .shipment_request_review-line-item .bottom_check-box .refund_end {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    margin-right: 16px; }
    .shipment_request_review-line-item .bottom_check-box .refund_end .refund_end_checkbox {
      margin-left: 8px; }
  .shipment_request_review-line-item .bottom_check-box .replacement {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    margin-right: 16px; }
    .shipment_request_review-line-item .bottom_check-box .replacement .replacement_checkbox {
      margin-left: 8px; }

.shipment_request_review-line-item .ghanta-icon-wraper {
  margin-right: 8px;
  margin-left: 8px;
  background: #f0eab3;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center; }

.shipment_request_review-line-item .replacement_border {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 16px;
  margin-top: 4px; }

.shipment_request_review-line-item .replacement_new_txt {
  background: #fffce3;
  height: 40px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 16px;
  letter-spacing: 0.032em;
  border: 1px solid #feea79;
  color: #212121; }

.shipment_request_review-line-item .refund_disable_circle {
  margin-right: 4px;
  color: #94e390;
  font-size: 16px; }

.shipment_request_review-line-item .bank_detail_txt {
  color: #212121;
  font-weight: 500; }

.shipment_request_review-line-item .groundaction_filter {
  background: #f5f5f5;
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #212121;
  margin-bottom: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  line-height: 16px; }

.shipment_request_review-line-item .link_data {
  float: right; }

.shipment_request_review-line-item .ali-in-store-hover-underline-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  color: #fd104d; }

.shipment_request_review-line-item .ali-in-store-hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 85%;
  transform: scaleX(0);
  height: 1px;
  bottom: 3px;
  left: 2px;
  background-color: #e61047;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out; }

.shipment_request_review-line-item .ali-in-store-hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left; }

.shipment_request_review-line-item .view-status-txt {
  color: #ce1543;
  margin-right: 4px; }

.shipment_request_review-line-item .replacement_text_data {
  font-size: 12px;
  color: #212121;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 500; }

.shipment_request_review-line-item .important_txt {
  color: #212121;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.032em;
  line-height: 16px;
  margin-right: 8px; }

.shipment_request_review-line-item .disabledaction {
  color: #00000040; }

.shipment_request_review-line-item .disabled_checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f5f5f5; }

.shipment_request_review-line-item .disabled_checkbox .ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #00000040 !important; }

.shipment_request_review-line-item .mandatory_filed {
  font-size: 12px !important;
  letter-spacing: 0.032em !important;
  line-height: 16px !important; }

.shipment_request_review-line-item .note_text {
  font-style: italic; }

.shipment_request_review-line-item .ant-select-selection-selected-value {
  color: #212121; }

.dispute-modal {
  width: 672px !important; }
  .dispute-modal .ant-modal-content {
    padding: 10px 10px; }
    .dispute-modal .ant-modal-content .ant-modal-header {
      padding: 16px 0px;
      width: 93.5%;
      margin: auto; }
  .dispute-modal .ant-modal-body {
    padding-top: 0;
    margin-top: -19px; }
    .dispute-modal .ant-modal-body .esc-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      padding-top: 16px;
      margin-bottom: 12px; }
      .dispute-modal .ant-modal-body .esc-details .details {
        width: 200px; }
        .dispute-modal .ant-modal-body .esc-details .details p {
          margin-bottom: 4px;
          font-size: 12px;
          color: #898989; }
        .dispute-modal .ant-modal-body .esc-details .details .id {
          height: 32px;
          background: #f5f5f5;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 16px;
          font-weight: 500;
          font-size: 12px;
          color: #1370fc; }
        .dispute-modal .ant-modal-body .esc-details .details .details {
          width: 200px; }
  .dispute-modal .modal-title {
    display: flex;
    justify-content: space-between; }
    .dispute-modal .modal-title :first-child {
      font-size: 16px;
      color: #212121; }
    .dispute-modal .modal-title :last-child {
      font-size: 12px;
      color: #898989;
      font-weight: 400; }
  .dispute-modal .blue-text {
    color: #0068ff; }
  .dispute-modal .dispute-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 0px; }
    .dispute-modal .dispute-header p {
      font-size: 12px;
      color: #000000d9;
      margin-bottom: 0px; }
  .dispute-modal .dispute-form {
    margin-top: 7px; }
    .dispute-modal .dispute-form .dispute-reason {
      margin-top: 24px; }
      .dispute-modal .dispute-form .dispute-reason p {
        font-size: 12px;
        color: #898989;
        margin-bottom: 5px; }
      .dispute-modal .dispute-form .dispute-reason .ant-select {
        width: 100% !important; }
        .dispute-modal .dispute-form .dispute-reason .ant-select .ant-select-selection {
          border-radius: 0;
          border: none;
          background: #f5f5f5; }
          .dispute-modal .dispute-form .dispute-reason .ant-select .ant-select-selection .ant-select-selection-selected-value {
            font-size: 12px; }
          .dispute-modal .dispute-form .dispute-reason .ant-select .ant-select-selection .ant-select-selection__placeholder,
          .dispute-modal .dispute-form .dispute-reason .ant-select .ant-select-selection .ant-select-search__field__placeholder {
            color: #898989;
            font-size: 12px; }
      .dispute-modal .dispute-form .dispute-reason textarea.ant-input {
        border: none;
        background: #f5f5f5;
        color: #898989;
        font-size: 12px;
        border-radius: 0; }
      .dispute-modal .dispute-form .dispute-reason .ant-upload {
        width: 100%;
        border-radius: 0; }
        .dispute-modal .dispute-form .dispute-reason .ant-upload .ant-btn {
          box-shadow: unset;
          border-radius: 0;
          font-size: 12px;
          color: #898989;
          background-color: #f5f5f5;
          border: none;
          width: 100%; }
  .dispute-modal .ant-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: none; }
    .dispute-modal .ant-modal-footer :first-of-type button {
      height: 40px;
      width: 176px;
      border-radius: 2px; }
    .dispute-modal .ant-modal-footer :last-of-type button {
      height: 40px;
      width: 176px;
      border-radius: 2px; }
  .dispute-modal .fields {
    width: 100%; }
  .dispute-modal .collapse_infromation {
    margin-top: 24px; }
    .dispute-modal .collapse_infromation .important_guide_txt {
      font-size: 14px;
      margin-top: 16px;
      margin-bottom: -8px;
      color: #212121;
      font-weight: 500;
      letter-spacing: 0.032em;
      line-height: 16px;
      margin-left: 16px;
      display: flex;
      align-items: center; }
    .dispute-modal .collapse_infromation .ant-collapse
> .ant-collapse-item
> .ant-collapse-header
.ant-collapse-arrow {
      left: auto !important;
      right: 16px !important;
      margin-top: 16px; }
    .dispute-modal .collapse_infromation .following_instructions {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #44433d;
      margin-left: -24px; }
    .dispute-modal .collapse_infromation .ant-collapse {
      background-color: #fffbe6 !important;
      border: 1px solid #ffe58f !important; }
    .dispute-modal .collapse_infromation .panel-content .left-content .note_txt {
      font-size: 12px;
      letter-spacing: 0.032e;
      letter-spacing: 0.032em;
      font-style: italic;
      margin-right: 8px;
      color: #212121;
      font-weight: 500; }
    .dispute-modal .collapse_infromation .panel-content .left-content .vfulfil_txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      font-style: italic;
      margin-right: 8px; }
    .dispute-modal .collapse_infromation .panel-content .upload_instructions {
      font-size: 12px;
      text-decoration: none;
      list-style-type: none;
      letter-spacing: 0.03em;
      margin-left: -32px;
      color: #212121; }
      .dispute-modal .collapse_infromation .panel-content .upload_instructions li {
        margin-bottom: 4px;
        font-size: 12px;
        letter-spacing: 0.032em; }
    .dispute-modal .collapse_infromation .panel-content .uploD_txt {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121;
      margin-bottom: 8px; }
    .dispute-modal .collapse_infromation .book_img img {
      height: 22px;
      margin-right: 8px; }
    .dispute-modal .collapse_infromation .ant-collapse-content {
      border-top: 1px solid #fffce6 !important; }
    .dispute-modal .collapse_infromation .ant-collapse-content > .ant-collapse-content-box {
      padding: 16px;
      background: #fffef9 !important; }
  .dispute-modal .star {
    color: #e61047; }

li.ant-select-dropdown-menu-item.dispute-option {
  font-size: 12px;
  font-weight: 500; }

.op {
  border-bottom: 1px solid #cecece;
  color: #898989;
  font-size: 12px;
  font-weight: 400; }

.op[aria-selected="true"] {
  color: #212121;
  font-weight: 500;
  border-bottom: 1px solid #6fc1ff; }

.ant-select .ant-select-selection {
  box-shadow: none; }

.review-line-item .ant-modal-content {
  padding: 10px 10px;
  border-radius: 3px; }
  .review-line-item .ant-modal-content .ant-modal-header {
    padding: 16px 37px; }

.review-line-item .order_vfid span {
  color: #898989; }

.review-line-item .modal-title {
  display: flex;
  justify-content: space-between; }
  .review-line-item .modal-title p {
    margin-bottom: 0px;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 22px; }

.review-line-item .blue-text {
  color: #0068ff; }

.review-line-item .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 16px;
  line-height: 16px;
  letter-spacing: 0.032em; }
  .review-line-item .modal-header p {
    font-size: 12px;
    color: black;
    font-weight: 500;
    margin-bottom: 0px; }

.review-line-item .line-items-tab {
  padding: 16px 8px 8px 16px;
  background: #fff; }
  .review-line-item .line-items-tab .line-item {
    background: #f2f2f2;
    margin-bottom: 10px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent; }
    .review-line-item .line-items-tab .line-item .ant-checkbox-wrapper {
      transform: scale(1.15); }
    .review-line-item .line-items-tab .line-item .ant-input {
      width: 80px; }
    .review-line-item .line-items-tab .line-item .ant-checkbox .ant-checkbox-inner {
      border: none;
      width: 14px;
      height: 14px; }
    .review-line-item .line-items-tab .line-item .quantity-field {
      max-width: 65px;
      margin-right: 5px; }
    .review-line-item .line-items-tab .line-item .anticon {
      font-size: 22px; }
    .review-line-item .line-items-tab .line-item .line-item-product-detail {
      display: flex;
      align-items: center; }
      .review-line-item .line-items-tab .line-item .line-item-product-detail .mr {
        margin-right: 8px; }
    .review-line-item .line-items-tab .line-item .line-item-img {
      width: 48px;
      height: 48px;
      margin-right: 0px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important; }
    .review-line-item .line-items-tab .line-item .line-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px; }
    .review-line-item .line-items-tab .line-item .line-item-sku {
      font-size: 12px;
      color: #000;
      font-weight: 400;
      margin: 0; }
  .review-line-item .line-items-tab .line-item.selected {
    background: #eeffe5;
    border: 1px solid #73d13d; }
  .review-line-item .line-items-tab .edit-quantity-btn {
    background: transparent;
    border: 1px solid #ff005e;
    border-radius: 4px;
    color: #ff005e; }
  .review-line-item .line-items-tab .edit-quantity-btn:hover {
    background: #ff005e;
    border: 1px solid #ff005e;
    box-shadow: 0 1px 2px rgba(255, 0, 94, 0.4);
    color: white; }

.review-line-item .shipments-tab {
  padding: 12px 13px;
  background: #fff; }
  .review-line-item .shipments-tab .no-shipment {
    text-align: center; }
  .review-line-item .shipments-tab .shipment-header {
    background: #f2f2f2;
    padding: 28px 20px;
    margin-bottom: 5px;
    color: black; }
  .review-line-item .shipments-tab .shipment-items {
    background: #eff5ff;
    padding: 5px 10px; }
  .review-line-item .shipments-tab .shipment-item {
    background: transparent;
    padding: 16px 8px;
    display: flex;
    align-items: center; }
    .review-line-item .shipments-tab .shipment-item .ant-checkbox-wrapper {
      transform: scale(1.15); }
    .review-line-item .shipments-tab .shipment-item .ant-checkbox .ant-checkbox-inner {
      border: none; }
    .review-line-item .shipments-tab .shipment-item .anticon {
      font-size: 22px; }
    .review-line-item .shipments-tab .shipment-item .shipment-item-product-detail {
      display: flex;
      align-items: center; }
    .review-line-item .shipments-tab .shipment-item .shipment-item-img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important; }
    .review-line-item .shipments-tab .shipment-item .shipment-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 15px; }
    .review-line-item .shipments-tab .shipment-item .shipment-item-sku {
      font-size: 14px;
      color: #676666;
      font-weight: 400;
      margin: 0;
      margin-top: -2px; }
  .review-line-item .shipments-tab .shipment-item:nth-of-type(2n) {
    background: white; }

.review-line-item .quantity {
  width: 250px; }

.dispute-raised-modal .dispute-raised-header {
  width: 100%;
  text-align: center; }
  .dispute-raised-modal .dispute-raised-header .di {
    width: 88%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center; }
    .dispute-raised-modal .dispute-raised-header .di svg {
      font-size: 12px;
      margin-right: 10px; }
    .dispute-raised-modal .dispute-raised-header .di h6 {
      margin: 0;
      padding: 0; }
  .dispute-raised-modal .dispute-raised-header p {
    font-size: 12px;
    color: #898989;
    line-height: 15px;
    text-align: left;
    width: 86%;
    margin: auto; }
  .dispute-raised-modal .dispute-raised-header h6 {
    font-size: 16px;
    color: black;
    font-weight: 500; }
  .dispute-raised-modal .dispute-raised-header .anticon {
    font-size: 76px;
    color: #00cc00;
    margin-bottom: 15px; }
  .dispute-raised-modal .dispute-raised-header .ant-btn {
    background: #00cc00;
    color: white;
    border: none;
    box-shadow: none;
    width: 264px;
    height: 40px; }

.dispute-raised-modal .ant-modal-body {
  padding: 20px 10px 30px; }

.dispute-failed-modal .di {
  height: 60px;
  display: flex;
  justify-content: left;
  align-items: center; }
  .dispute-failed-modal .di svg {
    font-size: 12px;
    margin-right: 15px;
    fill: red;
    color: red; }
  .dispute-failed-modal .di h6 {
    margin: 0;
    padding: 0; }

.dispute-failed-modal .dispute-raised-header {
  width: 102%;
  text-align: center; }
  .dispute-failed-modal .dispute-raised-header p {
    font-size: 12px;
    color: #898989;
    line-height: 16px;
    text-align: left; }
  .dispute-failed-modal .dispute-raised-header h6 {
    font-size: 16px;
    color: black;
    font-weight: 500; }
  .dispute-failed-modal .dispute-raised-header .anticon {
    font-size: 76px;
    color: #e20101;
    margin-bottom: 15px; }
  .dispute-failed-modal .dispute-raised-header .ant-btn {
    border: none;
    box-shadow: none;
    width: 118px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 15px; }
  .dispute-failed-modal .dispute-raised-header a {
    color: #919191;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none; }

.dispute-failed-modal .ant-modal-body {
  padding: 25px 20px 30px 35px; }

.dispute-failed-modal .but {
  margin-top: 20px; }

.Shipment_order_data .shipmentnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .Shipment_order_data .shipmentnav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .Shipment_order_data .shipmentnav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .Shipment_order_data .shipmentnav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .Shipment_order_data .shipmentnav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .Shipment_order_data .shipmentnav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .Shipment_order_data .shipmentnav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .Shipment_order_data .shipmentnav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .Shipment_order_data .shipmentnav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .Shipment_order_data .shipmentnav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .Shipment_order_data .shipmentnav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .Shipment_order_data .shipmentnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .Shipment_order_data .shipmentnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .Shipment_order_data .shipmentnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 11px;
        color: #898989; }
        .Shipment_order_data .shipmentnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .Shipment_order_data .shipmentnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 10px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .Shipment_order_data .shipmentnav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .Shipment_order_data .shipmentnav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1268fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1268fb; }
      .Shipment_order_data .shipmentnav .secondwrap .filtertab .hover-shipment-underline-animation {
        position: relative; }
      .Shipment_order_data .shipmentnav .secondwrap .filtertab .hover-shipment-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .Shipment_order_data .shipmentnav .secondwrap .filtertab .hover-shipment-underline-animation:hover {
        color: #4398ed !important; }
        .Shipment_order_data .shipmentnav .secondwrap .filtertab .hover-shipment-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .Shipment_order_data .shipmentnav .secondwrap .filtertab .hover-shipment-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .Shipment_order_data .shipmentnav .secondwrap .filtertab .hover-shipment-underline-animation-click {
        position: relative; }
      .Shipment_order_data .shipmentnav .secondwrap .filtertab .hover-shipment-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .Shipment_order_data .shipmentnav .secondwrap .download_csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .Shipment_order_data .shipmentnav .ant-input {
    width: 82%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .Shipment_order_data .shipmentnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .Shipment_order_data .shipmentnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .Shipment_order_data .shipmentnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .Shipment_order_data .shipmentnav .ant-calendar-range-picker-input {
    width: 36% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .Shipment_order_data .shipmentnav .ant-calendar-picker-clear,
  .Shipment_order_data .shipmentnav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.Shipment_order_data .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 90px; }

.Shipment_order_data .ant-select-selection__placeholder {
  color: #898989; }

.Shipment_order_data .shipment-order-filter .ant-select-arrow {
  left: 164% !important; }

.Shipment_order_data .Globalsearch {
  position: relative; }
  .Shipment_order_data .Globalsearch .import-product-search {
    width: 300px !important; }
  .Shipment_order_data .Globalsearch .ant-input-group {
    width: 300px !important; }
    .Shipment_order_data .Globalsearch .ant-input-group button {
      width: 100px !important; }
  .Shipment_order_data .Globalsearch .search-click {
    border-radius: 0px !important; }
  .Shipment_order_data .Globalsearch .ant-btn {
    position: absolute;
    top: 0px;
    right: -31px;
    width: 20px;
    height: 40px;
    background-color: #cf3341;
    border: 0; }
    .Shipment_order_data .Globalsearch .ant-btn svg {
      transition: all 0.5s;
      fill: white; }
      .Shipment_order_data .Globalsearch .ant-btn svg path {
        stroke: white; }
  .Shipment_order_data .Globalsearch .ant-btn:hover {
    border-color: transparent; }
  .Shipment_order_data .Globalsearch .ant-btn:hover svg {
    transition: all  0.5s;
    transform: rotateX(180deg); }
    .Shipment_order_data .Globalsearch .ant-btn:hover svg g {
      fill: white; }
    .Shipment_order_data .Globalsearch .ant-btn:hover svg path {
      stroke: white; }
  .Shipment_order_data .Globalsearch svg {
    position: relative;
    left: -9px; }
  .Shipment_order_data .Globalsearch .drop-down-bulk {
    position: absolute;
    right: 0;
    top: 0;
    width: 22px;
    height: 100%;
    border: 1px solid #770f0f;
    background-color: #770f0f;
    border-radius: 0 2px 2px 0; }
    .Shipment_order_data .Globalsearch .drop-down-bulk svg {
      position: relative;
      top: 8px;
      left: 2px; }
  .Shipment_order_data .Globalsearch .drop-down-bulk:hover .bulksearch {
    display: block; }
  .Shipment_order_data .Globalsearch .drop-down-bulk:hover
svg {
    transition: all  0.5s;
    transform: rotateX(180deg); }
    .Shipment_order_data .Globalsearch .drop-down-bulk:hover
svg g {
      fill: #898989; }
  .Shipment_order_data .Globalsearch .drop-down-bulk:hover svg {
    transition: all  0.5s;
    transform: rotateX(180deg); }
    .Shipment_order_data .Globalsearch .drop-down-bulk:hover svg g {
      fill: #898989; }
  .Shipment_order_data .Globalsearch .bulksearch {
    position: absolute;
    display: none;
    right: -1px;
    bottom: -51px;
    width: 112px;
    height: 50px; }
    .Shipment_order_data .Globalsearch .bulksearch .bulk-search-button {
      position: relative;
      height: 100%;
      width: 100%;
      margin-right: 16px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      background: #0068ff !important;
      font-weight: 500 !important;
      box-shadow: 2px 2px 8px  !important;
      border: none;
      color: #fff;
      z-index: 9999; }
    .Shipment_order_data .Globalsearch .bulksearch .bulk-search-button:hover {
      color: #fff;
      background-color: #0063f2 !important;
      border: 2px solid #0058d6 !important; }

@media screen and (min-width: 1440px) {
  .shipmentnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .shipmentnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.non-cursor-pointer {
  pointer-events: none; }

.bulk-search-menu {
  width: 132px;
  text-align: center; }

.my-disputes {
  letter-spacing: 0.3px !important; }
  .my-disputes .content-page-title {
    margin-bottom: 0px; }
  .my-disputes .page-subtitle {
    font-size: 16px;
    color: black;
    margin-bottom: 0px; }
    .my-disputes .page-subtitle span {
      font-size: 14px;
      color: black; }
  .my-disputes .dispute-filter {
    margin-bottom: 32px; }
    .my-disputes .dispute-filter .dispute-status {
      font-size: 14px;
      color: black;
      margin-bottom: 0px; }
    .my-disputes .dispute-filter .ant-btn {
      background: #1370fc;
      border: none;
      box-shadow: none;
      color: white;
      font-size: 12px;
      font-weight: 500;
      width: 160px;
      height: 40px;
      border-radius: 2px !important; }
    .my-disputes .dispute-filter .ant-select {
      width: auto !important; }
      .my-disputes .dispute-filter .ant-select .ant-select-selection {
        border: none;
        box-shadow: none;
        color: #000000a6; }
  .my-disputes .drop {
    background-color: white !important;
    padding: 4.5px 5px 4.5px 17px;
    width: 290px;
    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important; }
    .my-disputes .drop .ant-select-selection__rendered {
      margin-left: 8px;
      background-color: white !important;
      margin-right: 2px;
      height: 100% !important; }
      .my-disputes .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important; }
    .my-disputes .drop p {
      font-size: 12px !important;
      line-height: 16px !important;
      color: #898989 !important; }
    .my-disputes .drop .ant-select-selection-selected-value {
      font-size: 13px;
      line-height: 16px;
      color: #1268fb; }
    .my-disputes .drop .sel .ant-select-selection {
      width: 159px; }
      .my-disputes .drop .sel .ant-select-selection .ant-select-selection-selected-value {
        line-height: 30px; }
    .my-disputes .drop .sel .ant-select-arrow {
      top: 53%; }
  .my-disputes .skeleton {
    margin-bottom: 30px; }
  .my-disputes .empty-card {
    margin-bottom: 20px;
    text-align: center; }
  .my-disputes .no-escalations-found-txt {
    margin: 0; }

.import-product .placeholder ::placeholder {
  font-size: 12px; }

.import-product .ant-input-group .ant-input {
  height: 40px;
  box-shadow: 0px 2px 8px #00000014;
  border-radius: 2px 0 0 2px;
  border: 0px;
  background: white !important; }

.pick {
  color: #1268fb !important;
  background-color: white !important;
  margin-left: 8px;
  font-size: 12px !important;
  box-shadow: 0px 2px 8px #00000014; }
  .pick :focus {
    outline: none !important;
    border: none !important; }
  .pick :active {
    outline: none !important;
    border: none !important; }
  .pick .ant-calendar-picker-input {
    color: #1268fb !important;
    background: none;
    border: none;
    outline: none;
    font-size: 12px !important;
    padding-left: 0; }
    .pick .ant-calendar-picker-input :focus {
      outline: none !important;
      border: none !important; }
    .pick .ant-calendar-picker-input :active {
      outline: none !important;
      border: none !important; }
  .pick .ant-calendar-range-picker-separator {
    color: #1268fb !important;
    vertical-align: baseline; }

.dispute-card {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 20px; }
  .dispute-card .upper-section {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin-top: 24px; }
    .dispute-card .upper-section .vf {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px; }
      .dispute-card .upper-section .vf p {
        padding: 4px 10px;
        font-size: 12px;
        font-weight: 500; }
  .dispute-card .vforderid_tag {
    padding: 5px;
    padding-left: 15px;
    position: absolute;
    left: -10px;
    top: 8px;
    background: #ed7741;
    color: white;
    border-radius: 2px;
    border-bottom-left-radius: 0; }
  .dispute-card .vforderid_tag:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: 100%;
    left: 0px;
    border-bottom: 9px solid #ed7741;
    border-left: 9px solid transparent;
    transform: rotate(270deg); }
  .dispute-card .ant-card-body {
    padding: 0px; }
    .dispute-card .ant-card-body .blue-text {
      color: #0068ff; }
    .dispute-card .ant-card-body .header-row {
      display: flex;
      align-items: center;
      padding: 46px 52px 20px 52px; }
      .dispute-card .ant-card-body .header-row h3 {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 400; }
      .dispute-card .ant-card-body .header-row .dispute-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        min-width: 66px; }
        .dispute-card .ant-card-body .header-row .dispute-title .anticon {
          font-size: 17px;
          margin-right: 10px; }
      .dispute-card .ant-card-body .header-row .dispute-status {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 36px; }
    .dispute-card .ant-card-body .circle-ican {
      font-size: 10px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: "white";
      padding: 4px 8px;
      padding-right: 5px; }
    .dispute-card .ant-card-body .circle-ican-status {
      font-size: 12px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 4px 10px;
      padding-left: 0;
      font-weight: 500; }
    .dispute-card .ant-card-body .tabs-row {
      background: #16273b;
      padding: 0 24px;
      display: flex;
      align-items: center; }
      .dispute-card .ant-card-body .tabs-row ul {
        list-style-type: none;
        margin: 0;
        padding: 0; }
        .dispute-card .ant-card-body .tabs-row ul li {
          display: inline-block;
          padding: 12px 15px;
          color: white;
          cursor: pointer;
          line-height: 20px;
          letter-spacing: 0.032em; }
        .dispute-card .ant-card-body .tabs-row ul li.active,
        .dispute-card .ant-card-body .tabs-row ul li:hover {
          background: #244060; }
    .dispute-card .ant-card-body .tab-container {
      background: #f2f2f2;
      padding: 0 24px 24px 24px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px; }
      .dispute-card .ant-card-body .tab-container .dispute-info-tab {
        background: white;
        padding: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px; }
        .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px; }
          .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-info p {
            font-size: 14px;
            font-weight: 500;
            color: black;
            margin-bottom: 0px; }
            .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-info p span {
              color: #5a5a5a;
              font-weight: 400; }
        .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-reason {
          width: 50%;
          margin-right: 8px; }
          .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-reason h6 {
            font-size: 12px;
            line-height: 16px;
            color: #898989;
            margin-bottom: 4px;
            font-weight: 400; }
          .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-reason p {
            font-size: 12px;
            padding: 8px 16px;
            background: #f5f5f5;
            color: #252525; }
      .dispute-card .ant-card-body .tab-container .line-items-tab {
        padding: 16px 13px 7px 13px;
        background: #fff; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .line-item {
          background: #f2f2f2;
          margin-bottom: 10px;
          padding: 14px 12px;
          display: flex;
          align-items: center;
          border: 1px solid transparent; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .anticon {
          font-size: 17px; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-product-detail {
          display: flex;
          align-items: center; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          display: inline-block;
          background-size: cover !important;
          background-position: center !important;
          border: 1px solid #c8c8c8; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
          font-size: 12px; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-sku {
          font-size: 12px;
          color: #676666;
          font-weight: 400;
          margin: 0;
          margin-top: -2px; }
          .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-sku b {
            color: #898989;
            font-weight: 400; }
          .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-sku .detailcommon {
            color: #000;
            letter-spacing: 0.032em;
            font-size: 12px;
            line-height: 16px; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .delivered-date {
          margin-bottom: 0px;
          text-decoration: none; }
      .dispute-card .ant-card-body .tab-container .media-attachments {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px; }
        .dispute-card .ant-card-body .tab-container .media-attachments .image-div {
          margin-top: 10px;
          margin-right: 10px;
          border: 1px solid #898989; }
          .dispute-card .ant-card-body .tab-container .media-attachments .image-div img {
            width: 100px;
            height: 100px;
            cursor: pointer;
            padding: 3px; }
        .dispute-card .ant-card-body .tab-container .media-attachments .download-image-data {
          position: absolute;
          bottom: 25px;
          padding: 6px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 93px; }
  .dispute-card .flex {
    display: flex;
    justify-content: space-between; }
    .dispute-card .flex .flex-note-log {
      width: 44%;
      color: #000000a6; }
    .dispute-card .flex .flex-date {
      width: 30%;
      display: flex;
      align-items: center; }
      .dispute-card .flex .flex-date .circle {
        margin-right: 8px; }
      .dispute-card .flex .flex-date .font-weight {
        font-weight: 600;
        color: #212121; }
      .dispute-card .flex .flex-date span {
        color: #212121; }
      .dispute-card .flex .flex-date .line {
        margin-left: 8px;
        margin-right: 8px; }
    .dispute-card .flex .flex-addedby {
      width: 13%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #000000a6; }
  .dispute-card .fields {
    display: flex;
    width: 94%;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px; }
    .dispute-card .fields .field {
      flex: 1 1;
      margin-right: 24px; }
      .dispute-card .fields .field .field-label {
        font-size: 12px;
        line-height: 16px;
        color: #898989;
        margin-bottom: 4px; }
      .dispute-card .fields .field .field-value {
        font-size: 12px;
        padding: 8px 16px;
        background: #f5f5f5;
        color: black;
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        .dispute-card .fields .field .field-value svg {
          margin-right: 12px; }
    .dispute-card .fields .field:last-of-type {
      margin-right: 0; }
  .dispute-card .flex-5 {
    flex: 4 1; }
  .dispute-card .flex-6 {
    flex: 3 1; }
  .dispute-card .flex-7 {
    flex: 2 1;
    font-size: 12px; }
  .dispute-card .flex-8 {
    flex: 1 1; }
  .dispute-card .note-logs {
    padding: 8px 16px;
    background: #f5f5f5;
    margin-bottom: 8px; }
  .dispute-card .notes-logs-txt {
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989; }
  .dispute-card .notes-logs-data {
    background: white;
    padding: 12px 16px;
    font-size: 12px; }
    .dispute-card .notes-logs-data .note {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      color: #212121;
      font-style: italic;
      font-size: 12px; }
  .dispute-card .tab-count {
    display: inline-block;
    background: #ff9400;
    width: 20px;
    height: 20px;
    border-radius: 16px;
    margin-left: 12px;
    text-align: center;
    font-size: 12px;
    line-height: 20px; }
  .dispute-card .upper_data {
    display: flex;
    justify-content: space-between; }
  .dispute-card .no-found {
    padding: 16px;
    text-align: center;
    background: white;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 20px;
    color: "#000";
    font-weight: 500; }
  .dispute-card .video-data {
    width: 100px;
    height: 100px;
    cursor: pointer; }
  .dispute-card .shipment-status {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .dispute-card .returned-on {
    margin-left: -16px; }
  .dispute-card .delivered-on {
    margin-left: -16px; }
  .dispute-card .delivered {
    margin-left: -38px; }
  .dispute-card .returned {
    margin-left: -38px; }
  .dispute-card .shipment-status {
    margin-left: 24px; }
  .dispute-card .header-varaint-status {
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989;
    font-size: 12px; }
  .dispute-card .delivered-on-date {
    color: #73d13d;
    margin-bottom: 0;
    font-size: 12px;
    text-decoration: underline;
    display: flex;
    justify-content: left; }
  .dispute-card .row-ul li {
    font-size: 12px; }
  .dispute-card .escalation-status-wraper {
    background-color: #f7f7f7;
    height: 112px;
    margin-bottom: 12px;
    display: flex;
    align-items: center; }
    .dispute-card .escalation-status-wraper .progress-line {
      position: relative;
      margin: 0 auto;
      bottom: 20px;
      border-radius: 10px;
      width: 60%;
      background: #cecece;
      height: 8px;
      z-index: 90;
      display: flex;
      justify-content: space-between; }
      .dispute-card .escalation-status-wraper .progress-line .progress {
        transition: all 1s linear;
        position: absolute;
        width: 0;
        height: 100%;
        background: #2bc822; }
    .dispute-card .escalation-status-wraper .status {
      position: relative;
      top: -4px;
      left: -1px; }
      .dispute-card .escalation-status-wraper .status .dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #cecece;
        cursor: pointer; }
        .dispute-card .escalation-status-wraper .status .dot .current {
          background: white;
          border: 5px solid orange; }
        .dispute-card .escalation-status-wraper .status .dot.completed {
          position: relative;
          background: #2bc822;
          border: 5px solid #2bc822; }
      .dispute-card .escalation-status-wraper .status .dot.dot-active {
        outline: 4px solid #b8f0b3;
        animation: pulse-black 2s infinite; }
      .dispute-card .escalation-status-wraper .status .time-and-status-wraper {
        position: relative;
        display: flex;
        min-width: 75px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        right: 28px; }
        .dispute-card .escalation-status-wraper .status .time-and-status-wraper .date {
          margin-top: 2px;
          color: #212121; }
      .dispute-card .escalation-status-wraper .status .shipment_step_color {
        color: #2bc822;
        font-weight: 500;
        font-size: 12px;
        margin-top: 7px; }
      .dispute-card .escalation-status-wraper .status .original_color {
        color: #cecece;
        font-size: 12px;
        margin-top: 7px; }
    .dispute-card .escalation-status-wraper .status:nth-of-type(4) .time-and-status-wraper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      right: -30px; }
  .dispute-card .logs-wraper {
    max-height: 160px;
    overflow-y: scroll; }
    .dispute-card .logs-wraper .status-logs {
      height: 48px !important;
      padding: 0px 16px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5fff4; }
      .dispute-card .logs-wraper .status-logs .flex-date .circle {
        height: 16px;
        width: 16px;
        background-color: #212121;
        border-radius: 50%;
        position: relative; }
      .dispute-card .logs-wraper .status-logs .flex-date .circle.single-circle::after {
        content: "";
        height: 0;
        width: 0; }
      .dispute-card .logs-wraper .status-logs .flex-date .circle::after {
        content: "";
        height: 40px;
        width: 2px;
        background-color: #212121;
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translateX(-50%); }
    .dispute-card .logs-wraper .status-logs:last-of-type .circle::after {
      content: "";
      height: 0px;
      width: 0px; }
  .dispute-card .logs-wraper::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #898989; }
  .dispute-card .logs-wraper::-webkit-scrollbar-thumb {
    background-color: #898989; }
  .dispute-card .single-log::-webkit-scrollbar {
    width: 8px;
    border: 0px solid #ffffff; }
  .dispute-card .single-log::-webkit-scrollbar-thumb {
    background-color: #ffffff; }
  .dispute-card .cancelEscalation_status {
    display: flex; }
    .dispute-card .cancelEscalation_status .cancel_esc {
      margin-right: 16px; }

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #2ac82260; }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

@keyframes load-25 {
  0% {
    width: 0; }
  100% {
    width: 20%; } }

@keyframes load-50 {
  0% {
    width: 0; }
  100% {
    width: 54%; } }

@keyframes load-100 {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.add-dispute-modal {
  width: 672px !important; }
  .add-dispute-modal .ant-select-dropdown-menu-item-selected {
    color: red; }
  .add-dispute-modal .ant-modal-content {
    border-radius: 2px !important; }
  .add-dispute-modal .ant-modal-header {
    width: 90%;
    margin: auto;
    padding-left: 0;
    padding-bottom: 8px;
    padding-top: 30px; }
  .add-dispute-modal .ant-modal-body {
    padding: 0px 24px 6px 24px; }
    .add-dispute-modal .ant-modal-body .dispute-form {
      margin-top: 7px;
      width: 97%;
      margin: auto; }
      .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason {
        margin-top: 14px; }
        .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason p {
          font-size: 12px;
          color: #898989;
          margin-bottom: 5px; }
        .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-select {
          width: 100% !important; }
          .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-select .ant-select-selection {
            border-radius: 2px;
            font-size: 12px;
            border: none;
            background: #eaeaea;
            box-shadow: none; }
            .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-select .ant-select-selection .ant-select-selection__placeholder,
            .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-select .ant-select-selection .ant-select-search__field__placeholder {
              color: #acacac;
              font-size: 12px; }
        .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-input {
          border-radius: 2px;
          font-size: 12px;
          border: none;
          background: #eaeaea;
          color: #5a5a5a; }
          .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-input ::placeholder {
            font-size: 12px; }
  .add-dispute-modal .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px; }
    .add-dispute-modal .ant-modal-footer button {
      font-size: 12px;
      height: 40px;
      width: 176px;
      border-radius: 2px; }

.op {
  border-bottom: 1px solid #cecece;
  color: #898989;
  font-size: 12px;
  font-weight: 400; }

.op[aria-selected="true"] {
  color: #212121;
  font-weight: 500;
  border-bottom: 1px solid #6fc1ff; }

.image-modal .ant-modal-body {
  min-height: 400px; }
  .image-modal .ant-modal-body .img-wraper {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 400px; }
    .image-modal .ant-modal-body .img-wraper .image-size {
      width: 100%;
      height: 100%;
      position: relative; }
    .image-modal .ant-modal-body .img-wraper .video-size {
      width: 100%;
      height: 100%;
      position: relative; }

.image-modal .ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 29px; }

.image-modal .image-arrow {
  position: absolute;
  top: 50%;
  z-index: 99;
  background: transparent;
  padding: 0px;
  height: 40px;
  width: 40px;
  border: none;
  box-shadow: none; }
  .image-modal .image-arrow .anticon {
    font-size: 40px; }

.picing-request-nav .request-nav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .picing-request-nav .request-nav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .picing-request-nav .request-nav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .picing-request-nav .request-nav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .picing-request-nav .request-nav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .picing-request-nav .request-nav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .picing-request-nav .request-nav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .picing-request-nav .request-nav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .picing-request-nav .request-nav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .picing-request-nav .request-nav .ordfillwrap .right .show-data-from {
        font-size: 12px;
        margin-left: 8px; }
      .picing-request-nav .request-nav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .picing-request-nav .request-nav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .picing-request-nav .request-nav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .picing-request-nav .request-nav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .picing-request-nav .request-nav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .picing-request-nav .request-nav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .picing-request-nav .request-nav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .picing-request-nav .request-nav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1268fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1268fb; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-escalations-underline-animation {
        position: relative; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-escalations-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-escalations-underline-animation:hover {
        color: #4398ed !important; }
        .picing-request-nav .request-nav .secondwrap .filtertab .hover-escalations-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-escalations-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-escalations-underline-animation-click {
        position: relative; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-escalations-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .picing-request-nav .request-nav .secondwrap .download-csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .picing-request-nav .request-nav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .picing-request-nav .request-nav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .picing-request-nav .request-nav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .picing-request-nav .request-nav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .picing-request-nav .request-nav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .picing-request-nav .request-nav .ant-calendar-picker-clear,
  .picing-request-nav .request-nav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.picing-request-nav .bottom-data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 90px;
  margin-bottom: 32px; }

.picing-request-nav .ant-select-selection__placeholder {
  color: #898989; }

.picing-request-nav .left {
  margin-left: "7px";
  color: #000; }
  .picing-request-nav .left .content-page-title {
    display: block;
    font-size: 12px;
    margin-bottom: 12px;
    font-weight: 400; }
    .picing-request-nav .left .content-page-title .escalations-txt {
      text-decoration: underline; }

.picing-request-nav .range-picker {
  margin-left: 10px;
  max-width: 300px; }

@media screen and (min-width: 1440px) {
  .request-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .request-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.ndr-page .bucket-filters-wraper {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.ndr-page .custom-arrow-icon {
  pointer-events: none !important; }

.ndr-page .ndr-header-row {
  padding: 0 0 20px 0; }
  .ndr-page .ndr-header-row .content-page-title {
    margin-bottom: 20px; }
  .ndr-page .ndr-header-row .page-subtitle {
    font-size: 16px;
    color: black;
    margin-bottom: 0px; }
    .ndr-page .ndr-header-row .page-subtitle span {
      font-size: 14px;
      color: black; }

.ndr-page .tabs-row .ant-tabs-bar {
  padding: 10px 40px 0px 40px;
  margin-bottom: 0px; }

.ndr-page .tabs-row .ant-tabs-tabpane {
  padding: 40px; }

.ndr-page .pick {
  color: #1268fb !important;
  background-color: white !important;
  margin-left: 8px;
  font-size: 12px !important;
  box-shadow: 0px 2px 8px #00000014; }

.ndr-page .ant-row {
  font-size: 14px; }
  .ndr-page .ant-row .content-page-title {
    font-size: 12px;
    text-decoration: underline; }

.ndr-page .select-all-wraper {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .ndr-page .select-all-wraper * {
    letter-spacing: 0.032em; }
  .ndr-page .select-all-wraper .select-all {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #898989;
    font-size: 12px; }
    .ndr-page .select-all-wraper .select-all .ant-checkbox-wrapper {
      display: flex;
      align-items: flex-end; }
      .ndr-page .select-all-wraper .select-all .ant-checkbox-wrapper span {
        font-size: 12px;
        color: #898989; }
    .ndr-page .select-all-wraper .select-all .divider {
      margin-right: 8px; }
  .ndr-page .select-all-wraper p {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #898989;
    position: relative; }
    .ndr-page .select-all-wraper p button {
      margin-left: 8px !important;
      font-weight: 500 !important;
      padding-right: 40px; }
      .ndr-page .select-all-wraper p button span {
        font-weight: 500 !important; }
    .ndr-page .select-all-wraper p .disabled {
      opacity: 0.5; }
    .ndr-page .select-all-wraper p .white-arrow {
      pointer-events: none;
      position: absolute;
      top: 50%;
      transform: translateY(-45%);
      right: 16px;
      z-index: 9;
      transform: translateY(-50%) rotateX(0);
      transition: all 0.3s ease 0s; }
      .ndr-page .select-all-wraper p .white-arrow svg {
        font-size: 16px; }
        .ndr-page .select-all-wraper p .white-arrow svg g path:last-of-type {
          fill: #fff; }
    .ndr-page .select-all-wraper p .white-arrow.open {
      transition: all 0.35s ease 0s;
      transform: translateY(-50%) rotate(180deg); }
      .ndr-page .select-all-wraper p .white-arrow.open svg g {
        fill: #fff; }

.bucket-drodown-overlay .ant-dropdown-menu {
  background-color: #f8f8f8;
  border-radius: 2px; }

.bucket-drodown-overlay .ant-dropdown-menu-item {
  height: 40px;
  display: flex;
  align-items: center;
  width: 164px !important; }

.bucket-drodown-overlay .ant-dropdown-menu-item:nth-of-type(1) {
  border-bottom: 1px solid #cecece; }

.bucket-drodown-overlay .ant-dropdown-menu-item:hover {
  border-color: #b5dafe !important; }

.bucket-drodown-overlay .flex {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .bucket-drodown-overlay .flex h3 {
    font-size: 12px;
    color: #898989; }

.bulk-action-modal {
  width: 420px !important; }
  .bulk-action-modal .ant-modal-close-x {
    display: none; }
  .bulk-action-modal * {
    letter-spacing: 0.032em; }
  .bulk-action-modal .ant-modal-body {
    padding: 30px 16px 32px 16px; }
    .bulk-action-modal .ant-modal-body .heading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 22px; }
      .bulk-action-modal .ant-modal-body .heading p {
        margin-left: 8px;
        font-size: 16px;
        color: #212121;
        font-weight: 500; }
      .bulk-action-modal .ant-modal-body .heading svg {
        font-size: 24px; }
    .bulk-action-modal .ant-modal-body .picker {
      margin-top: 12px; }
    .bulk-action-modal .ant-modal-body .content-data {
      margin-bottom: 26px; }
      .bulk-action-modal .ant-modal-body .content-data p {
        font-size: 12px;
        color: #898989;
        margin-bottom: 8px;
        text-align: center; }
      .bulk-action-modal .ant-modal-body .content-data h3 {
        margin: 0;
        padding: 0;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: #212121; }
    .bulk-action-modal .ant-modal-body .custom-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 8px;
      gap: 8px; }
    .bulk-action-modal .ant-modal-body .ant-btn-ghost {
      width: 154px;
      height: 40px;
      border-color: #898989;
      font-size: 16px; }
    .bulk-action-modal .ant-modal-body .ant-btn-ghost:hover {
      border-color: #2986ff; }
    .bulk-action-modal .ant-modal-body .ant-btn-primary {
      width: 184px;
      font-size: 16px;
      height: 40px;
      background-color: #2bc822;
      border: #2bc822; }
  .bulk-action-modal .ant-modal-footer {
    display: none !important; }
  .bulk-action-modal .input-label {
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: #000; }
  .bulk-action-modal .no-top-margin {
    margin-top: 0rem; }
  .bulk-action-modal input,
  .bulk-action-modal textarea {
    border: 1.5px solid #979797;
    border-radius: 2px;
    font-size: 12px; }
  .bulk-action-modal .ant-form-item-control {
    position: relative;
    line-height: 32px;
    zoom: 1;
    font-size: 12px; }
  .bulk-action-modal .disabled-Btn {
    opacity: 0.5 !important;
    color: #fff; }
    .bulk-action-modal .disabled-Btn span {
      color: #fff; }
  .bulk-action-modal .ant-btn-primary {
    opacity: 1; }

.ndr-card {
  margin-bottom: 3%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-top: 10px;
  position: relative; }
  .ndr-card .vforderid_tag {
    width: 225px;
    height: 24px;
    margin-left: -32px; }
    .ndr-card .vforderid_tag .click-id-badge {
      min-width: 200px;
      max-width: 225px; }
  .ndr-card .bucket-badge {
    position: absolute;
    top: 8px;
    right: 32px;
    color: #fff;
    padding: 1px 6px;
    font-size: 12px;
    font-weight: 500 !important;
    border-radius: 5px;
    background-color: #3587ff; }
  .ndr-card .ant-dropdown-button.ant-btn-group
> .ant-btn:last-child:not(:first-child):not(:disabled) {
    background: #3587ff; }
  .ndr-card .ant-dropdown-menu-item-disabled,
  .ndr-card .ant-dropdown-menu-submenu-title-disabled {
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed !important;
    background: rgba(0, 0, 0, 0.15) !important; }
  .ndr-card .hidden {
    display: none; }
  .ndr-card .ant-card-body {
    padding: 0; }
  .ndr-card .blue-text {
    color: #0068ff; }
  .ndr-card .mr-3 {
    margin-right: 10px; }
  .ndr-card .header-row {
    display: flex;
    align-items: center;
    padding: 24px 32px 0px 32px; }
    .ndr-card .header-row .header-top-row {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .ndr-card .header-row .header-top-row p {
        color: #fff;
        border-bottom-left-radius: 0;
        font-size: 12px;
        width: 115px;
        text-align: center;
        height: 26px;
        margin-top: 0px;
        border-radius: 2px;
        letter-spacing: 0.032em;
        line-height: 16px; }
      .ndr-card .header-row .header-top-row .action_taken {
        border-radius: 2px;
        font-size: 12px;
        font-weight: 500;
        padding-left: 12px;
        padding-right: 12px;
        color: black;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: 24px;
        text-align: center;
        letter-spacing: 0.032em;
        align-items: center;
        line-height: 16px; }
  .ndr-card .upper_middle_data {
    display: flex;
    align-items: center;
    padding: 24px 32px 0px 32px; }
    .ndr-card .upper_middle_data .middle_row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      line-height: 16px;
      letter-spacing: 0.032em;
      font-size: 12px; }
      .ndr-card .upper_middle_data .middle_row .waybill_number {
        display: flex;
        align-items: center;
        justify-content: center; }
        .ndr-card .upper_middle_data .middle_row .waybill_number .ant-checkbox-wrapper {
          display: flex;
          align-items: flex-end; }
          .ndr-card .upper_middle_data .middle_row .waybill_number .ant-checkbox-wrapper .ant-checkbox-inner {
            background-color: #ededed; }
        .ndr-card .upper_middle_data .middle_row .waybill_number .ant-checkbox-wrapper-checked .ant-checkbox-inner {
          background-color: #36c626; }
        .ndr-card .upper_middle_data .middle_row .waybill_number p {
          color: #898989;
          margin-left: 16px; }
      .ndr-card .upper_middle_data .middle_row .order_no {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .ndr-card .upper_middle_data .middle_row .order_no .middledata {
          display: flex;
          justify-content: center;
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.032em; }
        .ndr-card .upper_middle_data .middle_row .order_no .shipment-date {
          display: flex;
          justify-content: center;
          margin-bottom: 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.032em; }
      .ndr-card .upper_middle_data .middle_row .ndr-action {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 36px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.032em; }
        .ndr-card .upper_middle_data .middle_row .ndr-action .span-button {
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 128px;
          border-radius: 2px;
          letter-spacing: 0.032em;
          line-height: 16px;
          font-weight: 500; }
        .ndr-card .upper_middle_data .middle_row .ndr-action .span-button2 {
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          width: 128px;
          border-radius: 2px;
          letter-spacing: 0.032em;
          line-height: 16px;
          font-weight: 500; }
  .ndr-card .tabs-row-card {
    background: #16273b;
    padding: 0 20px 0 28px;
    display: flex;
    align-items: center; }
    .ndr-card .tabs-row-card ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.032em;
      font-weight: 400; }
      .ndr-card .tabs-row-card ul li {
        display: inline-block;
        padding: 8px 24px;
        color: white;
        cursor: pointer; }
      .ndr-card .tabs-row-card ul li.active,
      .ndr-card .tabs-row-card ul li:hover {
        background: #244060; }
  .ndr-card .tab-container {
    background: #f2f2f2;
    padding: 0 27px 12px 27px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }
  .ndr-card .flex {
    margin-bottom: 5px;
    margin: 0 0px 8px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    font-size: 12px;
    color: #898989; }
    .ndr-card .flex .flex-description {
      width: 45%; }
    .ndr-card .flex .flex-added-on {
      width: 27%; }
    .ndr-card .flex .flex-addedby {
      width: 25%; }
  .ndr-card .flex-2 {
    width: 432px; }
  .ndr-card .flex-3 {
    width: 304px; }
  .ndr-card .flex-4 {
    width: 95px; }
  .ndr-card .ant-avatar {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px; }
  .ndr-card .thead {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    padding-left: 16px; }
    .ndr-card .thead .flex-description {
      width: 45%; }
    .ndr-card .thead .flex-added-on {
      width: 27%; }
    .ndr-card .thead .flex-addedby {
      width: 25%; }
  .ndr-card .anticon {
    font-size: 17px;
    margin-right: 10px; }
  .ndr-card .dropdownmenu4 {
    width: 128px;
    height: 32px;
    background: #1161fb;
    color: #fff;
    position: relative;
    border: none;
    border-radius: 2px; }
  .ndr-card .action-taken-drop {
    width: 136px;
    height: 32px;
    background: #229d1b;
    color: #fff;
    position: relative;
    border: 1px solid #229d1b;
    border-radius: 2px;
    padding: 0px 8px; }
  .ndr-card .action-taken-drop-btn .right {
    font-size: 8px;
    margin-right: 0px; }

.drop-menu ul {
  border-radius: 0px; }
  .drop-menu ul li {
    height: 40px;
    width: 176px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 14px; }
    .drop-menu ul li i {
      margin-right: 0px !important; }
    .drop-menu ul li svg {
      font-size: 16px;
      margin-left: 8px;
      opacity: 0;
      transition: opacity 0.3s linear; }
  .drop-menu ul li:hover {
    font-weight: 500; }
    .drop-menu ul li:hover svg {
      transition: opacity 0.4s linear;
      opacity: 1; }

.expired-tooltip {
  font-size: 12px; }

.dropdowndropmenustyle {
  width: 180px !important;
  border-radius: 2px !important; }
  .dropdowndropmenustyle ul li {
    margin: 0 !important;
    border-bottom: 1px solid #cecece !important;
    text-align: left !important;
    font-size: 12px !important; }

.dropdowndropmenustyle li:hover {
  background: #f5faff;
  color: #212121;
  border-bottom: 2px solid #b5dafe !important; }

.span-button2:hover {
  background: #c70c3c !important; }

.shipment-info-tab {
  padding: 18px 13px 7px 13px;
  background: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .shipment-info-tab .line-item {
    background: #f7f7f7;
    margin-bottom: 10px;
    padding: 14px 12px;
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent; }
    .shipment-info-tab .line-item .line-item-product-detail {
      display: flex;
      align-items: center;
      width: 250px;
      margin-left: 0px; }
    .shipment-info-tab .line-item .line-item-img {
      width: 56px;
      height: 56px;
      margin-right: 10px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important; }
    .shipment-info-tab .line-item .line-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      width: 150px; }
    .shipment-info-tab .line-item .line-item-sku {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      width: 150px; }
  .shipment-info-tab .line-items-tab_header_upper_data {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    padding: 16px 16px 8px;
    margin: -16px 0 -2px 0;
    color: #898989; }
    .shipment-info-tab .line-items-tab_header_upper_data .flex33 {
      width: 250px; }
    .shipment-info-tab .line-items-tab_header_upper_data .flex44 {
      width: 85px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .shipment-info-tab .line-items-tab_header_upper_data .flex55 {
      width: 250px;
      display: flex;
      align-items: center;
      justify-content: space-evenly; }
      .shipment-info-tab .line-items-tab_header_upper_data .flex55 span {
        margin-right: 20px; }
      .shipment-info-tab .line-items-tab_header_upper_data .flex55 .Quantity {
        width: 70px; }
      .shipment-info-tab .line-items-tab_header_upper_data .flex55 .star {
        width: 30px; }
      .shipment-info-tab .line-items-tab_header_upper_data .flex55 .Shopify_Selling_Price {
        width: 150px; }
    .shipment-info-tab .line-items-tab_header_upper_data .flex66 {
      width: 115px;
      display: flex;
      padding-left: 29px;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
  .shipment-info-tab .quntyandpices {
    display: flex;
    width: 250px;
    justify-content: space-evenly;
    align-items: center;
    font-size: 12px;
    color: #000; }
    .shipment-info-tab .quntyandpices .Quantity {
      width: 70px; }
    .shipment-info-tab .quntyandpices .star {
      width: 30px; }
    .shipment-info-tab .quntyandpices .Shopify_Selling_Price {
      width: 150px; }
  .shipment-info-tab .end_data {
    width: 115px;
    display: flex;
    padding-left: 29px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px; }
    .shipment-info-tab .end_data .rtouppercase {
      text-transform: uppercase; }
    .shipment-info-tab .end_data .normalcase {
      text-transform: capitalize; }

.customer-info-tab {
  background: #fff;
  padding: 8px 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .customer-info-tab .header_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%; }
    .customer-info-tab .header_customer_info .full_name {
      width: 32%; }
      .customer-info-tab .header_customer_info .full_name .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .header_customer_info .full_name .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .customer-info-tab .header_customer_info .address_line1 {
      width: 32%; }
      .customer-info-tab .header_customer_info .address_line1 .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .header_customer_info .address_line1 .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px; }
    .customer-info-tab .header_customer_info .Pin_code {
      width: 32%; }
      .customer-info-tab .header_customer_info .Pin_code .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .header_customer_info .Pin_code .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
  .customer-info-tab .middle_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%; }
    .customer-info-tab .middle_customer_info .email_address {
      width: 32%; }
      .customer-info-tab .middle_customer_info .email_address .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .middle_customer_info .email_address .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .customer-info-tab .middle_customer_info .address_line2 {
      width: 32%; }
      .customer-info-tab .middle_customer_info .address_line2 .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .middle_customer_info .address_line2 .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .customer-info-tab .middle_customer_info .ship_country {
      width: 32%; }
      .customer-info-tab .middle_customer_info .ship_country .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898898;
        cursor: no-drop; }
      .customer-info-tab .middle_customer_info .ship_country .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px; }
  .customer-info-tab .footer_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px; }
    .customer-info-tab .footer_customer_info .mobile_number {
      width: 32%; }
      .customer-info-tab .footer_customer_info .mobile_number .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .footer_customer_info .mobile_number .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .customer-info-tab .footer_customer_info .citytown {
      width: 32%; }
      .customer-info-tab .footer_customer_info .citytown .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898898;
        cursor: no-drop; }
      .customer-info-tab .footer_customer_info .citytown .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }
    .customer-info-tab .footer_customer_info .State {
      width: 32%; }
      .customer-info-tab .footer_customer_info .State .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #898989;
        cursor: no-drop; }
      .customer-info-tab .footer_customer_info .State .label {
        font-size: 12px;
        color: #898898;
        margin-bottom: 8px;
        letter-spacing: 0.032em;
        line-height: 16px; }

.view-remark-tab {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .view-remark-tab .flex {
    margin-bottom: 5px;
    margin: 0 0px 8px;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    font-size: 12px;
    color: #898989; }
    .view-remark-tab .flex .flex-remarks {
      width: 30%; }
    .view-remark-tab .flex .flex-bucket {
      width: 30%; }
    .view-remark-tab .flex .flex-remarkby {
      width: 12%; }
    .view-remark-tab .flex .flex-logged {
      width: 20%; }
  .view-remark-tab .thead {
    display: flex;
    margin-bottom: 8px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    padding: 0 16px;
    justify-content: space-between; }
    .view-remark-tab .thead .flex-remarks {
      width: 30%; }
    .view-remark-tab .thead .flex-bucket {
      width: 30%; }
    .view-remark-tab .thead .flex-remarkby {
      width: 12%; }
    .view-remark-tab .thead .flex-logged {
      width: 20%; }

.Escalate_NDR * {
  letter-spacing: 0.032em; }

.Escalate_NDR .ant-modal-content {
  padding: 24px 32px; }
  .Escalate_NDR .ant-modal-content .ant-modal-title {
    padding-bottom: 8px; }
  .Escalate_NDR .ant-modal-content .ant-modal-header {
    padding: 0; }
  .Escalate_NDR .ant-modal-content .ant-modal-body {
    padding: 0; }
    .Escalate_NDR .ant-modal-content .ant-modal-body .ndr-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      margin-bottom: 12px; }
      .Escalate_NDR .ant-modal-content .ant-modal-body .ndr-details .details {
        width: 200px; }
        .Escalate_NDR .ant-modal-content .ant-modal-body .ndr-details .details p {
          margin-bottom: 4px;
          font-size: 12px;
          color: #898989; }
        .Escalate_NDR .ant-modal-content .ant-modal-body .ndr-details .details .id {
          height: 32px;
          background: #f5f5f5;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 16px;
          font-weight: 500;
          font-size: 12px;
          color: #1370fc; }
    .Escalate_NDR .ant-modal-content .ant-modal-body .remarks {
      color: #898989;
      font-size: 12px;
      margin-bottom: 4px; }
    .Escalate_NDR .ant-modal-content .ant-modal-body textarea {
      resize: none;
      height: 100px;
      padding: 8px 16px;
      border: none;
      background-color: #f5f5f5; }
    .Escalate_NDR .ant-modal-content .ant-modal-body ::placeholder {
      color: #898989;
      font-size: 12px; }
    .Escalate_NDR .ant-modal-content .ant-modal-body .upload-text {
      margin-top: 12px;
      margin-bottom: 4px;
      font-size: 12px;
      color: #898989; }
    .Escalate_NDR .ant-modal-content .ant-modal-body .ant-upload {
      padding: 0px !important;
      padding-left: 8px !important;
      border: none;
      background-color: #f5f5f5;
      border-radius: 2px; }
      .Escalate_NDR .ant-modal-content .ant-modal-body .ant-upload .ant-upload-drag-container {
        display: flex;
        align-items: center;
        height: 32px; }
        .Escalate_NDR .ant-modal-content .ant-modal-body .ant-upload .ant-upload-drag-container .ant-upload-text {
          margin: 0px;
          font-size: 12px;
          color: #898989; }
      .Escalate_NDR .ant-modal-content .ant-modal-body .ant-upload .ant-upload-drag-icon {
        margin-bottom: 6px;
        margin-right: 8px; }
        .Escalate_NDR .ant-modal-content .ant-modal-body .ant-upload .ant-upload-drag-icon svg {
          color: #898989;
          font-size: 16px; }
  .Escalate_NDR .ant-modal-content .ant-modal-footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px; }
    .Escalate_NDR .ant-modal-content .ant-modal-footer .cancel {
      width: 176px;
      height: 40px;
      border-radius: 1px;
      border: 1px solid #898989; }
    .Escalate_NDR .ant-modal-content .ant-modal-footer .cancel:hover {
      width: 176px;
      height: 40px;
      color: #1370fc;
      border-radius: 1px;
      border: 1px solid #1370fc; }
    .Escalate_NDR .ant-modal-content .ant-modal-footer .ant-btn-primary {
      width: 176px;
      height: 40px;
      border-radius: 1px; }
    .Escalate_NDR .ant-modal-content .ant-modal-footer .success_btn {
      border: 1px solid #2bc823;
      background-color: #2bc823; }

.Escalate_NDR .ant-input {
  border-radius: 2px !important; }

.Escalate_NDR .re-Attemptcheckbox {
  margin-top: 32px; }
  .Escalate_NDR .re-Attemptcheckbox span {
    font-size: 12px;
    color: #898989; }

.Escalate_NDR .ndr-reattamp_modal {
  border: none; }
  .Escalate_NDR .ndr-reattamp_modal input {
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5; }
  .Escalate_NDR .ndr-reattamp_modal .ant-input-number {
    border: none; }
    .Escalate_NDR .ndr-reattamp_modal .ant-input-number input {
      border: 1px solid #f5f5f5;
      height: 32px; }
    .Escalate_NDR .ndr-reattamp_modal .ant-input-number input:focus {
      background-color: #fff;
      border: 1px solid #212121; }
  .Escalate_NDR .ndr-reattamp_modal .ant-input-number:focus {
    outline: none;
    border: none;
    box-shadow: none; }

.Escalate_NDR .btn_disabled {
  color: #fff;
  background-color: #2bc82371;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  pointer-events: none;
  border: none; }

.Escalate_NDR .remarks span {
  color: red; }

.Escalate_NDR input:focus {
  background-color: #fff;
  border: 1px solid #212121; }

.Escalate_NDR textarea:focus {
  background-color: #ffffff !important;
  border: 1px solid #212121 !important; }

.ndr-reattamp_modal {
  margin-top: 8px;
  border: 1px solid #898989;
  padding: 8px; }
  .ndr-reattamp_modal .upper_data {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px; }
    .ndr-reattamp_modal .upper_data .ant-form-item-control {
      width: 200px; }
  .ndr-reattamp_modal .middle_data {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px; }
    .ndr-reattamp_modal .middle_data .ant-form-item-control {
      width: 200px; }
  .ndr-reattamp_modal .bottom_data {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px; }
  .ndr-reattamp_modal .btn-save-customer {
    display: flex;
    justify-self: end; }
  .ndr-reattamp_modal .input-label {
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: #000; }
  .ndr-reattamp_modal input,
  .ndr-reattamp_modal textarea {
    border: 1.5px solid #979797;
    border-radius: 2px;
    font-size: 12px; }
  .ndr-reattamp_modal .ant-form-item-control {
    position: relative;
    line-height: 32px;
    zoom: 1;
    font-size: 12px;
    margin-right: 8px; }

.ndr-modal .input-label {
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  color: #000; }

.ndr-modal .no-top-margin {
  margin-top: 0rem; }

.ndr-modal input,
.ndr-modal textarea {
  border: 1.5px solid #979797;
  border-radius: 2px;
  font-size: 12px; }

.ndr-modal .ant-form-item-control {
  position: relative;
  line-height: 32px;
  zoom: 1;
  font-size: 12px; }

.ant-modal-content {
  border-radius: 3px; }

.Ndr_order_data .import-product-search > span > span {
  width: 345px !important; }

.Ndr_order_data .Ndrnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .Ndr_order_data .Ndrnav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .Ndr_order_data .Ndrnav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .Ndr_order_data .Ndrnav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .Ndr_order_data .Ndrnav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .Ndr_order_data .Ndrnav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .Ndr_order_data .Ndrnav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .Ndr_order_data .Ndrnav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .Ndr_order_data .Ndrnav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .Ndr_order_data .Ndrnav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .Ndr_order_data .Ndrnav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .Ndr_order_data .Ndrnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .Ndr_order_data .Ndrnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .Ndr_order_data .Ndrnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .Ndr_order_data .Ndrnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .Ndr_order_data .Ndrnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .Ndr_order_data .Ndrnav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .Ndr_order_data .Ndrnav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1268fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1268fb; }
      .Ndr_order_data .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation {
        position: relative; }
      .Ndr_order_data .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .Ndr_order_data .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover {
        color: #4398ed !important; }
        .Ndr_order_data .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .Ndr_order_data .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .Ndr_order_data .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation-click {
        position: relative; }
      .Ndr_order_data .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .Ndr_order_data .Ndrnav .secondwrap .download_csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .Ndr_order_data .Ndrnav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .Ndr_order_data .Ndrnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .Ndr_order_data .Ndrnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .Ndr_order_data .Ndrnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .Ndr_order_data .Ndrnav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .Ndr_order_data .Ndrnav .ant-calendar-picker-clear,
  .Ndr_order_data .Ndrnav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.Ndr_order_data .bottom_data {
  display: flex;
  justify-content: space-between;
  padding-top: 90px;
  margin-top: 32px;
  padding-top: 90px;
  margin-bottom: 16px; }

.Ndr_order_data .ant-select-selection__placeholder {
  color: #1268fb; }

.Ndr_order_data .drop {
  background-color: #f8f8f8 !important;
  box-shadow: 0px 2px 8px #00000014;
  letter-spacing: 0 !important; }
  .Ndr_order_data .drop > div {
    height: 40px !important; }
  .Ndr_order_data .drop .ant-select.ant-select-enabled {
    height: 40px !important; }
  .Ndr_order_data .drop .ant-select-selection__rendered {
    margin-left: 16px;
    background-color: #f8f8f8 !important;
    margin-right: 2px;
    height: 40px !important; }
    .Ndr_order_data .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
      height: 40px !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .Ndr_order_data .drop .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .Ndr_order_data .drop p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #898989 !important; }
  .Ndr_order_data .drop .ant-select-selection-selected-value {
    height: 100% !important;
    line-height: 32px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: 13px;
    color: #1268fb; }
    .Ndr_order_data .drop .ant-select-selection-selected-value .anticon svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px; }
  .Ndr_order_data .drop .ant-select-selection {
    width: 159px;
    height: 40px !important;
    border: none !important;
    background: #f8f8f8 !important; }
    .Ndr_order_data .drop .ant-select-selection .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .Ndr_order_data .drop .ant-select-selection .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .Ndr_order_data .drop .ant-select-arrow {
    top: 53%;
    left: 159% !important; }

@media screen and (min-width: 1440px) {
  .Ndrnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .Ndrnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.non-cursor-pointer {
  pointer-events: none;
  border-bottom: none !important; }

.bucket-filter-wraper {
  margin-bottom: 12px;
  position: relative; }
  .bucket-filter-wraper * {
    letter-spacing: 0.032em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer !important; }
  .bucket-filter-wraper .ant-select-selection-item {
    display: none !important; }
  .bucket-filter-wraper .custom-text {
    color: "#6E6E6E";
    font-size: 12px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    pointer-events: none; }
    .bucket-filter-wraper .custom-text span {
      color: #1268fb;
      font-weight: 500;
      margin-left: 4px; }
  .bucket-filter-wraper .custom-arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotateX(0);
    right: 12px;
    transition: all 0.3s ease 0s; }
  .bucket-filter-wraper .custom-arrow-icon.open {
    transition: all 0.35s ease 0s;
    transform: translateY(-50%) rotate(180deg); }
    .bucket-filter-wraper .custom-arrow-icon.open g {
      fill: #898989; }
  .bucket-filter-wraper .ant-select-selection__choice__content {
    max-width: 30px; }
  .bucket-filter-wraper .ant-select.ant-select-enabled {
    height: 40px; }
  .bucket-filter-wraper .ant-select-selection--multiple {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px; }
    .bucket-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered {
      height: 40px;
      margin-bottom: 0px;
      padding-left: 90px; }
      .bucket-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice {
        max-width: 100px;
        position: relative;
        margin-top: 8px;
        color: #1268fb;
        font-weight: 500;
        font-size: 12px;
        background: none;
        border: 1px solid transparent;
        z-index: 10;
        margin-right: 2px;
        padding: 0 2px !important; }
        .bucket-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice span {
          display: none; }
      .bucket-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-search__field {
        margin-top: 3.4px;
        font-size: 12px; }
  .bucket-filter-wraper .ant-select-search.ant-select-search--inline {
    width: 50px;
    margin-top: 5px;
    color: #1268fb; }
  .bucket-filter-wraper .ant-checkbox.ant-checkbox-checked {
    display: none; }

.bucket-filter-overlay * {
  letter-spacing: 0.032em; }

.bucket-filter-overlay .ant-select-dropdown-menu-item-disabled i {
  display: none; }

.bucket-filter-overlay .ant-select-dropdown-menu-item {
  color: #898989;
  min-height: 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #cecece;
  padding-left: 40px; }
  .bucket-filter-overlay .ant-select-dropdown-menu-item i {
    position: absolute;
    right: 280px !important;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #e3e3e3;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .bucket-filter-overlay .ant-select-dropdown-menu-item i svg {
      font-size: 12px; }

.bucket-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  color: #212121;
  font-weight: 500; }
  .bucket-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i {
    background-color: #2bc822;
    border-color: #2bc822 !important;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .bucket-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i svg {
      color: #fff;
      stroke: 2px; }

.bucket-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled i {
  display: none; }

.bucket-filter-overlay .ant-select-dropdown-menu-item:hover {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe; }
  .bucket-filter-overlay .ant-select-dropdown-menu-item:hover svg {
    color: #898989; }

.bucket-filter-wraper.custom-padding .ant-select-search.ant-select-search--inline {
  width: 30px;
  margin-top: 5px;
  color: #1268fb; }

.bucket-filter-wraper.custom-padding .ant-select-selection__rendered {
  height: 40px;
  margin-bottom: 0px;
  padding-left: 130px !important; }
  .bucket-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-selection__choice {
    max-width: 100px;
    position: relative;
    margin-top: 8px;
    color: #1268fb;
    font-weight: 500;
    font-size: 12px;
    background: none;
    border: 1px solid transparent;
    z-index: 10;
    margin-right: 2px;
    padding: 0 2px !important; }
    .bucket-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-selection__choice span {
      display: none; }
  .bucket-filter-wraper.custom-padding .ant-select-selection__rendered li:nth-last-child(2) {
    position: relative; }
    .bucket-filter-wraper.custom-padding .ant-select-selection__rendered li:nth-last-child(2) ::after {
      content: "";
      position: absolute;
      right: -1px;
      top: 1px; }
  .bucket-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-search__field {
    margin-top: 3.4px;
    font-size: 12px; }

.bucket-filter-wraper.custom-padding .ant-select-selection__choice {
  position: relative; }
  .bucket-filter-wraper.custom-padding .ant-select-selection__choice ::after {
    content: ",";
    position: absolute;
    right: -1px;
    top: 1px; }

.bucket-filter-wraper.custom-padding .ant-select-selection__choice:last-of-type {
  position: relative;
  display: none !important; }
  .bucket-filter-wraper.custom-padding .ant-select-selection__choice:last-of-type ::after {
    content: "--";
    position: absolute;
    right: -1px;
    top: 1px; }

.overlay-footer {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .overlay-footer button {
    height: 26px !important;
    padding: 2px 8px !important; }
  .overlay-footer span {
    font-size: 12px; }

.single-filter-wraper {
  position: relative;
  margin-bottom: 12px; }
  .single-filter-wraper * {
    letter-spacing: 0.032em; }
  .single-filter-wraper .custom-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    left: 16px;
    z-index: 10; }
  .single-filter-wraper .ant-select-open svg g {
    fill: #898989; }
  .single-filter-wraper .ant-select-selection.ant-select-selection--single {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px; }
    .single-filter-wraper .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
      height: 40px; }
      .single-filter-wraper .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
        height: 40px;
        display: flex !important;
        align-items: center;
        font-size: 12px;
        color: #1268fb;
        font-weight: 500;
        padding-left: 108px; }
        .single-filter-wraper .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value .ant-select-selection-selected-value {
          margin-left: 3px; }
  .single-filter-wraper .ant-select-arrow {
    margin-top: -7px; }

.single-filter-overlay * {
  letter-spacing: 0.032em; }

.single-filter-overlay .ant-select-dropdown-menu-item-disabled i {
  display: none; }

.single-filter-overlay .ant-select-dropdown-menu-item {
  color: #898989;
  min-height: 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #cecece; }
  .single-filter-overlay .ant-select-dropdown-menu-item i {
    position: absolute;
    right: 280px !important;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #e3e3e3;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .single-filter-overlay .ant-select-dropdown-menu-item i svg {
      font-size: 12px; }

.single-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  color: #212121;
  font-weight: 500; }
  .single-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i {
    background-color: #2bc822;
    border-color: #2bc822 !important;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .single-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i svg {
      color: #fff;
      stroke: 2px; }

.single-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled i {
  display: none; }

.single-filter-overlay .ant-select-dropdown-menu-item:hover {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe; }
  .single-filter-overlay .ant-select-dropdown-menu-item:hover svg {
    color: #898989; }

.deductedFromWallet {
  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;
  background-color: #fff;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .deductedFromWallet .not-found-content {
    margin-bottom: 0px; }
  .deductedFromWallet .not-found-wraper {
    margin-bottom: 0px !important; }
  .deductedFromWallet .topPerformingProductHead {
    height: 48px;
    background-color: #f0f2f659;
    padding: 16px 24px;
    font-size: 12px;
    color: #898989;
    position: relative; }
    .deductedFromWallet .topPerformingProductHead .name {
      margin-left: 0px !important; }
    .deductedFromWallet .topPerformingProductHead span {
      font-size: 12px;
      color: #898989;
      margin-left: 4px; }
    .deductedFromWallet .topPerformingProductHead span:first-of-type {
      color: #212121;
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px; }
  .deductedFromWallet .product-heads {
    height: 24px;
    padding: 0 24px;
    background-color: #f0f2f659;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .deductedFromWallet .product-heads div {
      font-size: 12px;
      font-weight: 500;
      color: #898989;
      display: flex;
      align-items: center;
      justify-content: center; }
    .deductedFromWallet .product-heads .name-head {
      justify-content: flex-start;
      width: 260px; }
    .deductedFromWallet .product-heads .delivered-order-head {
      width: 160px;
      justify-content: flex-end; }
  .deductedFromWallet .product-row-wraper {
    padding-top: 0px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px; }
    .deductedFromWallet .product-row-wraper .product-row {
      height: 40px;
      padding-left: 8px;
      padding-right: 8px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .deductedFromWallet .product-row-wraper .product-row > div {
        font-size: 12px;
        letter-spacing: 0.032em;
        color: #898989;
        display: flex;
        align-items: center;
        justify-content: center; }
      .deductedFromWallet .product-row-wraper .product-row .name-head {
        justify-content: flex-start;
        width: 256px;
        color: #212121; }
      .deductedFromWallet .product-row-wraper .product-row .name-head.total {
        font-weight: 600;
        font-size: 14px; }
      .deductedFromWallet .product-row-wraper .product-row .delivered-order-head {
        width: 180px;
        justify-content: flex-end;
        font-weight: 500; }
      .deductedFromWallet .product-row-wraper .product-row .delivered-order-head.debit {
        color: #c70c3c; }
      .deductedFromWallet .product-row-wraper .product-row .delivered-order-head.credit {
        color: #229d1b; }
      .deductedFromWallet .product-row-wraper .product-row .delivered-order-head.yellow {
        color: #ff9800; }
    .deductedFromWallet .product-row-wraper .product-row:nth-child(2n) {
      background-color: #f5f5f5; }
    .deductedFromWallet .product-row-wraper .last-row {
      background-color: #fff !important;
      border-top: 1px solid #ebe9e9; }

.wallet-container {
  display: flex;
  grid-gap: 24px;
  gap: 24px;
  margin-top: 40px; }
  .wallet-container > div {
    width: 48.8%; }

.ant-alert-with-description * {
  letter-spacing: 0.032em; }

.ant-alert-with-description .ant-alert-message {
  font-size: 12px;
  font-weight: 600;
  color: #212121; }

.ant-alert-with-description .ant-alert-description {
  font-size: 12px;
  color: #212121; }

.counter-card-wraper {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .counter-card-wraper * {
    letter-spacing: 0.032em; }
  .counter-card-wraper .counter-card {
    border-radius: 2px;
    width: 24.19%;
    box-shadow: 0 3px 6px #00000029;
    background-color: #fff; }
    .counter-card-wraper .counter-card .head {
      padding: 16px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      font-weight: 500;
      background-color: #0c182a;
      color: #fff;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px; }
      .counter-card-wraper .counter-card .head .global-tooltip-w i {
        margin-right: 0px !important; }
      .counter-card-wraper .counter-card .head .global-tooltip-w:hover #icomponent1 {
        stroke: #0c182a; }
      .counter-card-wraper .counter-card .head .global-tooltip-w:hover #icomponent2 {
        stroke: #0c182a; }
    .counter-card-wraper .counter-card .body {
      padding: 12px 16px;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      background-color: #fff;
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .counter-card-wraper .counter-card .body .amount {
        display: flex;
        align-items: baseline;
        margin-bottom: 16px; }
        .counter-card-wraper .counter-card .body .amount span {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.651);
          margin-right: 6px !important; }
        .counter-card-wraper .counter-card .body .amount .amountp {
          font-size: 18px;
          color: #081321;
          font-weight: 500; }
        .counter-card-wraper .counter-card .body .amount p {
          font-size: 18px;
          color: #081321;
          font-weight: 500; }
      .counter-card-wraper .counter-card .body .h-line {
        height: 1px;
        background-color: #e8e8e8;
        margin-bottom: 8px; }
      .counter-card-wraper .counter-card .body .flex-wraper-count {
        display: flex; }
        .counter-card-wraper .counter-card .body .flex-wraper-count .attempt {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          position: relative; }
      .counter-card-wraper .counter-card .body .count {
        display: flex;
        align-items: center;
        position: relative; }
        .counter-card-wraper .counter-card .body .count * {
          transition: all 0.3s linear !important; }
        .counter-card-wraper .counter-card .body .count span {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.651);
          margin-right: 6px; }
        .counter-card-wraper .counter-card .body .count p {
          font-size: 14px;
          color: #707070; }
        .counter-card-wraper .counter-card .body .count .icon {
          position: absolute;
          right: 0px;
          bottom: -4px;
          height: 24px;
          width: 24px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center; }
          .counter-card-wraper .counter-card .body .count .icon svg {
            transform: rotate(268deg);
            margin-left: 2px; }
            .counter-card-wraper .counter-card .body .count .icon svg path {
              fill: #212121; }
      .counter-card-wraper .counter-card .body .count:hover * {
        transition: all 0.3s linear !important; }
      .counter-card-wraper .counter-card .body .count:hover .icon {
        position: absolute;
        right: 0px;
        bottom: -4px;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center; }
        .counter-card-wraper .counter-card .body .count:hover .icon svg {
          transform: rotate(0deg);
          margin-left: 2px; }
          .counter-card-wraper .counter-card .body .count:hover .icon svg path {
            fill: #1268fb; }
    .counter-card-wraper .counter-card .body-percentage {
      border-bottom-right-radius: 2px;
      border-bottom-left-radius: 2px;
      padding: 12px 16px;
      height: 106px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #081321;
      font-weight: 500; }

.counter-card-wraper.trans .counter-card {
  width: 23.79% !important; }

.data-sorter {
  display: flex;
  align-items: center; }
  .data-sorter .ant-dropdown-trigger {
    display: flex;
    align-items: center; }
  .data-sorter span {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #6e6e6e !important; }
  .data-sorter .ant-select-selection {
    background-color: transparent;
    border: none;
    border-radius: 0px; }
  .data-sorter .ant-select-selection__rendered {
    margin-left: 6px !important;
    margin-right: 32px !important; }
  .data-sorter .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em;
    margin-right: 4px; }
  .data-sorter a {
    font-size: 12px !important;
    color: #1268fb !important;
    letter-spacing: 0.032em !important;
    font-weight: 500 !important; }
  .data-sorter .anticon {
    font-size: 16px;
    margin-left: 4px !important;
    transition: all 0.5s; }
  .data-sorter .arrow-down {
    transform: rotateX(0deg); }
  .data-sorter .arrow-up {
    transform: rotateX(180deg); }
    .data-sorter .arrow-up g {
      fill: #898989; }

.sorter-dropdwon-item .active {
  font-weight: 500;
  color: #000000a6; }

.custom-div-tooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 280px; }
  .custom-div-tooltip .line {
    height: 1px;
    margin: 12px 0px;
    width: 212px;
    background-color: #898989 !important; }
  .custom-div-tooltip h2 {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 12px; }
  .custom-div-tooltip p {
    font-weight: 500;
    font-size: 14px; }

.custom-bg-tooltip .ant-tooltip-arrow::before {
  background-color: #1370fc !important;
  width: 9px;
  height: 10px; }

.custom-bg-tooltip .ant-tooltip-content {
  border-radius: 5px;
  border: 2px solid #1370fc !important;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 1px 50px 30px; }

.custom-bg-tooltip .ant-tooltip-inner {
  background-color: #fff !important;
  color: #212121; }
  .custom-bg-tooltip .ant-tooltip-inner h2 {
    color: #898989; }

.table-top-head {
  margin-bottom: 6px; }
  .table-top-head .clickable span {
    font-size: 12px; }
  .table-top-head span {
    font-size: 14px; }

.table-top-head.flex {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.custom-table-wraper .details .name {
  font-size: 12px;
  color: #1162fb;
  font-weight: 500; }

.custom-table-wraper .details .id {
  font-size: 12px;
  color: #898989;
  font-weight: 500; }

.custom-table-wraper .details .name-wraper {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .custom-table-wraper .details .name-wraper svg {
    transition: all 0.3s linear;
    opacity: 0;
    margin-left: 6px; }

.custom-table-wraper .details .name-wraper:hover svg {
  transition: all 0.3s linear;
  opacity: 1; }

.custom-table-wraper .global-tooltip-w i {
  margin-right: 0px !important; }

.custom-table-wraper .global-tooltip-w:hover #icomponent1 {
  stroke: #0c182a; }

.custom-table-wraper .global-tooltip-w:hover #icomponent2 {
  stroke: #0c182a; }

.custom-table-wraper .tooltip-title {
  display: flex !important;
  align-items: center;
  justify-content: center; }
  .custom-table-wraper .tooltip-title > span {
    width: 40px; }
  .custom-table-wraper .tooltip-title .global-tooltip-w {
    width: -webkit-max-content !important;
    width: max-content !important;
    min-width: -webkit-max-content !important;
    min-width: max-content !important; }

.custom-table-wraper .ant-table-thead tr {
  height: 51px; }
  .custom-table-wraper .ant-table-thead tr th {
    background-color: #16273b;
    color: #fff !important;
    font-size: 12px; }
    .custom-table-wraper .ant-table-thead tr th span {
      display: block;
      min-width: 100px;
      text-align: -webkit-center; }

.custom-table-wraper .ant-table-body tr td {
  border-bottom: 0px !important;
  border-top: 0px !important;
  font-size: 12px;
  text-align: center; }
  .custom-table-wraper .ant-table-body tr td span {
    font-size: 12px; }

.custom-table-wraper .ant-table-body tr:nth-child(even) {
  background: #f5f5f5; }

.custom-table-wraper .ant-table-fixed-right td,
.custom-table-wraper .ant-table-fixed-left td {
  font-size: 12px;
  border-bottom: 0px;
  text-align: center; }
  .custom-table-wraper .ant-table-fixed-right td span,
  .custom-table-wraper .ant-table-fixed-left td span {
    font-size: 12px;
    border-bottom: 0px; }

.custom-table-wraper .ant-table-fixed-right tr:nth-child(even),
.custom-table-wraper .ant-table-fixed-left tr:nth-child(even) {
  background: #f5f5f5; }

.rtoTable.custom-table-wraper .ant-table-column-title:nth-child(3) {
  display: none !important; }

.rtoTable.custom-table-wraper .ant-table-thead tr {
  height: 51px; }
  .rtoTable.custom-table-wraper .ant-table-thead tr th {
    background-color: #16273b;
    color: #fff !important;
    font-size: 12px; }

.table-pagination {
  padding: 12px 0px; }

.custom-table-wraper::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px; }

.custom-table-wraper::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #898989;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }

.custom-table-wraper::-webkit-scrollbar {
  width: 8px;
  border: 1px solid #898989; }

.transaction-report-nav .Ndrnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .transaction-report-nav .Ndrnav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .transaction-report-nav .Ndrnav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .transaction-report-nav .Ndrnav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .transaction-report-nav .Ndrnav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      right: 15px; }
      .transaction-report-nav .Ndrnav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .transaction-report-nav .Ndrnav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .transaction-report-nav .Ndrnav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .transaction-report-nav .Ndrnav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .transaction-report-nav .Ndrnav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .transaction-report-nav .Ndrnav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .transaction-report-nav .Ndrnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .transaction-report-nav .Ndrnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .transaction-report-nav .Ndrnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .transaction-report-nav .Ndrnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .transaction-report-nav .Ndrnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .transaction-report-nav .Ndrnav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .transaction-report-nav .Ndrnav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1268fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1268fb; }
      .transaction-report-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation {
        position: relative; }
      .transaction-report-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .transaction-report-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover {
        color: #4398ed !important; }
        .transaction-report-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .transaction-report-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .transaction-report-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation-click {
        position: relative; }
      .transaction-report-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .transaction-report-nav .Ndrnav .secondwrap .download_csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .transaction-report-nav .Ndrnav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .transaction-report-nav .Ndrnav .ant-calendar-picker-input.ant-input {
    width: 86%; }
  .transaction-report-nav .Ndrnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .transaction-report-nav .Ndrnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .transaction-report-nav .Ndrnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .transaction-report-nav .Ndrnav .ant-calendar-range-picker-input {
    width: 36.6% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .transaction-report-nav .Ndrnav .ant-calendar-picker-clear,
  .transaction-report-nav .Ndrnav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.transaction-report-nav .bottom_data {
  display: flex;
  justify-content: space-between;
  padding-top: 90px;
  margin-top: 32px;
  padding-top: 90px; }

.transaction-report-nav .ant-select-selection__placeholder {
  color: #1268fb; }

.transaction-report-nav .drop {
  background-color: #f8f8f8 !important;
  box-shadow: 0px 2px 8px #00000014;
  letter-spacing: 0 !important; }
  .transaction-report-nav .drop > div {
    height: 40px !important; }
  .transaction-report-nav .drop .ant-select.ant-select-enabled {
    height: 40px !important; }
  .transaction-report-nav .drop .ant-select-selection__rendered {
    margin-left: 16px;
    background-color: #f8f8f8 !important;
    margin-right: 2px;
    height: 40px !important; }
    .transaction-report-nav .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
      height: 40px !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .transaction-report-nav .drop .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .transaction-report-nav .drop p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #898989 !important; }
  .transaction-report-nav .drop .ant-select-selection-selected-value {
    height: 100% !important;
    line-height: 32px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: 13px;
    color: #1268fb; }
    .transaction-report-nav .drop .ant-select-selection-selected-value .anticon svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px; }
  .transaction-report-nav .drop .ant-select-selection {
    width: 159px;
    height: 40px !important;
    border: none !important;
    background: #f8f8f8 !important; }
    .transaction-report-nav .drop .ant-select-selection .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .transaction-report-nav .drop .ant-select-selection .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .transaction-report-nav .drop .ant-select-arrow {
    top: 53%;
    left: 159% !important; }

@media screen and (min-width: 1440px) {
  .Ndrnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .Ndrnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.non-cursor-pointer {
  pointer-events: none;
  border-bottom: none !important; }

.orders-to-vf-graph-wraper {
  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;
  background-color: #fff; }
  .orders-to-vf-graph-wraper * {
    letter-spacing: 0.032em; }
  .orders-to-vf-graph-wraper .graph-head {
    height: 56px;
    background-color: #f0f2f659;
    padding: 16px 24px 12px 24px;
    font-size: 12px;
    color: #898989;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .orders-to-vf-graph-wraper .graph-head span {
      font-size: 12px;
      color: #898989;
      margin-left: 4px; }
    .orders-to-vf-graph-wraper .graph-head .name {
      margin-left: 0px; }
    .orders-to-vf-graph-wraper .graph-head span:first-of-type {
      color: #212121;
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px; }
    .orders-to-vf-graph-wraper .graph-head .abs-div {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: -48px;
      left: 48px;
      justify-content: flex-start; }
      .orders-to-vf-graph-wraper .graph-head .abs-div > div {
        margin-right: 34px; }
      .orders-to-vf-graph-wraper .graph-head .abs-div svg {
        margin-right: 2px; }
      .orders-to-vf-graph-wraper .graph-head .abs-div span {
        font-size: 12px;
        color: #212121;
        font-weight: 500; }
    .orders-to-vf-graph-wraper .graph-head .compare {
      display: flex;
      align-items: center; }
  .orders-to-vf-graph-wraper .recharts-wrapper {
    padding-left: 6px;
    padding-right: 6px;
    margin-top: 76px !important; }
    .orders-to-vf-graph-wraper .recharts-wrapper .recharts-surface {
      width: 100% !important; }
    .orders-to-vf-graph-wraper .recharts-wrapper .yAxis {
      font-size: 10px; }
    .orders-to-vf-graph-wraper .recharts-wrapper .xAxis g {
      font-size: 14px; }

.graph-custom-tooltip {
  border: 1px solid #000;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  opacity: 1;
  padding: 11px 16px;
  transition: top 200ms, left 200ms;
  font-size: 12px;
  min-width: 200px !important;
  z-index: 999; }
  .graph-custom-tooltip .light-bold {
    font-weight: 500; }
  .graph-custom-tooltip .date {
    margin-bottom: 7px; }
  .graph-custom-tooltip .details {
    display: flex; }
    .graph-custom-tooltip .details .icon {
      display: flex;
      align-items: flex-start;
      margin-top: 6px;
      margin-right: 6px; }
      .graph-custom-tooltip .details .icon svg {
        font-size: 6px; }
    .graph-custom-tooltip .details .text {
      text-transform: capitalize; }
      .graph-custom-tooltip .details .text p {
        font-weight: 500; }
      .graph-custom-tooltip .details .text p:first-of-type {
        font-weight: 400; }
  .graph-custom-tooltip .details.last {
    margin-top: 14px; }

.topPerformingProductHeadWraper.custom {
  height: auto !important; }
  .topPerformingProductHeadWraper.custom .topPerformingProductHead {
    height: auto !important;
    padding-bottom: 8px !important; }
  .topPerformingProductHeadWraper.custom .ant-table-fixed-left tr:nth-child(even) {
    background: #f5f5f5 !important; }
  .topPerformingProductHeadWraper.custom tr:nth-child(even) {
    background: #f5f5f5; }

.topPerformingProductHeadWraper {
  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;
  background-color: #fff; }
  .topPerformingProductHeadWraper * {
    letter-spacing: 0.032em !important; }
  .topPerformingProductHeadWraper .percentage-text {
    font-size: 12px;
    color: #212121;
    font-weight: 500; }
  .topPerformingProductHeadWraper .topPerformingProductHead {
    background-color: #f0f2f659;
    padding: 16px 24px;
    padding-bottom: 0px;
    font-size: 12px;
    color: #898989;
    position: relative;
    display: flex;
    justify-content: space-between; }
    .topPerformingProductHeadWraper .topPerformingProductHead button {
      color: #fff !important; }
      .topPerformingProductHeadWraper .topPerformingProductHead button span {
        color: #fff !important;
        font-size: 12px !important;
        font-weight: 500 !important; }
    .topPerformingProductHeadWraper .topPerformingProductHead .name {
      margin-left: 0px !important; }
    .topPerformingProductHeadWraper .topPerformingProductHead span {
      font-size: 12px;
      color: #898989;
      margin-left: 4px; }
    .topPerformingProductHeadWraper .topPerformingProductHead span:first-of-type {
      color: #212121;
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px; }
  .topPerformingProductHeadWraper .product-heads {
    height: auto !important;
    padding-bottom: 6px !important;
    padding: 0 24px;
    background-color: #f0f2f659;
    display: flex;
    align-items: center !important;
    justify-content: space-between; }
    .topPerformingProductHeadWraper .product-heads div {
      font-size: 12px;
      font-weight: 500;
      color: #262626;
      display: flex;
      align-items: center;
      justify-content: center; }
    .topPerformingProductHeadWraper .product-heads .name-head {
      justify-content: flex-start;
      width: 305px; }
    .topPerformingProductHeadWraper .product-heads .total-order-head {
      width: 70px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center; }
    .topPerformingProductHeadWraper .product-heads .attempts-percentage-head {
      width: 100px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center; }
    .topPerformingProductHeadWraper .product-heads .total-delivered-head {
      width: 90px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center; }
    .topPerformingProductHeadWraper .product-heads .delivered-percentage-head {
      width: 50px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center; }
    .topPerformingProductHeadWraper .product-heads .delivered-order-head {
      width: 80px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center; }
  .topPerformingProductHeadWraper .product-row-wraper {
    padding-top: 12px;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px; }
    .topPerformingProductHeadWraper .product-row-wraper .product-row {
      height: 56px;
      padding-left: 8px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row > div {
        font-size: 12px;
        font-weight: 500;
        color: #898989;
        display: flex;
        align-items: center;
        justify-content: center; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head {
        justify-content: flex-start;
        width: 295px; }
        .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper {
          height: 40px;
          display: flex;
          align-items: center; }
          .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .img {
            height: 40px;
            width: 40px;
            border-radius: 2px;
            border: 2px solid #cecece;
            margin-right: 16px; }
            .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .img img {
              height: 100%;
              object-fit: cover;
              width: 100%; }
          .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .details .name {
            font-size: 12px;
            color: #1162fb; }
          .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .details .id {
            font-size: 12px;
            color: #898989; }
          .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .details .name-wraper {
            display: flex;
            align-items: center;
            justify-content: flex-start; }
            .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .details .name-wraper svg {
              transition: all 0.3s linear;
              opacity: 0;
              margin-left: 6px; }
          .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .details .name-wraper:hover svg {
            transition: all 0.3s linear;
            opacity: 1; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .total-order-head {
        width: 70px;
        text-align: center; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .attempts-percentage-head {
        width: 100px;
        text-align: center; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .total-delivered-head {
        width: 90px !important;
        text-align: center; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .delivered-percentage-head {
        width: 50px;
        text-align: center; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .delivered-order-head {
        width: 80px !important;
        text-align: center; }
    .topPerformingProductHeadWraper .product-row-wraper .product-row:nth-child(2n) {
      background-color: #f5f5f5; }

.maps-container {
  display: flex;
  grid-gap: 24px;
  grid-gap: 0px;
  gap: 0px;
  flex-wrap: wrap; }
  .maps-container > div {
    width: 100% !important; }
  .maps-container .maps-wraper {
    margin-bottom: 40px;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000029;
    background-color: #fff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    width: 60%; }
    .maps-container .maps-wraper .not-found-wraper {
      margin-bottom: 0px; }
    .maps-container .maps-wraper > svg {
      position: relative !important;
      height: 500px !important; }
    .maps-container .maps-wraper .map-head-wraper {
      padding: 16px 24px 12px 24px;
      height: 72px;
      background-color: #f0f2f659;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead {
        font-size: 12px;
        color: #898989;
        position: relative;
        margin-bottom: 4px; }
        .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead .name {
          margin-left: 0px !important; }
        .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead span {
          font-size: 12px;
          color: #898989;
          margin-left: 4px; }
        .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead span:first-of-type {
          color: #212121;
          font-size: 16px;
          font-weight: 500;
          margin-right: 4px; }
      .maps-container .maps-wraper .map-head-wraper .product-heads {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        letter-spacing: 0.032em;
        padding-bottom: 6px;
        height: auto !important; }
        .maps-container .maps-wraper .map-head-wraper .product-heads div {
          font-size: 12px;
          font-weight: 400;
          color: #898989;
          display: flex;
          align-items: center;
          justify-content: center; }
        .maps-container .maps-wraper .map-head-wraper .product-heads .name-head {
          justify-content: flex-start; }
        .maps-container .maps-wraper .map-head-wraper .product-heads .delivered-order-head {
          width: 180px;
          justify-content: flex-end;
          padding-right: 8px; }
  .maps-container .scroller {
    max-height: 430px;
    overflow-x: hidden; }
  .maps-container .scroller.extra {
    max-height: 396px; }
  .maps-container .table {
    position: absolute;
    right: 32px;
    top: 100px; }
    .maps-container .table * {
      letter-spacing: 0.032em; }
    .maps-container .table .wraper-first {
      display: flex;
      min-width: 250px;
      border: 1px solid #c9c9c9;
      font-weight: 500;
      height: 24px;
      margin-bottom: 8px; }
      .maps-container .table .wraper-first .first-row {
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 12px;
        width: 71%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #f0f2f659; }
      .maps-container .table .wraper-first .second-row {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        width: 29%;
        align-items: center;
        justify-content: center; }
    .maps-container .table .wraper {
      display: flex;
      min-width: 250px;
      border: 1px solid #c9c9c9;
      font-weight: 500; }
      .maps-container .table .wraper .first-row {
        font-size: 12px;
        width: 150px;
        overflow: hidden;
        padding-left: 6px;
        padding-right: 6px; }
      .maps-container .table .wraper .head {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        background-color: #f0f2f659; }
      .maps-container .table .wraper .sub-head {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 22px; }
      .maps-container .table .wraper .first-row {
        border-left: 0px;
        justify-content: flex-start; }
      .maps-container .table .wraper .second-row {
        width: 100px; }
      .maps-container .table .wraper .third-row {
        width: 100px; }
      .maps-container .table .wraper .fourth-row {
        width: 70px; }
    .maps-container .table .wraper.wraper2 {
      border-top: 0px;
      font-weight: 400; }
    .maps-container .table .wraper.wraper2:nth-child(2n + 1) {
      background-color: #f5f5f5; }
  .maps-container .scroller::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px; }
  .maps-container .scroller::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
  .maps-container .scroller::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #898989; }

.HoverInfo {
  padding: 0px !important;
  min-width: 100px !important;
  max-width: 300px !important;
  z-index: 999999; }
  .HoverInfo .graph-custom-tooltip {
    border: 1px solid #000;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    opacity: 1;
    z-index: 999999;
    padding: 11px 16px;
    transition: top 200ms, left 200ms;
    font-size: 12px;
    min-width: 150px; }
    .HoverInfo .graph-custom-tooltip .bold-5 {
      font-weight: 500; }

.ant-alert-with-description * {
  letter-spacing: 0.032em; }

.ant-alert-with-description .ant-alert-message {
  font-size: 12px;
  font-weight: 600;
  color: #212121; }

.ant-alert-with-description .ant-alert-description {
  font-size: 12px;
  color: #212121; }

.table-first-head {
  background-color: #f0f2f6; }

.top-performing-table .ant-table-header {
  overflow: hidden !important; }

.top-performing-table .ant-table-tbody > tr > td {
  padding: 12px !important; }

.top-performing-table .ant-table-thead .ant-table-column-title {
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #262626 !important; }

.top-performing-table .ant-table-thead th {
  padding: 12px 6px !important; }

.top-performing-table tbody td {
  font-size: 12px !important; }

.top-performing-table .name-head {
  justify-content: flex-start;
  width: 295px; }
  .top-performing-table .name-head .product-wraper {
    height: 40px;
    display: flex;
    align-items: center; }
    .top-performing-table .name-head .product-wraper .img {
      height: 40px;
      width: 40px;
      border-radius: 2px;
      border: 2px solid #cecece;
      margin-right: 16px; }
      .top-performing-table .name-head .product-wraper .img img {
        height: 100%;
        object-fit: cover;
        width: 100%; }
    .top-performing-table .name-head .product-wraper .details .name {
      font-size: 12px;
      color: #1162fb;
      font-weight: 500; }
    .top-performing-table .name-head .product-wraper .details .id {
      font-size: 12px;
      color: #898989;
      font-weight: 500; }
    .top-performing-table .name-head .product-wraper .details .name-wraper {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .top-performing-table .name-head .product-wraper .details .name-wraper svg {
        transition: all 0.3s linear;
        opacity: 0;
        margin-left: 6px; }
    .top-performing-table .name-head .product-wraper .details .name-wraper:hover svg {
      transition: all 0.3s linear;
      opacity: 1; }

.order-transaction-nav .product-filter {
  width: 280px;
  margin-bottom: 6px;
  box-shadow: 0px 2px 8px #00000014;
  letter-spacing: 0 !important;
  position: relative;
  background-color: #ffffffd2 !important; }
  .order-transaction-nav .product-filter .custom-cross-ic {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translate(-50%, -50%);
    right: 0px;
    z-index: 999;
    height: 14px;
    width: 14px;
    background-color: #898989;
    font-weight: 500;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    padding-right: 1px;
    cursor: pointer; }
  .order-transaction-nav .product-filter svg {
    transition: 0.3s all; }
  .order-transaction-nav .product-filter .ant-select-selection {
    background-color: #ffffffd2 !important; }
  .order-transaction-nav .product-filter .ant-select-selection__choice {
    padding-left: 6px !important;
    padding-right: 6px !important; }
    .order-transaction-nav .product-filter .ant-select-selection__choice svg {
      display: none !important; }
  .order-transaction-nav .product-filter .ant-select.ant-select-enabled {
    width: 100%;
    padding-top: 4px;
    padding-bottom: 4px; }
  .order-transaction-nav .product-filter .ant-select-selection-selected-value {
    margin-right: 3px;
    padding-right: 3px; }
  .order-transaction-nav .product-filter .ant-select-open svg {
    transform: rotate(180deg) !important;
    fill: #898989 !important; }
    .order-transaction-nav .product-filter .ant-select-open svg path {
      fill: #898989 !important; }

.order-transaction-nav .Ndrnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .order-transaction-nav .Ndrnav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .order-transaction-nav .Ndrnav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .order-transaction-nav .Ndrnav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .order-transaction-nav .Ndrnav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      right: 15px; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .order-transaction-nav .Ndrnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 100px; }
    .order-transaction-nav .Ndrnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .order-transaction-nav .Ndrnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .order-transaction-nav .Ndrnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1268fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1268fb; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation {
        position: relative; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover {
        color: #4398ed !important; }
        .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation-click {
        position: relative; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .order-transaction-nav .Ndrnav .secondwrap .download_csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .order-transaction-nav .Ndrnav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .order-transaction-nav .Ndrnav .ant-calendar-picker-input.ant-input {
    width: 86%; }
  .order-transaction-nav .Ndrnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .order-transaction-nav .Ndrnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .order-transaction-nav .Ndrnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .order-transaction-nav .Ndrnav .ant-calendar-range-picker-input {
    width: 36.6% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .order-transaction-nav .Ndrnav .ant-calendar-picker-clear,
  .order-transaction-nav .Ndrnav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.order-transaction-nav .bottom_data {
  display: flex;
  justify-content: space-between;
  padding-top: 90px;
  margin-top: 32px;
  padding-top: 90px; }

.order-transaction-nav .ant-select-selection__placeholder {
  color: #1268fb; }

.order-transaction-nav .drop {
  background-color: #f8f8f8 !important;
  box-shadow: 0px 2px 8px #00000014;
  letter-spacing: 0 !important; }
  .order-transaction-nav .drop > div {
    height: 40px !important; }
  .order-transaction-nav .drop .ant-select.ant-select-enabled {
    height: 40px !important; }
  .order-transaction-nav .drop .ant-select-selection__rendered {
    margin-left: 16px;
    background-color: #f8f8f8 !important;
    margin-right: 2px;
    height: 40px !important; }
    .order-transaction-nav .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
      height: 40px !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .order-transaction-nav .drop .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .order-transaction-nav .drop p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #898989 !important; }
  .order-transaction-nav .drop .ant-select-selection-selected-value {
    height: 100% !important;
    line-height: 32px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: 13px;
    color: #1268fb; }
    .order-transaction-nav .drop .ant-select-selection-selected-value .anticon svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px; }
  .order-transaction-nav .drop .ant-select-selection {
    width: 159px;
    height: 40px !important;
    border: none !important;
    background: #f8f8f8 !important; }
    .order-transaction-nav .drop .ant-select-selection .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .order-transaction-nav .drop .ant-select-selection .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .order-transaction-nav .drop .ant-select-arrow {
    top: 53%;
    left: 159% !important; }

@media screen and (min-width: 1440px) {
  .Ndrnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .Ndrnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.non-cursor-pointer {
  pointer-events: none;
  border-bottom: none !important; }

.overlay-footer-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9999; }
  .overlay-footer-bottom button {
    background: #1268fb !important;
    color: #fff !important;
    margin-bottom: 4px; }
  .overlay-footer-bottom button:hover {
    color: #fff !important; }

.global-tooltip-black {
  display: flex;
  align-items: center;
  justify-content: center; }

.global-tooltip-icon-black {
  font-size: 16px !important;
  margin: 0px 8px !important; }

.global-tooltip-black:hover .global-tooltip-icon-black {
  border-radius: 50%;
  cursor: pointer; }
  .global-tooltip-black:hover .global-tooltip-icon-black svg g {
    stroke: #ffffff; }
  .global-tooltip-black:hover .global-tooltip-icon-black svg :last-child g {
    stroke: #26b99a; }

.global-tooltip-black:hover .global-tooltip-icon-black g text {
  fill: #fff; }

.global-tooltip-black:hover .global-tooltip-icon-black g > g {
  stroke: #ffffff; }

.maps-container {
  display: flex;
  grid-gap: 24px;
  grid-gap: 0px;
  gap: 0px;
  flex-wrap: wrap; }
  .maps-container > div {
    width: 100% !important; }
  .maps-container .maps-wraper {
    margin-bottom: 40px;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000029;
    background-color: #fff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    width: 60%; }
    .maps-container .maps-wraper .not-found-wraper {
      margin-bottom: 0px; }
    .maps-container .maps-wraper > svg {
      position: relative !important;
      height: 500px !important; }
    .maps-container .maps-wraper .map-head-wraper {
      padding: 16px 24px 12px 24px;
      height: 72px;
      background-color: #f0f2f659;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead {
        font-size: 12px;
        color: #898989;
        position: relative;
        margin-bottom: 4px; }
        .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead .name {
          margin-left: 0px !important; }
        .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead span {
          font-size: 12px;
          color: #898989;
          margin-left: 4px; }
        .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead span:first-of-type {
          color: #212121;
          font-size: 16px;
          font-weight: 500;
          margin-right: 4px; }
      .maps-container .maps-wraper .map-head-wraper .product-heads {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        letter-spacing: 0.032em; }
        .maps-container .maps-wraper .map-head-wraper .product-heads div {
          font-size: 12px;
          font-weight: 400;
          color: #898989;
          display: flex;
          align-items: center;
          justify-content: center; }
        .maps-container .maps-wraper .map-head-wraper .product-heads .name-head {
          justify-content: flex-start; }
        .maps-container .maps-wraper .map-head-wraper .product-heads .delivered-order-head {
          width: 180px;
          justify-content: flex-end;
          padding-right: 8px; }
  .maps-container .scroller {
    max-height: 430px;
    overflow-x: hidden; }
  .maps-container .scroller.extra {
    max-height: 396px; }
  .maps-container .map-table-wraper {
    width: 55% !important;
    position: absolute;
    right: 0.5px;
    top: 72px; }
    .maps-container .map-table-wraper * {
      letter-spacing: 0.032em; }
    .maps-container .map-table-wraper .wraper-first {
      display: flex;
      min-width: 250px;
      border: 1px solid #c9c9c9;
      font-weight: 500;
      height: 24px;
      margin-bottom: 8px; }
      .maps-container .map-table-wraper .wraper-first .first-row {
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 12px;
        width: 71%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #f0f2f659; }
      .maps-container .map-table-wraper .wraper-first .second-row {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        width: 29%;
        align-items: center;
        justify-content: center; }
    .maps-container .map-table-wraper .wraper {
      display: flex;
      min-width: 250px;
      border: 1px solid #c9c9c9;
      font-weight: 500; }
      .maps-container .map-table-wraper .wraper .first-row {
        font-size: 12px;
        width: 150px;
        overflow: hidden;
        padding-left: 6px;
        padding-right: 6px; }
      .maps-container .map-table-wraper .wraper .head {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        background-color: #f0f2f659; }
      .maps-container .map-table-wraper .wraper .sub-head {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 22px; }
      .maps-container .map-table-wraper .wraper .first-row {
        border-left: 0px;
        justify-content: flex-start; }
      .maps-container .map-table-wraper .wraper .second-row {
        width: 100px; }
      .maps-container .map-table-wraper .wraper .third-row {
        width: 100px; }
      .maps-container .map-table-wraper .wraper .fourth-row {
        width: 70px; }
    .maps-container .map-table-wraper .wraper.wraper2 {
      border-top: 0px;
      font-weight: 400; }
    .maps-container .map-table-wraper .wraper.wraper2:nth-child(2n + 1) {
      background-color: #f5f5f5; }
  .maps-container .scroller::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px; }
  .maps-container .scroller::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
  .maps-container .scroller::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #898989; }

.HoverInfo {
  padding: 0px !important;
  min-width: 100px !important;
  max-width: 300px !important;
  z-index: 999999; }
  .HoverInfo .graph-custom-tooltip {
    border: 1px solid #000;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    opacity: 1;
    z-index: 999999;
    padding: 11px 16px;
    transition: top 200ms, left 200ms;
    font-size: 12px;
    min-width: 150px; }
    .HoverInfo .graph-custom-tooltip .bold-5 {
      font-weight: 500; }

.ant-alert-with-description * {
  letter-spacing: 0.032em; }

.ant-alert-with-description .ant-alert-message {
  font-size: 12px;
  font-weight: 600;
  color: #212121; }

.ant-alert-with-description .ant-alert-description {
  font-size: 12px;
  color: #212121; }

.table-first-head {
  background-color: #f0f2f6; }

.map-table-wraper {
  overflow-x: scroll;
  overflow-y: hidden;
  float: right;
  min-height: 400px;
  max-height: 500px; }
  .map-table-wraper .ant-table-header {
    overflow: hidden !important; }
  .map-table-wraper * {
    letter-spacing: 0.032em; }
  .map-table-wraper .ant-spin-container {
    border: 1px solid #e8e8e8; }
  .map-table-wraper .global-tooltip-w i {
    margin-right: 0px !important; }
  .map-table-wraper .global-tooltip-w:hover #icomponent1 {
    stroke: #0c182a; }
  .map-table-wraper .global-tooltip-w:hover #icomponent2 {
    stroke: #0c182a; }
  .map-table-wraper .tooltip-title {
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .map-table-wraper .tooltip-title > span {
      width: 40px; }
    .map-table-wraper .tooltip-title .global-tooltip-w {
      width: -webkit-max-content !important;
      width: max-content !important;
      min-width: -webkit-max-content !important;
      min-width: max-content !important; }
  .map-table-wraper .ant-table-thead tr {
    height: 32px; }
    .map-table-wraper .ant-table-thead tr th {
      background-color: #f0f2f659;
      color: #fff !important;
      font-size: 12px;
      height: 32px;
      padding: 0px; }
      .map-table-wraper .ant-table-thead tr th span {
        display: block;
        min-width: 100px;
        text-align: -webkit-center; }
      .map-table-wraper .ant-table-thead tr th .ant-table-column-title {
        text-align: center;
        color: #212121;
        display: block;
        width: -webkit-max-content;
        width: max-content; }
    .map-table-wraper .ant-table-thead tr .ant-table-column-has-actions.ant-table-column-has-sorters {
      position: relative; }
      .map-table-wraper .ant-table-thead tr .ant-table-column-has-actions.ant-table-column-has-sorters .ant-table-column-sorters .ant-table-column-sorter {
        position: absolute;
        right: -36px; }
    .map-table-wraper .ant-table-thead tr th:first-of-type span {
      display: block;
      text-align: left; }
  .map-table-wraper .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0px; }
  .map-table-wraper .ant-table-body tr td {
    border-bottom: 0px !important;
    border-top: 0px !important;
    font-size: 12px;
    padding: 6px;
    text-align: center; }
    .map-table-wraper .ant-table-body tr td span {
      font-size: 12px; }
  .map-table-wraper .ant-table-body tr td:first-of-type {
    text-align: left; }
  .map-table-wraper .ant-table-body tr:nth-child(even) {
    background: #f5f5f5; }
  .map-table-wraper .ant-table-fixed-right .ant-table-thead > tr > th:first-child {
    border-top-right-radius: 0px; }
  .map-table-wraper .ant-table-fixed-right .ant-table-thead tr th span {
    text-align: center; }
  .map-table-wraper .ant-table-fixed-right td {
    font-size: 12px;
    border-bottom: 0px;
    text-align: center;
    padding: 6px; }
    .map-table-wraper .ant-table-fixed-right td span {
      font-size: 12px;
      border-bottom: 0px; }
  .map-table-wraper .ant-table-fixed-right tr:nth-child(even) {
    background: #f5f5f5; }
  .map-table-wraper .ant-table-fixed-right,
  .map-table-wraper .ant-table-fixed-right table {
    border-radius: 0 0 0 0; }
  .map-table-wraper .ant-table-fixed-left,
  .map-table-wraper .ant-table-fixed-left table {
    border-radius: 0 0 0 0; }
  .map-table-wraper .ant-table-fixed-left .ant-table-fixed .ant-table-thead .ant-table-column-sorters {
    width: 100%; }
  .map-table-wraper .ant-table-fixed-left .ant-table-row.ant-table-row-level-0 {
    height: 30px; }
    .map-table-wraper .ant-table-fixed-left .ant-table-row.ant-table-row-level-0 .ant-table-column-has-actions.ant-table-column-has-sorters {
      padding: 0px !important;
      padding-left: 16px !important; }
  .map-table-wraper .ant-table-fixed-left .ant-table-thead tr th span {
    text-align: left;
    padding-left: 16px; }
    .map-table-wraper .ant-table-fixed-left .ant-table-thead tr th span span {
      padding-left: 0px; }
  .map-table-wraper .ant-table-fixed-left .ant-table-thead .ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort .ant-table-column-sorters {
    position: relative; }
    .map-table-wraper .ant-table-fixed-left .ant-table-thead .ant-table-column-has-actions.ant-table-column-has-sorters.ant-table-column-sort .ant-table-column-sorters .ant-table-column-sorter {
      position: absolute; }
  .map-table-wraper .ant-table-fixed-left td {
    font-size: 12px;
    border-bottom: 0px;
    text-align: left; }
    .map-table-wraper .ant-table-fixed-left td span {
      font-size: 12px;
      border-bottom: 0px; }
  .map-table-wraper .ant-table-fixed-left tr:nth-child(even) {
    background: #f5f5f5; }

.topPerformingProductHeadWraper {
  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;
  background-color: #fff; }
  .topPerformingProductHeadWraper * {
    letter-spacing: 0.032em !important; }
  .topPerformingProductHeadWraper .topPerformingProductHead {
    height: 56px;
    background-color: #f0f2f659;
    padding: 16px 24px;
    font-size: 12px;
    color: #898989;
    position: relative; }
    .topPerformingProductHeadWraper .topPerformingProductHead .name {
      margin-left: 0px !important; }
    .topPerformingProductHeadWraper .topPerformingProductHead span {
      font-size: 12px;
      color: #898989;
      margin-left: 4px; }
    .topPerformingProductHeadWraper .topPerformingProductHead span:first-of-type {
      color: #212121;
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px; }
  .topPerformingProductHeadWraper .product-heads {
    height: 32px;
    padding: 0 24px;
    background-color: #f0f2f659;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
    .topPerformingProductHeadWraper .product-heads div {
      font-size: 12px;
      font-weight: 500;
      color: #262626;
      display: flex;
      align-items: center;
      justify-content: center; }
    .topPerformingProductHeadWraper .product-heads .name-head {
      justify-content: flex-start;
      width: 305px; }
    .topPerformingProductHeadWraper .product-heads .total-order-head {
      width: 90px; }
    .topPerformingProductHeadWraper .product-heads .total-delivered-head {
      width: 146px; }
    .topPerformingProductHeadWraper .product-heads .delivered-percentage-head {
      width: 65px; }
    .topPerformingProductHeadWraper .product-heads .delivered-order-head {
      width: 190px; }
  .topPerformingProductHeadWraper .product-row-wraper {
    padding-top: 12px;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px; }
    .topPerformingProductHeadWraper .product-row-wraper .product-row {
      height: 56px;
      padding-left: 8px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row > div {
        font-size: 12px;
        font-weight: 500;
        color: #898989;
        display: flex;
        align-items: center;
        justify-content: center; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head {
        justify-content: flex-start;
        width: 295px; }
        .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper {
          height: 40px;
          display: flex;
          align-items: center; }
          .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .img {
            height: 40px;
            width: 40px;
            border-radius: 2px;
            border: 2px solid #cecece;
            margin-right: 16px; }
            .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .img img {
              height: 100%;
              object-fit: cover;
              width: 100%; }
          .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .details .name {
            font-size: 12px;
            color: #1162fb; }
          .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .details .id {
            font-size: 12px;
            color: #898989; }
          .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .details .name-wraper {
            display: flex;
            align-items: center;
            justify-content: flex-start; }
            .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .details .name-wraper svg {
              transition: all 0.3s linear;
              opacity: 0;
              margin-left: 6px; }
          .topPerformingProductHeadWraper .product-row-wraper .product-row .name-head .product-wraper .details .name-wraper:hover svg {
            transition: all 0.3s linear;
            opacity: 1; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .total-order-head {
        width: 90px; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .total-delivered-head {
        width: 146px; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .delivered-percentage-head {
        width: 65px; }
      .topPerformingProductHeadWraper .product-row-wraper .product-row .delivered-order-head {
        width: 190px; }
    .topPerformingProductHeadWraper .product-row-wraper .product-row:nth-child(2n) {
      background-color: #f5f5f5; }

.maps-container {
  display: flex;
  grid-gap: 24px;
  grid-gap: 0px;
  gap: 0px;
  flex-wrap: wrap; }
  .maps-container > div {
    width: 100% !important; }
  .maps-container .maps-wraper {
    margin-bottom: 40px;
    border-radius: 4px;
    box-shadow: 0 3px 6px #00000029;
    background-color: #fff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    width: 60%; }
    .maps-container .maps-wraper .not-found-wraper {
      margin-bottom: 0px; }
    .maps-container .maps-wraper > svg {
      position: relative !important;
      height: 500px !important; }
    .maps-container .maps-wraper .map-head-wraper {
      padding: 16px 24px 12px 24px;
      height: 72px;
      background-color: #f0f2f659;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead {
        font-size: 12px;
        color: #898989;
        position: relative;
        margin-bottom: 4px; }
        .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead .name {
          margin-left: 0px !important; }
        .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead span {
          font-size: 12px;
          color: #898989;
          margin-left: 4px; }
        .maps-container .maps-wraper .map-head-wraper .topPerformingProductHead span:first-of-type {
          color: #212121;
          font-size: 16px;
          font-weight: 500;
          margin-right: 4px; }
      .maps-container .maps-wraper .map-head-wraper .product-heads {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        letter-spacing: 0.032em; }
        .maps-container .maps-wraper .map-head-wraper .product-heads div {
          font-size: 12px;
          font-weight: 400;
          color: #898989;
          display: flex;
          align-items: center;
          justify-content: center; }
        .maps-container .maps-wraper .map-head-wraper .product-heads .name-head {
          justify-content: flex-start; }
        .maps-container .maps-wraper .map-head-wraper .product-heads .delivered-order-head {
          width: 180px;
          justify-content: flex-end;
          padding-right: 8px; }
  .maps-container .scroller {
    max-height: 430px;
    overflow-x: hidden; }
  .maps-container .scroller.extra {
    max-height: 396px; }
  .maps-container .table {
    position: absolute;
    right: 32px;
    top: 100px; }
    .maps-container .table * {
      letter-spacing: 0.032em; }
    .maps-container .table .wraper-first {
      display: flex;
      min-width: 250px;
      border: 1px solid #c9c9c9;
      font-weight: 500;
      height: 24px;
      margin-bottom: 8px; }
      .maps-container .table .wraper-first .first-row {
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        font-size: 12px;
        width: 71%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: #f0f2f659; }
      .maps-container .table .wraper-first .second-row {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        width: 29%;
        align-items: center;
        justify-content: center; }
    .maps-container .table .wraper {
      display: flex;
      min-width: 250px;
      border: 1px solid #c9c9c9;
      font-weight: 500; }
      .maps-container .table .wraper .first-row {
        font-size: 12px;
        width: 150px;
        overflow: hidden;
        padding-left: 6px;
        padding-right: 6px; }
      .maps-container .table .wraper .head {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 32px;
        background-color: #f0f2f659; }
      .maps-container .table .wraper .sub-head {
        border-left: 1px solid #c9c9c9;
        font-size: 12px;
        padding-left: 6px;
        padding-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 22px; }
      .maps-container .table .wraper .first-row {
        border-left: 0px;
        justify-content: flex-start; }
      .maps-container .table .wraper .second-row {
        width: 100px; }
      .maps-container .table .wraper .third-row {
        width: 100px; }
      .maps-container .table .wraper .fourth-row {
        width: 70px; }
    .maps-container .table .wraper.wraper2 {
      border-top: 0px;
      font-weight: 400; }
    .maps-container .table .wraper.wraper2:nth-child(2n + 1) {
      background-color: #f5f5f5; }
  .maps-container .scroller::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px; }
  .maps-container .scroller::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-color: #898989;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }
  .maps-container .scroller::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #898989; }

.HoverInfo {
  padding: 0px !important;
  min-width: 100px !important;
  max-width: 300px !important;
  z-index: 999999; }
  .HoverInfo .graph-custom-tooltip {
    border: 1px solid #000;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    opacity: 1;
    z-index: 999999;
    padding: 11px 16px;
    transition: top 200ms, left 200ms;
    font-size: 12px;
    min-width: 150px; }
    .HoverInfo .graph-custom-tooltip .bold-5 {
      font-weight: 500; }

.ant-alert-with-description * {
  letter-spacing: 0.032em; }

.ant-alert-with-description .ant-alert-message {
  font-size: 12px;
  font-weight: 600;
  color: #212121; }

.ant-alert-with-description .ant-alert-description {
  font-size: 12px;
  color: #212121; }

.table-first-head {
  background-color: #f0f2f6; }

.order-transaction-nav .Ndrnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .order-transaction-nav .Ndrnav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .order-transaction-nav .Ndrnav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .order-transaction-nav .Ndrnav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .order-transaction-nav .Ndrnav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      right: 15px; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .order-transaction-nav .Ndrnav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .order-transaction-nav .Ndrnav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .order-transaction-nav .Ndrnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .order-transaction-nav .Ndrnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .order-transaction-nav .Ndrnav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1268fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1268fb; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation {
        position: relative; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover {
        color: #4398ed !important; }
        .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation-click {
        position: relative; }
      .order-transaction-nav .Ndrnav .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .order-transaction-nav .Ndrnav .secondwrap .download_csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .order-transaction-nav .Ndrnav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .order-transaction-nav .Ndrnav .ant-calendar-picker-input.ant-input {
    width: 86%; }
  .order-transaction-nav .Ndrnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .order-transaction-nav .Ndrnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .order-transaction-nav .Ndrnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .order-transaction-nav .Ndrnav .ant-calendar-range-picker-input {
    width: 36.6% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .order-transaction-nav .Ndrnav .ant-calendar-picker-clear,
  .order-transaction-nav .Ndrnav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.order-transaction-nav .bottom_data {
  display: flex;
  justify-content: space-between;
  padding-top: 90px;
  margin-top: 32px;
  padding-top: 90px; }

.order-transaction-nav .ant-select-selection__placeholder {
  color: #1268fb; }

.order-transaction-nav .drop {
  background-color: #f8f8f8 !important;
  box-shadow: 0px 2px 8px #00000014;
  letter-spacing: 0 !important; }
  .order-transaction-nav .drop > div {
    height: 40px !important; }
  .order-transaction-nav .drop .ant-select.ant-select-enabled {
    height: 40px !important; }
  .order-transaction-nav .drop .ant-select-selection__rendered {
    margin-left: 16px;
    background-color: #f8f8f8 !important;
    margin-right: 2px;
    height: 40px !important; }
    .order-transaction-nav .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
      height: 40px !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .order-transaction-nav .drop .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .order-transaction-nav .drop p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #898989 !important; }
  .order-transaction-nav .drop .ant-select-selection-selected-value {
    height: 100% !important;
    line-height: 32px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: 13px;
    color: #1268fb; }
    .order-transaction-nav .drop .ant-select-selection-selected-value .anticon svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px; }
  .order-transaction-nav .drop .ant-select-selection {
    width: 159px;
    height: 40px !important;
    border: none !important;
    background: #f8f8f8 !important; }
    .order-transaction-nav .drop .ant-select-selection .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .order-transaction-nav .drop .ant-select-selection .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .order-transaction-nav .drop .ant-select-arrow {
    top: 53%;
    left: 159% !important; }

@media screen and (min-width: 1440px) {
  .Ndrnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .Ndrnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.non-cursor-pointer {
  pointer-events: none;
  border-bottom: none !important; }

.orders-to-vf-graph-wraper.pie {
  position: relative;
  margin-bottom: 40px;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;
  background-color: #fff; }
  .orders-to-vf-graph-wraper.pie * {
    letter-spacing: 0.032em; }
  .orders-to-vf-graph-wraper.pie .circle-color-wraper {
    display: flex;
    align-items: center;
    justify-content: center; }
    .orders-to-vf-graph-wraper.pie .circle-color-wraper .circle {
      height: 20px;
      width: 20px;
      border-radius: 50%; }
  .orders-to-vf-graph-wraper.pie .graph-head {
    height: 56px;
    background-color: #f0f2f659;
    padding: 16px 24px 12px 24px;
    font-size: 12px;
    color: #898989;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .orders-to-vf-graph-wraper.pie .graph-head span {
      font-size: 12px;
      color: #898989;
      margin-left: 4px; }
    .orders-to-vf-graph-wraper.pie .graph-head .name {
      margin-left: 0px; }
    .orders-to-vf-graph-wraper.pie .graph-head span:first-of-type {
      color: #212121;
      font-size: 16px;
      font-weight: 500;
      margin-right: 4px; }
    .orders-to-vf-graph-wraper.pie .graph-head .abs-div {
      position: absolute;
      display: flex;
      align-items: center;
      bottom: -48px;
      left: 48px;
      justify-content: flex-start; }
      .orders-to-vf-graph-wraper.pie .graph-head .abs-div > div {
        margin-right: 34px; }
      .orders-to-vf-graph-wraper.pie .graph-head .abs-div svg {
        margin-right: 2px; }
      .orders-to-vf-graph-wraper.pie .graph-head .abs-div span {
        font-size: 12px;
        color: #212121;
        font-weight: 500; }
    .orders-to-vf-graph-wraper.pie .graph-head .compare {
      display: flex;
      align-items: center; }
  .orders-to-vf-graph-wraper.pie .recharts-wrapper {
    padding-left: 6px;
    padding-right: 6px;
    z-index: 9 !important;
    margin-top: 0px !important;
    right: 32px !important; }
    .orders-to-vf-graph-wraper.pie .recharts-wrapper .recharts-surface {
      width: 100% !important; }
    .orders-to-vf-graph-wraper.pie .recharts-wrapper .yAxis {
      font-size: 10px; }
    .orders-to-vf-graph-wraper.pie .recharts-wrapper .xAxis g {
      font-size: 14px; }

.graph-custom-tooltip {
  border: 1px solid #000;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  opacity: 1;
  padding: 11px 16px;
  transition: top 200ms, left 200ms;
  font-size: 12px;
  min-width: 250px; }
  .graph-custom-tooltip .light-bold {
    font-weight: 500; }
  .graph-custom-tooltip .date {
    margin-bottom: 7px; }
  .graph-custom-tooltip .details {
    display: flex; }
    .graph-custom-tooltip .details .icon {
      display: flex;
      align-items: flex-start;
      margin-top: 6px;
      margin-right: 6px; }
      .graph-custom-tooltip .details .icon svg {
        font-size: 6px; }
    .graph-custom-tooltip .details .text {
      text-transform: capitalize; }
      .graph-custom-tooltip .details .text p {
        font-weight: 500; }
      .graph-custom-tooltip .details .text p:first-of-type {
        font-weight: 400; }
  .graph-custom-tooltip .details.last {
    margin-top: 14px; }

.map-table-wraper.pie {
  position: absolute;
  right: 0;
  top: 50%;
  padding: 0px 10px;
  transform: translateY(-45%);
  overflow-x: scroll;
  overflow-y: scroll;
  float: right;
  min-height: 400px;
  width: 46%; }
  .map-table-wraper.pie * {
    letter-spacing: 0.032em; }
  .map-table-wraper.pie .row-wraper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 6px 2px;
    border: 1px solid transparent;
    transition: 0.3s all linear; }
    .map-table-wraper.pie .row-wraper > .row1 {
      padding-left: 6px; }
  .map-table-wraper.pie .row-wraper.head div {
    font-weight: 500; }
  .map-table-wraper.pie .activeTooltip {
    transition: 0.3s all linear;
    background-color: #e6f4ff !important;
    border: 1px solid #91d5ff; }
    .map-table-wraper.pie .activeTooltip .row {
      font-weight: 500 !important; }
  .map-table-wraper.pie .row {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #212121;
    font-weight: 400; }
  .map-table-wraper.pie .row1 {
    width: 40%;
    justify-content: flex-start; }
  .map-table-wraper.pie .row2 {
    width: 20%; }
  .map-table-wraper.pie .row3 {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .map-table-wraper.pie .row3 .circle {
      height: 20px;
      width: 20px;
      border-radius: 50%; }
  .map-table-wraper.pie .row-wraper:nth-child(even) {
    background: #f5f5f5; }

.collapse-item {
  width: 992px;
  margin: auto;
  border-radius: 0px !important;
  letter-spacing: 0.032em; }
  .collapse-item .ic-w {
    margin-top: 8px; }
    .collapse-item .ic-w span {
      display: flex !important;
      align-items: center;
      font-size: 11px; }
  .collapse-item .ant-collapse-item {
    border-radius: 0px !important; }
    .collapse-item .ant-collapse-item .container {
      background: #ffffff !important;
      padding: 8px 24px 8px 24px !important;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .collapse-item .ant-collapse-item .container .prod-details {
        flex-basis: 25%;
        display: flex;
        align-items: center;
        cursor: auto; }
        .collapse-item .ant-collapse-item .container .prod-details p {
          font-size: 12px; }
        .collapse-item .ant-collapse-item .container .prod-details .img {
          height: 40px;
          width: 40px;
          background: gray;
          border: 2px solid #f5f5f5;
          margin-right: 12px; }
          .collapse-item .ant-collapse-item .container .prod-details .img img {
            height: 100%;
            width: 100%;
            object-fit: cover; }
      .collapse-item .ant-collapse-item .container .live-inv {
        flex-basis: 12%;
        text-align: center;
        font-size: 12px; }
      .collapse-item .ant-collapse-item .container .expand {
        flex-direction: column; }
      .collapse-item .ant-collapse-item .container .pricing {
        flex-basis: 16%;
        text-align: center;
        font-size: 12px; }
        .collapse-item .ant-collapse-item .container .pricing .price-values {
          display: flex;
          align-items: center;
          justify-content: space-between; }
      .collapse-item .ant-collapse-item .container .pricing span {
        font-size: 9px;
        line-height: 14px; }
      .collapse-item .ant-collapse-item .container .dist {
        flex-basis: 16%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 12px; }
        .collapse-item .ant-collapse-item .container .dist p:first-of-type {
          margin-top: 4px; }
        .collapse-item .ant-collapse-item .container .dist p {
          font-size: 9px;
          line-height: 14px; }
      .collapse-item .ant-collapse-item .container .flexible {
        flex-basis: 10%;
        text-align: center; }
        .collapse-item .ant-collapse-item .container .flexible .restock-button {
          background-color: #ffba39 !important;
          box-shadow: 0px 2px 8px #002f7225 !important;
          border-radius: 2px !important;
          font-weight: 500 !important;
          font-size: 12px !important;
          color: #fff !important;
          text-shadow: none !important;
          cursor: pointer;
          padding: 6px 8px;
          display: flex;
          align-items: center; }
        .collapse-item .ant-collapse-item .container .flexible .restock-button:hover {
          background-color: #ee9e07 !important; }
      .collapse-item .ant-collapse-item .container .expand {
        flex-basis: 12%;
        text-align: right;
        color: #1370fc;
        font-weight: 500;
        font-size: 12px; }
  .collapse-item .ant-collapse-header {
    border-radius: 0px !important;
    padding: 0 !important; }
  .collapse-item .ant-collapse-content {
    background: #f5f5f5; }
  .collapse-item .ant-collapse-content-box {
    padding: 0 24px !important; }
  .collapse-item .head-inner {
    background-color: #f5f5f5;
    margin: auto;
    justify-content: flex-start;
    display: flex;
    box-shadow: none !important;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 6px;
    font-size: 12px; }
    .collapse-item .head-inner .prod-details {
      flex-basis: 33.5%;
      font-weight: 500;
      text-decoration: underline; }
    .collapse-item .head-inner .live-inv {
      flex-basis: 12%;
      text-align: center;
      font-weight: 500;
      text-decoration: underline; }
    .collapse-item .head-inner .pricing {
      flex-basis: 16%;
      text-align: center;
      font-weight: 500;
      text-decoration: underline; }
    .collapse-item .head-inner .dist {
      flex-basis: 16%;
      text-align: center;
      font-weight: 500;
      text-decoration: underline; }
    .collapse-item .head-inner .expand {
      flex-basis: 12%;
      text-align: right;
      font-weight: 500;
      text-decoration: underline;
      opacity: 0; }
  .collapse-item .bottom-head2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px !important; }
    .collapse-item .bottom-head2 .prod-details {
      flex-basis: 33.5%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      cursor: auto;
      font-size: 12px; }
      .collapse-item .bottom-head2 .prod-details span {
        font-weight: 500; }
      .collapse-item .bottom-head2 .prod-details p {
        font-size: 12px; }
      .collapse-item .bottom-head2 .prod-details .img {
        height: 40px;
        width: 40px;
        background: gray;
        border: 2px solid #f5f5f5;
        margin-right: 12px; }
        .collapse-item .bottom-head2 .prod-details .img img {
          height: 100%;
          width: 100%;
          object-fit: cover; }
    .collapse-item .bottom-head2 .live-inv {
      flex-basis: 12%;
      text-align: center;
      font-size: 12px; }
    .collapse-item .bottom-head2 .pricing {
      flex-basis: 16%;
      text-align: center;
      font-size: 12px; }
    .collapse-item .bottom-head2 .dist {
      flex-basis: 16%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px; }
    .collapse-item .bottom-head2 .expand {
      flex-basis: 12%;
      text-align: right;
      opacity: 0;
      color: #1370fc;
      font-weight: 500;
      font-size: 12px; }
  .collapse-item .bottom-head2:last-of-type {
    margin-bottom: 0; }
  .collapse-item .product-link-name p a:hover {
    text-decoration: underline !important; }
  .collapse-item .fl {
    width: 90% !important; }
    .collapse-item .fl span {
      color: #212121;
      font-weight: 500; }

.ant-collapse.ant-collapse-icon-position-right.collapse-item {
  width: auto; }

.sourcing-order-card .ant-card-body {
  padding: 0px; }

.sourcing-order-card .rto-status {
  padding: 5px;
  padding-left: 15px;
  position: absolute;
  left: -10px;
  top: 8px;
  color: white;
  background: #15dd24;
  border-radius: 2px;
  border-bottom-left-radius: 0;
  padding-right: 20px; }

.sourcing-order-card .rto-status:before {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  top: 100%;
  left: 0px;
  border-bottom: 9px solid #15dd24;
  border-left: 9px solid transparent;
  transform: rotate(270deg); }

.sourcing-order-card .sourcing-card-header {
  padding: 24px; }
  .sourcing-order-card .sourcing-card-header .sourcing-order-image-container {
    width: 15%; }
  .sourcing-order-card .sourcing-card-header .sourcing-order-details {
    padding-left: 24px;
    flex: 1 1; }
    .sourcing-order-card .sourcing-card-header .sourcing-order-details h4 {
      margin-bottom: 0px;
      font-size: 14px;
      color: black;
      font-weight: 500; }
    .sourcing-order-card .sourcing-card-header .sourcing-order-details p {
      margin-bottom: 0px;
      font-size: 16px;
      line-height: 22px;
      color: #585858;
      font-weight: 400; }
  .sourcing-order-card .sourcing-card-header .soucing-order-info {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .sourcing-order-card .sourcing-card-header .sourcing-partner-info {
    display: flex;
    justify-content: space-around;
    background: #eeeeee;
    padding: 8px;
    margin-top: 12px; }
    .sourcing-order-card .sourcing-card-header .sourcing-partner-info p {
      margin-bottom: 0px;
      font-size: 14px;
      color: black;
      line-height: 22px;
      font-weight: 500; }
      .sourcing-order-card .sourcing-card-header .sourcing-partner-info p span {
        font-size: 16px;
        font-weight: 400;
        color: #585858; }

.sourcing-order-card .view-more-button {
  text-align: center;
  margin-top: 13px; }
  .sourcing-order-card .view-more-button button {
    font-size: 14px;
    height: 36px; }

.new-inventory-card-logs .head {
  width: auto !important; }

.ant-collapse.ant-collapse-icon-position-right.collapse-item-logs {
  width: auto; }

.collapse-item-logs {
  width: 992px;
  margin: auto;
  border-radius: 0px !important;
  letter-spacing: 0.032em; }
  .collapse-item-logs .ant-collapse-item {
    border-radius: 0px !important; }
    .collapse-item-logs .ant-collapse-item .container {
      background: #ffffff !important;
      padding: 8px 24px 8px 24px !important;
      display: flex;
      align-items: center; }
      .collapse-item-logs .ant-collapse-item .container .details {
        width: 40%;
        height: 40px;
        display: flex;
        align-items: center; }
        .collapse-item-logs .ant-collapse-item .container .details .img {
          height: 40px;
          width: 40px;
          background: gray;
          border: 2px solid #f5f5f5;
          margin-right: 24px; }
          .collapse-item-logs .ant-collapse-item .container .details .img img {
            height: 100%;
            width: 100%;
            object-fit: cover; }
        .collapse-item-logs .ant-collapse-item .container .details p {
          font-size: 12px; }
      .collapse-item-logs .ant-collapse-item .container .InventoryE {
        width: 40%;
        font-size: 12px; }
        .collapse-item-logs .ant-collapse-item .container .InventoryE .view-in-tab-link:hover span {
          text-decoration: underline !important; }
        .collapse-item-logs .ant-collapse-item .container .InventoryE a:hover {
          text-decoration: underline; }
        .collapse-item-logs .ant-collapse-item .container .InventoryE .flex-rto {
          display: flex;
          align-items: center;
          justify-content: flex-start; }
          .collapse-item-logs .ant-collapse-item .container .InventoryE .flex-rto p {
            margin-left: 4px; }
        .collapse-item-logs .ant-collapse-item .container .InventoryE .flex-rto:hover {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer; }
          .collapse-item-logs .ant-collapse-item .container .InventoryE .flex-rto:hover p {
            text-decoration: underline; }
      .collapse-item-logs .ant-collapse-item .container :nth-child(3) {
        width: 20%;
        font-size: 12px; }
  .collapse-item-logs .ant-collapse-header {
    border-radius: 0px !important;
    padding: 0 !important; }
  .collapse-item-logs .ant-collapse-content {
    background: #f5f5f5; }
  .collapse-item-logs .bottom-head {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    padding-left: 72px;
    font-size: 12px; }
    .collapse-item-logs .bottom-head :nth-child(1) {
      width: 30.7%;
      font-size: 12px; }
    .collapse-item-logs .bottom-head :nth-child(2) {
      width: 21.8%;
      padding-left: 2px;
      font-size: 12px; }
    .collapse-item-logs .bottom-head :nth-child(3) {
      width: 35%;
      padding-left: 5px;
      font-size: 12px; }
    .collapse-item-logs .bottom-head h5 {
      width: 100% !important;
      text-decoration: underline; }
  .collapse-item-logs .bottom-head2 {
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    padding-left: 72px;
    font-size: 12px; }
    .collapse-item-logs .bottom-head2 :nth-child(1) {
      width: 30.7%;
      font-size: 12px; }
    .collapse-item-logs .bottom-head2 :nth-child(2) {
      width: 21.8%;
      padding-left: 2px;
      font-size: 12px; }
    .collapse-item-logs .bottom-head2 :nth-child(3) {
      width: 35%;
      padding-left: 5px;
      font-size: 12px; }
    .collapse-item-logs .bottom-head2 .live-inv p {
      margin-left: 30px; }
    .collapse-item-logs .bottom-head2 .distribution p {
      margin-left: 85px; }
  .collapse-item-logs .product-link-name p a:hover {
    text-decoration: underline !important; }

.collapse-item.rto {
  width: 992px;
  margin: auto;
  border-radius: 0px !important;
  letter-spacing: 0.032em; }
  .collapse-item.rto .ant-collapse-item {
    border-radius: 0px !important; }
    .collapse-item.rto .ant-collapse-item .container {
      background: #ffffff !important;
      padding: 8px 24px 8px 24px !important;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .collapse-item.rto .ant-collapse-item .container .details {
        width: 240px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: auto; }
        .collapse-item.rto .ant-collapse-item .container .details .img {
          height: 40px;
          width: 40px;
          background: gray;
          border: 2px solid #f5f5f5;
          margin-right: 24px; }
          .collapse-item.rto .ant-collapse-item .container .details .img img {
            height: 100%;
            width: 100%;
            object-fit: cover; }
        .collapse-item.rto .ant-collapse-item .container .details p {
          font-size: 12px;
          margin: 0;
          padding: 0; }
      .collapse-item.rto .ant-collapse-item .container .InventoryE {
        width: 56px;
        margin-left: 331px;
        font-size: 12px;
        height: 40px;
        display: flex;
        align-items: center; }
      .collapse-item.rto .ant-collapse-item .container :nth-child(3) {
        width: 248px;
        padding-left: 30px;
        font-size: 12px;
        height: 40px;
        display: flex;
        align-items: center;
        color: #1370fc;
        cursor: pointer; }
  .collapse-item.rto .ant-collapse-header {
    border-radius: 0px !important;
    padding: 0 !important; }
  .collapse-item.rto .ant-collapse-content {
    background: #f5f5f5; }
  .collapse-item.rto .bottom-head-rto {
    padding-left: 24px;
    padding-right: 24px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px; }
    .collapse-item.rto .bottom-head-rto div {
      height: 32px;
      display: flex;
      align-items: center; }
      .collapse-item.rto .bottom-head-rto div p {
        text-align: center; }
      .collapse-item.rto .bottom-head-rto div h5 {
        font-size: 12px;
        width: 100%;
        padding: 0 !important;
        text-decoration: underline;
        text-align: center;
        margin-bottom: 0px; }
    .collapse-item.rto .bottom-head-rto div:nth-of-type(1) {
      width: 120px; }
      .collapse-item.rto .bottom-head-rto div:nth-of-type(1) p {
        text-align: start; }
      .collapse-item.rto .bottom-head-rto div:nth-of-type(1) h5 {
        font-size: 12px;
        width: 100%;
        padding: 0 !important;
        text-align: start; }
    .collapse-item.rto .bottom-head-rto div:nth-of-type(2) {
      width: 120px; }
      .collapse-item.rto .bottom-head-rto div:nth-of-type(2) h5 {
        text-align: left !important; }
    .collapse-item.rto .bottom-head-rto div:nth-of-type(3) {
      width: 40px; }
      .collapse-item.rto .bottom-head-rto div:nth-of-type(3) h5 {
        text-align: center !important; }
    .collapse-item.rto .bottom-head-rto div:nth-of-type(4) {
      width: 100px;
      display: flex;
      justify-content: end; }
  .collapse-item.rto .bottom-head-rto.data {
    height: 40px;
    border-top: 1px solid #fff; }
    .collapse-item.rto .bottom-head-rto.data div:nth-of-type(6) p {
      text-align: left; }
    .collapse-item.rto .bottom-head-rto.data div:nth-of-type(5) p {
      text-align: left; }
    .collapse-item.rto .bottom-head-rto.data div:nth-of-type(3) p {
      text-align: center; }
    .collapse-item.rto .bottom-head-rto.data div:nth-of-type(2) p {
      text-align: left; }
  .collapse-item.rto .bottom-head-rto.data:last-of-type {
    margin-bottom: 0px !important;
    border-bottom: 0px; }
  .collapse-item.rto .product-link-name p a:hover {
    text-decoration: underline !important; }
  .collapse-item.rto .view-in-vf-link:hover {
    text-decoration: underline !important; }
  .collapse-item.rto .bottom-head-rto.data.first {
    margin-top: 0px; }

.inventory-list .heading .content-page-title {
  margin-bottom: 0; }
  .inventory-list .heading .content-page-title span {
    float: right;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center; }

.inventory-list .heading p {
  font-size: 16px;
  color: black; }

.inventory-list .inventory-filters {
  display: flex;
  justify-content: space-between; }
  .inventory-list .inventory-filters .store-filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 4.5px 5px 4.5px 17px;
    width: 290px;
    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important;
    background-color: white !important; }
    .inventory-list .inventory-filters .store-filters p {
      margin-bottom: 0px;
      font-size: 12px;
      color: black;
      margin-right: 5px; }
    .inventory-list .inventory-filters .store-filters .filter_select .ant-select-selection__placeholder {
      display: block !important;
      background-color: white !important; }
    .inventory-list .inventory-filters .store-filters .filter_select .ant-select-selection__choice {
      display: none; }
    .inventory-list .inventory-filters .store-filters .filter.ant-select {
      width: auto;
      color: #004eff;
      font-size: 12px; }
      .inventory-list .inventory-filters .store-filters .filter.ant-select .ant-select-selection {
        background-color: transparent;
        border: none; }
        .inventory-list .inventory-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered {
          line-height: 31px; }
          .inventory-list .inventory-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
            color: #004eff; }
          .inventory-list .inventory-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered ul .ant-select-selection__choice {
            color: #004eff; }
            .inventory-list .inventory-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered ul .ant-select-selection__choice .ant-select-selection__choice__remove:hover,
            .inventory-list .inventory-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered ul .ant-select-selection__choice .ant-select-selection__choice__remove {
              color: #0267fb; }
      .inventory-list .inventory-filters .store-filters .filter.ant-select .ant-select-selection--single {
        height: 25px; }
      .inventory-list .inventory-filters .store-filters .filter.ant-select .ant-select-arrow {
        margin-top: -2px;
        color: #004eff; }
    .inventory-list .inventory-filters .store-filters .ant-select-focused .ant-select-selection,
    .inventory-list .inventory-filters .store-filters .ant-select-selection:focus,
    .inventory-list .inventory-filters .store-filters .ant-select-selection:active {
      border-color: none;
      border-right-width: 0px !important;
      outline: 0;
      box-shadow: none; }

.inventory-list .ant-select-selection-selected-value {
  width: 70px; }

.inventory-list .ant-select-arrow {
  margin-left: 15px; }

.inventory-list .ant-collapse {
  border: none;
  box-shadow: 0px 3px 10px #00000010; }

.inventory-list .ant-collapse:last-child {
  border: none;
  box-shadow: 0px 3px 10px #00000010;
  border-bottom-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important; }
  .inventory-list .ant-collapse:last-child .ant-collapse-item {
    border-bottom-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-bottom: none !important; }
    .inventory-list .ant-collapse:last-child .ant-collapse-item .ant-collapse-header {
      border-bottom-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important; }
      .inventory-list .ant-collapse:last-child .ant-collapse-item .ant-collapse-header .container {
        border-bottom-left-radius: 4px !important;
        border-bottom-left-radius: 4px !important;
        border-bottom: none !important; }

.inventory-list .ant-collapse-content-active {
  border: none; }

.inventory-list .head {
  box-shadow: 0px 3px 10px #00000010 !important; }

.new-inventory-card {
  letter-spacing: 0.032em; }
  .new-inventory-card .svg-wraper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 4px; }
    .new-inventory-card .svg-wraper svg {
      display: block;
      fill: #fff;
      margin-bottom: -4px; }
  .new-inventory-card .svg-wraper.desc svg:nth-of-type(2) {
    fill: #fbc52d; }
  .new-inventory-card .svg-wraper.asc svg:nth-of-type(1) {
    fill: #fbc52d; }
  .new-inventory-card .head {
    height: 40px;
    background: #16273b;
    margin: auto;
    margin-top: 24px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 12px;
    color: white; }
    .new-inventory-card .head .prod-details {
      flex-basis: 25%; }
    .new-inventory-card .head .live-inv {
      flex-basis: 12%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
    .new-inventory-card .head .pricing {
      flex-basis: 16%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
    .new-inventory-card .head .dist {
      flex-basis: 16%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
    .new-inventory-card .head .flexible {
      flex-basis: 10%;
      text-align: center; }
    .new-inventory-card .head .expand {
      flex-basis: 13%;
      text-align: right;
      opacity: 0; }
    .new-inventory-card .head .expand.custom {
      opacity: 1 !important;
      text-align: center; }

.new-inventory-card-logs {
  position: relative;
  letter-spacing: 0.032em; }
  .new-inventory-card-logs .head {
    width: 992px;
    height: 40px;
    background: #16273b;
    margin: auto;
    margin-top: 24px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 12px;
    color: white; }
    .new-inventory-card-logs .head :nth-child(1) {
      width: 40%; }
    .new-inventory-card-logs .head :nth-child(2) {
      width: 40%; }
    .new-inventory-card-logs .head :nth-child(3) {
      width: 20%; }

.new-inventory-rto-logs {
  position: relative;
  letter-spacing: 0.032em; }
  .new-inventory-rto-logs .head {
    width: 992px;
    height: 40px;
    background: #16273b;
    margin: auto;
    margin-top: 24px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 12px;
    color: white;
    justify-content: space-between; }
    .new-inventory-rto-logs .head :nth-child(1) {
      width: 376px; }
    .new-inventory-rto-logs .head :nth-child(2) {
      width: 120px;
      text-align: center; }
    .new-inventory-rto-logs .head :nth-child(3) {
      width: 56px; }

.logs-btn {
  display: block;
  height: 40px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  width: 120px;
  font-weight: 500 !important;
  align-items: center;
  justify-content: center; }

.logs-tab .ant-pagination {
  margin-top: 20px; }

.logs-tab.rto .ant-pagination {
  margin-top: 20px; }

.logs-tab.rto .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px; }

.new-inventory-rto-logs .head {
  width: auto !important; }

.scroller {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden; }

.scroller::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px; }

.scroller::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #898989;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }

.scroller::-webkit-scrollbar {
  width: 8px;
  border: 1px solid #898989; }

.bottom-fix-counter {
  height: 40px;
  background-color: #d8dade;
  margin: auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 12px;
  color: white; }
  .bottom-fix-counter div {
    color: #212121;
    font-weight: 600;
    font-size: 14px; }
  .bottom-fix-counter .prod-details {
    flex-basis: 24.7%; }
  .bottom-fix-counter .live-inv {
    flex-basis: 12%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  .bottom-fix-counter .pricing {
    flex-basis: 16%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  .bottom-fix-counter .dist {
    flex-basis: 16%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  .bottom-fix-counter .flexible {
    flex-basis: 10%;
    text-align: center; }
  .bottom-fix-counter .expand {
    flex-basis: 12%;
    text-align: right; }

.bottom-fix-counter.bad .prod-details {
  flex-basis: 25%; }

.bottom-fix-counter.bad .live-inv {
  flex-basis: 12%; }

.bottom-fix-counter.bad .pricing {
  flex-basis: 16%; }

.bottom-fix-counter.bad .dist {
  flex-basis: 16%; }

.bottom-fix-counter.bad .flexible {
  display: none; }

.bottom-fix-counter.bad .expand {
  flex-basis: 12%; }

.inventory_order_data .inv-nav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .inventory_order_data .inv-nav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 32px; }
    .inventory_order_data .inv-nav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .inventory_order_data .inv-nav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .inventory_order_data .inv-nav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .inventory_order_data .inv-nav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .inventory_order_data .inv-nav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .inventory_order_data .inv-nav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .inventory_order_data .inv-nav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .inventory_order_data .inv-nav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .inventory_order_data .inv-nav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .inventory_order_data .inv-nav .secondwrap {
    display: flex;
    align-items: center;
    height: 42px;
    justify-content: space-between; }
    .inventory_order_data .inv-nav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .inventory_order_data .inv-nav .secondwrap .filtertab .tab {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .inventory_order_data .inv-nav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .inventory_order_data .inv-nav .secondwrap .filtertab .tab button {
          display: contents;
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .inventory_order_data .inv-nav .secondwrap .filtertab .circle {
        margin-left: 8px !important;
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .inventory_order_data .inv-nav .secondwrap .filtertab .backgroundcircle {
        margin-left: 8px !important;
        position: relative;
        background: #1161fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1161fb; }
      .inventory_order_data .inv-nav .secondwrap .filtertab .hover-inventory-underline-animation {
        position: relative; }
      .inventory_order_data .inv-nav .secondwrap .filtertab .hover-inventory-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 0px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .inventory_order_data .inv-nav .secondwrap .filtertab .hover-inventory-underline-animation:hover {
        color: #4398ed !important; }
        .inventory_order_data .inv-nav .secondwrap .filtertab .hover-inventory-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .inventory_order_data .inv-nav .secondwrap .filtertab .hover-inventory-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .inventory_order_data .inv-nav .secondwrap .filtertab .hover-inventory-underline-animation-click {
        position: relative; }
      .inventory_order_data .inv-nav .secondwrap .filtertab .hover-inventory-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 0px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .inventory_order_data .inv-nav .secondwrap .download_csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .inventory_order_data .inv-nav .inventory-datepicker {
    margin-right: 10px; }
    .inventory_order_data .inv-nav .inventory-datepicker .ant-calendar-picker-input {
      width: 88%; }
      .inventory_order_data .inv-nav .inventory-datepicker .ant-calendar-picker-input .anticon {
        right: 62px; }
  .inventory_order_data .inv-nav .inventory-datepicker:focus .ant-calendar-picker-input {
    box-shadow: none; }
  .inventory_order_data .inv-nav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .inventory_order_data .inv-nav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .inventory_order_data .inv-nav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .inventory_order_data .inv-nav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .inventory_order_data .inv-nav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .inventory_order_data .inv-nav .ant-calendar-picker-clear,
  .inventory_order_data .inv-nav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.inventory_order_data .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 90px; }
  .inventory_order_data .bottom_data .import-product-search {
    width: 360px !important; }
    .inventory_order_data .bottom_data .import-product-search .ant-input-wrapper {
      width: 360px !important; }

.inventory_order_data .ant-select-selection__placeholder {
  color: #1268fb; }

@media screen and (min-width: 1440px) {
  .inv-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 242px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .inv-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.movement-filter-wraper {
  margin-bottom: 48px;
  position: relative; }
  .movement-filter-wraper * {
    letter-spacing: 0.032em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer !important; }
  .movement-filter-wraper .ant-select-selection-item {
    display: none !important; }
  .movement-filter-wraper .custom-text {
    color: "#6E6E6E";
    font-size: 12px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    pointer-events: none; }
    .movement-filter-wraper .custom-text span {
      color: #1268fb;
      font-weight: 500;
      margin-left: 4px; }
  .movement-filter-wraper .custom-arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotateX(0);
    right: 12px;
    transition: all 0.3s ease 0s; }
  .movement-filter-wraper .custom-arrow-icon.open {
    transition: all 0.35s ease 0s;
    transform: translateY(-50%) rotate(180deg); }
    .movement-filter-wraper .custom-arrow-icon.open g {
      fill: #898989; }
  .movement-filter-wraper .ant-select.ant-select-enabled {
    height: 40px; }
  .movement-filter-wraper .ant-select-selection--multiple {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px; }
    .movement-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered {
      height: 40px;
      margin-bottom: 0px; }
      .movement-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice {
        max-width: 60px;
        position: relative;
        margin-top: 8px;
        color: #1268fb;
        font-weight: 500;
        font-size: 12px;
        background: none;
        border: 1px solid transparent;
        z-index: 10;
        margin-right: 2px;
        border: 1px solid #e8e8e8;
        padding: 0 2px !important; }
        .movement-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice span {
          display: none; }
      .movement-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-search__field {
        margin-top: 3.4px;
        font-size: 12px; }
  .movement-filter-wraper .ant-select-search.ant-select-search--inline {
    width: 50px;
    margin-top: 5px;
    color: #1268fb; }
  .movement-filter-wraper .ant-checkbox.ant-checkbox-checked {
    display: none; }

.movement-filter-overlay * {
  letter-spacing: 0.032em; }

.movement-filter-overlay .ant-select-dropdown-menu-item-disabled i {
  display: none; }

.movement-filter-overlay .ant-select-dropdown-menu-item {
  color: #898989;
  min-height: 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #cecece;
  padding-left: 40px; }
  .movement-filter-overlay .ant-select-dropdown-menu-item i {
    position: absolute;
    right: 260px !important;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #e3e3e3;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .movement-filter-overlay .ant-select-dropdown-menu-item i svg {
      font-size: 12px; }

.movement-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  color: #212121;
  font-weight: 500; }
  .movement-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i {
    background-color: #2bc822;
    border-color: #2bc822 !important;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .movement-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i svg {
      color: #fff;
      stroke: 2px; }

.movement-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled i {
  display: none; }

.movement-filter-overlay .ant-select-dropdown-menu-item:hover {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe; }
  .movement-filter-overlay .ant-select-dropdown-menu-item:hover svg {
    color: #898989; }

.type-filter-wraper {
  position: relative;
  margin-bottom: 48px; }
  .type-filter-wraper * {
    letter-spacing: 0.032em; }
  .type-filter-wraper .custom-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    left: 16px;
    z-index: 10; }
  .type-filter-wraper .ant-select-open svg g {
    fill: #898989; }
  .type-filter-wraper .ant-select-selection.ant-select-selection--single {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px; }
    .type-filter-wraper .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
      height: 40px; }
      .type-filter-wraper .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
        height: 40px;
        display: flex !important;
        align-items: center;
        font-size: 12px;
        color: #1268fb;
        font-weight: 500;
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        padding-left: 44px; }
        .type-filter-wraper .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value .ant-select-selection-selected-value {
          margin-left: 3px; }
  .type-filter-wraper .ant-select-arrow {
    margin-top: -7px; }

.type-filter-overlay * {
  letter-spacing: 0.032em; }

.type-filter-overlay .ant-select-dropdown-menu-item-disabled i {
  display: none; }

.type-filter-overlay .ant-select-dropdown-menu-item {
  color: #898989;
  min-height: 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #cecece; }
  .type-filter-overlay .ant-select-dropdown-menu-item i {
    position: absolute;
    right: 280px !important;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #e3e3e3;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .type-filter-overlay .ant-select-dropdown-menu-item i svg {
      font-size: 12px; }

.type-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  color: #212121;
  font-weight: 500; }
  .type-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i {
    background-color: #2bc822;
    border-color: #2bc822 !important;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .type-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i svg {
      color: #fff;
      stroke: 2px; }

.type-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled i {
  display: none; }

.type-filter-overlay .ant-select-dropdown-menu-item:hover {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe; }
  .type-filter-overlay .ant-select-dropdown-menu-item:hover svg {
    color: #898989; }

.sourcing-card {
  background: white;
  border-radius: 4px;
  margin-bottom: 24px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05); }
  .sourcing-card .media-attachments {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px; }
    .sourcing-card .media-attachments .item-container {
      position: relative; }
    .sourcing-card .media-attachments .image-div {
      margin-top: 10px;
      margin-right: 10px;
      border: 1px solid #898989; }
      .sourcing-card .media-attachments .image-div img {
        width: 100px;
        height: 100px;
        cursor: pointer;
        padding: 3px; }
    .sourcing-card .media-attachments .video-data {
      width: 100px;
      height: 100px;
      cursor: pointer; }
    .sourcing-card .media-attachments .download-image-data {
      position: absolute;
      bottom: 3px;
      padding: 6px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 69px; }
  .sourcing-card .edd-date {
    display: flex; }
    .sourcing-card .edd-date .heading {
      font-size: 12px;
      line-height: 16px;
      color: #898989;
      margin-top: 6px;
      font-weight: 400;
      margin-right: 8px; }
    .sourcing-card .edd-date .calender-container {
      position: relative;
      top: 3px; }
      .sourcing-card .edd-date .calender-container svg {
        fill: #1268fb; }
    .sourcing-card .edd-date .field-value {
      font-size: 12px;
      position: relative;
      top: 9px;
      font-weight: 600; }
      .sourcing-card .edd-date .field-value .iconCantainer {
        margin-left: 2px;
        position: relative; }
    .sourcing-card .edd-date .field-value:hover svg #Ellipse_44 {
      stroke: #1268fb;
      fill: #1268fb; }
    .sourcing-card .edd-date .field-value:hover svg #Ellipse_47 {
      stroke: #fff;
      fill: #ffff; }
    .sourcing-card .edd-date .field-value:hover svg #Rectangle_2462 {
      stroke: #fff;
      fill: #ffff; }
    .sourcing-card .edd-date .field-value1 {
      font-size: 12px;
      position: relative;
      top: 9px;
      font-weight: 600; }
  .sourcing-card .gst-field {
    font-size: 9px;
    color: #898989;
    line-height: 12px;
    font-weight: 400 !important;
    display: block; }
  .sourcing-card .top-section {
    display: flex;
    position: relative;
    padding: 32px;
    padding-bottom: 0px; }
    .sourcing-card .top-section .vforderid_tag {
      position: absolute;
      left: 0;
      z-index: 9;
      top: 23px; }
    .sourcing-card .top-section .status-label {
      position: absolute;
      top: 24px;
      right: 32px;
      font-weight: 500;
      padding: 4px 0;
      text-align: center;
      font-size: 12px;
      color: white;
      border-radius: 2px;
      z-index: 9;
      height: 24px;
      letter-spacing: 0.032em;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px; }
      .sourcing-card .top-section .status-label .circle {
        height: 10px;
        width: 10px;
        margin: 0px 10px;
        background-color: #fff;
        border-radius: 50%; }
    .sourcing-card .top-section .img-container {
      width: 160px;
      margin-right: 24px;
      height: 160px; }
      .sourcing-card .top-section .img-container img {
        width: 160px;
        height: 160px;
        border: 2px solid #f5f5f5; }
      .sourcing-card .top-section .img-container button {
        position: absolute;
        top: 0;
        width: 32px;
        height: 100%;
        background: rgba(33, 33, 33, 0.4);
        border: none;
        cursor: pointer;
        display: none; }
      .sourcing-card .top-section .img-container .next-img {
        right: 0; }
      .sourcing-card .top-section .img-container .prev-img {
        left: 0; }
    .sourcing-card .top-section .img-container:hover button {
      display: block; }
    .sourcing-card .top-section .basic-details {
      flex: 1 1;
      min-width: 0; }
      .sourcing-card .top-section .basic-details .product-description {
        font-size: 16px;
        color: #212121;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .sourcing-card .top-section .basic-details p {
        font-size: 16px;
        letter-spacing: 0.032em;
        line-height: 22px;
        font-weight: 500;
        color: #212121;
        margin-top: 3px;
        text-transform: capitalize; }
      .sourcing-card .top-section .basic-details .product-title-link a:hover {
        text-decoration: underline !important; }
    .sourcing-card .top-section .fields {
      display: flex;
      border-top: 1px solid #cecece;
      padding-top: 16px;
      margin-top: 8px;
      width: 100%; }
      .sourcing-card .top-section .fields .field {
        flex: 1 1;
        margin-right: 20px; }
        .sourcing-card .top-section .fields .field .field-label {
          font-size: 12px;
          line-height: 16px;
          color: #898989;
          margin-bottom: 4px;
          font-weight: 400; }
        .sourcing-card .top-section .fields .field .field-value {
          font-size: 12px;
          padding: 8px 16px;
          background: #f5f5f5; }
      .sourcing-card .top-section .fields .field:last-of-type {
        margin-right: 0; }
    .sourcing-card .top-section .bottom-fields {
      display: flex;
      padding-top: 16px;
      width: 100%; }
      .sourcing-card .top-section .bottom-fields .field {
        width: 168px;
        margin-right: 24px; }
        .sourcing-card .top-section .bottom-fields .field .field-label {
          font-size: 12px;
          line-height: 16px;
          color: #898989;
          margin-bottom: 4px;
          font-weight: 400; }
        .sourcing-card .top-section .bottom-fields .field .field-value {
          font-size: 13px;
          padding: 8px 16px;
          font-weight: 600;
          background: #f5f5f5; }
      .sourcing-card .top-section .bottom-fields .field-value.black {
        color: #212121 !important;
        text-align: center; }
      .sourcing-card .top-section .bottom-fields .field:last-of-type {
        margin-right: 0; }
    .sourcing-card .top-section .fields2 {
      display: flex;
      padding-top: 10px; }
      .sourcing-card .top-section .fields2 .field {
        flex: 1 1;
        margin-right: 24px; }
        .sourcing-card .top-section .fields2 .field .field-label {
          font-size: 12px;
          line-height: 16px;
          color: #898989;
          margin-bottom: 4px;
          font-weight: 400; }
        .sourcing-card .top-section .fields2 .field .field-value {
          font-size: 12px;
          padding: 8px 16px;
          background: #f5f5f5; }
      .sourcing-card .top-section .fields2 .field:last-of-type {
        margin-right: 0; }
  .sourcing-card .tabs-header {
    background: #16273b;
    height: 40px; }
    .sourcing-card .tabs-header ul {
      list-style: none;
      padding: 0;
      margin: 0 32px; }
      .sourcing-card .tabs-header ul li {
        display: inline-block; }
        .sourcing-card .tabs-header ul li button {
          border: none;
          background: transparent;
          margin-right: 24px;
          font-size: 12px;
          padding: 10px 15px;
          color: #fff;
          cursor: pointer;
          line-height: 20px;
          letter-spacing: 0.032em; }
          .sourcing-card .tabs-header ul li button .tab-count {
            display: inline-block;
            background: #ff9400;
            width: 20px;
            height: 20px;
            border-radius: 16px;
            margin-left: 6px;
            text-align: center;
            font-size: 12px;
            line-height: 20px; }
      .sourcing-card .tabs-header ul .add-notes-btn button {
        background: transparent;
        font-size: 12px;
        padding: 10px 15px;
        color: #fff;
        cursor: pointer;
        line-height: 20px;
        letter-spacing: 0.032em;
        border: 1px solid #fff;
        display: flex;
        height: 32px;
        margin-top: 4px;
        align-items: center; }
  .sourcing-card .tabs-content {
    background: #f5f5f5;
    padding: 0 32px 32px 32px;
    letter-spacing: 0.032em;
    border-radius: 4px; }
  .sourcing-card .flex {
    display: flex;
    align-items: center; }
  .sourcing-card .flex-notes-logs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989;
    margin-top: 8px;
    font-size: 12px; }
    .sourcing-card .flex-notes-logs .flex-note-logs {
      width: 45%; }
    .sourcing-card .flex-notes-logs .flex-date {
      width: 27%; }
    .sourcing-card .flex-notes-logs .flex-addedby {
      width: 25%; }
  .sourcing-card .notes-detail {
    padding: 8px 16px;
    background: #f5f5f5;
    margin-bottom: 8px; }
  .sourcing-card .flex-3 {
    flex: 2 1; }
  .sourcing-card .flex-2 {
    flex: 3 1; }
  .sourcing-card .flex-1 {
    flex: 1 1; }
  .sourcing-card .anticon {
    margin-left: 4px; }
  .sourcing-card .amount-tooltip-ican .anticon:hover svg #Ellipse_44 {
    stroke: #1268fb;
    fill: #1268fb; }
  .sourcing-card .amount-tooltip-ican .anticon:hover svg #Ellipse_47 {
    stroke: #fff;
    fill: #ffff; }
  .sourcing-card .amount-tooltip-ican .anticon:hover svg #Rectangle_2462 {
    stroke: #fff;
    fill: #ffff; }
  .sourcing-card .tab-container {
    background: #f5f5f5;
    padding: 0px 0px 0px 0px;
    margin-top: 16px;
    border-radius: 4px; }
  .sourcing-card .no-data {
    text-align: center;
    font-size: 12px;
    background: #fff;
    padding: 16px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .sourcing-card .booking-request-date .booking-request-created-date {
    font-size: 12px;
    line-height: 16px;
    color: #898989;
    margin-bottom: 4px;
    font-weight: 400;
    margin-top: 8px; }
  .sourcing-card .booking-request-date .booking-request-field-value {
    font-size: 12px;
    padding: 8px 16px;
    background: #f5f5f5;
    width: 23%; }
  .sourcing-card .add-notes-btn {
    float: right; }
  .sourcing-card .display-block-notes {
    background: #fff;
    padding: 5px 16px;
    font-size: 12;
    display: block; }
  .sourcing-card .display-none-notes {
    background: #fff;
    padding: 5px 16px;
    font-size: 12;
    display: none; }
  .sourcing-card .display-block-logs {
    background: #fff;
    padding: 5px 16px;
    font-size: 12;
    display: block; }
  .sourcing-card .display-none-logs {
    background: #fff;
    padding: 5px 16px;
    font-size: 12;
    display: none; }
  .sourcing-card .no-found {
    padding: 16px;
    text-align: center;
    background: white;
    color: #000;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 20px;
    font-size: 16px; }
  .sourcing-card .right-cancel-btn {
    width: 102px;
    position: absolute;
    top: 57px;
    right: 22px;
    font-weight: 500;
    padding: 4px 0;
    text-align: center;
    font-size: 12px;
    border-radius: 2px;
    z-index: 9;
    height: 24px;
    letter-spacing: 0.032em;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px; }
    .sourcing-card .right-cancel-btn .cancel-btn {
      width: 120px;
      height: 32px; }
  .sourcing-card .green-color {
    color: #1c6513 !important;
    text-align: center; }
  .sourcing-card .quantity-txt {
    color: #212121 !important;
    text-align: center; }
  .sourcing-card .invtype {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start; }
    .sourcing-card .invtype .vr {
      width: 1px;
      background-color: #898989;
      height: 8px;
      margin: 0 8px; }
    .sourcing-card .invtype p {
      font-size: 12px !important;
      margin: 0 !important;
      color: #898989 !important;
      margin-right: 4px !important; }
    .sourcing-card .invtype span {
      font-size: 12px !important;
      font-weight: 500;
      color: #212121; }
  .sourcing-card .image-row {
    display: flex; }
    .sourcing-card .image-row .img-cointainer {
      height: 110px;
      width: 110px;
      position: relative;
      margin-right: 10px; }
      .sourcing-card .image-row .img-cointainer img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
      .sourcing-card .image-row .img-cointainer .io-container {
        width: 110px;
        margin-right: 10px;
        height: 110px;
        margin-bottom: 12px;
        display: inline-block;
        background-color: lavender; }
        .sourcing-card .image-row .img-cointainer .io-container svg {
          display: flex;
          margin: auto;
          height: 110px; }
      .sourcing-card .image-row .img-cointainer .download-icon {
        position: absolute;
        top: 80px;
        right: 13px;
        transform: scale(2); }
  .sourcing-card .heading-custom-image {
    margin-bottom: 12px;
    font-size: 12px; }
  .sourcing-card .remarks-row {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 16px;
    font-size: 12px;
    flex-wrap: wrap; }
    .sourcing-card .remarks-row .remarks {
      margin-top: 12px;
      width: 90%; }
      .sourcing-card .remarks-row .remarks .remarks-heading {
        font-weight: 600;
        margin-right: 0px; }
    .sourcing-card .remarks-row button {
      align-self: center; }

.edd-test {
  font-size: 12px;
  max-width: 350px;
  border-bottom: 1px solid gray;
  margin-bottom: 5px; }
  .edd-test .remark {
    margin-bottom: 10px; }
    .edd-test .remark .heading {
      margin-right: 6px;
      font-weight: 600; }

.edd-test:last-of-type {
  border-bottom: 0px solid gray; }

.edd-log-text {
  font-size: 10px;
  margin-bottom: 3px;
  display: flex; }
  .edd-log-text .item {
    margin-right: 12px; }
    .edd-log-text .item .heading {
      margin-right: 6px;
      font-weight: 600; }

.heading-eddlogs {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400; }

.view-in-store {
  display: flex;
  align-items: center;
  justify-self: center;
  margin-bottom: 2px;
  margin-top: 4px; }
  .view-in-store p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100%;
    cursor: pointer;
    width: auto !important; }
  .view-in-store .view-in-store-hover-underline-animation {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    color: #fd104d; }
  .view-in-store .view-in-store-view-in-store:hover a span {
    color: #e61047; }
  .view-in-store .view-in-store-hover-underline-animation:after {
    content: "";
    position: absolute;
    width: 85%;
    transform: scaleX(0);
    height: 1px;
    bottom: 3px;
    left: 2px;
    background-color: #e61047;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out; }
  .view-in-store .view-in-store-hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left; }
  .view-in-store a span {
    color: #ce1543; }
  .view-in-store a svg g path {
    stroke: #ce1543; }

.orders-add-note-modal .ant-modal-content {
  border-radius: 2px !important; }

.orders-add-note-modal .input-label {
  font-weight: 700;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  color: #000; }

.orders-add-note-modal .no-top-margin {
  margin-top: 0rem; }

.orders-add-note-modal input,
.orders-add-note-modal textarea {
  border: 1.5px solid #979797;
  border-radius: 4px;
  padding-top: 8px !important; }

.orders-add-note-modal .ant-modal-close {
  display: none; }

.orders-add-note-modal .ant-input {
  background-image: none !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: #f5f5f5;
  color: #212121; }

.orders-add-note-modal .ant-input::placeholder {
  font-size: 12px;
  font-weight: 400px;
  letter-spacing: 0.032em;
  line-height: 16px;
  color: #898989; }

.orders-add-note-modal .ant-modal-body {
  padding: 16px 24px;
  font-size: 12px;
  line-height: 1.5;
  word-wrap: break-word; }

.orders-add-note-modal .ant-modal-header {
  padding: 16px 0px;
  border-bottom: 1px solid #cecece;
  display: flex;
  margin: 0 auto;
  width: 512px; }

.orders-add-note-modal .ant-modal-footer {
  padding: 16px 0px;
  text-align: right;
  border-top: 1px solid #cecece;
  width: 512px;
  margin: 0 auto; }

.orders-add-note-modal .ant-modal-content {
  border-radius: 0px; }

.orders-add-note-modal .add-note-btn {
  background: #2bc822;
  border: none;
  width: 144px;
  height: 40px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px; }

.orders-add-note-modal .add-note-cancel {
  border: 1px solid #898989;
  width: 88px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 16px;
  border-radius: 2px;
  color: #898989; }

.orders-add-note-modal .ant-modal-title {
  color: #212121;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 16px; }

.opops .dragger-packing {
  border-radius: 0px; }

.opops .io-container {
  width: 110px;
  margin-right: 10px;
  height: 110px;
  margin-bottom: 12px;
  display: inline-block;
  background-color: lavender; }
  .opops .io-container svg {
    display: flex;
    margin: auto;
    height: 110px; }

.opops .cointainer {
  display: flex;
  margin-top: 12px; }

.opops .heading {
  font-size: 12px;
  margin-bottom: 6px; }

.opops .drage-image {
  padding: 24px 16px;
  background-color: #f5f5f5;
  margin-right: 8px;
  width: 110px; }

.opops .drage-image:hover {
  background-color: #1370fc;
  color: #fff; }
  .opops .drage-image:hover p {
    color: #fff; }

.opops .drage-image:hover svg g path {
  fill: #fff; }

.opops .drage-image svg {
  position: relative;
  left: 33%; }

.opops .image-cointainer {
  position: relative; }
  .opops .image-cointainer .icon-cointainer-pdf {
    position: absolute;
    height: 16px;
    width: 16px;
    display: inline-block;
    top: 2px;
    right: 12px;
    display: none; }
    .opops .image-cointainer .icon-cointainer-pdf svg {
      height: 100%;
      width: 100%; }
  .opops .image-cointainer .icon-cointainer {
    position: absolute;
    height: 16px;
    width: 16px;
    display: inline-block;
    top: -1px;
    right: 10px;
    display: none; }
    .opops .image-cointainer .icon-cointainer svg {
      height: 100%;
      width: 100%; }

.opops .image-cointainer:hover .icon-cointainer {
  display: block; }

.opops .image-cointainer:hover .icon-cointainer-pdf {
  display: block; }

.image-modal .ant-modal-body {
  min-height: 400px; }
  .image-modal .ant-modal-body .img-wraper {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 400px; }
    .image-modal .ant-modal-body .img-wraper .image-size {
      width: 100%;
      height: 100%;
      position: relative; }
    .image-modal .ant-modal-body .img-wraper .video-size {
      width: 100%;
      height: 100%;
      position: relative; }

.image-modal .ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 29px; }

.image-modal .image-arrow {
  position: absolute;
  top: 50%;
  z-index: 99;
  background: transparent;
  padding: 0px;
  height: 40px;
  width: 40px;
  border: none;
  box-shadow: none; }
  .image-modal .image-arrow .anticon {
    font-size: 40px; }

.image-row {
  display: flex; }
  .image-row .img-cointainer {
    height: 110px;
    width: 110px;
    position: relative;
    margin-right: 10px; }
    .image-row .img-cointainer img {
      height: 100%;
      width: 100%;
      object-fit: scale-down; }
    .image-row .img-cointainer .io-container {
      width: 110px;
      margin-right: 10px;
      height: 110px;
      margin-bottom: 12px;
      display: inline-block;
      background-color: lavender; }
      .image-row .img-cointainer .io-container svg {
        display: flex;
        margin: auto;
        height: 110px; }
    .image-row .img-cointainer .download-icon {
      position: absolute;
      top: 80px;
      right: 10px;
      transform: scale(2); }

.sourcing-requests-list .ant-select-arrow {
  margin: 0; }

.sourcing-requests-list .ant-input-search-button {
  background: #fd104d;
  border-color: #fd104d;
  height: 40px;
  font-size: 12px;
  line-height: 0;
  border-radius: 0 2px 2px 0;
  text-shadow: none;
  box-shadow: none; }

.sourcing-requests-list .requests-filters .ant-select .ant-select-selection__rendered {
  margin-left: 0;
  width: 71px; }

.sourcing-requests-list .requests-filters .ant-select .ant-select-selection-selected-value {
  margin-right: 8px; }

.sourcing-requests-list .ant-input-search input {
  background-color: white !important;
  border: none;
  border-radius: 2px 0 0 2px !important;
  height: 40px;
  letter-spacing: 0.032em;
  font-size: 12px; }

.sourcing-requests-list .heading .content-page-title {
  margin-bottom: 0; }
  .sourcing-requests-list .heading .content-page-title span {
    float: right;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center; }

.sourcing-requests-list .heading p {
  font-size: 16px;
  color: black; }

.sourcing-requests-list .requests-filters {
  display: flex;
  justify-content: space-between; }
  .sourcing-requests-list .requests-filters .ant-input {
    background-color: "white" !important; }
  .sourcing-requests-list .requests-filters .store-filters {
    background-color: white !important;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
    .sourcing-requests-list .requests-filters .store-filters p {
      margin-bottom: 0px;
      font-size: 12px;
      color: black;
      margin-right: 5px; }
    .sourcing-requests-list .requests-filters .store-filters .filter_select .ant-select-selection__placeholder {
      display: block !important; }
    .sourcing-requests-list .requests-filters .store-filters .filter_select .ant-select-selection__choice {
      display: none; }
    .sourcing-requests-list .requests-filters .store-filters .filter.ant-select {
      width: auto;
      color: #004eff;
      font-size: 14px;
      font-weight: 600; }
      .sourcing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection {
        background-color: transparent;
        border: none; }
        .sourcing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered {
          line-height: 31px; }
          .sourcing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
            color: #004eff; }
          .sourcing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered ul .ant-select-selection__choice {
            color: #004eff; }
            .sourcing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered ul .ant-select-selection__choice .ant-select-selection__choice__remove:hover,
            .sourcing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered ul .ant-select-selection__choice .ant-select-selection__choice__remove {
              color: #0267fb; }
      .sourcing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection--single {
        height: 25px; }
      .sourcing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-arrow {
        margin-top: -2px;
        color: #004eff; }
    .sourcing-requests-list .requests-filters .store-filters .ant-select-focused .ant-select-selection,
    .sourcing-requests-list .requests-filters .store-filters .ant-select-selection:focus,
    .sourcing-requests-list .requests-filters .store-filters .ant-select-selection:active {
      border-color: none;
      border-right-width: 0px !important;
      outline: 0;
      box-shadow: none; }

.sourcing-requests-list .store-filters:hover {
  background-color: #fff !important; }

.request-not-found {
  padding: 16px;
  text-align: center;
  background: #fff;
  color: #000;
  font-weight: 500; }

.ant-pagination-options-size-changer .ant-select-arrow {
  margin-top: -4px !important; }

.Sourcing_nav_data .Sourcing_nav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .Sourcing_nav_data .Sourcing_nav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .Sourcing_nav_data .Sourcing_nav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .Sourcing_nav_data .Sourcing_nav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .Sourcing_nav_data .Sourcing_nav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 100px; }
      .Sourcing_nav_data .Sourcing_nav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .Sourcing_nav_data .Sourcing_nav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .Sourcing_nav_data .Sourcing_nav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px;
        top: 39%;
        left: 145% !important; }
      .Sourcing_nav_data .Sourcing_nav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .Sourcing_nav_data .Sourcing_nav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .Sourcing_nav_data .Sourcing_nav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .Sourcing_nav_data .Sourcing_nav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .Sourcing_nav_data .Sourcing_nav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .Sourcing_nav_data .Sourcing_nav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .Sourcing_nav_data .Sourcing_nav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .Sourcing_nav_data .Sourcing_nav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .Sourcing_nav_data .Sourcing_nav .ant-calendar-picker-clear,
  .Sourcing_nav_data .Sourcing_nav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.Sourcing_nav_data .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 40px;
  margin-bottom: 32px; }

.Sourcing_nav_data .fullorder_data .ant-select-selection__placeholder {
  color: #898989; }

.Sourcing_nav_data .ant-select-dropdown {
  font-size: 12px !important;
  border-radius: 0px !important;
  background: #f8f8f8;
  margin-top: 19px !important; }

.Sourcing_nav_data .ant-select-selection {
  width: 200px !important;
  border: none !important;
  background: #f8f8f8 !important; }

.Sourcing_nav_data .ant-select-dropdown-menu-item:last-child {
  border-bottom: none !important; }

.Sourcing_nav_data .commondropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 32px; }
  .Sourcing_nav_data .commondropdown .anticon svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    padding-top: 2px; }

.Sourcing_nav_data .commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 1px solid #b5dafe !important;
  color: #212121;
  font-weight: 500; }
  .Sourcing_nav_data .commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) .cancel-status:hover {
    display: none; }

.Sourcing_nav_data .filter .ant-select-arrow {
  top: 39%;
  left: 130% !important; }

@media screen and (min-width: 1440px) {
  .Sourcing_nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; }
    .Sourcing_nav .ant-select-selection {
      width: 200px !important;
      border: none !important;
      background: #f8f8f8 !important; }
  .filter .ant-select-arrow {
    top: 39%;
    left: 130% !important; }
  .commondropdown {
    border-bottom: 1px solid #cecece !important;
    padding: 8px 16px !important;
    background: #f8f8f8 !important;
    color: #898989;
    display: flex;
    align-items: center;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 32px; }
  .fullorder_data .ant-select-selection__placeholder {
    color: #898989; }
  .ant-select-dropdown {
    font-size: 12px !important;
    border-radius: 0px !important;
    background: #f8f8f8;
    margin-top: 19px !important; }
  .commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f4ff !important;
    border-bottom: 1px solid #b5dafe !important;
    color: #212121;
    font-weight: 500; }
  .sourcing-request-status-cancel {
    border-bottom: 1px solid #cecece !important;
    padding: 8px 16px !important;
    background: #f8f8f8 !important;
    color: #898989;
    display: flex;
    align-items: center;
    font-weight: 400;
    letter-spacing: 0.032em;
    line-height: 32px; }
  .sourcing-request-status-cancel:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #e6f4ff !important;
    color: #212121;
    font-weight: 500; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .Sourcing_nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.fullorder_data .drop {
  background-color: #f8f8f8 !important;
  box-shadow: 0px 2px 8px #00000014;
  letter-spacing: 0 !important; }
  .fullorder_data .drop .ant-select-selection__rendered {
    margin-left: 16px;
    background-color: #f8f8f8 !important;
    margin-right: 2px;
    height: 100% !important; }
    .fullorder_data .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .fullorder_data .drop .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .fullorder_data .drop p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #898989 !important; }
  .fullorder_data .drop .ant-select-selection-selected-value {
    height: 100% !important;
    line-height: 32px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: 13px;
    color: #1268fb; }
    .fullorder_data .drop .ant-select-selection-selected-value .anticon svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px; }
  .fullorder_data .drop .ant-select-selection {
    width: 159px;
    border: none !important;
    background: #f8f8f8 !important; }
    .fullorder_data .drop .ant-select-selection .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .fullorder_data .drop .ant-select-selection .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }

.sourcing-request-status-cancel {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 32px; }

.sourcing-request-status-cancel:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  color: #212121;
  font-weight: 500; }

.pricing-card {
  background: white;
  border-radius: 4px;
  margin-bottom: 24px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  letter-spacing: 0.032em; }
  .pricing-card .top-section {
    display: flex;
    padding: 32px 32px 16px 32px; }
    .pricing-card .top-section .img-container {
      width: 160px;
      margin-right: 24px; }
      .pricing-card .top-section .img-container img {
        width: 160px;
        height: 160px;
        border: 2px solid #f5f5f5; }
    .pricing-card .top-section .basic-details {
      flex: 1 1;
      min-width: 0; }
      .pricing-card .top-section .basic-details .product-description {
        font-size: 16px;
        color: #212121;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .pricing-card .top-section .basic-details .product-name-detail {
        font-size: 16px;
        margin-top: 3px; }
        .pricing-card .top-section .basic-details .product-name-detail .product-name-txt {
          font-weight: 500;
          color: #212121;
          line-height: "16px";
          letter-spacing: "0.032em"; }
          .pricing-card .top-section .basic-details .product-name-detail .product-name-txt span a:hover {
            text-decoration: underline !important; }
      .pricing-card .top-section .basic-details .product-id-copie-data .product-Id {
        font-size: 12px;
        letter-spacing: 0.032em;
        font-weight: 500;
        color: "#898989"; }
      .pricing-card .top-section .basic-details .product-id-copie-data .copied-Id {
        margin-left: 6px;
        color: #898989;
        font-weight: 500;
        font-size: 12px; }
    .pricing-card .top-section .fields {
      display: flex;
      border-top: 1px solid #cecece;
      padding-top: 16px;
      margin-top: 16px;
      width: 100%; }
      .pricing-card .top-section .fields .field {
        flex: 1 1;
        margin-right: 16px; }
        .pricing-card .top-section .fields .field .field-label {
          font-size: 12px;
          line-height: 16px;
          color: #898989;
          margin-bottom: 4px; }
        .pricing-card .top-section .fields .field .field-value {
          font-size: 12px;
          padding: 8px 16px;
          background: #f5f5f5;
          color: #212121; }
      .pricing-card .top-section .fields .field:last-of-type {
        margin-right: 0; }
  .pricing-card .tabs-header {
    background: #16273b;
    height: 40px; }
    .pricing-card .tabs-header ul {
      list-style: none;
      padding: 0;
      margin: 0 32px; }
      .pricing-card .tabs-header ul li {
        display: inline-block; }
        .pricing-card .tabs-header ul li button {
          border: none;
          background: transparent;
          margin-right: 32px;
          color: white;
          font-size: 12px;
          line-height: 24px;
          padding: 8px 16px;
          cursor: pointer; }
          .pricing-card .tabs-header ul li button .tab-count {
            display: inline-block;
            background: #ff9400;
            width: 24px;
            height: 24px;
            border-radius: 24px;
            margin-left: 12px; }
  .pricing-card .tabs-content {
    background: #f5f5f5;
    padding: 0 32px 32px 32px; }
    .pricing-card .tabs-content .tab-content-all-data {
      padding: 16px;
      background: white;
      font-size: 12px;
      border-radius: 0px 0px 4px 4px;
      display: none; }
    .pricing-card .tabs-content .block {
      display: block; }
  .pricing-card .flex {
    display: flex;
    align-items: center; }
    .pricing-card .flex .flex-4 {
      padding: 3px 13px 3px;
      background-color: #0068ff;
      color: #fff;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px; }
  .pricing-card .retail-price-data {
    padding: 16px 24px;
    letter-spacing: 0.032em;
    border-radius: 0px 2px 2px 2px;
    background-color: #f5faff;
    border: 2px solid #1370fc; }
  .pricing-card .flex-1 {
    flex: 1 1; }
    .pricing-card .flex-1 .retail-piece {
      font-size: 20px;
      color: #565759;
      font-weight: 600;
      letter-spacing: 0.032em;
      line-height: 20px; }
      .pricing-card .flex-1 .retail-piece span {
        font-size: 12px; }
    .pricing-card .flex-1 .pices-color {
      color: #f66161; }
  .pricing-card .flex-2 {
    flex: 2 1; }
  .pricing-card .flex-3 .right-text {
    font-size: 10px;
    font-style: italic;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #000; }
  .pricing-card .anticon svg {
    margin-bottom: 1px; }
  .pricing-card .tab-container {
    background: #f5f5f5;
    padding: 0px !important;
    border-radius: 4px !important; }
  .pricing-card .view-in-store {
    margin-top: 0px !important; }
  .pricing-card .bulk-pricing-table {
    margin-top: 16px; }
    .pricing-card .bulk-pricing-table .bulk-pricing-table-txt {
      font-size: 12px;
      padding: 4px 12px;
      color: white;
      background: #1370fc;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      display: "inline-block"; }
    .pricing-card .bulk-pricing-table .pricingtabledata {
      background: #f5faff;
      overflow: auto;
      border: 2px solid #1370fc;
      border-radius: 0px 2px 2px 2px; }
      .pricing-card .bulk-pricing-table .pricingtabledata .nowrap-Data {
        display: flex;
        flex-wrap: nowrap; }
        .pricing-card .bulk-pricing-table .pricingtabledata .nowrap-Data .quantity-data {
          flex: 1 1;
          min-width: 22%; }
          .pricing-card .bulk-pricing-table .pricingtabledata .nowrap-Data .quantity-data .quantity-txt {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            color: #898989;
            font-weight: 400;
            border-bottom: 1px solid #b5dafe;
            border-top-left-radius: 2px;
            letter-spacing: "0.032em"; }
          .pricing-card .bulk-pricing-table .pricingtabledata .nowrap-Data .quantity-data .quantity-price-txt {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 24px;
            color: #898989;
            font-weight: 400;
            border-top-left-radius: 2px;
            letter-spacing: "0.032em"; }
        .pricing-card .bulk-pricing-table .pricingtabledata .nowrap-Data .range-data {
          display: flex;
          flex-direction: column;
          flex: 1 1;
          min-width: 20%; }
          .pricing-card .bulk-pricing-table .pricingtabledata .nowrap-Data .range-data .range-split-data {
            text-align: center;
            padding-top: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid #b5dafe;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 0.032em;
            color: #565759; }
          .pricing-card .bulk-pricing-table .pricingtabledata .nowrap-Data .range-data .freight-ppq {
            text-align: center;
            padding-top: 12px;
            padding-bottom: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1 1;
            color: #565759;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: 0.032em; }
  .pricing-card .line-through {
    color: #565759;
    text-decoration: line-through;
    font-weight: 500; }

p,
h3,
h2 {
  margin: 0; }

.top-section {
  position: relative;
  overflow: hidden; }

.product-label {
  position: absolute;
  padding: 6px;
  background-color: #ff9400;
  width: 187px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  transform: rotate(-40deg);
  left: -36px;
  top: 33px; }

.pricing-requests-list {
  letter-spacing: 0.032em; }
  .pricing-requests-list .heading .content-page-title {
    margin-bottom: 0; }
    .pricing-requests-list .heading .content-page-title span {
      float: right;
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center; }
  .pricing-requests-list .heading p {
    font-size: 16px;
    color: black; }
  .pricing-requests-list .requests-filters {
    display: flex;
    justify-content: space-between;
    border-radius: 2px; }
    .pricing-requests-list .requests-filters .store-filters {
      background-color: #f8f8f8 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 2px 8px #00000014;
      letter-spacing: 0 !important; }
      .pricing-requests-list .requests-filters .store-filters p {
        margin-bottom: 0px;
        font-size: 14px;
        color: black;
        margin-right: 5px; }
      .pricing-requests-list .requests-filters .store-filters .filter_select .ant-select-selection__placeholder {
        display: block !important; }
      .pricing-requests-list .requests-filters .store-filters .filter_select .ant-select-selection__choice {
        display: none; }
      .pricing-requests-list .requests-filters .store-filters .filter.ant-select {
        width: auto;
        color: #004eff;
        font-size: 14px;
        font-weight: 600; }
        .pricing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection {
          background-color: transparent;
          border: none; }
          .pricing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered {
            line-height: 16px;
            margin-right: 5px;
            margin-left: 5px;
            line-height: 30px;
            margin-top: -6px;
            justify-content: center;
            display: flex;
            height: 40px;
            padding: 8px; }
            .pricing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
              color: #004eff; }
            .pricing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered ul .ant-select-selection__choice {
              color: #004eff; }
              .pricing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered ul .ant-select-selection__choice .ant-select-selection__choice__remove:hover,
              .pricing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection .ant-select-selection__rendered ul .ant-select-selection__choice .ant-select-selection__choice__remove {
                color: #0267fb; }
        .pricing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection--single {
          height: 16px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center; }
        .pricing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-arrow {
          color: #004eff;
          right: 0px;
          margin-right: 8px; }
        .pricing-requests-list .requests-filters .store-filters .filter.ant-select .ant-select-selection-selected-value {
          margin-right: 10px;
          width: 260px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center; }
      .pricing-requests-list .requests-filters .store-filters .ant-select-focused .ant-select-selection,
      .pricing-requests-list .requests-filters .store-filters .ant-select-selection:focus,
      .pricing-requests-list .requests-filters .store-filters .ant-select-selection:active {
        border-color: none;
        border-right-width: 0px !important;
        outline: 0;
        box-shadow: none; }
  .pricing-requests-list .store-filters:hover {
    background-color: #f8f8f8 !important; }
  .pricing-requests-list .card-data {
    margin-bottom: 30px;
    text-align: center; }
    .pricing-requests-list .card-data .icon-data {
      font-size: 60px; }

.PricingRequestNav .RequestNav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .PricingRequestNav .RequestNav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .PricingRequestNav .RequestNav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .PricingRequestNav .RequestNav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .PricingRequestNav .RequestNav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .PricingRequestNav .RequestNav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .PricingRequestNav .RequestNav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .PricingRequestNav .RequestNav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .PricingRequestNav .RequestNav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .PricingRequestNav .RequestNav .ordfillwrap .right .show_data_from {
        font-size: 12px; }
      .PricingRequestNav .RequestNav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
    .PricingRequestNav .RequestNav .ordfillwrap .leftdata {
      margin-left: 7px; }
      .PricingRequestNav .RequestNav .ordfillwrap .leftdata .requeststxt {
        font-size: 12px;
        margin-bottom: 12px;
        color: #000000; }
      .PricingRequestNav .RequestNav .ordfillwrap .leftdata .pricing-updates-underline {
        text-decoration: underline;
        color: #000000; }
  .PricingRequestNav .RequestNav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .PricingRequestNav .RequestNav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .PricingRequestNav .RequestNav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .PricingRequestNav .RequestNav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .PricingRequestNav .RequestNav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .PricingRequestNav .RequestNav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .PricingRequestNav .RequestNav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1268fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1268fb; }
      .PricingRequestNav .RequestNav .secondwrap .filtertab .hover-pricing-update-underline-animation {
        position: relative; }
      .PricingRequestNav .RequestNav .secondwrap .filtertab .hover-pricing-update-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .PricingRequestNav .RequestNav .secondwrap .filtertab .hover-pricing-update-underline-animation:hover {
        color: #4398ed !important; }
        .PricingRequestNav .RequestNav .secondwrap .filtertab .hover-pricing-update-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .PricingRequestNav .RequestNav .secondwrap .filtertab .hover-pricing-update-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .PricingRequestNav .RequestNav .secondwrap .filtertab .hover-pricing-update-underline-animation-click {
        position: relative; }
      .PricingRequestNav .RequestNav .secondwrap .filtertab .hover-pricing-update-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .PricingRequestNav .RequestNav .secondwrap .download_csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .PricingRequestNav .RequestNav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .PricingRequestNav .RequestNav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .PricingRequestNav .RequestNav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .PricingRequestNav .RequestNav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .PricingRequestNav .RequestNav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .PricingRequestNav .RequestNav .ant-calendar-picker-clear,
  .PricingRequestNav .RequestNav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.PricingRequestNav .bottom-data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 90px;
  margin-bottom: 32px; }

.PricingRequestNav .ant-select-selection__placeholder {
  color: #898989; }

@media screen and (min-width: 1440px) {
  .RequestNav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .RequestNav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px); }

.fullorder_data .downn {
  transform: translateY(-125.6px); }

.non-cursor-pointer {
  pointer-events: none;
  border-bottom: none !important; }

.store-listing .ant-pagination {
  margin-top: 20px; }

.store-listing .content-page-title {
  margin-bottom: 0px; }

.store-listing .page-subtitle {
  font-size: 16px;
  color: black;
  margin-bottom: 0px; }

.store-listing table {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 20px; }
  .store-listing table .store-info {
    flex: 1 1; }
    .store-listing table .store-info p {
      font-size: 12px;
      margin-bottom: 0px;
      letter-spacing: 0.032px;
      line-height: 24px;
      color: #212121;
      font-weight: 400; }
  .store-listing table .store-status {
    display: flex;
    align-items: center; }
    .store-listing table .store-status p {
      font-size: 12px;
      margin-bottom: 0px;
      margin-left: 8px;
      letter-spacing: 0.032px;
      line-height: 24px;
      color: #212121;
      font-weight: 500; }
    .store-listing table .store-status .ant-switch-checked {
      background-color: #229d1b; }
  .store-listing table thead tr th {
    font-size: 12px;
    background: #16273b !important;
    border-radius: 0 !important;
    color: white !important;
    letter-spacing: 0.032px;
    line-height: 24px;
    font-weight: 400; }
  .store-listing table tbody {
    background: white; }
    .store-listing table tbody tr td {
      font-size: 12px;
      border-bottom-color: #f2f2f2 !important;
      letter-spacing: 0.032px;
      line-height: 24px; }

.store-listing .ant-switch-small {
  min-width: 32px;
  height: 16px;
  line-height: 14px; }

.store-listing .ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px !important; }

.store-listing .ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 4px !important; }

.store-listing .ant-table-tbody > tr:last-child > td:first-child {
  border-bottom-left-radius: 4px !important; }

.store-listing .ant-table-tbody > tr:last-child > td:last-child {
  border-bottom-right-radius: 4px !important; }

.store-listing .ant-table-tbody > tr > td :last-child {
  border-bottom: 0px !important; }

.store-listing .ant-table-tbody > tr > td {
  border-bottom: 0px !important; }

.store-listing .ant-table-tbody > tr {
  border-bottom: 1px solid #cecece !important; }

.store-listing .ant-table-tbody > tr:last-child {
  border-bottom: 0px !important; }

.store-listing .shopify-link {
  font-weight: 500;
  letter-spacing: 0.032px;
  line-height: 24px;
  font-size: 12px; }
  .store-listing .shopify-link a {
    color: #1268fb; }
  .store-listing .shopify-link .add-on {
    font-weight: 500;
    color: #212121;
    font-size: 12px; }

.Re-verify-details {
  font-size: 12px;
  font-weight: 500;
  color: #212121;
  text-decoration: underline !important;
  line-height: 16px;
  margin-bottom: 8px; }

.reverify_geo {
  font-weight: 600 !important;
  text-decoration: underline !important; }

.select-geo .ant-dropdown-menu {
  background-color: #f8f8f8;
  border-radius: 2px; }

.select-geo .ant-dropdown-menu-item {
  height: 40px;
  display: flex;
  align-items: center;
  width: 105px; }

.select-geo .ant-dropdown-menu-item:nth-of-type(1) {
  border-bottom: 1px solid #cecece; }

.select-geo .flex {
  display: flex;
  align-items: center;
  justify-content: flex-start; }
  .select-geo .flex h3 {
    font-size: 12px;
    color: #898989; }

.storgeo-dropdown {
  display: flex;
  align-items: center; }
  .storgeo-dropdown span {
    font-weight: 500;
    color: #898989; }
  .storgeo-dropdown .arrow {
    transition: 0.3s all; }
  .storgeo-dropdown .arrow-fill {
    transform: rotateX(180deg); }
    .storgeo-dropdown .arrow-fill g {
      fill: #898989; }

.store-geo-modal {
  padding: 0;
  width: 398px !important; }
  .store-geo-modal * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }
  .store-geo-modal .ant-modal-content {
    border-radius: 2px; }
  .store-geo-modal .ant-modal-close-x {
    display: none; }
  .store-geo-modal .ant-modal-body {
    padding: 24px; }
  .store-geo-modal h1 {
    font-size: 16px;
    font-weight: 500;
    margin: auto;
    color: #212121;
    margin-bottom: 5px;
    text-align: center; }
  .store-geo-modal h2 {
    font-size: 12px;
    color: #212121;
    margin: auto;
    text-align: center;
    font-weight: 400; }
  .store-geo-modal h5 {
    font-size: 12px;
    color: #212121;
    margin: auto;
    text-align: center;
    font-weight: 400;
    margin-bottom: 24px; }
    .store-geo-modal h5 span {
      font-weight: 500; }
  .store-geo-modal .content {
    background-color: #f8f8f8;
    padding: 0 16px;
    margin-bottom: 24px; }
    .store-geo-modal .content .div1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 49px; }
      .store-geo-modal .content .div1 p {
        font-size: 12px;
        color: #212121; }
    .store-geo-modal .content .flex {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .store-geo-modal .content .flex h3 {
        font-size: 12px;
        color: #898989; }
  .store-geo-modal .btns {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .store-geo-modal .btns .confirm {
      width: 176px;
      background-color: #2bc822;
      color: white;
      font-size: 16px !important;
      font-weight: 500;
      border-radius: 2px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }
    .store-geo-modal .btns .cancel {
      width: 161px;
      background-color: white;
      border: 2px solid #898989;
      color: 898989;
      font-size: 16px;
      font-weight: 500;
      border-radius: 2px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer; }

.storlistingnav .stornav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 255px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .storlistingnav .stornav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .storlistingnav .stornav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }

.storlistingnav .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 32px;
  padding-bottom: 32px; }

.storlistingnav .ant-select-selection__placeholder {
  color: #1268fb; }

@media screen and (min-width: 1440px) {
  .stornav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 344px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .stornav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.contact-center-nav .contact-ceneternav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .contact-center-nav .contact-ceneternav .contactcenterwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px;
    margin-bottom: 16px; }
  .contact-center-nav .contact-ceneternav .contact-center-secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact .tab {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact .tab .active {
          color: #1268fb; }
        .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact .tab button {
          display: contents;
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact .hover-inventory-underline-animation {
        position: relative; }
      .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact .hover-inventory-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 0px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact .hover-inventory-underline-animation:hover {
        color: #4398ed !important; }
        .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact .hover-inventory-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact .hover-inventory-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact .hover-inventory-underline-animation-click {
        position: relative; }
      .contact-center-nav .contact-ceneternav .contact-center-secondwrap .tab-container-contact .hover-inventory-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 0px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }

.contact-center-nav .contact-center-btn {
  display: flex; }
  .contact-center-nav .contact-center-btn .save-settings-btn button {
    margin-right: 16px !important; }
  .contact-center-nav .contact-center-btn .contact-center-settings-btn {
    margin-bottom: 10px;
    height: 32px;
    line-height: 30px;
    margin-right: 100px; }
  .contact-center-nav .contact-center-btn .save-settings-btn {
    margin-bottom: 10px;
    height: 32px;
    line-height: 30px;
    margin-right: 16px; }
    .contact-center-nav .contact-center-btn .save-settings-btn button {
      height: 37px !important; }

.contact-center-nav .disable-clickable-dropdown {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #94e38f !important;
  border-color: #94e38f !important; }

.contact-center-nav .disable-contact-center-settings-btn {
  pointer-events: none;
  cursor: not-allowed;
  background-color: #4d78e9 !important;
  border-color: #4d78e9 !important;
  height: 37px; }

.body-container-contact {
  margin-top: 24px;
  padding-top: 76px;
  margin-bottom: 32px; }

.contact-p {
  letter-spacing: 0.032em;
  font-weight: 500;
  height: 37px; }
  .contact-p svg {
    font-size: 16px;
    color: white;
    margin-right: 8px; }
    .contact-p svg g {
      color: white; }
    .contact-p svg path {
      color: white;
      fill: white; }
  .contact-p img {
    margin-right: 10px; }

.contact-g {
  height: 37px; }

@media screen and (min-width: 1440px) {
  .contact-ceneternav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6% !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1442px) and (max-width: 3200px) {
  .contact-ceneternav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.logo-containr svg {
  position: relative;
  height: 12px;
  width: 18px;
  transform: scale(1.5);
  left: 1px;
  top: 2px; }

.contact-center-setting {
  padding: 8px 32px; }
  .contact-center-setting .ant-radio-inner::after {
    top: 6px;
    left: 6px;
    width: 4px;
    height: 4px;
    background-color: #fff; }
  .contact-center-setting .ant-radio.ant-radio-checked .ant-radio-inner {
    background-color: #2bc822;
    border: none; }
  .contact-center-setting label.ant-radio-wrapper.ant-radio-wrapper-checked {
    color: #212121; }

.ant-tabs-content-animated {
  position: relative; }

.cod-order-content {
  font-size: 12px;
  letter-spacing: 0.032px; }
  .cod-order-content svg.reachable-message {
    cursor: pointer; }
  .cod-order-content .contact-center-tooltip:hover svg.reachable-message {
    background: #1268fb;
    border-radius: 50%; }
  .cod-order-content .contact-center-tooltip:hover svg.reachable-message g#Ellipse_41 {
    stroke: #1268fb; }
  .cod-order-content .contact-center-tooltip:hover svg.reachable-message text {
    fill: #fff !important; }
  .cod-order-content .contact-center-tooltip {
    position: relative;
    display: inline-block; }
  .cod-order-content .contact-center-tooltip .tooltiptext {
    display: none;
    width: 296px;
    font-weight: 400;
    background-color: #212121;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.032em;
    text-align: center;
    border-radius: 2px;
    padding: 8px 8px;
    position: absolute;
    z-index: 1;
    top: -62px;
    right: 5%;
    transition: opacity 0.3s; }
  .cod-order-content .contact-center-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #212121 transparent transparent transparent; }
  .cod-order-content .contact-center-tooltip:hover .tooltiptext {
    display: block; }
  .cod-order-content label.ant-radio-wrapper {
    line-height: 20px;
    height: unset;
    display: block; }

.non-delivery-reports {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em; }
  .non-delivery-reports label.ant-radio-wrapper {
    line-height: 20px;
    height: unset;
    display: block; }

.prepaid-order-content {
  font-size: 12px;
  letter-spacing: 0.032px; }
  .prepaid-order-content svg.reachable-message {
    cursor: pointer; }
  .prepaid-order-content .contact-center-tooltip:hover svg.reachable-message {
    background: #1268fb;
    border-radius: 50%; }
  .prepaid-order-content .contact-center-tooltip:hover svg.reachable-message g#Ellipse_41 {
    stroke: #1268fb; }
  .prepaid-order-content .contact-center-tooltip:hover svg.reachable-message text {
    fill: #fff !important; }
  .prepaid-order-content .contact-center-tooltip {
    position: relative;
    display: inline-block; }
  .prepaid-order-content .contact-center-tooltip .tooltiptext {
    display: none;
    width: 296px;
    font-weight: 400;
    background-color: #212121;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.032em;
    text-align: center;
    border-radius: 2px;
    padding: 8px 8px;
    position: absolute;
    z-index: 1;
    top: -62px;
    right: 5%;
    transition: opacity 0.3s; }
  .prepaid-order-content .contact-center-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #212121 transparent transparent transparent; }
  .prepaid-order-content .contact-center-tooltip:hover .tooltiptext {
    display: block; }
  .prepaid-order-content label.ant-radio-wrapper {
    line-height: 20px;
    height: unset;
    display: block; }

.contact-tab-container p,
.contact-tab-container h2,
.contact-tab-container span {
  font-size: 12px;
  letter-spacing: 0.032em; }

.contact-tab-container .save-setting {
  font-size: 12px;
  background-color: #0068ff;
  border: none;
  padding: 11px 16px;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  height: 40px;
  font-weight: 600; }

.contact-tab-container .ant-tabs {
  background: #f6f6f6;
  border-radius: 0px 0px 4px 4px; }

.contact-tab-container .ant-tabs-nav-container {
  width: 200px; }

.contact-tab-container .ant-tabs .ant-tabs-left-bar,
.contact-tab-container .ant-tabs .ant-tabs-right-bar {
  background: #f6f6f6;
  height: 100% !important;
  border-right: 0px;
  padding-top: 8px; }

.contact-tab-container .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: left;
  letter-spacing: 0.032em; }

.contact-tab-container .ant-tabs-nav .ant-tabs-tab-active {
  color: #212121;
  background: #ffff;
  font-weight: 400; }

.contact-tab-container .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none !important; }

.contact-tab-container .ant-tabs-tabpane.ant-tabs-tabpane-active {
  background: #fff; }

.contact-tab-container .ant-tabs .ant-tabs-left-content {
  padding: 16px 32px;
  background: #fff;
  min-height: 350px;
  border-left: 0px; }

.contact-tab-container .enable-order-content {
  padding-left: 32px;
  padding-bottom: 8px;
  padding-right: 24px; }

.contact-tab-container .calling-services {
  padding: 16px;
  background: #f5faff; }

.contact-tab-container .contact-tab-list {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 3px 10px;
  border-radius: 0px 0px 4px 4px; }

.contact-tab-container .ant-tabs-tab {
  margin: 0 0 1px 0 !important;
  padding: 12px 32px !important;
  font-size: 12px;
  line-height: 16px; }

.contact-tab-container .ant-tabs-nav .ant-tabs-tab:hover {
  color: #212121; }

.contact-tab-container .enable-order-text p {
  padding: 15px 0px 8px; }

.contact-tab-container label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  color: #000; }

.contact-tab-container .ant-checkbox {
  margin-right: 8px; }

.contact-tab-container .ant-radio-wrapper {
  padding-bottom: 5px; }

.contact-tab-container span.ant-checkbox-inner,
.contact-tab-container span.ant-radio-inner {
  background: #ededed; }

.contact-tab-container .ant-radio.ant-radio-checked span.ant-radio-inner {
  background-color: #2bc822; }

.contact-tab-container span.ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2bc822; }

.contact-tab-container .contact-tab-header {
  padding: 8px 32px;
  background-color: #16273b;
  border-radius: 4px 4px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px; }

.contact-tab-container .colling-services h2 {
  font-size: 14px;
  letter-spacing: 0.032em;
  color: #ffffff;
  font-weight: 500;
  line-height: 16px; }

.cod-order-content {
  font-size: 12px;
  letter-spacing: 0.032px; }
  .cod-order-content svg.reachable-message {
    cursor: pointer; }
  .cod-order-content .contact-center-tooltip:hover svg.reachable-message {
    background: #1268fb;
    border-radius: 50%; }
  .cod-order-content .contact-center-tooltip:hover svg.reachable-message g#Ellipse_41 {
    stroke: #1268fb; }
  .cod-order-content .contact-center-tooltip:hover svg.reachable-message text {
    fill: #fff !important; }
  .cod-order-content .contact-center-tooltip {
    position: relative;
    display: inline-block; }
  .cod-order-content .contact-center-tooltip .tooltiptext {
    display: none;
    width: 296px;
    font-weight: 400;
    background-color: #212121;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.032em;
    text-align: center;
    border-radius: 2px;
    padding: 8px 8px;
    position: absolute;
    z-index: 1;
    top: -62px;
    right: 5%;
    transition: opacity 0.3s; }
  .cod-order-content .contact-center-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #212121 transparent transparent transparent; }
  .cod-order-content .contact-center-tooltip:hover .tooltiptext {
    display: block; }
  .cod-order-content label.ant-radio-wrapper {
    line-height: 20px;
    height: unset;
    display: block; }

.non-delivery-reports {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.032em; }
  .non-delivery-reports label.ant-radio-wrapper {
    line-height: 20px;
    height: unset;
    display: block; }

.prepaid-order-content {
  font-size: 12px;
  letter-spacing: 0.032px; }
  .prepaid-order-content svg.reachable-message {
    cursor: pointer; }
  .prepaid-order-content .contact-center-tooltip:hover svg.reachable-message {
    background: #1268fb;
    border-radius: 50%; }
  .prepaid-order-content .contact-center-tooltip:hover svg.reachable-message g#Ellipse_41 {
    stroke: #1268fb; }
  .prepaid-order-content .contact-center-tooltip:hover svg.reachable-message text {
    fill: #fff !important; }
  .prepaid-order-content .contact-center-tooltip {
    position: relative;
    display: inline-block; }
  .prepaid-order-content .contact-center-tooltip .tooltiptext {
    display: none;
    width: 296px;
    font-weight: 400;
    background-color: #212121;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.032em;
    text-align: center;
    border-radius: 2px;
    padding: 8px 8px;
    position: absolute;
    z-index: 1;
    top: -62px;
    right: 5%;
    transition: opacity 0.3s; }
  .prepaid-order-content .contact-center-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 10px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #212121 transparent transparent transparent; }
  .prepaid-order-content .contact-center-tooltip:hover .tooltiptext {
    display: block; }
  .prepaid-order-content label.ant-radio-wrapper {
    line-height: 20px;
    height: unset;
    display: block; }

.variant-mapping {
  margin-top: 62px; }
  .variant-mapping .variant-matching .header-txt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px; }
    .variant-mapping .variant-matching .header-txt p {
      color: #898989;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      font-weight: 400;
      margin-right: 172px; }
  .variant-mapping .save-continue {
    background: #2bc822;
    border: none;
    border-radius: 2px;
    letter-spacing: 0.032em;
    line-height: 16px;
    font-weight: 500;
    float: right;
    margin-top: 16px; }
  .variant-mapping .varaint-matching-data {
    display: flex;
    justify-self: space-between;
    width: 100%; }
    .variant-mapping .varaint-matching-data .left-varaint-bundle-image-data {
      width: 43%;
      margin-right: 16px;
      display: flex;
      align-items: center;
      margin-bottom: 48px; }
      .variant-mapping .varaint-matching-data .left-varaint-bundle-image-data img {
        margin-left: 16px; }
  .variant-mapping .ant-select-selection {
    min-height: 64px;
    border: none;
    background: #f5f5f5;
    border-radius: 2px; }
    .variant-mapping .ant-select-selection .ant-select-selection__rendered {
      min-height: 48px;
      padding-top: 6px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.032em;
      color: #212121; }
    .variant-mapping .ant-select-selection .ant-select-search--inline {
      position: absolute;
      width: 100%;
      height: 100%;
      margin-top: -2px; }
  .variant-mapping .ali-product-name {
    font-weight: 400;
    margin-top: 72px;
    margin-bottom: 24px;
    color: #898989;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    background: #f5faff;
    padding: 8px;
    border-radius: 4px; }
  .variant-mapping .ant-select-selection__placeholder {
    top: 72%;
    font-size: 12px;
    color: #898989;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .variant-mapping .link-product-name {
    color: #0068ff;
    letter-spacing: 0.032em;
    font-weight: 400; }
  .variant-mapping .tooltipText {
    margin-right: 8px; }
  .variant-mapping .aliproduct-length {
    color: #898989; }
  .variant-mapping .anticon:hover svg #Ellipse_44 {
    stroke: #1268fb;
    fill: #1268fb; }
  .variant-mapping .anticon:hover svg #Ellipse_47 {
    stroke: #fff;
    fill: #ffff; }
  .variant-mapping .anticon:hover svg #Rectangle_2462 {
    stroke: #fff;
    fill: #ffff; }

.bundle-variant-nav-data .variant-nav {
  padding-left: 59px;
  padding-top: 58px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }

.bundle-variant-nav-data .ordfillwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px; }

.bundle-variant-nav-data .variant-mapping-txt {
  text-decoration: underline;
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #000; }

.bundle-variant-nav-data .arrow-txt {
  margin: 0 4px; }

@media screen and (min-width: 1440px) {
  .variant-nav {
    padding-left: 104px !important;
    padding-top: 58px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .variant-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.right-varaint-bundle-image-data {
  width: 50%; }
  .right-varaint-bundle-image-data .ant-input-number {
    width: 64px !important;
    height: 64px !important;
    border-radius: 2px !important;
    background: #f5f5f5 !important;
    display: flex !important;
    align-items: center !important;
    font-size: 20px !important;
    color: #000 !important;
    letter-spacing: 0.032em !important;
    line-height: 16px !important;
    border: none !important; }
  .right-varaint-bundle-image-data .ant-btn:not([disabled]):hover {
    text-decoration: none; }
  .right-varaint-bundle-image-data .ant-btn:hover,
  .right-varaint-bundle-image-data .ant-btn:focus {
    color: #fd104d;
    border-color: #fd104d; }

.allspec-data {
  display: flex; }

.allspec-dropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 400;
  letter-spacing: 0.032em;
  line-height: 24px; }

.allspec-dropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 2px solid #b5dafe !important;
  border-radius: 0px !important; }

.option-varaint.allspecs-product-name {
  margin-bottom: -5px; }

.add-new-varaint {
  margin-top: -8px;
  color: #fd104d;
  font-size: 12px;
  letter-spacing: 0.032em;
  line-height: 16px;
  border: none;
  box-shadow: none; }

.switch-store-modal .ant-alert-info {
  margin-bottom: 1rem; }

.switch-store-modal .ant-input-group-wrapper {
  margin-bottom: 1rem; }
  .switch-store-modal .ant-input-group-wrapper .ant-input {
    padding: 1.2rem; }
  .switch-store-modal .ant-input-group-wrapper .ant-input::placeholder {
    font-size: 1rem; }
  .switch-store-modal .ant-input-group-wrapper .ant-input-group-addon {
    font-size: 0.9rem; }

.switch-store-modal .subscript {
  font-weight: 400;
  color: #707070; }

.switch-store-modal .ant-modal-close-x {
  display: none !important; }

.switch-store-modal .middle_data {
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  padding: 32px; }
  .switch-store-modal .middle_data p {
    margin-right: 32px; }

.switch-store-modal .footer_button {
  display: flex;
  justify-content: center; }

.switch-store-modal .ant-modal-header {
  border-bottom: none !important; }

.switch-store-modal .ant-modal-content {
  padding: 32px; }

.switch-store-modal .ant-modal-body {
  padding: 0 !important; }

.switch-store-modal .user_description {
  text-align: center;
  margin-bottom: 16px;
  margin-top: -16px;
  line-height: 16px;
  letter-spacing: 0.032em;
  color: #898989;
  font-size: 12px; }

.switch-store-modal .footer_description p {
  margin-bottom: 24px;
  color: #212121;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-size: 12px; }

.ndr-escalations {
  letter-spacing: 0.032em !important; }
  .ndr-escalations .bucket-filters-wraper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .ndr-escalations .content-page-title {
    margin-bottom: 0px; }
  .ndr-escalations .page-subtitle {
    font-size: 16px;
    color: black;
    margin-bottom: 0px; }
    .ndr-escalations .page-subtitle span {
      font-size: 14px;
      color: black; }
  .ndr-escalations .dispute-filter {
    margin-bottom: 32px; }
    .ndr-escalations .dispute-filter .dispute-status {
      font-size: 14px;
      color: black;
      margin-bottom: 0px; }
    .ndr-escalations .dispute-filter .ant-btn {
      background: #1370fc;
      border: none;
      box-shadow: none;
      color: white;
      font-size: 12px;
      font-weight: 500;
      width: 160px;
      height: 40px;
      border-radius: 2px !important; }
    .ndr-escalations .dispute-filter .ant-select {
      width: auto !important; }
      .ndr-escalations .dispute-filter .ant-select .ant-select-selection {
        border: none;
        box-shadow: none;
        color: #000000a6; }
  .ndr-escalations .drop {
    background-color: white !important;
    padding: 4.5px 5px 4.5px 17px;
    width: 290px;
    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important; }
    .ndr-escalations .drop .ant-select-selection__rendered {
      margin-left: 8px;
      background-color: white !important;
      margin-right: 2px;
      height: 100% !important; }
      .ndr-escalations .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important; }
    .ndr-escalations .drop p {
      font-size: 12px !important;
      line-height: 16px !important;
      color: #898989 !important; }
    .ndr-escalations .drop .ant-select-selection-selected-value {
      font-size: 13px;
      line-height: 16px;
      color: #1268fb; }
    .ndr-escalations .drop .sel .ant-select-selection {
      width: 159px; }
      .ndr-escalations .drop .sel .ant-select-selection .ant-select-selection-selected-value {
        line-height: 30px; }
    .ndr-escalations .drop .sel .ant-select-arrow {
      top: 53%; }
  .ndr-escalations .skeleton {
    margin-bottom: 30px; }
  .ndr-escalations .empty-card {
    margin-bottom: 20px;
    text-align: center; }
  .ndr-escalations .no-escalations-found-txt {
    margin: 0; }

.import-product .placeholder ::placeholder {
  font-size: 12px; }

.import-product .ant-input-group .ant-input {
  height: 40px;
  box-shadow: 0px 2px 8px #00000014;
  border-radius: 2px 0 0 2px;
  border: 0px;
  background: white !important; }

.pick {
  color: #1268fb !important;
  background-color: white !important;
  margin-left: 8px;
  font-size: 12px !important;
  box-shadow: 0px 2px 8px #00000014; }
  .pick :focus {
    outline: none !important;
    border: none !important; }
  .pick :active {
    outline: none !important;
    border: none !important; }
  .pick .ant-calendar-picker-input {
    color: #1268fb !important;
    background: none;
    border: none;
    outline: none;
    font-size: 12px !important;
    padding-left: 0; }
    .pick .ant-calendar-picker-input :focus {
      outline: none !important;
      border: none !important; }
    .pick .ant-calendar-picker-input :active {
      outline: none !important;
      border: none !important; }
  .pick .ant-calendar-range-picker-separator {
    color: #1268fb !important;
    vertical-align: baseline; }

.time-control {
  display: flex;
  width: 250px;
  align-items: center;
  justify-content: center;
  height: 24px;
  background-color: #f5f5f5; }
  .time-control .play-pause {
    padding: 0px 8px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .time-control .download-btn {
    padding: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .time-control input {
    width: 100%;
    height: 4px;
    position: absolute;
    -webkit-appearance: none;
    background: none;
    cursor: pointer; }
    .time-control input:focus {
      outline: none; }
    .time-control input::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 1rem;
      width: 1rem; }
  .time-control p {
    padding: 1rem;
    text-align: center; }

.play-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  height: 4px; }
  .play-control svg {
    cursor: pointer; }

.track {
  width: 100%;
  background-color: #1370fb;
  position: relative;
  overflow: hidden;
  height: 4px; }

.animate-track {
  position: absolute;
  height: 16px;
  width: 100%;
  background-color: lightgrey;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  pointer-events: none;
  transition: all 0.3s linear; }

@media screen and (max-width: 768px) {
  .time-control {
    width: 90%; }
  .play-control {
    width: 40%; } }

.customer-info-tab {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }
  .customer-info-tab .header_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%; }
    .customer-info-tab .header_customer_info .full_name {
      width: 32%; }
      .customer-info-tab .header_customer_info .full_name .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #000; }
      .customer-info-tab .header_customer_info .full_name .label {
        font-size: 12px;
        color: #898898; }
    .customer-info-tab .header_customer_info .address_line1 {
      width: 32%; }
      .customer-info-tab .header_customer_info .address_line1 .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #000; }
      .customer-info-tab .header_customer_info .address_line1 .label {
        font-size: 12px;
        color: #898898; }
    .customer-info-tab .header_customer_info .Pin_code {
      width: 32%; }
      .customer-info-tab .header_customer_info .Pin_code .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #000; }
      .customer-info-tab .header_customer_info .Pin_code .label {
        font-size: 12px;
        color: #898898; }
  .customer-info-tab .middle_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 100%; }
    .customer-info-tab .middle_customer_info .email_address {
      width: 32%; }
      .customer-info-tab .middle_customer_info .email_address .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #000; }
      .customer-info-tab .middle_customer_info .email_address .label {
        font-size: 12px;
        color: #898898; }
    .customer-info-tab .middle_customer_info .address_line2 {
      width: 32%; }
      .customer-info-tab .middle_customer_info .address_line2 .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #000; }
      .customer-info-tab .middle_customer_info .address_line2 .label {
        font-size: 12px;
        color: #898898; }
    .customer-info-tab .middle_customer_info .ship_country {
      width: 32%; }
      .customer-info-tab .middle_customer_info .ship_country .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #000; }
      .customer-info-tab .middle_customer_info .ship_country .label {
        font-size: 12px;
        color: #898898; }
  .customer-info-tab .footer_customer_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px; }
    .customer-info-tab .footer_customer_info .mobile_number {
      width: 32%; }
      .customer-info-tab .footer_customer_info .mobile_number .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #000; }
      .customer-info-tab .footer_customer_info .mobile_number .label {
        font-size: 12px;
        color: #898898; }
    .customer-info-tab .footer_customer_info .citytown {
      width: 32%; }
      .customer-info-tab .footer_customer_info .citytown .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #000; }
      .customer-info-tab .footer_customer_info .citytown .label {
        font-size: 12px;
        color: #898898; }
    .customer-info-tab .footer_customer_info .State {
      width: 32%; }
      .customer-info-tab .footer_customer_info .State .shipment_cstm_info {
        background: #f5f5f5;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        padding: 8px;
        color: #000; }
      .customer-info-tab .footer_customer_info .State .label {
        font-size: 12px;
        color: #898898; }

.escalation-card {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 20px; }
  .escalation-card .header-top-row p {
    color: #fff;
    border-bottom-left-radius: 0;
    font-size: 12px;
    width: 115px;
    text-align: center;
    height: 26px;
    margin-top: 0px;
    border-radius: 2px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .escalation-card .header-top-row .action_taken {
    border-radius: 2px;
    font-size: 12px;
    font-weight: 500;
    padding-left: 12px;
    padding-right: 12px;
    color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 24px;
    text-align: center;
    letter-spacing: 0.032em;
    align-items: center;
    line-height: 16px; }
  .escalation-card .vforderid_tag {
    padding: 5px;
    padding-left: 15px;
    position: absolute;
    left: -10px;
    top: 8px;
    background: #ed7741;
    color: white;
    border-radius: 2px;
    border-bottom-left-radius: 0; }
  .escalation-card .vforderid_tag:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: 100%;
    left: 0px;
    border-bottom: 9px solid #ed7741;
    border-left: 9px solid transparent;
    transform: rotate(270deg); }
  .escalation-card .ant-card-body {
    padding: 0px; }
    .escalation-card .ant-card-body .blue-text {
      color: #1268fb;
      font-weight: 500; }
    .escalation-card .ant-card-body .header-row {
      display: flex;
      align-items: center;
      padding: 46px 52px 20px 52px; }
      .escalation-card .ant-card-body .header-row h3 {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 400; }
      .escalation-card .ant-card-body .header-row .dispute-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        min-width: 66px; }
        .escalation-card .ant-card-body .header-row .dispute-title .anticon {
          font-size: 17px;
          margin-right: 10px; }
      .escalation-card .ant-card-body .header-row .dispute-status {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 36px; }
    .escalation-card .ant-card-body .circle-ican {
      font-size: 10px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: "white";
      padding: 4px 8px;
      padding-right: 5px; }
    .escalation-card .ant-card-body .circle-ican-status {
      font-size: 12px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 4px 10px;
      padding-left: 0;
      font-weight: 500; }
    .escalation-card .ant-card-body .tabs-row {
      background: #16273b;
      padding: 0 32px;
      display: flex;
      align-items: center; }
      .escalation-card .ant-card-body .tabs-row ul {
        list-style-type: none;
        margin: 0;
        padding: 0; }
        .escalation-card .ant-card-body .tabs-row ul li {
          display: inline-block;
          padding: 12px 15px;
          color: white;
          cursor: pointer;
          line-height: 20px;
          letter-spacing: 0.032em; }
        .escalation-card .ant-card-body .tabs-row ul li.active,
        .escalation-card .ant-card-body .tabs-row ul li:hover {
          background: #244060; }
    .escalation-card .ant-card-body .tab-container {
      background: #f5f5f5;
      padding: 0 32px 32px 32px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px; }
      .escalation-card .ant-card-body .tab-container .dispute-info-tab {
        background: white;
        padding: 16px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px; }
        .escalation-card .ant-card-body .tab-container .dispute-info-tab .dispute-info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px; }
          .escalation-card .ant-card-body .tab-container .dispute-info-tab .dispute-info p {
            font-size: 14px;
            font-weight: 500;
            color: black;
            margin-bottom: 0px; }
            .escalation-card .ant-card-body .tab-container .dispute-info-tab .dispute-info p span {
              color: #5a5a5a;
              font-weight: 400; }
        .escalation-card .ant-card-body .tab-container .dispute-info-tab .dispute-reason {
          width: 100%;
          margin-right: 8px; }
          .escalation-card .ant-card-body .tab-container .dispute-info-tab .dispute-reason h6 {
            font-size: 12px;
            line-height: 16px;
            color: #898989;
            margin-bottom: 4px;
            font-weight: 400; }
          .escalation-card .ant-card-body .tab-container .dispute-info-tab .dispute-reason p {
            font-size: 12px;
            padding: 8px 16px;
            background: #f5f5f5;
            color: #252525; }
      .escalation-card .ant-card-body .tab-container .escalation-log {
        padding: 16px 0px 7px 0px; }
      .escalation-card .ant-card-body .tab-container .line-items-tab {
        padding: 16px 13px 7px 13px;
        background: #fff; }
        .escalation-card .ant-card-body .tab-container .line-items-tab .line-item {
          background: #f2f2f2;
          margin-bottom: 10px;
          padding: 14px 12px;
          display: flex;
          align-items: center;
          border: 1px solid transparent; }
        .escalation-card .ant-card-body .tab-container .line-items-tab .anticon {
          font-size: 17px; }
        .escalation-card .ant-card-body .tab-container .line-items-tab .line-item-product-detail {
          display: flex;
          align-items: center; }
        .escalation-card .ant-card-body .tab-container .line-items-tab .line-item-img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          display: inline-block;
          background-size: cover !important;
          background-position: center !important;
          border: 1px solid #c8c8c8; }
        .escalation-card .ant-card-body .tab-container .line-items-tab .line-item-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
          font-size: 12px; }
        .escalation-card .ant-card-body .tab-container .line-items-tab .line-item-sku {
          font-size: 12px;
          color: #676666;
          font-weight: 400;
          margin: 0;
          margin-top: -2px; }
          .escalation-card .ant-card-body .tab-container .line-items-tab .line-item-sku b {
            color: #898989;
            font-weight: 400; }
          .escalation-card .ant-card-body .tab-container .line-items-tab .line-item-sku .detailcommon {
            color: #000;
            letter-spacing: 0.032em;
            font-size: 12px;
            line-height: 16px; }
        .escalation-card .ant-card-body .tab-container .line-items-tab .delivered-date {
          margin-bottom: 0px;
          text-decoration: none; }
      .escalation-card .ant-card-body .tab-container .media-attachments {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        background-color: #ffffff;
        padding-top: 28px; }
        .escalation-card .ant-card-body .tab-container .media-attachments .download-image-data {
          position: absolute;
          bottom: 25px;
          padding: 6px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 93px; }
    .escalation-card .ant-card-body .audio-container {
      padding: 0 16px; }
      .escalation-card .ant-card-body .audio-container p {
        font-size: 12px;
        color: #898989;
        font-weight: 400;
        margin-bottom: 0px; }
      .escalation-card .ant-card-body .audio-container .audio-wraper-all {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px; }
        .escalation-card .ant-card-body .audio-container .audio-wraper-all .audio-wraper {
          margin-right: 24px;
          margin-top: 12px; }
    .escalation-card .ant-card-body .images-container {
      padding: 0 16px; }
      .escalation-card .ant-card-body .images-container p {
        font-size: 12px;
        color: #898989;
        font-weight: 400;
        margin-bottom: 0px; }
      .escalation-card .ant-card-body .images-container .image-div-wraper {
        border: none !important;
        margin-top: 12px !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap; }
        .escalation-card .ant-card-body .images-container .image-div-wraper .img-div {
          height: 98px !important;
          width: 98px !important;
          margin-bottom: 20px;
          margin-right: 24px;
          position: relative; }
          .escalation-card .ant-card-body .images-container .image-div-wraper .img-div img {
            height: 100% !important;
            width: 100% !important;
            padding: 0px !important;
            object-fit: cover; }
          .escalation-card .ant-card-body .images-container .image-div-wraper .img-div .download-icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0px;
            right: 8px;
            cursor: pointer; }
            .escalation-card .ant-card-body .images-container .image-div-wraper .img-div .download-icon i {
              font-size: 24px; }
          .escalation-card .ant-card-body .images-container .image-div-wraper .img-div .eye-icon {
            transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
            position: absolute;
            opacity: 0;
            font-size: 40px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer; }
        .escalation-card .ant-card-body .images-container .image-div-wraper .img-div:hover .eye-icon {
          transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
          opacity: 1; }
    .escalation-card .ant-card-body .videos-container {
      padding: 0 16px; }
      .escalation-card .ant-card-body .videos-container p {
        font-size: 12px;
        color: #898989;
        font-weight: 400;
        margin-bottom: 12px; }
      .escalation-card .ant-card-body .videos-container .video-div-wraper {
        border: none !important;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap; }
        .escalation-card .ant-card-body .videos-container .video-div-wraper .video-div {
          height: 98px !important;
          width: 98px !important;
          border: 1px solid #c9c9c9;
          margin-bottom: 20px;
          margin-right: 24px;
          position: relative; }
          .escalation-card .ant-card-body .videos-container .video-div-wraper .video-div video {
            width: 100%;
            height: 100%;
            object-fit: cover; }
          .escalation-card .ant-card-body .videos-container .video-div-wraper .video-div .video-icon {
            transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
            position: absolute;
            opacity: 0;
            font-size: 40px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
            opacity: 1; }
          .escalation-card .ant-card-body .videos-container .video-div-wraper .video-div .download-icon {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 0px;
            right: 8px;
            cursor: pointer; }
            .escalation-card .ant-card-body .videos-container .video-div-wraper .video-div .download-icon i {
              font-size: 24px; }
      .escalation-card .ant-card-body .videos-container .video-div:hover .video-icon {
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        opacity: 1; }
  .escalation-card .flex {
    display: flex;
    justify-content: space-between; }
    .escalation-card .flex .flex-note-log {
      width: 45%; }
    .escalation-card .flex .flex-date {
      width: 27%; }
    .escalation-card .flex .flex-addedby {
      width: 25%; }
  .escalation-card .flex-5 {
    flex: 4 1; }
    .escalation-card .flex-5 .line-item-product-detail {
      width: 100%; }
  .escalation-card .flex-6 {
    flex: 3 1; }
  .escalation-card .flex-7 {
    flex: 2 1;
    font-size: 12px; }
  .escalation-card .flex-8 {
    flex: 1 1; }
  .escalation-card .note-logs {
    padding: 8px 16px;
    background: #f5f5f5;
    margin-bottom: 8px; }
  .escalation-card .notes-logs-txt {
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989; }
  .escalation-card .notes-logs-data {
    background: white;
    padding: 12px 32px;
    font-size: 12px; }
  .escalation-card .tab-count {
    display: inline-block;
    background: #ff9400;
    width: 20px;
    height: 20px;
    border-radius: 16px;
    margin-left: 12px;
    text-align: center;
    font-size: 12px;
    line-height: 20px; }
  .escalation-card .upper_data {
    display: flex;
    justify-content: space-between; }
  .escalation-card .no-found {
    padding: 16px;
    text-align: center;
    background: white;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 20px;
    color: "#000";
    font-weight: 500; }
  .escalation-card .video-data {
    width: 100px;
    height: 100px;
    cursor: pointer; }
  .escalation-card .shipment-status {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .escalation-card .returned-on {
    margin-left: -16px; }
  .escalation-card .delivered-on {
    margin-left: -16px; }
  .escalation-card .delivered {
    margin-left: -38px; }
  .escalation-card .returned {
    margin-left: -38px; }
  .escalation-card .shipment-status {
    margin-left: 24px; }
  .escalation-card .header-varaint-status {
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989;
    font-size: 12px; }
  .escalation-card .header-varaint-status-table {
    margin-bottom: 10px;
    padding: 0 0px;
    color: #898989;
    font-size: 12px; }
  .escalation-card .delivered-on-date {
    color: #73d13d;
    margin-bottom: 0;
    font-size: 12px;
    text-decoration: underline;
    display: flex;
    justify-content: left; }
  .escalation-card .top-header {
    display: flex;
    width: 97%;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px; }
    .escalation-card .top-header .vf {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px; }
      .escalation-card .top-header .vf p {
        padding: 4px 10px;
        font-size: 12px;
        font-weight: 500; }
  .escalation-card .fields {
    display: flex;
    width: 100%;
    padding: 0 32px;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px; }
    .escalation-card .fields .field {
      flex: 1 1;
      margin-right: 24px; }
      .escalation-card .fields .field .field-label {
        font-size: 12px;
        line-height: 16px;
        color: #898989;
        margin-bottom: 4px; }
      .escalation-card .fields .field .field-value {
        font-size: 12px;
        padding: 8px 12px;
        background: #f5f5f5;
        color: black; }
    .escalation-card .fields .field:last-of-type {
      margin-right: 0; }
  .escalation-card .transaction-stats-tabledc table thead {
    height: 40px;
    padding-left: 24px; }
    .escalation-card .transaction-stats-tabledc table thead tr th {
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.3px;
      background: #5c89e4 !important;
      border-radius: 0 !important;
      color: white !important;
      padding: 10px; }
    .escalation-card .transaction-stats-tabledc table thead tr th:nth-of-type(1) {
      padding-left: 24px; }
  .escalation-card .transaction-stats-tabledc table tbody {
    background: white; }
    .escalation-card .transaction-stats-tabledc table tbody tr td {
      font-size: 12px;
      border-bottom-color: #f2f2f2 !important;
      text-transform: capitalize;
      padding: 10px;
      padding-top: 8px;
      padding-bottom: 8px; }
      .escalation-card .transaction-stats-tabledc table tbody tr td p {
        margin-bottom: 0px; }
    .escalation-card .transaction-stats-tabledc table tbody tr td:nth-of-type(1) {
      padding-left: 24px; }
    .escalation-card .transaction-stats-tabledc table tbody tr td:nth-of-type(6) {
      color: #212121; }
    .escalation-card .transaction-stats-tabledc table tbody tr td:nth-of-type(4) button {
      width: 104px;
      font-size: 12px;
      font-weight: 500;
      background-color: #1370fc;
      color: #fff;
      border-radius: 2px;
      border: none;
      box-shadow: 0px 2px 3px #00000020; }
    .escalation-card .transaction-stats-tabledc table tbody tr td:nth-of-type(7) .status {
      padding: 0 7px;
      display: flex;
      align-items: center;
      height: 26px;
      border-radius: 2px;
      width: auto;
      color: #fff;
      font-size: 12px;
      font-weight: 500; }
      .escalation-card .transaction-stats-tabledc table tbody tr td:nth-of-type(7) .status .circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #fff;
        margin-right: 8px; }
    .escalation-card .transaction-stats-tabledc table tbody tr td:nth-of-type(8) .action a {
      display: flex;
      align-items: center; }
    .escalation-card .transaction-stats-tabledc table tbody tr td:nth-of-type(9) {
      color: #212121; }
    .escalation-card .transaction-stats-tabledc table tbody tr td:nth-of-type(10) {
      color: #212121; }
    .escalation-card .transaction-stats-tabledc table tbody tr td:nth-of-type(11) {
      color: #212121; }
    .escalation-card .transaction-stats-tabledc table tbody tr td:nth-of-type(12) {
      color: #212121; }
  .escalation-card .log-heading-cointainer {
    display: flex;
    padding: 16px 16px 0px; }
    .escalation-card .log-heading-cointainer div {
      font-size: 12px;
      width: 50%; }
  .escalation-card .logsnotes-container:last-of-type {
    border-bottom: none; }
  .escalation-card .logsnotes-container {
    border-bottom: 1px solid gray;
    display: flex;
    padding: 16px 16px; }
    .escalation-card .logsnotes-container .right-log .right-icon {
      position: absolute;
      left: -8px;
      bottom: -5px; }
    .escalation-card .logsnotes-container .log-contiainer {
      font-size: 12px;
      width: 50%; }
      .escalation-card .logsnotes-container .log-contiainer .divider-bar {
        padding-bottom: 24px;
        border-bottom: 1px solid gray; }
      .escalation-card .logsnotes-container .log-contiainer .Escalation--log-container {
        width: 90%;
        background-color: #eaf0fe;
        padding: 8px 16px;
        position: relative; }
        .escalation-card .logsnotes-container .log-contiainer .Escalation--log-container .line-1 {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px; }
          .escalation-card .logsnotes-container .log-contiainer .Escalation--log-container .line-1 .esc {
            font-weight: 500;
            color: #212121; }
          .escalation-card .logsnotes-container .log-contiainer .Escalation--log-container .line-1 .divi {
            display: inline-block;
            margin: 0px 3px; }
          .escalation-card .logsnotes-container .log-contiainer .Escalation--log-container .line-1 .view-btn {
            text-decoration: underline;
            color: #1268fb;
            cursor: pointer; }
          .escalation-card .logsnotes-container .log-contiainer .Escalation--log-container .line-1 .view-btn:hover {
            color: #1268fb;
            cursor: pointer;
            font-weight: 500; }
        .escalation-card .logsnotes-container .log-contiainer .Escalation--log-container .remark-partner-text {
          font-weight: 500;
          color: #212121; }
      .escalation-card .logsnotes-container .log-contiainer .ece-status span {
        font-weight: 500;
        color: #212121; }
      .escalation-card .logsnotes-container .log-contiainer .reattampt-container.scroller {
        max-height: 120px !important; }
        .escalation-card .logsnotes-container .log-contiainer .reattampt-container.scroller h2 {
          font-size: 12px; }
      .escalation-card .logsnotes-container .log-contiainer .reattampt-container {
        background-color: #fef5e9;
        padding: 8px 16px;
        position: relative; }
        .escalation-card .logsnotes-container .log-contiainer .reattampt-container .esc {
          font-weight: 500;
          color: #212121; }
        .escalation-card .logsnotes-container .log-contiainer .reattampt-container .divi {
          display: inline-block;
          margin: 0px 3px; }
        .escalation-card .logsnotes-container .log-contiainer .reattampt-container .remark-partner-text {
          font-weight: 500;
          color: #212121; }
        .escalation-card .logsnotes-container .log-contiainer .reattampt-container .remark-parnter {
          margin-top: 10px; }
        .escalation-card .logsnotes-container .log-contiainer .reattampt-container .left-icon {
          position: absolute;
          bottom: -5px;
          right: -8px; }
      .escalation-card .logsnotes-container .log-contiainer .Escalation-close {
        background-color: #fef5e9;
        padding: 8px 8px;
        position: relative;
        margin-top: 10px; }
        .escalation-card .logsnotes-container .log-contiainer .Escalation-close .esc {
          font-weight: 500;
          color: #212121; }
        .escalation-card .logsnotes-container .log-contiainer .Escalation-close .divi {
          display: inline-block;
          margin: 0px 3px; }
        .escalation-card .logsnotes-container .log-contiainer .Escalation-close .left-icon {
          position: absolute;
          bottom: -5px;
          right: -8px; }
      .escalation-card .logsnotes-container .log-contiainer .escalation-processing {
        background-color: #fef5e9;
        padding: 8px 8px;
        position: relative;
        color: #212121;
        font-weight: 500; }
        .escalation-card .logsnotes-container .log-contiainer .escalation-processing .left-icon {
          position: absolute;
          bottom: -5px;
          right: -8px; }

.lists li {
  font-size: 12px; }

.clickable.ar svg {
  transform: rotate(90deg);
  margin-right: 6px !important; }
  .clickable.ar svg path {
    fill: #fff; }

.image-modal .ant-modal-body {
  min-height: 400px; }
  .image-modal .ant-modal-body .img-wraper {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    height: 400px; }
    .image-modal .ant-modal-body .img-wraper .image-size {
      width: 100%;
      height: 100%;
      position: relative; }
    .image-modal .ant-modal-body .img-wraper .video-size {
      width: 100%;
      height: 100%;
      position: relative; }

.image-modal .ant-modal-close-x {
  width: 32px;
  height: 32px;
  line-height: 29px; }

.image-modal .image-arrow {
  position: absolute;
  top: 50%;
  z-index: 99;
  background: transparent;
  padding: 0px;
  height: 40px;
  width: 40px;
  border: none;
  box-shadow: none; }
  .image-modal .image-arrow .anticon {
    font-size: 40px; }

.picing-request-nav .request-nav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .picing-request-nav .request-nav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .picing-request-nav .request-nav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .picing-request-nav .request-nav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .picing-request-nav .request-nav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .picing-request-nav .request-nav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .picing-request-nav .request-nav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .picing-request-nav .request-nav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .picing-request-nav .request-nav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .picing-request-nav .request-nav .ordfillwrap .right .show-data-from {
        font-size: 12px;
        margin-left: 8px; }
      .picing-request-nav .request-nav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .picing-request-nav .request-nav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .picing-request-nav .request-nav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .picing-request-nav .request-nav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .picing-request-nav .request-nav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .picing-request-nav .request-nav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .picing-request-nav .request-nav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .picing-request-nav .request-nav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1268fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1268fb; }
      .picing-request-nav .request-nav .secondwrap .filtertab .tabunderline {
        border-bottom: 2px solid #1268fb; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation {
        position: relative; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation:hover {
        color: #4398ed !important; }
        .picing-request-nav .request-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation-click {
        position: relative; }
      .picing-request-nav .request-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .picing-request-nav .request-nav .secondwrap .download-csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .picing-request-nav .request-nav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .picing-request-nav .request-nav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .picing-request-nav .request-nav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .picing-request-nav .request-nav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .picing-request-nav .request-nav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .picing-request-nav .request-nav .ant-calendar-picker-clear,
  .picing-request-nav .request-nav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.picing-request-nav .download_csv {
  margin-right: 100px;
  margin-bottom: 10px;
  line-height: 30px;
  height: 32px;
  line-height: 30px; }

.picing-request-nav .bottom-data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 90px;
  margin-bottom: 32px; }

.picing-request-nav .ant-select-selection__placeholder {
  color: #898989; }

.picing-request-nav .left {
  margin-left: "7px";
  color: #000; }
  .picing-request-nav .left .content-page-title {
    display: block;
    font-size: 12px;
    margin-bottom: 12px;
    font-weight: 400; }
    .picing-request-nav .left .content-page-title .escalations-txt {
      text-decoration: underline; }

.picing-request-nav .range-picker {
  margin-left: 10px;
  max-width: 300px; }

.picing-request-nav .drop {
  background-color: #f8f8f8 !important;
  padding: 0px;
  box-shadow: 0px 2px 8px #00000014;
  letter-spacing: 0 !important; }
  .picing-request-nav .drop > div {
    height: 40px !important; }
  .picing-request-nav .drop .ant-select.ant-select-enabled {
    height: 40px !important; }
  .picing-request-nav .drop .ant-select-selection__rendered {
    margin-left: 16px;
    background-color: #f8f8f8 !important;
    margin-right: 2px;
    height: 40px !important; }
    .picing-request-nav .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
      height: 40px !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .picing-request-nav .drop .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .picing-request-nav .drop p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #898989 !important; }
  .picing-request-nav .drop .ant-select-selection-selected-value {
    height: 100% !important;
    line-height: 32px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: 13px;
    color: #1268fb; }
    .picing-request-nav .drop .ant-select-selection-selected-value .anticon svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px; }
  .picing-request-nav .drop .ant-select-selection {
    width: 159px;
    height: 40px !important;
    border: none !important;
    background: #f8f8f8 !important; }
    .picing-request-nav .drop .ant-select-selection .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .picing-request-nav .drop .ant-select-selection .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .picing-request-nav .drop .ant-select-arrow {
    top: 53%;
    left: 159% !important; }

@media screen and (min-width: 1440px) {
  .request-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .request-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.Escalate_NDR * {
  letter-spacing: 0.032em; }

.Escalate_NDR .ant-modal-content {
  padding: 24px 32px; }
  .Escalate_NDR .ant-modal-content .ant-modal-title {
    padding-bottom: 8px; }
  .Escalate_NDR .ant-modal-content .ant-modal-header {
    padding: 0; }
  .Escalate_NDR .ant-modal-content .ant-modal-body {
    padding: 0; }
    .Escalate_NDR .ant-modal-content .ant-modal-body .ndr-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      margin-bottom: 12px; }
      .Escalate_NDR .ant-modal-content .ant-modal-body .ndr-details .details {
        width: 200px; }
        .Escalate_NDR .ant-modal-content .ant-modal-body .ndr-details .details p {
          margin-bottom: 4px;
          font-size: 12px;
          color: #898989; }
        .Escalate_NDR .ant-modal-content .ant-modal-body .ndr-details .details .id {
          height: 32px;
          background: #f5f5f5;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding-left: 16px;
          font-weight: 500;
          font-size: 12px;
          color: #1370fc; }
    .Escalate_NDR .ant-modal-content .ant-modal-body .remarks {
      color: #898989;
      font-size: 12px;
      margin-bottom: 4px; }
    .Escalate_NDR .ant-modal-content .ant-modal-body textarea {
      resize: none;
      height: 100px;
      padding: 8px 16px;
      border: none;
      background-color: #f5f5f5; }
    .Escalate_NDR .ant-modal-content .ant-modal-body ::placeholder {
      color: #898989;
      font-size: 12px; }
    .Escalate_NDR .ant-modal-content .ant-modal-body .upload-text {
      margin-top: 12px;
      margin-bottom: 4px;
      font-size: 12px;
      color: #898989; }
    .Escalate_NDR .ant-modal-content .ant-modal-body .ant-upload {
      padding: 0px !important;
      padding-left: 8px !important;
      border: none;
      background-color: #f5f5f5;
      border-radius: 2px; }
      .Escalate_NDR .ant-modal-content .ant-modal-body .ant-upload .ant-upload-drag-container {
        display: flex;
        align-items: center;
        height: 32px; }
        .Escalate_NDR .ant-modal-content .ant-modal-body .ant-upload .ant-upload-drag-container .ant-upload-text {
          margin: 0px;
          font-size: 12px;
          color: #898989; }
      .Escalate_NDR .ant-modal-content .ant-modal-body .ant-upload .ant-upload-drag-icon {
        margin-bottom: 6px;
        margin-right: 8px; }
        .Escalate_NDR .ant-modal-content .ant-modal-body .ant-upload .ant-upload-drag-icon svg {
          color: #898989;
          font-size: 16px; }
  .Escalate_NDR .ant-modal-content .ant-modal-footer {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15px; }
    .Escalate_NDR .ant-modal-content .ant-modal-footer .cancel {
      width: 176px;
      height: 40px;
      border-radius: 1px;
      border: 1px solid #898989; }
    .Escalate_NDR .ant-modal-content .ant-modal-footer .cancel:hover {
      width: 176px;
      height: 40px;
      color: #1370fc;
      border-radius: 1px;
      border: 1px solid #1370fc; }
    .Escalate_NDR .ant-modal-content .ant-modal-footer .ant-btn-primary {
      width: 176px;
      height: 40px;
      border-radius: 1px; }
    .Escalate_NDR .ant-modal-content .ant-modal-footer .success_btn {
      border: 1px solid #2bc823;
      background-color: #2bc823; }

.Escalate_NDR .ant-input {
  border-radius: 2px !important; }

.Escalate_NDR .re-Attemptcheckbox {
  margin-top: 32px; }
  .Escalate_NDR .re-Attemptcheckbox span {
    font-size: 12px;
    color: #898989; }

.Escalate_NDR .ndr-reattamp_modal {
  border: none; }
  .Escalate_NDR .ndr-reattamp_modal input {
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5; }
  .Escalate_NDR .ndr-reattamp_modal .ant-input-number {
    border: none; }
    .Escalate_NDR .ndr-reattamp_modal .ant-input-number input {
      border: 1px solid #f5f5f5;
      height: 32px; }
    .Escalate_NDR .ndr-reattamp_modal .ant-input-number input:focus {
      background-color: #fff;
      border: 1px solid #212121; }
  .Escalate_NDR .ndr-reattamp_modal .ant-input-number:focus {
    outline: none;
    border: none;
    box-shadow: none; }

.Escalate_NDR .btn_disabled {
  color: #fff;
  background-color: #2bc82371;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  pointer-events: none;
  border: none; }

.Escalate_NDR .remarks span {
  color: red; }

.Escalate_NDR input:focus {
  background-color: #fff;
  border: 1px solid #212121; }

.Escalate_NDR textarea:focus {
  background-color: #ffffff !important;
  border: 1px solid #212121 !important; }

.ndr-reattamp_modal {
  margin-top: 8px;
  border: 1px solid #898989;
  padding: 8px; }
  .ndr-reattamp_modal .upper_data {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px; }
    .ndr-reattamp_modal .upper_data .ant-form-item-control {
      width: 200px; }
  .ndr-reattamp_modal .middle_data {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px; }
    .ndr-reattamp_modal .middle_data .ant-form-item-control {
      width: 200px; }
  .ndr-reattamp_modal .bottom_data {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px; }
  .ndr-reattamp_modal .btn-save-customer {
    display: flex;
    justify-self: end; }
  .ndr-reattamp_modal .input-label {
    font-weight: 700;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    color: #000; }
  .ndr-reattamp_modal input,
  .ndr-reattamp_modal textarea {
    border: 1.5px solid #979797;
    border-radius: 2px;
    font-size: 12px; }
  .ndr-reattamp_modal .ant-form-item-control {
    position: relative;
    line-height: 32px;
    zoom: 1;
    font-size: 12px;
    margin-right: 8px; }

.bucket-filter-wraper {
  display: flex;
  grid-gap: 89px;
  gap: 89px;
  margin-bottom: 12px;
  position: relative; }
  .bucket-filter-wraper * {
    letter-spacing: 0.032em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer !important; }
  .bucket-filter-wraper .ant-select-selection-item {
    display: none !important; }
  .bucket-filter-wraper .custom-text {
    color: "#6E6E6E";
    font-size: 12px;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    pointer-events: none; }
    .bucket-filter-wraper .custom-text span {
      color: #1268fb;
      font-weight: 500;
      margin-left: 4px; }
  .bucket-filter-wraper .custom-arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotateX(0);
    right: 12px;
    transition: all 0.3s ease 0s; }
  .bucket-filter-wraper .custom-arrow-icon.open {
    transition: all 0.35s ease 0s;
    transform: translateY(-50%) rotate(180deg); }
    .bucket-filter-wraper .custom-arrow-icon.open g {
      fill: #898989; }
  .bucket-filter-wraper .ant-select-selection__choice__content {
    max-width: 30px; }
  .bucket-filter-wraper .ant-select.ant-select-enabled {
    height: 40px; }
  .bucket-filter-wraper .ant-select-selection--multiple {
    height: 40px;
    border: none !important;
    box-shadow: 0px 2px 8px #00000014;
    background-color: #f8f8f8;
    border-radius: 2px; }
    .bucket-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered {
      height: 40px;
      margin-bottom: 0px;
      padding-left: 90px; }
      .bucket-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice {
        max-width: 100px;
        position: relative;
        margin-top: 8px;
        color: #1268fb;
        font-weight: 500;
        font-size: 12px;
        background: none;
        border: 1px solid transparent;
        z-index: 10;
        margin-right: 2px;
        padding: 0 2px !important; }
        .bucket-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-selection__choice span {
          display: none; }
      .bucket-filter-wraper .ant-select-selection--multiple .ant-select-selection__rendered .ant-select-search__field {
        margin-top: 3.4px;
        font-size: 12px; }
  .bucket-filter-wraper .ant-select-search.ant-select-search--inline {
    width: 50px;
    margin-top: 5px;
    color: #1268fb; }
  .bucket-filter-wraper .ant-checkbox.ant-checkbox-checked {
    display: none; }

.bucket-filter-overlay * {
  letter-spacing: 0.032em; }

.bucket-filter-overlay .ant-select-dropdown-menu-item-disabled i {
  display: none; }

.bucket-filter-overlay .ant-select-dropdown-menu-item {
  color: #898989;
  min-height: 40px;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #cecece;
  padding-left: 40px; }
  .bucket-filter-overlay .ant-select-dropdown-menu-item i {
    position: absolute;
    right: 280px !important;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid #e3e3e3;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .bucket-filter-overlay .ant-select-dropdown-menu-item i svg {
      font-size: 12px; }

.bucket-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
  color: #212121;
  font-weight: 500; }
  .bucket-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i {
    background-color: #2bc822;
    border-color: #2bc822 !important;
    display: flex !important;
    align-items: center;
    justify-content: center; }
    .bucket-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected i svg {
      color: #fff;
      stroke: 2px; }

.bucket-filter-overlay .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled i {
  display: none; }

.bucket-filter-overlay .ant-select-dropdown-menu-item:hover {
  background-color: #f5faff;
  border-bottom: 1px solid #b5dafe; }
  .bucket-filter-overlay .ant-select-dropdown-menu-item:hover svg {
    color: #898989; }

.bucket-filter-wraper.custom-padding .ant-select-search.ant-select-search--inline {
  width: 30px;
  margin-top: 5px;
  color: #1268fb; }

.bucket-filter-wraper.custom-padding .ant-select-selection__rendered {
  height: 40px;
  margin-bottom: 0px;
  padding-left: 130px !important; }
  .bucket-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-selection__choice {
    max-width: 100px;
    position: relative;
    margin-top: 8px;
    color: #1268fb;
    font-weight: 500;
    font-size: 12px;
    background: none;
    border: 1px solid transparent;
    z-index: 10;
    margin-right: 2px;
    padding: 0 2px !important; }
    .bucket-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-selection__choice span {
      display: none; }
  .bucket-filter-wraper.custom-padding .ant-select-selection__rendered li:nth-last-child(2) {
    position: relative; }
    .bucket-filter-wraper.custom-padding .ant-select-selection__rendered li:nth-last-child(2) ::after {
      content: "";
      position: absolute;
      right: -1px;
      top: 1px; }
  .bucket-filter-wraper.custom-padding .ant-select-selection__rendered .ant-select-search__field {
    margin-top: 3.4px;
    font-size: 12px; }

.bucket-filter-wraper.custom-padding .ant-select-selection__choice {
  position: relative; }
  .bucket-filter-wraper.custom-padding .ant-select-selection__choice ::after {
    content: ",";
    position: absolute;
    right: -1px;
    top: 1px; }

.bucket-filter-wraper.custom-padding .ant-select-selection__choice:last-of-type {
  position: relative;
  display: none !important; }
  .bucket-filter-wraper.custom-padding .ant-select-selection__choice:last-of-type ::after {
    content: "--";
    position: absolute;
    right: -1px;
    top: 1px; }

.overlay-footer {
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .overlay-footer button {
    height: 26px !important;
    padding: 2px 8px !important; }
  .overlay-footer span {
    font-size: 12px; }

.download-utility-table {
  margin-bottom: 20px;
  letter-spacing: 0.032em !important; }
  .download-utility-table .ant-table-thead tr {
    background-color: #16273b; }
    .download-utility-table .ant-table-thead tr th {
      padding-top: 12px !important;
      padding-bottom: 11px !important;
      background-color: #16273b !important; }
    .download-utility-table .ant-table-thead tr th:first-of-type {
      padding-left: 24px !important; }
    .download-utility-table .ant-table-thead tr .ant-table-column-title {
      font-size: 12px;
      color: #fff; }
    .download-utility-table .ant-table-thead tr th:last-of-type {
      padding-left: 32px; }
  .download-utility-table .ant-table-tbody {
    box-shadow: 0px 0px 4px #00000020; }
    .download-utility-table .ant-table-tbody tr {
      background-color: #fff; }
      .download-utility-table .ant-table-tbody tr td:first-of-type {
        padding-left: 24px; }
      .download-utility-table .ant-table-tbody tr td {
        border-color: #cecece;
        font-size: 12px;
        color: #212121;
        padding: 19px 16px !important; }
        .download-utility-table .ant-table-tbody tr td span {
          font-size: 12px;
          color: #212121; }
    .download-utility-table .ant-table-tbody tr:last-of-type td {
      border-bottom: 0px solid #000; }
    .download-utility-table .ant-table-tbody .status {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
      .download-utility-table .ant-table-tbody .status i {
        margin-right: 4px; }
    .download-utility-table .ant-table-tbody .download-link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #1370fc;
      font-weight: 500; }
      .download-utility-table .ant-table-tbody .download-link i {
        margin-right: 8px; }
    .download-utility-table .ant-table-tbody .download-link-disabled {
      cursor: not-allowed;
      opacity: 0.4; }

.msg {
  letter-spacing: 0.032em;
  color: #898989;
  margin-bottom: 12px;
  font-size: 12px; }

.download-utility-wraper .download-utility-nav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .download-utility-wraper .download-utility-nav .ordfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .download-utility-wraper .download-utility-nav .ordfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .download-utility-wraper .download-utility-nav .ordfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .download-utility-wraper .download-utility-nav .ordfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .download-utility-wraper .download-utility-nav .ordfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .download-utility-wraper .download-utility-nav .ordfillwrap .right .filter svg {
        font-size: 20px; }
      .download-utility-wraper .download-utility-nav .ordfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .download-utility-wraper .download-utility-nav .ordfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .download-utility-wraper .download-utility-nav .ordfillwrap .right .show-data-from {
        font-size: 12px;
        margin-left: 8px; }
      .download-utility-wraper .download-utility-nav .ordfillwrap .right .dispute-status {
        font-size: 12px; }
  .download-utility-wraper .download-utility-nav .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .download-utility-wraper .download-utility-nav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .download-utility-wraper .download-utility-nav .secondwrap .filtertab .tab {
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .download-utility-wraper .download-utility-nav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .download-utility-wraper .download-utility-nav .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .download-utility-wraper .download-utility-nav .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .download-utility-wraper .download-utility-nav .secondwrap .filtertab .backgroundcircle {
        position: relative;
        background: #1268fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1268fb; }
      .download-utility-wraper .download-utility-nav .secondwrap .filtertab .tabunderline {
        border-bottom: 2px solid #1268fb; }
      .download-utility-wraper .download-utility-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation {
        position: relative; }
      .download-utility-wraper .download-utility-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .download-utility-wraper .download-utility-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation:hover {
        color: #4398ed !important; }
        .download-utility-wraper .download-utility-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .download-utility-wraper .download-utility-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .download-utility-wraper .download-utility-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation-click {
        position: relative; }
      .download-utility-wraper .download-utility-nav .secondwrap .filtertab .hover-ndrEscalations-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .download-utility-wraper .download-utility-nav .secondwrap .download-csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .download-utility-wraper .download-utility-nav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .download-utility-wraper .download-utility-nav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .download-utility-wraper .download-utility-nav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .download-utility-wraper .download-utility-nav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .download-utility-wraper .download-utility-nav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .download-utility-wraper .download-utility-nav .ant-calendar-picker-clear,
  .download-utility-wraper .download-utility-nav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.download-utility-wraper .bottom-data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 90px; }

.download-utility-wraper .ant-select-selection__placeholder {
  color: #898989; }

.download-utility-wraper .left {
  margin-left: "7px";
  color: #000; }
  .download-utility-wraper .left .content-page-title {
    display: block;
    font-size: 12px;
    margin-bottom: 12px;
    font-weight: 400; }
    .download-utility-wraper .left .content-page-title .escalations-txt {
      text-decoration: underline; }

.download-utility-wraper .range-picker {
  margin-left: 10px;
  max-width: 300px; }

.download-utility-wraper .drop {
  background-color: #f8f8f8 !important;
  padding: 0px;
  box-shadow: 0px 2px 8px #00000014;
  letter-spacing: 0 !important; }
  .download-utility-wraper .drop > div {
    height: 40px !important; }
  .download-utility-wraper .drop .ant-select.ant-select-enabled {
    height: 40px !important; }
  .download-utility-wraper .drop .ant-select-selection__rendered {
    margin-left: 16px;
    background-color: #f8f8f8 !important;
    margin-right: 2px;
    height: 40px !important; }
    .download-utility-wraper .drop .ant-select-selection__rendered .ant-select-selection-selected-value {
      height: 40px !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .download-utility-wraper .drop .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .download-utility-wraper .drop p {
    font-size: 12px !important;
    line-height: 16px !important;
    color: #898989 !important; }
  .download-utility-wraper .drop .ant-select-selection-selected-value {
    height: 100% !important;
    line-height: 32px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: 13px;
    color: #1268fb; }
    .download-utility-wraper .drop .ant-select-selection-selected-value .anticon svg {
      width: 16px !important;
      height: 16px !important;
      margin-right: 8px; }
  .download-utility-wraper .drop .ant-select-selection {
    width: 159px;
    height: 40px !important;
    border: none !important;
    background: #f8f8f8 !important; }
    .download-utility-wraper .drop .ant-select-selection .ant-select-selection-selected-value {
      height: 100% !important;
      line-height: 32px !important;
      display: flex !important;
      justify-content: center;
      align-items: center;
      padding-right: 13px;
      color: #1268fb; }
      .download-utility-wraper .drop .ant-select-selection .ant-select-selection-selected-value .anticon svg {
        width: 16px !important;
        height: 16px !important;
        margin-right: 8px; }
  .download-utility-wraper .drop .ant-select-arrow {
    top: 53%;
    left: 159% !important; }

@media screen and (min-width: 1440px) {
  .download-utility-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .download-utility-nav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.shipment_request_data .shipmentrequestnav {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 32px; }
    .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap .left .underline {
      text-decoration: underline; }
    .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap .right .filter svg {
        font-size: 20px; }
      .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap .right .show_data_from {
        font-size: 12px; }
      .shipment_request_data .shipmentrequestnav .shipmentrequestfillwrap .right .dispute-status {
        font-size: 12px; }
  .shipment_request_data .shipmentrequestnav .secondwrap {
    display: flex;
    align-items: center;
    height: 42px;
    justify-content: space-between; }
    .shipment_request_data .shipmentrequestnav .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .tab {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .tab button {
          display: contents;
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .circle {
        margin-left: 8px !important;
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .backgroundcircle {
        margin-left: 8px !important;
        position: relative;
        background: #1161fb;
        font-size: 12px;
        padding: 2px 10px;
        color: #fff;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #1161fb; }
      .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .hover-inventory-underline-animation {
        position: relative; }
      .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .hover-inventory-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 0px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .hover-inventory-underline-animation:hover {
        color: #4398ed !important; }
        .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .hover-inventory-underline-animation:hover .circle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .hover-inventory-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .hover-inventory-underline-animation-click {
        position: relative; }
      .shipment_request_data .shipmentrequestnav .secondwrap .filtertab .hover-inventory-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 0px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .shipment_request_data .shipmentrequestnav .secondwrap .download_csv {
      margin-right: 100px;
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px; }
  .shipment_request_data .shipmentrequestnav .shipment-request-datepicker {
    margin-right: 10px; }
    .shipment_request_data .shipmentrequestnav .shipment-request-datepicker .ant-calendar-picker-input {
      width: 88%; }
      .shipment_request_data .shipmentrequestnav .shipment-request-datepicker .ant-calendar-picker-input .anticon {
        right: 62px; }
  .shipment_request_data .shipmentrequestnav .shipment-request-datepicker:focus .ant-calendar-picker-input {
    box-shadow: none; }
  .shipment_request_data .shipmentrequestnav .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .shipment_request_data .shipmentrequestnav .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .shipment_request_data .shipmentrequestnav .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .shipment_request_data .shipmentrequestnav .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .shipment_request_data .shipmentrequestnav .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .shipment_request_data .shipmentrequestnav .ant-calendar-picker-clear,
  .shipment_request_data .shipmentrequestnav .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }

.shipment_request_data .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 90px; }
  .shipment_request_data .bottom_data .shipment_request_dropdown {
    background-color: #f8f8f8 !important;
    box-shadow: 0px 2px 8px #00000014;
    letter-spacing: 0 !important;
    display: flex;
    float: left;
    align-items: center;
    margin-left: -40px; }
    .shipment_request_data .bottom_data .shipment_request_dropdown .ant-select-selection__rendered {
      margin-left: 16px;
      background-color: #f8f8f8 !important;
      margin-right: 2px;
      height: 100% !important; }
      .shipment_request_data .bottom_data .shipment_request_dropdown .ant-select-selection__rendered .ant-select-selection-selected-value {
        height: 100% !important;
        line-height: 32px !important;
        display: flex !important;
        padding-right: 13px;
        font-size: 12px;
        color: #1268fb;
        letter-spacing: 0.032em;
        width: 100%; }
        .shipment_request_data .bottom_data .shipment_request_dropdown .ant-select-selection__rendered .ant-select-selection-selected-value .anticon svg {
          width: 16px !important;
          height: 16px !important;
          margin-right: 8px; }
    .shipment_request_data .bottom_data .shipment_request_dropdown .shipment_request_servicebale-select .ant-select-selection {
      width: 288px !important; }
    .shipment_request_data .bottom_data .shipment_request_dropdown .ant-select-selection {
      padding: 0 !important;
      background: none;
      border: none;
      margin-right: 0; }
    .shipment_request_data .bottom_data .shipment_request_dropdown .ant-select-selection__placeholder {
      color: #898989; }

.right_data_request {
  padding-right: 104px;
  display: flex; }
  .right_data_request .add_new_request {
    margin-right: 8px; }

.requestnav_shipment_commondropdown {
  border-bottom: 1px solid #cecece !important;
  padding: 8px 16px !important;
  background: #f8f8f8 !important;
  color: #898989;
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.032em;
  line-height: 32px; }
  .requestnav_shipment_commondropdown .anticon svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    padding-top: 2px; }

.requestnav_shipment_commondropdown:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: #e6f4ff !important;
  border-bottom: 2px solid #b5dafe !important;
  border-radius: 0px !important; }

@media screen and (min-width: 1440px) {
  .shipmentrequestnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 242px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .shipmentrequestnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.dispute-card {
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 20px; }
  .dispute-card .upper-section {
    display: flex;
    width: 97%;
    justify-content: space-between;
    margin-top: 24px; }
    .dispute-card .upper-section .vf {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px; }
      .dispute-card .upper-section .vf p {
        padding: 4px 10px;
        font-size: 12px;
        font-weight: 500; }
  .dispute-card .vforderid_tag {
    padding: 5px;
    padding-left: 15px;
    position: absolute;
    left: -10px;
    top: 8px;
    background: #ed7741;
    color: white;
    border-radius: 2px;
    border-bottom-left-radius: 0; }
  .dispute-card .vforderid_tag:before {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: 100%;
    left: 0px;
    border-bottom: 9px solid #ed7741;
    border-left: 9px solid transparent;
    transform: rotate(270deg); }
  .dispute-card .ant-card-body {
    padding: 0px; }
    .dispute-card .ant-card-body .blue-text {
      color: #0068ff; }
    .dispute-card .ant-card-body .header-row {
      display: flex;
      align-items: center;
      padding: 46px 52px 20px 52px; }
      .dispute-card .ant-card-body .header-row h3 {
        margin-bottom: 0px;
        font-size: 16px;
        font-weight: 400; }
      .dispute-card .ant-card-body .header-row .dispute-title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        min-width: 66px; }
        .dispute-card .ant-card-body .header-row .dispute-title .anticon {
          font-size: 17px;
          margin-right: 10px; }
      .dispute-card .ant-card-body .header-row .dispute-status {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 36px; }
    .dispute-card .ant-card-body .circle-ican {
      font-size: 10px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: "white";
      padding: 4px 8px;
      padding-right: 5px; }
    .dispute-card .ant-card-body .circle-ican-status {
      font-size: 12px;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 4px 10px;
      padding-left: 0;
      font-weight: 500; }
    .dispute-card .ant-card-body .tabs-row {
      background: #16273b;
      padding: 0 24px;
      display: flex;
      align-items: center; }
      .dispute-card .ant-card-body .tabs-row ul {
        list-style-type: none;
        margin: 0;
        padding: 0; }
        .dispute-card .ant-card-body .tabs-row ul li {
          display: inline-block;
          padding: 12px 15px;
          color: white;
          cursor: pointer;
          line-height: 20px;
          letter-spacing: 0.032em; }
        .dispute-card .ant-card-body .tabs-row ul li.active,
        .dispute-card .ant-card-body .tabs-row ul li:hover {
          background: #244060; }
    .dispute-card .ant-card-body .tab-container {
      background: #f2f2f2;
      padding: 0 24px 24px 24px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px; }
      .dispute-card .ant-card-body .tab-container .dispute-info-tab {
        background: white;
        padding: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px; }
        .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-info {
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px; }
          .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-info p {
            font-size: 14px;
            font-weight: 500;
            color: black;
            margin-bottom: 0px; }
            .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-info p span {
              color: #5a5a5a;
              font-weight: 400; }
        .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-reason {
          width: 50%;
          margin-right: 8px; }
          .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-reason h6 {
            font-size: 12px;
            line-height: 16px;
            color: #898989;
            margin-bottom: 4px;
            font-weight: 400; }
          .dispute-card .ant-card-body .tab-container .dispute-info-tab .dispute-reason p {
            font-size: 12px;
            padding: 8px 16px;
            background: #f5f5f5;
            color: #252525; }
      .dispute-card .ant-card-body .tab-container .line-items-tab {
        padding: 16px 13px 7px 13px;
        background: #fff; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .line-item {
          background: #f2f2f2;
          margin-bottom: 10px;
          padding: 14px 12px;
          display: flex;
          align-items: center;
          border: 1px solid transparent; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .anticon {
          font-size: 17px; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-product-detail {
          display: flex;
          align-items: center; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-img {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          display: inline-block;
          background-size: cover !important;
          background-position: center !important;
          border: 1px solid #c8c8c8; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
          font-size: 12px; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-sku {
          font-size: 12px;
          color: #676666;
          font-weight: 400;
          margin: 0;
          margin-top: -2px; }
          .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-sku b {
            color: #898989;
            font-weight: 400; }
          .dispute-card .ant-card-body .tab-container .line-items-tab .line-item-sku .detailcommon {
            color: #000;
            letter-spacing: 0.032em;
            font-size: 12px;
            line-height: 16px; }
        .dispute-card .ant-card-body .tab-container .line-items-tab .delivered-date {
          margin-bottom: 0px;
          text-decoration: none; }
      .dispute-card .ant-card-body .tab-container .media-attachments {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px; }
        .dispute-card .ant-card-body .tab-container .media-attachments .image-div {
          margin-top: 10px;
          margin-right: 10px;
          border: 1px solid #898989; }
          .dispute-card .ant-card-body .tab-container .media-attachments .image-div img {
            width: 100px;
            height: 100px;
            cursor: pointer;
            padding: 3px; }
        .dispute-card .ant-card-body .tab-container .media-attachments .download-image-data {
          position: absolute;
          bottom: 25px;
          padding: 6px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 93px; }
  .dispute-card .flex {
    display: flex;
    justify-content: space-between; }
    .dispute-card .flex .flex-note-log {
      width: 44%;
      color: #000000a6; }
    .dispute-card .flex .flex-date {
      width: 30%;
      display: flex;
      align-items: center; }
      .dispute-card .flex .flex-date .circle {
        margin-right: 8px; }
      .dispute-card .flex .flex-date .font-weight {
        font-weight: 600;
        color: #212121; }
      .dispute-card .flex .flex-date span {
        color: #212121; }
      .dispute-card .flex .flex-date .line {
        margin-left: 8px;
        margin-right: 8px; }
    .dispute-card .flex .flex-addedby {
      width: 13%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #000000a6; }
  .dispute-card .fields {
    display: flex;
    width: 94%;
    margin: auto;
    margin-top: 24px;
    margin-bottom: 24px; }
    .dispute-card .fields .field {
      flex: 1 1;
      margin-right: 24px; }
      .dispute-card .fields .field .field-label {
        font-size: 12px;
        line-height: 16px;
        color: #898989;
        margin-bottom: 4px; }
      .dispute-card .fields .field .field-value {
        font-size: 12px;
        padding: 8px 16px;
        background: #f5f5f5;
        color: black;
        display: flex;
        justify-content: flex-start;
        align-items: center; }
        .dispute-card .fields .field .field-value svg {
          margin-right: 12px; }
    .dispute-card .fields .field:last-of-type {
      margin-right: 0; }
  .dispute-card .flex-5 {
    flex: 4 1; }
  .dispute-card .flex-6 {
    flex: 3 1; }
  .dispute-card .flex-7 {
    flex: 2 1;
    font-size: 12px; }
  .dispute-card .flex-8 {
    flex: 1 1; }
  .dispute-card .note-logs {
    padding: 8px 16px;
    background: #f5f5f5;
    margin-bottom: 8px; }
  .dispute-card .notes-logs-txt {
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989; }
  .dispute-card .notes-logs-data {
    background: white;
    padding: 12px 16px;
    font-size: 12px; }
    .dispute-card .notes-logs-data .note {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      color: #212121;
      font-style: italic;
      font-size: 12px; }
  .dispute-card .tab-count {
    display: inline-block;
    background: #ff9400;
    width: 20px;
    height: 20px;
    border-radius: 16px;
    margin-left: 12px;
    text-align: center;
    font-size: 12px;
    line-height: 20px; }
  .dispute-card .upper_data {
    display: flex;
    justify-content: space-between; }
  .dispute-card .no-found {
    padding: 16px;
    text-align: center;
    background: white;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 20px;
    color: "#000";
    font-weight: 500; }
  .dispute-card .video-data {
    width: 100px;
    height: 100px;
    cursor: pointer; }
  .dispute-card .shipment-status {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px; }
  .dispute-card .returned-on {
    margin-left: -16px; }
  .dispute-card .delivered-on {
    margin-left: -16px; }
  .dispute-card .delivered {
    margin-left: -38px; }
  .dispute-card .returned {
    margin-left: -38px; }
  .dispute-card .shipment-status {
    margin-left: 24px; }
  .dispute-card .header-varaint-status {
    margin-bottom: 5px;
    padding: 0 16px;
    color: #898989;
    font-size: 12px; }
  .dispute-card .delivered-on-date {
    color: #73d13d;
    margin-bottom: 0;
    font-size: 12px;
    text-decoration: underline;
    display: flex;
    justify-content: left; }
  .dispute-card .row-ul li {
    font-size: 12px; }
  .dispute-card .escalation-status-wraper {
    background-color: #f7f7f7;
    height: 112px;
    margin-bottom: 12px;
    display: flex;
    align-items: center; }
    .dispute-card .escalation-status-wraper .progress-line {
      position: relative;
      margin: 0 auto;
      bottom: 20px;
      border-radius: 10px;
      width: 60%;
      background: #cecece;
      height: 8px;
      z-index: 90;
      display: flex;
      justify-content: space-between; }
      .dispute-card .escalation-status-wraper .progress-line .progress {
        transition: all 1s linear;
        position: absolute;
        width: 0;
        height: 100%;
        background: #2bc822; }
    .dispute-card .escalation-status-wraper .status {
      position: relative;
      top: -4px;
      left: -1px; }
      .dispute-card .escalation-status-wraper .status .dot {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #cecece;
        cursor: pointer; }
        .dispute-card .escalation-status-wraper .status .dot .current {
          background: white;
          border: 5px solid orange; }
        .dispute-card .escalation-status-wraper .status .dot.completed {
          position: relative;
          background: #2bc822;
          border: 5px solid #2bc822; }
      .dispute-card .escalation-status-wraper .status .dot.dot-active {
        outline: 4px solid #b8f0b3;
        animation: pulse-black 2s infinite; }
      .dispute-card .escalation-status-wraper .status .time-and-status-wraper {
        position: relative;
        display: flex;
        min-width: 75px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        right: 28px; }
        .dispute-card .escalation-status-wraper .status .time-and-status-wraper .date {
          margin-top: 2px;
          color: #212121; }
      .dispute-card .escalation-status-wraper .status .shipment_step_color {
        color: #2bc822;
        font-weight: 500;
        font-size: 12px;
        margin-top: 7px; }
      .dispute-card .escalation-status-wraper .status .original_color {
        color: #cecece;
        font-size: 12px;
        margin-top: 7px; }
    .dispute-card .escalation-status-wraper .status:nth-of-type(4) .time-and-status-wraper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      right: -30px; }
  .dispute-card .logs-wraper {
    max-height: 160px;
    overflow-y: scroll; }
    .dispute-card .logs-wraper .status-logs {
      height: 48px !important;
      padding: 0px 16px !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f5fff4; }
      .dispute-card .logs-wraper .status-logs .flex-date .circle {
        height: 16px;
        width: 16px;
        background-color: #212121;
        border-radius: 50%;
        position: relative; }
      .dispute-card .logs-wraper .status-logs .flex-date .circle.single-circle::after {
        content: "";
        height: 0;
        width: 0; }
      .dispute-card .logs-wraper .status-logs .flex-date .circle::after {
        content: "";
        height: 40px;
        width: 2px;
        background-color: #212121;
        position: absolute;
        top: 16px;
        left: 50%;
        transform: translateX(-50%); }
    .dispute-card .logs-wraper .status-logs:last-of-type .circle::after {
      content: "";
      height: 0px;
      width: 0px; }
  .dispute-card .logs-wraper::-webkit-scrollbar {
    width: 8px;
    border: 1px solid #898989; }
  .dispute-card .logs-wraper::-webkit-scrollbar-thumb {
    background-color: #898989; }
  .dispute-card .single-log::-webkit-scrollbar {
    width: 8px;
    border: 0px solid #ffffff; }
  .dispute-card .single-log::-webkit-scrollbar-thumb {
    background-color: #ffffff; }

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 #2ac82260; }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); } }

@keyframes load-25 {
  0% {
    width: 0; }
  100% {
    width: 20%; } }

@keyframes load-50 {
  0% {
    width: 0; }
  100% {
    width: 54%; } }

@keyframes load-100 {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.add-dispute-modal {
  width: 672px !important; }
  .add-dispute-modal .ant-select-dropdown-menu-item-selected {
    color: red; }
  .add-dispute-modal .ant-modal-content {
    border-radius: 2px !important; }
  .add-dispute-modal .ant-modal-header {
    width: 90%;
    margin: auto;
    padding-left: 0;
    padding-bottom: 8px;
    padding-top: 30px; }
  .add-dispute-modal .ant-modal-body {
    padding: 0px 24px 6px 24px; }
    .add-dispute-modal .ant-modal-body .dispute-form {
      margin-top: 7px;
      width: 97%;
      margin: auto; }
      .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason {
        margin-top: 14px; }
        .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason p {
          font-size: 12px;
          color: #898989;
          margin-bottom: 5px; }
        .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-select {
          width: 100% !important; }
          .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-select .ant-select-selection {
            border-radius: 2px;
            font-size: 12px;
            border: none;
            background: #eaeaea;
            box-shadow: none; }
            .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-select .ant-select-selection .ant-select-selection__placeholder,
            .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-select .ant-select-selection .ant-select-search__field__placeholder {
              color: #acacac;
              font-size: 12px; }
        .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-input {
          border-radius: 2px;
          font-size: 12px;
          border: none;
          background: #eaeaea;
          color: #5a5a5a; }
          .add-dispute-modal .ant-modal-body .dispute-form .dispute-reason .ant-input ::placeholder {
            font-size: 12px; }
  .add-dispute-modal .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 32px; }
    .add-dispute-modal .ant-modal-footer button {
      font-size: 12px;
      height: 40px;
      width: 176px;
      border-radius: 2px; }

.op {
  border-bottom: 1px solid #cecece;
  color: #898989;
  font-size: 12px;
  font-weight: 400; }

.op[aria-selected="true"] {
  color: #212121;
  font-weight: 500;
  border-bottom: 1px solid #6fc1ff; }

.shipment_request_review-line-item .ant-modal-content {
  padding: 8px 0px;
  border-radius: 0px !important; }
  .shipment_request_review-line-item .ant-modal-content .ant-modal-header {
    padding: 16px 8px;
    width: 856px;
    margin: auto; }
  .shipment_request_review-line-item .ant-modal-content .rvp-field {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .shipment_request_review-line-item .ant-modal-content .rvp-field .rvp-right {
      font-size: 14px;
      font-weight: 500; }
  .shipment_request_review-line-item .ant-modal-content .flex-wraper {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
    .shipment_request_review-line-item .ant-modal-content .flex-wraper > div {
      margin-right: 3px; }
  .shipment_request_review-line-item .ant-modal-content .ant-modal-footer {
    padding: 16px 0px;
    text-align: left !important;
    background: transparent;
    border-top: 1px solid #e8e8e8;
    border-radius: 0 0 4px 4px;
    width: 856px;
    margin: auto; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail {
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 16px; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .title_text {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #212121;
      margin-bottom: 12px;
      font-weight: 500; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .replacement_text {
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #898989;
      margin-bottom: 16px; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .note_txt {
      font-size: 14px;
      letter-spacing: 0.032em;
      line-height: 16px;
      color: #000;
      margin-bottom: 16px;
      font-weight: 500;
      font-style: italic;
      margin-right: 6px; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason {
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 16px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason .pickup_reason {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121;
        margin-bottom: 8px;
        font-weight: 500; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason .reverse_pickup_reason_data {
        font-size: 12px;
        width: 100%;
        margin-bottom: 16px; }
        .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason .reverse_pickup_reason_data .ant-select-selection {
          display: block;
          box-sizing: border-box;
          background-color: #f5f5f5;
          border: none;
          border-radius: 0;
          outline: none; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason .other-textarea textarea {
        width: 100%;
        border: none;
        outline: none;
        background-color: #f5f5f5;
        height: 100px;
        padding: 8px;
        font-size: 12px;
        letter-spacing: 0.032em;
        margin-bottom: 16px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .reversePickupReason .other_label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        margin-bottom: 4px; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 32px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .common_label {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        width: 208px;
        margin-bottom: 4px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .upeer_label_margin {
        margin-right: 6px;
        margin-bottom: 16px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .customer_detail_text {
        width: 208px;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .account_number_text {
        width: 208px;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .confirm_account_number_text {
        width: 208px;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .end_customer_detail_input .ifsc {
        width: 208px;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
    .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .amount_data {
      margin-top: -22px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .amount_data .amount_value {
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #898989;
        margin-bottom: 4px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .amount_data .end_customer_amount_input {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px; }
      .shipment_request_review-line-item .ant-modal-content .ant-modal-footer .amount_data .customer_amount_text {
        width: 208px;
        border-radius: 0px;
        background: #f5f5f5;
        border: none;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px;
        color: #212121; }
  .shipment_request_review-line-item .ant-modal-content .bottom_button {
    text-align: right; }
    .shipment_request_review-line-item .ant-modal-content .bottom_button .not_clickablebtn {
      width: 176px;
      background: #88b6fd;
      height: 40px;
      border: none;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px;
      pointer-events: none; }
    .shipment_request_review-line-item .ant-modal-content .bottom_button .clickable_btn {
      width: 176px;
      background: #1370fc;
      height: 40px;
      border: none;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
    .shipment_request_review-line-item .ant-modal-content .bottom_button .cancel_close_btn {
      width: 176px;
      background: #f5f5f5;
      height: 40px;
      border: none;
      color: #898989;
      font-weight: 500;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }

.shipment_request_review-line-item .order_vfid span {
  color: #898989; }

.shipment_request_review-line-item .modal-title {
  display: flex;
  justify-content: space-between; }
  .shipment_request_review-line-item .modal-title p {
    margin-bottom: 0px;
    font-size: 16px;
    letter-spacing: 0.032em;
    line-height: 22px; }

.shipment_request_review-line-item .blue-text {
  color: #1268fb; }

.shipment_request_review-line-item .modal-header {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px;
  line-height: 16px;
  letter-spacing: 0.032em;
  margin-top: 16px;
  font-size: 12px; }
  .shipment_request_review-line-item .modal-header p {
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin-bottom: 0px;
    letter-spacing: 0.032em;
    line-height: 16px; }

.shipment_request_review-line-item .line-items-tab {
  background: #fff;
  margin-bottom: -16px;
  padding: 8px; }
  .shipment_request_review-line-item .line-items-tab .line-item {
    background: #f2f2f2;
    margin-bottom: 10px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent; }
    .shipment_request_review-line-item .line-items-tab .line-item .ant-checkbox-wrapper {
      transform: scale(1.15); }
    .shipment_request_review-line-item .line-items-tab .line-item .ant-input {
      width: 80px; }
    .shipment_request_review-line-item .line-items-tab .line-item .ant-checkbox .ant-checkbox-inner {
      border: none;
      width: 14px;
      height: 14px; }
    .shipment_request_review-line-item .line-items-tab .line-item .quantity-field {
      max-width: 65px;
      margin-right: 5px; }
    .shipment_request_review-line-item .line-items-tab .line-item .anticon {
      font-size: 22px; }
    .shipment_request_review-line-item .line-items-tab .line-item .line-item-product-detail {
      display: flex;
      align-items: center;
      width: 61%;
      margin-left: 0px; }
      .shipment_request_review-line-item .line-items-tab .line-item .line-item-product-detail .mr {
        margin-right: 8px; }
    .shipment_request_review-line-item .line-items-tab .line-item .line-item-img {
      width: 56px;
      height: 56px;
      margin-right: 0px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important; }
    .shipment_request_review-line-item .line-items-tab .line-item .line-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 12px;
      margin-top: -12px; }
    .shipment_request_review-line-item .line-items-tab .line-item .line-item-sku {
      font-size: 12px;
      color: #000;
      font-weight: 400;
      margin: 0; }
  .shipment_request_review-line-item .line-items-tab .line-item.selected {
    background: #eeffe5;
    border: 1px solid #73d13d; }
  .shipment_request_review-line-item .line-items-tab .edit-quantity-btn {
    background: transparent;
    border: 1px solid #ff005e;
    border-radius: 4px;
    color: #ff005e; }
  .shipment_request_review-line-item .line-items-tab .edit-quantity-btn:hover {
    background: #ff005e;
    border: 1px solid #ff005e;
    box-shadow: 0 1px 2px rgba(255, 0, 94, 0.4);
    color: white; }

.shipment_request_review-line-item .shipments-tab {
  padding: 12px 13px;
  background: #fff; }
  .shipment_request_review-line-item .shipments-tab .no-shipment {
    text-align: center; }
  .shipment_request_review-line-item .shipments-tab .shipment-header {
    background: #f2f2f2;
    padding: 28px 20px;
    margin-bottom: 5px;
    color: black; }
  .shipment_request_review-line-item .shipments-tab .shipment-items {
    background: #eff5ff;
    padding: 5px 10px; }
  .shipment_request_review-line-item .shipments-tab .shipment-item {
    background: transparent;
    padding: 16px 8px;
    display: flex;
    align-items: center; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .ant-checkbox-wrapper {
      transform: scale(1.15); }
    .shipment_request_review-line-item .shipments-tab .shipment-item .ant-checkbox .ant-checkbox-inner {
      border: none; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .anticon {
      font-size: 22px; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .shipment-item-product-detail {
      display: flex;
      align-items: center; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .shipment-item-img {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      display: inline-block;
      background-size: cover !important;
      background-position: center !important; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .shipment-item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 15px; }
    .shipment_request_review-line-item .shipments-tab .shipment-item .shipment-item-sku {
      font-size: 14px;
      color: #676666;
      font-weight: 400;
      margin: 0;
      margin-top: -2px; }
  .shipment_request_review-line-item .shipments-tab .shipment-item:nth-of-type(2n) {
    background: white; }

.shipment_request_review-line-item .quantity {
  width: 20%; }

.shipment_request_review-line-item .action {
  width: 15%; }
  .shipment_request_review-line-item .action .action_button {
    background: transparent;
    border: none;
    box-shadow: none;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #fd115e;
    padding: 0; }

.shipment_request_review-line-item .variants_detail_header {
  margin-top: -8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 8px; }
  .shipment_request_review-line-item .variants_detail_header .varaint_text {
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin-bottom: 0px;
    letter-spacing: 0.032em;
    line-height: 16px;
    width: 55%; }
  .shipment_request_review-line-item .variants_detail_header .quantity_txt {
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin-bottom: 0px;
    letter-spacing: 0.032em;
    line-height: 16px;
    width: 20%; }
  .shipment_request_review-line-item .variants_detail_header .action_txt {
    font-size: 12px;
    color: #898989;
    font-weight: 400;
    margin-bottom: 0px;
    letter-spacing: 0.032em;
    line-height: 16px;
    width: 15%; }

.shipment_request_review-line-item .bottom_check-box {
  display: flex;
  justify-content: start;
  padding: 16px 8px 0px 16px; }
  .shipment_request_review-line-item .bottom_check-box .following_action {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    margin-right: 16px; }
  .shipment_request_review-line-item .bottom_check-box .reverse_pickup {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    margin-right: 16px; }
    .shipment_request_review-line-item .bottom_check-box .reverse_pickup .reverse_pickup_checkbox {
      margin-left: 8px; }
  .shipment_request_review-line-item .bottom_check-box .refund_end {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    margin-right: 16px; }
    .shipment_request_review-line-item .bottom_check-box .refund_end .refund_end_checkbox {
      margin-left: 8px; }
  .shipment_request_review-line-item .bottom_check-box .replacement {
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #898989;
    margin-right: 16px; }
    .shipment_request_review-line-item .bottom_check-box .replacement .replacement_checkbox {
      margin-left: 8px; }

.shipment_request_review-line-item .ghanta-icon-wraper {
  margin-right: 8px;
  margin-left: 8px;
  background: #f0eab3;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center; }

.shipment_request_review-line-item .replacement_border {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 16px;
  margin-top: 4px; }

.shipment_request_review-line-item .replacement_new_txt {
  background: #fffce3;
  height: 40px;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 16px;
  letter-spacing: 0.032em;
  border: 1px solid #feea79;
  color: #212121; }

.shipment_request_review-line-item .refund_disable_circle {
  margin-right: 4px;
  color: #94e390;
  font-size: 16px; }

.shipment_request_review-line-item .bank_detail_txt {
  color: #212121;
  font-weight: 500; }

.shipment_request_review-line-item .groundaction_filter {
  background: #f5f5f5;
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #212121;
  margin-bottom: 16px;
  height: 32px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  line-height: 16px; }

.shipment_request_review-line-item .link_data {
  float: right; }

.shipment_request_review-line-item .ali-in-store-hover-underline-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  color: #fd104d; }

.shipment_request_review-line-item .ali-in-store-hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 85%;
  transform: scaleX(0);
  height: 1px;
  bottom: 3px;
  left: 2px;
  background-color: #e61047;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out; }

.shipment_request_review-line-item .ali-in-store-hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left; }

.shipment_request_review-line-item .view-status-txt {
  color: #ce1543;
  margin-right: 4px; }

.shipment_request_review-line-item .replacement_text_data {
  font-size: 12px;
  color: #212121;
  letter-spacing: 0.032em;
  line-height: 16px;
  font-weight: 500; }

.shipment_request_review-line-item .important_txt {
  color: #212121;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.032em;
  line-height: 16px;
  margin-right: 8px; }

.shipment_request_review-line-item .disabledaction {
  color: #00000040; }

.shipment_request_review-line-item .disabled_checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f5f5f5; }

.shipment_request_review-line-item .disabled_checkbox .ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #00000040 !important; }

.shipment_request_review-line-item .mandatory_filed {
  font-size: 12px !important;
  letter-spacing: 0.032em !important;
  line-height: 16px !important; }

.shipment_request_review-line-item .note_text {
  font-style: italic; }

.shipment_request_review-line-item .ant-select-selection-selected-value {
  color: #212121; }

.open-rfq-wraper .external-case {
  display: flex;
  flex-direction: column; }
  .open-rfq-wraper .external-case .progress-bar {
    border: 1px solid #898989;
    width: 160px;
    padding: 5px;
    border-radius: 2px;
    background-color: #d4cece82;
    color: #898989;
    align-self: center; }

.open-rfq-wraper .no-rfq {
  margin-top: 52px;
  background-color: #fff;
  padding: 40px;
  padding-right: 80px;
  padding-bottom: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  grid-gap: 150px;
  gap: 150px; }
  .open-rfq-wraper .no-rfq .nav-rfq-btn {
    border-color: green;
    width: 160px;
    box-shadow: 0px 2px 3px #00000022;
    font-weight: 500;
    background-color: green;
    height: auto;
    padding: 5px; }
  .open-rfq-wraper .no-rfq .ant-btn-primary[disabled] {
    color: #898989;
    background-color: #d4cece82;
    font-weight: 400;
    border-color: transparent; }
  .open-rfq-wraper .no-rfq .rfq-left-dis {
    line-height: 1.32;
    font-size: 12px;
    color: #fd104d;
    position: relative;
    top: 5px;
    left: 25px; }
  .open-rfq-wraper .no-rfq * {
    margin: 0;
    letter-spacing: 0.032em; }
  .open-rfq-wraper .no-rfq h1 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 14px; }
  .open-rfq-wraper .no-rfq h2 {
    font-size: 14px;
    margin-bottom: 14px;
    font-weight: 500; }
  .open-rfq-wraper .no-rfq h3 {
    font-size: 14px;
    margin-bottom: 24px;
    font-weight: 400; }

.open-rfq-wraper .rfq-table {
  margin-top: 52px; }
  .open-rfq-wraper .rfq-table .variants {
    margin-top: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    .open-rfq-wraper .rfq-table .variants p::after {
      content: ", ";
      margin-right: 4px; }
    .open-rfq-wraper .rfq-table .variants p:last-of-type::after {
      content: ""; }
  .open-rfq-wraper .rfq-table .valid {
    display: flex;
    flex-direction: column; }
    .open-rfq-wraper .rfq-table .valid span {
      color: #898989; }
  .open-rfq-wraper .rfq-table .reject-reason {
    margin-top: 6px; }
    .open-rfq-wraper .rfq-table .reject-reason .btn {
      font-size: 12px;
      color: #898989; }
      .open-rfq-wraper .rfq-table .reject-reason .btn .icon-container {
        position: relative;
        top: 2px;
        left: -4px; }
      .open-rfq-wraper .rfq-table .reject-reason .btn .text {
        position: relative;
        top: -6px; }
    .open-rfq-wraper .rfq-table .reject-reason .btn:hover {
      border-color: #212121 !important; }
    .open-rfq-wraper .rfq-table .reject-reason * {
      fill: #212121; }
  .open-rfq-wraper .rfq-table .table-img-details {
    display: flex;
    align-items: center; }
    .open-rfq-wraper .rfq-table .table-img-details .text-wraper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 1; }
    .open-rfq-wraper .rfq-table .table-img-details p {
      line-height: 13px;
      display: block;
      font-weight: 400 !important; }
    .open-rfq-wraper .rfq-table .table-img-details .name-link {
      line-height: 13px;
      display: flex;
      align-items: center;
      font-weight: 400 !important; }
      .open-rfq-wraper .rfq-table .table-img-details .name-link svg {
        margin-left: 4px;
        opacity: 0; }
    .open-rfq-wraper .rfq-table .table-img-details .name-link:hover {
      color: #0068ff; }
      .open-rfq-wraper .rfq-table .table-img-details .name-link:hover svg {
        opacity: 1; }
    .open-rfq-wraper .rfq-table .table-img-details img {
      height: 52px;
      width: 52px;
      object-fit: contain;
      margin-right: 16px;
      border: 1px solid #c7c7c7;
      flex: none; }
    .open-rfq-wraper .rfq-table .table-img-details .vf {
      display: inline-flex !important;
      color: #898989;
      margin-top: 4px;
      align-items: center; }
      .open-rfq-wraper .rfq-table .table-img-details .vf svg {
        margin-left: 4px; }
    .open-rfq-wraper .rfq-table .table-img-details .vf:hover {
      color: #0068ff; }
  .open-rfq-wraper .rfq-table .link-modal {
    cursor: pointer; }
  .open-rfq-wraper .rfq-table .action-btn1 {
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    flex-direction: column; }
    .open-rfq-wraper .rfq-table .action-btn1 .takeAction svg {
      fill: white; }
    .open-rfq-wraper .rfq-table .action-btn1 .re-open-btn .icon-container svg {
      fill: #fff;
      position: relative;
      left: -6px;
      top: -1px; }
    .open-rfq-wraper .rfq-table .action-btn1 .re-open-btn .text {
      position: relative;
      top: -9px; }
    .open-rfq-wraper .rfq-table .action-btn1 .re-open-btn span {
      color: #ffff !important; }
    .open-rfq-wraper .rfq-table .action-btn1 .ant-btn {
      height: 24px;
      border-radius: 2px;
      font-size: 12px;
      border: 1px solid #898989; }
      .open-rfq-wraper .rfq-table .action-btn1 .ant-btn span {
        color: #898989; }
    .open-rfq-wraper .rfq-table .action-btn1 .ant-btn.ant-btn-primary {
      border: 1px solid #1370fc;
      background-color: #1370fc; }
      .open-rfq-wraper .rfq-table .action-btn1 .ant-btn.ant-btn-primary span {
        color: #fff !important; }
    .open-rfq-wraper .rfq-table .action-btn1 .media-btn {
      display: flex;
      background-color: #fff;
      border: 1px solid #d21b5c;
      transition: all 0.2s ease-in;
      justify-content: center; }
      .open-rfq-wraper .rfq-table .action-btn1 .media-btn svg {
        height: 18px;
        width: 18px;
        fill: #d21b5c;
        align-self: center;
        transition: all 0.2s ease-in; }
      .open-rfq-wraper .rfq-table .action-btn1 .media-btn span {
        align-self: center;
        color: #d21b5c;
        padding-left: 2px;
        transition: all 0.2s ease-in; }
    .open-rfq-wraper .rfq-table .action-btn1 .media-btn:hover {
      background-color: #d21b5c;
      border: 1px solid transparent;
      transition: all 0.2s; }
      .open-rfq-wraper .rfq-table .action-btn1 .media-btn:hover svg {
        fill: #fff;
        transition: all 0.2s ease-in; }
      .open-rfq-wraper .rfq-table .action-btn1 .media-btn:hover span {
        color: #fff;
        transition: all 0.2s ease-in; }
    .open-rfq-wraper .rfq-table .action-btn1 .df {
      align-self: baseline; }
      .open-rfq-wraper .rfq-table .action-btn1 .df .image-continer {
        position: relative;
        top: 5px; }
        .open-rfq-wraper .rfq-table .action-btn1 .df .image-continer svg {
          width: 20px;
          margin-right: -2px;
          height: 20px;
          fill: #d21b5c; }
    .open-rfq-wraper .rfq-table .action-btn1 .underline-animatio {
      display: inline-block;
      position: relative;
      color: #d21b5c;
      top: -3px; }
    .open-rfq-wraper .rfq-table .action-btn1 .underline-animatio:after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 0;
      left: 0;
      color: #d21b5c;
      background-color: #d21b5c;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out; }
    .open-rfq-wraper .rfq-table .action-btn1 .underline-animatio:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left; }
  .open-rfq-wraper .rfq-table .blue-tick-container {
    display: inline-block;
    padding-right: 4px;
    padding-left: 2px;
    position: relative;
    top: 2px; }
  .open-rfq-wraper .rfq-table .red-cross-container {
    position: relative;
    top: 1px;
    padding-left: 4px;
    padding-right: 4px; }
    .open-rfq-wraper .rfq-table .red-cross-container svg {
      height: 12px;
      width: 12px; }
  .open-rfq-wraper .rfq-table .action-btn {
    display: flex;
    grid-gap: 8px;
    gap: 8px; }
    .open-rfq-wraper .rfq-table .action-btn a {
      color: #fff;
      padding: 0px;
      margin: 0px;
      width: 126px;
      margin: auto; }
    .open-rfq-wraper .rfq-table .action-btn .ant-btn {
      height: 24px;
      border-radius: 2px;
      font-size: 12px;
      border: 1px solid #898989; }
      .open-rfq-wraper .rfq-table .action-btn .ant-btn span {
        color: #898989; }
    .open-rfq-wraper .rfq-table .action-btn .ant-btn.ant-btn-primary {
      border: 1px solid #1370fc;
      background-color: #1370fc; }
      .open-rfq-wraper .rfq-table .action-btn .ant-btn.ant-btn-primary span {
        color: #fff !important; }
  .open-rfq-wraper .rfq-table .ant-table-thead th {
    padding: 12px !important;
    background-color: #16273b !important;
    color: #fff !important;
    font-size: 12px; }
  .open-rfq-wraper .rfq-table .ant-table-tbody * {
    font-weight: 500;
    color: #212121; }
  .open-rfq-wraper .rfq-table .ant-table-tbody td {
    padding: 12px !important;
    background-color: #fff !important;
    color: #212121 !important;
    font-size: 12px; }
  .open-rfq-wraper .rfq-table .ant-table-tbody tr:nth-child(even) td {
    background-color: #f5f5f5 !important; }

.show-data-from {
  font-size: 12px;
  margin-left: 8px; }

.media-modal .ant-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll; }
  .media-modal .ant-modal-body img {
    object-fit: contain; }

#test {
  /* Safari, Chrome and Opera > 12.1 */
  /* Firefox < 16 */
  /* Internet Explorer */
  /* Opera < 12.1 */
  animation: fadein 1s; }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

.rtrty {
  color: #fff !important; }
  .rtrty * {
    color: #ffffff !important; }

.view-btn-rfq {
  color: #fff !important; }
  .view-btn-rfq * {
    color: #ffffff !important; }

.view-btn-rfq:hover {
  background-color: #054805 !important;
  border: 2px solid #054805 !important;
  color: #fff !important; }

.open-rfq-nav .roq-btb-container {
  position: relative;
  top: -8px; }

.open-rfq-nav .rfq-left {
  text-align: center;
  line-height: 1.32;
  font-size: 12px;
  color: #fd104d; }
  .open-rfq-nav .rfq-left .value {
    font-weight: 600; }

.open-rfq-nav .rfq-left-dis {
  text-align: center;
  line-height: 1.32;
  font-size: 12px;
  color: #fd104d;
  position: relative;
  top: 5px; }

.open-rfq-nav .open-rfq-nav-upper {
  padding-left: 59px;
  padding-top: 85px;
  padding-right: 158px;
  background-color: #f5f5f5;
  margin-right: -16%;
  margin-left: -6%;
  transform: translateY(-125.6px);
  box-shadow: 0px 3px 8px #00000012;
  position: absolute;
  width: 100%;
  left: 21.7%;
  z-index: 99;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
  .open-rfq-nav .open-rfq-nav-upper .private-product-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px; }
    .open-rfq-nav .open-rfq-nav-upper .private-product-wrap .store-filters {
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .open-rfq-nav .open-rfq-nav-upper .private-product-wrap .store-filters p {
        margin-bottom: 0px;
        font-size: 12px;
        color: #6e6e6e;
        margin-right: 0px; }
    .open-rfq-nav .open-rfq-nav-upper .private-product-wrap .right {
      display: flex;
      align-items: center;
      justify-content: center; }
      .open-rfq-nav .open-rfq-nav-upper .private-product-wrap .right .filter .ant-select-selection {
        padding: 0 !important;
        background: none;
        border: none;
        margin-right: 0; }
      .open-rfq-nav .open-rfq-nav-upper .private-product-wrap .right .filter svg {
        font-size: 20px; }
      .open-rfq-nav .open-rfq-nav-upper .private-product-wrap .right .filter .ant-select-arrow {
        margin-top: -10px; }
      .open-rfq-nav .open-rfq-nav-upper .private-product-wrap .right .filter .ant-select-selection-selected-value {
        padding-right: 26px; }
      .open-rfq-nav .open-rfq-nav-upper .private-product-wrap .right .show_data_from {
        font-size: 12px; }
      .open-rfq-nav .open-rfq-nav-upper .private-product-wrap .right .dispute-status {
        font-size: 12px; }
    .open-rfq-nav .open-rfq-nav-upper .private-product-wrap .left {
      margin-left: "7px"; }
  .open-rfq-nav .open-rfq-nav-upper .secondwrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 104px; }
    .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 12px;
      letter-spacing: 0.032em;
      line-height: 16px; }
      .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .tab {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 32px;
        color: #898989; }
        .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .tab .active {
          color: #1268fb; }
        .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .tab button {
          cursor: pointer;
          border: none;
          background: transparent;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 500; }
      .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .circle {
        position: relative;
        background: #f5f5f5;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .backgroundcircle {
        position: relative;
        font-size: 12px;
        padding: 2px 10px;
        color: #898989;
        border-radius: 13px;
        display: inline-block;
        height: 1.5rem;
        min-width: 1.5rem;
        max-width: 3.3rem;
        vertical-align: middle;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border: 1px solid #898989; }
      .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation {
        position: relative; }
        .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation .private-products-txt a {
          color: #898989; }
        .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation .store-product-txt a {
          color: #898989; }
        .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation .wishlist-product-txt a {
          color: #898989; }
        .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation .import-list-txt a {
          color: #898989; }
      .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #4398ed;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out; }
      .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation:hover {
        color: #4398ed !important; }
        .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation:hover a {
          color: #4398ed; }
        .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation:hover .backgroundcircle {
          border: #4398ed 1px solid !important;
          color: #4398ed !important;
          font-size: 12px;
          letter-spacing: 0.032em;
          font-weight: 400; }
      .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
        transform: scaleX(1);
        transform-origin: top left; }
      .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation-click {
        position: relative; }
        .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation-click button {
          color: #1268fb; }
        .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation-click .backgroundcircle {
          background-color: #1268fb;
          color: #fff;
          border: 1px solid #1268fb; }
      .open-rfq-nav .open-rfq-nav-upper .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        left: 1px;
        bottom: 0px;
        background-color: #1268fb;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        transform: scaleX(1);
        transform-origin: top left; }
    .open-rfq-nav .open-rfq-nav-upper .secondwrap .download_csv {
      margin-bottom: 10px;
      line-height: 30px;
      height: 32px;
      line-height: 30px;
      margin-right: 100px; }
  .open-rfq-nav .open-rfq-nav-upper .ant-input {
    width: 80%;
    font-size: 12px;
    background-color: #f5f5f5;
    color: #1268fb;
    border: none;
    border-radius: 0px;
    font-weight: 500;
    letter-spacing: 0.032em;
    line-height: 24px;
    padding-left: 0px; }
  .open-rfq-nav .open-rfq-nav-upper .ant-calendar-range-picker-separator {
    color: #1268fb !important; }
  .open-rfq-nav .open-rfq-nav-upper .ant-select-selection {
    background-color: #f5f5f5;
    border: none;
    border-radius: 0px; }
  .open-rfq-nav .open-rfq-nav-upper .ant-select-selection-selected-value {
    font-size: 12px;
    color: #1268fb;
    letter-spacing: 0.032em; }
  .open-rfq-nav .open-rfq-nav-upper .ant-calendar-range-picker-input {
    width: 35% !important;
    letter-spacing: 0.032em;
    line-height: 24px; }
  .open-rfq-nav .open-rfq-nav-upper .ant-calendar-picker-clear,
  .open-rfq-nav .open-rfq-nav-upper .ant-calendar-picker-icon {
    top: 50%;
    right: 47px;
    width: 14px;
    height: 14px;
    font-size: 12px; }
  .open-rfq-nav .open-rfq-nav-upper .breadcurmb-txt {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 24px; }
    .open-rfq-nav .open-rfq-nav-upper .breadcurmb-txt > span {
      margin: 0px 4px; }
    .open-rfq-nav .open-rfq-nav-upper .breadcurmb-txt .breadcurmb-underline {
      text-decoration: underline; }

.open-rfq-nav .bottom_data {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding-top: 92px;
  margin-bottom: -32px; }

.open-rfq-nav .ant-select-selection__placeholder {
  color: #1268fb; }

.open-rfq-nav .rfq-status {
  width: 210px;
  height: 40px;
  font-size: 12px !important; }
  .open-rfq-nav .rfq-status svg {
    transition: 0.3s all;
    fill: none; }
    .open-rfq-nav .rfq-status svg path:last-of-type {
      fill: #898989; }
  .open-rfq-nav .rfq-status .ant-select-selection.ant-select-selection--single {
    height: 40px;
    border: none;
    border-radius: 2px;
    background-color: #f8f8f8;
    box-shadow: 0px 2px 8px #00000016; }
    .open-rfq-nav .rfq-status .ant-select-selection.ant-select-selection--single .ant-select-selection__rendered {
      height: 40px; }
  .open-rfq-nav .rfq-status .ant-select-selection-selected-value {
    height: 40px;
    margin-top: 4px;
    color: #1268fb; }
    .open-rfq-nav .rfq-status .ant-select-selection-selected-value span {
      color: #898989;
      margin-right: 2px; }

.open-rfq-nav .rfq-status.ant-select-open svg {
  transform: rotate(-180deg);
  transition: 0.3s all;
  fill: #212121; }
  .open-rfq-nav .rfq-status.ant-select-open svg path {
    fill: #212121; }

.open-rfq-nav .nav-rfq-btn {
  border-color: green;
  width: 160px;
  box-shadow: 0px 2px 3px #00000022;
  font-weight: 500;
  background-color: green;
  height: auto;
  padding: 5px; }

.open-rfq-nav .ant-btn-primary[disabled] {
  color: #898989;
  background-color: #d4cece82;
  font-weight: 400;
  border-color: transparent; }

@media screen and (min-width: 1440px) {
  .open-rfq-nav-upper {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6% !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1442px) and (max-width: 3200px) {
  .open-rfq-nav-upper {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.custom-select span {
  display: none; }

.rfq-modal {
  top: 32px !important; }
  .rfq-modal * {
    letter-spacing: 0.032em;
    margin: 0; }
  .rfq-modal .heading-varient {
    font-size: 12px !important;
    color: #898989 !important;
    line-height: 24px !important; }
  .rfq-modal .product-name-wraper {
    position: relative; }
    .rfq-modal .product-name-wraper .external-product-wraper {
      position: absolute;
      background-color: #fff;
      z-index: 99;
      max-height: 200px;
      overflow-y: hidden;
      top: 65px;
      overflow: scroll;
      width: 100%;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }
      .rfq-modal .product-name-wraper .external-product-wraper .img-details.external {
        background-color: #f5f5f5;
        border-bottom: 1px solid #fff !important; }
  .rfq-modal .scroller {
    max-height: 469px !important; }
  .rfq-modal .ant-form-explain {
    text-transform: capitalize !important; }
  .rfq-modal .ant-modal-header {
    padding: 24px 0px 8px 0px !important;
    margin: 0px 24px; }
    .rfq-modal .ant-modal-header .ant-modal-title h1 {
      font-size: 16px;
      font-weight: 600;
      color: #212121;
      margin-bottom: 6px; }
    .rfq-modal .ant-modal-header .ant-modal-title h2 {
      font-size: 12px;
      font-weight: 400;
      color: #898989; }
  .rfq-modal .qty-error {
    color: #f5222d;
    font-size: 11px; }
  .rfq-modal .ant-modal-body {
    padding-top: 16px !important;
    padding-bottom: 0px !important; }
    .rfq-modal .ant-modal-body .img-details-wraper {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .rfq-modal .ant-modal-body .img-details-wraper .right_svg {
        display: none; }
    .rfq-modal .ant-modal-body .varient-qty-container {
      margin-bottom: 16px !important; }
    .rfq-modal .ant-modal-body .red-dot {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 12px;
      font-family: SimSun, sans-serif;
      line-height: 1; }
    .rfq-modal .ant-modal-body .img-details-wraper-custom .right_svg {
      display: none; }
    .rfq-modal .ant-modal-body .img-details {
      display: flex;
      grid-gap: 5px;
      gap: 5px;
      align-items: center;
      justify-content: flex-start; }
      .rfq-modal .ant-modal-body .img-details img {
        height: 32px !important;
        width: 32px;
        object-fit: contain;
        border: 1px solid #e8e8e8;
        margin-right: 6px; }
      .rfq-modal .ant-modal-body .img-details .text-wraper {
        font-size: 12px;
        line-height: 16px;
        flex: 1 1; }
        .rfq-modal .ant-modal-body .img-details .text-wraper .allsep {
          font-size: 10px; }
    .rfq-modal .ant-modal-body .external-product-wraper .img-details.external {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px;
      padding-left: 0px;
      border-bottom: 1px solid #e9e9e9;
      cursor: pointer;
      transition: 0.4s all; }
    .rfq-modal .ant-modal-body .external-product-wraper .img-details.external svg {
      fill: #5c88ff; }
    .rfq-modal .ant-modal-body .external-product-wraper .img-details.external:hover {
      background-color: #f5f5f5; }
      .rfq-modal .ant-modal-body .external-product-wraper .img-details.external:hover * {
        font-weight: 500;
        transition: 0.4s all; }
    .rfq-modal .ant-modal-body .ant-upload.ant-upload-btn {
      padding: 0px !important; }
    .rfq-modal .ant-modal-body .preview-image-wraper {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: scroll; }
      .rfq-modal .ant-modal-body .preview-image-wraper img {
        height: 80px; }
      .rfq-modal .ant-modal-body .preview-image-wraper embed {
        height: 80px; }
      .rfq-modal .ant-modal-body .preview-image-wraper video {
        height: 80px; }
      .rfq-modal .ant-modal-body .preview-image-wraper iframe {
        height: 80px; }
    .rfq-modal .ant-modal-body .ant-upload.ant-upload-drag {
      border-radius: 0px;
      border: none;
      background-color: #f5f5f5;
      padding: 0px !important;
      height: 64px; }
      .rfq-modal .ant-modal-body .ant-upload.ant-upload-drag .ant-upload-drag-container {
        color: #898989;
        font-size: 12px;
        background-color: #f5f5f5; }
    .rfq-modal .ant-modal-body .ant-tabs-bar {
      margin-bottom: 16px !important;
      border-bottom: 0px; }
      .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll {
        display: flex;
        align-items: center;
        justify-content: flex-start; }
        .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll > div {
          width: 100%; }
        .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-nav.ant-tabs-nav-animated > div {
          width: 100%; }
        .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab {
          padding: 8px 16px !important;
          width: 50%;
          margin: 0 !important;
          text-align: center;
          background-color: #f5f5f5 !important;
          font-size: 12px;
          color: #212121 !important; }
        .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab-active.ant-tabs-tab {
          background-color: #5c88ff !important;
          color: #fff !important; }
        .rfq-modal .ant-modal-body .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-ink-bar {
          background-color: #5c88ff !important;
          transition: 0.3s all linear !important;
          display: none !important; }
    .rfq-modal .ant-modal-body .ant-select-selection__choice {
      height: 32px;
      margin-top: 4px; }
    .rfq-modal .ant-modal-body .product-select .ant-select-selection.ant-select-selection--single {
      border-radius: 0px !important;
      background-color: #f5f5f5 !important;
      height: 40px; }
    .rfq-modal .ant-modal-body .product-select .ant-select-selection__placeholder {
      padding-left: 12px !important; }
    .rfq-modal .ant-modal-body .product-select input {
      padding-left: 12px !important; }
    .rfq-modal .ant-modal-body .product-variants .ant-select-selection__placeholder {
      margin-left: 0px;
      padding-left: 12px; }
    .rfq-modal .ant-modal-body .product-variants input {
      margin-left: 6px !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-select-selection__clear {
      top: 35% !important;
      background: transparent !important; }
    .rfq-modal .ant-modal-body .ant-form #expected_landing_date {
      width: 100% !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-calendar-picker-clear {
      top: 25% !important;
      background: #f5f5f5 !important;
      border-radius: 50%; }
    .rfq-modal .ant-modal-body .ant-form .ant-calendar-picker-icon {
      top: 25% !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-form-explain {
      font-size: 11px !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-row.ant-form-item {
      margin-bottom: 16px !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-row.varient-item {
      margin-bottom: 0px !important;
      margin-left: 20px;
      width: 50%; }
    .rfq-modal .ant-modal-body .ant-form .ant-input-group-addon {
      height: 32px !important;
      border-radius: 0px !important;
      background-color: #ededed !important;
      border: none !important;
      font-size: 12px !important; }
    .rfq-modal .ant-modal-body .ant-form input {
      border-radius: 0px !important;
      background-color: #f5f5f5 !important;
      border: none !important;
      font-size: 12px !important; }
    .rfq-modal .ant-modal-body .ant-form input::placeholder {
      color: #898989 !important; }
    .rfq-modal .ant-modal-body .ant-form textarea::placeholder {
      color: #898989 !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-input:placeholder-shown {
      font-size: 12px !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-select-selection-selected-value {
      padding-left: 12px !important;
      margin-top: 4px;
      font-size: 12px !important; }
    .rfq-modal .ant-modal-body .ant-form .ant-select-selection {
      border: none !important; }
      .rfq-modal .ant-modal-body .ant-form .ant-select-selection .ant-select-selection.ant-select-selection--single {
        height: 40px !important; }
      .rfq-modal .ant-modal-body .ant-form .ant-select-selection .ant-select-selection__rendered {
        height: 40px !important;
        border-radius: 0px !important;
        background-color: #f5f5f5 !important;
        border: none !important;
        margin-left: 0px !important; }
        .rfq-modal .ant-modal-body .ant-form .ant-select-selection .ant-select-selection__rendered svg {
          fill: #5c88ff; }
        .rfq-modal .ant-modal-body .ant-form .ant-select-selection .ant-select-selection__rendered input {
          background-color: transparent !important; }
      .rfq-modal .ant-modal-body .ant-form .ant-select-selection .ant-select-selection__placeholder {
        top: 22% !important;
        font-size: 12px !important;
        color: #898989 !important;
        padding-left: 9px; }
    .rfq-modal .ant-modal-body .ant-form textarea {
      border-radius: 0px !important;
      background-color: #f5f5f5 !important;
      border: none !important;
      font-size: 12px; }
    .rfq-modal .ant-modal-body .ant-form .private-label-radio-group {
      display: flex;
      align-items: center; }
      .rfq-modal .ant-modal-body .ant-form .private-label-radio-group label span:nth-of-type(2) {
        font-size: 12px;
        color: #898989; }
      .rfq-modal .ant-modal-body .ant-form .private-label-radio-group .ant-radio-wrapper-checked .ant-radio-inner {
        border-color: #2bc822 !important; }
      .rfq-modal .ant-modal-body .ant-form .private-label-radio-group .ant-radio-wrapper-checked .ant-radio-inner::after {
        background: #2bc822 !important; }
    .rfq-modal .ant-modal-body .ant-form .footer-button {
      border-top: 1px solid #e8e8e8;
      padding-top: 6px; }
      .rfq-modal .ant-modal-body .ant-form .footer-button .ant-form-item-children {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .rfq-modal .ant-modal-body .ant-form .footer-button .ant-form-item-children button {
          padding: 0px 50px;
          box-shadow: 0px 2px 3px #00000016; }
        .rfq-modal .ant-modal-body .ant-form .footer-button .ant-form-item-children .cancelBtn {
          box-shadow: none; }
        .rfq-modal .ant-modal-body .ant-form .footer-button .ant-form-item-children button:last-of-type {
          background-color: #2bc822;
          border-color: #2bc822; }
    .rfq-modal .ant-modal-body .ant-form .ant-form-item-label {
      line-height: 24px !important; }
      .rfq-modal .ant-modal-body .ant-form .ant-form-item-label label {
        font-size: 12px !important;
        color: #898989 !important; }

.product-overlay-wraper li {
  border-bottom: 1px solid #fff; }

.product-overlay-wraper .img-details-wraper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .product-overlay-wraper .img-details-wraper .right_svg {
    fill: #5c88ff; }

.product-overlay-wraper .img-details {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  justify-content: flex-start; }
  .product-overlay-wraper .img-details img {
    height: 32px !important;
    width: 32px;
    object-fit: contain;
    border: 1px solid #e8e8e8;
    margin-right: 6px; }
  .product-overlay-wraper .img-details .text-wraper {
    font-size: 12px !important;
    line-height: 16px;
    flex: 1 1; }

.rfq-modal
.ant-modal-body
.ant-form
.landing-select
.ant-select-selection
.ant-select-selection__rendered {
  height: 32px !important;
  margin-right: 0px !important; }

.rfq-modal
.ant-modal-body
.ant-form
.landing-select
.ant-select-selection
.ant-select-arrow {
  top: 35%; }

.quote-modal {
  top: 32px !important; }
  .quote-modal .gst {
    font-size: 12px; }
  .quote-modal div[role="tablist"] {
    display: none !important; }
  .quote-modal .files {
    margin-top: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 4px;
    gap: 4px;
    flex-wrap: wrap; }
    .quote-modal .files > div,
    .quote-modal .files > img,
    .quote-modal .files > video {
      object-fit: cover;
      height: 72px;
      width: 72px;
      border: 1px solid #c4c4c4;
      cursor: pointer; }
  .quote-modal * {
    letter-spacing: 0.032em;
    font-size: 12px;
    margin: 0; }
  .quote-modal .global-tooltip .anticon.global-tooltip-icon {
    margin-right: 0px !important; }
  .quote-modal .global-tooltip span i {
    margin-right: 0px !important; }
  .quote-modal .scroller {
    max-height: 469px !important; }
  .quote-modal .ant-modal-header {
    padding: 24px 0px 8px 0px !important;
    margin: 0px 24px; }
    .quote-modal .ant-modal-header .ant-modal-title h1 {
      font-size: 16px;
      font-weight: 600;
      color: #212121;
      margin-bottom: 6px; }
    .quote-modal .ant-modal-header .ant-modal-title h2 {
      font-size: 12px;
      font-weight: 400;
      color: #898989; }
  .quote-modal .ant-modal-body {
    padding-top: 16px !important;
    padding-bottom: 16px !important; }
    .quote-modal .ant-modal-body .moq-range-wraper {
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #e8e8e8; }
      .quote-modal .ant-modal-body .moq-range-wraper .view-media {
        margin-top: 6px;
        border: 1px solid #0068ff !important;
        color: #0068ff !important; }
        .quote-modal .ant-modal-body .moq-range-wraper .view-media span {
          color: #0068ff !important; }
      .quote-modal .ant-modal-body .moq-range-wraper .moq-heading,
      .quote-modal .ant-modal-body .moq-range-wraper .moq-days,
      .quote-modal .ant-modal-body .moq-range-wraper .loop-line {
        display: flex;
        justify-content: space-between;
        padding: 12px 24px; }
        .quote-modal .ant-modal-body .moq-range-wraper .moq-heading > div,
        .quote-modal .ant-modal-body .moq-range-wraper .moq-days > div,
        .quote-modal .ant-modal-body .moq-range-wraper .loop-line > div {
          width: 220px;
          color: #212121;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
        .quote-modal .ant-modal-body .moq-range-wraper .moq-heading .moq,
        .quote-modal .ant-modal-body .moq-range-wraper .moq-days .moq,
        .quote-modal .ant-modal-body .moq-range-wraper .loop-line .moq {
          width: 140px !important;
          align-items: flex-start; }
        .quote-modal .ant-modal-body .moq-range-wraper .moq-heading .days,
        .quote-modal .ant-modal-body .moq-range-wraper .moq-days .days,
        .quote-modal .ant-modal-body .moq-range-wraper .loop-line .days {
          display: flex;
          align-items: center; }
      .quote-modal .ant-modal-body .moq-range-wraper .moq-heading {
        padding: 0px 24px;
        margin-bottom: 4px; }
        .quote-modal .ant-modal-body .moq-range-wraper .moq-heading > div {
          color: #898989 !important;
          font-weight: 400; }
      .quote-modal .ant-modal-body .moq-range-wraper .moq-days {
        padding: 0px 24px;
        margin-bottom: 8px; }
      .quote-modal .ant-modal-body .moq-range-wraper .moq {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400 !important; }
      .quote-modal .ant-modal-body .moq-range-wraper .loop-line:nth-of-type(odd) {
        background-color: #f5f5f5; }
    .quote-modal .ant-modal-body .remark-wraper {
      margin-bottom: 40px; }
      .quote-modal .ant-modal-body .remark-wraper p {
        margin-bottom: 8px;
        color: #898989; }
      .quote-modal .ant-modal-body .remark-wraper textarea {
        border-radius: 0px;
        border: none;
        background-color: #f5f5f5;
        resize: none; }
  .quote-modal .ant-modal-footer {
    margin-left: 24px !important;
    margin-right: 24px !important;
    padding: 12px 0 !important; }
    .quote-modal .ant-modal-footer .grp button:first-of-type {
      background-color: #f5f5f5; }
    .quote-modal .ant-modal-footer .grp .ant-btn-primary {
      font-weight: 500; }
    .quote-modal .ant-modal-footer .footer-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end; }
      .quote-modal .ant-modal-footer .footer-btns button {
        padding-left: 56px;
        padding-right: 56px; }
      .quote-modal .ant-modal-footer .footer-btns a {
        padding-left: 32px;
        padding-right: 32px; }
      .quote-modal .ant-modal-footer .footer-btns .green {
        background-color: #2bc822;
        border: #2bc822; }
        .quote-modal .ant-modal-footer .footer-btns .green span {
          color: #fff;
          font-weight: 600; }
      .quote-modal .ant-modal-footer .footer-btns .green:hover {
        background-color: #198313; }

.media-modal .ant-modal-body {
  position: relative; }

.media-modal .left,
.media-modal .right {
  position: absolute; }
  .media-modal .left svg,
  .media-modal .right svg {
    transform: scale(2);
    cursor: pointer; }

.media-modal .left {
  left: 24px;
  top: 50%;
  transform: translateY(-50%); }

.media-modal .right {
  right: 24px;
  top: 50%;
  transform: translateY(-50%); }

.media-modal .img-slider > div,
.media-modal .img-slider > img,
.media-modal .img-slider > video {
  object-fit: cover;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 500px;
  width: 100%;
  border: 1px solid #c4c4c4;
  cursor: pointer; }

.media-modal .ant-modal-body {
  padding: 24px 4rem; }

.media-modal .img-slider {
  display: flex;
  align-items: center; }

.media-modal-new .ant-modal-body {
  position: relative; }

.media-modal-new .left,
.media-modal-new .right {
  position: absolute; }
  .media-modal-new .left svg,
  .media-modal-new .right svg {
    transform: scale(2);
    cursor: pointer; }

.media-modal-new .left {
  left: 24px;
  top: 50%;
  transform: translateY(-50%); }

.media-modal-new .right {
  right: 24px;
  top: 50%;
  transform: translateY(-50%); }

.media-modal-new .img-slider > div,
.media-modal-new .img-slider > img,
.media-modal-new .img-slider > video {
  object-fit: cover;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  max-height: 500px;
  width: 100%;
  border: 1px solid #c4c4c4;
  cursor: pointer; }

.media-modal-new .ant-modal-body {
  padding: 24px 4rem; }

.media-modal-new .img-slider {
  display: flex;
  align-items: center; }

.rfq-variant-modal .name-pro {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
  padding-right: 12px; }

.rfq-variant-modal .sub-heading {
  display: flex;
  justify-content: flex-end; }

.rfq-variant-modal .Total-row {
  display: flex;
  justify-content: space-between;
  border: 1px solid #898989;
  padding: 12px 5px; }
  .rfq-variant-modal .Total-row .total {
    font-weight: 500;
    color: #212121; }
  .rfq-variant-modal .Total-row .qty {
    padding-right: 12px;
    font-weight: 500;
    color: #212121; }

.rfq-variant-modal .heading-row {
  display: flex;
  justify-content: space-between;
  padding: 12px 5px 12px;
  background-color: #16273b; }
  .rfq-variant-modal .heading-row .img-details {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    justify-content: flex-start; }
    .rfq-variant-modal .heading-row .img-details img {
      height: 40px !important;
      width: 40px;
      object-fit: contain;
      border: 1px solid #e8e8e8;
      margin-right: 6px; }
    .rfq-variant-modal .heading-row .img-details .text-wraper {
      font-size: 14px !important;
      line-height: 17px;
      font-weight: 500;
      flex: 1 1;
      color: #ffffff; }
  .rfq-variant-modal .heading-row .heading-qty {
    font-weight: 500;
    color: #ffffff;
    padding-right: 12px; }

.rfq-variant-modal .variant-row {
  display: flex;
  justify-content: space-between;
  padding: 5px; }
  .rfq-variant-modal .variant-row .img-details {
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    justify-content: flex-start; }
    .rfq-variant-modal .variant-row .img-details img {
      height: 40px !important;
      width: 40px;
      object-fit: contain;
      border: 1px solid #e8e8e8;
      margin-right: 6px; }
    .rfq-variant-modal .variant-row .img-details .text-wraper {
      font-size: 14px !important;
      line-height: 14px;
      flex: 1 1;
      color: #212121;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
      padding-right: 12px; }
  .rfq-variant-modal .variant-row .qty-variant {
    align-self: center;
    padding-right: 12px; }

.rfq-variant-modal .variant-row:nth-of-type(even) {
  background: #f5f5f5; }

.cancel-modal234 .reason-container {
  background-color: #f5f5f5;
  padding: 12px 8px;
  font-size: 14px;
  color: #212121;
  font-weight: 500; }
  .cancel-modal234 .reason-container span {
    color: #898989;
    font-weight: 400; }

.cancel-modal234 .by {
  margin-top: 6px;
  text-align: right;
  font-size: 12px;
  color: #212121;
  font-weight: 500; }
  .cancel-modal234 .by span {
    color: #898989;
    font-weight: 400; }

.preview-broadcast1 {
  opacity: 0;
  transition: all 0.5s ease-in-out; }
  .preview-broadcast1 .preview {
    font-family: 'Poppins', sans-serif !important;
    background-color: white;
    padding: 35px 42px;
    margin-top: 24px; }
    .preview-broadcast1 .preview .imgdiv {
      height: 360px;
      margin-bottom: 22px; }
      .preview-broadcast1 .preview .imgdiv img {
        height: 100%;
        width: 100%;
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center; }
    .preview-broadcast1 .preview .we h1 {
      color: #2E2E2E;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px; }
    .preview-broadcast1 .preview .we p {
      color: #2E2E2E;
      font-size: 13px;
      margin-bottom: 20px;
      font-weight: 500; }
      .preview-broadcast1 .preview .we p span {
        color: #2E2E2E;
        font-weight: bold; }
    .preview-broadcast1 .preview .we h4 {
      color: #2E2E2E;
      font-size: 12px; }
    .preview-broadcast1 .preview .how span {
      font-weight: 600; }
    .preview-broadcast1 .preview .how p {
      font-size: 13px;
      color: #2E2E2E; }
    .preview-broadcast1 .preview .back {
      margin: 20px 0; }
      .preview-broadcast1 .preview .back h2 {
        color: #7E5BA6;
        font-size: 18px;
        font-weight: 600; }
      .preview-broadcast1 .preview .back h3 {
        color: #7E5BA6;
        font-size: 18px;
        font-weight: 700; }
    .preview-broadcast1 .preview .happy {
      margin-top: 24px; }
      .preview-broadcast1 .preview .happy h3 {
        color: #2E2E2E;
        font-size: 14px;
        font-weight: 600; }
  .preview-broadcast1 .footer {
    height: 320px;
    background-color: #3A3A3C;
    text-align: center;
    padding-top: 22px; }
    .preview-broadcast1 .footer .footerimg {
      height: 40px;
      width: 106px;
      margin: auto; }
      .preview-broadcast1 .footer .footerimg img {
        height: 100%;
        width: 100%; }
    .preview-broadcast1 .footer h4 {
      color: #fff;
      font-size: 21px;
      font-weight: 500;
      margin-top: 16px;
      margin-bottom: 16px; }
    .preview-broadcast1 .footer .follow {
      margin-bottom: 16px;
      font-size: 12px;
      color: #fff; }
    .preview-broadcast1 .footer .address {
      padding: 24px 0;
      color: white; }
  .preview-broadcast1 .ant-modal-footer {
    display: none !important; }
  .preview-broadcast1 .ref {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column; }

.showOpacity {
  opacity: 1;
  transition: all 0.5s ease-in-out; }

.setting-cointainer {
  position: relative;
  /* Firefox */ }
  .setting-cointainer .custom-pop-up {
    position: fixed;
    z-index: 9;
    /* width: 200px; */
    background-color: #fff;
    top: 50%;
    left: 58%;
    transform: translate(-50%, -50%);
    width: 1008px;
    border-radius: 12px; }
    .setting-cointainer .custom-pop-up .wr {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 24px;
      gap: 24px;
      padding: 32px 32px; }
    .setting-cointainer .custom-pop-up * {
      letter-spacing: 0.032em; }
    .setting-cointainer .custom-pop-up .left {
      width: 62%;
      border: 4px solid #f5f5f5;
      padding: 24px;
      margin-right: 24px;
      position: relative; }
      .setting-cointainer .custom-pop-up .left h1 {
        font-size: 20px;
        margin: 0px;
        font-weight: 600; }
      .setting-cointainer .custom-pop-up .left h2 {
        font-size: 14px;
        margin-bottom: 24px; }
      .setting-cointainer .custom-pop-up .left h3 {
        font-size: 12px;
        font-weight: 500;
        color: #0068ff; }
      .setting-cointainer .custom-pop-up .left h4 {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 16px; }
      .setting-cointainer .custom-pop-up .left .btn-wraper {
        display: flex; }
        .setting-cointainer .custom-pop-up .left .btn-wraper a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 12px; }
        .setting-cointainer .custom-pop-up .left .btn-wraper a {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: 12px; }
          .setting-cointainer .custom-pop-up .left .btn-wraper a svg {
            height: 24px;
            width: 24px;
            margin-right: 12px;
            color: #fff;
            fill: #fff; }
        .setting-cointainer .custom-pop-up .left .btn-wraper a:first-of-type svg {
          fill: #898989 !important; }
        .setting-cointainer .custom-pop-up .left .btn-wraper a:first-of-type:hover svg {
          fill: #0068ff !important; }
    .setting-cointainer .custom-pop-up .right {
      width: 38%;
      height: 100%; }
      .setting-cointainer .custom-pop-up .right img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
  .setting-cointainer .italic-text {
    font-style: italic; }
  .setting-cointainer .heading {
    border-bottom: 1px solid #cecece;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.032em;
    font-weight: 500;
    color: #212121;
    display: flex;
    justify-content: space-between; }
  .setting-cointainer .sub-heading {
    border-bottom: 1px solid #cecece;
    margin-bottom: 32px;
    margin-top: 24px;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.032em;
    font-weight: 500;
    color: #212121; }
  .setting-cointainer .brand_logo {
    margin-top: 32px; }
    .setting-cointainer .brand_logo .logo-heading-cointainer {
      display: flex;
      font-size: 12px;
      margin-bottom: 5px; }
      .setting-cointainer .brand_logo .logo-heading-cointainer .brand_name_label {
        width: 50%; }
    .setting-cointainer .brand_logo .uploader-cointainer {
      display: flex; }
      .setting-cointainer .brand_logo .uploader-cointainer .brand_logo_uploder {
        display: flex;
        justify-content: space-between;
        width: 50%; }
        .setting-cointainer .brand_logo .uploader-cointainer .brand_logo_uploder .brand_logo_ican {
          width: 32%;
          height: 120px; }
          .setting-cointainer .brand_logo .uploader-cointainer .brand_logo_uploder .brand_logo_ican .drage-image {
            padding: 24px 16px;
            background-color: #f5f5f5;
            margin-right: 8px; }
          .setting-cointainer .brand_logo .uploader-cointainer .brand_logo_uploder .brand_logo_ican .drage-image:hover {
            background-color: #1370fc;
            color: #fff; }
          .setting-cointainer .brand_logo .uploader-cointainer .brand_logo_uploder .brand_logo_ican .drage-image:hover svg g path {
            fill: #fff; }
          .setting-cointainer .brand_logo .uploader-cointainer .brand_logo_uploder .brand_logo_ican .drage-image svg {
            position: relative;
            left: 33%; }
        .setting-cointainer .brand_logo .uploader-cointainer .brand_logo_uploder .images_show {
          width: 65%; }
  .setting-cointainer .title-select {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    color: #898989;
    margin-bottom: 5px;
    margin-top: 4px; }
  .setting-cointainer .select-theme {
    margin-bottom: 10px; }
  .setting-cointainer .radio-group-domain {
    margin-bottom: 10px; }
  .setting-cointainer .self-input .title-input {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    color: #898989;
    margin-bottom: 5px;
    margin-top: 4px; }
  .setting-cointainer .self-input .ant-input-group {
    width: 80%; }
  .setting-cointainer .self-input .ant-input-group-addon {
    background: #f5f5f5;
    border: 1px solid #212121;
    border-radius: 0px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 20px;
    font-size: 12px;
    height: 37px;
    padding: 4px 11px;
    border-right: none; }
  .setting-cointainer .self-input .ant-input {
    background: #f5f5f5;
    border: 1px solid #212121;
    border-radius: 0px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 20px;
    font-size: 12px;
    height: 37px;
    padding: 4px 11px; }
  .setting-cointainer .self-input .inputdd .ant-input-group-addon {
    border: none; }
  .setting-cointainer .self-input .inputdd .ant-input:hover {
    border: none;
    border-right-width: 0px !important; }
  .setting-cointainer .self-input .input {
    width: 80%;
    background: #f5f5f5;
    border: 1px solid #212121;
    border-radius: 0px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 20px;
    font-size: 12px;
    height: 37px;
    padding: 4px 11px; }
  .setting-cointainer .self-input .disable {
    opacity: 0.5; }
  .setting-cointainer .container-mainDomain .title-input {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.032em;
    color: #898989;
    margin-bottom: 5px;
    margin-top: 4px; }
  .setting-cointainer .container-mainDomain .ant-input-group-wrapper {
    width: 80%; }
    .setting-cointainer .container-mainDomain .ant-input-group-wrapper .ant-input-group {
      width: 100%; }
  .setting-cointainer .container-mainDomain .ant-input-group-addon {
    background: #f5f5f5;
    border: 1px solid #212121;
    border-radius: 0px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 20px;
    font-size: 12px;
    height: 37px;
    padding: 4px 11px;
    border-left: 0; }
  .setting-cointainer .container-mainDomain .ant-input {
    background: #f5f5f5;
    border: 1px solid #212121;
    border-radius: 0px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 20px;
    font-size: 12px;
    height: 37px;
    padding: 4px 11px; }
  .setting-cointainer .container-mainDomain .inputdd .ant-input-group-addon {
    border: none; }
  .setting-cointainer .container-mainDomain .inputdd .ant-input:hover {
    border: none;
    border-right-width: 0px !important; }
  .setting-cointainer .container-mainDomain .input {
    width: 80%;
    background: #f5f5f5;
    border: 1px solid #212121;
    border-radius: 0px;
    color: #212121;
    letter-spacing: 0.032em;
    line-height: 20px;
    font-size: 12px;
    height: 37px;
    padding: 4px 11px; }
  .setting-cointainer .container-mainDomain .colour-text {
    margin-left: 12px;
    font-weight: 400; }
  .setting-cointainer .container-mainDomain .disable {
    opacity: 0.5; }
  .setting-cointainer .container-mainDomain .verified {
    color: #36c626;
    font-weight: 500;
    position: relative;
    right: -8px; }
    .setting-cointainer .container-mainDomain .verified .icon-container {
      position: relative;
      right: -1px;
      top: 1px; }
      .setting-cointainer .container-mainDomain .verified .icon-container svg {
        transform: scale(1.2); }
        .setting-cointainer .container-mainDomain .verified .icon-container svg * {
          fill: #ffffff !important;
          stroke: #36c626 !important; }
  .setting-cointainer .cointainer-input {
    display: flex; }
    .setting-cointainer .cointainer-input .container-main {
      width: 50%; }
      .setting-cointainer .cointainer-input .container-main .title-input-cointainer {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin-bottom: 5px;
        margin-top: 5px; }
      .setting-cointainer .cointainer-input .container-main .title-input {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.032em;
        color: #898989;
        margin-bottom: 5px;
        margin-top: 4px; }
      .setting-cointainer .cointainer-input .container-main .input {
        width: 80%;
        background: #f5f5f5;
        border: 1px solid #212121;
        border-radius: 0px;
        color: #212121;
        letter-spacing: 0.032em;
        line-height: 20px;
        font-size: 12px;
        height: 37px;
        padding: 4px 11px; }
      .setting-cointainer .cointainer-input .container-main .colour-text {
        margin-left: 12px;
        font-weight: 400; }
    .setting-cointainer .cointainer-input .container-2 {
      width: 50%; }
      .setting-cointainer .cointainer-input .container-2 .container-main {
        width: 100%; }
  .setting-cointainer .btnpre {
    margin-top: 16px;
    height: 32px;
    padding: 0 15px;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #fff !important;
    text-shadow: none !important;
    background-color: #0068ff !important;
    box-shadow: 0px 2px 8px #002f7225 !important;
    border: 2px solid #0068ff !important;
    border-radius: 2px !important;
    background-color: #fff !important;
    color: #0068ff !important;
    border: 2px solid #0068ff !important;
    box-shadow: none !important;
    margin-right: 5px; }
  .setting-cointainer .icon-container {
    position: relative;
    right: 4px;
    top: 2px; }
    .setting-cointainer .icon-container svg * {
      fill: #0068ff;
      stroke: #0068ff; }
  .setting-cointainer .btn {
    margin-top: 16px;
    height: 32px;
    padding: 0 15px;
    cursor: pointer;
    white-space: nowrap;
    text-align: center;
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 500 !important;
    font-size: 12px !important;
    color: #fff !important;
    text-shadow: none !important;
    background-color: #0068ff !important;
    box-shadow: 0px 2px 8px #002f7225 !important;
    border: 2px solid #0068ff !important;
    border-radius: 2px !important; }
  .setting-cointainer .cancel-btn {
    background-color: #fff !important;
    color: #212121 !important;
    border: 2px solid #212121 !important;
    box-shadow: none !important;
    margin-left: 5px; }
  .setting-cointainer .divider {
    border-bottom: 1px solid #cecece;
    margin-bottom: 6px;
    margin-top: 32px; }
  .setting-cointainer .footer-cointainer {
    text-align: right; }
  .setting-cointainer .heading-right {
    position: relative;
    top: -16px; }
  .setting-cointainer input:focus-visible,
  .setting-cointainer textarea {
    outline: none !important; }
  .setting-cointainer input::-webkit-outer-spin-button,
  .setting-cointainer input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .setting-cointainer input[type="number"] {
    -moz-appearance: textfield; }
  .setting-cointainer input:disabled {
    cursor: not-allowed;
    border: 0px solid black !important; }
  .setting-cointainer textarea:disabled {
    cursor: not-allowed;
    border: 0px solid black !important; }
  .setting-cointainer .domain-cointainer-main ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px; }
  .setting-cointainer .domain-cointainer-main ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }

.icon-i-ontracking:hover svg g {
  fill: blue; }

.icon-i-ontracking:hover svg #i_Button_Circle {
  stroke: none; }

.icon-i-ontracking:hover svg #icomponent1 {
  stroke: white; }

.icon-i-ontracking:hover svg #icomponent2 {
  stroke: white; }

.setting-cointainer.locked::after {
  content: "";
  height: 133%;
  width: 128%;
  position: absolute;
  top: -162px;
  left: -129px;
  backdrop-filter: blur(3px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(20, 20, 20, 0.55); }

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 9; }

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 5px solid #d4c980; }

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background: linear-gradient(to right, #a47e30, #d4c980);
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 "Poppins", sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center; }

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px; }

.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: #bf953f;
  border-left-color: #bf953f; }

.ribbon-top-left::before {
  top: 0;
  right: 0; }

.ribbon-top-left::after {
  bottom: 0;
  left: 0; }

.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg); }


.colorContainer .cointainer-input {
  display: flex; }
  .colorContainer .cointainer-input .container-main {
    width: 30%; }
    .colorContainer .cointainer-input .container-main .title-input {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.032em;
      color: #898989;
      margin-bottom: 5px;
      margin-top: 4px; }
    .colorContainer .cointainer-input .container-main .input {
      width: 80%;
      background: #f5f5f5;
      border: 1px solid #212121;
      border-radius: 0px;
      color: #212121;
      letter-spacing: 0.032em;
      line-height: 20px;
      font-size: 12px;
      height: 37px;
      padding: 4px 11px; }
    .colorContainer .cointainer-input .container-main .colour-text {
      margin-left: 12px;
      font-weight: 400; }

.preferences_seetting_data {
  padding-bottom: 32px; }
  .preferences_seetting_data .settingnav {
    padding-left: 59px;
    padding-top: 85px;
    padding-right: 158px;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    transform: translateY(-125.6px);
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 21.7%;
    z-index: 99;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); }
    .preferences_seetting_data .settingnav .settingwrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px; }
      .preferences_seetting_data .settingnav .settingwrap .store-filters {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: flex-end; }
        .preferences_seetting_data .settingnav .settingwrap .store-filters p {
          margin-bottom: 0px;
          font-size: 12px;
          color: #6e6e6e;
          margin-right: 0px; }
    .preferences_seetting_data .settingnav .secondwrap {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .preferences_seetting_data .settingnav .secondwrap .filtertab {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 12px;
        letter-spacing: 0.032em;
        line-height: 16px; }
        .preferences_seetting_data .settingnav .secondwrap .filtertab .tab {
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 32px;
          color: #898989; }
          .preferences_seetting_data .settingnav .secondwrap .filtertab .tab .active {
            color: #1268fb; }
          .preferences_seetting_data .settingnav .secondwrap .filtertab .tab button {
            cursor: pointer;
            border: none;
            background: transparent;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 500; }
        .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation {
          position: relative; }
        .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #4398ed;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out; }
        .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:hover {
          color: #4398ed !important; }
          .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:hover .circle {
            border: #4398ed 1px solid !important;
            color: #4398ed !important;
            font-size: 12px;
            letter-spacing: 0.032em;
            font-weight: 400; }
        .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation:hover:after {
          transform: scaleX(1);
          transform-origin: top left; }
        .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation-click {
          position: relative; }
        .preferences_seetting_data .settingnav .secondwrap .filtertab .hover-ndr-underline-animation-click:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          left: 1px;
          bottom: 0px;
          background-color: #1268fb;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
          transform: scaleX(1);
          transform-origin: top left; }
  .preferences_seetting_data .bottom_data {
    display: flex;
    justify-content: space-between;
    padding-top: 90px;
    margin-top: 32px;
    padding-top: 12px;
    margin-bottom: 8px; }
  .preferences_seetting_data .ant-select-selection__placeholder {
    color: #1268fb; }

@media screen and (min-width: 1440px) {
  .settingnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: -6%;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 22.7% !important;
    z-index: 99; } }

@media screen and (min-width: 1441px) and (max-width: 1900px) {
  .settingnav {
    padding-left: 104px !important;
    padding-top: 85px;
    padding-right: 244px !important;
    background-color: #f5f5f5;
    margin-right: -16%;
    margin-left: 0 !important;
    box-shadow: 0px 3px 8px #00000012;
    position: absolute;
    width: 100%;
    left: 240px !important;
    z-index: 99; } }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.non-cursor-pointer {
  pointer-events: none;
  border-bottom: none !important; }

.fullorder_data .uppp {
  transform: translateY(-195.6px);
  transition: all 0.3s linear; }

.fullorder_data .downn {
  transform: translateY(-125.6px);
  transition: all 0.3s linear; }

.signup-container-new {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#536be8, #3d2fd8, #3d2fd8, #6c48e2);
  position: relative; }
  .signup-container-new .mainCard-signup {
    z-index: 999;
    width: 900px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 6px 8px 48px rgba(0, 0, 0, 0.39);
    display: flex; }
  .signup-container-new .shadow-container {
    position: absolute;
    top: -247px;
    left: -63px; }

.right-container-signup {
  flex: 1 1;
  display: block;
  width: 100%;
  padding-bottom: 47px !important; }
  .right-container-signup .ant-tabs-nav-scroll {
    display: none; }
  .right-container-signup .ant-tabs-bar {
    display: none; }

.right-card-signupcontainer .signup-line {
  margin-bottom: 12px; }
  .right-card-signupcontainer .signup-line .login-form-signup-here {
    margin-left: 127px; }

.right-card-signupcontainer .rightlogocontainer {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  top: 3px;
  left: 6px; }

.right-card-signupcontainer .facebookcontainer {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  top: 2px;
  left: 0; }

.right-card-signupcontainer .googlecontainer {
  position: relative;
  margin-left: 10px;
  margin-right: 5px;
  top: 3px;
  left: 0; }

.right-card-signupcontainer .error-phone {
  color: #f5222d;
  line-height: 1;
  padding-bottom: 6px; }

.right-card-signupcontainer .heading-bar {
  padding-top: 58px;
  padding-left: 56px; }

.right-card-signupcontainer .main-heading {
  font-size: 28px;
  font-weight: 800;
  font-family: "Martel", serif !important;
  color: #212121; }

.right-card-signupcontainer .sub-heading {
  font-size: 14px;
  font-weight: 400;
  color: #323041; }

.right-card-signupcontainer .flex-from {
  padding: 10px 10%; }
  .right-card-signupcontainer .flex-from .flex-form-inner {
    overflow: scroll;
    max-height: 50vh;
    width: 100%;
    padding: 0 2px;
    overflow-x: hidden;
    box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.2); }
    .right-card-signupcontainer .flex-from .flex-form-inner .ant-form-item {
      margin-bottom: 16px; }
    .right-card-signupcontainer .flex-from .flex-form-inner .otp-info {
      font-size: 12px;
      color: #898989;
      font-style: italic;
      line-height: 1;
      margin-top: 6px; }
    .right-card-signupcontainer .flex-from .flex-form-inner .input-signup23 {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 100%; }
    .right-card-signupcontainer .flex-from .flex-form-inner .input-signup22 input,
    .right-card-signupcontainer .flex-from .flex-form-inner .input-signup22 select {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 100%; }
    .right-card-signupcontainer .flex-from .flex-form-inner .input-signup22 input {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 100%; }
    .right-card-signupcontainer .flex-from .flex-form-inner .headingquestion {
      line-height: 1;
      margin-bottom: 8px;
      color: #212121; }
    .right-card-signupcontainer .flex-from .flex-form-inner .question-container {
      font-size: 14px !important;
      color: #898989 !important;
      margin-bottom: 24px !important; }
    .right-card-signupcontainer .flex-from .flex-form-inner .option-group {
      display: flex;
      width: 496px;
      flex-wrap: wrap; }
      .right-card-signupcontainer .flex-from .flex-form-inner .option-group .option {
        width: 47%;
        color: #898989 !important;
        margin-bottom: 4px; }
    .right-card-signupcontainer .flex-from .flex-form-inner .ant-radio-checked .ant-radio-inner {
      border-color: #38b957; }
    .right-card-signupcontainer .flex-from .flex-form-inner .ant-radio-checked .ant-radio-inner::after {
      background-color: #38b957; }
    .right-card-signupcontainer .flex-from .flex-form-inner .ant-radio-wrapper-checked span {
      color: #212121 !important; }
    .right-card-signupcontainer .flex-from .flex-form-inner .ant-radio:hover .ant-radio-inner {
      border-color: #38b957; }
    .right-card-signupcontainer .flex-from .flex-form-inner .ant-radio-inner {
      background-color: #f5f5f5; }
    .right-card-signupcontainer .flex-from .flex-form-inner .question-container-first {
      margin-top: 24px; }
    .right-card-signupcontainer .flex-from .flex-form-inner .question-container-last {
      margin-bottom: 20px !important; }
    .right-card-signupcontainer .flex-from .flex-form-inner .phone-relative .ant-form-item-children {
      position: relative; }
      .right-card-signupcontainer .flex-from .flex-form-inner .phone-relative .ant-form-item-children p {
        pointer-events: none;
        position: absolute;
        top: 20px;
        left: 75px;
        transform: translate(0%, -50%);
        z-index: 9;
        font-size: 14px;
        color: #898989;
        opacity: 0.5; }
    .right-card-signupcontainer .flex-from .flex-form-inner .phone-relative .phoneinput input {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989 !important;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 100% !important;
      padding-left: 45px !important; }
    .right-card-signupcontainer .flex-from .flex-form-inner .error-phone-input input:focus {
      border: 1px solid red !important; }
    .right-card-signupcontainer .flex-from .flex-form-inner .submitbutton {
      background-color: #38b957;
      border-radius: 2px !important;
      height: 40px !important;
      width: 390px;
      font-size: 14px;
      display: block;
      color: #ffffff;
      font-weight: 500;
      margin-bottom: 12px;
      border: 0px solid transparent; }
    .right-card-signupcontainer .flex-from .flex-form-inner .facebookbutton {
      background-color: #2275c8;
      text-align: center;
      border-radius: 2px !important;
      height: 40px !important;
      width: 390px !important;
      font-size: 14px;
      display: block;
      color: #ffffff;
      font-weight: 500;
      margin-bottom: 12px;
      border: 0px solid transparent;
      padding-top: 10px; }
    .right-card-signupcontainer .flex-from .flex-form-inner .signupbutton {
      border-radius: 2px !important;
      text-align: center;
      height: 40px !important;
      width: 390px !important;
      font-size: 14px;
      display: block;
      border: 1px solid #212121;
      font-weight: 500;
      padding-top: 7px;
      color: #212121; }

.right-card-signupcontainer .flex-form2 {
  padding-left: 56px;
  padding-top: 5px; }
  .right-card-signupcontainer .flex-form2 .submitbutton {
    background-color: #38b957;
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px;
    font-size: 14px;
    display: block;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 12px;
    border: 0px solid transparent; }

.otpVerifyContaner {
  color: #212121;
  min-height: 550px; }
  .otpVerifyContaner .heading-bar {
    padding-top: 58px;
    padding-left: 42.4px; }
  .otpVerifyContaner .main-heading {
    font-size: 28px;
    font-weight: 600;
    font-family: "Martel", serif !important;
    color: #212121; }
  .otpVerifyContaner .sub-heading {
    font-size: 14px;
    font-weight: 400;
    color: #323041; }
  .otpVerifyContaner .email-section {
    padding-top: 24px;
    padding-left: 42.4px;
    padding-bottom: 16px; }
    .otpVerifyContaner .email-section .input-email {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 390px; }
    .otpVerifyContaner .email-section .edit {
      margin-left: 4px; }
      .otpVerifyContaner .email-section .edit .editiconContainer {
        position: relative;
        top: 2px; }
      .otpVerifyContaner .email-section .edit .edit-s {
        text-decoration: underline;
        color: #fc125e; }
    .otpVerifyContaner .email-section .otp-info {
      font-size: 12px;
      font-style: italic;
      color: #898989; }
  .otpVerifyContaner .otp-section {
    padding-left: 42.4px; }
    .otpVerifyContaner .otp-section .otp-verify .otp-number {
      width: 56px;
      height: 40px;
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      margin-right: 6px;
      padding-left: 25px;
      border: 1px solid #898989; }
    .otpVerifyContaner .otp-section .otp-verify .otp-number:focus {
      border: 1px solid #3a2ccd; }
    .otpVerifyContaner .otp-section .msg-otp {
      font-size: 12px;
      font-style: italic;
      color: #898989;
      margin-top: 6px;
      margin-bottom: 4px; }
    .otpVerifyContaner .otp-section .resend {
      border: 0;
      background-color: #ffffff;
      text-decoration: underline;
      color: #3b2dd1;
      cursor: pointer; }
    .otpVerifyContaner .otp-section .disableresend {
      text-decoration: underline;
      opacity: 0.6;
      pointer-events: none; }
    .otpVerifyContaner .otp-section .time-container {
      color: #898989; }
    .otpVerifyContaner .otp-section .submit-section {
      padding-top: 199px; }
      .otpVerifyContaner .otp-section .submit-section .verify-container {
        background-color: #38b957;
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px;
        font-size: 14px;
        display: block;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 12px;
        border: 0px solid transparent; }
      .otpVerifyContaner .otp-section .submit-section .disable-verify-container {
        background-color: #38b957;
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px;
        font-size: 14px;
        display: block;
        color: #ffffff;
        font-weight: 500;
        margin-bottom: 12px;
        border: 0px solid transparent;
        opacity: 0.6; }
      .otpVerifyContaner .otp-section .submit-section .go-back-btn {
        border-radius: 2px !important;
        height: 40px !important;
        width: 390px !important;
        font-size: 14px;
        display: block;
        color: #898989;
        border: 1px solid #898989; }
      .otpVerifyContaner .otp-section .submit-section .rightlogocontainer {
        position: relative;
        margin-left: 0;
        margin-right: 0;
        top: 3px;
        left: 6px; }
  .otpVerifyContaner .change-btn-container .change-emailbtn {
    background-color: #38b957;
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px !important;
    font-size: 14px;
    display: block;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 12px;
    border: 0px solid transparent; }
  .otpVerifyContaner .ant-btn[disabled] {
    opacity: 0.6; }

.reset-container-new {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#536be8, #3d2fd8, #3d2fd8, #6c48e2);
  position: relative; }
  .reset-container-new .mainCard-signup {
    z-index: 999;
    width: 864px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 6px 8px 48px rgba(0, 0, 0, 0.39);
    display: flex; }
  .reset-container-new .shadow-container {
    position: absolute;
    top: -247px;
    left: -63px; }

.right-container-signup {
  flex: 1 1;
  padding-bottom: 47px !important; }
  .right-container-signup .ant-tabs-nav-scroll {
    display: none; }
  .right-container-signup .ant-tabs-bar {
    display: none; }

.resetSection {
  color: #212121;
  min-height: 550px; }
  .resetSection .heading-bar {
    padding-top: 58px;
    padding-left: 56px; }
  .resetSection .main-heading {
    font-size: 28px;
    font-weight: 600;
    font-family: "Martel", serif !important;
    color: #212121;
    padding-left: 90px; }
  .resetSection .sub-heading {
    font-size: 14px;
    font-weight: 400;
    color: #323041;
    padding-left: 84px; }
  .resetSection .heading-reset {
    color: #0068ff;
    font-weight: 500;
    padding-top: 60px;
    padding-left: 175px; }
  .resetSection .line-blue {
    background-color: #0068ff;
    height: 2px;
    width: 178px;
    margin-left: 159px;
    margin-top: 10px; }
  .resetSection .flex-from {
    padding-left: 56px;
    padding-top: 24px; }
    .resetSection .flex-from .input-signup23 {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 390px; }
    .resetSection .flex-from .input-signup22 input {
      height: 40px !important;
      border-radius: 4px !important;
      border: 1px solid #898989;
      background-color: #f5f5f5;
      font-size: 14px;
      padding: 10px 0 10px 16px;
      width: 390px; }
    .resetSection .flex-from .input-signup22 .ant-input-suffix {
      right: 68px; }
  .resetSection .alert {
    width: 390px;
    margin-bottom: 12px; }
  .resetSection .change-emailbtn {
    background-color: #38b957;
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px !important;
    font-size: 14px;
    display: block;
    color: #ffffff;
    font-weight: 500;
    margin-bottom: 12px;
    border: 0px solid transparent; }
  .resetSection .login-form-signup-here {
    border-radius: 2px !important;
    height: 40px !important;
    width: 390px !important;
    font-size: 14px;
    display: block;
    font-weight: 500;
    margin-bottom: 12px; }
  .resetSection .login-page-footer {
    font-weight: 500;
    margin-top: 50px; }
    .resetSection .login-page-footer .copy-line {
      padding-left: 175px; }
    .resetSection .login-page-footer p {
      margin-bottom: 0px;
      color: #1a1a1a; }
    .resetSection .login-page-footer .footer-links {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0px;
      padding-left: 138px; }
      .resetSection .login-page-footer .footer-links li {
        display: inline-block;
        margin: 0 20px; }
        .resetSection .login-page-footer .footer-links li a {
          color: #1a1a1a; }
        .resetSection .login-page-footer .footer-links li a:hover {
          color: black; }

