.planandbilldetailcontainer {
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 12px;
  letter-spacing: 0.032em;
  color: #898989;
  padding: 48px;

  .red-badge {
    background-color: #fc125e;
    color: #fff;
    border-radius: 10px;
    width: fit-content;
    font-size: 13px;
    font-weight: 500;
    padding: 3px 8px;
  }

  .green-badge {
    background-color: #049d23;
    color: #fff;
    border-radius: 10px;
    font-size: 13px;
    width: fit-content;
    font-weight: 500;
    padding: 3px 8px;
  }

  .overdue-bar-wraper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .overdue-bar {
    font-size: 13px;
    border-radius: 5px;
    font-weight: 500;
    background-color: #cc0042;
    border: 2px solid #ac0036;
    color: #fff;
    line-height: 20px;
    padding: 12px;
    margin-bottom: 24px;
    display: inline-flex;
    padding-top: 0px;
    padding-bottom: 0px;
    align-items: center;
    gap: 12px;
    svg {
      fill: #fff;
    }

    .ant-btn {
      span {
        color: #cc0042;
      }
    }

    .ant-btn:hover {
      border-color: #ac0036 !important;
    }
  }

  .credit-card-wraper {
    border-radius: 5px;
    padding: 6px 6px;
    border: 1px solid;
    text-transform: uppercase;
  }

  .plan-status-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    p {
      font-weight: 500;
    }
  }

  .uppercontainer {
    display: flex;
    justify-content: space-between;
    .currentplan-container {
      border: 1px solid #cecece;
      border-radius: 4px;
      width: 400px;
      padding: 16px;

      > div {
        button {
          width: 100% !important;
        }
      }

      .firstline {
        display: flex;
        color: #212121;
        justify-content: space-between;
        margin-bottom: 18px;
        .plan {
          color: #4a4be4;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
      .secline {
        text-align: center;
        font-size: 16px;
        margin-bottom: 12px;
        .cost {
          font-size: 28px;
          font-weight: 600;
          color: #212121;
        }
      }
      .free-line {
        text-align: center;
        font-size: 48px;

        margin-top: 26px;
      }
      .free-line-bottom {
        text-align: center;
        font-size: 14px;
        margin-top: 17px;
        margin-bottom: 20px;
      }
      .third-line {
        text-align: center;
        margin-bottom: 16px;
      }
      .forth-line {
        text-align: center;
        margin-bottom: 6px;

        .ant-btn {
          width: 190px;
          height: 32px;
          background-color: #0068ff;
          font-size: 14px;
          font-weight: 600;
          border: 1px solid transparent;
          border-radius: 4px !important;
          color: #ffffff;
        }
        .ant-btn:hover {
          background-color: #536be8;
        }
      }
      .fifth-line {
        text-align: center;

        .ant-btn {
          width: 190px;
          height: 32px;
          font-size: 14px;
          color: #898989;
          border-radius: 4px !important;
        }
      }

      .six-line {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;

        .red-btn {
          color: red;
          border-color: red !important;
        }

        .orange-btn {
          color: orange !important;
          border-color: orange !important;
        }

        button {
          flex: 1 !important;
          font-weight: 500 !important;
        }
      }
    }

    .currentplan-container.overdue {
      border-color: #fc125e;
    }
    .payment-method-container {
      padding: 16px;
      border: 1px solid #cecece;
      border-radius: 4px;
      width: 440px;
      align-self: flex-start;
      .first-line {
        font-size: 12px;
        font-weight: 500px;
        color: #212121;
      }
      .sec-line {
        padding-bottom: 8px;
        border-bottom: 1px solid #cecece;
        margin-bottom: 16px;
      }
      .details-container {
        color: #212121;
        display: flex;
        .item-first {
          margin-right: 14px;
        }
        .item-sec {
          flex: 1;
          .sec-1 {
            font-weight: 500;
          }
          .sec-2 {
            font-weight: 500;
            margin-bottom: 9px;
          }
        }
        .item-third {
          color: #fc125e;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
        }
      }
    }
  }
  table {
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    filter: drop-shadow(0 3px 10px rgba(0, 0, 0, 0.16));
    border-radius: 20px;

    * {
      line-height: 16px !important;
    }
    .do-btn {
      display: inline-block;
      padding: 6px;
      width: 140px;
      height: 32px;
      background-color: #0068ff;
      font-size: 14px;
      font-weight: 400;
      border: 1px solid transparent;
      border-radius: 2px !important;
      color: #ffffff;
      text-align: center;
    }
    .do-btn:hover {
      background-color: #536be8;
    }

    .store-info {
      flex: 1;
      p {
        font-size: 12px;
        margin-bottom: 0px;
        letter-spacing: 0.032px;
        line-height: 24px;
        color: #212121;
        font-weight: 400;
      }
    }

    .store-status {
      display: flex;
      align-items: center;
      p {
        font-size: 12px;
        margin-bottom: 0px;
        margin-left: 8px;
        letter-spacing: 0.032px;
        line-height: 24px;
        color: #212121;
        font-weight: 500;
      }
      .ant-switch-checked {
        background-color: #229d1b;
      }
    }
    thead {
      tr {
        th {
          font-size: 12px;
          background: #16273b !important;
          border-radius: 0 !important;
          color: white !important;
          letter-spacing: 0.032px;
          line-height: 24px;
          font-weight: 400;
        }
      }
    }
    tbody {
      background: white;
      tr {
        td {
          font-size: 12px;
          border-bottom-color: #f2f2f2 !important;
          letter-spacing: 0.032px;
          line-height: 24px;
        }
      }
    }
  }
  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 4px !important;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 4px !important;
  }
  .ant-table-tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 4px !important;
  }
  .ant-table-tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 4px !important;
  }
  .ant-table-tbody > tr > td :last-child {
    border-bottom: 0px !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: 0px !important;
  }
  .ant-table-tbody > tr {
    border-bottom: 1px solid #cecece !important;
  }
  .ant-table-tbody > tr:last-child {
    border-bottom: 0px !important;
  }
  .table-cointainer {
    margin-bottom: 32px;
    margin-top: 24px;
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid #cecece !important;
    }
    .ant-table-tbody > tr:last-of-type > td {
      border-bottom: none !important;
    }
    // .ant-table-tbody > tr:nth-of-type(even) {
    //   background-color: #f5f5f5;
    // }
  }
}
.bottom-copy {
  text-align: center;
  color: #898989;
  margin-top: 49px;
}

.ant-popover-placement-right {
  .ant-popover-inner-content {
    width: 300px !important;
  }
}
