.options-container35 {
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  gap: 26px;
  .ant-switch-disabled {
    cursor: unset;
    opacity: 1;
  }
  .ant-switch-disabled::after {
    cursor: unset;
  }
  .subheading {
    font-size: 14px;
    padding-bottom: 5px;
    padding-top: 10px;
  }
  .order-switch-container {
    display: flex;
    .label {
      margin-right: 12px;
    }
    .ant-switch-checked {
      background-color: #36c626;
    }
  }
  .otp-switch-container {
    // padding-top: 16px;
    .label {
      margin-right: 12px;
    }
    .ant-switch-checked {
      background-color: #36c626;
    }
  }
  .type-container {
    .ant-radio-checked .ant-radio-inner {
      border-color: #36c626;
    }
    .ant-radio-wrapper-checked {
      span {
        color: #212121;
        font-weight: 500;
      }
    }
    .ant-radio-checked .ant-radio-inner::after {
      background-color: #36c626;
    }
  }
  .input-container {
    .input-tags {
      width: 360px;
      background: #f5f5f5;
      border: 1px solid #212121;
      border-radius: 0px;
      color: #212121;
      letter-spacing: 0.032em;
      line-height: 20px;
      font-size: 12px;
      height: 37px;
      padding: 4px 11px;
    }
  }
  input:focus-visible,
  textarea {
    outline: none !important;
  }
  input:disabled {
    cursor: not-allowed;
    border: 0px solid black !important;
  }
  .time-zone-tooltip-custom {
    position: relative;
    top: 2px;
  }
}
