.permissionDrawer {
  z-index: 99999;
  .ant-drawer-header {
    background: #192f4a;
    border-radius: 0px;
    width: 1000px;
    padding: 16px 40px;
    position: fixed;
    z-index: 99;
    height: 56px;
    .modalTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .add-new-role-txt {
        margin-bottom: 0px;
        color: white;
        font-weight: 500;
        text-transform: capitalize;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.032em;
      }
      p {
        margin-bottom: 0px;
        color: white;
        font-size: 16px;
      }
      .anticon {
        color: white;
      }
    }
  }
  .ant-drawer-body {
    margin-top: 56px;
    padding: 0px 40px;
    background: #fcfcfc;
    .main-container {
      .permission-container {
        padding-top: 8px;
        border-bottom: 1px solid #b2b2b2;
        .checkbox-title {
          font-size: 12px;
          font-weight: 500;
          color: #212121;
          margin-bottom: 5px;
          span {
            font-size: 10px;
            font-weight: 400;
            float: right;
            cursor: pointer;
          }
        }
        .permission-option {
          display: flex;
          flex-wrap: wrap;
          p {
            font-size: 16px;
            color: #585858;
            font-weight: 400;
            margin-right: 20px;
          }
          .ant-checkbox-wrapper + .ant-checkbox-wrapper {
            margin-left: 0px;
          }
          .ant-checkbox-wrapper {
            margin-bottom: 16px;
            margin-right: 16px;
          }
        }
        .snapshot-select {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          margin-top: 4px;
          .select {
            color: #0062ff;
            font-size: 12px;
            letter-spacing: 0.032em;
            line-height: 16px;
            cursor: pointer;
            font-weight: 500;
          }
          .deselect {
            color: #ff005e;
            font-size: 12px;
            letter-spacing: 0.032em;
            line-height: 16px;
            cursor: pointer;
            font-weight: 500;
          }
        }
      }
    }
  }
  .drawer-footer {
    margin: 0px -40px;
    padding: 20px 40px;
    background: white;
    .ant-btn {
      background: #1bca00;
      color: #fff !important;
      border: none !important;
      outline: none !important;
      font-size: 14px;
      font-weight: 500;
      border-radius: 2px;
      height: 40px;
      width: 140px;
      letter-spacing: 0.032em;
    }
    a {
      color: #212121;
      font-size: 16px;
      font-weight: 400;
      margin-left: 24px;
      letter-spacing: 0.032em;
      line-height: 20px;
    }
  }

  .ant-checkbox-wrapper + span,
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
    font-size: 12px;
    letter-spacing: 0.032em;
    line-height: 16px;
    color: #212121;
    font-weight: 400;
  }

  .all-permissions-continue {
    margin-left: 16px;
    background: none;
    border: none;
    cursor: pointer;
    color: #0068ff;
    letter-spacing: 0.032em;
    font-size: 14px;
    font-weight: 500;
  }
  .all-permissions-continue:hover {
    margin-left: 16px;
    background: none;
    border: none;
    cursor: pointer;
    color: #0068ff;
    letter-spacing: 0.032em;
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
  }

  .select:hover {
    text-decoration: underline;
  }
  .deselect:hover {
    text-decoration: underline;
  }

  .drawer-footer {
    .ant-btn:hover {
      background: #28af21;
      border: 2px solid #229d1b !important;
    }
  }

  .arrow-right {
    transform: rotate(270deg);

    g {
      stroke: white;
      fill: white;

      path {
        fill: white;
      }
    }
  }
}

.permission-modal-checkbox {
  z-index: 99999;
  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 48px;
      margin-right: 12px;
    }
  }
}
